import moment from 'moment'

/**
 * Language mappings for our Dates
 * @type {Object}
 */
const dateTranslations = {
  days: [
    'Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'
  ],
  fullDays: [
    'Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'
  ],
  months: [
    'Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'
  ],
  fullMonths: [
    'January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'
  ]
}

const dateHelpers = {
  _create: function (dateString) {
    const date = new Date(Date.parse(dateString))
    return (dateHelpers._valiDate(date)) ? date : false
  },

  _dateFormatString: 'ddd D MMM YY',

  // @todo: is this method too complicated?
  /**
   * checks that the date created is valid
   * @param  {Date} date JS date object
   * @return {bool} boolean
   */
  _valiDate: function (date) {
    if (Object.prototype.toString.call(date) === '[object Date]') {
      return (!isNaN(date.getTime()))
    }
    return false
  },

  /**
   * takes a JS Date and returns an array with the date parts
   * @param  {Date} date JS Date object
   * @return {Array} Array with three strings, for Year, Month, Day
   */
  _fragmentDate: function (date) {
    return date
      .toISOString()
      .slice(0, 10)
      .split('-')
  },

  /**
   * Returns an object with all the possibly required numbers or strings for our date
   * @param  {String} dateString - String in date parseable format
   * @return {Object} - Object containing date numbers and strings
   */
  _getDateTranslations: function (dateString, verbose = false) {
    const date = dateHelpers._create(dateString)

    // check that it's a valid date first
    if (date) {
      const dateFragments = dateHelpers._fragmentDate(date)
      const dayOfWeekIndex = date.getUTCDay()
      const monthIndex = date.getUTCMonth()
      return {
        dayOfMonth: dateFragments[2],
        dayOfWeek: dayOfWeekIndex + 1,
        dayName: verbose ? dateTranslations.fullDays[dayOfWeekIndex] : dateTranslations.days[dayOfWeekIndex],
        monthOfYear: dateFragments[1],
        monthName: verbose ? dateTranslations.fullMonths[monthIndex] : dateTranslations.months[monthIndex],
        year: verbose ? dateFragments[0] : dateFragments[0].slice(2, 4)
      }
    }
    return false
  },

  /**
   * Public method to return a date string
   * @param  {String} dateString - string in Date parseable format
   * @return {String} - The format e.g. "Thu 5 Nov 16"
   */
  formatStandard: function (dateString, verbose = false) {
    const translations = dateHelpers._getDateTranslations(dateString, verbose)
    if (translations) {
      return `${translations.dayName} ${translations.dayOfMonth} ${translations.monthName} ${translations.year}`
    }
    return null
  },

  /**
   * Public method to return a an ISO string with no time on it
   * @param  {date} date - string in Date parseable format
   * @return {String} - The format
   */
  sliceISO: (date) => {
    const dateToManip = dateHelpers._create(date)
    if (!dateToManip) {
      return null
    }
    return dateToManip.toISOString().slice(0, 10)
  },

  // @todo: should be somewhere else?
  /**
   * Private method to pad single digit numbers
   * @param  {String} n - number to pad
   * @return {String} - padded number
   */
  _padNumber (n) {
    if (n >= 10) return n
    return '0' + n
  },

  /**
   * Public method to return return an array of dates in a range between two dates
   * @param  {String} startDate - datestring in parsable format
   * @param  {String} endDate - datestring in parsable format
   * @return {String} - Array of dates
   */
  getRange: function (startDate, endDate) {
    const start = dateHelpers._create(startDate)
    const end = dateHelpers._create(endDate)

    if (!start || !end) {
      return []
    }

    const dates = []
    for (let i = start; i <= end; i = new Date(i.setDate(i.getDate() + 1))) {
      let year = i.getFullYear()
      let month = dateHelpers._padNumber((i.getMonth() + 1))
      let day = dateHelpers._padNumber(i.getDate())
      dates.push(
        `${year}-${month}-${day}`
      )
    }
    return dates
  },

  addDays (startDate, days) {
    return moment(startDate).add(days, 'day')
  },

  format (date) {
    const dateToFormat = (moment.isMoment(date)) ? date : moment.utc(date)
    return dateToFormat.format(dateHelpers._dateFormatString)
  },

  parseDate (date) {
    const dateToParse = (moment.isMoment(date)) ? date : moment.utc(date)
    return dateToParse.utc().format('YYYY-MM-DD')
  },

  isSameDay (firstDate, secondDate) {
    return moment(firstDate).isSame(secondDate, 'day')
  },

  getDaysBetweenDates (firstDate, secondDate) {
    return moment(secondDate).diff(moment(firstDate), 'days')
  }
}

export default dateHelpers
