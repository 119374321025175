import React, { PureComponent } from 'react'
import moment from 'moment'
import Modal from 'react-bootstrap/lib/Modal'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import tracking from '../actions/tracking'
import SVGSpyglass from '../components/atoms/SVGSpyglass'
import eventMarkerClassNames from '../configs/EventMarkerClassNames'

import Calendar from './Discovery/Calendar'

import BrandContext from '../contexts/BrandContext'

class DiscoveryModal extends PureComponent {
  constructor () {
    super()
    this.state = {
      events: []
    }

    this.getEvents = this.getEvents.bind(this)
  }

  renderSummary () {
    if (this.props.isLoading) return <FormattedMessage id='availability.flexibleDatesModalHeaderLoading' tagName='small' />
    if (this.props.error) return <FormattedMessage id='common.anErrorHasOccurred' tagName='small' />
    if (this.props.noPackages) return <FormattedMessage id='common.noDiscoverPackagesAvailable' values={{ date: moment(this.props.bounds.currentMonth).format('MMMM YYYY') }} />
    let messageId = 'availability.flexibleDatesModalHeaderNoAvail'
    let hotelName = null
    if (this.props.hotelName) {
      messageId = 'availability.flexibleDatesModalHeaderAvailable'
      hotelName = this.props.hotelName.startsWith('The') ? this.props.hotelName : `the ${this.props.hotelName.replace('the ', '')}`
    }
    return (
      <FormattedMessage
        id={messageId}
        tagName='small'
        values={{
          checkInDate: this.props.bounds.currentMonth.format('MMMM'),
          hasEventEntry: this.props.hasEventEntry,
          hotelPartyString: this.props.hotelPartyString,
          hotelName: hotelName,
          stayLength: this.props.nights,
          ticketName: this.props.ticketName,
          venueName: this.props.venueName
        }}
      />
    )
  }

  getEvents (events) {
    this.setState(() => ({
      events
    }))
  }

  render () {
    const { isVisible, toggleVisible, nights, withPriceFinderSubheading } = this.props

    return (
      <Modal
        className='price-finder'
        onHide={toggleVisible}
        show={isVisible}
      >
        <Modal.Header className='bg-brand' closeButton>
          <Modal.Title>
            <span className='pr-2'><SVGSpyglass width='23' height='23' aria-hidden='true' role='presentation' /></span>
            <FormattedMessage id='common.dealFinder' />
            {nights && withPriceFinderSubheading &&
              <div className='price-finder-subheading'>
                {nights === 1
                  ? <FormattedMessage id='common.dealFinderPriceInfoMessageSingle' />
                  : <FormattedMessage id='common.dealFinderPriceInfoMessageMulti' values={{ nights: nights }} />
                }
              </div>
            }
          </Modal.Title>
        </Modal.Header>
        <Modal.Body className='flexible-prices-modal-calendar'>
          <Calendar getEvents={this.getEvents} />
        </Modal.Body>
        <Modal.Footer>
          {!!this.state.events.length &&
            <div className='calendar-events'>
              {this.state.events.map((event) => {
                return <div className='calendar-event-names'>
                  <div className={eventMarkerClassNames[event.eventIndex]} />
                  <p>{event.eventName}</p>
                </div>
              })}
            </div>
          }
          <p className='pull-left' >
            <FormattedMessage id='common.pricesValidFrom' values={{ date: moment(new Date()).format('ddd Do MMM YYYY') }} tagName='small' />
          </p>
        </Modal.Footer>
      </Modal>
    )
  }
}

DiscoveryModal.contextType = BrandContext

function mapStateToProps (state) {
  return {
    bounds: state.discovery.bounds,
    error: state.discovery.error,
    hasEventEntry: state.discovery.hasEventEntry,
    hotelName: (state.packageRates.packages.find(pkg => pkg.id === state.discovery.hotelId) || {}).name,
    hotelEventName: state.discovery.hotelEventName,
    hotelPartyString: state.basket.hotelPartyString,
    isLoading: state.discovery.isLoading,
    isVisible: state.modal.visible.discoveryCalendar,
    nights: state.discovery.nights,
    noPackages: state.discovery.packagesCount === 0,
    ticketName: state.searchSummary.title,
    venueName: state.discovery.venueName
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleVisible: () => {
      dispatch(tracking.track('close', 'discovery-modal', 'close'))
      return dispatch({
        type: 'MODAL_TOGGLE',
        modalName: 'discoveryCalendar'
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DiscoveryModal)
export {
  DiscoveryModal
}
