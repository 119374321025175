import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGSun extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <g>
          <path d='M378.795 368.439a128.774 128.774 0 0 0 5.385-36.744c0-70.679-57.501-128.18-128.18-128.18s-128.18 57.501-128.18 128.18c0 12.449 1.841 24.862 5.385 36.744H0v30h512v-30H378.795zm-31.718-.001H164.924c-4.716-11.628-7.104-23.95-7.104-36.744 0-54.136 44.043-98.18 98.18-98.18 54.137 0 98.18 44.043 98.18 98.18 0 12.794-2.387 25.116-7.103 36.744zM241 113.561h30v60h-30zM322.118 187.28l30.024-51.947 25.974 15.012-30.024 51.948zM385.488 239.724l51.982-29.956 14.978 25.99-51.981 29.957z' />
          <path d='M414.11 346.862l.037-30 60 .072-.036 30zM132.996 150.324l25.973-15.008 30.017 51.945-25.973 15.009zM58.646 235.76l14.978-25.99 51.983 29.958-14.978 25.99zM36.982 316.956l60-.072.036 30-60 .072z' />
        </g>
      </SVG>
    )
  }
}

SVGSun.defaultProps = {
  title: 'Sun Icon',
  height: '44',
  width: '44',
  viewBox: '0 0 512 512'
}

SVGSun.propTypes = SVG.propTypes

export default SVGSun
