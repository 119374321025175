const experiment = (state = {
  listeners: [],
  active: {},
  initialized: false
}, action) => {
  switch (action.type) {
    case 'INITIALIZE_EXPERIMENT_EVENT_LISTENERS': {
      const active = {}
      Object.keys(action.experiments).map(name => {
        if (action.experiments[name].brands.indexOf(window.brandConfig.name) !== -1) {
          active[name] = action.experiments[name]
        }
      })

      return Object.assign({}, state, {
        active,
        all: action.experiments,
        initialized: true
      })
    }
    case 'EXPERIMENT_FORCE_ACTIVE': {
      const experiments = window.theWallExperiments || {}
      if (!experiments[action.name]) return state
      const forcedExperiment = Object.assign({}, experiments[action.name], {
        forced: true
      })
      return Object.assign({}, state, {
        active: Object.assign({}, state.active, {
          [action.name]: forcedExperiment
        })
      })
    }
    case 'EXPERIMENT_LISTENER_ADD':
      return Object.assign({}, state, {
        listeners: [...state.listeners, action.listener]
      })
    case 'EXPERIMENT_LISTENER_TEARDOWN':
      return Object.assign({}, state, {
        listeners: []
      })
    default:
      return state
  }
}

export default experiment
