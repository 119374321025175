import React from 'react'
import SummaryWrapper from '../../hoc/SummaryWrapper'

import { Col, Collapse, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

const SimpleSearchSummary = ({
  checkinDate,
  collapsed,
  handleSummaryCollapseToggle,
  hasTicketDescriptions,
  hasParkEntry,
  labelParkDate,
  nights,
  onChange,
  parkDate,
  roomDescriptions,
  summaryTitleCollapsible,
  ticketDescriptions,
  ticketDuration,
  trackValues
}) => (
  <React.Fragment>
    <div className='summary-panel container block-sm'>
      <Row>
        <Col lg={3} sm={2} className='col-lg-3 col-sm-2'>
          <a
            className={summaryTitleCollapsible}
            onClick={handleSummaryCollapseToggle}
            onKeyDown={(e) => e.key === 'Enter' && handleSummaryCollapseToggle}>
            <h3 className='h3 mt-0'>
              <FormattedMessage id='common.summary' /> <span className='i-plus pull-right collapse-icon'>+</span>
            </h3>
          </a>
        </Col>

        <Collapse in={!collapsed}>
          <div className='summary-content collapse-non-sm collapse-non-md collapse-non-lg'>
            <Row>
              <Col xs={12}>
                <p className='ml-3 mb-2'><FormattedMessage id='common.yourStay' /> - <FormattedMessage id='common.dateForNights' values={{ date: checkinDate, nights }} /></p>
              </Col>
              <Col xs={12}>
                <div className='float-left ml-3'>
                  <FormattedMessage id='common.yourRoom' values={{ count: roomDescriptions.length }} /> -
                </div>
                <div className='float-left'>
                  {roomDescriptions.map(roomDescription =>
                    <p className='ml-2 mb-2'>{roomDescription}</p>
                  )}
                </div>
              </Col>
              {hasParkEntry &&
              <Col xs={12}>
                <p className='ml-3 mb-2'>{labelParkDate} - <FormattedMessage id='common.parkEntryForConsecutiveDays' values={{ parkDate: parkDate && parkDate.format('Do MMMM YY'), ticketDuration }} /></p>
              </Col>
              }
              <Col
                xs={12}
                onClick={() => trackValues('change', 'click')}
                onKeyDown={(e) => e.key === 'Enter' && trackValues('change', 'keydown')}
              >
                <button
                  className='btn-link'
                  onClick={onChange}
                  onKeyDown={(e) => e.key === 'Enter' && onChange()}
                  role='presentation'
                  tabIndex='0'>
                  <FormattedMessage id='common.editSearch' /> &raquo;
                </button>
              </Col>
            </Row>
          </div>
        </Collapse>
      </Row>
    </div>
    {hasTicketDescriptions &&
    <Collapse in={!collapsed}>
      <div className='fade summary-content bg-tertiary collapse-non-sm collapse-non-md collapse-non-lg'>
        <div className='block-xs container-fluid d-sm-flex small-xs'>
          {Object.keys(ticketDescriptions).map(date => {
            return (
              <div className='pl-1 pr-4'>
                <strong>{date}</strong>
                {ticketDescriptions[date].map(details => {
                  return <p className='mb-0'>{details.name} - {details.time}: <span className='text-muted small'>{details.comp}</span></p>
                })}
              </div>
            )
          })}
        </div>
      </div>
    </Collapse>
    }
  </React.Fragment>
)

export default SummaryWrapper(SimpleSearchSummary)
