import React from 'react'
import PropTypes from 'prop-types'

const SummaryDiscountMessage = ({ message }) => (
  <div className='clearfix'>
    <div className='label label-info discount-message'>{message}</div>
  </div>
)

SummaryDiscountMessage.propTypes = {
  message: PropTypes.string.isRequired
}

export default SummaryDiscountMessage
