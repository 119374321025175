import 'isomorphic-fetch'
import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import config from '../configs/config'
import dateHelpers from './dateHelpers'
import languageHelpers from './languageHelpers'
import routeHelpers from './routeHelpers'

const { harvestBasket } = config
const withChildAges = _.get(config, 'brandConfig.secure.withChildAges', false)
const withSimplifiedSummary = _.get(config, 'brandConfig.secure.hasFeatures.availability.hasSimplifiedSummary', false)

const basketHelpers = {}

basketHelpers.getComposition = (partyCompCounts = {}) => {
  const { adults, children, infants, babies } = partyCompCounts
  if (!adults) {
    return ''
  }
  let composition = `${adults} ${languageHelpers.pluralise('Adult', adults)}`
  if (children > 0) {
    composition += `, ${children} ${languageHelpers.pluralise('Child', children)}`
  }
  if (infants > 0) {
    composition += `, ${infants} ${languageHelpers.pluralise('Infant', infants)}`
  }
  if (babies > 0) {
    composition += `, ${babies} ${languageHelpers.pluralise('Baby', babies)}`
  }

  return composition
}

/**
 * Calculates the surcharge for a credit card
 * It figures out if the price would be between the min and max surcharge and returns the correct value
 * @param  {Object} creditCardSurcharge {min, max, percentage}
 * @param  {Number} grossPrice the grossPrice
 * @return {Number} the calculated surcharge
 */
basketHelpers.getCreditCardSurcharge = (creditCardSurcharge, grossPrice) => {
  // Work out gross price including surcharge
  const { max, min, percentage } = creditCardSurcharge
  const surcharge = (grossPrice / 100) * percentage

  // This line will use the minimum or the maximum surcharge if the calculated
  // surcharge is outside the bounds of min/max
  const limitedSurcharge = Math.min(Math.max(surcharge, min), max)

  // Make sure we only display when the information is all correct
  if (!isNaN(limitedSurcharge)) {
    return Number(limitedSurcharge.toFixed(2))
  }

  return 0
}

basketHelpers.getEventDates = (firstEventDay, venueType = 'Theme Park', hasParkEntry) => {
  // harvest gives us booleans as strings, so we need to account for that here
  if (typeof hasParkEntry === 'string') {
    hasParkEntry = (hasParkEntry === 'true')
  }

  const firstEventDayFormatted = dateHelpers.format(firstEventDay)
  const label = hasParkEntry ? 'Arrival date' : `${venueType} Day`
  return (
    <div>
      <strong>{label}: </strong> {firstEventDayFormatted}
    </div>
  )
}

basketHelpers.getPartyPerRoom = (roomTypes, rooms) => {
  return (rooms || []).map((room) => basketHelpers.getPartyRoomDescription(roomTypes, room))
}

basketHelpers.getPartyRoomDescription = (roomTypes, room = {}) => {
  const roomType = basketHelpers.getRoomType(roomTypes, room)
  const { adults, children, infants } = basketHelpers.maybeConvertPartyComp(room)
  return {
    description: roomType.roomShortDesc,
    party: basketHelpers.getComposition({ adults, children, infants })
  }
}

basketHelpers.getRoomsDescriptions = (roomTypes, rooms) => {
  return (rooms || []).map((room = {}) => {
    const roomType = basketHelpers.getRoomType(roomTypes, room)
    const { adults, children, infants } = basketHelpers.maybeConvertPartyComp(room)
    if (withSimplifiedSummary) return room.originalName || roomType.roomShortDesc
    return `${room.originalName || roomType.roomShortDesc} - ${basketHelpers.getComposition({ adults, children, infants })}`
  })
}

basketHelpers.getRoomType = (roomTypes, room = {}) => {
  if (!roomTypes || !room || !room.occupancyType || !room.adults) return {}
  const adults = Array.isArray(room.adults) ? room.adults.length : room.adults
  let children = 0
  if (room.children) {
    children = Array.isArray(room.children) ? room.children.length : room.children
  }
  let roomType = roomTypes[`${room.occupancyType}${adults}${children}`] || {}
  if (Object.keys(roomType).length === 0 && room.occupancyType.includes('FM')) {
    roomType = roomTypes['FM']
  }

  return roomType
}

basketHelpers.handleGetSessionBasketData = function (includes, isUpgradesStage = false) {
  // get basket object from sessionStorage
  let basketObj = sessionStorage && JSON.parse(sessionStorage.getItem('sessionBasket'))

  // If there is no basket object in session, grab it from the window.
  if (!basketObj) {
    if (Object.keys(harvestBasket).length === 0) return false
    basketObj = Object.assign({}, harvestBasket)
    basketHelpers.handleSetSessionBasketData('sessionBasket', basketObj)
  }

  return new Promise((resolve, reject) => {
    basketObj.includes = includes

    // get the basketData with linked items from the works
    // then store it in state as harvestData
    // Ideally this should be a action / reducer thing.. but for now I'm just removing client-basket.
    const queryStringParams = routeHelpers.getQueryStringParams()
    const objectToSerialise = {
      date: Date.now(),
      include: basketObj.includes || 'hotelProducts,ticketProducts',
      tag: basketObj.tag
    }
    if (queryStringParams.referrer) objectToSerialise.referrer = queryStringParams.referrer
    const params = routeHelpers.serialiseObject(objectToSerialise)
    fetch(`//${document.location.host}/basketFetch/${basketObj.id}?${params}`, {
      credentials: 'include'
    })
      .then((response) => {
        if (response.status >= 200 && response.status < 300) {
          return response.json()
        }
        throw new Error(response.statusText)
      })
      .then((response) => {
        const harvestData = response.harvest
        if (Object.keys(harvestData).length > 0) {
          const nights = isUpgradesStage ? _.get(harvestData, 'baskets.data.hotel.nights') : 1
          this.setState(() => ({
            harvestData,
            nights
          }), () => {
            // return the data in the resolve to use elsewhere
            resolve(harvestData)

            if (isUpgradesStage) {
              this.removeUpgrades()
            }
          })
        }
      })
      .catch((error) => reject(error))
  })
}

basketHelpers.handleSetSessionBasketData = function (itemName, data) {
  if (!itemName || !data || !sessionStorage) {
    return
  }
  sessionStorage.setItem(itemName, JSON.stringify(data))
}

// If we have withChildAges (search project), we want to return infants as children
basketHelpers.maybeConvertPartyComp = partyComp => {
  const partyCompCopy = _.cloneDeep(partyComp)
  if (!withChildAges) return partyCompCopy
  partyCompCopy.children = Number(partyCompCopy.children) + Number(partyCompCopy.infants)
  partyCompCopy.infants = 0
  return partyCompCopy
}

basketHelpers.getTicketDescriptions = ticketRates => {
  return ticketRates.reduce((mappedTicketRates, ticket) => {
    if (ticket.name) {
      const dateTime = basketHelpers.getDateAndTimeFormat(ticket.startDate, ticket.timeslot.start)
      const ticketDetails = {
        comp: basketHelpers.getComposition({
          adults: ticket.adults,
          children: ticket.children
        }),
        name: ticket.name,
        time: dateTime.time
      }
      if (!mappedTicketRates[dateTime.date]) {
        mappedTicketRates[dateTime.date] = []
      }
      mappedTicketRates[dateTime.date].push(ticketDetails)
    }
    return mappedTicketRates
  }, {})
}

basketHelpers.getDateAndTimeFormat = (date, time) => {
  time = time.replace(/(\d{2})/, '$1:') // adds a colon into the time
  const dateTime = new Date(`${date} ${time}`)
  date = moment(dateTime).format('dddd Do MMM YYYY')
  time = moment(dateTime).format('h:mm A')
  return { date, time }
}

export default basketHelpers
