// SEE: https://github.com/rangle/redux-beacon for explanation of this file

const experiment = data => ({
  event: 'sbpexperiment',
  experimentName: data.experimentName,
  variantName: data.variantName
})

const event = data => ({
  hitType: 'event',
  event: data.event,
  action: data.action,
  category: data.category,
  label: data.label
})

// Map the event to a Redux action
const eventsMap = {
  EXPERIMENT_DATA: experiment,
  TRACKING_TRACK: event,
  TRACKING_ERROR: event
}

export default eventsMap
