/*
 * https://github.com/eligolding/react-mailcheck#readme
 * Taken from Tripapplite -
 * https://github.com/holidayextras/tripapplite/blob/staging/src/javascripts/app/components/shared/MailCheck.js
 */

import { Component } from 'react'
import PropTypes from 'prop-types'
import mailcheck from '../../scripts/jquery.mailcheck'

class MailCheck extends Component {
  constructor (props) {
    super(props)

    this.state = {
      suggestion: ''
    }

    this.onCheckEmail = this.onCheckEmail.bind(this)
  }

  componentWillMount () {
    this.onCheckEmail(this.props.email)
  }

  componentDidUpdate (nextProps) {
    if (this.props.email !== nextProps.email) {
      this.onCheckEmail(this.props.email)
    }
  }

  onCheckEmail (email) {
    const {
      domains,
      topLevelDomains,
      secondLevelDomains,
      distanceFunction
    } = this.props

    mailcheck.run({
      email,
      domains,
      topLevelDomains,
      secondLevelDomains,
      distanceFunction,
      suggested: (suggestion) => {
        this.setState({
          suggestion
        })
      },
      empty: () => {
        if (this.state.suggestion) {
          this.setState({
            suggestion: ''
          })
        }
      }
    })
  }

  render () {
    return this.props.children(this.state.suggestion)
  }
}

const { string, array, func } = PropTypes

MailCheck.propTypes = {
  email: string.isRequired,
  children: func.isRequired,
  domains: array,
  topLevelDomains: array,
  secondLevelDomains: array,
  distanceFunction: func
}

export default MailCheck
