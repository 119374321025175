import 'isomorphic-fetch'
import routeHelpers from '../helpers/routeHelpers'
import _ from 'lodash'
import config from '../configs/config'
const { brandConfig } = config
const assetsUrl = _.get(brandConfig, 'secure.assetsUrl', '')

export const setIsLoading = (bool) => {
  return { type: 'SET_IS_LOADING', payload: bool }
}

export const setQueryObject = (queryObject) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_QUERY_OBJECT_SUCCESS',
      payload: queryObject
    })
  }
}

export const changeSeats = (partyComposition) => {
  return (dispatch) => {
    dispatch({
      type: 'CHANGE_SEATS_SUCCESS',
      payload: partyComposition
    })
  }
}

export const changeQuery = (key, value) => {
  return (dispatch) => {
    dispatch({
      type: 'CHANGE_QUERY_OBJECT_SUCCESS',
      payload: { key, value }
    })
  }
}

export const selectSeat = (seat, book) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_SELECTED_SEAT',
      payload: { seat, book }
    })
  }
}

export const setAvailability = (packageRatesReply) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_SEAT_AVAILABILITY',
      payload: packageRatesReply
    })
  }
}

export const setEvent = (product) => {
  return (dispatch) => {
    dispatch({
      type: 'SET_EVENT_SUCCESS',
      payload: product
    })
  }
}

export const setVenue = (venueProducts) => {
  const venue = venueProducts[Object.keys(venueProducts)[0]]
  return (dispatch) => {
    return new Promise((resolve, reject) => {
      fetch(`${assetsUrl}venueMaps/${venue.id}.json`)
        .then((response) => response.json())
        .then((response) => {
          resolve(dispatch({
            type: 'SET_VENUE_SUCCESS',
            payload: {
              seatMap: response,
              id: venue.id,
              name: venue.name,
              sections: venue.sections
            }
          }))
        })
        .catch(() => resolve(dispatch({
          type: 'SET_ERROR_SUCCESS',
          payload: {
            seatMap: true
          }
        })))
    })
  }
}

export const getPackageRates = () => {
  return (dispatch, getState) => {
    dispatch(setIsLoading(true))
    return new Promise((resolve, reject) => {
      const params = routeHelpers.serialiseObject(getState().seats.queryObject)
      fetch(`/availabilityJson?${params}`)
        .then((response) => response.json())
        .then((response) => {
          const venueProducts = _.get(response, 'packageRatesReply.linked.venueProducts', null)
          dispatch(setIsLoading(false))
          if (venueProducts) resolve(dispatch(setVenue(venueProducts)))
          resolve(dispatch(setAvailability(response.packageRatesReply)))
        })
        .catch((error) => reject(error))
    })
  }
}
