import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import { Col } from 'react-bootstrap'
import moment from 'moment'

import SVGCalendar from '../../atoms/SVGCalendar'

const Dates = ({
  checkInDate,
  checkInTime,
  checkOutDate,
  checkOutTime,
  hasFixedSummary,
  hideBasketSVGs,
  reducedInfo,
  showPriceOnMobile,
  hasYourStay
}) => {
  const datesHide = classNames({
    'col-xs-2 pl-0 pr-0': true,
    'hidden-xs': reducedInfo
  })
  const defaultMenu = classNames({
    'hidden-xs': reducedInfo
  })
  const ulClassName = `list-unstyled svg col-xs-${hideBasketSVGs ? '6' : '5'} pl-0 mb-0`

  if (!hasFixedSummary) {
    return (
      <div>
        {!hideBasketSVGs &&
          <div className={datesHide}>
            <SVGCalendar
              height='30'
              width='30'
              viewBox='0 0 58 58'
            />
          </div>
        }

        {/* Hotel date on mobile version */}
        {!showPriceOnMobile && reducedInfo && [checkInDate, checkOutDate].map((date, index) => {
          return (
            <Col className='mt-0 visible-xs-block pb-0' xs={5} key={`date${index}`}>
              <div className='media-body media-middle text-right'>
                <div className='large-lg'>
                  {date.format('ddd')}
                </div>
                <span className='small'>
                  {date.format('MMM')}
                </span>
              </div>
              <div className='media-right media-middle display-4'>
                {date.format('D')}
              </div>
            </Col>
          )
        })}

        <div className={defaultMenu}>
          <ul className={ulClassName}>
            <li><FormattedMessage id='common.checkin' tagName='strong' /></li>
            <li>{checkInDate.format('ddd D MMM YY')}</li>
            <li className='text-muted tiny'>from {checkInTime}</li>
          </ul>
          <ul className={ulClassName}>
            <li><FormattedMessage id='common.checkout' tagName='strong' /></li>
            <li>{checkOutDate.format('ddd D MMM YY')}</li>
            <li className='clearfix text-muted tiny'>by {checkOutTime}</li>
          </ul>
        </div>
      </div>
    )
  }

  return (
    <React.Fragment>
      <li className={reducedInfo ? 'hidden-xs' : ''}>
        <div className='media'>
          <div className='media-left'>
            {!hideBasketSVGs &&
              <SVGCalendar
                height='30'
                width='30'
                viewBox='0 0 58 58'
              />
            }
          </div>
          <div className='media-body'>
            {hasYourStay &&
              <React.Fragment>
                <div className='media-heading'><FormattedMessage id='common.yourStay' /></div>
                <div className='text-muted tiny'><FormattedMessage id='basket.checkIn' values={{ checkInDate: checkInDate.format('Do MMMM YYYY'), checkInTime: checkInTime }} /></div>
                <div className='text-muted tiny'><FormattedMessage id='basket.checkOut' values={{ checkOutDate: checkOutDate.format('Do MMMM YYYY'), checkOutTime: checkOutTime }} /></div>
              </React.Fragment>
            }
            {!hasYourStay &&
              <React.Fragment>
                <div className='row'>
                  <div className='col-xs-6'>
                    <div className='media-heading'><FormattedMessage id='common.checkin' /></div>
                    <div className='text-muted tiny'>{checkInDate.format('ddd D MMM YY')}</div>
                    <div className='text-muted tiny'>from {checkInTime}</div>
                  </div>
                  <div className='col-xs-6'>
                    <div className='media-heading'><FormattedMessage id='common.checkout' /></div>
                    <div className='text-muted tiny'>{checkOutDate.format('ddd D MMM YY')}</div>
                    <div className='text-muted tiny'>by {checkOutTime}</div>
                  </div>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
        <hr className={reducedInfo ? 'hidden-xs' : ''} />
      </li>
      {/* Hotel date on mobile version */}
      {!showPriceOnMobile &&
        <div className='clearfix visible-xs-block mb-0'>
          {reducedInfo && [checkInDate, checkOutDate].map((date, index) => {
            return (
              <Col className='mt-0 pb-0' xs={5} key={`date${index}`}>
                <div className='media-body media-middle text-right'>
                  <div className='large-lg'>
                    {date.format('ddd')}
                  </div>
                  <span className='small'>
                    {date.format('MMM')}
                  </span>
                </div>
                <div className='media-right media-middle display-4'>
                  {date.format('D')}
                </div>
              </Col>
            )
          })}
        </div>
      }
    </React.Fragment>
  )
}

Dates.propTypes = {
  checkInDate: PropTypes.instanceOf(moment).isRequired,
  checkInTime: PropTypes.string.isRequired,
  checkOutDate: PropTypes.instanceOf(moment).isRequired,
  checkOutTime: PropTypes.string.isRequired,
  hasFixedSummary: PropTypes.bool.isRequired,
  hideBasketSVGs: PropTypes.bool
}

export default Dates
