import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import Calendar from './Calendar'

import engine from '../../../actions/engine'

class CheckIn extends PureComponent {
  constructor (props) {
    super(props)
    this.isDayDisabled = this.isDayDisabled.bind(this)
  }
  dateAsString (date, errorString = '') {
    const asMoment = moment(date)
    return asMoment.isValid() ? asMoment.format('Do MMM YY') : errorString
  }

  isDayDisabled (day) {
    day = moment(day)
    const check = !this.props.dates.all[day.format('YYYY-MM-DD')]
    if (this.props.hasTimeslotSelectUI) {
      if (!check && !this.props.dates.all[day.format('YYYY-MM-DD')].timeslots.length) {
        return true
      }
    }
    return check
  }

  render () {
    return (
      <div className={classNames({
        'searchform-checkin': true,
        'has-error': this.props.error
      })}>
        <div className='form-group'>
          <label htmlFor='searchformcheckinbutton'>
            {this.props.label}
          </label>
          <button
            id='searchformcheckinbutton'
            type='button'
            onClick={this.props.toggleVisible}
            onKeyDown={e => e.key === 'Enter' && this.props.toggleVisible()}
            className='form-control ignore-react-onclickoutside'
          >
            {this.dateAsString(this.props.checkInDate, 'Please choose')}
          </button>
          {this.props.isVisible &&
            <Calendar
              onClose={this.props.toggleVisible}
              isDayDisabled={this.isDayDisabled}
              endDate={this.props.lastAvailableDate}
              selected={this.props.checkInDate}
              startDate={this.props.firstAvailableDate}
              onChange={this.props.change}
            />
          }
          {this.props.error &&
            <div className='help-block'>
              <FormattedMessage id='common.pleaseSelect' />
            </div>
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const {
    checkInDate: defaultCheckInDate
  } = state.engine.defaults

  const {
    checkInDate,
    dates,
    firstAvailableDate,
    lastAvailableDate
  } = state.engine.engineStore

  return {
    dates,
    checkInDate: new Date(checkInDate || defaultCheckInDate),
    firstAvailableDate: new Date(firstAvailableDate),
    lastAvailableDate: new Date(lastAvailableDate),
    label: _.get(state.engine.brandConfig, 'UITextDefaults.labelCheckIn', 'Check in'),
    hasTimeslotSelectUI: _.get(state, 'engine.brandConfig.hasTimeslotSelectUI', false),
    error: state.engine.errorCheckInDate,
    isVisible: state.engine.visibleCheckInCalendar
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleVisible: e => {
      if (e && e.preventDefault) e.preventDefault()
      if (e && e.stopPropagation) e.stopPropagation()
      return dispatch(engine.toggle('checkInCalendar', false))
    },

    change: checkInDate => {
      return dispatch(engine.changeCheckInDate(checkInDate))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn)
