import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'

import SVGHotel from '../../atoms/SVGHotel'

class HotelItem extends PureComponent {
  render () {
    const hotelItemHide = classNames('mb-0 pl-0 flex-align_center list-unstyled', {
      'col-xs-12': this.props.hideBasketSVGs,
      'col-xs-10': !this.props.hideBasketSVGs,
      'hidden-xs': this.props.reducedInfo
    })
    const hotelItemSVGHide = classNames('col-xs-2 pl-0 pr-0', {
      'hidden-xs': this.props.reducedInfo
    })

    if (!this.props.hasFixedSummary) {
      return (
        <div className='clearfix'>
          {!this.props.hideBasketSVGs &&
            <div className={hotelItemSVGHide}>
              <SVGHotel
                height='30'
                width='30'
                viewBox='0 0 512 512'
              />
              <div />
            </div>
          }
          <ul className={hotelItemHide}>
            <li>
              <strong>
                {this.props.name}
              </strong>
            </li>
            {this.props.roomName &&
              <li>{this.props.roomName}</li>
            }
            {this.props.roomDescriptions.map((description, index) => (
              <li className='tiny text-muted' key={`roomDescription${index}`} dangerouslySetInnerHTML={{ __html: description }} />
            ))}
            {this.props.cateringDescription &&
              <li>{this.props.cateringDescription}</li>
            }
            {this.props.cancellationWaiver &&
              <li>{this.props.cancellationWaiver}</li>
            }
          </ul>
        </div>
      )
    }

    return (
      <li className={this.props.reducedInfo ? 'hidden-xs' : ''}>
        <div className='media'>
          <div className='media-left'>
            {!this.props.hideBasketSVGs &&
              <div className={hotelItemSVGHide}>
                <SVGHotel
                  height='30'
                  width='30'
                  viewBox='0 0 512 512'
                />
              </div>
            }
          </div>
          <div className='media-body'>
            <div className='media-heading'>{this.props.name}</div>

            {this.props.roomName &&
              <div className='text-muted tiny'>{this.props.roomName}</div>
            }

            {this.props.roomDescriptions.map((room, index) => (
              <div key={`roomDescription${index}`} className='text-muted tiny'>
                {room.party}
                {room.description && <span className='tiny text-muted' dangerouslySetInnerHTML={{ __html: ` (${room.description})` }} />}
              </div>
            ))}
          </div>
        </div>
        <hr />
      </li>
    )
  }
}

HotelItem.propTypes = {
  cancellationWaiver: PropTypes.string,
  cateringDescription: PropTypes.string,
  name: PropTypes.string.isRequired,
  hasFixedSummary: PropTypes.bool.isRequired,
  hideBasketSVGs: PropTypes.bool,
  roomDescriptions: PropTypes.array.isRequired,
  roomName: PropTypes.string
}

export default HotelItem
