const filters = (state = {
  active: false,
  price: 99999,
  milesToPark: 99,
  propertyType: {
    hotel: false,
    glamping: false,
    selfCateringLodges: false
  },
  starRating: [],
  popularOptions: {
    hasFreeWifi: false,
    hasSwimmingPool: false,
    hasFreeParking: false,
    hasRestaurant: false,
    hasGym: false
  },
  hotelFeatures: {
    hasGoodieBag: false,
    isInfantFriendly: false,
    hasKidsDining: false,
    hasKidsPlayArea: false
  },
  sortBy: 'Top Selling'
}, action) => {
  const { type, value } = action
  switch (type) {
    case 'FILTER_BY_PRICE':
      return {
        ...state,
        active: true,
        price: value
      }
    case 'FILTER_BY_MILES_TO_PARK':
      return {
        ...state,
        active: true,
        milesToPark: value
      }
    case 'FILTER_BY_PROPERTY_TYPE':
      return {
        ...state,
        active: 'propertyType',
        propertyType: {
          ...state.propertyType,
          [value]: !state.propertyType[value]
        }
      }
    case 'FILTER_BY_STAR_RATING': {
      let starRating
      // This will add / remove the rating from the array to filter by
      if (state.starRating.indexOf(value) === -1) {
        starRating = [].concat(state.starRating, value).sort()
      } else {
        // No need to sort if you are removing one as it will preserve the order anyway
        starRating = state.starRating.filter(rating => rating !== value)
      }
      return {
        ...state,
        active: 'starRating',
        starRating
      }
    }
    case 'FILTER_BY_POPULAR_OPTIONS':
      return {
        ...state,
        active: 'popularOptions',
        popularOptions: {
          ...state.popularOptions,
          [value]: !state.popularOptions[value]
        }
      }
    case 'FILTER_BY_HOTEL_FEATURES':
      return {
        ...state,
        active: 'hotelFeatures',
        hotelFeatures: {
          ...state.hotelFeatures,
          [value]: !state.hotelFeatures[value]
        }
      }
    case 'SORT_BY_CHEAPEST':
      return {
        ...state,
        sortBy: 'Cheapest'
      }
    case 'SORT_BY_CLOSEST':
      return {
        ...state,
        sortBy: 'Closest'
      }
    case 'SORT_BY_TOP_SELLING':
      return {
        ...state,
        sortBy: 'Top Selling'
      }
    default:
      return state
  }
}

export default filters
