import _ from 'lodash'

const initialState = {
  error: '',
  loading: false,
  vouchers: []
}

const vouchers = (state = initialState, action) => {
  switch (action.type) {
    case 'ADD_VOUCHER_START': {
      return {
        ...state,
        loading: true
      }
    }

    case 'ADD_VOUCHER_FAILED': {
      return {
        ...state,
        error: action.error,
        loading: false
      }
    }

    case 'ADD_VOUCHER_SUCCESS': {
      return {
        error: '',
        loading: false,
        vouchers: [
          ...state.vouchers,
          action.voucher
        ]
      }
    }

    case 'REMOVE_VOUCHER_SUCCESS': {
      const vouchers = _.cloneDeep(state.vouchers).filter(voucher => voucher.voucherCode !== action.voucherCode)
      return {
        vouchers
      }
    }

    case 'REMOVE_ALL_VOUCHERS': {
      return initialState
    }

    default:
      return state
  }
}

export default vouchers
