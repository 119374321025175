import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Youtube from 'react-youtube'
import classNames from 'classnames'
import { query } from '../atoms/BreakPoint'
import ResponsiveImage from '../atoms/ResponsiveImage'
import trackingHelpers from '../../helpers/trackingHelpers'

class Carousel extends Component {
  constructor (props) {
    super(props)

    this.state = {
      index: 0,
      translate: 0,
      translation: 0
    }

    this.videos = []
    this.carouselRef = React.createRef()
    this.handleScrolling = this.handleScrolling.bind(this)
    this.scroll = this.scroll.bind(this)
    this.scrollTo = this.scrollTo.bind(this)
    this.updateDimensions = this.updateDimensions.bind(this)
  }

  componentDidMount () {
    window.addEventListener('resize', this.updateDimensions)
    if (this.props.imgs.length) {
      this.setState(() => ({
        translate: (this.carouselRef.current.offsetWidth / 7) * 3,
        translation: this.carouselRef.current.offsetWidth / 7
      }))
    }
  }

  componentWillUnmount () {
    window.removeEventListener('resize', this.updateDimensions)
  }

  updateDimensions () {
    this.setState(() => ({
      translate: (this.carouselRef.current.offsetWidth / 7) * 3,
      translation: this.carouselRef.current.offsetWidth / 7
    }))
    this.carouselRef.current.scrollLeft = this.carouselRef.current.offsetWidth
  }

  scroll (dir) {
    if (dir === 'left') {
      this.carouselRef.current.scrollLeft -= this.carouselRef.current.offsetWidth
    } else {
      this.carouselRef.current.scrollLeft += this.carouselRef.current.offsetWidth
    }
  }

  scrollTo (index) {
    this.carouselRef.current.scrollLeft =
      this.carouselRef.current.offsetWidth * (index + 1)
  }

  videoTracking (action, category, label) {
    trackingHelpers.track('sb.track', action, category, label)
  }

  handleScrolling () {
    this.videos.forEach(video => video.pauseVideo())
    const scrollPosition = this.carouselRef.current.scrollLeft / this.carouselRef.current.offsetWidth - 1
    if (scrollPosition === -1) this.scrollTo(this.props.imgs.length - 1)
    if (scrollPosition === this.props.imgs.length) this.scrollTo(0)
    const roundedScrollPosition = Math.round(scrollPosition)
    if (roundedScrollPosition !== this.state.index && roundedScrollPosition + 1 > 0 && roundedScrollPosition < this.props.imgs.length && Number.isInteger(scrollPosition)) {
      const { imgs, hotelName, trackingCategory } = this.props
      const roomName = imgs[roundedScrollPosition].caption
      const trackingAction = roomName || hotelName
      trackingHelpers.track('sb.track', trackingAction, trackingCategory, `${roundedScrollPosition + 1}/${imgs.length}`)
      this.setState(() => ({
        index: roundedScrollPosition,
        translate: this.state.translation * (3 - roundedScrollPosition)
      }))
    }
  }

  render () {
    const {
      buttons,
      counter,
      imgs,
      imgOnly,
      indicators,
      name,
      onClick,
      preview,
      startPosition
    } = this.props
    if (!imgs.length) {
      return null
    }

    const firstImg = imgs[0]
    const lastImg = imgs[imgs.length - 1]
    return (
      <div className='sw-carouselParent'>
        <div className={`sw-carouselContainer ${preview && imgs.length > 1 ? 'sw-preview' : ''}`}>
          <div
            className='sw-carousel mb-0'
            onClick={() => onClick && onClick(this.state.index + 1)}
            onScroll={this.handleScrolling}
            onLoad={() => {
              this.scrollTo(startPosition - 1 || 0)
            }}
            ref={this.carouselRef}
            role={imgOnly && 'button'}
            style={{ overflowX: `${imgs.length === 1 ? 'hidden' : ''}` }}
          >
            <div className='sw-imgContainer'>
              <ResponsiveImage lazyload={false} alt={`${name} gallery image ${imgs.length - 1}`} src={lastImg.src} />
            </div>
            {imgs.map((img, index) => {
              return !img.id || imgOnly ? (
                <div tabIndex={0} key={index} className='sw-imgContainer'>
                  <span className='sr-only'>{`${name} gallery image ${index + 1}`}</span>
                  <ResponsiveImage lazyload={false} alt={`${name} gallery image ${index + 1}`} src={img.src} />
                </div>
              ) : (
                <Youtube
                  videoId={img.id}
                  containerClassName={`sw-videoContainer ${imgs.length === 1 ? 'pb-0' : ''}`}
                  opts={{
                    height: '100%',
                    width: '100%',
                    playerVars: {
                      autoplay: (startPosition - 1 === index || imgs.length === 1) && 1
                    }
                  }}
                  onPlay={() => this.videoTracking('Play', 'Youtube', img.id)}
                  onPause={() => this.videoTracking('Pause', 'Youtube', img.id)}
                  onReady={e => this.videos.push(e.target)}
                />
              )
            })}
            <div className='sw-imgContainer'>
              <ResponsiveImage lazyload={false} alt={`${name} gallery image 1`} src={firstImg.src} />
            </div>
          </div>
          {buttons && imgs.length > 1 && !query.isXs() && (
            <React.Fragment>
              <label className='sw-arrow sw-left' onClick={() => this.scroll('left')}>
                <svg
                  aria-hidden='true'
                  role='presentation'
                  fill='currentColor'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <path d='M0 0h24v24H0z' fill='none' />
                  <path d='M20 11H7.83l5.59-5.59L12 4l-8 8 8 8 1.41-1.41L7.83 13H20v-2z' />
                </svg>
                <span className='sr-only'>Previous</span>
              </label>
              <label className='sw-arrow sw-right' onClick={() => this.scroll('right')}>
                <svg
                  aria-hidden='true'
                  role='presentation'
                  fill='currentColor'
                  xmlns='http://www.w3.org/2000/svg'
                  width='24'
                  height='24'
                  viewBox='0 0 24 24'
                >
                  <path d='M0 0h24v24H0z' fill='none' />
                  <path d='M12 4l-1.41 1.41L16.17 11H4v2h12.17l-5.58 5.59L12 20l8-8z' />
                </svg>
                <span className='sr-only'>Next</span>
              </label>
            </React.Fragment>
          )}
          {counter && imgs.length > 1 && (
            <div className='sw-counter'>
              {`${this.state.index + 1} of ${imgs.length}`}
            </div>
          )}
          {indicators && imgs.length > 1 && (
            <ol
              className={`${preview ? 'sw-preview' : 'sw-pip'}-indicators`}
              ref={this.indicatorsRef}
            >
              {imgs.map((img, index) => {
                const indicatorClassNames = classNames({
                  'sw-active-indicator': this.state.index === index,
                  'sw-pip-indicator': !preview,
                  'sw-preview-indicator': preview
                })
                return (
                  <li
                    className={indicatorClassNames}
                    style={{
                      transform: `translateX(${
                        imgs.length > 7 && preview ? this.state.translate : '0'
                      }px`,
                      backgroundImage: `${preview ? `url(${img.src}?w=300)` : ''}`
                    }}
                    key={index}
                    onClick={() => this.scrollTo(index)}
                  />
                )
              })}
            </ol>
          )}
        </div>
      </div>
    )
  }
}

Carousel.propTypes = {
  buttons: PropTypes.bool,
  counter: PropTypes.bool,
  hotelName: PropTypes.string,
  imgs: PropTypes.array,
  imgOnly: PropTypes.bool,
  indicators: PropTypes.bool,
  name: PropTypes.string,
  onClick: PropTypes.func,
  preview: PropTypes.bool,
  startPosition: PropTypes.number,
  trackingCategory: PropTypes.string
}

export default Carousel
