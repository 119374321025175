import _ from 'lodash'
import config from '../configs/config'

const {
  harvestBasketData
} = config

const bestPriceGuarantee = (state, action) => {
  state = state || {
    hasBestPriceGuarantee: false
  }

  switch (action.type) {
    case 'GET_MOREINFORMATION_PAGE_DATA':
    case 'GET_PAYMENT_PAGE_DATA':
    case 'GET_UPGRADES_PAGE_DATA': {
      const hasBestPriceGuarantee = _.get(harvestBasketData, 'ticket.hasBestPriceGuarantee') === 'true'
      return Object.assign({}, state, {
        hasBestPriceGuarantee
      })
    }

    default:
      return state
  }
}

export default bestPriceGuarantee
