import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGTicket extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <path d='M5.484 6.984c0.844 0 1.5-0.656 1.5-1.5s-0.656-1.5-1.5-1.5-1.5 0.656-1.5 1.5 0.656 1.5 1.5 1.5zM21.422 11.578c0.375 0.375 0.563 0.844 0.563 1.406s-0.188 1.031-0.563 1.406l-7.031 7.031c-0.375 0.375-0.844 0.563-1.406 0.563s-1.031-0.188-1.406-0.563l-9-9c-0.375-0.375-0.563-0.844-0.563-1.406v-7.031c0-1.078 0.891-1.969 1.969-1.969h7.031c0.563 0 1.031 0.188 1.406 0.563z' />
      </SVG>
    )
  }
}

SVGTicket.propTypes = SVG.propTypes

SVGTicket.defaultProps = {
  title: 'Ticket Icon'
}

export default SVGTicket
