import React, { useContext } from 'react'

import BrandContext from '../../contexts/BrandContext'

import trackingHelpers from '../../helpers/trackingHelpers'

/**
 * getTitle - Returns a string for the title to display
 *
 * @param {boolean} [isCallCentre=false] flag to know if we are displaying this to the call centre or not
 * @param {boolean} [resortWording=false]
 * @return {string} The title to show
 */
const getTitle = (isCallCentre = false, resortWording = false) => {
  if (isCallCentre) return 'Resort Server Issue'
  if (resortWording) return 'Sorry, our resort hotels are currently unavailable'
  return 'Sorry, our site is undergoing essential maintenance'
}

/**
 * getContent - Returns a string based on brand and adds the URL in it
 *
 * @param {string} brand Used to display a different piece depending on what is passed in
 * @param {string} callbackFormUrl URL to display as a link to the callback form
 * @param {string} helpCentreUrl URL to display as a link to the callback form
 * @param {boolean} [isCallCentre=false] flag to know if we are displaying this to the call centre or not
 * @param {string} parkName Used to display a different name depending on what is passed in
 * @param {boolean} [resortWording=false]
 * @return {string} The content to show
*/
const getContent = ({
  brand,
  callbackFormUrl,
  customerCanLeaveDetails = true,
  helpCentreUrl,
  isCallCentre = false,
  parkName,
  resortWording = false
}) => {
  if (isCallCentre) {
    return (
      <div>
        <p>There is currently an issue with the resort hotel property management server.</p>
        <p>Customers may be populating the callback form with their details for callbacks.&nbsp;
          <strong>Please ensure your team leader is aware</strong>
        </p>
      </div>
    )
  }

  const callbackFormLink = (
    <a
      {...trackingHelpers.getAttributes('No Availability Callback', 'Availability', callbackFormUrl)}
      href={callbackFormUrl}
      target='_blank'>
      leave your details here
    </a>
  )

  // Specific wording for resort
  if (resortWording) {
    return (
      <div>
        <p>
          This means we're unable to make or amend any resort bookings online or via the phone.
        </p>

        <p>
          {customerCanLeaveDetails &&
            <React.Fragment>
              If you would like one of our Guest Experience team to contact you when our resort hotels are available then please {callbackFormLink}.
            </React.Fragment>
          }
        </p>
      </div>
    )
  }

  let breakType = 'short break'
  switch (brand) {
    case 'AB':
    case 'AT':
      breakType = 'fantabulous short break'
      break
    case 'CH':
      breakType = 'wild adventure'
  }

  let helpCentreLinkString
  if (helpCentreUrl) {
    helpCentreLinkString = (
      <React.Fragment>
        If you have general queries, our <a target='_blank' href={helpCentreUrl} {...trackingHelpers.getAttributes('Help Centre Link', 'Availability', helpCentreUrl)}>Help Centre</a> is the best place to go for the most up to date information about your Short Break and for answers to the most frequently asked questions.
      </React.Fragment>
    )
  }

  return (
    <div>
      <p>
        Unfortunately, this means you won't be able to book your {breakType} with {parkName} just yet.
        Our Guest Experience Team are also unable to make bookings, check availability or make amendments to existing bookings.
      </p>
      <p>
        We aim to be back up and running shortly, so please try again soon.
      </p>

      <p>
        {helpCentreLinkString}
        {customerCanLeaveDetails &&
          <React.Fragment>
            {' '}If you would like one of our Guest Experience
            team to contact you, please {callbackFormLink} and we will get back to you as soon as we can. If you've left
            your details we will contact you shortly.
          </React.Fragment>
        }
      </p>

    </div>
  )
}

/**
 * CallbackForm - Returns the rendered HTML
 *
 * @param {Object} props {isCallCentre}
 * @return {HTMLElement} HTML
 */
const CallbackForm = () => {
  const { brandConfig, isCallCentre } = useContext(BrandContext)

  const { brand, parkName, secure = {} } = brandConfig || {}
  const callbackForm = (secure.hasFeatures && secure.hasFeatures.callbackForm) || {}
  const callbackFormUrl = callbackForm.url || ''
  const customerCanLeaveDetails = callbackForm.customerCanLeaveDetails || false
  const helpCentreUrl = secure.helpCentreUrl || ''
  const resortWording = callbackForm.resortWording || false

  if (!brand || !callbackFormUrl || !parkName) return null
  const title = getTitle(isCallCentre, resortWording)
  const content = getContent({
    brand,
    callbackFormUrl,
    customerCanLeaveDetails,
    helpCentreUrl,
    isCallCentre,
    parkName,
    resortWording
  })

  return (
    <div className='panel panel-default'>
      <div className='panel-body'>
        {resortWording
          ? <p>
            <strong>{title}</strong>
          </p>
          : <h1>{title}</h1>
        }
        {content}
      </div>
    </div>
  )
}

export default CallbackForm
