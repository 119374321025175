import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGClock extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <path d='M12.516 6.984v5.25l4.5 2.672-0.75 1.266-5.25-3.188v-6h1.5zM12 20.016c4.406 0 8.016-3.609 8.016-8.016s-3.609-8.016-8.016-8.016-8.016 3.609-8.016 8.016 3.609 8.016 8.016 8.016zM12 2.016c5.531 0 9.984 4.453 9.984 9.984s-4.453 9.984-9.984 9.984-9.984-4.453-9.984-9.984 4.453-9.984 9.984-9.984z' />
      </SVG>
    )
  }
}

SVGClock.propTypes = SVG.propTypes

SVGClock.defaultProps = {
  title: 'Clock Icon'
}

export default SVGClock
