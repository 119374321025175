const ratesHelpers = {}

/**
 * Check the vailidity of a given ratesReply
 * @return {Object} An object of a ratesReply (e.g. packageRates or upgradeRates)
 */
ratesHelpers.isValid = (ratesReply) => {
  return Object.keys(ratesReply || {}).length > 0 && ![404, 500].includes(ratesReply.statusCode)
}

/**
 * Get the relevant event product id for a given package rate
 * @return {String} A 6 character string representing the event product id
 */
ratesHelpers.getEventProductIdForPackageRate = (packageRate, packageRatesContext) => {
  const eventProductIds = _.get(packageRate, ['links', 'eventProducts', 'ids'], [])
  if (!eventProductIds.length) return null
  if (eventProductIds.length === 1) return eventProductIds[0]
  const ticketBucketContext = _.get(packageRatesContext, ['ticketRates', 'bucket'])
  const eventProductIdForContext = eventProductIds.find(eventProductId => eventProductId.substring(0, 3) === ticketBucketContext)
  return eventProductIdForContext || eventProductIds[0]
}

/**
 * Get the relevant ticket rate id for a given package rate
 * @return {String} A 32 character hash representing the ticket rate id
 */
ratesHelpers.getTicketRateIdForPackageRate = (packageRate, packageRatesContext, linkedTicketRates = {}) => {
  const ticketRateIds = _.get(packageRate, ['links', 'ticketRates', 'ids'], [])
  if (!ticketRateIds.length) return null
  if (ticketRateIds.length === 1) return ticketRateIds[0]
  const ticketBucketContext = _.get(packageRatesContext, ['ticketRates', 'bucket'])
  const ticketRateIdForContext = Object.keys(linkedTicketRates).find(ticketRateId => {
    const ticketRate = linkedTicketRates[ticketRateId]
    return ticketRateIds.includes(ticketRateId) && (ticketRate.bucket === ticketBucketContext)
  })
  return ticketRateIdForContext || ticketRateIds[0]
}

export default ratesHelpers
