const tracking = {
  track: (action, category, label) => {
    return dispatch => {
      if (window && window.tracker && window.tracker.inline) {
        tracker.inline('click', {
          name: `${category}::${action}`,
          value: '' + label
        })
      }
      return dispatch({
        action,
        category,
        event: 'sb.track',
        label,
        type: 'TRACKING_TRACK'
      })
    }
  },
  error: (action, category, label) => {
    return dispatch => {
      return dispatch({
        action,
        category,
        event: 'sb.error',
        label,
        type: 'TRACKING_ERROR'
      })
    }
  }
}

export default tracking
