import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import ExtraNight from '../molecules/ExtraNight'
import ExtraNightV2 from '../molecules/ExtraNightV2'

class ExtraNightsBlock extends PureComponent {
  render () {
    return (
      this.props.listLayout
        ? <div className='product-list product-list-divided product-list-secondary'>
          <ExtraNightV2
            data={this.props.extraNights.before}
            handleExtraNightCTA={this.props.handleExtraNightCTA}
            hotelCode={this.props.hotelCode}
            hotelImage={this.props.hotelImage}
            hotelName={this.props.hotelName}
            isNightBefore
          />
          <ExtraNightV2
            data={this.props.extraNights.after}
            handleExtraNightCTA={this.props.handleExtraNightCTA}
            hotelCode={this.props.hotelCode}
            hotelImage={this.props.hotelImage}
            hotelName={this.props.hotelName}
          />
        </div>
        : <ul className='list-group extraNights'>
          <ExtraNight
            data={this.props.extraNights.before}
            handleExtraNightCTA={this.props.handleExtraNightCTA}
            hotelCode={this.props.hotelCode}
            isNightBefore
            extraNightButtonSmallSize={this.props.extraNightButtonSmallSize}
          />
          <ExtraNight
            data={this.props.extraNights.after}
            handleExtraNightCTA={this.props.handleExtraNightCTA}
            hotelCode={this.props.hotelCode}
            isNightBefore={false}
            extraNightButtonSmallSize={this.props.extraNightButtonSmallSize}
          />
        </ul>
    )
  }
}

ExtraNightsBlock.propTypes = {
  extraNights: PropTypes.shape({
    after: PropTypes.object,
    before: PropTypes.object
  }).isRequired,
  handleExtraNightCTA: PropTypes.func.isRequired,
  hotelCode: PropTypes.string.isRequired,
  hotelImage: PropTypes.string,
  extraNightButtonSmallSize: PropTypes.bool,
  listLayout: PropTypes.bool.isRequired
}

export default ExtraNightsBlock
