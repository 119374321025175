import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

class BasketEventItem extends PureComponent {
  render () {
    return (
      <div className='basket-event-summary'>
        {this.props.title && <h3>{this.props.title}</h3>}
        <div className='basket-event-summary__details'>
          {this.props.name}
          {this.props.dates}
          <div>
            <strong><FormattedMessage id='common.guests' />: </strong>
            {this.props.partyComposition}
          </div>
        </div>
      </div>
    )
  }
}

BasketEventItem.propTypes = {
  dates: PropTypes.element,
  name: PropTypes.element,
  partyComposition: PropTypes.string,
  title: PropTypes.string
}

export default BasketEventItem
