import _ from 'lodash'
import basketHelpers from '../helpers/basketHelpers'
import config from '../configs/config'
const { harvestBasketData } = config

const brand = (state = {
  hotelPartyString: '',
  ticketPartyString: ''
}, action) => {
  switch (action.type) {
    case 'GET_AVAILABILITY_PAGE_DATA': {
      let { roomRates = {}, ticketRates = {} } = _.get(window, 'packageRatesReply.meta.context', {})
      roomRates = basketHelpers.maybeConvertPartyComp(roomRates)
      ticketRates = basketHelpers.maybeConvertPartyComp(ticketRates)
      return Object.assign({}, state, {
        hotelPartyString: basketHelpers.getComposition({
          adults: roomRates.adults || 0,
          children: roomRates.children || 0,
          babies: roomRates.infants || 0
        }),
        ticketPartyString: basketHelpers.getComposition({
          adults: ticketRates.adults || 0,
          children: ticketRates.children || 0,
          infants: ticketRates.infants || 0
        })
      })
    }

    case 'GET_MOREINFORMATION_PAGE_DATA':
    case 'GET_PAYMENT_PAGE_DATA':
    case 'GET_UPGRADES_PAGE_DATA': {
      let { hotel = {}, ticket = {} } = harvestBasketData
      hotel = basketHelpers.maybeConvertPartyComp(hotel)
      ticket = basketHelpers.maybeConvertPartyComp(ticket)
      return Object.assign({}, state, {
        hotelPartyString: basketHelpers.getComposition({
          adults: hotel.adults || 0,
          children: hotel.children || 0,
          babies: hotel.infants || 0
        }),
        ticketPartyString: basketHelpers.getComposition({
          adults: ticket.adults || 0,
          children: ticket.children || 0,
          infants: ticket.infants || 0
        })
      })
    }
    default:
      return state
  }
}

export default brand
