import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import SVGChat from '../atoms/SVGChat'
import SVGClock from '../atoms/SVGClock'
import SVGPhone from '../atoms/SVGPhone'

import trackingHelpers from '../../helpers/trackingHelpers'

import BrandContext from '../../contexts/BrandContext'

class ContactInformation extends PureComponent {
  render () {
    const agent = this.context.agent
    const brandConfig = this.context.brandConfig
    const {
      callCostPerMinute,
      ticketOnlyCallCostPerMinute,
      ticketOnlyTelephoneNumber,
      numberAgentOverrides
    } = brandConfig
    const helpCentreUrl = _.get(brandConfig, 'secure.helpCentreUrl')
    const contact = _.get(brandConfig, 'secure.hasFeatures.contact', {})
    const isLiveChatEnabled = contact.liveChat || false
    const helpCentreLink = (<a target='_blank' href={helpCentreUrl}><FormattedMessage id='common.help' /></a>)
    const telNumberOverride = numberAgentOverrides && numberAgentOverrides[agent]
    const telephoneNumber = contact.telephone && (telNumberOverride || brandConfig.number)

    return (
      <article>
        {this.props.contactMessage && (<p><strong>{this.props.contactMessage}</strong></p>)}
        {helpCentreUrl && (
          <div className='help-centre'>
            <FormattedMessage id='common.help' tagName='h5' />
            <FormattedMessage id='common.helpCentreText' tagName='p' values={{ helpCentreLink }} />
          </div>
        )}
        {isLiveChatEnabled && (
          <div>
            <h5>
              <SVGChat
                title='Chat'
                uniqueID='Contact Chat Icon'
              /> Live Chat
            </h5>
            {this.props.isLiveChatAvailable ? (
              <p>
                Chat in real time to one of our sales specialists <a
                  className='hoverable'
                  {...trackingHelpers.getAttributes('Click', 'Live Chat', 'Chat')}
                  onClick={this.props.handleOpenLiveChat}
                  title='Open Live Chat'>
                  on Live Chat
                </a>
              </p>
            ) : (
              <p>Our Customer Experience Team are not available on Live Chat at the moment.</p>
            )}
          </div>
        )}
        {telephoneNumber && (
          <div>
            <h5>
              <SVGPhone
                title='Phone'
                uniqueID='Contact Phone Icon'
              /> Hotel And Ticket Packages
            </h5>
            <p>
              <a href={`tel:${telephoneNumber}`} title='call us'>{telephoneNumber}</a>
              {agent && (<span> quoting <strong>{agent}</strong><br /></span>)}
              <small>calls cost {callCostPerMinute} plus your phone company&#39;s access charge.</small>
            </p>
          </div>
        )}
        <h5>
          <SVGClock
            title='Hours'
            uniqueID='Contact Hours Icon'
          /> Customer Experience Opening Hours</h5>
        <ul>
          <li>9am to 9pm - Monday to Friday (including Bank Holidays)</li>
          <li>9am to 5:30pm - Saturday & Sunday</li>
        </ul>
        {ticketOnlyTelephoneNumber && (
          <div>
            <h5>
              <SVGPhone
                title='Phone'
                uniqueID='Contact Phone Icon'
              /> Ticket Only
            </h5>
            <p>
              <a href={`tel:${ticketOnlyTelephoneNumber}`} title='call us'>
                {ticketOnlyTelephoneNumber}
              </a>
              {ticketOnlyCallCostPerMinute &&
                <span>
                  <br />
                  <small>Calls cost {ticketOnlyCallCostPerMinute} plus your phone company&#39;s access charge.</small>
                </span>
              }
            </p>
          </div>
        )}
      </article>
    )
  }
}

ContactInformation.contextType = BrandContext

ContactInformation.propTypes = {
  contactMessage: PropTypes.string,
  handleOpenLiveChat: PropTypes.func.isRequired,
  isLiveChatAvailable: PropTypes.bool.isRequired
}

ContactInformation.defaultProps = {
  isLiveChatAvailable: false
}

export default ContactInformation
