import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import trackingHelpers from '../../helpers/trackingHelpers'

class ProgressTrackerStep extends PureComponent {
  render () {
    const label = (
      <span>
        {this.props.children}
        {this.props.name}
        {this.props.isActive && <span className='sr-only'> (current step)</span>}
      </span>
    )
    const step = this.props.handleAction ? (
      <a
        className='hoverable'
        onClick={this.props.handleAction}
        onKeyDown={(e) => e.key === 'Enter' && this.props.handleAction()}
        role='button'
        tabIndex='0'>
        {label}
      </a>
    ) : (<div>{label}</div>)
    const stepClass = this.props.isActive ? 'active' : null

    return (
      <li
        className={stepClass}
        {...trackingHelpers.getAttributes('Breadcrumb link clicked', 'Breadcrumbs', this.props.name)}>
        {step}
      </li>
    )
  }
}

ProgressTrackerStep.propTypes = {
  handleAction: PropTypes.func,
  isActive: PropTypes.bool.isRequired,
  name: PropTypes.string.isRequired
}

ProgressTrackerStep.defaultProps = {
  isActive: false
}

export default ProgressTrackerStep
