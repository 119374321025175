import _ from 'lodash'
import moment from 'moment'
import config from '../configs/config'

import dateHelpers from './dateHelpers'
import resourceHelpers from './resourceHelpers'
import sortingHelpers from './sortingHelpers'

const upgradesHelpers = {
  upgradeMapper: function () {
    const {
      autoBundleUpgradeSummaryVisibilityByTicket = false,
      groupUpgradesByTag: groupByTags,
      imagesDomain,
      upgradeGroups,
      upgradeOrder
    } = (config.brandConfig && config.brandConfig.secure) || {}
    const {
      harvestBasketData,
      upgradeRatesReply,
      upgradeCompositionRequirements,
      upgradeIsNightsBasedConfig,
      upgradeIsRoomBasedConfig = {
        AMJTB5: true,
        AMJTB6: true
      }
    } = config || {}

    if (!harvestBasketData ||
        !upgradeRatesReply ||
        (upgradeRatesReply.upgradeRates || []).length === 0 ||
        !upgradesHelpers._hasUpgradeRates(upgradeRatesReply.upgradeRates)) {
      return []
    }

    // use slice to sort a copy of the upgradeRatesReply.upgradeRates
    const sortedUpgradeRates = upgradeRatesReply.upgradeRates.slice()
      .sort(sortingHelpers.sortByOrderArray(upgradeOrder, 'code'))
      // WEB-9263 remove the postal confirmation supplement from the upgrades page
      .filter(upgrade => upgrade.code !== 'SPOSBP')

    const groupedUpgrades = upgradesHelpers._groupUpgrades(sortedUpgradeRates, upgradeRatesReply.linked.upgradeProducts, upgradeRatesReply.linked.groupProducts, groupByTags, upgradeGroups)

    // @todo: HACK FOR CHESS SANTA GROTTO WORK . NEEDS TO BE REMOVED AFTERWARDS (FP-10832)
    // Chess Grotto Groups are the only ones to be featured under 'Included in your break...'
    const isThereAChessGrottoGroup = Object.keys(groupedUpgrades).some(groupedUpgrade => groupedUpgrade.startsWith('GROUPCHESSINGTONUPGRADEGROTTO'))
    const selectedTicket = _.get(harvestBasketData, 'ticket.id', '').substring(3, 6)
    return Object.keys(groupedUpgrades).map(code => {
      const groupedUpgrade = groupedUpgrades[code]
      const isUpgradeGroup = code.startsWith('GROUP')
      const upgradeProducts = upgradesHelpers._getProductsForDefaultUpgradeInGroup(groupedUpgrade, isUpgradeGroup, code)
      const groupedUpgradeIds = upgradeProducts.map(upgradeProduct => upgradeProduct.id)
      const upgradeProduct = upgradeProducts[0]

      const isFeatured = productId => productId === upgradeProduct.id
      const isAdultRequired = _.get(upgradeCompositionRequirements, [upgradeProduct.id], true)
      const isNightsBased = (upgradeIsNightsBasedConfig && upgradeIsNightsBasedConfig[upgradeProduct.id]) || false
      const isRoomBased = (upgradeIsRoomBasedConfig && upgradeIsRoomBasedConfig[upgradeProduct.id]) || false
      const rooms = (harvestBasketData.rooms || []).length
      const nights = moment(harvestBasketData.hotel.checkoutDate).diff(moment(harvestBasketData.hotel.checkinDate), 'days')
      let quantity = null
      if (isNightsBased && nights > 1) quantity = nights
      if (isRoomBased) quantity = rooms

      let upgrade = {
        composition: {
          adults: Number(window.adults),
          cars: null,
          children: Number(window.children),
          infants: Number(window.infants),
          quantity
        },
        compositionBoundaries: {
          min: {
            adults: isAdultRequired ? 1 : 0
          },
          max: {}
        },
        featured: isThereAChessGrottoGroup ? code.startsWith('GROUPCHESSINGTONUPGRADEGROTTO') : (Object.keys(config.featuredUpgrades).find(isFeatured) !== undefined),
        group: code,
        groupIds: groupedUpgradeIds,
        id: upgradeProduct.id,
        imageBasePath: `${imagesDomain}`,
        isAdultRequired,
        isAvailableForAllSelectedDates: false,
        isInBasket: false,
        isIncludedInPackage: upgradeProduct.isIncludedInPackage,
        isNightsBased,
        isRoomBased,
        isUpgradeGroup,
        selectedProductId: upgradeProduct.id,
        showUpgradeDate: true,
        isSummaryVisibleUpgrade: !isNightsBased
      }

      if (upgradeProduct.isIncludedInPackage && !isNightsBased && autoBundleUpgradeSummaryVisibilityByTicket) {
        upgrade.isSummaryVisibleUpgrade = _.get(upgradeProduct, 'ticketsToDisplayAutoBundleMessageInSummary', []).includes(selectedTicket)
      }
      upgradesHelpers.applyProductInfo(upgrade, upgradeProduct)

      if (isUpgradeGroup) {
        upgrade.groupInfo = groupedUpgrade
        upgradesHelpers.applyGroupUpgrades(upgrade, groupedUpgrades[code])
        upgradesHelpers.applyGroupProductInfo(upgrade, upgradeRatesReply.linked.groupProducts[code])
      } else {
        upgradesHelpers.applyGroupProducts(upgrade, upgradeRatesReply)
      }

      // Set days options
      upgradesHelpers.applyUpgradeDays(upgrade, upgradeRatesReply.upgradeRates)

      // Set dates options
      upgradesHelpers.applyUpgradeRateDates(upgrade, upgradeRatesReply.upgradeRates, harvestBasketData.ticket)

      const updatedUpgrade = upgradesHelpers.getUpgradeRate({
        upgradeRates: upgradeRatesReply.upgradeRates,
        id: upgrade.selectedDays || upgrade.id,
        date: upgrade.selectedDate
      })
      upgradesHelpers.applyUpgradeRate(upgrade, updatedUpgrade)

      if (isUpgradeGroup) {
        const lowestUpgradeRateForGroup = upgradesHelpers.getLowestUpgradeRateInGroup(upgradeRatesReply.upgradeRates, upgrade.group)
        const isPriceSameForAllUpgradesInGroup = upgradesHelpers.isPriceSameForAllUpgradesInGroup(lowestUpgradeRateForGroup.grossPrice, upgradeRatesReply.upgradeRates, upgrade.group)
        upgradesHelpers.applyLowestGroupUpgradeRate(upgrade, lowestUpgradeRateForGroup, isPriceSameForAllUpgradesInGroup)
      }

      return upgradesHelpers.applyRequirements(upgradeRatesReply, upgrade)
    })
  },

  _getProductsForDefaultUpgradeInGroup: function (groupedUpgrade, isUpgradeGroup, code) {
    if (!isUpgradeGroup) return groupedUpgrade

    const firstGroupedUpgradeKey = Object.keys(groupedUpgrade)[0]
    if (firstGroupedUpgradeKey === 'upgrades') {
      if (code.includes('GROUPTHORPEUPGRADEBREAKFAST') || code.includes('GROUPWARWICKUPGRADEBREAKFAST')) {
        return Object.keys(groupedUpgrade.upgrades || {}).map(key => groupedUpgrade.upgrades[key][0])
      }
      const firstNonTagUpgradeKey = Object.keys(groupedUpgrade.upgrades)[0]
      return groupedUpgrade.upgrades[firstNonTagUpgradeKey]
    }

    return groupedUpgrade[firstGroupedUpgradeKey]
  },

  _getProductsForUpgradeInGroup: function (groupedUpgrade, upgradeId) {
    return Object.keys(groupedUpgrade).reduce((result, groupedUpgradeKey) => {
      const groupedUpgradeValue = groupedUpgrade[groupedUpgradeKey]
      if (groupedUpgradeKey === 'upgrades' && groupedUpgradeValue[upgradeId]) {
        result.push(...groupedUpgradeValue[upgradeId])
        return result
      }

      const idPresentInTagOrCodeGroup = groupedUpgradeValue.find((upgradeProduct) => upgradeProduct.id === upgradeId)
      if (idPresentInTagOrCodeGroup) {
        result.push(...groupedUpgradeValue)
      }
      return result
    }, [])
  },

  applyGroupUpgrades: function (upgrade, group) {
    const groupUpgrades = Object.keys(group).reduce((result, groupKey) => {
      const groupElement = group[groupKey]
      if (groupKey === 'upgrades') {
        const upgradeProducts = Object.keys(groupElement).reduce((upgradeProductsResult, groupElementKey) => {
          const upgradeElement = groupElement[groupElementKey]
          upgradeProductsResult.push(upgradeElement[0])
          return upgradeProductsResult
        }, [])
        result.push(...upgradeProducts)
      } else {
        result.push(groupElement[0])
      }
      return result
    }, [])
    upgrade.groupUpgradeProducts = groupUpgrades.map(groupUpgrade => {
      return {
        id: groupUpgrade.id,
        isInBasket: false,
        text: groupUpgrade.name,
        value: groupUpgrade.id
      }
    })
  },

  applyProductInfo: function (upgrade, product) {
    const imageFromPrismic = product.image && product.image.match(/\/?([^.]+)\.jpg/)
    Object.assign(upgrade, {
      title: product.nameVariant || product.name,
      strapline: product.strapline,
      description: product.description,
      additionalDescription: product.additionalDescription,
      additionalDescriptionCompact: product.additional_description_compact,
      image: resourceHelpers.generateImagePaths(upgrade.imageBasePath, 'upgrades/', (imageFromPrismic && imageFromPrismic[1]) || product.id)
    })
  },

  applyGroupProductInfo: function (upgrade, groupProduct) {
    Object.assign(upgrade, {
      groupTitle: groupProduct.name,
      groupDescription: groupProduct.description,
      groupImage: resourceHelpers.generateImagePaths(upgrade.imageBasePath, 'upgrades/', groupProduct.id)
    })
  },

  applyGroupProducts: function (upgrade, upgradeRatesReply) {
    const upgradeRate = upgradeRatesReply.upgradeRates.find(rate => rate.code === upgrade.id)
    const groupProductLinks = upgradeRate.links.groupProducts
    if (!groupProductLinks || !groupProductLinks.ids) return
    upgrade.groupProducts = groupProductLinks.ids.reduce((result, id) => {
      const groupProduct = upgradeRatesReply.linked.groupProducts[id]
      if (groupProduct) {
        result.push({
          id,
          title: groupProduct.name,
          groupDescription: groupProduct.description,
          groupImage: resourceHelpers.generateImagePaths(upgrade.imageBasePath, 'upgrades/', id)

        })
      }
      return result
    }, [])
  },

  // Find upgradeRate based on options
  getUpgradeRate: function ({ upgradeRates, id, date }) {
    if (!upgradesHelpers._hasUpgradeRates(upgradeRates) || typeof id === 'undefined') {
      return
    }

    return _.find(upgradeRates, upgradeRate => {
      return id === upgradeRate.code && (typeof date !== 'undefined' ? upgradeRate.startDate === date : true)
    })
  },

  getLowestUpgradeRateInGroup: function (upgradeRates, upgradeGroupId) {
    return upgradeRates.reduce((result, upgradeRate) => {
      const groupsForRate = _.get(upgradeRate, 'links.groupProducts.ids', [])
      if (!groupsForRate.find(groupForRate => groupForRate === upgradeGroupId)) return result
      if (isNaN(upgradeRate.grossPrice)) return result

      if (!result || upgradeRate.grossPrice < result.grossPrice) {
        result = upgradeRate
      }
      return result
    }, null)
  },

  isPriceSameForAllUpgradesInGroup: function (lowestUpgradeRateForGroup, upgradeRates, upgradeGroup) {
    return upgradeRates
      .filter(upgradeRate => _.get(upgradeRate, 'links.groupProducts.ids[0]') === upgradeGroup)
      .every(upgradeRate => upgradeRate.grossPrice === lowestUpgradeRateForGroup)
  },

  // Extend upgrade with days
  applyUpgradeDays: function (upgrade, upgradeRates) {
    const upgradeDays = upgradesHelpers.getSortedUpgradesDays(upgradeRates, upgrade)
    // Set days options
    if (upgradeDays) {
      // this will allow the days drop down to show in situations where there is 2 or more available days or 1 day variant is sold out
      if ((upgradeDays.length > 1 && upgradeDays.some(upgradeDay => upgradeDay.days > 1)) ||
          ((upgradeDays.length === 1 && upgradeDays[0].days > 1))) {
        upgrade.days = upgradeDays
      }
    }
  },

  // Extend upgrade with dates
  applyUpgradeRateDates: function (upgrade, upgradeRates, ticket, lastSelectedIfNone) {
    if (!ticket) return

    const ticketDates = dateHelpers.getRange(ticket.startDate, ticket.endDate)
    upgrade.dates = upgradesHelpers._getSortedUpgradesDates(upgradeRates, upgrade)
    // If we have the same length of dates this with a big certainty means that we have
    // match between the dates for the whole period during the ticket.
    // We also check if the first date is the ticket start date
    upgrade.isAvailableForAllSelectedDates = (upgrade.dates.length === ticketDates.length &&
      (upgrade.dates.length ? upgrade.dates[0] === ticketDates[0] : true))

    if (upgrade.dates.length && (!upgrade.selectedDate || upgrade.dates.indexOf(upgrade.selectedDate) === -1)) {
      upgrade.selectedDate = lastSelectedIfNone ? upgrade.dates[upgrade.dates.length - 1] : upgrade.dates[0]
    }
  },

  // Extend upgrade with price
  applyUpgradeRate: function (upgrade, upgradeRate) {
    if (!upgradeRate) return
    Object.assign(upgrade, {
      discountAmount: upgradeRate.discountAmount || null,
      discountType: upgradeRate.discountType || null,
      hideInfantsGoFreeMessage: upgradeRate.hideInfantsGoFreeMessage || false,
      packageId: upgradeRate.id,
      price: (+upgradeRate.grossPrice || 0).toFixed(2),
      selectedDate: upgradeRate.startDate || upgradeRate.selectedDate,
      standardPrice: (upgradeRate.standardPrice) ? +upgradeRate.standardPrice.toFixed(2) : null
    })
  },

  applyLowestGroupUpgradeRate: function (upgrade, upgradeRate, isPriceSameForAllUpgradesInGroup) {
    Object.assign(upgrade, {
      groupPrice: (upgradeRate.grossPrice || 0).toFixed(2),
      groupStandardPrice: (upgradeRate.standardPrice) ? upgradeRate.standardPrice.toFixed(2) : null,
      isPriceSameForAllUpgradesInGroup
    })
  },

  _hasUpgradeRates: upgradeRates => Array.isArray(upgradeRates) && upgradeRates.length > 0,

  // Calculates the duration between two dates in days
  // and return the days and the id of the product
  getSortedUpgradesDays: (upgradeRates, upgrade) => {
    if (!upgradesHelpers._hasUpgradeRates(upgradeRates) || !upgrade) {
      return []
    }
    const filteredUpgradeRates = upgradeRates.filter(upgradeRate => upgrade.groupIds.indexOf(upgradeRate.code) !== -1)
    return filteredUpgradeRates
      .reduce((res, upgradeRate) => {
        let days = dateHelpers.getDaysBetweenDates(upgradeRate.startDate, upgradeRate.endDate) + 1

        if (res.every(daysObj => daysObj.id !== upgradeRate.code)) {
          res.push({
            days,
            id: upgradeRate.code
          })
        }

        return res
      }, [])
      .sort(sortingHelpers.sortByProperty('days'))
  },

  // Extend upgrade with sorted dates array
  _getSortedUpgradesDates: (upgradeRates, upgrade) => {
    if (!upgradesHelpers._hasUpgradeRates(upgradeRates) || !upgrade) {
      return []
    }

    return upgradeRates.sort(sortingHelpers.sortByDate('startDate'))
      .filter(upgradeRate => upgrade.selectedProductId === upgradeRate.code)
      .map(updatedUpgrade => updatedUpgrade.startDate)
      .reduce((res, date) => {
        res.indexOf(date) === -1 && res.push(date)
        return res
      }, [])
  },

  /**
   * Group products by upgrade group if set in upgrade groups from Transformer Brand document,
   * by tags if the tag exists in the tags attribute
   * or by product code
   * @param {Object[]} upgradeRates - An array of objects containing the ordered upgradeRates.
   * @param {Object} upgradeProducts - upgradeProducts object containing content for upgrades.
   * @param {Object} groupProducts - groupProducts object containing content for groups.
   * @param {Object[]} groupByTags - An array of tag name strings to enable grouping by tag.
   * @param {Object[]} upgradeGroups - An array of group upgrade name strings to enable grouping by upgrade group.
   */
  _groupUpgrades: function (upgradeRates, upgradeProducts, groupProducts, groupByTags, upgradeGroups) {
    if (!upgradeRates || upgradeRates.length < 1) {
      return {}
    }
    return upgradeRates.reduce((result, upgradeRate) => {
      // there should only ever be a single upgradeProduct for an upgrade id, therefore select that using the upgradeRate code
      const upgradeProductForUpgradeRate = upgradeProducts[upgradeRate.code]
      if (!upgradeProductForUpgradeRate) {
        return result
      }

      // Attaching the property from upgradeRates reply to the upgradeProduct so we can use it in components.
      upgradeProductForUpgradeRate.isIncludedInPackage = upgradeRate.isIncludedInPackage

      // get an array of all enabled tags for grouping by comparing the tags on upgradeProducts with groupByTags
      const enabledTagsForUpgrade = upgradeProductForUpgradeRate.tags.reduce((enabledTags, upgradeProductTag) => {
        // the tag needs to be set in Transformer Brand document in order to group by tag
        if (groupByTags && !!groupByTags.find(groupByTag => groupByTag === upgradeProductTag)) {
          enabledTags.push(upgradeProductTag)
        }
        return enabledTags
      }, [])

      // find the groupProducts from the linked resource
      const groupProductIds = _.get(upgradeRate, 'links.groupProducts.ids', [])

      // check if it's a group upgrade and if that upgrade is part of a grouped product (prevent breaks when a Prismic grouped upgrade doc is tagged incorrectly)
      const hasGroupedProducts = groupProducts && Object.values(groupProducts).some(groupProduct => groupProductIds.includes(groupProduct.id))
      if (groupProductIds.length !== 0 && !hasGroupedProducts) {
        return result
      }

      // get an array of all enabled grouped upgrades by comparing linked resource with upgradeGroups
      const groupProductsForUpgradeRate = groupProductIds.reduce((groupProductsResult, groupProductId) => {
        // the upgrade group needs to be set in Transformer Brand document in order to group by upgrade group
        if (upgradeGroups && upgradeGroups.find(upgradeGroup => upgradeGroup === groupProductId)) {
          groupProductsResult.push(groupProducts[groupProductId])
        }
        return groupProductsResult
      }, [])

      // handling of all types of upgrades (grouped, tagged, standard) here
      // handle groups here
      if (groupProductsForUpgradeRate.length) {
        return groupProductsForUpgradeRate.reduce((groupResult, groupProduct) => {
          if (!groupResult[groupProduct.id]) {
            groupResult[groupProduct.id] = {}
          }
          let resultForGroup = groupResult[groupProduct.id]

          // handle tagged upgrades here
          if (enabledTagsForUpgrade.length > 0) {
            enabledTagsForUpgrade.reduce((upgradeTagResult, upgradeTag) => {
              (upgradeTagResult[upgradeTag] || (upgradeTagResult[upgradeTag] = [])).push(upgradeProductForUpgradeRate)
              return upgradeTagResult
            }, resultForGroup)
            return groupResult
          }

          if (!resultForGroup['upgrades']) {
            resultForGroup['upgrades'] = {}
          }

          // handle standard upgrades here
          (resultForGroup.upgrades[upgradeRate.code] || (resultForGroup.upgrades[upgradeRate.code] = [])).push(upgradeProductForUpgradeRate)
          return groupResult
        }, result)
      }

      // handle tagged upgrades here
      if (enabledTagsForUpgrade.length > 0) {
        return enabledTagsForUpgrade.reduce((upgradeTagResult, upgradeTag) => {
          (upgradeTagResult[upgradeTag] || (upgradeTagResult[upgradeTag] = [])).push(upgradeProductForUpgradeRate)
          return upgradeTagResult
        }, result)
      }

      // handle standard upgrades here
      (result[upgradeRate.code] || (result[upgradeRate.code] = [])).push(upgradeProductForUpgradeRate)
      return result
    }, {})
  },

  // Update the composition based on requirements
  applyRequirements: (upgradeRatesReply, product) => {
    const upgradeRate = upgradesHelpers.getUpgradeRate({
      upgradeRates: upgradeRatesReply.upgradeRates,
      id: product.selectedDays || product.id,
      date: product.selectedDate
    })
    if (!upgradeRate || !upgradeRate.links || !upgradeRate.links.requirements) return product
    const requirements = upgradeRate.links.requirements.ids.map(id => {
      return upgradeRatesReply.linked.requirements[id]
    })
    for (let requirement of requirements) {
      switch (requirement.name) {
        case 'parking':
        case 'quantityBased':
          let label = requirement.label || 'quantityBased'
          if (requirement.name === 'parking') {
            label = 'cars'
          }
          if (requirement.max) {
            product.compositionBoundaries.max[label] = requirement.max
          }
          // The number of adults represents the number of cars
          Object.assign(product.composition, {
            adults: 1,
            [label]: 1
          })
          product.compositionBoundaries.min[label] = 1
          break
        case 'adults':
          if (requirement.max) {
            product.composition.adults = requirement.max
          } else if (requirement.max === 0) {
            product.composition.adults = 0
          }
          break
        case 'children':
          if (requirement.max) {
            product.composition.children = requirement.max
          } else if (requirement.max === 0) {
            product.composition.children = 0
          }
          break
        case 'date':
          if (requirement.max === 0) {
            product.dates = []
            product.showUpgradeDate = false
          }
          break
        case 'quantity':
          if (requirement.max) {
            product.compositionBoundaries.max.quantity = requirement.max
          }
          // set all the things to 1
          Object.assign(product.composition, {
            adults: 1,
            quantity: product.isRoomBased ? product.composition.quantity : 1
          })
          product.compositionBoundaries.min.quantity = 1
          break
        default:
          break
      }
    }
    return product
  },

  getCompositionHash: composition => {
    const {
      adults,
      children,
      infants
    } = composition
    const cars = parseInt(composition.cars)
    const carsHash = `${(cars && cars > 1) ? `-${cars}` : ''}`
    const quantity = parseInt(composition.quantity) || parseInt(composition.quantityBased)
    const quantityHash = `${(quantity && quantity > 0) ? `-${quantity}` : ''}`
    return `${adults}-${children}-${infants}${carsHash}${quantityHash}`
  },

  multiplyPriceByQuantity: (price, quantity) => price * quantity,

  upgradeSelectedSwappableTicketChange: (upgrade, productId, swappableTickets) => {
    upgrade.selectedProductId = productId
    const newSwappableTicket = swappableTickets.find(swappableTicket => swappableTicket.id === productId)

    upgradesHelpers.applyProductInfo(upgrade, newSwappableTicket)

    if (!upgrade.isUpgradeGroup || upgradesHelpers._upgradeInDifferentTagOrCodeGroup(upgrade, productId)) {
      upgrade.id = upgrade.selectedProductId
    }

    if (upgrade.isUpgradeGroup) {
      const upgradeProducts = upgradesHelpers._getProductsForUpgradeInGroup(upgrade.groupInfo, upgrade.id)
      upgrade.groupIds = upgradeProducts.map(upgradeProduct => upgradeProduct.id)
    }

    upgradesHelpers.applyUpgradeRate(upgrade, newSwappableTicket)
  },

  upgradeSelectedProductChange: (upgrade, productId, upgradeRatesReply, ticket) => {
    // The productId is the id of another attraction
    upgrade.selectedProductId = productId
    upgradesHelpers.applyProductInfo(upgrade, _.get(upgradeRatesReply, ['linked', 'upgradeProducts', productId], null))

    if (!upgrade.isUpgradeGroup || upgradesHelpers._upgradeInDifferentTagOrCodeGroup(upgrade, productId)) {
      upgrade.id = upgrade.selectedProductId
    }

    // Generate dates array and add it like a property to the attraction
    upgradesHelpers.applyUpgradeRateDates(upgrade, upgradeRatesReply.upgradeRates, ticket)

    if (upgrade.isUpgradeGroup) {
      const upgradeProducts = upgradesHelpers._getProductsForUpgradeInGroup(upgrade.groupInfo, upgrade.id)
      upgrade.groupIds = upgradeProducts.map(upgradeProduct => upgradeProduct.id)
    }
    // Set days options
    upgradesHelpers.applyUpgradeDays(upgrade, upgradeRatesReply.upgradeRates)
  },

  _upgradeInDifferentTagOrCodeGroup: (upgradeGroup, upgradeId) => {
    const previousUpgradeId = upgradeGroup.id
    return Object.keys(upgradeGroup.groupInfo).reduce((result, groupedUpgradeKey) => {
      const groupedUpgradeValue = upgradeGroup.groupInfo[groupedUpgradeKey]
      if (groupedUpgradeKey === 'upgrades' && groupedUpgradeValue[upgradeId]) {
        return true
      }

      if (result) return result
      const previousUpgradePresent = !!groupedUpgradeValue.find((upgradeProduct) => upgradeProduct.id === previousUpgradeId)
      const newUpgradePresent = !!groupedUpgradeValue.find((upgradeProduct) => upgradeProduct.id === upgradeId)
      return (previousUpgradePresent !== newUpgradePresent)
    }, false)
  },

  addNightsBasedAutoBundledUpgrades: (availableUpgradeRates, upgradeRatesReply) => {
    const clonedRates = _.cloneDeep(availableUpgradeRates)
    const nightBasedRates = availableUpgradeRates.filter(rate => (rate.isNightsBased && rate.composition && rate.composition.quantity > 1))
    nightBasedRates.forEach(clonedRate => {
      for (let i = 1; i < clonedRate.composition.quantity; i++) {
        const updatedUpgrade = upgradesHelpers.getUpgradeRate({
          date: dateHelpers.addDays(clonedRate.selectedDate, i).format('YYYY-MM-DD'),
          id: clonedRate.selectedDays || clonedRate.id,
          upgradeRates: upgradeRatesReply.upgradeRates
        })
        if (updatedUpgrade) {
          const newUpgrade = Object.assign({}, clonedRate)
          upgradesHelpers.applyUpgradeRate(newUpgrade, updatedUpgrade)
          clonedRates.push(newUpgrade)
        }
      }
    })

    return clonedRates
  },
  getAdditionalUpgrades: (additionalPackageRatesReply, harvestBasketData) => {
    const currentTicketBucket = harvestBasketData.ticket.bucket
    const currentPackageTypeId = harvestBasketData.packageTypeId
    const searchFormId = _.get(additionalPackageRatesReply, 'meta.context.searchFormId')

    const checkinDate = harvestBasketData.hotel.checkinDate
    const checkoutDate = harvestBasketData.hotel.checkoutDate
    const nightBeforeDate = moment.utc(checkinDate).subtract(1, 'days').format('YYYY-MM-DD')
    const nightAfterDate = moment.utc(checkoutDate).add(1, 'days').format('YYYY-MM-DD')

    const allPackageRates = upgradesHelpers.getAdditionalUpgradesPackages(additionalPackageRatesReply)

    let currentId = currentTicketBucket
    let key = 'bucket'
    if (searchFormId && currentPackageTypeId) {
      currentId = currentPackageTypeId
      key = 'packageTypeId'
    }

    const packages = upgradesHelpers.structureResponse(allPackageRates, currentId, key, nightBeforeDate, nightAfterDate, checkinDate, checkoutDate)

    if (key === 'bucket') {
      // need to swap the key as the next function does not understand 'bucket'
      key = 'ticketBucket'
    }

    return packages.reduce((result, packageRate) => {
      const upgrade = upgradesHelpers.calculateAndSetAdditionalUpgrades(packageRate, currentId, key, harvestBasketData)
      const group = packageRate.group
      if (upgrade) {
        result[group] = result[group] || []
        result[group].push(upgrade)
      }
      return result
    }, [])
  },

  getAdditionalUpgradesPackages (additionalPackageRatesReply) {
    let allPackages = {}

    const allPackageRates = (additionalPackageRatesReply.packageRates || []).map(additionalPackageRate => {
      let result = Object.keys(additionalPackageRate.links).reduce((result, groupedUpgradeKey) => {
        const groupUpgradeValue = _.get(additionalPackageRate, ['links', groupedUpgradeKey], {})
        const groupUpgradeId = _.get(groupUpgradeValue, ['ids', 0], null)
        if (groupUpgradeId) {
          const rates = _.get(additionalPackageRatesReply.linked, [groupedUpgradeKey, groupUpgradeId], {})
          if (Object.entries(rates).length) {
            result = Object.assign({}, result, {
              [groupedUpgradeKey]: {
                [groupUpgradeId]: {
                  ...rates
                }
              }
            })
          }
        }
        return result
      }, [])
      const rooms = _.get(additionalPackageRatesReply.linked, ['rooms'], {})
      result = Object.assign({}, result, {
        rooms
      })

      const ticketRateId = _.get(additionalPackageRate, ['links', 'ticketRates', 'ids', 0], '')
      const bucket = _.get(additionalPackageRatesReply, ['linked', 'ticketRates', ticketRateId, 'bucket'], '')
      const roomRates = _.get(additionalPackageRate, ['links', 'roomRates', 'ids', 0], '')
      const checkinDate = _.get(additionalPackageRatesReply.linked, ['roomRates', roomRates, 'checkinDate'])
      const checkoutDate = _.get(additionalPackageRatesReply.linked, ['roomRates', roomRates, 'checkoutDate'])
      const packageTypeId = _.get(additionalPackageRate, 'packageTypeId', '')

      const linked = Object.assign({}, {
        ...result
      })

      allPackages = Object.assign({}, allPackages, {
        bucket,
        packageTypeId,
        checkinDate,
        checkoutDate,
        packageRatesReply: {
          linked,
          packageRates: additionalPackageRate
        }
      })

      return allPackages
    })
    return allPackageRates
  },

  structureResponse (allPackageRates, currentId, key, nightBeforeDate, nightAfterDate, checkinDate, checkoutDate) {
    let responses = []

    let ids = allPackageRates.map(a => a[key]).filter(function (value, index, self) {
      return self.indexOf(value) === index
    })

    ids.map((id) => {
      let response = Object.assign({}, {
        [id]: {
          group: {},
          originalPackage: {},
          extraNights: {}
        }
      })

      const extraNightBefore = allPackageRates.find(packageRate =>
        packageRate[key] === id &&
        packageRate.checkinDate === nightBeforeDate &&
        packageRate.checkoutDate === checkoutDate
      ) || {}

      const originalPackage = allPackageRates.find(packageRate =>
        packageRate[key] === id &&
        packageRate.checkinDate === checkinDate &&
        packageRate.checkoutDate === checkoutDate) || {}

      const extraNightAfter = allPackageRates.find(packageRate =>
        packageRate[key] === id &&
        packageRate.checkinDate === checkinDate &&
        packageRate.checkoutDate === nightAfterDate) || {}

      const group = currentId === id ? 'extraNights' : 'swappableTicketUpgrades'

      const keyName = (key === 'bucket') ? 'ticketBucket' : key

      response = Object.assign({}, {
        [keyName]: id,
        group,
        originalPackage,
        extraNights: {
          before: extraNightBefore,
          after: extraNightAfter
        }
      })
      responses.push(response)
    })

    return responses
  },

  calculateAndSetAdditionalUpgrades (additionalUpgradeObj, currentId, key, harvestBasketData) {
    const availabilityReply = _.get(additionalUpgradeObj, 'originalPackage.packageRatesReply.packageRates', undefined)
    if (additionalUpgradeObj[key] !== currentId) {
      if (!availabilityReply) return null
      const packageReply = _.get(additionalUpgradeObj, 'originalPackage.packageRatesReply.linked', undefined)
      const ticketRateId = Object.keys(packageReply.ticketRates)[0]
      const ticketRate = _.get(packageReply, `ticketRates[${ticketRateId}]`)
      const ticketCode = `${ticketRate.bucket}${ticketRate.code}`
      const contentId = _.get(availabilityReply, 'links.eventProducts.ids[0]', undefined)
      const hotelCode = _.get(availabilityReply, 'links.hotelProducts.ids[0]', undefined)
      const hotelName = _.get(packageReply, `hotelProducts[${hotelCode}].name`, undefined)
      const originalPrice = (availabilityReply && availabilityReply.grossPrice) ? availabilityReply.grossPrice.toFixed(2) : null
      const standardPrice = (availabilityReply && availabilityReply.standardPrice) ? availabilityReply.standardPrice.toFixed(2) : null
      const packageId = (availabilityReply && availabilityReply.id) ? availabilityReply.id : null
      const basketPrice = harvestBasketData.grossPrice
      const basketStandardPrice = harvestBasketData.standardPrice
      const swappableTicketUpgradeCost = (basketPrice && availabilityReply && availabilityReply.grossPrice && (availabilityReply.grossPrice - basketPrice).toFixed(2)) || null
      const swappableTicketUpgradeStandardCost = (basketStandardPrice && availabilityReply && availabilityReply.standardPrice && (availabilityReply.standardPrice - basketStandardPrice).toFixed(2)) || null
      const availabilityReplyBefore = _.get(additionalUpgradeObj, 'extraNights.before', undefined)
      const swappableTicketExtraNightBefore = upgradesHelpers.calculateAndSetExtraNight(true, availabilityReplyBefore, harvestBasketData, hotelName, originalPrice, standardPrice)
      const availabilityReplyAfter = _.get(additionalUpgradeObj, 'extraNights.after', undefined)
      const swappableTicketExtraNightAfter = upgradesHelpers.calculateAndSetExtraNight(false, availabilityReplyAfter, harvestBasketData, hotelName, originalPrice, standardPrice)

      const extraNights = Object.assign({},
        swappableTicketExtraNightBefore,
        swappableTicketExtraNightAfter
      )
      const imageBasePath = `${config.brandConfig && config.brandConfig.secure && config.brandConfig.secure.imagesDomain}`
      const eventProducts = (packageReply && packageReply.eventProducts) || {}
      const name = eventProducts[contentId].name

      const swappableTicketUpgrade = Object.assign({}, {
        additionalDescription: eventProducts[contentId].additionalDescription || '',
        description: eventProducts[contentId].eventInformation,
        extraNights,
        groupDescription: eventProducts[contentId].groupDescription,
        groupImage: eventProducts[contentId].groupImage,
        grossPrice: swappableTicketUpgradeCost,
        groupTitle: eventProducts[contentId].groupTitle,
        href: eventProducts[contentId].href,
        id: ticketCode,
        isAvailable: (typeof availabilityReply === 'object'),
        image: resourceHelpers.generateImagePaths(imageBasePath, 'upgrades/', contentId),
        imageBasePath,
        contentId,
        isInBasket: false,
        isIncludedInPackage: false,
        isSeasonPass: (/season pass/i.test(name)),
        isSwappableTicket: true,
        name,
        originalPackageId: packageId,
        originalPrice: originalPrice,
        packageId,
        packageTypeId: availabilityReply.packageTypeId,
        price: swappableTicketUpgradeCost,
        selectedDate: additionalUpgradeObj.originalPackage.checkinDate,
        standardOriginalPrice: standardPrice,
        standardPrice: swappableTicketUpgradeStandardCost,
        text: eventProducts[contentId].name,
        value: ticketCode,
        whatsIncluded: eventProducts[contentId].whatsIncluded
      })
      return swappableTicketUpgrade
    } else {
      const availabilityReplyBefore = _.get(additionalUpgradeObj, 'extraNights.before', undefined)
      const extraNightBefore = upgradesHelpers.calculateAndSetExtraNight(true, availabilityReplyBefore, harvestBasketData)
      const availabilityReplyAfter = _.get(additionalUpgradeObj, 'extraNights.after', undefined)
      const extraNightAfter = upgradesHelpers.calculateAndSetExtraNight(false, availabilityReplyAfter, harvestBasketData)

      const extraNights = Object.assign({},
        extraNightBefore,
        extraNightAfter
      )
      return extraNights
    }
  },

  calculateAndSetExtraNight (isNightBefore, reply, harvestBasketData, hotelName = null, basketPrice = null, basketStandardPrice = null) {
    let extraNights = {}
    const extraNightSwitch = isNightBefore ? 'before' : 'after'
    const extraNightDate = isNightBefore ? moment.utc(reply.checkinDate) : moment.utc(reply.checkoutDate)
    const availabilityReply = _.get(reply, 'packageRatesReply.packageRates', {})
    const originalPrice = (availabilityReply && availabilityReply.grossPrice) ? availabilityReply.grossPrice.toFixed(2) : null
    const standardPrice = (availabilityReply && availabilityReply.standardPrice) ? availabilityReply.standardPrice.toFixed(2) : null
    const packageId = (availabilityReply && availabilityReply.id) ? availabilityReply.id : null
    if (!basketPrice) {
      basketPrice = harvestBasketData.grossPrice
      basketStandardPrice = harvestBasketData.standardPrice
    }
    if (!hotelName) {
      hotelName = harvestBasketData.hotel.name
    }
    const extraNightCost = (basketPrice && availabilityReply && availabilityReply.grossPrice && (availabilityReply.grossPrice - basketPrice).toFixed(2)) || null
    const extraNightStandardCost = (basketStandardPrice && availabilityReply && availabilityReply.standardPrice && (availabilityReply.standardPrice - basketStandardPrice).toFixed(2)) || null
    extraNights = {
      [extraNightSwitch]: {
        name: hotelName,
        originalPrice: originalPrice,
        standardOriginalPrice: standardPrice,
        originalPackageId: packageId,
        grossPrice: extraNightCost,
        standardPrice: extraNightStandardCost,
        date: extraNightDate,
        isInBasket: false,
        isAvailable: (typeof availabilityReply === 'object'),
        packageTypeId: availabilityReply.packageTypeId
      }
    }
    return extraNights
  },
  convertToGroupUpgrades (swappableTicketUpgrades, upgrades, ticket, composition) {
    const groups = upgradesHelpers.groupBy(swappableTicketUpgrades, function (item) {
      // use groupTitle pulled from prismic, to group upgrades
      return [item.groupTitle]
    })
    const additionalGroupUpgrades = groups.reduce((result, group) => {
      const groupUpgrade = upgradesHelpers.covertGroupToGroupUpgrades(group, upgrades, ticket, composition)

      result.push(groupUpgrade)
      return result
    }, [])
    return additionalGroupUpgrades
  },
  groupBy (array, f) {
    var groups = {}
    array.forEach(function (o) {
      var group = JSON.stringify(f(o))
      groups[group] = groups[group] || []
      groups[group].push(o)
    })
    return Object.keys(groups).map(function (group) {
      return groups[group]
    })
  },
  covertGroupToGroupUpgrades (group, upgrades, ticket, composition) {
    let groupInfo = {}
    let groupIds = []
    group.forEach((additionalUpgrade) => {
      groupIds.push(additionalUpgrade.id)
      groupInfo[additionalUpgrade.id] = additionalUpgrade
      additionalUpgrade.bucket = additionalUpgrade.id.slice(0, 3)
      additionalUpgrade.adults = ticket.adults
      additionalUpgrade.children = ticket.children
      additionalUpgrade.infants = ticket.infants
      additionalUpgrade.startDate = ticket.startDate
    })

    const defaultAdditionalUpgrade = group.reduce((result, additionalUpgrade) => {
      if (!result || Number(additionalUpgrade.grossPrice) < Number(result.grossPrice)) {
        result = additionalUpgrade
      }
      return result
    }, null)

    const isPriceSameForAllUpgradesInGroup = group.every(upgradeRate => upgradeRate.grossPrice === defaultAdditionalUpgrade.grossPrice)

    const imageBasePath = `${config.brandConfig && config.brandConfig.secure && config.brandConfig.secure.imagesDomain}`
    // We can have only 1 swappable ticket upgrade in the basket
    const swappableTicketInBasket = (upgrades.find(upgrade => upgrade.isSwappableTicket && upgrade.isInBasket) || {})
    const isInBasket = swappableTicketInBasket.packageId === defaultAdditionalUpgrade.packageId

    return Object.assign({}, {
      ...defaultAdditionalUpgrade,
      additionalDescription: defaultAdditionalUpgrade.additionalDescription,
      composition: composition,
      compositionBoundaries: {
        'min': composition,
        'max': composition
      },
      dates: [defaultAdditionalUpgrade.selectedDate],
      featured: true,
      group: defaultAdditionalUpgrade.groupTitle,
      groupDescription: defaultAdditionalUpgrade.groupDescription,
      groupIds,
      groupImage: resourceHelpers.generateImagePaths(imageBasePath, 'upgrades/', defaultAdditionalUpgrade.groupImage),
      groupInfo: Object.assign({}, {
        'upgrades': groupInfo
      }),
      groupPrice: defaultAdditionalUpgrade.price,
      groupStandardPrice: defaultAdditionalUpgrade.originalStandardPrice,
      groupTitle: defaultAdditionalUpgrade.groupTitle,
      groupUpgradeProducts: group,
      isInBasket,
      isPriceSameForAllUpgradesInGroup,
      isSeasonPass: defaultAdditionalUpgrade.isSeasonPass,
      isSwappableTicket: true,
      isUpgradeGroup: true,
      row: true,
      selectedProductId: defaultAdditionalUpgrade.id,
      title: defaultAdditionalUpgrade.name
    })
  }
}

export default upgradesHelpers
