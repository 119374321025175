import _ from 'lodash'
import { createSelector } from 'reselect'

const getPaymentState = state => state.payment || {}
const getVouchersState = state => (state && state.vouchers && state.vouchers.vouchers) || []

export const getTotalRedemptionValue = createSelector(
  [getVouchersState, getPaymentState],
  (vouchers, paymentState) => {
    let totalAmountToPay = paymentState.grossPrice
    let totalRedemptionValue = 0

    vouchers.forEach(voucher => {
      // If total amount to pay is higher than the remaining value on the voucher
      if (totalAmountToPay >= voucher.remainingValue) {
        voucher.redemptionValue = Number(voucher.remainingValue)
      } else {
        voucher.redemptionValue = Number(totalAmountToPay.toFixed(2))
      }

      totalRedemptionValue += voucher.redemptionValue
      totalAmountToPay -= voucher.redemptionValue
    })

    return totalRedemptionValue
  }
)

export const getTotalVoucherValue = createSelector(
  getVouchersState,
  vouchers => vouchers.reduce((acc, curr) => {
    acc += Number(curr.remainingValue)
    return acc
  }, 0)
)

export const getRemainingVoucherValue = createSelector(
  [getTotalVoucherValue, getPaymentState],
  (totalVoucherValue, paymentState) => paymentState && _.round(totalVoucherValue - paymentState.grossPrice, 2)
)

export const getTotalAmountToPay = createSelector(
  [getTotalRedemptionValue, getPaymentState],
  (totalRemptionValue, paymentState) => paymentState && _.round(paymentState.grossPrice - totalRemptionValue, 2)
)
