import _ from 'lodash'

const termsAndConditions = _.get(window, 'brandReply.brands[0].termsAndConditions')
const brandConfig = _.get(window, 'brandConfig', {})
const data = Object.assign({ termsAndConditions }, brandConfig)

const brand = (state = {
  ...data
}, action) => {
  switch (action.type) {
    case 'RECEIVED_BRAND':
      return Object.assign({}, state, {
        ...action.data
      })
    default:
      return state
  }
}

export default brand
