import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import DisplayPrice from '../atoms/DisplayPrice'

import dateHelpers from '../../helpers/dateHelpers'
import trackingHelpers from '../../helpers/trackingHelpers'

class ExtraNight extends PureComponent {
  render () {
    const { date, grossPrice, isAvailable, isInBasket } = this.props.data
    let dateString = ''
    // night after needs to be the current check-out date (not check-out date + 1 which is passed in state)
    if (date && !this.props.isNightBefore) {
      dateString = dateHelpers.formatStandard(moment(date, 'DD-MM-YYYY').subtract(1, 'days'))
    } else {
      dateString = dateHelpers.formatStandard(date)
    }

    const disabled = (!isAvailable || !grossPrice)
    let buttonText = <FormattedMessage id='common.unavailable' />

    if (isAvailable) {
      if (grossPrice) {
        // Price and already in basket
        if (isInBasket) buttonText = <FormattedMessage id='common.remove' />
        // Price but not in basket yet
        else buttonText = (<span>Add <DisplayPrice price={Number(grossPrice)} /></span>)
      }
    }

    const classes = classNames({
      'btn': this.props.extraNightButtonSmallSize,
      'btn-lg': !this.props.extraNightButtonSmallSize,
      'disabled': disabled,
      'pull-right btn-secondary': true
    })

    return grossPrice && (
      <li className='list-group-item clearfix'>
        <button
          className={classes}
          {...trackingHelpers.getAttributes('Select Extra Night Date', 'Extra Night', `${this.props.hotelCode} ${(this.props.isNightBefore) ? 'Start' : 'End'}`)}
          onClick={!disabled && this.props.handleExtraNightCTA.bind(this, this.props.isNightBefore)}
          onKeyDown={(e) => e.key === 'Enter' && !disabled && this.props.handleExtraNightCTA.bind(this, this.props.isNightBefore)}>
          {buttonText}
        </button>
        <span>
          &hellip;{`to the ${this.props.isNightBefore ? 'start' : 'end'} of your break`}
          <strong> - {dateString}</strong>
        </span>
      </li>
    )
  }
}

ExtraNight.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.instanceOf(moment),
    grossPrice: PropTypes.string,
    isAvailable: PropTypes.bool.isRequired,
    isInBasket: PropTypes.bool.isRequired
  }).isRequired,
  extraNightButtonSmallSize: PropTypes.bool,
  hotelCode: PropTypes.string.isRequired,
  isNightBefore: PropTypes.bool
}

export default ExtraNight
