import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class SVG extends PureComponent {
  render () {
    let labelledBy = (this.props.uniqueID) ? `${this.props.uniqueID}Title` : null

    // @todo why the this.props.description check?
    if (this.props.uniqueID && this.props.description) {
      labelledBy += ` ${this.props.uniqueID}Description`
    }

    const title = (this.props.title && this.props.uniqueID) ? (
      <title id={`${this.props.uniqueID}Title`}>
        {this.props.title}
      </title>
    ) : null

    const description = (this.props.description && this.props.uniqueID) ? (
      <desc id={`${this.props.uniqueID}Description`}>
        {this.props.description}
      </desc>
    ) : null

    return (
      <svg
        xmlns='http://www.w3.org/2000/svg'
        xmlnsXlink='http://www.w3.org/1999/xlink'
        version='1.1'
        fill={this.props.fill}
        width={this.props.width}
        height={this.props.height}
        viewBox={this.props.viewBox}
        aria-labelledby={labelledBy}
        className={this.props.className}>
        {title}
        {description}
        {this.props.children}
      </svg>
    )
  }
}

SVG.propTypes = {
  children: PropTypes.element,
  className: PropTypes.string,
  description: PropTypes.string,
  fill: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  title: PropTypes.string,
  uniqueID: PropTypes.string,
  viewBox: PropTypes.string,
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

SVG.defaultProps = {
  height: 24,
  width: 24
}

export default SVG
