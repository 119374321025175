import _ from 'lodash'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import { Button, Collapse, Tab, Tabs } from 'react-bootstrap'

import DisplayPrice from '../atoms/DisplayPrice'
import GoogleMapsWrapper from '../atoms/GoogleMapsWrapper'
import HotelLocation from '../atoms/HotelLocation'
import SVGCallCentre from '../atoms/SVGCallCentre'

import HotelImageCarousel from '../molecules/HotelImageCarousel'
import ModalTab from '../molecules/ModalTab'

import generalHelpers from '../../helpers/generalHelpers'
import trackingHelpers from '../../helpers/trackingHelpers'

import config from '../../configs/config'
const { brandConfig, packageRatesReply } = config
const venueProduct = _.get(packageRatesReply, 'linked.venueProducts')

const Review = ({ review }) => {
  return (
    <blockquote>
      <p dangerouslySetInnerHTML={{ __html: review.review }} />
    </blockquote>
  )
}

class HotelInformation extends PureComponent {
  constructor (props) {
    super(props)

    // Initial state
    this.state = {
      showMoreReviews: false
    }

    // Define parameters for handleAddProduct
    let ctaParams = {}
    if (props.isResort) {
      ctaParams = {
        isResort: true,
        name: props.name,
        showRoomSelector: true
      }
      if (props.isRoom) {
        Object.assign(ctaParams, {
          isRoom: true,
          roomId: props.roomId
        })
      } else {
        Object.assign(ctaParams, {
          doChangePage: false,
          isRoom: false
        })
      }
    }

    this.handleAddProduct = this.handleAddProduct.bind(this, ctaParams)
    this.setTab = this.setTab.bind(this)
  }

  handleAddProduct (params, routeOptions = null) {
    // First make sure we confirm stuff
    if (this.props.isCotConfirmationRequired && this.props.hotelInfants > 0 && !params.showRoomSelector) {
      const cotRequestedText = `Please confirm that you've called the hotel on ${this.props.telephone} to confirm that a cot is available`
      if (!confirm(cotRequestedText)) return
    }
    return this.props.handleAddProduct(this.props.hotel, params, routeOptions)
  }

  // For the moreinformation page we want to have (internal) links to modals, some of the fields on that page are shared with this one
  // so these links need to be removed otherwise a customer will go to a blank page.
  // The regular expression will remove any anchor tag which href starts with "http://#"
  removePrismicModalLinks (html) {
    return html && html.replace(/<a href="http:\/\/#[^>]+>([^>]+)<\/a>/g, '$1')
  }

  setTab (tabIndex = 'hotel') {
    trackingHelpers.track('sb.track', tabIndex, 'Hotel More Info', this.props.id)
    this.props.handleSetProductModal(this.props.id, {
      tab: tabIndex
    })
  }

  render () {
    const { venueProducts } = this.props
    let venueMarkers = {
      label: venueProduct.name,
      lat: +brandConfig.latitude || 0,
      lng: +brandConfig.longitude || 0
    }
    // Checks for venue product to calculate distance (e.g. Encore Tickets - distance to theatre over theme park)
    if (venueProducts) {
      const { latitude, longitude, name } = venueProducts[Object.keys(venueProducts)[0]]
      venueMarkers = {
        label: name,
        lat: parseFloat(latitude) || 0,
        lng: parseFloat(longitude) || 0
      }
    }
    const mapMarkers = [venueMarkers, {
      label: this.props.name,
      lat: +this.props.latitude || 0,
      lng: +this.props.longitude || 0
    }]
    const defaultTab = _.get(this.props, 'modalState.supplementary.tab', 'hotel')
    // Define some strings
    let packageInfo = null
    let productTitle = this.props.hasParkEntry ? `${this.props.venueType} tickets` : 'For Hotel'
    if (this.props.hasTicketNameOnCTA) {
      productTitle = this.props.ticketName
    }

    if (this.props.hasBreakfastIncluded) {
      productTitle += ' & breakfast'
    }

    // Doing some swapsies
    if (this.props.packageInfo) {
      packageInfo = productTitle
      productTitle = this.props.packageInfo
    }

    if (_.get(brandConfig, 'secure.hasFeatures.availability.customProductTitle')) productTitle = 'For Hotel, Breakfast & Tickets'

    const packageTotalString = this.props.perPersonPrice || this.props.packageTotal
    const hotelLocation = <HotelLocation {...this.props} />

    let roomDescriptions = this.props.roomFacilities
    if (!roomDescriptions) {
      const occupancyTypes = (this.props.rooms || []).map(room => room.occupancyType)

      roomDescriptions = (this.props.defaultRoomDescriptions || [])
        // Filter descriptions based on what occupancyType(s) have been selected
        .filter(d => occupancyTypes.includes(d.occupancy_type))
        // Return only the description of each of them
        .map(d => d.description)
        // Make it one string
        .join('')
    }

    // Components
    let ctaCondition = null
    if (this.props.isCotConfirmationRequired && this.props.hotelInfants > 0) {
      const telephone = <strong>{this.props.telephone}</strong>
      ctaCondition = (
        <small>
          <SVGCallCentre
            key={`Hotel${this.props.id}CotsNotification`}
            title='Call Centre Notification'
          />
          <FormattedMessage id='availability.confirmCotCallCentre' values={{ telephone }} />
        </small>
      )
    }

    return (
      <div className='row'>
        {this.props.importantInformation && (
          <div className='col-xs-12'>
            <p className='alert alert-info'>
              {this.props.importantInformation}
            </p>
          </div>
        )}
        <div className='clearfix' />
        <div className='col-md-8'>
          <Button
            block
            bsStyle='primary'
            bsSize='large'
            className='visible-xs visible-sm'
            {...trackingHelpers.getAttributes('Hotel CTA', 'Hotel Information Modal', this.props.id)}
            data-product-id={this.props.id}
            onClick={this.handleAddProduct}
            onKeyDown={(e) => e.key === 'Enter' && this.handleAddProduct()}>
            <span className='cta_text'>{this.props.fromText}<DisplayPrice price={packageTotalString} operator='ceil' /></span>
            {productTitle && <small className='cta_subtext'>{productTitle}</small>}
          </Button>
          {this.props.images && (
            <HotelImageCarousel
              autoplay={_.get(this.props, 'modalState.supplementary.autoplay', false)}
              images={this.props.images}
              hotelName={this.props.name}
              videoId={this.props.videoId}
            />
          )}
          <div className='clearfix'>
            <Tabs activeKey={defaultTab} onSelect={this.setTab} id={`hotelInformation${this.props.id}`}>
              <Tab eventKey='hotel' title='Hotel'>
                <section>
                  <div className='panel panel-default'>
                    <div className='panel-heading'>
                      <h1 className='panel-title'>The Hotel</h1>
                    </div>
                    {this.props.hotelFacilities &&
                      <div className='panel-body' dangerouslySetInnerHTML={{ __html: this.removePrismicModalLinks(generalHelpers.italicToSmall(this.props.hotelFacilities)) }} />
                    }
                  </div>
                  <div className='panel panel-default'>
                    <div className='panel-heading'>
                      <h2 className='panel-title'>Hotel Details</h2>
                    </div>
                    <div className='panel-body'>
                      {hotelLocation && (
                        <div>
                          <h3 className='h4'>
                            Distance from {this.props.venueType}
                          </h3>
                          <p>{hotelLocation}</p>
                        </div>
                      )}
                      <h3 className='h4'>Parking</h3>
                      <p>{`Free parking is ${!this.props.hasFreeParking ? 'not ' : ''}available`}</p>
                      <h3 className='h4'>Address</h3>
                      {this.props.address0 && <div>{this.props.address0}</div>}
                      {this.props.address1 && <div>{this.props.address1}</div>}
                      {this.props.address2 && <div>{this.props.address2}</div>}
                      {this.props.addressLocality && <div>{this.props.addressLocality}</div>}
                      {this.props.addressRegion && <div>{this.props.addressRegion}</div>}
                      {this.props.postalCode && <div>{this.props.postalCode}</div>}
                    </div>
                  </div>
                  {mapMarkers.length > 0 && <GoogleMapsWrapper markers={mapMarkers} fitBounds />}
                </section>
              </Tab>
              <ModalTab
                eventKey='rooms'
                tabTitle='Rooms'
                title='Room Details'>
                {roomDescriptions &&
                  <span dangerouslySetInnerHTML={{ __html: generalHelpers.italicToSmall(`${roomDescriptions}`) }} />
                }
                {this.props.roomInformationList &&
                  <span dangerouslySetInnerHTML={{ __html: generalHelpers.italicToSmall(`${this.props.roomInformationList}`) }} />
                }
              </ModalTab>
              {this.props.childFacilities && (
                <ModalTab
                  eventKey='kids'
                  tabTitle='Kids'
                  title='Kids'>
                  <span dangerouslySetInnerHTML={{ __html: generalHelpers.italicToSmall(this.props.childFacilities) }} />
                </ModalTab>
              )}
              {this.props.leisureFacilities && (
                <ModalTab
                  eventKey='leisure'
                  tabTitle='Leisure'
                  title='Leisure Facilities'>
                  <span dangerouslySetInnerHTML={{ __html: generalHelpers.italicToSmall(this.props.leisureFacilities) }} />
                </ModalTab>
              )}
              {this.props.restaurantFacilities && (
                <ModalTab
                  eventKey='food'
                  tabTitle='Food'
                  title='Food'>
                  <span dangerouslySetInnerHTML={{ __html: generalHelpers.italicToSmall(this.props.restaurantFacilities) }} />
                </ModalTab>
              )}
              {this.props.hasFeatures.reviews && this.props.reviews.length > 0 && (
                <ModalTab
                  eventKey='reviews'
                  tabTitle='Reviews'
                  title='Reviews'>
                  <h3>Our customers said</h3>
                  {this.props.reviews.slice(0, 3).map(review => <Review key={`review-${this.props.id}-${review.id}`} review={review} />)}
                  {this.props.reviews.length > 3 &&
                    <div>
                      {!this.state.showMoreReviews &&
                        <a
                          onClick={() => this.setState((state) => ({ showMoreReviews: !state.showMoreReviews }))}
                          onKeyDown={(e) => e.key === 'Enter' && this.setState((state) => ({ showMoreReviews: !state.showMoreReviews }))}
                          role='button'>
                          <FormattedMessage id='common.showMoreReviews' values={{ count: this.props.reviews.length - 3 }} />
                        </a>
                      }
                      <Collapse in={this.state.showMoreReviews}>
                        <div>
                          {this.props.reviews.slice(3).map(review => <Review key={`review-${this.props.id}-${review.id}`} review={review} />)}
                        </div>
                      </Collapse>
                    </div>
                  }
                </ModalTab>
              )}
            </Tabs>
          </div>
        </div>
        <div className='col-md-4 modal--product-information__basket'>
          <div className='text-right'>
            {packageInfo &&
              <strong>{packageInfo}</strong>
            }
            {this.props.perPersonPrice &&
              <div className='package-total'>
                <strong>
                  <FormattedMessage id='common.total' /> <DisplayPrice price={this.props.packageTotal} operator='ceil' />
                </strong>
              </div>
            }
          </div>

          <Button
            block
            bsStyle='primary'
            bsSize='large'
            {...trackingHelpers.getAttributes('Hotel CTA', 'Hotel Information Modal', this.props.id)}
            data-product-id={this.props.id}
            onClick={this.handleAddProduct}
            onKeyDown={(e) => e.key === 'Enter' && this.handleAddProduct()}>
            <span className='cta_text'>{this.props.fromText}<DisplayPrice price={packageTotalString} operator='ceil' /></span>
            {productTitle && <small className='cta_subtext'>{productTitle}</small>}
          </Button>
          {ctaCondition}

          {this.props.basket}

          <div className='text-right'>
            {packageInfo && <strong>{packageInfo}</strong>}
            {this.props.perPersonPrice && (
              <div className='package-total'>
                <strong>
                  <FormattedMessage id='common.total' /> <DisplayPrice price={this.props.packageTotal} operator='ceil' />
                </strong>
              </div>
            )}
          </div>

          <Button
            block
            bsStyle='primary'
            bsSize='large'
            {...trackingHelpers.getAttributes('Hotel CTA', 'Hotel Information Modal', this.props.id)}
            data-product-id={this.props.id}
            onClick={this.handleAddProduct}
            onKeyDown={(e) => e.key === 'Enter' && this.handleAddProduct()}>
            <span className='cta_text'>{this.props.fromText}<DisplayPrice price={packageTotalString} operator='ceil' /></span>
            {productTitle && <small className='cta_subtext'>{productTitle}</small>}
          </Button>
          {ctaCondition}
        </div>
      </div>
    )
  }
}

HotelInformation.propTypes = {
  address0: PropTypes.string.isRequired,
  address1: PropTypes.string,
  address2: PropTypes.string,
  addressLocality: PropTypes.string.isRequired,
  addressRegion: PropTypes.string,
  basket: PropTypes.element.isRequired,
  childFacilities: PropTypes.string,
  distance: PropTypes.number.isRequired,
  handleAddProduct: PropTypes.func.isRequired,
  handleSetProductModal: PropTypes.func.isRequired,
  hasBreakfastIncluded: PropTypes.bool.isRequired,
  hasFreeParking: PropTypes.bool.isRequired,
  hasFeatures: PropTypes.object.isRequired,
  hasParkEntry: PropTypes.bool,
  hotelFacilities: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  images: PropTypes.array.isRequired,
  importantInformation: PropTypes.string,
  isCotConfirmationRequired: PropTypes.bool.isRequired,
  isResort: PropTypes.bool.isRequired,
  isVideoAutoplay: PropTypes.bool.isRequired,
  latitude: PropTypes.number,
  leisureFacilities: PropTypes.string,
  longitude: PropTypes.number,
  modalState: PropTypes.object.isRequired,
  name: PropTypes.string.isRequired,
  packageTotal: PropTypes.number.isRequired,
  postalCode: PropTypes.string.isRequired,
  restaurantFacilities: PropTypes.string,
  reviews: PropTypes.array.isRequired,
  roomFacilities: PropTypes.string.isRequired,
  roomInformationList: PropTypes.string,
  telephone: PropTypes.string.isRequired,
  venueType: PropTypes.string.isRequired,
  videoId: PropTypes.string
}

export default HotelInformation
