import _ from 'lodash'

const preferenceCentre = (state = {
  allowGeneralMarketing: true,
  allowOrderMarketing: true,
  customerId: null,
  grossPrice: 0,
  orderId: null,
  lineItems: []
}, action) => {
  switch (action.type) {
    case 'GET_PREFERENCECENTRE_PAGE_DATA': {
      const order = _.get(window, 'orderReply.orders[0]', {})
      const linked = _.get(window, 'orderReply.linked', {})
      const {
        agent = null,
        referrer = null,
        allowGeneralMarketing = true,
        allowOrderMarketing = true,
        id = null,
        grossPrice = state.grossPrice
      } = order
      const {
        hotelProducts = {},
        lineItems = {},
        eventProducts = {},
        upgradeProducts = {},
        customers = {}
      } = linked

      const items = Object.values(lineItems).map(item => {
        const productId = item.productId
        let name = ''

        switch (item.type) {
          case 'hotel':
            const hotelInfo = hotelProducts[productId] || {}
            name = hotelInfo.name
            break

          case 'ticket':
            const contentId = item.contentId
            const ticketInfo = eventProducts[contentId] || eventProducts[productId] || {}
            name = ticketInfo.name
            break

          case 'attraction':
          case 'supplement':
            const attractionInfo = upgradeProducts[productId] || {}
            name = attractionInfo.name
            break

          default:
        }
        return {
          category: item.type,
          currency: item.currency,
          id: productId,
          name,
          price: item.grossPrice,
          quantity: item.quantity
        }
      })

      return Object.assign({}, state, {
        agent,
        allowGeneralMarketing,
        allowOrderMarketing,
        customers: customers[Object.keys(customers)[0]],
        customerId: _.get(order, 'links.customers.ids[0]', null),
        grossPrice,
        lineItems: items,
        orderId: id,
        referrer
      })
    }
    case 'PREFERENCECENTRE_UPDATE_PREFERENCES': {
      return Object.assign({}, state, {
        [action.marketingType]: Boolean(action.value)
      })
    }
    default:
      return state
  }
}

export default preferenceCentre
