import React from 'react'
import { useSelector } from 'react-redux'

import { FormattedMessage } from 'react-intl'

import SVGCalendar from '../../atoms/SVGCalendar'

const ParkEntry = ({
  hasFixedSummary,
  hasParkEntry,
  hideBasketSVGs,
  labelParkDate,
  parkDate,
  reducedInfo,
  ticketDuration
}) => {
  const hasTicketDateCalendar = useSelector(state => _.get(state, 'brand.secure.hasFeatures.hasTicketDateCalendar', false))

  if (!hasParkEntry || !hasFixedSummary || !hasTicketDateCalendar) return null
  return (
    <li className={reducedInfo ? 'hidden-xs' : ''}>
      <div className='media'>
        <div className='media-left'>
          {!hideBasketSVGs &&
            <SVGCalendar
              height='30'
              width='30'
              viewBox='0 0 58 58'
            />
          }
        </div>
        <div className='media-body'>
          <div className='media-heading'>{labelParkDate}</div>
          <div className='text-muted tiny'><FormattedMessage id='common.parkEntryForConsecutiveDays' values={{ parkDate: parkDate && parkDate.format('Do MMMM YY'), ticketDuration }} /></div>
        </div>
      </div>
      <hr />
    </li>
  )
}

export default ParkEntry
