import _ from 'lodash'
import moment from 'moment'
import languageHelpers from '../helpers/languageHelpers'
import config from '../configs/config'
const { harvestBasketData } = config

const datesDiffInDays = (date1, date2) => {
  // Ensuring the number is a positive integer (1, 2 etc not -1, -2 etc).
  // This is because if date2 comes after date1 the value is negative &
  // we only care about the diff value.
  return Math.abs(moment(date1).diff(moment(date2), 'days'))
}

const filterSeenMessages = (newMessages = [], existingMessages = []) => {
  return newMessages.filter(message => {
    return existingMessages.indexOf(message) === -1
  })
}

const withChildAges = _.get(config.brandConfig, 'secure.withChildAges', false)

const engineOld = (state = {
  action: 'availability',
  agent: undefined,
  brandConfig: {},
  customerCode: undefined,
  isSubmitting: false,
  modalVisible: false,
  operator: '',
  roomBucket: undefined,
  venueCode: undefined,

  engineStore: {},
  hasEngineStore: false,

  visibleCheckOutCalendar: false,
  visibleCheckInCalendar: false,
  visiblePartyComp: false,
  visibleTicketCalendar: false,
  visibleHotelCalendar: false,

  errorCheckInDate: false,
  errorCheckOutDate: false,
  errorChildAges: false,
  errorRooms: false,
  errorPartyComp: false,
  errorTickets: false,
  errorTicketDate: false,
  errorHotelDate: false,
  errorNights: false,

  messages: [],
  seenMessages: [],

  isValidForSubmission: false,

  defaults: {
    checkInDate: undefined,
    ticketDate: undefined,
    ticketCode: undefined,
    ages: [],
    rooms: [],
    nights: undefined
  },

  withChildAges
}, action) => {
  switch (action.type) {
    case 'ENGINEOLD_CHANGE_CHECKINDATE':
      return Object.assign({}, state, {
        errorCheckInDate: false,
        visibleCheckInCalendar: false,
        engineStore: action.engineStore,
        messages: filterSeenMessages(action.engineStore.messages, state.seenMessages)
      })

    case 'ENGINEOLD_CHANGE_CHECKOUTDATE':
      return Object.assign({}, state, {
        errorCheckOutDate: false,
        visibleCheckOutCalendar: false,
        engineStore: action.engineStore,
        messages: filterSeenMessages(action.engineStore.messages, state.seenMessages)
      })

    case 'ENGINEOLD_CHANGE_ROOM':
      return Object.assign({}, state, {
        errorRooms: false,
        engineStore: action.engineStore,
        messages: filterSeenMessages(action.engineStore.messages, state.seenMessages)
      })

    case 'ENGINEOLD_CHANGE_PARTYCOMP':
      return Object.assign({}, state, {
        errorPartyComp: !!action.engineStore.invalidPartyMax,
        engineStore: action.engineStore,
        messages: filterSeenMessages(action.engineStore.messages, state.seenMessages)
      })

    case 'ENGINEOLD_UPDATE_CHILDAGE':
      return Object.assign({}, state, {
        engineStore: action.engineStore,
        messages: filterSeenMessages(action.engineStore.messages, state.seenMessages)
      })

    case 'ENGINEOLD_REVERT_PARTYANDAGES':
      return Object.assign({}, state, {
        engineStore: action.engineStore
      })

    case 'ENGINEOLD_CHANGE_TICKET':
      return Object.assign({}, state, {
        errorTickets: false,
        engineStore: action.engineStore,
        messages: filterSeenMessages(action.engineStore.messages, state.seenMessages)
      })

    case 'ENGINEOLD_CHANGE_TICKET_DATE':
      return Object.assign({}, state, {
        errorTicketDate: false,
        visibleTicketCalendar: false,
        engineStore: action.engineStore,
        messages: filterSeenMessages(action.engineStore.messages, state.seenMessages)
      })

    case 'ENGINEOLD_CHANGE_ONLY_TICKET_DATE':
      return Object.assign({}, state, {
        engineStore: Object.assign({}, state.engineStore, {
          ticketDate: action.ticketDate
        })
      })

    case 'ENGINEOLD_CHANGE_HOTEL_DATE':
      return Object.assign({}, state, {
        errorHotelDate: false,
        visibleHotelCalendar: false,
        engineStore: action.engineStore,
        messages: filterSeenMessages(action.engineStore.messages, state.seenMessages)
      })

    case 'ENGINEOLD_CHANGE_NIGHTS':
      return Object.assign({}, state, {
        engineStore: action.engineStore,
        errorNights: false,
        messages: filterSeenMessages(action.engineStore.messages, state.seenMessages)
      })

    case 'ENGINEOLD_SUBMIT':
      return Object.assign({}, state, {
        isSubmitting: true,
        modalVisible: false
      })

    case 'ENGINEOLD_MODAL_TOGGLE':
      return Object.assign({}, state, {
        modalVisible: action.forceVisible || !state.modalVisible
      })

    case 'ENGINEOLD_TOGGLE_CHECKOUTCALENDAR':
      return Object.assign({}, state, {
        visibleCheckOutCalendar: action.forceVisible || !state.visibleCheckOutCalendar
      })

    case 'ENGINEOLD_TOGGLE_CHECKINCALENDAR':
      return Object.assign({}, state, {
        visibleCheckInCalendar: action.forceVisible || !state.visibleCheckInCalendar
      })

    case 'ENGINEOLD_TOGGLE_PARTYCOMP':
      return Object.assign({}, state, {
        visiblePartyComp: action.forceVisible || !state.visiblePartyComp
      })

    case 'ENGINEOLD_TOGGLE_TICKETCALENDAR':
      return Object.assign({}, state, {
        visibleTicketCalendar: action.forceVisible || !state.visibleTicketCalendar
      })

    case 'ENGINEOLD_TOGGLE_HOTELCALENDAR':
      return Object.assign({}, state, {
        visibleHotelCalendar: action.forceVisible || !state.visibleHotelCalendar
      })

    case 'ENGINEOLD_ERROR_CHECKINDATE':
      return Object.assign({}, state, {
        errorCheckInDate: action.hasError
      })

    case 'ENGINEOLD_ERROR_CHILDAGES':
      return Object.assign({}, state, {
        errorChildAges: action.hasError
      })

    case 'ENGINEOLD_INITIALIZE': {
      return Object.assign({}, state, {
        engineStore: action.engineStore,
        brandConfig: action.brandConfig,
        hasEngineStore: true,
        messages: [].concat(action.engineStore.messages)
      })
    }

    case 'ENGINEOLD_VALIDATION': {
      const {
        checkInDate,
        checkOutDate,
        childAges,
        remainingRoomPartyComp,
        invalidPartyMax,
        invalidPartyMultiple,
        invalidPartyInfants,
        ticketCode,
        ticketDate,
        hotelDate
      } = state.engineStore

      const {
        adults,
        children
      } = remainingRoomPartyComp

      // Rooms
      let errorRooms = false
      if (action.fieldsToValidate.rooms && (adults > 0 || children > 0)) {
        errorRooms = 'Please allocate the remaining '
        if (adults > 0) {
          errorRooms += `${adults} ${languageHelpers.pluralise('Adult', adults)}`
          if (children > 0) {
            errorRooms += ' and '
          }
        }
        if (children > 0) {
          errorRooms += `${children} ${languageHelpers.pluralise('Child', children)}`
        }
        errorRooms += ' to a room'
      }

      const errorChildAges = action.fieldsToValidate.childAges && childAges.some(age => age.value === '')

      // Open party composition if child ages need to be entered
      const visiblePartyComp = errorChildAges

      // Check in
      const errorCheckInDate = !action.fieldsToValidate.checkInDate ? false : !checkInDate

      // Check Out
      const errorCheckOutDate = !action.fieldsToValidate.checkOutDate ? false : !checkOutDate

      // Party Composition
      const errorPartyComp = !action.fieldsToValidate.partyComp ? false : !!(invalidPartyMax || invalidPartyMultiple || invalidPartyInfants)

      // Tickets
      const errorTickets = !action.fieldsToValidate.tickets ? false : !ticketCode

      // Ticket Date
      const errorTicketDate = !action.fieldsToValidate.ticketDate ? false : !ticketDate

      // Hotel Date
      const errorHotelDate = !action.fieldsToValidate.hotelDate ? false : !hotelDate

      // Finally set a boolean to indicate we are good to submit.
      const isValidForSubmission = (
        !errorCheckInDate &&
        !errorCheckOutDate &&
        !errorChildAges &&
        !errorHotelDate &&
        !errorPartyComp &&
        !errorRooms &&
        !errorTicketDate &&
        !errorTickets
      )

      return Object.assign({}, state, {
        errorCheckInDate,
        errorCheckOutDate,
        errorChildAges,
        errorHotelDate,
        errorPartyComp,
        errorRooms,
        errorTicketDate,
        errorTickets,
        isValidForSubmission,
        visiblePartyComp
      })
    }

    case 'GET_LANDING_PAGE_DATA': {
      const brandConfig = _.get(window, 'brandConfig', {})
      return Object.assign({}, state, {
        agent: brandConfig.agent,
        customerCode: _.get(window, 'customerCode', undefined),
        roomBucket: brandConfig.roomBucket,
        venueCode: brandConfig.parkCode
      })
    }

    case 'GET_AVAILABILITY_PAGE_DATA':
    case 'GET_SEATS_PAGE_DATA': {
      const {
        SeatType = '',
        agent,
        ages,
        customerCode,
        roomRates = {},
        ticketRates = {},
        venueCode,
        operator,
        promotionCode,
        referrer
      } = _.get(window, 'packageRatesReply.meta.context', {})
      const linkedTicketRates = Object.values(_.get(window, 'packageRatesReply.linked.ticketRates', {})) || []
      const firstTicketRate = _.head(linkedTicketRates) || {}
      const hasParkEntry = firstTicketRate.hasParkEntry

      const checkInDate = roomRates.checkinDate || undefined
      const checkOutDate = roomRates.checkoutDate || undefined

      const defaults = Object.assign({}, {
        checkInDate,
        ticketDate: ticketRates.startDate || undefined,
        ticketCode: (ticketRates.bucket || '') + SeatType,
        ages,
        rooms: roomRates.rooms || [],
        nights: datesDiffInDays(checkInDate, checkOutDate)
      })

      return Object.assign({}, state, {
        action: _.get(window, 'brandConfig.secure.flow[0].endpoint', {}),
        agent,
        customerCode,
        hasParkEntry,
        roomBucket: roomRates.bucket || undefined,
        venueCode,
        operator,
        promotionCode,
        referrer,
        defaults
      })
    }

    case 'GET_MOREINFORMATION_PAGE_DATA':
    case 'GET_PAYMENT_PAGE_DATA':
    case 'GET_UPGRADES_PAGE_DATA': {
      const {
        agent,
        ages,
        customerCode,
        hasParkEntry,
        hotel = {},
        roomRates = {},
        rooms = [],
        ticket = {},
        operator,
        promotionCode,
        referrer
      } = harvestBasketData

      const checkInDate = roomRates.checkinDate || undefined
      const checkOutDate = roomRates.checkoutDate || undefined

      const defaults = Object.assign({}, {
        checkInDate,
        ticketDate: ticket.startDate || undefined,
        ticketCode: ticket.bucket,
        ages,
        rooms,
        nights: datesDiffInDays(checkInDate, checkOutDate)
      })

      return Object.assign({}, state, {
        action: _.get(window, 'brandConfig.secure.flow[0].endpoint', {}),
        agent,
        customerCode,
        hasParkEntry,
        roomBucket: hotel.bucket || undefined,
        venueCode: _.get(window, 'packageRatesReply.meta.context.venueCode') || _.get(window, 'venueProduct.id'),
        operator,
        promotionCode,
        referrer,
        defaults
      })
    }
    default:
      return state
  }
}

export {
  datesDiffInDays,
  filterSeenMessages
}

export default engineOld
