import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'

import SVGPartyComposition from '../../atoms/SVGPartyComposition'

class PartyComposition extends PureComponent {
  render () {
    const partyCompositionHide = classNames({
      'col-xs-2 pl-0 pr-0': true,
      'hidden-xs': this.props.reducedInfo
    })
    const partyCompositionClasses = classNames('mb-0 pl-0 pt-0 flex-align_center block-xs', {
      'col-xs-10': !this.props.hideBasketSVGs,
      'col-xs-12': this.props.hideBasketSVGs
    })
    return (
      <div className='d-flex'>
        {!this.props.hideBasketSVGs &&
          <div className={partyCompositionHide}>
            <SVGPartyComposition
              height='30'
              width='30'
              viewBox='0 0 412 412'
            />
          </div>
        }
        <dl className={partyCompositionClasses}>
          <dt className='h5 mt-0 mb-0'>
            {this.props.partyComposition}
          </dt>
        </dl>
      </div>
    )
  }
}

PartyComposition.propTypes = {
  hideBasketSVGs: PropTypes.bool,
  partyComposition: PropTypes.string.isRequired
}

export default PartyComposition
