import '@babel/polyfill'
import 'isomorphic-fetch'

import _ from 'lodash'
import React from 'react'
import Modal from 'react-bootstrap/lib/Modal'
import ReactDOM from 'react-dom'
import { FormattedMessage, IntlProvider } from 'react-intl'
import { Provider, connect } from 'react-redux'
import { applyMiddleware, createStore, combineReducers, compose } from 'redux'
import thunk from 'redux-thunk'
import moment from 'moment'

// Using https://github.com/rangle/redux-beacon for event tracking.
import { createMiddleware } from 'redux-beacon'
import GoogleTagManager from '@redux-beacon/google-tag-manager'

import { emitter } from '@marvelapp/react-ab-test'

import SVGSearch from './components/atoms/SVGSearch'

import Loading from './components/molecules/Loading'
import ProgressTracker from './components/molecules/ProgressTracker'

import CallbackForm from './components/organisms/CallbackForm'

import AvailabilityContainer from './AvailabilityContainer'
import LandingPageContainer from './LandingPageContainer'
import PaymentContainer from './PaymentContainer'
import UpgradesContainer from './UpgradesContainer'

import Footer from './containers/Footer'
import Header from './containers/Header'
import MoreInformation from './containers/MoreInformation'
import PreferenceCentre from './containers/PreferenceCentre'
import Seats from './containers/Seats'

import CheckInCheckOut from './containers/EngineOld/CheckInCheckOut'
import WithTickets from './containers/EngineOld/WithTickets'

import PackagingEngine from './containers/Engine/PackagingEngine'

import LiveChat from './helpers/liveChat'
import ratesHelpers from './helpers/ratesHelpers'
import routeHelpers from './helpers/routeHelpers'
import trackingHelpers from './helpers/trackingHelpers'
import interstitialLogic from './helpers/interstitialLogic'

import engine from './actions/engine'
import engineOld from './actions/engineOld'
import experiment from './actions/experiment'

import annualPass from './reducers/annualPass'
import basket from './reducers/basket'
import bestPriceGuarantee from './reducers/bestPriceGuarantee'
import brand from './reducers/brand'
import chat from './reducers/chat'
import configuration from './reducers/configuration'
import discovery from './reducers/discovery'
import engineReducer from './reducers/engine'
import engineOldReducer from './reducers/engineOld'
import experimentReducer from './reducers/experiment'
import filters from './reducers/filters'
import modal from './reducers/modal'
import packageRates from './reducers/packageRates/index'
import payment from './reducers/payment'
import preferenceCentre from './reducers/preferenceCentre'
import seats from './reducers/seats'
import searchSummary from './reducers/searchSummary'
import vouchers from './reducers/vouchers'

import config from './configs/config'
import eventMapping from './tracking/eventMapping'

import getMessages from '../locale/getMessages'

// i18n
// Polyfill for older browsers
if (!window.Intl) {
  // Needs to be require. (dnyamic) import fails here for Safari
  window.Intl = require('intl')
}

if (typeof A11Y !== 'undefined' && A11Y) {
  const a11y = require('react-a11y').default
  a11y(React, ReactDOM, {
    rules: {
      'aria-role': 'warn',
      'aria-unsupported-elements': 'warn',
      'click-events-have-key-events': 'warn',
      'hidden-uses-tabindex': 'warn',
      'img-redundant-alt': 'warn',
      'img-uses-alt': 'warn',
      'interactive-supports-focus': 'warn',
      'label-has-for': 'warn',
      'mouse-events-have-key-events': 'warn',
      'no-access-key': 'warn',
      'no-hash-ref': 'warn',
      'onclick-uses-role': 'warn',
      'tabindex-no-positive': 'warn',
      'tabindex-uses-button': 'warn'
    },
    filterFn: (name) => {
      // This is so we don't get warnings on these components.
      // These should only be components of plugins we can't do anything about.
      return ![
        'CloseButton',
        'Modal',
        'ModalDialog',
        'Carousel'
      ].includes(name)
    }
  })
}
const {
  brandConfig,
  endpoint,
  eventProduct,
  harvestBasketData,
  packageRatesReply,
  roomThemeOrderReply,
  savedUrls,
  upgradeRatesReply
} = config

const gtmMiddleware = createMiddleware(eventMapping, GoogleTagManager())

const composeEnhancers = (typeof window !== 'undefined' && window.__REDUX_DEVTOOLS_EXTENSION_COMPOSE__) || compose

const initialState = (window.state) ? JSON.parse(window.state) : {}

const store = createStore(
  combineReducers({
    annualPass,
    basket,
    bestPriceGuarantee,
    brand,
    chat,
    configuration,
    discovery,
    engine: engineReducer,
    engineOld: engineOldReducer,
    experiment: experimentReducer,
    filters,
    modal,
    packageRates,
    payment,
    preferenceCentre,
    seats,
    searchSummary,
    vouchers
  }),
  initialState,
  composeEnhancers(
    applyMiddleware(
      thunk,
      gtmMiddleware
    )
  )
)

// These values come from a different object depending on the page were on
let roomRatesBucket = _.get(harvestBasketData, 'hotel.bucket')
if (Object.keys(packageRatesReply).length > 0) {
  roomRatesBucket = _.get(packageRatesReply, 'meta.context.roomRates.bucket', null)
}

const appState = {
  hasError: false,
  isCallCentre: brandConfig.consumerType === 'callCentre',
  isCallbackFormVisible: false,
  liveChat: {
    isLoading: false,
    isOnline: false
  },
  modalStates: {
    isContactModalVisible: false,
    isFAQsModalVisible: false,
    isTermsModalVisible: false,
    isPrivacyModalVisible: false,
    isCookieModalVisible: false
  },
  showEngineModal: false,
  roomRatesBucket
}

const EngineModal = props => (
  <Modal
    onHide={props.close}
    show={props.show}>
    <Modal.Header closeButton>
      <Modal.Title>
        <FormattedMessage id='common.amendYourSearch' />
      </Modal.Title>
    </Modal.Header>
    <Modal.Body>
      {brandConfig.secure.hasFeatures.hasPackaging ? (
        <PackagingEngine />
      ) : (
        <React.Fragment>
          {props.version === 'withTickets' ? <WithTickets /> : <CheckInCheckOut />}
        </React.Fragment>
      )}
    </Modal.Body>
  </Modal>
)

EngineModal.defaultProps = {
  version: 'checkInCheckOut'
}

class App extends React.Component {
  constructor (props) {
    super(props)
    this.state = appState

    this.initLiveChat = this.initLiveChat.bind(this)
    this.navigateTo = routeHelpers.navigateTo.bind(null)
    this.setLiveChatState = this.setLiveChatState.bind(this)
    this.showCallbackForm = this.showCallbackForm.bind(this)
    this.trackAvailabilityMessage = this.trackAvailabilityMessage.bind(this)
    props.setupExperimentEventListeners()
    if (window && window.tracker) {
      tracker.initialise({
        env: process.env.NODE_ENV === 'review' ? 'staging' : process.env.NODE_ENV,
        lb: brandConfig.secure.hasFeatures.hasTrackerLB || false,
        organisation: 'Holiday Extras Shortbreaks Limited',
        service: 'the-wall-service'
      })
    }
  }

  listenToScroll () {
    const winScroll =
      document.body.scrollTop || document.documentElement.scrollTop

    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight

    const scrolled = winScroll / height
    const scrolledPercentage = Math.round(scrolled * 100)
    if ((scrolledPercentage % 10) === 0) {
      trackingHelpers.track('sb.track', endpoint, 'Scroll Tracking', scrolledPercentage)
    }
  }

  componentDidMount () {
    // Check if we have a harvest error
    if (window.harvestHasAnError) {
      this.showCallbackForm()
    } else {
      document.addEventListener('harvestError', () => {
        this.showCallbackForm()
      })
    }
    const holdingPage = document.getElementById('holdingPage')
    holdingPage && holdingPage.parentNode && holdingPage.parentNode.removeChild(holdingPage)

    this.props.dispatchEndpoint(endpoint)

    // Track tab / window switches (its not an error, but we want to track it as 'nonInteraction')
    const {
      adults = '',
      checkinDate = '',
      checkoutDate = '',
      children = '',
      infants = '',
      nights = '',
      rooms = '',
      seatType = '',
      startDate = ''
    } = window
    // This is the same format we already track, so 'easiest' to see from what tab to what tab is being switched
    const GAPageName = `booking/${endpoint}/${seatType}/${startDate}/${nights}/${adults}/${children}/${infants}/${checkinDate}/${checkoutDate}${rooms}`

    // Instead of using window.onfocus / onblur, as that also triggers when switching between tab and dev console for example.
    // This does not.
    document.addEventListener('visibilitychange', e => {
      const isTabHidden = e && e.target && e.target.hidden
      const action = `Tab ${isTabHidden ? 'lost' : 'received'} focus`
      trackingHelpers.track('sb.track', action, endpoint, GAPageName)
    })
    document.addEventListener('scroll', this.listenToScroll)
  }

  componentWillUnmount () {
    document.removeEventListener('scroll', this.listenToScroll)
    this.props.tearDownExperimentListeners()
  }

  componentDidUpdate (prevProps) {
    if (this.props.experimentsInitialized !== prevProps.experimentsInitialized) {
      this.props.forceActiveExperiment()
    }

    // Get some visibility on pages being rendered correctly, or throwing an error
    fetch('/metrics/pageRender', {
      method: 'POST',
      body: JSON.stringify({
        endpoint,
        hasError: this.state.hasError
      }),
      headers: {
        'Content-Type': 'application/json'
      }
    }).catch(() => { })
    // Get visibility on hotels we actually show the customer
    if (endpoint === 'availability') {
      const hotels = Object.values(_.get(packageRatesReply, 'linked.hotelProducts', {}))
      let resortHotels = hotels.filter(hotelProduct => hotelProduct.isResort).length
      let offsiteHotels = hotels.length - resortHotels

      // If we show the callback form, we don't show any hotels, so set values to 0
      if (this.state.isCallbackFormVisible) {
        resortHotels = 0
        offsiteHotels = 0
      }

      fetch('/metrics/hotelCount', {
        method: 'POST',
        body: JSON.stringify({
          offsiteHotels,
          resortHotels
        }),
        headers: {
          'Content-Type': 'application/json'
        }
      }).catch(() => { })
    }
  }

  showCallbackForm (err = '') {
    if (window && window.tracker) {
      tracker.error({
        code: 'cbf001',
        source: 'client',
        message: 'showCallbackForm',
        info: err
      })
    }
    this.setState((prevState, props) => {
      return { isCallbackFormVisible: true }
    })
  }

  checkWhetherToShowCallbackForm () {
    return (
      this.state.hasError ||
      _.get(brandConfig, 'secure.hasFeatures.callbackForm.isVisible', false) ||
      (endpoint === 'availability' && !ratesHelpers.isValid(packageRatesReply)) ||
      (endpoint === 'upgrades' && !ratesHelpers.isValid(upgradeRatesReply)) ||
      this.state.isCallbackFormVisible
    )
  }

  trackAvailabilityMessage (message, ratesReply) {
    // the search date is in a specific format when tracked, e.g. 27JUL17
    const searchDate = moment(_.get(ratesReply, 'meta.context.ticketRates.startDate')).format('DDMMMYY')
    return trackingHelpers.track('sb.error', 'Availability', message, searchDate)
  }

  getProgressTracker () {
    if (!_.get(brandConfig, 'secure.hasFeatures.progressTrackers', false)) {
      return null
    }
    let activeProgressTrackerStepIndex = null
    const hasSeatsStage = _.get(brandConfig, 'secure.flow', []).find(f => f.endpoint === 'seats')
    return (
      <ProgressTracker>

        <ProgressTracker.Step
          handleAction={this.props.toggleEngineModal}
          name='Search'>
          <SVGSearch height='1em' width='1em' uniqueID='Search' viewBox='0 0 24 24' />
        </ProgressTracker.Step>

        {_.get(brandConfig, 'secure.flow', []).map((step, index) => {
          const isActive = endpoint && endpoint.includes(step.endpoint)
          if (isActive) {
            activeProgressTrackerStepIndex = index
          }
          let actionUrl = _.get(savedUrls, step.endpoint, null)
          let isActionable = (!activeProgressTrackerStepIndex || index < activeProgressTrackerStepIndex)
          if (!isActive && step.endpoint === 'seats' && endpoint === 'availability' && Object.keys(hasSeatsStage).length) actionUrl = document.referrer
          // we can only perform an action on the progress step if it's before the currently active step
          const handleAction = (isActionable && actionUrl && this.navigateTo.bind(null, actionUrl, null)) || null
          const key = `progressTracker${step.name}`
          const name = `${step.name}`
          return (
            <ProgressTracker.Step
              handleAction={handleAction}
              isActive={isActive}
              key={key}
              name={name}
            />
          )
        })}
      </ProgressTracker>
    )
  }

  initLiveChat () {
    LiveChat.init(this.setLiveChatState)
  }

  setLiveChatState (liveChatStateObject) {
    this.setState((state) => ({
      LiveChat: Object.assign({}, state.liveChat, liveChatStateObject)
    }))
  }

  componentDidCatch (error, info) {
    this.setState(() => ({
      hasError: true
    }))

    let logObject
    try {
      logObject = JSON.stringify({
        errorStack: error && error.stack && error.stack.toString(),
        componentStack: info.componentStack
      })
    } catch (error) {
      logObject = {
        error: 'JSON.stringify the logObject failed'
      }
    }

    fetch('/logging', {
      method: 'POST',
      body: logObject,
      headers: { 'Content-Type': 'application/json' }
    }).catch(() => {
      // Do nothing with this
      // We don't want to show the customer and we can't log it
    })
  }

  render () {
    let containerToRender = null
    // Callback form
    const hasVisibleCallbackForm = this.checkWhetherToShowCallbackForm()

    let packageRateToTrack = null
    if (endpoint === 'availability') {
      packageRateToTrack = packageRatesReply
    } else if ((endpoint === 'upgrades' || endpoint === 'payment')) {
      packageRateToTrack = upgradeRatesReply
    }
    // If we're showing the callback form, track it...
    hasVisibleCallbackForm && this.trackAvailabilityMessage('Callback Form Shown', packageRateToTrack)
    // ...and don't render any other containers
    const newEndpoint = hasVisibleCallbackForm ? 'callbackForm' : endpoint

    const progressTracker = this.getProgressTracker()

    let containerClassName = 'container'
    if (newEndpoint === 'seats') {
      containerClassName = 'container-fluid'
    }

    if (newEndpoint === 'availability' && brandConfig.secure.hasFeatures.filters && this.props.experiment) {
      const filterSplitTestName = 'NB-1181: Filters'
      // Having to use the state here because at this point we've not actually dropped into
      // any <Experiment /> components therefore no state is set.
      // So by using state here, we force the re-check of this block & can
      // then grab the variant using emitter (see: https://www.npmjs.com/package/react-ab-test#emitter)
      if (this.props.experiment.active[filterSplitTestName]) {
        const filtersVariant = emitter.getActiveVariant(filterSplitTestName)
        if (filtersVariant === 'filters') {
          containerClassName = 'container-fluid'
        }
      }
    }
    let pageType
    switch (newEndpoint) {
      case 'callbackForm':
        containerToRender = (
          <CallbackForm
            isCallCentre={this.state.isCallCentre}
          />
        )
        break
      case 'seats':
        containerToRender = (
          <Seats
            isCallCentre={this.state.isCallCentre}
            navigateTo={this.navigateTo}
            nextEndpoint={this.nextEndpoint}
            progressTracker={progressTracker}
            showCallbackForm={this.showCallbackForm} />
        )
        break
      case 'interstitial':
        interstitialLogic.checkBasketBuilderServiceReply()
        break
      case 'availability':
        pageType = 'search_results'
        containerToRender = (
          <AvailabilityContainer
            isCallCentre={this.state.isCallCentre}
            navigateTo={this.navigateTo}
            nextEndpoint={this.nextEndpoint}
            progressTracker={progressTracker}
            roomThemeOrder={roomThemeOrderReply}
            showCallbackForm={this.showCallbackForm}
            trackAvailabilityMessage={this.trackAvailabilityMessage}
            pageType={pageType} />
        )
        break
      case 'landing':
        containerToRender = (
          <LandingPageContainer />
        )
        break
      case 'moreinformation':
        pageType = 'product_info'
        containerToRender = (
          <MoreInformation
            nextEndpoint={this.nextEndpoint}
            navigateTo={this.navigateTo}
            progressTracker={progressTracker}
            showCallbackForm={this.showCallbackForm}
            sundryRates={window.sundryRates}
            pageType={pageType} />
        )
        containerClassName = ''
        break
      case 'payment':
        pageType = 'checkout'
        containerToRender = (
          <PaymentContainer
            isCallCentre={this.state.isCallCentre}
            progressTracker={progressTracker}
            showCallbackForm={this.showCallbackForm}
            sundryRates={window.sundryRates}
            pageType={pageType} />
        )
        break
      case 'preferencecentre':
        containerToRender = (
          <PreferenceCentre
            isCallCentre={this.state.isCallCentre}
          />
        )
        break
      case 'upgrades':
        pageType = 'addon_results'
        containerToRender = (
          <UpgradesContainer
            navigateTo={this.navigateTo}
            nextEndpoint={this.nextEndpoint}
            progressTracker={progressTracker}
            showCallbackForm={this.showCallbackForm}
            sundryRates={window.sundryRates}
            pageType={pageType} />
        )
        break
    }

    return (
      // Prevent showing page until CSS is loaded
      <div id='appRoot' style={{ visibility: 'hidden' }}>
        <Header pageType={pageType} />

        {/* @todo Ideally we shouldn't be using Portal for this */}
        {newEndpoint === 'availability' && <div id='header' className='headerContainer' />}

        <div className={containerClassName}>
          {containerToRender}
        </div>

        <Footer
          contactMessage={eventProduct && eventProduct.contactInformation}
          endpoint={endpoint}
          handleOpenLiveChat={LiveChat.open}
          initLiveChat={this.initLiveChat}
          liveChatState={this.state.liveChat}
        />

        <EngineModal
          close={this.props.toggleEngineModal}
          show={this.props.showEngineModal}
          version={brandConfig.secure.engineVersion}
        />

        {this.props.engineIsSubmitting && (
          <Loading />
        )}
      </div>
    )
  }
}

function mapStateToProps (state) {
  return {
    experiment: state.experiment,
    engineIsSubmitting: state.engineOld.isSubmitting,
    experimentsInitialized: state.experiment.initialized,
    showEngineModal: state.engineOld.modalVisible || state.engine.modalVisible,
    visibleModals: state.modal.visible
  }
}

function mapDispatchToProps (dispatch) {
  return {
    dispatchEndpoint: endpoint => {
      if (!endpoint) return
      dispatch({
        type: `GET_${endpoint.toUpperCase()}_PAGE_DATA`
      })
    },
    setupExperimentEventListeners: () => {
      return dispatch(experiment.setupEventListeners())
    },
    tearDownExperimentListeners: () => {
      return dispatch(experiment.tearDownListeners())
    },
    toggleEngineModal: () => {
      if (brandConfig.secure.hasFeatures.hasPackaging) return dispatch(engine.toggleModal())
      return dispatch(engineOld.toggleModal())
    },
    forceActiveExperiment: () => {
      return dispatch(experiment.forceActive())
    }
  }
}

// Exports for running with Redux in browser and for unit-testing (App)
const AppWithRedux = connect(mapStateToProps, mapDispatchToProps)(App)

ReactDOM.render(
  <IntlProvider locale='en' messages={getMessages(window.brandConfig)} textComponent='span'>
    <Provider store={store}>
      <AppWithRedux />
    </Provider>
  </IntlProvider>, document.getElementById('appContainer'))
