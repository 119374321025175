import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

class DisplayPrice extends PureComponent {
  render () {
    const roundedPrice = this.props.operator ? Math[this.props.operator](this.props.price) : parseFloat(this.props.price).toFixed(2)

    // Show price as 'Included' if its £0.00
    if (Number(roundedPrice) === 0 && !this.props.showZeroAsPrice) {
      return (
        <FormattedMessage id='common.included' />
      )
    }

    if (this.props.locale === 'en') {
      return (
        <span className='display-price'>{`\u00A3${roundedPrice}`}</span>
      )
    }

    return (
      <span className='display-price'>{roundedPrice}</span>
    )
  }
}

DisplayPrice.defaultProps = {
  locale: 'en',
  showZeroAsPrice: false
}

DisplayPrice.propTypes = {
  operator: PropTypes.oneOf(['ceil', 'floor', 'round']),
  price: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]).isRequired,
  showZeroAsPrice: PropTypes.bool
}

export default DisplayPrice
