import classNames from 'classnames'
import React from 'react'
import PropTypes from 'prop-types'

import trackingHelpers from '../../helpers/trackingHelpers'

const PaymentChoiceTypeButton = ({
  additionalClassNames = '',
  checked = false,
  children,
  disabled = false,
  id,
  name = 'paymentChoice',
  onChange = () => { },
  value
}) => {
  const className = classNames('control-label payment-choice__type-button', additionalClassNames, {
    'disabled': disabled
  })
  return (
    <label {...trackingHelpers.getAttributes('Payment Choice', 'Payment', value)} className={className}>
      <React.Fragment>
        <input
          checked={checked}
          disabled={disabled}
          id={id}
          name={name}
          onChange={() => {
            trackingHelpers.track('sb.track', 'Payment Choice', 'Payment', value)
            onChange(value)
          }}
          type='radio'
          value={value} />
        {children}
      </React.Fragment>
    </label>
  )
}

PaymentChoiceTypeButton.propTypes = {
  additionalClassNames: PropTypes.string,
  checked: PropTypes.bool.isRequired,
  children: PropTypes.node.isRequired,
  disabled: PropTypes.bool,
  id: PropTypes.string.isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired
}

export default PaymentChoiceTypeButton
