import helpers from './helpers'
const packageRates = (state = {
  packages: [],
  filtered: [],
  offsite: [],
  resort: [],
  total: 0
}, action) => {
  switch (action.type) {
    case 'FILTERED_PACKAGES':
      return Object.assign({}, state, {
        filtered: action.packages
      })

    case 'RECEIVED_PACKAGE_RATES':

      const brand = action.brand
      const roomThemeOrdering = action.roomThemeOrdering

      // ================================
      // ==== ADDITIONAL NIGHTS REPLY ===
      // ================================

      // Structure both the standard package rates reply & the additional nights reply if we have it.
      const packageList = helpers._restructurePackageRates(action.standard.packageRates, action.standard.linked, brand)
      const additionalNightList = helpers._restructurePackageRates(action.additionalNight.packageRates, action.additionalNight.linked, brand)

      // Attach additional nights replies
      const listWithAdditionalNights = helpers._mergeAdditionalNights(packageList, additionalNightList, action.hotelOfferCodes)

      // ==============================
      // ==== HOTEL EVENT PRODUCTS ====
      // ==============================

      // Overwrite existing values with ones found in hotelEventProducts
      const listWithHotelEventProductsOverwrite = helpers._mergeHotelEventProducts(listWithAdditionalNights, action.standard.linked)

      // ==============================
      // ==== ONSITE/OFFSITE SPLIT ====
      // ==============================

      // Now put the package rates into their respective onsite/offsite pots
      const list = helpers._buildOnsiteOffsiteList(listWithHotelEventProductsOverwrite)

      // TODO: Figure out why the themes are giving back multiple accessible room themes
      // TODO: CHEXXX has no tag... has this broken.

      // =================
      // ==== SORTING ====
      // =================

      const listThemesToArray = helpers._convertThemesToArray(list.resort)
      const listSortedThemes = helpers._sortThemes(listThemesToArray, roomThemeOrdering)
      const listThemeSortedRooms = helpers._sortThemeRooms(listSortedThemes, roomThemeOrdering)

      const packages = [].concat(
        helpers._sortObjectsByArray(brand.secure.hotelOrder, listThemeSortedRooms, 'id'),
        helpers._sortObjectsByArray(brand.secure.hotelOrder, list.offsite, 'id')
      )

      // Total available hotel list
      const total = packages.length

      // ===============
      // ==== FINISH ===
      // ===============
      return Object.assign({}, state, {
        packages,
        total
      })
    case 'RECEIVED_GROUPED_THEMES':
      return Object.assign({}, state, {
        hotelPackageRate: action.hotelPackageRate
      })
    default:
      return state
  }
}

export default packageRates
