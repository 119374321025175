import 'isomorphic-fetch'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Col, Panel, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { forceCheck } from 'react-lazyload'
import { AutoAffix } from 'react-overlays'
import { connect } from 'react-redux'

import { query } from './components/atoms/BreakPoint'
import EventInformation from './components/atoms/EventInformation'

import FixedSearchSummary from './components/atoms/SearchSummary/FixedSearchSummary'
import SVGSpyglass from './components/atoms/SVGSpyglass'
import SimpleSearchSummary from './components/atoms/SearchSummary/SimpleSearchSummary'
import StaticSearchSummary from './components/atoms/SearchSummary/StaticSearchSummary'

import ErrorHandler from './components/molecules/ErrorHandler'
import HotelSorter from './components/molecules/HotelSorter'
import Portal from './components/molecules/Portal'

import CallbackForm from './components/organisms/CallbackForm'
import HotelList from './components/organisms/HotelList'

import DiscoveryModal from './containers/DiscoveryModal'
import Filters from './containers/Filters'
import SplitTest from './containers/SplitTest'

import Calendar from './containers/Discovery/Calendar'

import basketHelpers from './helpers/basketHelpers'
import calculationHelpers from './helpers/calculationHelpers'
import ratesHelpers from './helpers/ratesHelpers'
import resourceHelpers from './helpers/resourceHelpers'
import routeHelpers from './helpers/routeHelpers'
import sortingHelpers from './helpers/sortingHelpers'
import trackingHelpers from './helpers/trackingHelpers'

import discovery from './actions/discovery'
import engine from './actions/engine'
import engineOld from './actions/engineOld'
import modal from './actions/modal'
import packageRates from './actions/packageRates'
import searchSummary from './actions/searchSummary'

import roomTypes from './configs/roomTypes'
import config from './configs/config'

const {
  additionalNightPackageRatesReply,
  brandConfig,
  endpoint,
  hotelOfferCodesReply,
  packageRateCallbacks,
  packageRatesReply
} = config

const venueProduct = _.get(packageRatesReply, 'linked.venueProducts', {})
const eventProduct = _.get(packageRatesReply, 'linked.eventProducts', {})
const venueProductId = Object.keys(venueProduct)[0]
const venueType = venueProduct[venueProductId] && venueProduct[venueProductId].venueType

class AvailabilityContainer extends Component {
  constructor (props) {
    super(props)

    // to be used to load responsive images on child components
    if (query.isXs()) this.sbImageBucketSize = '?w=450'
    if (query.isSm()) this.sbImageBucketSize = '?w=600'
    if (query.isMd()) this.sbImageBucketSize = '?w=750'
    if (query.isLg()) this.sbImageBucketSize = '?w=900'

    const context = _.get(packageRatesReply, 'meta.context', {})

    // collect hotel specific inventory messages
    brandConfig.secure.inventoryLevelMessages = _.get(brandConfig, 'secure.inventoryLevelMessages', [])
    const hotelProducts = _.get(packageRatesReply, 'linked.hotelProducts')
    for (var hotel in hotelProducts) {
      const hotelProduct = hotelProducts[hotel]
      if (typeof (hotelProduct.inventoryMessage) !== 'undefined' && hotelProduct.inventoryMessage[0].message) {
        brandConfig.secure.inventoryLevelMessages.push({
          [hotelProduct.id]: hotelProduct.inventoryMessage
        })
      }
    }

    const isResortHotelProviderAvailable = _.get(packageRatesReply, 'meta.providerStatuses.operaWebServices.isInService', true) && !_.get(packageRatesReply, 'meta.providerStatuses.operaWebServices.isDisabled', false)

    // Destructure all the things (with some default values so we don't fall over it when its not defined)
    const {
      assetsUrl,
      flow,
      hasFeatures = {},
      hotelImagePath,
      hotelOfTheMonth = [],
      hotelOrder = [],
      packageInfo = ''
    } = brandConfig.secure || {}
    this.assetsUrl = assetsUrl
    this.eventProducts = _.get(packageRatesReply, 'linked.eventProducts')
    // We need this still as not all brands have 'moreinformation' stage
    // so we need to know if we want to open the modal or go to moreinformation page
    this.featuredHotel = brandConfig.secure.hasFeaturedHotel ? _.get(packageRatesReply, 'meta.context.hotelCode', null) : null
    this.handleAffix = this.handleAffix.bind(this)
    this.hasFeatures = hasFeatures
    this.hasMoreInformationStage = flow.find(f => f.endpoint === 'moreinformation') !== undefined
    this.hasNoAvailabilityCalendar = _.get(brandConfig, 'secure.hasFeatures.availability.hasNoAvailabilityCalendar', false)
    this.hotelEventProducts = _.get(packageRatesReply, 'linked.hotelEventProducts', null)
    this.hotelImagePath = hotelImagePath
    this.hotelInventoryLevel = []
    this.hotelListElement = null
    this.hotelOrder = hotelOrder
    this.hotelOfTheMonth = hotelOfTheMonth
    this.hotelPackageRates = {}
    this.packageInfo = packageInfo
    this.queryStringParams = routeHelpers.getQueryStringParams()
    this.roomProducts = _.cloneDeep(_.get(packageRatesReply, 'linked.roomProducts')) || {}
    this.showCombinedHotelsList = isResortHotelProviderAvailable && _.get(this.hasFeatures, 'availability.hasCombinedHotelsList')
    this.alertInfoMobileClassName = (_.get(brandConfig, 'secure.hasFeatures.useAlertInfoMobileView', false)) ? 'alert-mobile-view' : ''
    this.extraNightsFeature = _.get(config, 'brandConfig.secure.hasFeatures.availability.hasExtraNights', false)

    let extraNights = this.hotelsBuilder(additionalNightPackageRatesReply, false, true)
    if (this.hasFeatures.resortHotels) {
      extraNights = (extraNights || []).concat(this.hotelsBuilder(additionalNightPackageRatesReply, true, true))
    }

    // Get hotels that are NOT resort
    const offSite = this.hotelsBuilder(packageRatesReply, false, false)

    // Get unique hotels, based on hotel id, that ARE resort (if config tells us that we should)
    const resort = this.hasFeatures.resortHotels && this.hotelsBuilder(packageRatesReply, true, false)

    let offerCodesMapping
    if (_.get(packageRatesReply, 'meta.context.packageGroupId')) {
      // if we're on packaging, use the offer hotel ids in the roomRates
      offerCodesMapping = this.buildOfferHotelsMapping(offSite, resort)
    } else {
      // we're not on packaging so use the transformer offer hotel mapping
      offerCodesMapping = hotelOfferCodesReply
    }

    this.state = {
      additionalNightPackageRatesReply: _.cloneDeep(additionalNightPackageRatesReply),
      checkoutDate: _.get(context, 'roomRates.checkoutDate', null),
      customerCode: context.customerCode || null,
      harvestData: {},
      hasMessage: {},
      hasResortMessage: {
        offSiteOfferResort: false,
        offSiteOfferResortLink: '',
        ratePlanIssueResort: false
      },
      hideResortMessage: false,
      hotelReviews: [],
      hotelRooms: [],
      hotels: {
        combined: [],
        extraNights,
        offerCodesMapping,
        offSite,
        resort
      },
      isResortHotelProviderAvailable,
      isResortHotelsUnavailableMessageVisible: this.hasFeatures.isResortHotelsUnavailableMessageVisible,
      // We only want to expand the summary on mobile if we are not scrolling to a hotel on the page.
      // That way we are not obscuring the hotel name with the summary.
      isVideoAutoplay: false,
      offsiteHotelsOnly: false,
      packageRatesReply: _.cloneDeep(packageRatesReply),
      productModalState: {
        supplementary: {}
      },
      roomComposition: {
        adults: _.get(context, 'roomRates.adults', 0),
        children: _.get(context, 'roomRates.children', 0),
        infants: _.get(context, 'roomRates.infants', 0)
      },
      selectedHotel: null,
      sortBy: this.queryStringParams.sortBy || _.get(this.hasFeatures, 'availability.defaultHotelSortProperty', 'defaultOrder'),
      supplementaryModal: {}
    }

    // TODO - Assign these variables as props outside of this component
    this.addToBasket = this.addToBasket.bind(this)
    this.assignHotelInventory = this.assignHotelInventory.bind(this)
    this.changePage = this.changePage.bind(this)
    this.handleAddProduct = this.handleAddProduct.bind(this)
    this.handleGetReviews = this.handleGetReviews.bind(this)
    this.handleGetSessionBasketData = basketHelpers.handleGetSessionBasketData.bind(this)
    this.handleSetSessionBasketData = basketHelpers.handleSetSessionBasketData.bind(this)
    this.handleStoreHotel = this.handleStoreHotel.bind(this)
    this.handleStoreHotelDetails = this.handleStoreHotelDetails.bind(this)
    this.handleStoreHotelPackageRates = this.handleStoreHotelPackageRates.bind(this)
    this.hotelsBuilder = this.hotelsBuilder.bind(this)
    this.onToggleModal = this.props.onToggleModal.bind(this)
    this.setEventDates = this.setEventDates.bind(this)
    this.setProductModalState = this.setProductModalState.bind(this)
    this.setSupplementaryModalState = this.setSupplementaryModalState.bind(this)
    this.sortHotels = this.sortHotels.bind(this)

    if (packageRatesReply && packageRatesReply.linked && _.has(packageRatesReply, 'linked.eventProducts') && _.has(packageRatesReply, 'linked.ticketRates')) {
      packageRateCallbacks.push(this.updatePackageRates.bind(this))
      // get ticket name based on an unknown product key
      const _ticketKey = [Object.keys(packageRatesReply.linked.eventProducts)[0]]
      this.state.ticketName = packageRatesReply.linked.eventProducts[_ticketKey].name

      // get ticket code
      const _ticketCodeKey = Object.keys(packageRatesReply.linked.ticketRates)[0]
      this.state.defaultTicketCode = packageRatesReply.linked.ticketRates[_ticketCodeKey].links.eventProducts.ids[0]
      this.state.hasBestPriceGuarantee = packageRatesReply.linked.ticketRates[_ticketCodeKey].hasBestPriceGuarantee
      this.state.hasParkEntry = packageRatesReply.linked.ticketRates[_ticketCodeKey].hasParkEntry
      this.state.isAnnualPass = packageRatesReply.linked.ticketRates[_ticketCodeKey].isAnnualPass
      this.state.offsiteHotelsOnly = packageRatesReply.linked.ticketRates[_ticketCodeKey].isOffsiteHotelsOnly
      const ticketCodesFromRates = Object.keys(_.get(packageRatesReply, 'linked.ticketRates', {})).map(key => {
        const rate = packageRatesReply.linked.ticketRates[key]
        return `${rate.bucket}${rate.code}`
      })

      this.state.hideResortMessage = ticketCodesFromRates.some(ticketCode => [
        'LKFWAR',
        'LKGWCB',
        'LKFWAT',
        'LKGWCD',
        'LKCWLN',
        'LKCWLP',
        'LKDWLR',
        'LKDWLT'
      ].includes(ticketCode))

      this.state.hasResortMessage.offSiteOfferResort = true
      // Just applies to the LLH codes above at present - to be addressed for all edge cases and brands in full solution: https://hxshortbreaks.atlassian.net/browse/PARTNERS-72
      this.state.hasResortMessage.offSiteOfferResortLink = <a href='https://www.legolandholidays.co.uk/offers/kids-go-free.html'> Resort properties</a>

      // There is an issue with messaging when we have different package rates across multiple nights search
      // Not sure this is the solution, this needs a robust solution further up stack
      // If this is accepted can be further improved/fixed with (with all offer etc messaging considered):
      // https://hxshortbreaks.atlassian.net/browse/PARTNERS-72
      // Temporarily disabled to ease to load on GX (24th June)
      if (Object.keys(packageRatesReply.linked.ticketRates).length === 1 &&
        packageRatesReply.linked.eventProducts[_ticketKey].duration !== 0 &&
        _.get(this.hasFeatures, 'availability.enableRateplanIssueMessage')) {
        this.state.hideResortMessage = true
        this.state.hasResortMessage.ratePlanIssueResort = true
        this.state.hasResortMessage.offSiteOfferResort = false
      }
    }

    props.getPackageRates().then(() => this.sortHotels(this.state.sortBy))
  }

  componentWillMount () {
    this.generateAvailabilityMessages()
    if (this.hasFeatures.resortHotels) {
      if (!this.state.hotels.resort.length) {
        this.setState(() => ({
          isResortHotelsUnavailableMessageVisible: true
        }))
      } else {
        // we have some resort hotels lets assign the total number of rooms available in each hotel stored in hotelInventoryLevel
        this.assignHotelInventory(this.state.hotels.resort)
      }
    }
  }

  componentDidUpdate (prevProps) {
    if (this.props.maxParty && prevProps.maxParty !== this.props.maxParty) this.generateAvailabilityMessages()
  }

  componentDidMount () {
    this.handleGetSessionBasketData('hotelProducts,ticketProducts,roomProducts')
    if (window && window.tracker) {
      tracker.page(
        'availability', {
          page_type: this.props.pageType
        }
      )
    }
    this.props.checkDiscoveryPrimed()
  }

  handleAffix (top) {
    if (top) {
      // when user scrolls down and position:fixed is set on summary panel
      this.props.toggleIsSummaryElementExpanded(false)
      this.props.toggleIsSummaryTitleClickable(true)
    } else {
      this.props.toggleIsSummaryElementExpanded(true)
      this.props.toggleIsSummaryTitleClickable(false)
    }
  }

  generateAvailabilityMessages () {
    const hasMessage = {}
    const offsitePackagesLength = (this.state.hotels.offSite) ? this.state.hotels.offSite.length : 0
    const resortPackagesLength = (this.state.hotels.resort) ? this.state.hotels.resort.length : 0
    const context = _.get(this.state, 'packageRatesReply.meta.context', {})
    const hasAvailability = resortPackagesLength !== 0 || offsitePackagesLength !== 0

    if (!this.eventProducts && hasAvailability) {
      hasMessage.missingEventProducts = true
      this.props.trackAvailabilityMessage('Missing Events Products', packageRatesReply)
    } else if (!hasAvailability && !this.state.isResortHotelProviderAvailable) {
      // If we have no availability and the resort hotel provider is unavailable
      hasMessage.noAvailability = false
      this.props.trackAvailabilityMessage('No 3rd Party Availability', packageRatesReply)
    } else if (!hasAvailability) {
      // This can be removed once all our partners are using the updated search form
      // The updated search form will not allow you to make request that exceeds the maxParty
      // https://github.com/holidayextras/sb-search-form/pull/103
      const { adults = 0, children = 0 } = context.roomRates || {}
      const numberInParty = adults + children
      if (this.props.maxParty && numberInParty > this.props.maxParty) {
        // Display max party exceeded error
        hasMessage.exceededMaxParty = true
        this.props.trackAvailabilityMessage('PartyMax number exceeded', packageRatesReply)
      } else {
        // if we have no availability, we want to show the noAvail message
        hasMessage.noAvailability = true
        this.props.trackAvailabilityMessage('No Total Availability', packageRatesReply)
      }
    } else {
      if (resortPackagesLength === 0) {
        hasMessage.noResortAvailability = true
        this.props.trackAvailabilityMessage('No Onsite Availability', packageRatesReply)
      }
      if (offsitePackagesLength === 0) {
        hasMessage.noOffsiteAvailability = true
        this.props.trackAvailabilityMessage('No Offsite Availability', packageRatesReply)
      }
      const rooms = _.get(context, 'roomRates.rooms', null)
      if (rooms && this.state.roomComposition.infants > (rooms.length)) {
        hasMessage.tooFewCots = true
        this.props.trackAvailabilityMessage('Too few rooms for cots', packageRatesReply)
      } else if (this.state.roomComposition.infants > 0) {
        hasMessage.cots = true
        this.props.trackAvailabilityMessage('Cots', packageRatesReply)
      }

      if (this.featuredHotel) {
        const featuredHotelAvailable = this.state.hotels.offSite.find(hotel => hotel.id === this.featuredHotel) !== undefined

        if (!featuredHotelAvailable) {
          hasMessage.noFeaturedHotel = true
          this.props.trackAvailabilityMessage('Featured hotel not available')
        }
      }
    }

    if (Object.keys(hasMessage).length === 0) return

    return this.setState(() => ({
      hasMessage
    }))
  }

  updatePackageRates (packageRatesReply, additionalNightPackageRatesReply) {
    this.hotelPackageRates = {}
    this.roomProducts = _.cloneDeep(_.get(packageRatesReply, 'linked.roomProducts', null))
    const offSiteHotels = this.hotelsBuilder(packageRatesReply, false, false)
    const resortHotels = this.hasFeatures.resortHotels ? this.hotelsBuilder(packageRatesReply, true, false) : []
    const extraNights = this.hotelsBuilder(additionalNightPackageRatesReply, false, true)

    this.setState((state) => ({
      packageRatesReply,
      additionalNightPackageRatesReply,
      hotels: Object.assign({}, state.hotels, {
        offSite: offSiteHotels,
        resort: resortHotels,
        extraNights
      })
    }), () => {
      this.sortHotels(this.state.sortBy)
      this.generateAvailabilityMessages()
    })
  }

  filterResortHotels (packageRatesReply, getResortHotels) {
    return _.get(packageRatesReply, 'packageRates', [])
      .filter(packageRate => {
        // If we don't have an id, things break
        if (!packageRate.id) return false
        const hotel = packageRatesReply.linked.roomRates[packageRate.links.roomRates.ids[0]]
        const hotelIsResort = hotel.isResort
        if (getResortHotels) {
          return hotelIsResort && hotel
        }
        return !hotelIsResort && hotel
      })
  }

  // sort our hotel packageRates by a property
  sortHotels (property = 'defaultOrder') {
    // If we want the default order, get it from the hotelOrder from transformer and sort offsite hotels by it, otherwise, sort by property
    // Reset to default order each time the property changes to avoid inconsistent sorting due to same property values (same price / same milesToPark)
    const defaultOrderSorting = sortingHelpers.sortByOrderArray(this.hotelOrder, 'id')
    const sortByProperty = sortingHelpers.sortByProperty(property)
    const sortByGhostPackage = sortingHelpers.sortByProperty('ghostPackage')

    const resortHotels =
      (this.hasFeatures.resortHotels &&
        this.state.hotels.resort &&
        sortingHelpers.sortHotelsAndRooms(this.state.hotels.resort, this.hotelOrder)) || []
    let offSiteHotels = this.state.hotels.offSite.sort(defaultOrderSorting)
    let combined = offSiteHotels.concat(resortHotels).sort(defaultOrderSorting)
    let hotelList = this.props.hotelList.sort(defaultOrderSorting)

    if (property !== 'defaultOrder') {
      resortHotels.sort(sortByProperty)
      offSiteHotels.sort(sortByProperty)
      combined.sort(sortByProperty)
      hotelList.sort(sortByProperty)
    }

    // Move the ghost hotels to the bottom of the list when sorting by price
    if (property === 'grossPriceInPence') {
      resortHotels.sort(sortByGhostPackage)
      offSiteHotels.sort(sortByGhostPackage)
      combined.sort(sortByGhostPackage)
      hotelList.sort(sortByGhostPackage)
    }

    const element = this.hotelListElement
    // Set the opacity to 0 for when sorting is clicked after initial render
    // Adding/Removing classes will be handled in the callback to setState
    if (element) {
      element.style.opacity = 0
    }

    this.setState((state) => ({
      hotels: Object.assign({}, state.hotels, {
        offSite: offSiteHotels,
        resort: resortHotels,
        combined: _.compact(combined),
        hotelList: hotelList
      }),
      sortBy: property
    }), () => {
      if (element) {
        element.classList.remove('fadeIn')
        setTimeout(() => {
          element.style.opacity = 1
          element.classList.add('fadeIn')
        }, 100)
      }
      // Timeout is needed to check whether an image is visible in browser when the page first loads
      setTimeout(() => {
        forceCheck()
      }, 500)
    })
  }

  isRoomCodeInOrderArray (roomProductsId = '', orderArray = []) {
    if (roomProductsId === '' || !orderArray.length) return false

    // For each code in order array, return true if the id is a room code
    return orderArray.filter(code => roomProductsId === code).length > 0
  }

  // flatten our API response into an array of hotels, ready for our HotelsList
  hotelsBuilder (packageRatesReply, getResortHotels = false, isExtraNight, orderArray = this.hotelOrder) {
    // Filter resort or offsite hotels
    const hotels = this.filterResortHotels(packageRatesReply, getResortHotels)

    return hotels.map((packageRate, index) => {
      const hotel = packageRatesReply.linked.hotelProducts[packageRate.links.hotelProducts.ids] || {}
      if (!Object.keys(hotel).length) return null
      let cheapestResortPrice = null

      if (hotel.isResort) {
        // store hotel room rates per hotel
        this.handleStoreHotelPackageRates(packageRate, hotel.id, isExtraNight)

        // Get the cheapest room from the hotel.
        // This is what we display to the customer, but we also need to be able to sort on it.
        const cheapestRoom = _.minBy(this.hotelPackageRates[hotel.id], 'grossPrice')

        cheapestResortPrice = cheapestRoom.grossPrice
      }

      const roomProductsId = _.get(packageRate, 'links.roomProducts.ids', null)
      const roomProducts = _.get(packageRatesReply, ['linked', 'roomProducts', roomProductsId], null)

      // Are we a room?
      const isRoom = roomProducts && this.isRoomCodeInOrderArray(roomProducts.id, orderArray)
      const roomRate = packageRatesReply.linked.roomRates[packageRate.links.roomRates.ids]

      let mappedId = isRoom ? roomProductsId[0] : (hotel.id || '')

      if (hotel.isResort && this.hotelInventoryLevel) {
        // calculate the hotel inventory level by adding up the inventory level of each room type returned for that hotel
        if (!_.get(this.hotelInventoryLevel, mappedId)) {
          this.hotelInventoryLevel[mappedId] = 0
        }
        this.hotelInventoryLevel[mappedId] += roomRate.inventoryLevel || 0
      }

      const context = packageRatesReply.meta.context
      const eventProductId = ratesHelpers.getEventProductIdForPackageRate(packageRate, context)
      const eventProduct = _.get(packageRatesReply, ['linked', 'eventProducts', eventProductId], {})

      const linkedTicketRates = _.get(packageRatesReply, ['linked', 'ticketRates'], {})
      const ticketRateId = ratesHelpers.getTicketRateIdForPackageRate(packageRate, context, linkedTicketRates)
      const ticketRate = _.get(packageRatesReply, ['linked', 'ticketRates', ticketRateId], {})

      // Find hotel information specific to a ticket (hotelEventProducts) if it exists
      const foundHotelEventProduct = _.find(this.hotelEventProducts, (obj, key) => key.slice(0, 6) === hotel.id)
      const hotelEventProduct = foundHotelEventProduct && foundHotelEventProduct.description

      // Calculations for per person pricing
      const adults = Number(_.get(context, 'roomRates.adults', 0))
      const children = Number(_.get(context, 'roomRates.children', 0))
      // If we're a room, use the roomRate price
      const grossPrice = cheapestResortPrice || packageRate.grossPrice || 0
      let ticketContext = context.ticketRates
      if (!ticketContext.adults) {
        const { packageTypeId } = packageRate
        const packageContext = packageRatesReply.meta[packageTypeId].context
        ticketContext = packageContext.ticketRates
      }
      // this clones the hotel object
      const mapped = _.cloneDeep(hotel)
      const mappedImages = isRoom ? roomProducts.images : mapped.images

      mapped.ages = context.ages || []
      mapped.guests = context.guests || []
      mapped.adults = adults
      mapped.bucket = _.get(context, 'roomRates.bucket', '')
      mapped.children = children
      mapped.checkInDate = roomRate.checkinDate || moment().format('YYYY-MM-DD')
      mapped.checkOutDate = roomRate.checkoutDate || moment().format('YYYY-MM-DD')
      mapped.defaultOrder = index
      mapped.description = isRoom ? roomProducts.description : mapped.description
      mapped.grossPrice = grossPrice
      mapped.grossPriceInPence = grossPrice * 100
      mapped.hasNightTail = roomRate.hasNightTail || false
      mapped.hotelEventProduct = hotelEventProduct || null
      mapped.packageTotal = grossPrice
      mapped.paidParkingAmount = hotel.paidParkingAmount || null
      mapped.summaryTypeMessage = eventProduct.summary_type_message || null
      mapped.hotelEventProduct = hotelEventProduct || null
      mapped.hasMoreThanOneRoom = packageRatesReply.packageRates.filter(packageRate => _.get(packageRate, 'links.hotelProducts.ids', []).includes(hotel.id)).length > 1

      if (this.hasFeatures.perPerson && grossPrice) {
        const numberInParty = adults + children || 0 // This will evaluate to true and return the number when adults and / or children is defined
        mapped.perPersonPrice = grossPrice / numberInParty
      }
      // discounts are not compatible with per person pricing, so we only want to show this if per person isn't active
      if (!this.hasFeatures.perPerson) {
        mapped.standardPrice = (packageRate.standardPrice) ? packageRate.standardPrice : null
      }

      // Is hotel of the month?
      mapped.isHotelOfTheMonth = hotel.id === this.hotelOfTheMonth
      mapped.hasTicketNameOnCTA = _.get(this.hasFeatures, 'availability.hasTicketNameOnCTA', false)

      // If we're a room, use the room's ID
      mapped.id = mappedId
      mapped.isRoom = isRoom
      mapped.images = resourceHelpers.generateImageVariations(mappedImages, mapped.id)
      mapped.infants = parseInt(_.get(context, 'roomRates.numInfants', 0), 10)
      mapped.inventoryLevel = roomRate.inventoryLevel || null
      mapped.isCotConfirmationRequired = roomRate.isCotConfirmationRequired || false
      mapped.latitude = Number(mapped.latitude) || 51
      mapped.longitude = Number(mapped.longitude) || 0
      mapped.addressLocality = mapped.addressLocality || ''
      mapped.location = {
        lat: mapped.latitude,
        long: mapped.longitude
      }
      let distanceCoords = {
        lat: parseFloat(venueProduct.latitude),
        long: parseFloat(venueProduct.longitude)
      }
      // Checks for venue product to calculate distance (e.g. Encore Tickets - distance to theatre over theme park)
      const venueProducts = _.get(packageRatesReply, 'linked.venueProducts', null)
      if (venueProducts) {
        const { latitude, longitude } = venueProducts[Object.keys(venueProducts)[0]]
        if (latitude && longitude) {
          distanceCoords = {
            lat: parseFloat(latitude),
            long: parseFloat(longitude)
          }
        }
      }
      mapped.milesToPark = mapped.milesToPark || calculationHelpers.calculateLatLongDistance(mapped.location, distanceCoords)
      mapped.milesToPark = mapped.milesToPark ? parseFloat(mapped.milesToPark) : 0
      mapped.nights = roomRate.nights || ''
      mapped.packageId = packageRate.id || ''
      mapped.roomId = isRoom ? roomProductsId[0] : null
      mapped.rooms = _.get(context, 'roomRates.rooms', [])
      mapped.roomName = isRoom ? roomProducts.name : null
      mapped.roomProducts = roomProducts
      mapped.roomRates = roomRate
      mapped.reviewsUrl = _.get(hotel, 'links.reviews.href', null)
      mapped.starRating = parseInt(mapped.starRating, 10) || 0
      mapped.ticketRates = ticketContext || {}
      mapped.ticketRates.endDate = ticketRate.endDate || false
      mapped.ticket = {
        ...eventProduct,
        id: `${ticketRate.bucket}${ticketRate.code}`,
        contentId: ticketRate.contentId
      }
      mapped.telephone = mapped.telephone || ''
      return mapped
    }).filter(Boolean)
  }

  buildOfferHotelsMapping (offSiteHotels, resortHotels) {
    let offerHotelsMapping = {}

    // offsites map to a different hotel returned in the additional nights response
    offSiteHotels && offSiteHotels.forEach(hotel => {
      if (hotel.roomRates && hotel.roomRates.offerHotelId) {
        offerHotelsMapping[hotel.id] = hotel.roomRates.offerHotelId
      }
    })

    // resort hotels simply map to themselves
    resortHotels && resortHotels.forEach(hotel => {
      if (hotel.hasNightTail) {
        offerHotelsMapping[hotel.id] = hotel.id
      }
    })

    return offerHotelsMapping
  }

  setProductModalState (visible, supplementary = {}) {
    this.setState(() => ({
      productModalState: {
        visible,
        supplementary
      }
    }))
  }

  setSupplementaryModalState (ref, visible) {
    this.setState(() => ({
      supplementaryModal: {
        ref,
        visible
      }
    }))
  }

  // @todo: could be a helper function, this is defined in more than 1 place in the exact same way
  setEventDates (firstEventDate, hasParkEntry) {
    this.setState(() => ({
      eventDates: basketHelpers.getEventDates(
        firstEventDate,
        venueType,
        hasParkEntry)
    }))
  }

  handleStoreHotel (selectedHotel) {
    this.setState(() => ({
      selectedHotel
    }))
  }

  handleStoreHotelDetails (hotelId, hotelName) {
    this.setState(() => ({
      hotelId,
      hotelName
    }))
  }

  handleStoreHotelPackageRates (hotelPackageRate, hotelId, isExtraNight = false) {
    if (!this.hotelPackageRates[hotelId]) {
      this.hotelPackageRates[hotelId] = []
    }

    const newHotelPackageRate = { ...hotelPackageRate, isExtraNight }

    this.hotelPackageRates[hotelId].push(newHotelPackageRate)
  }

  assignHotelInventory (resortHotels) {
    return resortHotels.map(hotel => {
      hotel.inventoryLevel = this.hotelInventoryLevel[hotel.id]
      return hotel
    })
  }

  handleGetReviews (url) {
    if (!url || typeof url !== 'string') return false
    return fetch(url)
      .then((response) => response.json())
      .then((response) => {
        const hotelReviews = _.get(response, 'packageRatesReply.reviews', [])
        return this.setState(() => ({
          hotelReviews
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  handleAddProduct (hotel, params, routeOptions) {
    let config = {
      doChangePage: true,
      grossPrice: null,
      isExtraNight: false,
      isResort: false,
      name: null,
      roomId: null,
      showRoomSelector: false,
      openVideoModal: false,
      utmSource: this.queryStringParams.utmSource
    }

    Object.assign(config, params)
    if (config.grossPrice) {
      // If we have a grossPrice, it's been passed down by our room so we should use it
      hotel = Object.assign({}, hotel, {
        grossPrice: config.grossPrice.toFixed(2)
      })
    }
    // for onsite hotel where themed room slider is not required
    if ((this.hasFeatures.hotelHasNoRoomSelection || []).indexOf(hotel.id) > -1) {
      Object.assign(config, {
        doChangePage: true,
        roomId: _.get(hotel, ['roomRates', 'links', 'roomProducts', 'ids', '0'], null)
      })
    }
    if (config.isResort && !config.roomId && config.showRoomSelector) {
      // close modal when we openRoomSelector
      this.setProductModalState(null, false)
    }
    if (hotel.isExtraNight) config.isExtraNight = true
    this.handleStoreHotel(hotel)
    this.addToBasket(hotel, config, routeOptions)
  }

  addToBasket (hotel, params, routeOptions) {
    const primaryImage = _.get(hotel, 'images.medium[0]', null)
    const roomName = (params.roomId && params.name !== '') ? params.name : null
    const grossPrice = Number(hotel.grossPrice)
    const standardPrice = Number(hotel.standardPrice)
    const searchFormId = _.get(packageRatesReply, 'meta.context.searchFormId', null)
    const { packageId } = hotel
    let agentCode = _.get(packageRatesReply, 'meta.context.agent')
    let selectedPackage = packageRatesReply.packageRates.find(packageRate => packageRate.id === packageId)

    if (params.isExtraNight) {
      selectedPackage = additionalNightPackageRatesReply.packageRates.find(packageRate => packageRate.id === packageId)
    }
    if (packageRatesReply.meta[selectedPackage.packageTypeId]) {
      agentCode = _.get(packageRatesReply, `meta.${selectedPackage.packageTypeId}.context.roomRates.agentCode`)
    }
    const selectedTicketRateId = selectedPackage.links.ticketRates.ids[0]
    const selectedTicketRate = packageRatesReply.linked.ticketRates[selectedTicketRateId]
    const harvestObject = {
      method: _.get(window, 'basket.id', null) ? 'put' : 'post',
      basketId: _.get(window, 'basket.id', null),
      version: _.get(window, 'basket.version', null),
      data: {
        agent: agentCode,
        ages: hotel.ages,
        guests: hotel.guests,
        availabilityUrl: window.location.href,
        brand: brandConfig.brand,
        customerCode: _.get(window, 'basket.data.customerCode', null),
        grossPrice,
        standardPrice,
        hotel: {
          accommodationType: hotel.accommodationType,
          bucket: _.get(packageRatesReply, 'meta.context.roomRates.bucket', ''),
          checkinDate: hotel.checkInDate,
          checkoutDate: hotel.checkOutDate,
          hasBreakfastIncluded: hotel.facilities.hasBreakfastIncluded,
          id: hotel.id,
          image: primaryImage,
          isResort: params.isResort,
          name: hotel.name,
          nights: hotel.nights,
          productId: hotel.id,
          roomId: params.roomId,
          roomName,
          stars: hotel.starRating,
          adults: _.get(window, 'packageRatesReply.meta.context.roomRates.adults', undefined),
          children: _.get(window, 'packageRatesReply.meta.context.roomRates.children', undefined),
          infants: _.get(window, 'packageRatesReply.meta.context.roomRates.infants', undefined)
        },
        hasParkEntry: selectedTicketRate.hasParkEntry,
        operator: _.get(window, 'basket.data.operator', 'rev'),
        rooms: hotel.rooms,
        roomRates: hotel.roomRates,
        seatsUrl: _.get(window, 'basket.data.seatsUrl', null),
        selectedItems: {},
        ticket: {
          adults: hotel.ticketRates.adults,
          basketAlternativeMessage: _.get(hotel.ticket, 'basketAlternativeMessage', null),
          bucket: hotel.ticketRates.bucket,
          children: hotel.ticketRates.children,
          contentId: hotel.ticket.contentId,
          duration: selectedTicketRate.days,
          endDate: hotel.ticketRates.endDate,
          engineDefaultTicketCode: _.get(this.props.engineState, 'defaultTicketCode', null),
          eventProducts: hotel.ticket.id,
          grossPrice: hotel.ticketRates.grossPrice,
          hasBestPriceGuarantee: this.state.hasBestPriceGuarantee,
          id: hotel.ticket.id,
          infants: hotel.ticketRates.infants,
          isAnnualPass: this.state.isAnnualPass,
          isFlexibleDate: selectedTicketRate.isFlexibleDate,
          name: hotel.ticket.name,
          startDate: hotel.ticketRates.startDate || hotel.ticketRates.arrivalDate,
          strapline: hotel.ticket.shortDescription,
          summaryDiscountMessage: hotel.ticket.summary_discount_message,
          whatsIncluded: hotel.ticket.whatsIncluded
        },
        searchFormId
      }
    }

    trackingHelpers.fbPixelTrack(hotel.grossPrice, [hotel.id, hotel.ticket.id], 'availability', 'AddToWishlist')

    harvestObject.data.rooms = harvestObject.data.rooms.map(room => {
      const roomType = basketHelpers.getRoomType(roomTypes, room)
      room.name = roomType.roomDescription
      room.shortName = roomType.roomShortDesc
      return room
    })

    harvestObject.data.selectedItems[hotel.packageId] = {
      id: hotel.packageId,
      resource: 'packageRates',
      type: 'package',
      grossPrice: hotel.grossPrice,
      standardPrice: hotel.standardPrice
    }

    const selectedItemsHash = Object.keys(_.get(window, 'basket.data.selectedItems', {}))[0]
    const selectedSeats = _.get(window, `basket.data.selectedItems[${selectedItemsHash}].selectedSeats`, null)
    if (selectedSeats) harvestObject.data.selectedItems[hotel.packageId].selectedSeats = selectedSeats

    const seats = _.get(window, `basket.data.selectedItems[${selectedItemsHash}].seats`, null)
    if (seats) harvestObject.data.selectedItems[hotel.packageId].seats = seats

    const { promotionCode, referrer } = _.get(window, 'packageRatesReply.meta.context')
    if (promotionCode) harvestObject.data.promotionCode = promotionCode
    if (referrer) harvestObject.data.referrer = referrer
    if (!params.isResort) {
      const { packageTypeId = null } = selectedPackage
      trackingHelpers.track('sb.track', 'Package selected', 'packageTypeId', packageTypeId)
      harvestObject.data.packageTypeId = packageTypeId
      const packageGroupId = _.get(window, 'packageRatesReply.meta.context.packageGroupId')
      harvestObject.data.packageGroupId = packageGroupId
    }
    if (hotel.ticketRates.arrivalTime) {
      harvestObject.data.ticket.timeslot = { start: hotel.ticketRates.arrivalTime }
    }
    window.updateBasket(harvestObject).then((data) => {
      // store basket in sessionStorage for retrieval later
      this.handleSetSessionBasketData('sessionBasket', data.harvest.baskets)
      // use the sessionBasket data to fetch new details about the current selection
      // this then puts it into state.harvestData and pushes back down through props
      this.handleGetSessionBasketData('hotelProducts,ticketProducts,roomProducts,reviews')
      // If doChangePage is true, change the page
      if (params.doChangePage) {
        // Track form submit
        const basket = _.get(data, 'harvest.baskets', {})
        Object.assign(params, {
          basketId: basket.id || _.get(window, 'basket.id'),
          versionId: basket.version || _.get(window, 'basket.version')
        })
        trackingHelpers.track('sb.track', 'Proceed to More Information', 'forms', hotel.id)
        this.changePage(hotel, params, routeOptions)
      }
    }, err => {
      console.error('Updating Harvest has resulted in an error:', err)
      // When there's an error with Harvest, show the callback form
      this.props.showCallbackForm(`Updating Harvest has resulted in an error: ${err.message || err}`)
    })
  }

  getPackageRate (allPackageRates, packageRateId) {
    return allPackageRates.packageRates.find(packageRate => {
      return packageRate.id === packageRateId
    })
  }

  getPriceFinderButton (btnText) {
    return (
      <div class='fixed-bottom shadow-sm'>
        <div class='panel m-0 panel-body'>
          <button
            aria-label={'Pricing for alternative dates'}
            className='btn btn-block btn-price-finder'
            {...trackingHelpers.getAttributes('open', 'discovery-modal', 'All hotels')}
            onClick={() => this.props.openDiscoveryModal()}
            onKeyDown={(e) => e.key === 'Enter' && this.props.openDiscoveryModal()}
          >
            <span className='pr-2'><SVGSpyglass width='22' height='22' /></span>
            <FormattedMessage id={btnText} />
          </button>
        </div>
      </div>
    )
  }

  changePage (hotel, params, routeOptions) {
    const { packageRatesReply, additionalNightPackageRatesReply } = this.state
    const ratesReply = params.isExtraNight ? additionalNightPackageRatesReply : packageRatesReply
    const { context } = ratesReply.meta
    const { filter = null, packageGroupId = null, venueCode } = this.queryStringParams
    const { packageId } = hotel

    const searchFormId = _.get(packageRatesReply, 'meta.context.searchFormId', null)
    let agentCode = _.get(packageRatesReply, 'meta.context.agent')
    let selectedPackage = packageRatesReply.packageRates.find(packageRate => packageRate.id === packageId)

    if (params.isExtraNight) {
      selectedPackage = additionalNightPackageRatesReply.packageRates.find(packageRate => packageRate.id === packageId)
    }

    const ticketRate = ratesReply.linked.ticketRates[selectedPackage.links.ticketRates.ids[0]]

    if (packageRatesReply.meta[selectedPackage.packageTypeId]) {
      agentCode = _.get(packageRatesReply, `meta.${selectedPackage.packageTypeId}.context.roomRates.agentCode`)
    }
    const requestObject = {
      utmSource: params.utmSource,
      adults: context.roomRates.adults,
      agent: agentCode,
      ages: context.ages,
      basketId: params.basketId,
      children: context.roomRates.children,
      context: {
        hotelProducts: {
          checkinDate: context.context.hotelProducts.checkinDate
        }
      },
      customerCode: context.customerCode,
      fallbackTicket: Object.keys(venueProduct)[0] || brandConfig.venueCode || brandConfig.parkCode,
      infants: context.roomRates.infants,
      hotelCode: hotel.id,
      roomRates: {
        bucket: context.roomRates.bucket,
        checkinDate: context.roomRates.checkinDate,
        checkoutDate: context.roomRates.checkoutDate,
        hotelCode: hotel.id,
        rooms: context.roomRates.rooms
      },
      SeatType: context.SeatType,
      tag: endpoint,
      ticketCode: `${ticketRate.bucket}${ticketRate.code}`,
      ticketRates: {
        bucket: ticketRate.bucket,
        code: ticketRate.code,
        endDate: ticketRate.endDate,
        startDate: ticketRate.startDate,
        ticketCode: `${ticketRate.bucket}${ticketRate.code}`
      },
      contentId: ticketRate.contentId || `${ticketRate.bucket}${ticketRate.code}`,
      versionId: params.versionId,
      venueCode: venueCode || Object.keys(venueProduct)[0] || brandConfig.venueCode || brandConfig.parkCode,
      searchFormId
    }
    if (context.guests && context.guests.length) {
      requestObject.guests = context.guests
      delete requestObject.ages
    }

    if (ticketRate.timeslot) {
      requestObject.ticketRates.timeslot = { start: ticketRate.timeslot.start }
    }

    if (filter) {
      requestObject.filter = filter
    }

    if (packageGroupId) {
      requestObject.packageGroupId = packageGroupId
      delete requestObject.ticketRates.bucket
      delete requestObject.ticketRates.code
    }

    // quantity based attractions will be optional
    if (_.get(this.hasFeatures, 'upgrades.quantityBased', false)) {
      requestObject.quantity = 1
    }

    // roomId will be optional
    if (params.roomId) {
      requestObject.roomRates.code = params.roomId
    }

    // promotionCode will be optional
    if (this.queryStringParams.promotionCode) {
      requestObject.promotionCode = this.queryStringParams.promotionCode
    }

    if (this.queryStringParams.providerReference) {
      requestObject.providerReference = this.queryStringParams.providerReference
    }

    if (this.queryStringParams.referrer) {
      requestObject.referrer = this.queryStringParams.referrer
    }

    // if providerEnvironment was present we need to pass it to the next stage
    if (context.providerEnvironment) {
      requestObject.providerEnvironment = context.providerEnvironment
    }

    if (context.bypassCache) {
      requestObject.bypassCache = context.bypassCache
    }

    if (params.moreInfoModalBookNow) {
      requestObject.moreInfoModalBookNow = params.moreInfoModalBookNow
    }

    if (params.openVideoModal) {
      requestObject.openVideoModal = params.openVideoModal
    }

    const flow = brandConfig.secure && brandConfig.secure.flow
    const nextEndpoint = routeHelpers.getNextStage(flow, endpoint, params.skipStage)
    if (nextEndpoint) {
      this.props.navigateTo(nextEndpoint, requestObject, routeOptions)
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)
  }

  render () {
    const offsiteResortMessage = this.state.hasResortMessage.offSiteOfferResort &&
      <FormattedMessage id='availability.offsiteResortMessage' />

    const offsiteResortMessageGX = this.state.hasResortMessage.offSiteOfferResort &&
      <FormattedMessage id='availability.offsiteResortMessageGX' />

    const ratePlanResortMessage = this.state.hasResortMessage.ratePlanIssueResort &&
      <FormattedMessage id='availability.ratePlanIssueResortMessage' values={{ telephone: brandConfig.number }} />

    const ratePlanResortMessageGX = this.state.hasResortMessage.ratePlanIssueResort &&
      <FormattedMessage id='availability.ratePlanIssueResortMessageGX' />

    const offsiteResortMessageHeader = (this.state.hasResortMessage.offSiteOfferResort && this.state.customerCode === 'Q')
      ? (<h3>{offsiteResortMessage} {this.state.hasResortMessage.offSiteOfferResortLink}</h3>) : (<h3>{offsiteResortMessageGX}</h3>)

    const ratePlanResortMessageHeader = (this.state.hasResortMessage.ratePlanIssueResort && this.state.customerCode === 'Q')
      ? (<h3>{ratePlanResortMessage}</h3>) : (<h3>{ratePlanResortMessageGX}</h3>)

    const onsitePackageAvailMessage = this.hasFeatures.multiPackageResortUnavailableMessaging && this.state.hasParkEntry ? `${brandConfig.multiPackageOnsiteAvailabilityMessaging} Try ` : `Sorry, it looks like we’re all out of rooms at the resort accommodation for the date you’ve chosen. Please try `
    // If we have no package rates at all we will not require the resort no availability message
    if (Object.keys(_.get(this.state, 'packageRatesReply.packageRates', [])).length === 0) {
      // @todo This is wrong.. need to fix this!
      this.state.isResortHotelsUnavailableMessageVisible = false
    }

    const MissingEventProducts = (this.state.hasMessage.missingEventProducts)
      ? <ErrorHandler
        titleFormattedMessage={{ id: 'availability.missingEventProductsTitle' }}
        formattedMessage={{
          id: 'availability.missingEventProducts'
        }}
        type='warning' />
      : null

    const NoAvailability = this.state.hasMessage.noAvailability
      ? (
        <ErrorHandler
          titleFormattedMessage={{ id: 'availability.noAvailabilityTitle' }}
          formattedMessage={{
            id: 'availability.noAvailability'
          }}
          type='info'
        />
      )
      : null

    const TooFewCotsMessage = (this.state.hasMessage.tooFewCots)
      ? <ErrorHandler
        titleFormattedMessage={{ id: 'availability.cotsTitle' }}
        formattedMessage={{
          id: 'availability.tooFewCotsMessage'
        }}
        type='danger' />
      : null

    const TravelCotsMessage = (this.state.hasMessage.cots)
      ? <ErrorHandler
        titleFormattedMessage={{ id: 'availability.cotsTitle' }}
        formattedMessage={{
          id: 'availability.travelCotsMessage',
          values: { telephone: brandConfig.number }
        }}
        type='info' />
      : null

    const NoFeaturedHotelMessage = (this.state.hasMessage.noFeaturedHotel)
      ? <ErrorHandler
        formattedMessage={{
          id: 'availability.noFeaturedHotelMessage'
        }}
        type='info' />
      : null

    const ExceededMaxPartyMessage = (this.state.hasMessage.exceededMaxParty)
      ? <ErrorHandler
        formattedMessage={{
          id: 'availability.exceededMaxPartyMessage',
          values: { maxParty: this.props.maxParty, telephone: brandConfig.number }
        }}
        type='info' />
      : null

    const isHotelListRequired = !this.state.hasMessage.noAvailability && !this.state.hasMessage.tooFewCots && !this.state.hasMessage.missingEventProducts
    const venueProducts = _.get(packageRatesReply, 'linked.venueProducts', null)
    // The current event messaging possibilities
    const ticketCode = Object.keys(eventProduct)[0]
    const dateSpecificInformation = _.get(eventProduct[ticketCode], `dateSpecificInformation`, '')
    const displayOn = Object.keys(dateSpecificInformation)[0]
    const eventInformation = _.get(eventProduct[ticketCode], `eventInformation`, '')
    let ticketInformation = _.includes(['all', 'both', 'eventOnly', 'hotelOnly', '0'], displayOn) ? _.get(dateSpecificInformation, displayOn, '') : ''
    // Temporary fix whilst committing
    if (displayOn === '0') ticketInformation = dateSpecificInformation
    const eventMessaging = `${ticketInformation}${eventInformation}`

    let CombinedHotelListOutput = null
    let OffSiteHotelListOutput = null
    let ResortHotelListOutput = null
    let propertyType
    let propertyInformation
    const hotelFilterList = (this.props.hotelList || []).map(hotel => {
      propertyType = hotel.isResort ? 'resort' : 'offSite'
      propertyInformation = this.state.hotels[propertyType].find((property) => {
        return property.id === hotel.id
      })
      const filteredExtraNightOffers = (this.state.hotels.extraNights || []).filter(extraNight => extraNight.id === this.state.hotels.offerCodesMapping[hotel.id])
      const isExtraNightEnabled = hotel.isResort && this.extraNightsFeature && !!filteredExtraNightOffers.length

      return {
        ...hotel,
        ...propertyInformation,
        packageTotal: hotel.price,
        hasExtraNight: this.state.hotels && this.state.hotels.offerCodesMapping[hotel.id] && isExtraNightEnabled,
        extraNightData: this.state.hotels.offerCodesMapping[hotel.id] && isExtraNightEnabled
          ? _.merge(_.minBy(filteredExtraNightOffers, 'grossPrice'), {
            isExtraNight: true
          })
          : null
      }
    })

    if (!this.showCombinedHotelsList && isHotelListRequired) {
      if ((this.state.hotels.resort || []).length > 0) {
        const resortHotelProps = {
          brand: brandConfig.brand,
          eventDates: this.state.eventDates,
          extraNights: this.state.hotels.extraNights,
          featuredHotel: this.featuredHotel,
          getReviews: this.handleGetReviews,
          getSessionBasketData: this.handleGetSessionBasketData,
          handleAddProduct: this.handleAddProduct,
          handleSetProductModal: this.setProductModalState,
          hasFeatures: this.hasFeatures,
          hasMoreInformationStage: this.hasMoreInformationStage,
          hasParkEntry: this.state.hasParkEntry,
          hotels: this.state.hotels.resort,
          imageBasePath: brandConfig.secure.imagesDomain,
          inventoryLevelMessages: _.get(this.props, 'brandConfig.secure.inventoryLevelMessages', null),
          isAnnualPass: this.state.isAnnualPass,
          isModalVisible: this.props.isModalVisible,
          isResort: true,
          isTrainStationPreferred: brandConfig.isTrainStationPreferred,
          isVideoAutoplay: this.state.isVideoAutoplay,
          listType: 'hotel-type-resort',
          modalContent: this.props.modalContent,
          modalState: this.state.productModalState,
          onHideModal: this.props.onHideModal,
          packageInfo: this.packageInfo,
          packageRatesByHotel: this.hotelPackageRates,
          partyComps: this.props.partyComps,
          reviews: this.state.hotelReviews,
          rooms: _.get(packageRatesReply, 'meta.context.roomRates.rooms', null),
          sbImageBucketSize: this.sbImageBucketSize,
          setSessionBasket: this.handleSetSessionBasketData,
          ticketComposition: this.props.ticketCompositionString,
          venueProducts: venueProducts,
          venueType,
          visibleModals: this.props.visibleModals
        }
        const showResortHotels = (isHotelListRequired && (this.state.hotels.resort || []).length > 0)
        if (showResortHotels) {
          ResortHotelListOutput = <HotelList {...resortHotelProps} />
        }
      }
      if ((this.state.hotels.offSite || []).length > 0) {
        const offsiteHotelProps = {
          brand: brandConfig.brand,
          eventDates: this.state.eventDates,
          extraNights: this.state.hotels.extraNights,
          featuredHotel: this.featuredHotel,
          getReviews: this.handleGetReviews,
          handleAddProduct: this.handleAddProduct,
          handleSetProductModal: this.setProductModalState,
          hasFeatures: this.hasFeatures,
          hasMoreInformationStage: this.hasMoreInformationStage,
          hasParkEntry: this.state.hasParkEntry,
          hotelOfferCodesMapping: this.state.hotels.offerCodesMapping,
          hotels: this.state.hotels.offSite,
          imageBasePath: brandConfig.secure.imagesDomain,
          inventoryLevelMessages: _.get(brandConfig, 'secure.inventoryLevelMessages', null),
          isAnnualPass: this.state.isAnnualPass,
          isTrainStationPreferred: brandConfig.isTrainStationPreferred,
          isVideoAutoplay: this.state.isVideoAutoplay,
          listType: 'hotel-type-offsite',
          modalContent: this.props.modalContent,
          modalState: this.state.productModalState,
          onHideModal: this.props.onHideModal,
          packageInfo: this.packageInfo,
          partyComps: this.props.partyComps,
          reviews: this.state.hotelReviews,
          rooms: _.get(packageRatesReply, 'meta.context.roomRates.rooms', null),
          sbImageBucketSize: this.sbImageBucketSize,
          ticketComposition: this.props.ticketCompositionString,
          venueProducts: venueProducts,
          venueType,
          visibleModals: this.props.visibleModals
        }
        OffSiteHotelListOutput = <HotelList {...offsiteHotelProps} />
      }
    } else {
      // A combined list of hotels is only available when turning on the Transformer flag (hasCombinedHotelsList)
      if (hotelFilterList.length > 0) {
        const combinedHotelProps = {
          brand: brandConfig.brand,
          eventDates: this.state.eventDates,
          eventMessaging,
          extraNights: this.state.hotels.extraNights,
          featuredHotel: this.featuredHotel,
          getReviews: this.handleGetReviews,
          handleAddProduct: this.handleAddProduct,
          handleSetProductModal: this.setProductModalState,
          hasFeatures: this.hasFeatures,
          hasMoreInformationStage: this.hasMoreInformationStage,
          hasParkEntry: this.state.hasParkEntry,
          hotelOfferCodesMapping: this.state.hotels.offerCodesMapping,
          hotels: hotelFilterList,
          imageBasePath: brandConfig.secure.imagesDomain,
          inventoryLevelMessages: _.get(brandConfig, 'secure.inventoryLevelMessages', null),
          isAnnualPass: this.state.isAnnualPass,
          isTrainStationPreferred: brandConfig.isTrainStationPreferred,
          isVideoAutoplay: this.state.isVideoAutoplay,
          modalContent: this.props.modalContent,
          modalState: this.state.productModalState,
          onHideModal: this.props.onHideModal,
          packageInfo: this.packageInfo,
          packageRatesByHotel: this.hotelPackageRates,
          partyComps: this.props.partyComps,
          reviews: this.state.hotelReviews,
          rooms: _.get(packageRatesReply, 'meta.context.roomRates.rooms', null),
          sbImageBucketSize: this.sbImageBucketSize,
          ticketComposition: this.props.ticketCompositionString,
          venueProducts: venueProducts,
          venueType,
          visibleModals: this.props.visibleModals
        }
        CombinedHotelListOutput = <HotelList {...combinedHotelProps} />
      }
    }
    const numberOfHotelsInOffsiteList = (this.state.hotels.offSite || []).length
    const numberOfHotelsInCombinedList = (this.state.hotels.combined || []).length

    const anyChildren = (this.state.roomComposition.children || this.state.roomComposition.infants) > 0

    const NoAvailabilityMessageSummary = (
      <React.Fragment>
        {/*
          Some browsers maintain scroll position when you go through history.
          The <AutoAffix> will try and render in the position it was in, but it will fail when there is not enough space on the page.
          Thats why we need to check if the hotel list is being rendered before rendering the <AutoAffix>
          We also want to show it if there is no availability, thats why we have '!isHotelListRequired'
        */}
        {this.hasFeatures.hasFixedSummary && (CombinedHotelListOutput || OffSiteHotelListOutput || ResortHotelListOutput || !isHotelListRequired) &&
          <Portal domNode={document.getElementById('header')}>
            {this.props.progressTracker}
            <div className='affix-container'>
              <AutoAffix
                onAffixedTop={() => this.handleAffix(false)}
                onAffixed={() => this.handleAffix(true)}>
                <div className='bg-default sticky' style={{ top: '0!important', zIndex: 1001 }}>
                  {this.hasFeatures.availability.hasSimplifiedSummary && query.isXs()
                    ? <SimpleSearchSummary />
                    : <FixedSearchSummary />
                  }
                </div>
              </AutoAffix>
            </div>
          </Portal>
        }

        {!this.hasFeatures.hasFixedSummary &&
          <React.Fragment>
            <Row>
              <Col md={12}>
                {this.props.progressTracker}
                <div className='panel panel-default'>
                  <div className='panel-heading'>
                    <h2 className='h3'>
                      <FormattedMessage id='common.summary' />
                    </h2>
                  </div>
                  <div className='panel-body'>
                    <StaticSearchSummary />
                  </div>
                </div>
              </Col>
            </Row>
          </React.Fragment>
        }

        {MissingEventProducts}
        {NoAvailability}
      </React.Fragment>
    )

    return (
      <section>
        {/* Empty split test for the AA test for Alton Towers (50/50 on the wall) */}
        <SplitTest.Experiment name='HBMTA-815'>
          <SplitTest.Variant name='show_alternative' />
          <SplitTest.Variant name='show_original' />
        </SplitTest.Experiment>

        {isHotelListRequired && eventMessaging &&
          <Row>
            <Col xs={12} className={`sticky-alert ${this.alertInfoMobileClassName}`}>
              <EventInformation content={eventMessaging} alertInfoMobileClassName={this.alertInfoMobileClassName} />
            </Col>
          </Row>
        }

        {(NoAvailability && this.props.discoveryPrimed === true && this.hasNoAvailabilityCalendar) ? (
          <div className='no-availability-calendar block-lg'>
            <div className='no-availability-calendar-heading'>
              <FormattedMessage id='availability.noResultsFound' tagName='h2' />
            </div>
            <div className='no-availability-calendar-body'>
              <span className='lead'>
                <FormattedMessage id='availability.selectFromAvailable' tagName='p' values={{ brand: brandConfig.parkName }} />
              </span>
            </div>
            <div className='no-availability-calendar-footer'>
              <Calendar
                fetchDataOnLoad
                availabilityFinder
                onDayClick={day => {
                  window.location = `${this.props.brandConfig.actions.web}?${day.searchParams}&sortBy=grossPriceInPence`
                }}
              />
            </div>
          </div>
        ) : (
          NoAvailabilityMessageSummary
        )}

        {TravelCotsMessage}
        {TooFewCotsMessage}
        {NoFeaturedHotelMessage}
        {this.props.engineModal}
        {ExceededMaxPartyMessage}

        {isHotelListRequired && !this.hasFeatures.filters &&
          <React.Fragment>
            {(this.hasFeatures.resortHotels || this.showCombinedHotelsList) && !this.state.offsiteHotelsOnly &&
              <div className='block-sm'>
                {!this.state.isResortHotelProviderAvailable &&
                  <CallbackForm />
                }
                {this.state.isResortHotelProviderAvailable && !this.state.isResortHotelsUnavailableMessageVisible && ResortHotelListOutput &&
                  <React.Fragment>
                    <FormattedMessage id='availability.resortHotelsHeading' tagName='h2' />
                    <div className='resort-hotels-container'>
                      {ResortHotelListOutput}
                    </div>
                  </React.Fragment>
                }
                {this.state.isResortHotelProviderAvailable && this.state.isResortHotelsUnavailableMessageVisible &&
                  <React.Fragment>
                    <FormattedMessage id='availability.resortHotels' tagName='h2' />
                    <Panel>
                      <Panel.Body>
                        {/* This is a quick fix to ensure this offer displays correct messaging for the lack of onsite properties: https://hxshortbreaks.atlassian.net/browse/PARTNERS-69. */}
                        {/* And for multi night/rate plan issue (which probably needs solving further up stack): https://hxshortbreaks.atlassian.net/browse/PARTNERS-24 */}
                        {/* There will be a proper solution looked at and put in place for these cases - This will happen!!! */}
                        {this.state.hideResortMessage ? (
                          <React.Fragment>
                            {offsiteResortMessageHeader &&
                              offsiteResortMessageHeader
                            }
                            {ratePlanResortMessageHeader &&
                              ratePlanResortMessageHeader
                            }
                          </React.Fragment>
                        ) : (
                          <h3>
                            {onsitePackageAvailMessage}
                            <a
                              className='hoverable'
                              onClick={this.props.toggleEngineModal}
                              onKeyDown={(e) => e.key === 'Enter' && this.props.toggleEngineModal()}
                              role='button'
                              tabIndex='0'
                            >
                              changing your dates
                            </a>
                            {this.hasFeatures.multiPackageResortUnavailableMessaging && this.state.hasParkEntry && _.get(brandConfig, 'secure.standardPackageUrl') &&
                              <React.Fragment>
                                {_.get(this.hasFeatures, 'footer.contact', false) ? ', ' : ' or '}
                                <a href={brandConfig.secure.standardPackageUrl}>
                                  changing your package
                                </a>
                              </React.Fragment>
                            }
                            {_.get(this.hasFeatures, 'footer.contact', false) &&
                              <React.Fragment>
                                &nbsp;or&nbsp;
                                <a
                                  className='hoverable'
                                  onClick={() => this.onToggleModal('contact')}
                                  onKeyDown={(e) => e.key === 'Enter' && this.onToggleModal('contact')}
                                  role='button'
                                  tabIndex='0'
                                >
                                  contact us
                                </a> for help
                              </React.Fragment>
                            }.
                          </h3>
                        )}
                      </Panel.Body>
                    </Panel>
                  </React.Fragment>
                }
                {this.state.isResortHotelProviderAvailable && CombinedHotelListOutput &&
                  <React.Fragment>
                    <div className='clearfix'>
                      <div className='float-sm-left text-center text-sm-left'>
                        <h2 className='m-0' data-automated-test='resultsAvailableHeader'>
                          <FormattedMessage id='common.resultsAvailable' values={{ count: this.state.hotels.combined.length }} />
                        </h2>
                      </div>
                      {(numberOfHotelsInCombinedList > 1) &&
                        <div className='text-sm-right text-center'>
                          <HotelSorter
                            active={this.state.sortBy}
                            sortAction={this.sortHotels}
                          />
                        </div>
                      }
                    </div>
                    <div className='hotels-container' ref={e => { if (!this.hotelListElement) this.hotelListElement = e }}>
                      {CombinedHotelListOutput}
                    </div>
                  </React.Fragment>
                }
              </div>
            }
            {OffSiteHotelListOutput &&
              <React.Fragment>
                <Row>
                  <Col xs={12} md={6}>
                    {!_.get(this.hasFeatures, 'isOffsiteHotelsHeadingHidden', false) &&
                      <FormattedMessage id='availability.offsiteHotelsHeading' tagName='h2' />
                    }
                  </Col>
                  {(numberOfHotelsInOffsiteList > 1) &&
                    <Col xs={12} md={6} className='text-right'>
                      <HotelSorter
                        active={this.state.sortBy}
                        sortAction={this.sortHotels}
                      />
                    </Col>
                  }
                </Row>
                {OffSiteHotelListOutput}
              </React.Fragment>
            }
          </React.Fragment>
        }
        {isHotelListRequired && this.hasFeatures.filters &&
          <SplitTest.Experiment name='NB-1181: Filters'>
            <SplitTest.Variant name='default'>
              <React.Fragment>
                {(this.hasFeatures.resortHotels || this.showCombinedHotelsList) && !this.state.offsiteHotelsOnly &&
                  <div className='block-sm'>
                    {!this.state.isResortHotelProviderAvailable &&
                      <CallbackForm />
                    }
                    {this.state.isResortHotelProviderAvailable && !this.state.isResortHotelsUnavailableMessageVisible && ResortHotelListOutput &&
                      <React.Fragment>
                        <FormattedMessage id='availability.resortHotelsHeading' tagName='h2' />
                        <div className='resort-hotels-container'>
                          {ResortHotelListOutput}
                        </div>
                      </React.Fragment>
                    }

                    {this.state.isResortHotelProviderAvailable && this.state.isResortHotelsUnavailableMessageVisible &&
                      <React.Fragment>
                        <FormattedMessage id='availability.resortHotels' tagName='h2' />
                        <Panel>
                          <Panel.Body>
                            {/* This is a quick fix to ensure this offer displays correct messaging for the lack of onsite properties: https://hxshortbreaks.atlassian.net/browse/PARTNERS-69. */}
                            {/* And for multi night/rate plan issue (which probably needs solving further up stack): https://hxshortbreaks.atlassian.net/browse/PARTNERS-24 */}
                            {/* There will be a proper solution looked at and put in place for these cases This will happen!!! */}
                            {this.state.hideResortMessage ? (
                              <React.Fragment>
                                {offsiteResortMessageHeader &&
                                  offsiteResortMessageHeader
                                }
                                {ratePlanResortMessageHeader &&
                                  ratePlanResortMessageHeader
                                }
                              </React.Fragment>
                            ) : (
                              <h3>
                                {onsitePackageAvailMessage}
                                <a
                                  className='hoverable'
                                  onClick={this.props.toggleEngineModal}
                                  onKeyDown={(e) => e.key === 'Enter' && this.props.toggleEngineModal()}
                                  role='button'
                                  tabIndex='0'
                                >
                                  changing your dates
                                </a>
                                {this.hasFeatures.multiPackageResortUnavailableMessaging && this.state.hasParkEntry && _.get(brandConfig, 'secure.standardPackageUrl') && (
                                  <React.Fragment>
                                    {_.get(this.hasFeatures, 'footer.contact', false) && ', '}
                                    <a href={brandConfig.secure.standardPackageUrl}>
                                      changing your package
                                    </a>
                                  </React.Fragment>
                                )}
                                {_.get(this.hasFeatures, 'footer.contact', false) &&
                                  <React.Fragment>
                                    &nbsp;or&nbsp;
                                    <a
                                      className='hoverable'
                                      onClick={() => this.onToggleModal('contact')}
                                      onKeyDown={(e) => e.key === 'Enter' && this.onToggleModal('contact')}
                                      role='button'
                                      tabIndex='0'
                                    >
                                      contact us
                                    </a> for help
                                  </React.Fragment>
                                }.
                              </h3>
                            )}
                          </Panel.Body>
                        </Panel>
                      </React.Fragment>
                    }
                    {this.state.isResortHotelProviderAvailable && CombinedHotelListOutput &&
                      <React.Fragment>
                        <div className='clearfix'>
                          <div className='float-sm-left text-center text-sm-left'>
                            <h2 className='m-0' data-automated-test='resultsAvailableHeader'>
                              <FormattedMessage id='common.resultsAvailable' values={{ count: this.state.hotels.combined.length }} />
                            </h2>
                          </div>
                          {(numberOfHotelsInCombinedList > 1) &&
                            <div className='text-sm-right text-center'>
                              <HotelSorter
                                active={this.state.sortBy}
                                sortAction={this.sortHotels}
                              />
                            </div>
                          }
                        </div>
                        <div className='hotels-container' ref={e => { if (!this.hotelListElement) this.hotelListElement = e }}>
                          {CombinedHotelListOutput}
                        </div>
                      </React.Fragment>
                    }
                  </div>
                }
                {OffSiteHotelListOutput &&
                  <React.Fragment>
                    <Row>
                      <Col xs={12} md={6}>
                        {!_.get(this.hasFeatures, 'isOffsiteHotelsHeadingHidden', false) &&
                          <FormattedMessage id='availability.offsiteHotelsHeading' tagName='h2' />
                        }
                      </Col>
                      {(numberOfHotelsInOffsiteList > 1) &&
                        <Col xs={12} md={6} className='text-right'>
                          <HotelSorter
                            active={this.state.sortBy}
                            sortAction={this.sortHotels}
                          />
                        </Col>
                      }
                    </Row>
                    {OffSiteHotelListOutput}
                  </React.Fragment>
                }
              </React.Fragment>
            </SplitTest.Variant>
            <SplitTest.Variant name='filters'>
              {this.hasFeatures.resortHotels && !this.state.offsiteHotelsOnly &&
                <div>
                  {!this.state.isResortHotelProviderAvailable ? (
                    <CallbackForm />
                  ) : (
                    <div>
                      {!this.state.isResortHotelProviderAvailable &&
                        <CallbackForm />
                      }
                      {this.state.isResortHotelProviderAvailable && this.state.hotels.resort.length > 0 && !this.state.isResortHotelsUnavailableMessageVisible &&
                        <div>
                          <div className='resort-hotels-container'>
                            {ResortHotelListOutput}
                          </div>
                        </div>
                      }

                      {this.state.isResortHotelProviderAvailable && this.state.isResortHotelsUnavailableMessageVisible &&
                        <div>
                          <h2>Resort Hotels</h2>
                          <Panel.Body>
                            {/* This is a quick fix to ensure this offer displays correct messaging for the lack of onsite properties: https://hxshortbreaks.atlassian.net/browse/PARTNERS-69. */}
                            {/* And for multi night/rate plan issue (which probably needs solving further up stack): https://hxshortbreaks.atlassian.net/browse/PARTNERS-24 */}
                            {/* There will be a proper solution looked at and put in place for these cases This will happen!!! */}
                            {this.state.hideResortMessage ? (
                              <React.Fragment>
                                {offsiteResortMessageHeader &&
                                  offsiteResortMessageHeader
                                }
                                {ratePlanResortMessageHeader &&
                                  ratePlanResortMessageHeader
                                }
                              </React.Fragment>
                            ) : (
                              <h3>Sorry, it looks like we're all out of rooms for the date you've chosen. Please try
                                <a
                                  className='hoverable'
                                  onClick={this.props.toggleEngineModal}
                                  onKeyDown={(e) => e.key === 'Enter' && this.props.onToggleModal()}
                                  role='button'
                                  tabIndex='0'
                                >
                                  changing your search
                                </a>, or
                                <a
                                  className='hoverable'
                                  onClick={() => this.handleModalTrigger('isContactModalVisible')}
                                  onKeyDown={(e) => e.key === 'Enter' && this.handleModalTrigger('isContactModalVisible')}
                                  role='button'
                                  tabIndex='0'
                                >
                                  contact us
                                </a> for help.
                              </h3>
                            )}
                          </Panel.Body>
                        </div>
                      }
                    </div>
                  )}
                </div>
              }
              <div className='row'>
                <div className='col-xs-12 col-sm-3'>
                  <aside role='complementary' id='aside'>
                    <div className='pull-left pb-3 pb-xs-0'>
                      {(numberOfHotelsInCombinedList > 1) &&
                        <HotelSorter
                          active={this.state.sortBy}
                          hotelOrder={_.get(brandConfig, 'secure.hotelOrder', [])}
                          sortAction={this.sortHotels}
                        />
                      }
                    </div>
                    <Filters
                      hotels={hotelFilterList}
                      hotelMaster={this.state.hotels.combined}
                      anyChildren={anyChildren}
                    />
                  </aside>
                </div>
                <div className='col-xs-12 col-sm-9'>
                  {this.hasFeatures.resortHotels && !this.hasFeatures.filters &&
                    <h2>Or take a look at our nearby, family-friendly hotels...</h2>
                  }
                  <div>
                    <h1 className='h2 mt-sm-0' data-automated-test='resultsAvailableHeader'>
                      <FormattedMessage id='common.resultsAvailable' values={{ count: hotelFilterList.length }} />
                    </h1>
                    {!this.hasFeatures.filters && (numberOfHotelsInCombinedList > 1) && (
                      <HotelSorter
                        active={this.state.sortBy}
                        hotelOrder={_.get(brandConfig, 'secure.hotelOrder', [])}
                        sortAction={this.sortHotels}
                      />
                    )}
                  </div>
                  {CombinedHotelListOutput}
                </div>
              </div>
            </SplitTest.Variant>
          </SplitTest.Experiment>
        }

        {this.props.discoveryPrimed && (
          <DiscoveryModal ticketCode={ticketCode} withPriceFinderSubheading />
        )}
      </section>
    )
  }
}

AvailabilityContainer.propTypes = {
  hotelInventoryLevel: PropTypes.array,
  isCallCentre: PropTypes.bool.isRequired,
  navigateTo: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  progressTracker: PropTypes.element,
  trackAvailabilityMessage: PropTypes.func.isRequired,
  visibleModals: PropTypes.shape({
    uspModal: PropTypes.bool
  }).isRequired
}

function mapStateToProps (state) {
  const { hotelPartyString, ticketPartyString } = state.basket
  return {
    discoveryPrimed: state.discovery.isPrimed,
    hotelCompositionString: hotelPartyString,
    hotelList: state.packageRates.filtered,
    hotelPartyString: state.basket.hotelPartyString,
    maxParty: state.searchSummary.brandConfig.maxParty,
    modalContent: state.modal.modalContent,
    ticketCompositionString: ticketPartyString,
    visibleModals: state.modal.visible
  }
}

function mapDispatchToProps (dispatch) {
  return {
    checkDiscoveryPrimed: () => {
      return dispatch(discovery.checkSearchIsDiscoveryPrimed())
    },
    getPackageRates: () => {
      return dispatch(packageRates.get()).then(() => {
        dispatch(packageRates.filter())
      })
    },
    onHideModal: (modalName) => {
      return dispatch(modal.hide(modalName))
    },
    onToggleModal: (modalName) => {
      return dispatch(modal.toggle(modalName))
    },
    toggleEngineModal: () => {
      if (brandConfig.secure.hasFeatures.hasPackaging) return dispatch(engine.toggleModal())
      return dispatch(engineOld.toggleModal())
    },
    openDiscoveryModal: () => {
      dispatch({
        type: 'MODAL_TOGGLE',
        modalName: 'discoveryCalendar'
      })

      dispatch({
        type: 'DISCOVERY_CALENDAR'
      })
    },
    toggleIsSummaryElementExpanded: expanded => dispatch(searchSummary.toggleIsSummaryElementExpanded(expanded)),
    toggleIsSummaryTitleClickable: clickable => dispatch(searchSummary.toggleIsSummaryTitleClickable(clickable))
  }
}

// Exports for running with Redux in browser and for unit-testing (AvailabilityContainer)
export default connect(mapStateToProps, mapDispatchToProps)(AvailabilityContainer)
export {
  AvailabilityContainer
}
