import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import SeatListItem from '../atoms/SeatListItem'

class SeatList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      moreSeats: {}
    }
  }

  onShowMoreSeats (index) {
    this.setState((state) => ({
      moreSeats: {
        [index]: !this.state.moreSeats[index]
      }
    }))
  }

  onHoverSeats (seat) {
    this.props.hoverSeat(seat)
  }

  onSelectSeat (seat, book) {
    this.props.selectSeat(seat, book)
  }

  render () {
    let seatGroups = []
    const { hasSeatMapImage, sections, selected, venue } = this.props

    return (
      <div className='seat-list'>
        {/* If using an image for the seat map we just use the section keys defined by the API response */}
        {/* Otherwise we use the section keys from the venue seat map we provide */}
        {venue && venue.seatMap && sections && Object.keys(hasSeatMapImage ? sections : venue.seatMap).map((key, i) => {
          const seats = sections[key]
          if (!seats) return
          const open = _.get(this.state, `moreSeats[${i}]`, null)
          return (
            <div key={`section${i}`}>
              <div className='panel panel-default'>
                <div className='panel-heading text-uppercase text-muted small'>
                  {seats[0].section}
                </div>
                <div className='panel-body'>
                  <ul className='list-unstyled'>
                    {/* Displays 4 seats until "MORE SEATS" is clicked */}
                    {seats.slice(0, (open ? seats.length : 4)).map((seat, i2) => {
                      const rowFirst = `${seat.row}-${seat.first}`
                      if (_.includes(seatGroups, rowFirst)) return
                      seatGroups.push(rowFirst)
                      const isSelected = (selected && seat.row === selected.row && seat.first === selected.first)
                      return (
                        <li
                          className={`py-3 ${isSelected ? 'selected' : ''}`}
                          key={`seatListItem${i2}`}
                          onMouseEnter={this.onHoverSeats.bind(this, seat)}
                          onMouseLeave={this.onHoverSeats.bind(this, null)}>
                          <SeatListItem
                            seat={seat}
                            key={`seat${i}${i2}`}
                            isSelected={isSelected}
                            selectSeat={this.onSelectSeat.bind(this)}
                            hasSeatMapImage={hasSeatMapImage}
                          />
                        </li>
                      )
                    })}
                  </ul>
                </div>
              </div>
              {seats.length > 4 &&
                <div className='show-more text-center'>
                  <Button
                    onClick={() => this.onShowMoreSeats(i)}
                    onKeyDown={(e) => e.key === 'Enter' && this.onShowMoreSeats(i)}>
                    {open ? 'LESS' : 'MORE'} SEATS
                  </Button>
                </div>
              }
            </div>
          )
        })}
      </div>
    )
  }
}

SeatList.propTypes = {
  selected: PropTypes.object,
  selectSeat: PropTypes.func.isRequired,
  availability: PropTypes.object.isRequired
}

export default SeatList
