import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import BasketSummaryDiscountMessage from '../atoms/BasketSummaryDiscountMessage'
import BasketTotal from '../atoms/BasketTotal'
import BasketTotalWithSurcharge from '../atoms/BasketTotalWithSurcharge'

import BasketEventItem from '../molecules/BasketEventItem'
import BasketHotelItem from '../molecules/BasketHotelItem'
import BasketUpgradeSummary from '../molecules/BasketUpgradeSummary'
import BasketSummary from '../molecules/BasketSummary'

class DefaultBasket extends PureComponent {
  render () {
    return (
      <aside id='basket-summary' className='panel panel-default basket-wrapper'>
        {this.props.headerImageUrl && <img className='img-responsive' src={this.props.headerImageUrl} alt='Basket Header' />}
        <div className='panel-body'>
          {this.props.children && this.props.children.map((basketItem, index) => (basketItem) ? (
            <div key={`basketItem${index}`}>
              {(index > 0) && (index !== (this.props.children.length - 1)) && <hr className='hr-brand' />}
              {basketItem}
            </div>
          ) : null)}
        </div>
      </aside>
    )
  }
}

DefaultBasket.EventItem = BasketEventItem
DefaultBasket.HotelItem = BasketHotelItem
DefaultBasket.Summary = BasketSummary
DefaultBasket.Total = BasketTotal
DefaultBasket.TotalWithSurcharge = BasketTotalWithSurcharge
DefaultBasket.UpgradeItems = BasketUpgradeSummary
DefaultBasket.SummaryDiscountMessage = BasketSummaryDiscountMessage

DefaultBasket.propTypes = {
  headerImageUrl: PropTypes.string.isRequired
}

export default DefaultBasket
