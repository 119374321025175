import PropTypes from 'prop-types'
import React from 'react'
import DayPicker from 'react-day-picker'

const onDayClick = (props, day, modifiers, e) => {
  if (modifiers.disabled) {
    return
  }
  // Selects / De-selectes date if already chosen.
  props.onChange(modifiers.selected ? null : day)
}

const Calendar = (props) => {
  return (
    <DayPicker
      disabledDays={props.isDayDisabled}
      firstDayOfWeek={props.firstDayOfWeek}
      fixedWeeks={props.hasFixedWeeks}
      fromMonth={props.startDate}
      initialMonth={props.selected || props.startDate}
      onDayClick={onDayClick.bind(null, props)}
      renderDay={props.renderDay}
      selected={props.selected}
      selectedDays={day => DayPicker.DateUtils.isSameDay(props.selected, day)}
      toMonth={props.endDate} />
  )
}

Calendar.propTypes = {
  endDate: PropTypes.instanceOf(Date),
  firstDayOfWeek: PropTypes.number,
  hasFixedWeeks: PropTypes.bool.isRequired,
  isDayDisabled: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  renderDay: PropTypes.func,
  selected: PropTypes.instanceOf(Date),
  startDate: PropTypes.instanceOf(Date)
}

Calendar.defaultProps = {
  firstDayOfWeek: 0,
  hasFixedWeeks: false
}

export default Calendar
