import React, { Component } from 'react'

import Form from './UI/Form'
import HotelDate from './UI/HotelDate'
import Nights from './UI/Nights'
import PartyComp from './UI/PartyComp'
import Rooms from './UI/Rooms'
import TicketDate from './UI/TicketDate'
import Tickets from './UI/Tickets'

class WithTickets extends Component {
  render () {
    return (
      <Form
        inline={this.props.inline}
        engineLogicOverrides={{
          forceTicketByDate: !this.props.hasTicketSelectUI
        }}
        fieldsToValidate={{
          hotelDate: this.props.hasHotelDateUI,
          partyComp: this.props.hasPartyCompUI,
          rooms: this.props.hasRoomsSelectUI,
          ticketDate: this.props.hasTicketDateUI,
          tickets: this.props.hasTicketSelectUI
        }}
        className='with-tickets'
        hasHotelDate={this.props.hasHotelDateUI}
      >
        <div>
          {this.props.hasTicketDateUI && <TicketDate />}
          {this.props.hasTicketSelectUI && <Tickets />}
          {this.props.hasHotelDateUI && <HotelDate />}
          {this.props.hasNightsSelectUI && <Nights />}
          {this.props.hasRoomsSelectUI && <Rooms />}
          {this.props.hasPartyCompUI && <PartyComp />}
        </div>
      </Form>
    )
  }
}

WithTickets.defaultProps = {
  inline: false,
  hasHotelDateUI: true,
  hasNightsSelectUI: true,
  hasPartyCompUI: true,
  hasRoomsSelectUI: true,
  hasTicketDateUI: true,
  hasTicketSelectUI: true
}

export default WithTickets
