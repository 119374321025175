import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

const HotelLocation = ({
  closestTrainStation,
  distance,
  hasEnhancedHotelTile,
  isResort,
  isRoom,
  isTrainStationPreferred,
  showDistanceFromVenueName = false,
  venueName,
  venueType
}) => {
  if (isTrainStationPreferred && closestTrainStation) {
    return <FormattedMessage id='availability.distanceFromTrainStation' values={{ closestTrainStation }} />
  }
  if (isRoom) {
    return <FormattedMessage id='availability.roomIsInTheResort' />
  }
  if (isResort && hasEnhancedHotelTile && venueType === 'Castle') {
    return <FormattedMessage id='availability.accommodationAtTheCastle' tagName='strong' />
  }
  if (isResort && hasEnhancedHotelTile) {
    return <FormattedMessage id='availability.accommodationOnResort' tagName='strong' />
  }
  if (isResort && !hasEnhancedHotelTile) {
    return <FormattedMessage id='availability.accommodationAtResort' />
  }
  if (distance) {
    const id = `availability.${(hasEnhancedHotelTile || showDistanceFromVenueName) ? 'distanceFromVenue' : 'distanceFromVenueOnly'}`
    const venue = showDistanceFromVenueName ? venueName : venueType
    return <FormattedMessage id={id} values={{ distance, venue, showDistanceFromVenueName }} />
  }
  return null
}

HotelLocation.propTypes = {
  closestTrainStation: PropTypes.string,
  distance: PropTypes.number,
  hasEnhancedHotelTile: PropTypes.bool,
  isResort: PropTypes.bool,
  isRoom: PropTypes.bool,
  isTrainStationPreferred: PropTypes.bool,
  venueType: PropTypes.string
}

export default HotelLocation
