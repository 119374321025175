import _ from 'lodash'
import moment from 'moment'
import Payplast from 'payment-client'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Alert, Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import { emitter } from '@marvelapp/react-ab-test'

import { query } from './components/atoms/BreakPoint'
import SVGLock from './components/atoms/SVGLock'
import Loading from './components/molecules/Loading'

import Basket from './components/organisms/Basket/Basket'
import BookingSummary from './components/organisms/BookingSummary'
import PaymentForm from './components/organisms/PaymentForm'

import basketHelpers from './helpers/basketHelpers'
import generalHelpers from './helpers/generalHelpers'
import languageHelpers from './helpers/languageHelpers'
import routeHelpers from './helpers/routeHelpers'
import trackingHelpers from './helpers/trackingHelpers'

import modal from './actions/modal'
import payment from './actions/payment'
import vouchers from './actions/vouchers'

import config from './configs/config'
import roomTypes from './configs/roomTypes'
import SplitTest from './containers/SplitTest'

import {
  getTotalAmountToPay,
  getTotalVoucherValue
} from './selectors/payment'

const {
  PayplastConfig,
  brandConfig,
  childAgeControlId,
  endpoint,
  eventProduct,
  harvestBasketData,
  hotelEventProductsReply,
  hotelProduct,
  roomControlIds,
  venueProduct
} = config

class PaymentContainer extends Component {
  constructor (props) {
    super(props)
    // Tech Debt - This Jira will hopefully take care of Harvest not keeping the data type when using strings
    // https://hxshortbreaks.atlassian.net/browse/WEB-10518
    // Once this is complete, this code will need to go
    this.cancellationWaiverAmount = _.get(config, 'brandConfig.cancellationWaiverAmount', null)
    this.hasFeatures = _.get(brandConfig, 'secure.hasFeatures', {})
    this.hasParkEntry = (harvestBasketData.hasParkEntry === 'true')
    this.hasPtr = _.get(this.hasFeatures, 'payment.ptr')
    this.hasSimplifiedPaymentPage = _.get(this.hasFeatures, 'payment.hasSimplifiedPaymentPage', false)
    this.hideHotel = hotelProduct.hide_hotel === '1'
    this.hotelEventProducts = _.get(hotelEventProductsReply, 'hotelEventProducts[0]', {})
    this.isJustAnnualPassLabel = _.get(this.hasFeatures, 'payment.isJustAnnualPassLabel', false)
    this.isResortHotel = !!_.get(harvestBasketData, 'hotel.isResort', false)
    this.queryStringParams = routeHelpers.getQueryStringParams()

    const includes = 'hotelProducts,ticketProducts,eventProducts,roomRates'
    basketHelpers.handleGetSessionBasketData.call(this, includes)
    const hasBoldCancellationProtection = _.get(config, 'brandConfig.secure.hasFeatures.payment.hasBoldCancellationProtection', false)

    const { sundryRates = [] } = this.props
    this.cancellationWaiver = sundryRates.find(rate => rate.type === 'cancellationWaiver') || {}

    const cancellationWaiverLink = <button
      type='button'
      className='p-0 btn-link text-decoration-underline'
      {...trackingHelpers.getAttributes('open', 'payment', 'cancellationWaiverInfo')}
      onClick={() => this.props.onToggleModal('cancellationWaiver')}
      onKeyDown={(e) => e.key === 'Enter' && this.props.onToggleModal('cancellationWaiver')}>
      <FormattedMessage id='common.TCs' />
    </button>

    const bookingProtectedMessage = hasBoldCancellationProtection
      ? <FormattedMessage id='payment.cancellationProtectionMessageUpdate' values={{ duration: this.cancellationWaiver.cancellationWaiverDurationDescription, TCs: cancellationWaiverLink }} tagName='small' />
      : `With Cancellation Waiver you can cancel your booking for any reason up to ${this.cancellationWaiver.cancellationWaiverDurationDescription} before your stay and receive a full refund on the price of your booking.`

    Object.assign(this.cancellationWaiver, {
      bookingProtectedMessage,
      description: venueProduct.cancellationwaiver
    })

    this.postalConfirmation = Object.assign({}, {
      ...sundryRates.find(rate => rate.type === 'postalConfirmation'),
      description: venueProduct.postalconfirmation
    })
    // Default state
    this.state = {
      harvestData: null,
      isSummaryTitleClickable: query.isXs()
    }
  }

  componentDidMount () {
    if (window && window.tracker) {
      tracker.page(
        'payment', {
          page_type: this.props.pageType
        }
      )
    }
    window.addEventListener('load', this.scrollToElementByHashUrl)

    if (!harvestBasketData || Object.keys(harvestBasketData).length < 1) {
      return this.props.showCallbackForm('Error: harvestBasketData undefined')
    }
    if (!this.hasFeatures.leadName && this.isResortHotel) {
      this.props.setIsBookerFirstRoomLead()
    }

    this.configurePayplast()
    this.props.initializePaymentForm(harvestBasketData.rooms)
  }

  scrollToElementByHashUrl () {
    // Scroll to the element with id from the url hash
    generalHelpers.scrollElementIntoView(window.location.hash)
  }

  // Handle all things Payplast related.
  // @todo needs some tests
  configurePayplast () {
    const threeDSecureVersion = emitter.getActiveVariant('PAYT-279:3DSVersion') === 'show_original' ? 1 : 2
    // Bind some methods to a copy of PayplastConfig
    const config = Object.assign({}, PayplastConfig, {
      add3DSecureFrame: (iframe) => {
        this.props.onPaymentUpdate()
        this.props.onAdd3DSecureFrame(iframe.outerHTML)
        this.props.onToggleModal('modal3DSecure')
        this.props.onPaymentUpdate(true)
      },
      getBrandName: () => brandConfig.parkName,
      getGrossPrice: () => this.props.totalBookingValue,
      getTotalAmountToPay: () => this.props.totalAmountToPay,
      getPaymentSelected: () => this.props.billingDetails.paymentChoice,
      getTotalVoucherValue: () => this.props.totalVoucherValue,
      hotelCode: hotelProduct.id,
      providerEnvironment: this.props.billingDetails.providerEnvironment,
      referrer: this.queryStringParams.referrer,
      isFormValid: () => {
        return this.props.isFormValid
      },
      onBlur: () => generalHelpers.pauseCallRecording('blur'),
      onCardTypeChange: () => this.props.onPaymentCardTypeChange,
      onCardVerified: () => this.props.onPaymentUpdate(false),
      onFocus: () => generalHelpers.pauseCallRecording('focus'),
      onError: (error) => {
        window.scrollTo(0, 0)
        this.props.onPaymentUpdate(true)
        this.props.onHideModal('modal3DSecure')

        const errorCode = _.get(error, 'code', null)
        switch (errorCode) {
          case 'PAYPAL_POPUP_CLOSED':
            return trackingHelpers.track('sb.track', 'Close', 'Paypal', '1')
          case 'R218': // RESTRICTED_PREALLOCATED_ROOMS error
            // Restrict when we show the callback form to the hours when CX is not open as per FP-5769
            // @todo this should be removed as part of a solution to FP-6707
            if (moment('09:00', 'HH:mm').utc().isAfter(moment.utc()) ||
              (moment.utc().day() <= 5 && moment('21:00', 'HH:mm').utc().isBefore(moment.utc())) ||
              (moment.utc().day() > 5 && moment('17:30', 'HH:mm').utc().isBefore(moment.utc()))) {
              this.props.showCallbackForm()
            }
            break
        }

        const { errMessage, id, title } = error
        const details = errMessage && title !== errMessage ? `<br />details: ${errMessage}` : ''
        const errorId = typeof id !== 'undefined' ? '<br>If investigation needed quote EC-' + id + ' on QA Jira' : ''
        let errorText = this.props.isCallCentre ? `${errorCode} ${title}${errorId}${details}` : title
        errorText = errorText.replace('<phoneNumber>', _.get(brandConfig, 'number', ''))

        // Fallback
        if (!errorText) {
          const phoneNumber = brandConfig.secure.freePhoneNumber || brandConfig.number
          errorText = 'There has been a problem making your booking, please click the help button on the top right of the screen to contact our Guest Experience Team.'
          if (phoneNumber) {
            errorText = `There has been a problem making your booking, please contact our Guest Experience Team on ${phoneNumber}, calls charged at a local rate. `
          }
        }

        this.props.onPaymentClientError(errorText, error.allowRetry !== false)
        trackingHelpers.track('sb.track', 'Error message', 'Error payment', errorText)
        return trackingHelpers.track('sb.track', 'Error', 'Error payment', errorCode)
      },
      onPaymentClientToken: (data) => {
        const activatePaypal = data.activatePaypal
        const activateApplePay = data.activateApplePay && window.ApplePaySession && ApplePaySession.canMakePayments()

        // Tracking for Apple Pay support in browser
        if (data.activateApplePay && window.ApplePaySession) {
          trackingHelpers.track('sb.track', 'browserSupported', 'ApplePay', 1)
          if (ApplePaySession.canMakePayments()) trackingHelpers.track('sb.track', 'canMakePayments', 'ApplePay', 1)
        }

        const paymentChoice = data.activateDtmf ? 'dtmf' : 'card'
        this.props.onPaymentClientToken(paymentChoice, activatePaypal, activateApplePay)
      },
      onPaymentMethodReply: (reply) => {
        let { brand, isApplePay = false, type } = reply
        brand = type === 'PAYPAL' ? 'Paypal' : brand
        this.paymentBrand = brand

        trackingHelpers.track('sb.track', type, 'PaymentType', brand)

        if (isApplePay) {
          trackingHelpers.track('sb.track', isApplePay, 'isApplePay', type)
        }
      },
      onPasswordSubmit: encryptedPassword => this.props.onSubmitAdminPassword(encryptedPassword),
      onPaypalOpen: () => trackingHelpers.track('sb.track', 'Open', 'Paypal', '1'),
      onApplePayOpen: () => trackingHelpers.track('sb.track', 'Open', 'ApplePay', '1'),
      onSubmit: () => {
        if (_.get(brandConfig, 'secure.hasFeatures.payment.hasTwoStagePayment', false) && !this.props.isCallCentre) {
          this.props.setDummyAddressData()
        }
        this.onFormSubmit()
        if (!_.get(brandConfig, 'secure.withChildAges', false) && !harvestBasketData.packageTypeId) {
          this.props.checkChildAges(harvestBasketData, this.props.billingDetails)
        }

        this.props.onPaymentUpdate(false)
      },
      onValidityChange: (e) => {
        // Check if our payment details are correct
        const field = _.get(e, `fields[${e.emittedBy}]`, null)
        const inputId = _.get(field, 'container.id', null)
        if (!field || !inputId) {
          return null
        }
        return this.props.onPaymentFieldValidation(inputId, field.isValid)
      },
      threeDSecureVersion,
      remove3DSecureFrame: () => this.props.onRemove3DSecureFrame(),
      showCxCardFields: this.hasFeatures.payment && this.hasFeatures.payment.showCxCardFields
    })

    document.addEventListener('formDataRequestEvent', () => {
      const form = document.getElementById(PayplastConfig.formId)

      if (!form) {
        return null
      }
      const { billingDetails } = this.props
      let creditCardSurcharge
      // Should be set permanently to true after 13 of January 2018 according to
      // EU Law which means we will be unable to charge our customers credit card surcharge.
      // We need to ensure that from this date we are no longer charging our customers and
      // that all mentions of credit card surcharge are removed from the website.
      const removeCreditCardSurcharge = brandConfig.secure.removeCreditCardSurcharge

      // Do not apply credit card fees
      if (removeCreditCardSurcharge) {
        creditCardSurcharge = 0
        harvestBasketData.surcharge = 0
      } else {
        creditCardSurcharge = basketHelpers.getCreditCardSurcharge(brandConfig.creditCardSurcharge, this.props.totalBookingValue)
        harvestBasketData.surcharge = creditCardSurcharge.toFixed(2)
      }

      // Add billingDetails to each room.
      const numInfants = Number(_.get(harvestBasketData, 'ticket.numInfants1', 0)) + Number(_.get(harvestBasketData, 'ticket.numInfants2', 0))

      harvestBasketData.rooms.map((room, index) => {
        const title = billingDetails[`titleRoom${index + 1}`]
        const givenName = billingDetails[`initialRoom${index + 1}`]
        const familyName = billingDetails[`surnameRoom${index + 1}`]
        return Object.assign(room, {
          childrenAges: [],
          familyName,
          givenName,
          numInfants,
          title
        })
      })

      harvestBasketData.isBookerFirstRoomLead = billingDetails.isBookerFirstRoomLead

      Object.keys(billingDetails).forEach(key => {
        if (/^childAge.*\d+$/.test(key)) {
          const roomIndex = Number(key.substr(-1)) - 1
          // Value of child age dropdown
          const age = billingDetails[key]
          harvestBasketData.rooms[roomIndex].childrenAges.push({
            age
          })
        }
      })

      this.updateBasket().then(() => {
        const data = {
          addressCountry: billingDetails.billToAddressCountry,
          addressLocality: billingDetails.billToAddressCity,
          addressRegion: billingDetails.billToAddressState,
          basketId: window.basket.id,
          detailedAddress: billingDetails.billToAddressLine2,
          email: billingDetails.billToEmail,
          familyName: billingDetails.billToSurname,
          givenName: billingDetails.billToForename,
          grossPrice: this.props.totalBookingValue.toFixed(2),
          grossPriceAndSurcharge: (this.props.totalBookingValue + creditCardSurcharge).toFixed(2),
          houseNumber: billingDetails.billToAddressLine0,
          isBookerFirstRoomLead: billingDetails.isBookerFirstRoomLead,
          operator: window.basket.data.operator || 'rev',
          paymentChoice: billingDetails.paymentChoice,
          postalCode: billingDetails.billToAddressPostalCode,
          providerEnvironment: billingDetails.providerEnvironment,
          remarks: billingDetails.remarks,
          streetAddress: billingDetails.billToAddressLine1,
          telephone: billingDetails.billToPhone,
          title: billingDetails.billToTitle
        }
        if (billingDetails.paymentChoice === 'dtmf') {
          const [month = 0, year = 0] = billingDetails.dtmfCardExpiryDate.replace(' ', '').split('/')
          data.expiryMonth = month
          data.expiryYear = year
          data.securityCode = billingDetails.dtmfSecurityCode
        } else if (billingDetails.paymentChoice === 'skip') {
          if (!removeCreditCardSurcharge) {
            data.applyCreditCardFees = billingDetails.applyCreditFees
          }
          data.adminPassword = billingDetails.adminPassword
        }

        if (this.props.vouchers && this.props.vouchers.length > 0) {
          data.vouchers = this.props.vouchers.map(voucher => ({
            redemptionValue: voucher.redemptionValue,
            voucherCode: voucher.voucherCode
          }))
        }

        // Weird
        const event = document.createEvent('Event')
        event.initEvent('formDataResponseEvent', true, true)
        event.detail = data
        return form.dispatchEvent(event)
      }, err => {
        console.error('Updating Harvest has resulted in an error:', err)
        // When there's an error with Harvest, show the callback form
        this.props.showCallbackForm(`Updating Harvest has resulted in an error: ${err.message || err}`)
      })
    })

    // Go to Preference Centre or Monsters for Call Centre or Almost Done for 2 Stage Payment Brands
    document.addEventListener('paymentComplete', (e) => {
      const { id, customerId } = (e && e.detail) || {}
      if (!id || !customerId) {
        throw new Error('Order details missing')
      }

      let nextEndpoint = 'confirmation'
      if (brandConfig.secure && brandConfig.secure.hasPreferenceCentre) {
        nextEndpoint = 'preferencecentre'
      }
      if (this.hasFeatures.payment && this.hasFeatures.payment.hasTwoStagePayment) {
        nextEndpoint = 'almostDone'
      }

      let url = `${nextEndpoint}/${id}/${customerId}`
      if (this.queryStringParams.referrer) {
        url += `?referrer=${this.queryStringParams.referrer}`
      }

      // create a cookie to prevent the dataLayer in the confirmation to send more than once
      const date = new Date()
      date.setTime(date.getTime() + (1 * 24 * 60 * 60 * 1000))
      document.cookie = 'confirmationCookie=;expires=' + date.toUTCString() + ' UTC;path=/'

      routeHelpers.navigateTo(url)
    })
    document.addEventListener('paymentReady', this.props.onPaymentUpdate.bind(null, true))
    document.addEventListener('tearDown', this.props.onPaymentUpdate.bind(null, false))

    config.hasTwoStagePayment = (_.get(brandConfig, 'secure.hasFeatures.payment.hasTwoStagePayment', false))

    // Start the payment client
    Payplast.start(config)
  }

  // @todo this method can probably go into app.js
  // so we can pass them down to all the containers and remove duplication
  getBasketComponent () {
    if (!this.state.harvestData || !venueProduct || !harvestBasketData) return null
    const selectedItems = harvestBasketData.selectedItems
    const jsonPackage = selectedItems && Object.keys(selectedItems).length ? JSON.parse(selectedItems[Object.keys(selectedItems)[0]].jsonPackage) : null
    const { date, timeslot } = _.get(jsonPackage, 'data.ticket', {})
    const seats = _.get(selectedItems[Object.keys(selectedItems)], 'seats', null)
    const showData = {
      date: moment(date).format('DD MMM YYYY'),
      seats
    }
    if (timeslot && timeslot.start && timeslot.start !== '0000') {
      showData.timeslot = { start: moment(timeslot.start, 'HHmm').format('h:mma') }
    }
    const ticketName = _.get(harvestBasketData, 'ticket.name', false)
    const eventDisplay = (this.hasParkEntry)
      ? (<span className='event-name'>{ticketName}</span>)
      : (<div><strong><FormattedMessage id='common.ticket' />: </strong><span className='event-name'>{ticketName}</span></div>)

    const hideBasketSVGs = this.hasFeatures.hideBasketSVGs || false
    const showBasketParkDate = this.hasFeatures.showBasketParkDate || false
    const isBreakfastIncluded = _.get(hotelProduct, 'facilities.hasBreakfastIncluded', false)
    const checkinDate = moment.utc(harvestBasketData.hotel.checkinDate)
    const checkoutDate = moment.utc(harvestBasketData.hotel.checkoutDate)
    const extraNights = this.getExtraNightFromHarvestBasket()
    const showUpgradeSummary = (harvestBasketData.upgrades && harvestBasketData.upgrades.length > 0) || this.props.billingDetails.hasCancellationWaiver || this.props.billingDetails.hasPostalConfirmation || extraNights
    const roomDescriptions = this.hasFeatures.hasFixedSummary ? basketHelpers.getPartyPerRoom(roomTypes, harvestBasketData.rooms) : basketHelpers.getRoomsDescriptions(roomTypes, harvestBasketData.rooms)
    const partyComposition = _.get(brandConfig, 'secure.withChildAges') ? this.props.hotelCompositionString : this.props.ticketCompositionString

    const wasText = this.props.isAnnualPass ? <FormattedMessage id='common.standardPrice' /> : <FormattedMessage id='common.was' />

    // Filter out upgrades we don't want to display in the basket (e.g. Season pass or night based ones)
    const upgradesToDisplayInBasket = (harvestBasketData.upgrades || []).filter(upgrade => {
      return (upgrade.isSwappableTicket !== 'true' && upgrade.isSwappableTicket !== true) && (upgrade.isNightsBased !== 'true' && upgrade.isNightsBased !== true)
    })

    if (this.hasFeatures.hasUpdatedBookingSummary) {
      return (
        <BookingSummary
          bestPricePromise={this.hasFeatures.bestPricePromise}
          cancellationWaiver={(this.props.billingDetails.hasCancellationWaiver && this.cancellationWaiver) || ''}
          cancellationWaiverPrice={this.cancellationWaiverAmount}
          cateringDescription={isBreakfastIncluded && hotelProduct.breakfastType}
          cateringDetails={isBreakfastIncluded && hotelProduct.breakfast_details_booking_flow}
          checkInDate={checkinDate}
          checkInTime={this.hotelEventProducts.checkinTime || hotelProduct.checkinTime}
          checkOutDate={checkoutDate}
          checkOutTime={this.hotelEventProducts.checkoutTime || hotelProduct.checkoutTime}
          discountMessage={harvestBasketData.ticket.summaryDiscountMessage}
          endpoint={endpoint}
          grossPrice={this.props.totalAmountToPay}
          hasCancellationProtectionMessage={this.hasFeatures.hasCancellationProtectionMessage}
          hasFixedSummary={this.hasFeatures.hasFixedSummary}
          hasInitialBasketCollapsed={this.hasFeatures.hasInitialBasketCollapsed}
          hasParkEntry={this.hasParkEntry}
          hasYourStay={this.hasFeatures.summary && this.hasFeatures.summary.hasYourStay}
          hotelName={hotelProduct.name}
          isPaymentStage
          postalConfirmation={this.props.billingDetails.hasPostalConfirmation && this.postalConfirmation}
          roomDescriptions={roomDescriptions}
          roomDetails={this.props.roomDetails}
          roomName={_.get(harvestBasketData, 'hotel.roomThemeName')}
          showSectionPrices={this.hasFeatures.basket && this.hasFeatures.basket.showSectionPrices}
          standardPrice={this.props.standardPrice}
          ticketAdditionalDetails={eventProduct.additionalDescription || ''}
          ticketName={ticketName}
          upgrades={upgradesToDisplayInBasket}
          wasText={wasText}
        />
      )
    }

    return (
      <Basket
        cancellationWaiver={(this.props.billingDetails.hasCancellationWaiver && this.cancellationWaiver) || ''}
        cancellationWaiverPrice={_.get(harvestBasketData, 'ticket.isSwappableTicket', false) ? null : this.cancellationWaiver.grossPrice}
        category={eventProduct.category}
        cateringDescription={_.get(hotelProduct, 'facilities.hasBreakfastIncluded', false) && hotelProduct.breakfastType}
        checkInDate={checkinDate}
        checkInTime={this.hotelEventProducts.checkinTime || hotelProduct.checkinTime}
        checkOutDate={checkoutDate}
        checkOutTime={this.hotelEventProducts.checkoutTime || hotelProduct.checkoutTime}
        creditCardSurcharge={brandConfig.creditCardSurcharge}
        discountMessage={harvestBasketData.ticket && harvestBasketData.ticket.summaryDiscountMessage &&
          <Basket.SummaryDiscountMessage
            message={harvestBasketData.ticket.summaryDiscountMessage}
          />
        }
        endpoint={endpoint}
        eventTitle={eventDisplay}
        extraNights={extraNights}
        grossPrice={this.props.totalAmountToPay}
        hasBestPricePromise={this.hasFeatures.bestPricePromise}
        hasFixedSummary={this.hasFeatures.hasFixedSummary}
        hasParkEntry={this.hasParkEntry}
        hideBasketSVGs={hideBasketSVGs}
        hideHotel={this.hideHotel}
        hotelEventDescription={this.hotelEventProducts.description || null}
        isPaymentStage
        isSummaryTitleClickable={this.state.isSummaryTitleClickable}
        name={hotelProduct.name}
        parkDate={this.props.parkDate}
        partyComposition={partyComposition}
        postalConfirmation={this.props.billingDetails.hasPostalConfirmation && this.postalConfirmation}
        roomDescriptions={roomDescriptions}
        roomName={_.get(harvestBasketData, 'hotel.roomThemeName')}
        shouldBasketExpand={false}
        showBasketParkDate={showBasketParkDate}
        showData={showData}
        showUpgradeSummary={showUpgradeSummary}
        standardPrice={this.props.standardPrice}
        summaryTitle='Upgrade Information'
        ticketName={ticketName}
        upgrades={upgradesToDisplayInBasket}
        venueType={venueProduct.venueType}
        wasText={wasText}
        whatsIncluded={_.get(harvestBasketData, 'ticket.whatsIncluded', eventProduct.whatsIncluded)}
      />
    )
  }

  getExtraNightFromHarvestBasket () {
    if (!harvestBasketData.extraNightObject) return null

    const { extraNightTimeslot, grossPrice, name } = harvestBasketData.extraNightObject
    const oppositeNight = (extraNightTimeslot === 'before') ? 'after' : 'before'

    return {
      [extraNightTimeslot]: {
        grossPrice,
        isInBasket: true,
        name
      },
      [oppositeNight]: { isInBasket: false, name }
    }
  }

  onFormSubmit () {
    const { billingDetails } = this.props
    // Start with those fields that are always visible
    let fieldsToValidate = Object.assign({}, billingDetails)

    // Some fields need trimming and checking to ensure they are not passed down empty or booking will fail
    // So a seperate object is built and passed to allow these to be handled differently
    const {
      billToAddressCity,
      billToAddressCountry,
      billToAddressLine0,
      billToAddressPostalCode,
      billToSurname,
      billToForename
    } = billingDetails

    const fieldsToTrim = {
      billToAddressCity,
      billToAddressCountry,
      billToAddressLine0,
      billToAddressPostalCode,
      billToSurname,
      billToForename
    }

    // Additional validation wil be handled by Payment-client
    if (billingDetails.paymentChoice === 'card') {
      // The details assigned here are just 'valid' or undefined
      Object.assign(fieldsToValidate, {
        cardExpiryDate: billingDetails.cardExpiryDate,
        creditCardNumber: billingDetails.creditCardNumber,
        securityCode: billingDetails.securityCode
      })

      if (this.props.isCallCentre && this.hasFeatures.payment.showCxCardFields) {
        fieldsToValidate = this.removeValidationChecks(fieldsToValidate, ['dtmfCardExpiryDate', 'dtmfSecurityCode'])
      }
    }

    // When paymentChoice is dtmf
    if (billingDetails.paymentChoice === 'dtmf') {
      // The details assigned here are just 'valid' or undefined
      Object.assign(fieldsToValidate, {
        dtmfCardExpiryDate: billingDetails.dtmfCardExpiryDate,
        dtmfSecurityCode: billingDetails.dtmfSecurityCode
      })

      // Remove the validation check for card details if we show them
      if (this.hasFeatures.payment.showCxCardFields) {
        fieldsToValidate = this.removeValidationChecks(fieldsToValidate, ['cardExpiryDate', 'creditCardNumber', 'securityCode'])
      }
    }

    // When paymentChoice is skip paymwnt
    if (billingDetails.paymentChoice === 'skip') {
      // The details assigned here are just 'valid' or undefined
      Object.assign(fieldsToValidate, {
        adminPassword: billingDetails.adminPassword
      })

      fieldsToValidate = this.removeValidationChecks(fieldsToValidate, ['dtmfCardExpiryDate', 'dtmfSecurityCode'])

      // Remove the validation check for card details if we show them
      if (this.hasFeatures.payment.showCxCardFields) {
        fieldsToValidate = this.removeValidationChecks(fieldsToValidate, ['cardExpiryDate', 'creditCardNumber', 'securityCode'])
      }
    }

    // When paymentChoice is paypal
    if (billingDetails.paymentChoice === 'paypal') {
      fieldsToValidate = this.removeValidationChecks(fieldsToValidate, ['cardExpiryDate', 'creditCardNumber', 'securityCode'])
    }

    // When annual pass is not available
    if (!this.props.isAnnualPass) {
      fieldsToValidate = this.removeValidationChecks(fieldsToValidate, ['annualPassNo'])
    }

    // Add rooms
    if (harvestBasketData.rooms) {
      // Loop over rooms
      harvestBasketData.rooms.forEach((room, roomIndex) => {
        // Adds control IDs used validation the for lead room names on payment form
        this.hasFeatures.leadName && this.isResortHotel && roomControlIds.map(id => {
          const controlId = languageHelpers.sprintf(id, roomIndex + 1)
          fieldsToValidate[controlId] = billingDetails[controlId]
        })
        // Add children
        room.agesToValidate.forEach((age, childIndex) => {
          const id = languageHelpers.sprintf(childAgeControlId, childIndex + 1, roomIndex + 1)
          fieldsToValidate[id] = billingDetails[id]
        })
      })
    }
    this.props.onFormSubmit(fieldsToValidate, fieldsToTrim)
  }

  // When we make a booking we shouldn't validate payments we aren't using
  removeValidationChecks (fieldsToValidate, fields) {
    fields.forEach(function (field) {
      delete fieldsToValidate[field]
    })
    return fieldsToValidate
  }

  updateBasket () {
    if (!window || !window.basket || !harvestBasketData || !window.updateBasket) {
      throw new Error('Basket not available for update.')
    }
    return window.updateBasket({
      method: 'put',
      basketId: window.basket.id,
      version: window.basket.version,
      data: harvestBasketData
    }).then(() => {
      window.basket.data = harvestBasketData
      return Promise.resolve()
    })
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)
  }

  render () {
    // Get components
    const basketComponent = this.getBasketComponent()
    const loadingComponent = this.props.isLoading && (<Loading />)
    return (
      <div>
        <SplitTest.Experiment name='PAYT-279:3DSVersion'>
          <SplitTest.Variant name='show_original' />
          <SplitTest.Variant name='show_alternative' />
        </SplitTest.Experiment>
        {loadingComponent}

        <Row>

          {this.props.errors && this.props.errors.paymentClient && (
            <Col xs={12}>
              <Alert
                bsStyle='warning'
              >
                <p
                  {...trackingHelpers.getAttributes('Error Message', 'Error Payment', this.props.errors.paymentClient)}
                >{this.props.errors.paymentClient}</p>
              </Alert>
            </Col>
          )}

          {this.props.errors && this.props.errors.adminPasswordError && (
            <Col xs={12}>
              <Alert
                bsStyle='warning'
              >
                <p
                  {...trackingHelpers.getAttributes('Error Message', 'Error Payment', this.props.errors.adminPasswordError)}
                >{this.props.errors.adminPasswordError}</p>
              </Alert>
            </Col>
          )}

          {(this.props.hasChildValidationError || this.props.hasYoungAdultsValidationError) && this.props.childValidationError && (
            <Col xs={12}>
              <Alert
                bsStyle='warning'
              >
                <strong
                  {...trackingHelpers.getAttributes('Error Message', 'Error Payment', this.props.childValidationError)}
                >{this.props.childValidationError}</strong>
              </Alert>
            </Col>
          )}

          {this.hasFeatures.hasFixedSummary &&
            <Col xs={12} md={4} mdPush={8} style={{ zIndex: 3 }}>
              {basketComponent}
            </Col>
          }

          {!this.hasFeatures.hasFixedSummary &&
            <Col xs={12} md={4} mdPush={8} className='mt-3'>
              {basketComponent}
            </Col>
          }

          <Col xs={12} md={8} mdPull={4}>
            <div className='hidden-xs stepper-wrapper'>
              {this.props.progressTracker}
            </div>

            <div className='panel panel-default payment-form-wrapper mt-3'>
              <div className='panel-heading'>
                {/* PaymentHeading */}
                {this.hasSimplifiedPaymentPage
                  ? <h1 className='mt-0'>
                    <SVGLock height='1em' width='1em' />&nbsp;
                    <FormattedMessage id='payment.speedyPayment' />
                  </h1>
                  : <h2 className='mt-0'>
                    <SVGLock height='1em' width='1em' />&nbsp;
                    <FormattedMessage id='payment.paymentFormHeading' />
                  </h2>
                }
              </div>

              {this.props.showForm && (
                <div className='panel-body'>
                  <PaymentForm
                    assetsUrl={brandConfig.secure.assetsUrl}
                    billingDetails={this.props.billingDetails}
                    cancellationWaiver={this.cancellationWaiver}
                    creditCardSurcharge={brandConfig.creditCardSurcharge}
                    errors={this.props.errors}
                    frame3DSecure={this.props.frame3DSecure}
                    grossPrice={this.props.totalAmountToPay}
                    hasAnnualPassField={this.props.isAnnualPass}
                    hasBreakfastIncluded={_.get(hotelProduct, 'facilities.hasBreakfastIncluded', false)}
                    hasLeadName={this.hasFeatures.leadName && this.isResortHotel}
                    hasPaymentFormContactName={brandConfig.hasPaymentFormContactName}
                    hasPreferenceCentre={brandConfig.secure.hasPreferenceCentre}
                    hasPtr={this.hasPtr}
                    hasTicketsIncluded={this.hasParkEntry}
                    hideHotel={this.hideHotel}
                    isCallCentre={this.props.isCallCentre}
                    isJustAnnualPassLabel={this.isJustAnnualPassLabel}
                    maxChildAge={this.props.maxChildAge}
                    minChildAge={this.props.minChildAge}
                    onChangeMerlinAnnualPass={this.props.checkMap}
                    onRemoveAllVouchers={this.props.onRemoveAllVouchers}
                    onInputChange={this.props.onInputChange}
                    onToggleModal={this.props.onToggleModal}
                    onToggleUpgrade={this.props.onToggleUpgrade}
                    postalConfirmation={this.postalConfirmation}
                    privacyPolicyModal={brandConfig.privacy_policy}
                    privacyPolicyUrl={_.get(brandConfig, 'secure.privacyPolicyUrl')}
                    ptrLegislationUrl={_.get(brandConfig, 'secure.ptrLegislationUrl')}
                    ptrLegislationModal={brandConfig.package_travel_regulations}
                    removeCreditCardSurcharge={brandConfig.secure.removeCreditCardSurcharge}
                    rooms={this.props.roomDetails}
                    setLeadRoomDetails={this.props.setLeadRoomDetails}
                    termsAndConditions={brandConfig.termsAndConditions}
                    visibleModals={this.props.visibleModals}
                    whatsIncludedinSwappableTicket={_.get(harvestBasketData, 'ticket.isSwappableTicket', false) && _.get(harvestBasketData, 'ticket.groupTitle', null)}
                  />
                </div>
              )}
            </div>
          </Col>
        </Row>
      </div>
    )
  }
}

PaymentContainer.propTypes = {
  billingDetails: PropTypes.object.isRequired,
  creditCardType: PropTypes.string,
  errors: PropTypes.object,
  frame3DSecure: PropTypes.node,
  isAnnualPass: PropTypes.bool.isRequired,
  isCallCentre: PropTypes.bool.isRequired,
  isFormValid: PropTypes.bool.isRequired,
  isFormValidated: PropTypes.bool.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onAdd3DSecureFrame: PropTypes.func.isRequired,
  onFormSubmit: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onPaymentCardTypeChange: PropTypes.func.isRequired,
  onPaymentClientError: PropTypes.func.isRequired,
  onPaymentClientToken: PropTypes.func.isRequired,
  onPaymentFieldValidation: PropTypes.func.isRequired,
  onPaymentUpdate: PropTypes.func.isRequired,
  onRemove3DSecureFrame: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  onToggleUpgrade: PropTypes.func.isRequired,
  paymentClientToken: PropTypes.string,
  progressTracker: PropTypes.element.isRequired,
  setDummyAddressData: PropTypes.func.isRequired,
  setLeadRoomDetails: PropTypes.func.isRequired,
  showForm: PropTypes.bool,
  sundryRates: PropTypes.array.isRequired,
  totalAmountToPay: PropTypes.number.isRequired,
  totalBookingValue: PropTypes.number.isRequired,
  totalVoucherValue: PropTypes.number.isRequired,
  visibleModals: PropTypes.shape({
    cancellationWaiverInfo: PropTypes.bool,
    termsAndConditions: PropTypes.bool
  }).isRequired
}

function mapStateToProps (state) {
  return {
    billingDetails: state.payment.billingDetails,
    childValidationError: state.payment.childValidationError,
    errors: state.payment.errors,
    frame3DSecure: state.payment.frame3DSecure,
    hasChildValidationError: state.payment.hasChildValidationError,
    hasYoungAdultsValidationError: state.payment.hasYoungAdultsValidationError,
    hotelCompositionString: state.basket.hotelPartyString,
    isAnnualPass: state.annualPass.isAnnualPass,
    isFormValid: state.payment.isFormValid,
    isFormValidated: state.payment.isFormValidated,
    isLoading: state.payment.isLoading || state.vouchers.loading,
    maxChildAge: state.payment.maxChildAge,
    minChildAge: state.payment.minChildAge,
    parkDate: state.searchSummary.parkDate,
    roomDetails: state.payment.roomDetails,
    showCxCardFields: state.payment.showCxCardFields,
    showForm: state.payment.showForm,
    standardPrice: state.payment.standardPrice,
    ticketCompositionString: state.basket.ticketPartyString,
    totalAmountToPay: getTotalAmountToPay(state), // Subtracts the amount of vouchers
    totalBookingValue: state.payment.grossPrice, // This doesn't subtract amounts from vouchers used
    totalVoucherValue: getTotalVoucherValue(state),
    visibleModals: state.modal.visible,
    vouchers: (state.vouchers && state.vouchers.vouchers) || []
  }
}

function mapDispatchToProps (dispatch) {
  return {
    checkChildAges: harvestBasketData => dispatch(payment.checkChildAges(harvestBasketData)),
    checkMap: code => dispatch(payment.checkAnnualPass(code)),
    initializePaymentForm: rooms => dispatch(payment.initializePaymentForm(rooms)),
    onAdd3DSecureFrame: frame => dispatch(payment.add3DSecureFrame(frame)),
    onFormSubmit: (fields, fieldsToTrim) => dispatch(payment.formSubmit(fields, fieldsToTrim)),
    onHideModal: modalName => dispatch(modal.hide(modalName)),
    onInputChange: (id, value) => dispatch(payment.inputChange(id, value)),
    onPaymentCardTypeChange: event => dispatch(payment.paymentCardTypeChange(event.cards)),
    onPaymentClientError: (message, showForm) => dispatch(payment.paymentClientError(message, showForm)),
    onPaymentClientToken: (token, activatePaypal, activateApplePay) => dispatch(payment.paymentClientToken(token, activatePaypal, activateApplePay)),
    onPaymentFieldValidation: (id, isValid) => dispatch(payment.paymentFieldValidation(id, isValid)),
    onPaymentUpdate: isLoading => dispatch(payment.paymentUpdate(isLoading)),
    onRemoveAllVouchers: () => dispatch(vouchers.removeAll()),
    onRemove3DSecureFrame: () => dispatch(payment.remove3DSecureFrame()),
    onSubmitAdminPassword: value => dispatch(payment.submitAdminPassword(value)),
    onToggleModal: modalName => dispatch(modal.toggle(modalName)),
    onToggleUpgrade: (id, value, upgrade) => dispatch(payment.toggleUpgrade(id, value, upgrade)),
    setDummyAddressData: () => dispatch(payment.setDummyAddressData()),
    setIsBookerFirstRoomLead: () => dispatch(payment.setIsBookerFirstRoomLead()),
    setLeadRoomDetails: (id, value) => dispatch(payment.setLeadRoomDetails(id, value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentContainer)
export {
  PaymentContainer
}
