import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGTick extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <path d='M24.765,71.212c-2.617,0-5.073-1.016-6.917-2.859L2.926,54.627C1.018,52.722,0,50.263,0,47.648    c0-2.614,1.018-5.071,2.866-6.92c1.849-1.849,4.307-2.865,6.921-2.865c2.591,0,5.029,1,6.872,2.818l8.102,7.108L56.611,5.369    c0.058-0.075,0.119-0.146,0.187-0.213c1.849-1.85,4.308-2.867,6.921-2.867c2.614,0,5.072,1.018,6.922,2.867    c3.783,3.784,3.814,9.923,0.092,13.747L32.448,68.167c-0.051,0.065-0.106,0.128-0.165,0.188c-1.914,1.912-4.498,2.926-7.214,2.854    C24.967,71.21,24.867,71.212,24.765,71.212z M9.788,41.863c-1.546,0-2.999,0.602-4.093,1.695c-1.093,1.092-1.694,2.545-1.694,4.09    s0.602,3,1.694,4.094l14.922,13.726c1.148,1.146,2.6,1.914,4.148,1.914l0.227,0.164c0.05,0,0.1,0,0.151,0l0.221-0.164    c1.51,0,2.929-0.653,4.008-1.69l38.274-49.293c0.051-0.065,0.105-0.148,0.166-0.207c2.256-2.258,2.256-5.939,0-8.195    c-1.095-1.094-2.547-1.701-4.094-1.701c-1.502,0-2.917,0.566-3.998,1.602L26.665,51.919c-0.335,0.445-0.84,0.73-1.394,0.787    c-0.551,0.057-1.106-0.118-1.525-0.486l-9.771-8.572c-0.032-0.028-0.064-0.059-0.095-0.09    C12.787,42.464,11.334,41.863,9.788,41.863z' />
      </SVG>
    )
  }
}

SVGTick.propTypes = SVG.propTypes

SVGTick.defaultProps = {
  title: 'Tick Icon'
}

export default SVGTick
