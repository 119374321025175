import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGCharter extends PureComponent {
  render () {
    return (
      <div className={`charter charter--${this.props.type}`} />
    )
  }
}

SVGCharter.propTypes = SVG.propTypes

SVGCharter.defaultProps = {
  type: ''
}

export default SVGCharter
