const modal = {

  /**
   * This will dispatch an event that will hide the modal passed by 'modalName'
   *
   * @param {String} modalName name of the modal that should be hidden
   * @return {Function} Returns a function to dispatch
   */
  hide: (modalName) => {
    return (dispatch) => {
      return new Promise(resolve => {
        resolve(dispatch({
          modalName,
          type: 'MODAL_HIDE'
        }))
      })
    }
  },

  /**
   * This will dispatch an event that will show the modal passed by 'modalName'
   *
   * @param {String} modalName name of the modal that should be shown
   * @return {Function} Returns a function to dispatch
   */
  show: (modalName) => {
    return (dispatch) => {
      return new Promise(resolve => {
        resolve(dispatch({
          modalName,
          type: 'MODAL_SHOW'
        }))
      })
    }
  },

  /**
   * This will dispatch an event that will toggle the modal passed by 'modalName'
   *
   * @param {String} modalName name of the modal that should be toggled
   * @return {Function} Returns a function to dispatch
   */
  toggle: (modalName) => {
    return (dispatch) => {
      return new Promise(resolve => {
        resolve(dispatch({
          modalName,
          type: 'MODAL_TOGGLE'
        }))
      })
    }
  }
}

export default modal
