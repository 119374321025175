const configuration = {
  getRoomThemeOrdering: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        const storedConfiguration = getState().configuration
        if (Object.keys(storedConfiguration.roomThemeOrdering).length > 0) {
          return resolve()
        }

        // If we don't have roomThemeOrdering already, we'd need to get it here. For now, off the window
        // but using promises for when / if this is an ajax call later on.
        dispatch({
          type: 'RECEIVED_ROOM_THEME_ORDERING',
          roomThemeOrdering: window && window.roomThemeOrderingReply
        })
        resolve()
      })
    }
  }
}

export default configuration
