import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import React, { PureComponent } from 'react'

import DisplayPrice from './DisplayPrice'

class ExtraNightSummary extends PureComponent {
  render () {
    const extraNightIncludedMessage = <FormattedMessage id='basket.extraNightIncluded' values={{ isNightBefore: this.props.isNightBefore }} />

    if (!this.props.hasFixedSummary) {
      return (
        <div className='row no-margin-padding'>
          <div className='col-sm-9 pull-left no-margin-padding'>
            <div>
              {extraNightIncludedMessage}
            </div>
          </div>
          <div className='col-sm-3 pull-right no-margin-padding'>
            <div className='basket-upgrade-price text-right'>
              <strong>
                <DisplayPrice price={_.get(this.props, 'data.grossPrice')} />
              </strong>
            </div>
            {this.props.handleExtraNightCTA &&
              <div className='text-right'>
                <a
                  {...this.props.data.removeBtnTrackingAttrs}
                  role='button'
                  onClick={this.props.handleExtraNightCTA.bind(this, this.props.isNightBefore)}
                  onKeyDown={(e) => e.key === 'Enter' && this.props.handleExtraNightCTA.bind(this, this.props.isNightBefore)}>
                  remove
                </a>
              </div>

            }
          </div>
        </div>
      )
    } else {
      return (
        <div className='text-muted tiny row mb-2'>
          <div className='col-xs-8'>
            {extraNightIncludedMessage}
          </div>
          <div className='col-xs-4 text-right'>
            <DisplayPrice price={_.get(this.props, 'data.grossPrice')} />
            <br />
            {this.props.handleExtraNightCTA &&
              <div className='text-right'>
                <a
                  {...this.props.data.removeBtnTrackingAttrs}
                  role='button'
                  onClick={this.props.handleExtraNightCTA.bind(this, this.props.isNightBefore)}
                  onKeyDown={(e) => e.key === 'Enter' && this.props.handleExtraNightCTA.bind(this, this.props.isNightBefore)}>
                  <FormattedMessage id='common.remove' />
                </a>
              </div>
            }
          </div>
        </div>
      )
    }
  }
}

ExtraNightSummary.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.instanceOf(moment),
    name: PropTypes.string,
    grossPrice: PropTypes.string,
    removeBtnTrackingAttrs: PropTypes.array
  }).isRequired,
  handleExtraNightCTA: PropTypes.func,
  isNightBefore: PropTypes.bool
}

export default ExtraNightSummary
