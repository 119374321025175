
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import tracking from '../../actions/tracking'
import CalendarModule from 'discovery-calendar'

class Calendar extends PureComponent {
  componentDidMount () {
    if (this.props.fetchDataOnLoad) {
      this.props.fetchData()
    }
  }
  componentDidUpdate (prevProps) {
    if (prevProps.fetchDataOnLoad !== this.props.fetchDataOnLoad) {
      this.props.fetchData()
    }
  }
  render () {
    const { getEvents } = this.props
    if (!this.props.startDate) return null
    const hasPackaging = _.get(window.brandConfig, 'secure.hasFeatures.hasPackaging', false)
    const searchFormService = (hasPackaging ? window.searchFormServicePackaging : window.searchFormService) || {}

    return (
      <CalendarModule
        callback={(state) => {
          this.props.discoveryCalendarCallback({
            packagesCount: state.packagesCount,
            bounds: state.bounds,
            nights: state.nights,
            isLoading: state.isLoading
          })
        }}
        hasEventEntry={this.props.hasEventEntry}
        hotelId={this.props.hotelId}
        nights={this.props.nights}
        partyComp={this.props.partyComp}
        selectDay={this.props.selectDay}
        startDate={this.props.startDate}
        track={(name, value) => {
          this.props.track(name, (this.props.availabilityFinder ? 'availability-finder-calendar' : 'discovery-price-finder-calendar'), value)
        }}
        venueCode={this.props.venueCode}
        searchFormId={this.props.searchFormId}
        roomId={this.props.roomId}
        discoveryPrimerConfig={searchFormService.discoveryPrimerConfig || {}}
        availableDates={searchFormService.availableDates || searchFormService.dates || {}}
        partyComposition={Object.values(this.props.partyComposition)}
        livePackageGroupId={this.props.packageGroupId}
        getEvents={getEvents}
      />
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    hasEventEntry: state.discovery.hasEventEntry,
    hotelId: state.discovery.hotelId,
    nights: state.discovery.nights,
    partyComp: state.discovery.partyComp,
    searchFormId: state.discovery.searchFormId,
    startDate: state.discovery.startDate,
    venueCode: state.discovery.venueCode,
    partyComposition: state.discovery.partyComposition,
    roomId: state.discovery.roomId,
    packageGroupId: state.discovery.packageGroupId
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    fetchData: () => {
      return dispatch({
        type: 'DISCOVERY_CALENDAR'
      })
    },
    discoveryCalendarCallback: (state) => {
      dispatch({
        type: 'DISCOVERY_CALENDAR_UPDATE',
        packagesCount: state.packagesCount,
        bounds: state.bounds,
        nights: state.nights,
        isLoading: state.isLoading
      })
    },
    track: (name, category, value) => {
      dispatch(tracking.track(name, category, value))
    },
    selectDay: (day) => {
      return dispatch({
        type: 'DISCOVERY_CALENDAR_SELECT_DAY',
        day
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Calendar)
export {
  Calendar
}
