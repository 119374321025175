const trackingHelpers = {

  /**
   * getAttributes - returns an object of data attributes
   * this function will return the object even if the values are undefined
   * but React doesn't render them
   * @param  {String} [action] data-action
   * @param  {String} [category] data-category
   * @param  {String} [label] data-label
   * @return {Object} Object of data attributes
   */
  getAttributes: function (action, category, label) {
    return {
      'data-track-name': `${category}::${action}`,
      'data-track-value': label,
      'data-action': action,
      'data-category': category,
      'data-label': label
    }
  },

  /**
   * track - passing information on to the dataLayer of Google Analytics
   * @param  {String} event - event to be passed
   * @param  {String} action - category to be passed
   * @param  {String} category - category to be passed
   * @param  {String} label - label to be passed
   * @return {void}
   */
  /*
  * Everything GTM
  * At present simply capturing tags for sending to the data layer
  * But we can build on this file though as we need to add tracking requirements
  */
  track: function (event, action, category, label) {
    if (window && window.tracker) {
      if (event === 'sb.error') {
        if (window.tracker.e) {
          tracker.e('error', `${category}::${action}`, '' + label)
        }
      } else {
        if (window.tracker.inline) {
          // 'sb.track' is not a recognised tracking event for tracker
          const trackerEvent = event === 'sb.track' ? 'auto_capture' : event
          tracker.inline(trackerEvent, {
            name: `${category}::${action}`,
            value: '' + label
          })
        }
      }
    }

    // This shouldn't happen really, but should really check it before using it.
    if (typeof dataLayer === 'undefined' || !Array.isArray(dataLayer)) return

    dataLayer.push({
      event,
      action,
      category,
      label
    })

    // blank this track back out of the datalayer.
    dataLayer.push({
      action: null,
      category: null,
      label: null
    })
  },

  fbPixelTrack: function (amount = 0.00, ids, pageName, eventName) {
    ids = Array.isArray(ids) ? ids : [ids]
    amount = amount.toFixed(2)

    if (window.fbq) {
      window.fbq('track', eventName, {
        value: amount,
        currency: 'GBP',
        content_name: pageName,
        content_type: 'product',
        content_ids: ids
      })
    }
  }
}

export default trackingHelpers
