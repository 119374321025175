import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import trackingHelpers from '../../helpers/trackingHelpers'
import classNames from 'classnames'

class Select extends PureComponent {
  render () {
    const {
      disabled,
      label = null,
      name,
      onChange,
      options,
      placeholder = null,
      tracking,
      value
    } = this.props
    const selectClassNames = classNames({
      'col-sm-7 col-lg-6': label,
      'col-sm-12 col-lg-12': !label
    })
    return (
      <div className='form-horizontal'>
        <div className='form-group'>
          {label && <label className='col-sm-5 col-lg-6 control-label'>{label}</label>}
          <div className={selectClassNames}>
            <select
              name={name}
              value={value}
              disabled={disabled}
              onChange={onChange}
              className='form-control'
              {...trackingHelpers.getAttributes(tracking.action, tracking.category, tracking.label)}>
              {placeholder && <option disabled>{placeholder}</option>}
              {options.map((option, index) => <option key={index} value={option.value}>{option.text}</option>)}
            </select>
          </div>
        </div>
      </div>
    )
  }
}

Select.propTypes = {
  label: PropTypes.string,
  name: PropTypes.string,
  onChange: PropTypes.func,
  options: PropTypes.array.isRequired,
  placeholder: PropTypes.string,
  tracking: PropTypes.object,
  value: PropTypes.string
}

export default Select
