import classNames from 'classnames'
import moment from 'moment'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { connect } from 'react-redux'

import { query } from '../components/atoms/BreakPoint'
import ResponsiveImage from '../components/atoms/ResponsiveImage'
import SeatLoader from '../components/atoms/SeatLoader'

import CallbackForm from '../components/organisms/CallbackForm'
import SeatList from '../components/organisms/SeatList'
import SeatMap from '../components/organisms/SeatMap'

import basketHelpers from '../helpers/basketHelpers'
import routeHelpers from '../helpers/routeHelpers'

import engineOld from '../actions/engineOld'
import modal from '../actions/modal'

import {
  setEvent,
  setVenue,
  selectSeat,
  changeSeats,
  changeQuery,
  setIsLoading,
  setQueryObject,
  getPackageRates,
  setAvailability
} from '../actions/seats'

import config from '../configs/config'
const {
  brandConfig,
  endpoint,
  eventProduct,
  packageRatesReply
} = config

class Seats extends Component {
  constructor (props) {
    super(props)
    this.state = {
      hovered: null,
      numberOfSeats: 6,
      selected: []
    }

    this.book = this.book.bind(this)
    this.changeSeats = this.changeSeats.bind(this)
    this.changeMatinee = this.changeMatinee.bind(this)
    this.changePage = this.changePage.bind(this)
    this.handleGetSessionBasketData = basketHelpers.handleGetSessionBasketData.bind(this)
    this.handleSetSessionBasketData = basketHelpers.handleSetSessionBasketData.bind(this)
    this.hoverSeat = this.hoverSeat.bind(this)
    this.queryPackageRates = this.queryPackageRates.bind(this)
    this.seatOccupancy = _.get(brandConfig, 'maxSeatBookingOccupancy', 6)
    this.venueProducts = _.get(packageRatesReply, 'linked.venueProducts', null)
    const { assetsUrl, brandLogo, imagePath, imagesDomain } = brandConfig.secure || {}
    this.assetsUrl = assetsUrl
    this.brandLogo = brandLogo
    this.eventProduct = eventProduct
    this.imagesDomain = imagesDomain
    this.imagePath = imagePath
  }

  componentDidMount () {
    if (!Object.keys(packageRatesReply.packageRates).length) {
      const context = _.get(packageRatesReply, 'meta.context', {})
      const query = Object.assign({}, context, {
        SeatType: context.SeatType.slice(0, -1) + 'M',
        ticketRates: Object.assign({}, context.ticketRates, {
          code: context.ticketRates.code.slice(0, -1) + 'M'
        })
      })
      this.queryPackageRates(query)
      return
    }
    this.queryPackageRates(packageRatesReply.meta.context)
  }

  componentDidUpdate (prevProps) {
    if (!prevProps.booked && this.props.booked) this.book()
  }

  hoverSeat (seat) {
    this.setState((state) => ({ hovered: seat }))
  }

  queryPackageRates (queryObject) {
    this.props.setEvent(eventProduct)
    this.props.setQueryObject(queryObject)
    this.props.getPackageRates()
  }

  changeSeats (partyComposition) {
    this.props.changeSeats(partyComposition)
    this.props.getPackageRates()
  }

  changeMatinee (code) {
    let ticketRates = this.props.queryObject.ticketRates
    ticketRates.code = code
    this.props.changeQuery('ticketRates', ticketRates)
    this.props.changeQuery('SeatType', code)
    this.props.getPackageRates()
  }

  changePage (data) {
    const flow = brandConfig.secure && brandConfig.secure.flow
    const nextEndpoint = routeHelpers.getNextStage(flow, endpoint)
    let queryObject = this.props.queryObject
    queryObject.basketId = _.get(window, 'basket.id', null)
    queryObject.tag = 'seats'
    queryObject.versionId = _.get(window, 'basket.version', null)
    if (nextEndpoint) this.props.navigateTo(nextEndpoint, queryObject)
  }

  book () {
    const { row, first, last } = this.props.selected
    const harvestObject = {
      basketId: _.get(window, 'basket.id', null),
      data: this.props.queryObject,
      method: _.get(window, 'basket.id', null) ? 'put' : 'post',
      version: _.get(window, 'basket.version', null)
    }
    harvestObject.data.selectedItems = {
      [this.props.selected.packageRateId]: {
        id: this.props.selected.packageRateId,
        type: 'package',
        standardPrice: '',
        resource: 'packageRates',
        seats: `${row}${first}-${last}`,
        selectedSeats: this.props.selected.first,
        venueName: this.props.venue.name || '',
        timeslot: this.props.selected.timeslot,
        grossPrice: this.props.selected.grossPrice
      }
    }
    harvestObject.data.seatsUrl = window.location.href
    window.updateBasket(harvestObject).then(data => {
      this.handleSetSessionBasketData('sessionBasket', data.harvest.baskets)
      this.handleGetSessionBasketData('hotelProducts,ticketProducts,roomProducts,reviews,eventProducts,venueProducts')
      this.changePage(data)
    }, err => {
      console.error('Updating Harvest has resulted in an error:', err)
      // When there's an error with Harvest, show the callback form
      this.props.showCallbackForm()
    })
  }

  render () {
    const eveningFilterClasses = classNames({
      'btn-group': !query.isSm(),
      'btn-group-vertical btn-block': query.isSm()
    })
    const isSeatProviderAvailable = _.get(packageRatesReply, 'meta.providerStatuses.encore.isInService', true) && !_.get(packageRatesReply, 'meta.providerStatuses.encore.isDisabled', false)
    const { availability, errors, eventProduct, loading, queryObject, selected, ticketRates, venue } = this.props
    const seatArray = Object.keys(availability).map(key => {
      return availability[key]
    })
    const sections = _.groupBy(seatArray, seat => {
      return seat.sectionId
    })
    // Check for alternative code to filter Matinee performances
    let alternativeCode = _.get(availability, `${Object.keys(availability)[0]}.alternativeCode`, '').slice(3, 6)
    let timeslot = _.get(availability, `${Object.keys(availability)[0]}.timeslot`, '')

    const isAvailability = Object.keys(availability).length > 0
    const ticketRateCodeLastChar = _.get(queryObject, 'ticketRates.code', '').substr(-1)
    const eveningBtnActive = ticketRateCodeLastChar === 'E' ? 'active' : ''
    const afternoonBtnActive = ticketRateCodeLastChar === 'M' ? 'active' : ''
    return (
      <section className='seat-booking-container clearfix mt-3'>
        {this.props.progressTracker}
        {isSeatProviderAvailable ? (
          <div>
            {loading &&
              <SeatLoader message='Searching For Seats' logoUrl={`${this.assetsUrl}${this.imagePath}${this.brandLogo}`} />
            }
            {!loading && !isAvailability &&
              <div>
                <div className='panel panel-default'>
                  <div className='panel-heading'>
                    Sorry, it looks like we're all out of packages for the options you've chosen. Please try&nbsp;
                    <a
                      className='hoverable'
                      onClick={this.props.toggleEngineModal}
                      onKeyDown={(e) => e.key === 'Enter' && this.props.toggleEngineModal()}>
                      changing your search
                    </a>, or
                    <a
                      className='hoverable'
                      onClick={() => this.props.onToggleModal('contact')}
                      onKeyDown={(e) => e.key === 'Enter' && this.props.onToggleModal('contact')}>
                      &nbsp;contact us
                    </a> for help.
                  </div>
                </div>
              </div>
            }
            {!loading && isAvailability &&
              <div>
                <div className='row'>
                  <div className='col-sm-12 col-md-4'>
                    <div className='panel panel-default mb-3'>
                      {eventProduct.id &&
                        <React.Fragment>
                          <div className='media'>
                            <div className='media-left'>
                              { eventProduct.thumbnailImage &&
                                <img
                                  alt={eventProduct.thumbnailImageAltText || 'Poster'}
                                  className='media-object'
                                  src={`${this.imagesDomain}${eventProduct.thumbnailImage}`}
                                />
                              }
                            </div>
                            <div className='media-body py-3 px-2'>
                              {eventProduct.name &&
                                <h2 className='m-0'>{eventProduct.name}</h2>
                              }
                              {queryObject.ticketRates &&
                                <p className='text-muted m-0'>{moment(queryObject.ticketRates.startDate).format('dddd, Do MMM YYYY')}</p>
                              }
                              {eventProduct.shortDescription && <div className='m0' dangerouslySetInnerHTML={{ __html: eventProduct.shortDescription }} />}
                              {queryObject && queryObject.ticketRates &&
                                <React.Fragment>
                                  <p className='mt-2 mb-0'>Performance Time</p>
                                  <div className={eveningFilterClasses} role='group'>
                                    {(eveningBtnActive || alternativeCode) &&
                                    <Button
                                      bsStyle='default'
                                      bsSize='small'
                                      className={eveningBtnActive}
                                      onClick={() => this.changeMatinee(alternativeCode)}
                                      onKeyDown={(e) => e.key === 'Enter' && this.changeMatinee(alternativeCode)}>
                                      Evening {eveningBtnActive && `- ${timeslot}`}
                                    </Button>
                                    }

                                    {(afternoonBtnActive || alternativeCode) &&
                                      <Button
                                        bsStyle='default'
                                        bsSize='small'
                                        className={afternoonBtnActive}
                                        onClick={() => this.changeMatinee(alternativeCode)}
                                        onKeyDown={(e) => e.key === 'Enter' && this.changeMatinee(alternativeCode)}>
                                        Afternoon {afternoonBtnActive && `- ${timeslot}`}
                                      </Button>
                                    }
                                  </div>
                                </React.Fragment>
                              }
                            </div>
                          </div>
                        </React.Fragment>
                      }
                    </div>
                    <SeatList
                      availability={availability}
                      errors={errors}
                      hoverSeat={this.hoverSeat}
                      hasSeatMapImage={eventProduct.seatMapImage}
                      sections={sections}
                      selectSeat={this.props.selectSeat}
                      selected={selected}
                      ticketRates={ticketRates}
                      venue={venue}
                    />
                  </div>
                  <div className='col-md-8 hidden-xs hidden-sm'>
                    {eventProduct.seatMapImage ? (
                      <ResponsiveImage
                        alt={`Seat map for ${eventProduct.name}`}
                        containerSizes={[707, 720, 616, 750]}
                        lazyload={false}
                        src={`${this.imagesDomain}${eventProduct.seatMapImage}`}
                      />
                    ) : (
                      <SeatMap
                        availability={availability}
                        book={this.book}
                        errors={errors}
                        hoverSeat={this.hoverSeat}
                        hovered={this.state.hovered}
                        logoUrl={`${this.assetsUrl}${this.imagePath}loader_logo_w_0.png`}
                        seatOccupancy={6}
                        sections={sections}
                        hasSeatMapImage={eventProduct.seatMapImage}
                        selectSeat={this.props.selectSeat}
                        selected={selected}
                        venue={venue}
                      />
                    )}
                  </div>
                </div>
              </div>
            }
          </div>
        ) : (
          <CallbackForm
            brand={brandConfig.brand}
            isCallCentre={this.props.isCallCentre}
            url={_.get(brandConfig, 'secure.hasFeatures.callbackForm.url')}
          />
        )}
      </section>
    )
  }
}

Seats.propTypes = {
  availability: PropTypes.object.isRequired,
  errors: PropTypes.object,
  eventProduct: PropTypes.object.isRequired,
  loading: PropTypes.bool.isRequired,
  queryObject: PropTypes.object.isRequired,
  selected: PropTypes.object,
  venue: PropTypes.object.isRequired
}

function mapStateToProps (state) {
  return {
    availability: state.seats.availability,
    booked: state.seats.booked,
    errors: state.seats.errors,
    eventProduct: state.seats.eventProduct,
    loading: state.seats.loading,
    queryObject: state.seats.queryObject,
    selected: state.seats.selected,
    venue: state.seats.venue
  }
}

function mapDispatchToProps (dispatch) {
  return {
    changeSeats: (partyComposition) => {
      dispatch(changeSeats(partyComposition))
    },
    setQueryObject: (queryObject) => {
      dispatch(setQueryObject(queryObject))
    },
    changeQuery: (key, value) => {
      dispatch(changeQuery(key, value))
    },
    selectSeat: (seat, book) => {
      dispatch(selectSeat(seat, book))
    },
    setIsLoading: (bool) => {
      dispatch(setIsLoading(bool))
    },
    setEvent: (eventProduct) => {
      dispatch(setEvent(eventProduct))
    },
    getPackageRates: () => {
      dispatch(getPackageRates())
    },
    setVenue: (venueProducts) => {
      dispatch(setVenue(venueProducts))
    },
    setAvailability: (ticketRates, seatCombinations, packageRates) => {
      dispatch(setAvailability(ticketRates, seatCombinations, packageRates))
    },
    toggleEngineModal: () => {
      return dispatch(engineOld.toggleModal())
    },
    onToggleModal: (modalName) => {
      return dispatch(modal.toggle(modalName))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Seats)
export {
  Seats
}
