import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import { Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import modal from '../actions/modal'

class ModalContainer extends PureComponent {
  render () {
    return (
      <Modal
        onHide={() => this.props.onHideModal(this.props.modalId)}
        show>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id={this.props.formattedMessageId} />
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {this.props.children}
        </Modal.Body>
      </Modal>
    )
  }
}

ModalContainer.propTypes = {
  onHideModal: PropTypes.func.isRequired,
  formattedMessageId: PropTypes.string.isRequired
}

function mapStateToProps (state, ownProps) {
  return {}
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    onHideModal: (modalName) => {
      return dispatch(modal.hide(modalName))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(ModalContainer)
