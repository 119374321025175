import React, { useState } from 'react'
import { Modal, Row } from 'react-bootstrap'

import SVGMoon from '../atoms/SVGMoon_chessGrottoHack'
import SVGSun from '../atoms/SVGSun_chessGrottoHack'

/*
  * Currently this Component is used for Chessington Grotto.
  * The purpose of this component is to show a selection popup if a customer has to make a choice prior to choosing an upgrade,
  * i.e. Chessington Grotto, shows a modal selection of either "Morning" | "Evening", Choosing one will close this modal
  * and render the respective modal for that selection.
*/

const UpgradeModalSelection = ({ title, description, upgradeOne, upgradeTwo, openModal }) => {
  const [hideModal, setHideModal] = useState(false)

  const trigger = (upgrade) => {
    setHideModal(true)
    openModal(upgrade, true)
  }
  return (
    <div>
      <Modal show={!hideModal} backdrop='static' >
        <Modal.Header>
          <Modal.Title> {title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p>{description}</p>
          <Row>
            <div className='col-xs-6'>
              <button type='button' style={{ whiteSpace: 'normal' }} className='btn btn-default col-xs-12' onClick={() => trigger(upgradeOne)}>
                <div style={{ color: '#333333' }}>
                  <SVGMoon title='Afternoon' uniqueID='Afternoon Moon Icon' />
                </div>
                <h4 className='text-center'>Afternoon</h4>
              </button>
            </div>
            <div className='col-xs-6'>
              <button type='button' style={{ whiteSpace: 'normal' }} className='btn btn-default col-xs-12' onClick={() => trigger(upgradeTwo)}>
                <div style={{ color: '#333333' }}>
                  <SVGSun title='Morning' uniqueID='Morning Sun Icon' />
                </div>
                <h4 className='text-center'>Morning</h4>
              </button>
            </div>
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default UpgradeModalSelection
