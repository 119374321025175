import 'isomorphic-fetch'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import MarketingPreferences from './MarketingPreferences'

import experiment from '../actions/experiment'
import modal from '../actions/modal'

import routeHelpers from '../helpers/routeHelpers'
import trackingHelpers from '../helpers/trackingHelpers'

import BrandContext from '../contexts/BrandContext'

class PreferenceCentre extends Component {
  constructor (props) {
    super(props)

    this.queryStringParams = routeHelpers.getQueryStringParams()
    this.updatePreferences = this.updatePreferences.bind(this)
    this.trackPurchaseEvent = this.trackPurchaseEvent.bind(this)
  }

  componentDidMount () {
    if (window && window.tracker) {
      tracker.page(
        'post_booking', {
          page_type: 'extra_details'
        }
      )
      // This will call the action to complete a specific experiment as per how tracker works
      // https://github.com/holidayextras/tracker#split-tets
      // It is important to note here that because we are calling in the componentDidMount
      // that any split tests in this file will not be accounted for as they will fire after this function call.
      // I do not know a solution to this problem yet but as we don't have split tests in the file right now
      // I am not going to address it yet... sorry. Rob H. 15/05/19
      this.props.completeExperiment('EXP-020:AvailabilityMoreInfoModalButton')
      this.props.completeExperiment('SIOP-146:extraNights')
      this.props.completeExperiment('SIOP-160:DealFinder')
      this.props.completeExperiment('SIOP-161:ParkEntryUpsell')
      this.props.completeExperiment('PAYT-279:3DSVersion')
      this.props.completeExperiment('SIOP-294:alternativeRoomAndGroupSortOrders')
      this.props.completeExperiment('HBMTA-815')
    }
  }

  componentDidUpdate () {
    if (window && window.tracker && this.props.lineItems.length > 0) {
      tracker.track('ecommerce', {
        method: 'new',
        basket: {
          products: this.props.lineItems.map(item => {
            let name = item.name
            if (!item.name) {
              if (item.id === 'ALLMON') {
                name = 'Service Charge'
              } else if (/^ALL.*Y$/.test(item.id)) {
                name = 'Yield Charge'
              } else {
                name = 'Unknown'
              }
            }

            return {
              category: item.category,
              currency: item.currency,
              id: this.props.orderId,
              name,
              price: Math.round(item.price * 100),
              quantity: item.quantity,
              sku: item.id
            }
          })
        }
      })
      this.trackPurchaseEvent()
    }
  }

  trackPurchaseEvent () {
    if (typeof dataLayer === 'undefined' || !Array.isArray(dataLayer)) return

    const { agent, customers, grossPrice, lineItems, orderId, referrer } = this.props
    const testBooking = /\+sandbox/.test(customers.email) && /test/i.test(customers.familyName)
    const pageName = `preferencecentre/${this.props.orderId}/${this.props.customers.id}`

    const transactionProducts = [...lineItems].map(item => ({
      category: item.category,
      name: item.name,
      price: item.price,
      quantity: item.quantity,
      sku: item.id
    }))

    dataLayer.push({
      event: 'purchase',
      agent: agent,
      pageName: pageName,
      source: referrer,
      testBooking,
      transactionAffiliation: this.context.brandConfig.profile,
      transactionId: orderId,
      transactionProducts,
      transactionTotal: grossPrice
    })
  }

  updatePreferences (event) {
    if (event) event.preventDefault()

    const params = {
      body: JSON.stringify({
        allowGeneralMarketing: this.props.allowGeneralMarketing,
        allowOrderMarketing: this.props.allowOrderMarketing
      }),
      headers: {
        'Content-Type': 'application/json'
      },
      method: 'PUT'
    }

    const queryParams = this.queryStringParams.referrer ? '?referrer=' + this.queryStringParams.referrer : ''
    return fetch(`/ordersUpdate/${this.props.orderId}${queryParams}`, params)
      .then(response => {
        if (response.status >= 200 && response.status < 300) {
          const url = this.props.isCallCentre
            ? `https://monstersinc.holidayextras.co.uk/monstersinc/r/welcome?product=book&task=masterTemplateViewAmendCancel&getHistory=true&transactionReference=${this.props.orderId}`
            : `/confirmation/${this.props.orderId}/${this.props.customerId}${queryParams}`
          routeHelpers.navigateTo(url)
        }
      })
      .catch(error => console.error('Updating preferences has resulted in an error:', error))
  }

  render () {
    const privacyPolicyUrl = this.context.brandConfig.secure && this.context.brandConfig.secure.privacyPolicyUrl
    let privacyPolicyLink = <a
      {...trackingHelpers.getAttributes('Footer link click', 'Secure Shell', 'privacy policy')}
      data-prog='0'
      onClick={() => this.props.onShowModal('privacy')}
      onKeyDown={(e) => e.key === 'Enter' && this.props.onShowModal('privacy')}
      role='button'
      tabIndex='0'>
      <FormattedMessage id='common.privacyPolicy' />
    </a>
    if (privacyPolicyUrl) {
      privacyPolicyLink = <a
        {...trackingHelpers.getAttributes('Footer link click', 'Secure Shell', 'privacy')}
        data-prog='0'
        href={privacyPolicyUrl}
        role='button'
        target='_blank'>
        <FormattedMessage id='common.privacyPolicy' />
      </a>
    }

    let shouldShowThankYou = false
    const {
      name,
      parkName
    } = this.context.brandConfig
    let alternativeParkName = ''
    if (
      this.context.brandConfig.secure &&
      this.context.brandConfig.secure.hasFeatures.preferenceCentre) {
      shouldShowThankYou = this.context.brandConfig.secure.hasFeatures.preferenceCentre.shouldShowThankYou || false
      alternativeParkName = this.context.brandConfig.secure.hasFeatures.preferenceCentre.alternativeParkName || ''
    }
    const selectPreferencesTitleHeaderClass = shouldShowThankYou ? 'h3' : 'h2'

    // Confusing, but it's hxbreaks under the deals branding. Setting as this for go live but needs being set properly for the future
    // Louis P - 11/09/20
    const parkNameToUse = parkName || alternativeParkName || name

    return (
      <div className='row'>
        <div className='col-lg-8 col-lg-offset-2'>
          <div className='panel panel-default'>
            <div className='panel-body'>
              { shouldShowThankYou &&
                <FormattedMessage id='preferenceCentre.thankYouBreaks' tagName='h2' />
              }
              <FormattedMessage id='preferenceCentre.selectPreferencesTitle' tagName={selectPreferencesTitleHeaderClass} />
              <FormattedHTMLMessage
                id='preferenceCentre.bookingMade'
                values={{
                  parkName: parkNameToUse
                }}
              />
              <form onSubmit={this.updatePreferences} className='form-horizontal'>
                <fieldset>
                  <MarketingPreferences />
                </fieldset>
                <fieldset>
                  <div>
                    <div className='row'>
                      <div className='col-md-12'>
                        <FormattedMessage id='preferenceCentre.byContinueing' tagName='p' />
                        <FormattedMessage id='preferenceCentre.forMoreInformation' tagName='p' values={{ privacyPolicyLink: privacyPolicyLink }} />
                        {this.context.brandConfig.secure && this.context.brandConfig.secure.dataProtectionEmailAddress &&
                          <FormattedHTMLMessage
                            id='preferenceCentre.covidMessaging'
                            values={{
                              emailAddress: this.context.brandConfig.secure.dataProtectionEmailAddress
                            }}
                          />
                        }
                      </div>
                      <div className='col-md-6 col-md-offset-3'>
                        <Button
                          type='submit'
                          block
                          bsSize='large'
                          bsStyle='primary'
                          {...trackingHelpers.getAttributes('View Confirmation', 'Preference Centre', '1')}
                        >
                          <FormattedMessage id='preferenceCentre.viewConfirmation' />
                        </Button>
                      </div>
                    </div>
                  </div>
                </fieldset>
              </form>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

PreferenceCentre.contextType = BrandContext

PreferenceCentre.propTypes = {
  completeExperiment: PropTypes.func.isRequired
}

PreferenceCentre.defaultProps = {
  completeExperiment: () => {}
}

function mapStateToProps (state) {
  return {
    agent: state.preferenceCentre.agent,
    allowGeneralMarketing: state.preferenceCentre.allowGeneralMarketing,
    allowOrderMarketing: state.preferenceCentre.allowOrderMarketing,
    customers: state.preferenceCentre.customers,
    customerId: state.preferenceCentre.customerId,
    orderId: state.preferenceCentre.orderId,
    lineItems: state.preferenceCentre.lineItems,
    grossPrice: state.preferenceCentre.grossPrice,
    referrer: state.preferenceCentre.referrer
  }
}

function mapDispatchToProps (dispatch) {
  return {
    completeExperiment: experimentName => dispatch(experiment.complete(experimentName)),
    onShowModal: modalName => dispatch(modal.show(modalName))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PreferenceCentre)
export {
  PreferenceCentre
}
