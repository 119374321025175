import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedMessage } from 'react-intl'

import Stepper from './Stepper'

const PartyComp = (props) => {
  const childrenPartyComp = props.partyComp.find(party => party.key === 'children') || {}
  const minChildAge = childrenPartyComp.range[0]
  const maxChildAge = childrenPartyComp.range[1]
  const agesForSelect = Array.from({ length: maxChildAge - minChildAge + 1 }, (_, i) => minChildAge + i)

  return (
    <div>
      {props.partyComp.map((party, i) => {
        return (
          <div className='form-group' data-automated-test={`partyCompSelector_${party.key}`} key={i}>
            <div>
              <div>{party.text} <small>({party.subText || party.subtext})</small></div>
            </div>
            <div>
              <Stepper
                count={party.count}
                min={party.limit[0]}
                max={party.limit[1]}
                onUpdate={(count) => props.onChange(i, count)}
              />
            </div>
          </div>
        )
      })}
      {props.withChildAges && (props.children || []).length > 0 &&
        <React.Fragment>
          <h3 className='text-center block-xs'>How old are they?</h3>
          {props.children.map((child, childIndex) => {
            return (
              <div key={childIndex} className={classNames({
                'clear': childIndex % 2 === 0,
                'form-group col-xs-6': true,
                'has-error': props.children[childIndex].error && props.errorChildAges
              })}>
                <label htmlFor={`child${childIndex}`}>Child {childIndex + 1}</label>
                <select
                  id={`child${childIndex}`}
                  value={child.age}
                  className='form-control'
                  onChange={(e) => props.updateChild(childIndex, e.target.value)}
                  aria-invalid={props.children[childIndex].error}
                  aria-describedby={`help${childIndex}`}>
                  <option disabled selected={!child.age} value=''>
                    Select
                  </option>
                  {agesForSelect.map((age) => {
                    return (
                      <option key={age} value={age}>
                        {age === 1 ? (
                          '1 years and under'
                        ) : (
                          `${age} years`
                        )}
                      </option>
                    )
                  })}
                </select>
                {((props.children[childIndex].error || !props.children[childIndex].age) && props.errorChildAges) && <div id={`help${childIndex}`} className='help-block'>
                  <FormattedMessage id='common.pleaseSelect' />
                </div>}
                {childrenPartyComp.withChildHeights &&
                  <div>
                    <label className='font-weight-normal' htmlFor={`childHeigth${childIndex}`}>
                      <input className='mr-2' type='checkbox' id={`childHeigth${childIndex}`} checked={child.overOneMeter} onChange={(e) => props.updateChild(childIndex, null, e.target.checked)} />
                      <FormattedMessage id='common.overOneMeter' />
                    </label>
                  </div>}
              </div>
            )
          })}
        </React.Fragment>
      }
    </div>
  )
}

PartyComp.defaultProps = {
  childAges: []
}

PartyComp.propTypes = {
  errorChildAges: PropTypes.bool.isRequired,
  errorPartyComp: PropTypes.bool.isRequired,
  onChange: PropTypes.func.isRequired,
  partyComp: PropTypes.arrayOf(PropTypes.shape({
    count: PropTypes.number,
    key: PropTypes.string,
    limit: PropTypes.array,
    subText: PropTypes.string,
    text: PropTypes.string
  })).isRequired,
  updateChild: PropTypes.func.isRequired,
  withChildAges: PropTypes.bool.isRequired
}

export default PartyComp
