import _ from 'lodash'
import classNames from 'classnames'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import EngineUIRooms from '../../../components/atoms/EngineUI/Rooms'

import config from '../../../configs/config'
import engineOld from '../../../actions/engineOld'

class Rooms extends PureComponent {
  render () {
    if (!this.props.hasEngineStore) return null

    const withChildAges = _.get(config, 'brandConfig.secure.withChildAges', false)
    // return a boolean if there is a value in the childAges array that matches 1 (infant exists in party)
    const hasInfant = withChildAges && !!this.props.childAges.find(age => age.value === 1)
    const pleaseNote = <FormattedMessage id='common.pleaseNote' tagName='strong' />

    return (
      <div className={classNames({
        'searchform-roomtype': true,
        'has-error': this.props.error
      })}>
        <div className='form-group'>
          <EngineUIRooms
            label={this.props.label}
            id='rooms_select'
            rooms={this.props.rooms}
            suggestions={this.props.roomTypes}
            onChange={this.props.change}
          />
          {this.props.error && <div className='help-block'>{this.props.error}</div>}
        </div>
        {hasInfant && !this.props.visiblePartyComp &&
          <div className='alert alert-info mb-0' role='alert'>
            <FormattedMessage id='common.infantsUnder1NotIncluded' tagName='p' values={{ pleaseNote }} />
          </div>
        }
      </div>
    )
  }
}

function mapStateToProps (state) {
  const {
    childAges,
    rooms,
    roomTypes
  } = state.engineOld.engineStore

  const {
    errorRooms,
    visiblePartyComp
  } = state.engineOld

  return {
    childAges,
    rooms,
    roomTypes,
    visiblePartyComp,
    error: errorRooms,
    hasEngineStore: state.engineOld.hasEngineStore,
    label: _.get(state.engineOld.brandConfig, 'UITextDefaults.labelRooms', 'Room')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    change: (roomNumber, roomCode) => {
      return dispatch(engineOld.changeRoom(roomNumber, roomCode))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Rooms)
