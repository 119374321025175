import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import BrandContext from '../contexts/BrandContext'

import trackingHelpers from '../helpers/trackingHelpers'
import modal from '../actions/modal'
import chat from '../actions/chat'
import SVGPhone from '../components/atoms/SVGPhone'
import SVGShortBreaks from '../components/atoms/SVGShortBreaks'
import SVGUSPTagline from '../components/atoms/SVGUSPTagline'
import routeHelpers from '../helpers/routeHelpers'

class Header extends PureComponent {
  render () {
    return (
      <BrandContext.Consumer>
        {context => {
          const {
            headerStrapline,
            headerStraplineMobile,
            parkName,
            referer,
            secure = {},
            seo = {}
          } = context.brandConfig || {}
          const {
            hasFeatures: {
              header = {}
            },
            helpCentreUrl,
            useRequestReferer
          } = secure
          const { contact, helpCentre, liveChat } = header
          const { domain, homePath = '' } = seo

          let seoURL = `${domain}${homePath}`
          if (useRequestReferer && referer) {
            seoURL = referer
          }

          const queryStringParams = routeHelpers.getQueryStringParams()
          const showLiveChat = queryStringParams.utmSource === 'holiday_extras_breaks' || liveChat

          return (
            <div className='navbar-container navbar-primary'>
              <div className='navbar-inner'>
                <nav className='navbar navbar-inverse navbar-static-top mb-xs-0' role='navigation'>
                  <div className='container-fluid'>

                    <a className='navbar-brand' href={seoURL}>
                      {parkName}
                    </a>

                    {header.simpleStrapLine &&
                      <span className='shortbreaks-strapline' dangerouslySetInnerHTML={{ __html: headerStrapline }} />
                    }

                    {header.officalShortBreaksHeader &&
                      <React.Fragment>
                        <div className='row'>
                          <div className='col-xs-11 col-sm-12 col-sm-offset-0 col-xs-offset-1 col-md-4 col-md-offset-2 pr-0 mt-3 sb-nav-img'>
                            <SVGShortBreaks height='50' width='250' viewBox='0 0 238 48.8' />
                          </div>
                          <div className='col-sm-4 pt-4 mt-4 hidden-sm hidden-xs'>
                            <SVGUSPTagline height='24' width='228' viewBox='0 0 238 25' />
                          </div>
                        </div>
                        <div className='row text-center'>
                          <div className='col-xs-6 col-xs-offset-4 col-sm-8 col-sm-offset-2'>
                            <div className='h5'>
                              <span className='hidden-xs' dangerouslySetInnerHTML={{ __html: headerStrapline }} />
                              <span className='hidden-sm hidden-md hidden-lg' dangerouslySetInnerHTML={{ __html: headerStraplineMobile }} />
                            </div>
                          </div>
                        </div>
                      </React.Fragment>
                    }
                    <div className='navbar-text float-right'>
                      {contact &&
                        <a
                          {...trackingHelpers.getAttributes('Contact Us', 'Secure Shell', window.endpoint)}
                          className='navbar-link text-uppercase'
                          onClick={() => this.props.onToggleModal('contact')}
                          onKeyDown={(e) => e.key === 'Enter' && this.props.onToggleModal('contact')}>
                          <span className='hidden-xs'>Contact Us</span>
                          <span className='visible-xs'><SVGPhone /></span>
                        </a>
                      }

                      {!showLiveChat && helpCentre && this.props.showHelpCentre &&
                        <a
                          className='navbar-link text-uppercase'
                          {...trackingHelpers.getAttributes('header link click', 'Secure Shell', 'help')}
                          data-prog='0'
                          href={helpCentreUrl}
                          target='_blank'>
                          Help
                        </a>
                      }
                    </div>
                  </div>
                </nav>
              </div>
            </div>
          )
        }}
      </BrandContext.Consumer>
    )
  }
}

Header.defaultProps = {
  showHelpCentre: true
}

function mapStateToProps (state, ownProps) {
  return {
    chat: state.chat
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    onToggleModal: (modalName) => {
      return dispatch(modal.toggle(modalName))
    },
    toggleChatWindow: () => dispatch(chat.minimizeChat())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Header)
export {
  Header
}
