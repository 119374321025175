import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGPhone extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <path d='M6.609 10.781c1.453 2.813 3.797 5.156 6.609 6.609l2.203-2.203c0.281-0.281 0.703-0.375 1.031-0.234 1.125 0.375 2.344 0.563 3.563 0.563 0.563 0 0.984 0.422 0.984 0.984v3.516c0 0.563-0.422 0.984-0.984 0.984-9.375 0-17.016-7.641-17.016-17.016 0-0.563 0.422-0.984 0.984-0.984h3.516c0.563 0 0.984 0.422 0.984 0.984 0 1.266 0.188 2.438 0.563 3.563 0.094 0.328 0.047 0.75-0.234 1.031z' />
      </SVG>
    )
  }
}

SVGPhone.propTypes = SVG.propTypes

SVGPhone.defaultProps = {
  title: 'Phone Icon'
}

export default SVGPhone
