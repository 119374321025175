import _ from 'lodash'
import moment from 'moment'
import React from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'

import Calendar from './Calendar'

import engine from '../../../actions/engine'

import {
  getFirstAvailableTicketDate,
  getLastAvailableTicketDate,
  getSelectedTicketDate
} from '../../../selectors/engine'

const TicketDate = () => {
  const dispatch = useDispatch()
  const {
    dates,
    closeOutDates,
    firstAvailableTicketDate,
    isVisible,
    label,
    lastAvailableTicketDate,
    selectedTicketDate,
    ticketDate,
    hasTimeslotSelectUI
  } = useSelector((state) => {
    return {
      dates: state.engine.engineStore.dates,
      closeOutDates: state.engine.engineStore.closeOutDates,
      firstAvailableTicketDate: getFirstAvailableTicketDate(state),
      isVisible: state.engine.visibleTicketCalendar,
      label: _.get(state.engine.brandConfig, 'UITextDefaults.labelParkDate', 'Arrival Date'),
      hasTimeslotSelectUI: _.get(state, 'engine.brandConfig.hasTimeslotSelectUI', false),
      lastAvailableTicketDate: getLastAvailableTicketDate(state),
      selectedTicketDate: getSelectedTicketDate(state),
      ticketDate: state.engine.engineStore.ticketDate
    }
  }, shallowEqual)

  const dateAsString = (date, errorString = '') => {
    const asMoment = moment(date)
    return asMoment.isValid() ? asMoment.format('Do MMM YY') : errorString
  }

  const isTicketCalendarDayDisabled = ticketDate => {
    const date = moment(ticketDate)
    const check = !date.isBetween(firstAvailableTicketDate, lastAvailableTicketDate, 'day', '[]') || closeOutDates[date.format('YYYY-MM-DD')]
    if (hasTimeslotSelectUI) {
      if (!check && !(dates.all[date.format('YYYY-MM-DD')] && dates.all[date.format('YYYY-MM-DD')].timeslots.length)) {
        return true
      }
    }
    return check
  }

  const toggleVisible = e => {
    if (e && e.preventDefault) e.preventDefault()
    if (e && e.stopPropagation) e.stopPropagation()
    return dispatch(engine.toggle('ticketCalendar', false))
  }

  const change = ticketDate => dispatch(engine.changeTicketDate(ticketDate))

  return (
    <div className='searchform-ticketdate'>
      <div className='form-group'>
        <label htmlFor='pleaseChooseButton'>{label}</label>
        <button
          className='form-control ignore-react-onclickoutside'
          id='pleaseChooseButton'
          onClick={toggleVisible}
          onKeyDown={(e) => e.key === 'Enter' && toggleVisible()}
          type='button'
        >
          {dateAsString(ticketDate, 'Please choose')}
        </button>
        {isVisible &&
          <Calendar
            onClose={toggleVisible}
            isDayDisabled={ticketDate => isTicketCalendarDayDisabled(ticketDate)}
            selected={selectedTicketDate}
            onChange={ticketDate => toggleVisible() && change(ticketDate)}
          />
        }
      </div>
    </div>
  )
}

export default TicketDate
