import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'

import DisplayPrice from '../components/atoms/DisplayPrice'
import SVGFilters from '../components/atoms/SVGFilters'
import SVGStar from '../components/atoms/SVGStar'

import filters from '../actions/filters'
import packageRates from '../actions/packageRates'

import filtersHelpers from '../helpers/filtersHelpers'
import trackingHelpers from '../helpers/trackingHelpers'

class Filters extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      moreOptions: true
    }
    this.seeMoreOptions = this.seeMoreOptions.bind(this)
    this.getFilters = {}
  }

  componentWillReceiveProps (nextProps) {
    if (nextProps.hotelMaster.length !== 0) {
      this.getFilters = filtersHelpers.getFilters(nextProps.hotelMaster)
    }
    this.props.getFilteredPackages()
  }

  seeMoreOptions () {
    return this.setState(() => ({
      moreOptions: false
    }))
  }

  render () {
    if (Object.keys(this.getFilters).length === 0) return null
    const getUpdated = filtersHelpers.getUpdated(this.props.hotels)
    const titlePrice = `£${this.props.price}`
    const titleDistance = `${this.props.distance} miles`
    const price = (
      <div className='slider' data-automated-test='filter_price'>
        <label className='h5'>Price</label>
        <div>
          <span className='pull-left tiny text-faded'><DisplayPrice price={this.getFilters.price.low} operator='round' /></span> <span className='pull-right tiny text-faded'><DisplayPrice price={this.getFilters.price.high} operator='round' /></span>
        </div>
        <div className='text-center'><output className='pt-0 m-0 d-inline-block pos-rel' aria-live='polite'><DisplayPrice price={this.props.price === 99999 ? this.getFilters.price.high : this.props.price} operator='round' /></output></div>
        <input
          value={this.props.price}
          onChange={e => this.props.filterByPrice(e.target.value)}
          min={this.getFilters.price.low}
          max={this.getFilters.price.high}
          type='range'
          title={titlePrice}
          {...trackingHelpers.getAttributes('price change', 'filters', `${this.props.price} price chosen`)}
        />
        <hr />
      </div>
    )

    const distance = (
      <div
        data-automated-test='filter_distance'
        className='slider'
        {...trackingHelpers.getAttributes('distance to park change', 'filters', 'distance range filter')}
      >
        <label className='h5'>Distance from park</label>
        <div>
          <span className='pull-left tiny text-faded'>{this.getFilters.distance.onsite ? 'Onsite' : `${this.getFilters.distance.closest} miles`}</span> <span className='pull-right tiny text-faded'>{this.getFilters.distance.furthest} miles</span>
        </div>
        <div className='text-center'><output className='pt-0 m-0 d-inline-block pos-rel' aria-live='polite'>{this.props.distance === 99 ? this.getFilters.distance.furthest : this.props.distance} Miles</output></div>
        <input value={this.props.distance} onChange={e => this.props.filterByMilesToPark(e.target.value)} min={this.getFilters.distance.onsite ? '0' : this.getFilters.distance.closest} max={this.getFilters.distance.furthest} type='range' title={titleDistance} step='0.1' />
        <hr />
      </div>
    )

    const propertyType = (
      <div data-automated-test='filter_propertyType'>
        <h5><FormattedMessage id='common.propertyType' /></h5>
        <ul className='list-unstyled small'>
          {this.getFilters.propertyType.map(type => {
            return (
              <li key={type} className='checkbox'>
                <label>
                  <input
                    aria-label='...'
                    onChange={e => this.props.filterByPropertyType(e.target.value)}
                    type='checkbox'
                    value={type}
                    {...trackingHelpers.getAttributes('filter property type', 'filters', `${type} property type`)}
                  /> <span>{type}</span>
                </label>
              </li>
            )
          })}
        </ul>
        <hr />
      </div>
    )

    const starRating = (
      <div data-automated-test='filter_starRating'>
        <h5><FormattedMessage id='common.starRating' /></h5>
        <ul className='list-unstyled small'>
          {this.getFilters.starRating.map(rating => {
            const starArr = [...Array(rating)].map((x, i) => ++i)
            const stars = starArr.map(star => {
              return (<SVGStar
                key={star}
                height='1em'
                viewBox='0 0 24 24'
                width='1em' />)
            })
            return (
              <li key={rating} className='checkbox'>
                <label>
                  <input
                    aria-label='...'
                    id='blankCheckbox'
                    type='checkbox'
                    value={rating}
                    onChange={(e) => this.props.filterByStarRating(e.target.value)}
                    {...trackingHelpers.getAttributes('filter star rating', 'filters', `${rating} star rating`)}
                  /> <span>{stars}</span>
                </label>
              </li>
            )
          })}
        </ul>
        <hr />
      </div>
    )

    const popularOptions = (
      <div data-automated-test='filter_popularOptions'>
        <h5><FormattedMessage id='common.popularOptions' /></h5>
        <ul className='list-unstyled small'>
          {this.getFilters.popularOptions.map(option => {
            const disabled = Boolean(getUpdated.optionTotals[option.value])
            return (
              <li key={option.value} className='checkbox'>
                <label>
                  <input
                    aria-label='...'
                    id='blankCheckbox'
                    type='checkbox'
                    value={option.value}
                    disabled={(!disabled)}
                    onChange={(e) => this.props.filterByPopularOptions(e.target.value)}
                    {...trackingHelpers.getAttributes('filter popular options', 'filters', `${option.value} option`)}
                  />
                  <span className={classNames({
                    'text-faded': !disabled
                  })}>{option.text}</span>
                  {getUpdated.optionTotals[option.value] &&
                    <span className='text-primary'> ({getUpdated.optionTotals[`${option.value}`].counter})</span>
                  }
                </label>
              </li>
            )
          })}
        </ul>
        <hr />
      </div>
    )

    const hotelFeatures = (
      <div data-automated-test='filter_hotelFeatures'>
        <h5><FormattedMessage id='common.hotelFeatures' /></h5>
        <ul className='list-unstyled small'>
          {this.getFilters.hotelFeatures.map(feature => {
            const disabled = Boolean(getUpdated.optionTotals[feature.value])
            return (
              <li key={feature.value} className='checkbox'>
                <label>
                  <input
                    aria-label='...'
                    id='blankCheckbox'
                    type='checkbox'
                    value={feature.value}
                    disabled={(!disabled)}
                    onChange={(e) => this.props.filterByHotelFeatures(e.target.value)}
                    {...trackingHelpers.getAttributes('filter common options', 'filters', `${feature.value} option`)}
                  />
                  <span className={classNames({
                    'text-faded': !disabled
                  })}>{feature.text}</span>
                  {getUpdated.optionTotals[feature.value] &&
                    <span className='text-primary'> ({getUpdated.optionTotals[`${feature.value}`].counter})</span>
                  }
                </label>
              </li>
            )
          })}
          {this.getFilters.hotelFeaturesMore &&
            <div>
              {this.state.moreOptions &&
                <a className='collapsed' href='#hotel-features' data-toggle='collapse' aria-controls='#hotel-features' aria-expanded='false' onClick={this.seeMoreOptions}>See more options</a>
              }
              <div data-automated-test='filter_hotelFeaturesMore' id='hotel-features' className='collapse' aria-expanded='false'>
                {this.getFilters.hotelFeaturesMore.map(feature => {
                  const disabled = Boolean(getUpdated.optionTotals[feature.value])
                  return (
                    <li key={feature.value} className='checkbox'>
                      <label>
                        <input
                          aria-label='...'
                          id='blankCheckbox'
                          type='checkbox'
                          value={feature.value}
                          disabled={(!disabled)}
                          onChange={(e) => this.props.filterByHotelFeatures(e.target.value)}
                          {...trackingHelpers.getAttributes('Hotel features options', 'filters', `${feature.value} option`)}
                        />
                        <span className={classNames({
                          'text-faded': !disabled
                        })}>{feature.text}</span>
                        {getUpdated.optionTotals[feature.value] &&
                          <span className='text-primary'> ({getUpdated.optionTotals[feature.value].counter})</span>
                        }
                      </label>
                    </li>
                  )
                })}
              </div>
            </div>
          }
        </ul>
      </div>
    )

    const familyFeatures = (
      <div data-automated-test='filter_familyFeatures'>
        <hr />
        <h5><FormattedMessage id='common.familyFeatures' /></h5>
        <ul className='list-unstyled small'>
          {this.getFilters.familyFeatures.map(family => {
            const disabled = Boolean(getUpdated.optionTotals[family.value])
            return (
              <li key={family.value} className='checkbox'>
                <label>
                  <input
                    aria-label='...'
                    id='blankCheckbox'
                    type='checkbox'
                    value={family.value}
                    disabled={(!disabled)}
                    onChange={(e) => this.props.filterByHotelFeatures(e.target.value)}
                    {...trackingHelpers.getAttributes('Family features options', 'filters', `${family.value} option`)}
                  />
                  <span className={classNames({
                    'text-faded': !disabled
                  })}>{family.text}</span>
                  {getUpdated.optionTotals[family.value] &&
                    <span className='text-primary'> ({getUpdated.optionTotals[`${family.value}`].counter})</span>
                  }
                </label>
              </li>
            )
          })}
        </ul>
      </div>
    )

    return (
      <React.Fragment>
        <a aria-controls='#filter-options' aria-expanded='false' className='btn btn-secondary btn-sm collapsed visible-xs pull-right' data-toggle='collapse' href='#filter-options'>
          <SVGFilters width='18' height='18' viewBox='0 0 24 24' /> <FormattedMessage id='common.filter' />
        </a>
        <div className='clearfix' />
        <div id='filter-options' className='panel panel-default collapse-non-lg collapse-non-md collapse-non-sm' aria-expanded='true'>
          <div className='panel-heading'>
            <h2 className='panel-title'><FormattedMessage id='common.filterYourResults' /></h2>
          </div>
          <div className='panel-body'>
            <form>
              {price}
              {distance}
              {propertyType && propertyType.length > 1 &&
                propertyType
              }
              {starRating}
              {popularOptions}
              {hotelFeatures}
              {this.props.anyChildren &&
                familyFeatures
              }
              <a
                aria-controls='#filter-options'
                aria-expanded='false'
                className='btn btn-default btn-sm collapsed visible-xs pull-right'
                data-toggle='collapse'
                href='#filter-options'
                {...trackingHelpers.getAttributes('filter options', 'filters', 'filter options update')}
              >
                <FormattedMessage id='common.update' />
              </a>
            </form>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    distance: state.filters.milesToPark,
    familyFeatures: state.filters.familyFeatures,
    filters: state.filters,
    hotelFeatures: state.filters.hotelFeatures,
    popularOptions: state.filters.popularOptions,
    price: state.filters.price,
    propertyType: state.filters.propertyType,
    starRating: state.filters.starRating
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    getFilteredPackages: () => {
      return dispatch(packageRates.filter())
    },
    filterByPrice: (value) => {
      return dispatch(filters.filterByPrice(value))
    },
    filterByMilesToPark: (value) => {
      return dispatch(filters.filterByMilesToPark(value))
    },
    filterByPropertyType: (value) => {
      return dispatch(filters.filterByPropertyType(value))
    },
    filterByStarRating: (value) => {
      return dispatch(filters.filterByStarRating(value))
    },
    filterByPopularOptions: (value) => {
      return dispatch(filters.filterByPopularOptions(value))
    },
    filterByHotelFeatures: (value) => {
      return dispatch(filters.filterByHotelFeatures(value))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Filters)
