import classNames from 'classnames'
import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import DisplayPrice from '../atoms/DisplayPrice'
import SeatLoader from '../atoms/SeatLoader'

const createSeatCodesFromRange = (seats) => {
  return _.range(seats.first, Number(seats.last + 1)).map(i => {
    return `${seats.sectionRow}-${i}`
  })
}

class SeatMap extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.onHoverSeats = this.onHoverSeats.bind(this)
    this.onSelectSeat = this.onSelectSeat.bind(this)
  }

  onHoverSeats (seat) {
    this.props.hoverSeat(seat)
  }

  onSelectSeat (seat, book) {
    this.props.selectSeat(seat, book)
  }

  book () {
    this.props.selectSeat(this.props.selected, true)
  }

  render () {
    const { availability, errors, hovered, logoUrl, selected, venue } = this.props
    const { name, seatMap } = venue
    const hoverCodes = hovered ? createSeatCodesFromRange(hovered) : []
    const selectedCodes = selected ? createSeatCodesFromRange(selected) : []
    const classes = classNames({
      'seats-selected': this.props.selected,
      'seat-map panel panel-default': true
    })

    return (
      <div className={classes}>
        <div className='panel-body'>
          <div className='show-when-no-selection text-center'>
            {name && <h2 className='mb-0'>{name}</h2>}
            <p className='text-muted'>Choose your seats</p>
          </div>
          {((errors.seatMap || !seatMap) && !this.props.hasSeatMapImage) &&
            <SeatLoader message='Seat Map Unavailable' logoUrl={logoUrl} />
          }
          {!errors.seatMap && seatMap &&
            <div>
              <div className='show-when-selection row'>
                <div className='col-sm-9'>
                  {this.props.selected &&
                    <div>
                      Selected Seats: <strong>{this.props.selected.section} {this.props.selected.row}{this.props.selected.first}-{this.props.selected.last}</strong><br />
                      <div className='h2 m-0'><DisplayPrice price={this.props.selected.grossPrice} operator='ceil' /></div>
                      <p className='m-0'>Tickets, Hotel and Breakfast</p>
                    </div>
                  }
                </div>
                <div className='col-sm-3'>
                  <div className='chosen-seat'>
                    <Button
                      block
                      bsStyle='primary'
                      onClick={this.book.bind(this)}
                      onKeyDown={(e) => e.key === 'Enter' && this.book.bind(this)}>
                      Book Now
                    </Button>
                  </div>
                </div>
              </div>
              <div className='seats'>
                {Object.keys(seatMap).map((item, index) => {
                  const section = seatMap[item]
                  const sectionAvailability = this.props.sections[item]
                  const sectionName = sectionAvailability ? sectionAvailability[0].section : ''
                  return (
                    <div key={`seat-section${index}`} className='seat-section'>
                      <div className='text-uppercase text-muted text-center'>{sectionName}</div>
                      {Object.keys(section).map(_i => {
                        const row = section[_i]
                        return (
                          <ol key={`${item}_${_i}`} className='seat-row list-inline'>
                            {Object.keys(row).map(__i => {
                              const seatIndex = row[__i]
                              const code = `${item}-${_i}-${seatIndex}`
                              if (seatIndex === -1) return <li key={`filler${__i}`} className='filler' />
                              const seat = availability[code]
                              const seatClasses = classNames({
                                'available': code in availability,
                                'hovered': hoverCodes.includes(code),
                                'selected': selectedCodes.includes(code)
                              })

                              return (
                                <li key={`seat${__i}`}>
                                  {seatClasses.includes('available') &&
                                    <button
                                      className={seatClasses}
                                      onClick={() => this.onSelectSeat(seat, false)}
                                      onKeyDown={(e) => e.key === 'Enter' && this.onSelectSeat(seat, false)}
                                      onMouseEnter={() => this.onHoverSeats(seat)}
                                      onMouseLeave={() => this.onHoverSeats(null)}
                                      title={this.props.code}
                                    />
                                  }
                                </li>
                              )
                            })}
                          </ol>
                        )
                      })}
                    </div>
                  )
                })}
              </div>
            </div>
          }
        </div>
        {!errors.seatMap &&
          <div>
            <ol className='seat-row key'>
              <li>
                <FormattedMessage id='common.unavailable' />
              </li>
              <li>
                <button className='available' id='available-seat' />
                <label className='d-inline' htmlFor='available-seat'>
                  <FormattedMessage id='common.available' />
                </label>
              </li>
              <li>
                <button className='selected' id='selected-seat' />
                <label className='d-inline' htmlFor='selected-seat'>
                  <FormattedMessage id='common.selected' />
                </label>
              </li>
            </ol>
            <div className='stage'>
              <FormattedMessage id='seats.stage' />
            </div>
          </div>
        }
      </div>
    )
  }
}

SeatMap.propTypes = {
  errors: PropTypes.object,
  venue: PropTypes.object.isRequired,
  availability: PropTypes.object.isRequired,
  selected: PropTypes.object
}

export default SeatMap
