import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGUSPTagline extends PureComponent {
  render () {
    return (
      <SVG {...this.props}>
        <g>
          <g>
            <path fill='#FFFFFF' d='M3.7,5.5h-3V1.2H12v4.3H9v12H3.6L3.7,5.5L3.7,5.5z' />
            <path fill='#FFFFFF' d='M12.9,1.3h5.2v5.5H21V1.3h5.2v16.2H21v-6h-2.9v6h-5.2V1.3z' />
            <path fill='#FFFFFF' d='M27.8,1.3h9.6v4.3H33v1.8h4.3v4H33v1.9h4.6v4.3h-9.7V1.3H27.8z' />
            <path fill='#FFFFFF' d='M43.9,1.3h9.6v4.3h-4.4v1.8h4.3v4h-4.3v6.1h-5.2V1.3z' />
            <path fill='#FFFFFF' d='M61.5,17.9c-1,0-1.9-0.1-2.8-0.4s-1.5-0.6-2.1-1.2c-0.6-0.5-1-1.2-1.4-2s-0.5-1.7-0.5-2.8V1.3h5.2v10.1
            c0,0.6,0.1,1,0.4,1.3c0.2,0.3,0.6,0.5,1.2,0.5s0.9-0.2,1.2-0.5c0.2-0.3,0.4-0.7,0.4-1.3V1.3h5.2v10.3c0,1.1-0.2,2-0.5,2.8
            s-0.8,1.5-1.4,2s-1.3,0.9-2.1,1.2C63.4,17.8,62.5,17.9,61.5,17.9z' />
            <path fill='#FFFFFF' d='M69.8,1.3h4.3l4.5,6.3V1.3h5.1v16.2h-4.3l-4.5-6.2v6.2h-5.1V1.3z' />
            <path fill='#FFFFFF' d='M89.8,12.5c0.4,0.1,0.7,0.2,1.1,0.3c0.3,0.1,0.7,0.1,1.1,0.2c0.4,0,0.8,0.1,1.3,0.1c0.7,0,1.1-0.3,1.1-0.8
            c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.5-0.5-0.9-0.7c-0.3-0.3-0.7-0.6-1.1-0.9C91.7,9.8,91.3,9.4,91,9c-0.3-0.4-0.6-0.9-0.9-1.4
            c-0.2-0.5-0.3-1.1-0.3-1.8c0-0.8,0.1-1.4,0.4-2s0.7-1.1,1.1-1.5c0.5-0.4,1.1-0.7,1.7-0.9c0.7-0.2,1.4-0.3,2.1-0.3
            c0.4,0,0.8,0,1.2,0.1c0.4,0,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1,0.1V6c-0.1,0-0.3,0-0.4,0s-0.3,0-0.4,0c-0.2,0-0.3,0-0.5,0
            c-0.1,0-0.2,0-0.4,0c-0.1,0-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2s-0.1,0.2-0.1,0.3c0,0.1,0.1,0.3,0.3,0.5s0.5,0.4,0.8,0.7
            s0.7,0.6,1,0.9s0.7,0.7,1,1.2c0.3,0.4,0.6,0.9,0.8,1.5s0.3,1.2,0.3,1.8c0,0.7-0.1,1.4-0.3,2c-0.2,0.6-0.6,1.2-1.1,1.6
            c-0.5,0.5-1.1,0.8-1.8,1.1c-0.7,0.3-1.6,0.4-2.7,0.4c-0.6,0-1.2,0-1.7-0.1s-1-0.1-1.4-0.2c-0.5-0.1-0.9-0.2-1.3-0.3L89.8,12.5
            L89.8,12.5z' />
            <path fill='#FFFFFF' d='M103.3,5.5h-3V1.2h11.3v4.3h-3v12h-5.4v-12H103.3z' />
            <path fill='#FFFFFF' d='M117.3,1.3h3.9l6.2,16.2h-5.1l-0.5-1.5h-4.9l-0.5,1.5h-5.1L117.3,1.3z M120.4,12.5l-1.1-2.8l-1.1,2.8H120.4z'
            />
            <path fill='#FFFFFF' d='M127.8,1.3h5.6c1,0,2,0.1,2.9,0.4s1.7,0.7,2.3,1.2c0.6,0.5,1.2,1.2,1.5,2c0.4,0.8,0.5,1.7,0.5,2.7
            c0,0.8-0.1,1.5-0.3,2.1c-0.2,0.6-0.5,1-0.8,1.4s-0.7,0.8-1.1,1.1l3,5.4h-6.1l-2.4-4.1v4.1h-5.2V1.3H127.8z M132.9,9.3h0.3
            c0.6,0,1.1-0.1,1.6-0.4c0.4-0.3,0.6-0.7,0.6-1.4c0-0.6-0.2-1.1-0.6-1.4s-1-0.4-1.6-0.4h-0.3V9.3z' />
            <path fill='#FFFFFF' d='M144.1,5.5h-3V1.2h11.3v4.3h-3v12H144v-12H144.1z' />
            <path fill='#FFFFFF' d='M152.8,12.5c0.4,0.1,0.7,0.2,1.1,0.3c0.3,0.1,0.7,0.1,1.1,0.2c0.4,0,0.8,0.1,1.3,0.1c0.7,0,1.1-0.3,1.1-0.8
            c0-0.2-0.1-0.4-0.3-0.6c-0.2-0.2-0.5-0.5-0.9-0.7c-0.3-0.3-0.7-0.6-1.1-0.9c-0.4-0.3-0.8-0.7-1.1-1.1c-0.3-0.4-0.6-0.9-0.9-1.4
            c-0.2-0.5-0.3-1.1-0.3-1.8c0-0.8,0.1-1.4,0.4-2s0.7-1.1,1.1-1.5c0.5-0.4,1.1-0.7,1.7-0.9s1.4-0.3,2.1-0.3c0.4,0,0.8,0,1.2,0.1
            c0.4,0,0.7,0.1,1,0.1c0.4,0,0.7,0.1,1,0.1V6c-0.1,0-0.3,0-0.4,0s-0.3,0-0.4,0c-0.2,0-0.3,0-0.5,0c-0.1,0-0.2,0-0.4,0
            s-0.3,0-0.4,0.1c-0.1,0-0.2,0.1-0.3,0.2c-0.1,0.1-0.1,0.2-0.1,0.3c0,0.1,0.1,0.3,0.3,0.5c0.2,0.2,0.5,0.4,0.8,0.7s0.7,0.6,1,0.9
            c0.4,0.3,0.7,0.7,1,1.2c0.3,0.4,0.6,0.9,0.8,1.5s0.3,1.2,0.3,1.8c0,0.7-0.1,1.4-0.3,2c-0.2,0.6-0.6,1.2-1.1,1.6
            c-0.5,0.5-1.1,0.8-1.8,1.1c-0.7,0.3-1.6,0.4-2.7,0.4c-0.6,0-1.2,0-1.7-0.1s-1-0.1-1.4-0.2c-0.5-0.1-0.9-0.2-1.3-0.3L152.8,12.5
            L152.8,12.5z' />
            <path fill='#FFFFFF' d='M169.1,1.3h5.2v5.5h2.9V1.3h5.2v16.2h-5.2v-6h-2.9v6h-5.2V1.3z' />
            <path fill='#FFFFFF' d='M184.1,1.3h9.6v4.3h-4.4v1.8h4.3v4h-4.3v1.9h4.6v4.3h-9.7V1.3H184.1z' />
            <path fill='#FFFFFF' d='M195.3,1.3h5.6c1,0,2,0.1,2.9,0.4s1.7,0.7,2.3,1.2s1.2,1.2,1.5,2c0.4,0.8,0.5,1.7,0.5,2.7
            c0,0.8-0.1,1.5-0.3,2.1c-0.2,0.6-0.5,1-0.8,1.4s-0.7,0.8-1.1,1.1l3,5.4h-6.1l-2.4-4.1v4.1h-5.2V1.3H195.3z M200.5,9.3h0.3
            c0.6,0,1.1-0.1,1.6-0.4c0.4-0.3,0.6-0.7,0.6-1.4c0-0.6-0.2-1.1-0.6-1.4s-1-0.4-1.6-0.4h-0.3V9.3z' />
            <path fill='#FFFFFF' d='M209.5,1.3h9.6v4.3h-4.4v1.8h4.3v4h-4.3v1.9h4.6v4.3h-9.8V1.3z' />
            <path fill='#FFFFFF' d='M220.6,13.4h5.2v4.1h-5.2V13.4z' />
            <path fill='#FFFFFF' d='M227.2,13.4h5.2v4.1h-5.2V13.4z' />
            <path fill='#FFFFFF' d='M233.9,13.4h5.2v4.1h-5.2V13.4z' />
          </g>
        </g>
      </SVG>
    )
  }
}

SVGUSPTagline.propTypes = SVG.propTypes

SVGUSPTagline.defaultProps = {
  title: 'The Fun Starts Here...'
}

export default SVGUSPTagline
