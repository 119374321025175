import _ from 'lodash'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Button, Col, ControlLabel, FormControl, HelpBlock } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import SVGLoadingSpinner from '../components/atoms/SVGLoadingSpinner'

import CardFields from './Payment/CardFields'
import DTMFFields from './Payment/DTMFFields'
import PaymentChoiceTypeButton from './Payment/PaymentChoiceTypeButton'
import VoucherFields from './Payment/VoucherFields'

import BrandContext from '../contexts/BrandContext'

import payment from '../actions/payment'

import {
  getTotalAmountToPay
} from '../selectors/payment'

class PaymentChoices extends Component {
  constructor () {
    super()
    this.onCardPasswordSubmit = this.onCardPasswordSubmit.bind(this)
  }

  onCardPasswordSubmit (e) {
    if (e.key === 'Enter') {
      e.preventDefault()
      document.getElementById('adminPasswordButton').click()
    } else {
      this.props.onInputChange(e.target.id, e.target.value)
    }
  }

  shouldComponentUpdate (nextProps) {
    return !_.isEqual(nextProps, this.props)
  }

  render () {
    const assetsUrl = _.get(this.context, 'brandConfig.secure.assetsUrl')
    const cardPasswordFieldClasses = classNames('password-fields-container', {
      'hidden': this.props.paymentClientToken !== 'dtmf' || this.props.adminPasswordValid || this.props.paymentChoice !== 'card'
    })
    const paymentFeatures = _.get(this.context, 'brandConfig.secure.hasFeatures.payment', {})
    const paypalButtonClassNames = classNames({
      'hidden-xs': this.props.activatePaypal && !this.props.activatePaypal.mobile,
      'hidden-lg': this.props.activatePaypal && !this.props.activatePaypal.largeComputer,
      'hidden-md': this.props.activatePaypal && !this.props.activatePaypal.smallComputer,
      'hidden-sm': this.props.activatePaypal && !this.props.activatePaypal.tablet
    })
    const hasSimplifiedPaymentPage = paymentFeatures.hasSimplifiedPaymentPage || false
    const hasVoucherField = paymentFeatures.hasVoucherField || false
    const hasPaypalChoice = Object.keys(this.props.activatePaypal || {}).some(key => ['mobile', 'tablet', 'Computer'].includes(key))
    const showCxCardFields = paymentFeatures.showCxCardFields || false

    // This is used twice in the code
    const PayPalPaymentChoice = () => <PaymentChoiceTypeButton
      additionalClassNames={paypalButtonClassNames}
      checked={this.props.paymentChoice === 'paypal'}
      disabled={this.context.isCallCentre || this.props.totalAmountToPay === 0}
      id='paypalButton'
      onChange={this.props.onPaymentChoiceChange}
      value='paypal'>
      <img src={`${assetsUrl}shared/paypal.png`} alt='PayPal logo' height='20' />
    </PaymentChoiceTypeButton>

    const cardChoicesClassNames = classNames({
      'col-sm-9 col-sm-offset-3': (this.props.enableVoucherPayment || !hasVoucherField) && !hasSimplifiedPaymentPage,
      'col-sm-8 col-sm-offset-4': this.props.enableVoucherPayment && hasSimplifiedPaymentPage,
      'd-inline-block': !this.props.enableVoucherPayment && hasVoucherField
    })

    return (
      <div>
        <div className='form-group'>
          <div className={classNames({
            'col-lg-8 col-sm-offset-4': hasSimplifiedPaymentPage && !hasVoucherField,
            'col-sm-12 text-sm-center': hasVoucherField && !this.props.enableVoucherPayment
          })}>
            {hasVoucherField &&
              <React.Fragment>
                <div className={this.props.enableVoucherPayment ? 'col-sm-12 text-sm-center' : 'd-inline-block'}>
                  <PaymentChoiceTypeButton
                    checked={this.props.enableVoucherPayment}
                    defaultChecked={this.props.enableVoucherPayment}
                    id='enableVoucherPayment'
                    name='enableVoucherPayment'
                    onChange={this.props.onToggleVoucherPayment}
                    value='voucher'>
                    <FormattedMessage id='payment.payWithYourVoucher' />
                  </PaymentChoiceTypeButton>
                </div>
                <VoucherFields setFocusToField={this.props.setFocusToField} />
              </React.Fragment>
            }

            <div className={cardChoicesClassNames}>
              {this.props.paymentClientToken !== 'dtmf' &&
                <React.Fragment>
                  <PaymentChoiceTypeButton
                    checked={(this.props.paymentChoice === 'card' || !this.props.paymentChoice) && this.props.totalAmountToPay !== 0}
                    disabled={this.props.totalAmountToPay === 0}
                    id='creditCardButton'
                    onChange={this.props.onPaymentChoiceChange}
                    value='card'>
                    <img src={`${assetsUrl}shared/paymentcards_065.png`} alt='Visa logo' height='20' />
                  </PaymentChoiceTypeButton>

                  {this.props.activateApplePay &&
                    <PaymentChoiceTypeButton
                      checked={this.props.paymentChoice === 'applePay'}
                      disabled={this.context.isCallCentre || this.props.totalAmountToPay === 0}
                      id='applePayButton'
                      onChange={this.props.onPaymentChoiceChange}
                      value='applePay'>
                      <img src={`${assetsUrl}shared/apple-pay.png`} alt='Apple Pay logo' height='20' />
                    </PaymentChoiceTypeButton>
                  }

                  {!hasSimplifiedPaymentPage && hasPaypalChoice && <PayPalPaymentChoice />}
                </React.Fragment>
              }

              {this.props.paymentClientToken === 'dtmf' &&
                <React.Fragment>
                  <PaymentChoiceTypeButton
                    checked={this.props.paymentChoice === 'dtmf' || !this.props.paymentChoice}
                    disabled={this.props.totalAmountToPay === 0}
                    id='dtmf'
                    onChange={this.props.onPaymentChoiceChange}
                    value='dtmf'>
                    <FormattedMessage id='payment.DTMF' />
                  </PaymentChoiceTypeButton>

                  <PaymentChoiceTypeButton
                    checked={this.props.paymentChoice === 'skip'}
                    className='text-uppercase'
                    disabled={this.props.totalAmountToPay === 0}
                    id='skip'
                    onChange={this.props.onPaymentChoiceChange}
                    value='skip'>
                    <FormattedMessage id='payment.skipPayment' />
                  </PaymentChoiceTypeButton>

                  {showCxCardFields &&
                    <PaymentChoiceTypeButton
                      checked={this.props.paymentChoice === 'card' || !this.props.paymentChoice}
                      disabled={this.props.totalAmountToPay === 0}
                      id='creditCardButton'
                      onChange={this.props.onPaymentChoiceChange}
                      value='card'>
                      <FormattedMessage id='payment.manualCardCapture' />
                    </PaymentChoiceTypeButton>
                  }
                </React.Fragment>
              }
            </div>
          </div>
        </div>

        {/* Admin Password needs to be entered before showing CX card fields */}
        {showCxCardFields &&
          <div className={cardPasswordFieldClasses}>
            <div className='form-group'>
              <Col sm={3}>
                <ControlLabel className='gutter-top-xs' htmlFor='cxCardPassword'>
                  <FormattedMessage id='common.password' />
                </ControlLabel>
              </Col>
              <Col xs={12} sm={9} className={this.props.errors.adminPasswordError ? 'has-error' : ''}>
                <FormControl
                  id='adminCardPassword'
                  name='admin_card_password'
                  onKeyDown={(e) => this.onCardPasswordSubmit(e)}
                  type='password' />
                {this.props.errors.adminPasswordError &&
                  <HelpBlock className='pull-right'>
                    {this.props.errors.adminPasswordError}
                  </HelpBlock>
                }
              </Col>
            </div>
            <div className='form-group'>
              <Col xs={12} sm={9} smPush={3}>
                <Button
                  id='adminPasswordButton'
                  bsStyle='primary'
                  style={{ 'width': '180px' }}
                >
                  {this.props.isFetchingPassword ? (
                    <SVGLoadingSpinner
                      height='1em'
                      width='1em'
                      viewBox='0 0 50 50'
                      style={{ enableBackground: 'new 0 0 50 50' }}
                      x='0px'
                      y='0px'
                      title='Loading'
                    />
                  ) : (
                    <FormattedMessage id='payment.submitPassword' />
                  )}
                </Button>
              </Col>
            </div>
          </div>
        }

        {this.props.paymentClientToken === 'dtmf' && <DTMFFields />}
        {(this.props.paymentClientToken !== 'dtmf' || showCxCardFields) && <CardFields />}

        {hasSimplifiedPaymentPage && !this.context.isCallCentre &&
          <div className='form-group'>
            <Col lg='8' smOffset='4' className='text-left'>
              <PayPalPaymentChoice />
            </Col>
          </div>
        }
      </div>
    )
  }
}

PaymentChoices.defaultProps = {
  activateApplePay: false,
  activatePaypal: {}
}

PaymentChoices.contextType = BrandContext

PaymentChoices.propTypes = {
  activateApplePay: PropTypes.bool.isRequired,
  activatePaypal: PropTypes.shape({
    mobile: PropTypes.bool,
    largeComputer: PropTypes.bool,
    smallComputer: PropTypes.bool,
    tablet: PropTypes.bool
  }),
  adminPasswordValid: PropTypes.bool.isRequired,
  dtmfCardExpiryDate: PropTypes.string,
  dtmfSecurityCode: PropTypes.string,
  enableVoucherPayment: PropTypes.bool,
  isFetchingPassword: PropTypes.bool,
  onInputChange: PropTypes.func.isRequired,
  onPaymentChoiceChange: PropTypes.func.isRequired,
  paymentChoice: PropTypes.string,
  paymentClientToken: PropTypes.string.isRequired
}

function mapStateToProps (state) {
  const {
    activateApplePay,
    activatePaypal,
    adminPasswordValid,
    billingDetails = {},
    enableVoucherPayment,
    errors,
    isFetchingPassword,
    paymentClientToken
  } = state.payment
  const {
    dtmfCardExpiryDate,
    dtmfSecurityCode,
    paymentChoice
  } = billingDetails
  const totalAmountToPay = getTotalAmountToPay(state)
  return {
    activateApplePay,
    activatePaypal,
    adminPasswordValid,
    dtmfCardExpiryDate,
    dtmfSecurityCode,
    enableVoucherPayment,
    errors,
    isFetchingPassword,
    paymentChoice,
    paymentClientToken,
    totalAmountToPay
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onInputChange: (id, value) => dispatch(payment.inputChange(id, value)),
    onPaymentChoiceChange: value => dispatch(payment.paymentChoiceChange(value)),
    onToggleVoucherPayment: () => dispatch(payment.toggleVoucherPayment())
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(PaymentChoices)
export {
  PaymentChoices
}
