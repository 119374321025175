import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import React, { Component } from 'react'
import { connect } from 'react-redux'

import engineOld from '../../../actions/engineOld'

import config from '../../../configs/config'
const withChildAges = _.get(config, 'brandConfig.secure.withChildAges', false)

class Form extends Component {
  constructor (props) {
    super(props)

    this.getAgesFields = this.getAgesFields.bind(this)
    this.getSeatTypeCode = this.getSeatTypeCode.bind(this)
    this.getTicketBucketCode = this.getTicketBucketCode.bind(this)

    this.autoSubmit = this.autoSubmit.bind(this)
  }

  componentDidMount () {
    this.props.initializeEngine()
  }

  componentDidUpdate (prevProps) {
    // This allows the form to submit when all fields are valid
    this.props.isValidForSubmission && this.searchForm.submit()
  }

  getAgesFields () {
    // Add childAges else use partyComp counts
    const ages = this.props.partyComp.reduce((acc, curr) => {
      if (withChildAges && curr.key === 'children') {
        this.props.childAges.forEach(age => {
          acc.push(age && age.value)
        })
      } else {
        for (let i = 0; i < curr.count; i++) {
          acc.push(curr.age)
        }
      }
      return acc
    }, [])

    return ages.map((age, i) => {
      return <input key={i} type='hidden' name='ages[]' value={age} />
    })
  }

  getSeatTypeCode () {
    if (!this.props.ticketCode) return null
    return this.props.ticketCode.slice(3, 6)
  }

  getTicketBucketCode () {
    if (!this.props.ticketCode) return null
    return this.props.ticketCode.slice(0, 3)
  }

  getTicketRatesCode () {
    if (!this.props.ticketCode) return null
    return this.props.ticketCode.slice(3, 6)
  }

  autoSubmit () {
    // TODO: Ensure this is called on any form change
    if (this.props.submitOnChange) {
      this.props.validate()
    }
  }

  render () {
    if (!this.props.hasEngineStore) return null

    const SeatType = this.getSeatTypeCode()
    const ticketBucket = this.getTicketBucketCode()
    const ticketCode = this.getTicketRatesCode()

    const checkoutDate = moment(this.props.checkInDate).add(this.props.selectedNights, 'days').format('YYYY-MM-DD')
    // This is being done to see whether the engine has a hotel date with nights selection or not
    const checkInDate = this.props.hasHotelDate ? moment(this.props.hotelDate).format('YYYY-MM-DD') : moment(this.props.checkInDate).format('YYYY-MM-DD')

    return (
      <form
        onSubmit={this.props.validate}
        className={classNames({
          'searchform': true,
          'searchform-inline': this.props.inline
        }, this.props.className)}
        action={this.props.action}
        method='get'
        ref={form => { this.searchForm = form }}
      >

        <input name='agent' type='hidden' value={this.props.agent} />
        {this.getAgesFields()}
        <input name='customerCode' type='hidden' value={this.props.customerCode} />

        {/* TODO: This can be removed once the work remove this on server goes live */}
        <input name='include' type='hidden' value='roomRates,ticketRates,hotelProducts,eventProducts,roomProducts,reviews' />

        <input name='roomRates[bucket]' type='hidden' value={this.props.roomBucket} />
        <input name='roomRates[checkinDate]' type='hidden' value={checkInDate} />
        <input name='roomRates[checkoutDate]' type='hidden' value={checkoutDate} />

        {this.props.rooms.map((room, i) => {
          if (!room) return null
          return (
            <div key={`room_${i}`}>
              <input name={`roomRates[rooms][${i}][adults]`} type='hidden' value={room.partyComposition.adults} />
              <input name={`roomRates[rooms][${i}][children]`} type='hidden' value={room.partyComposition.children || 0} />
              <input name={`roomRates[rooms][${i}][infants]`} type='hidden' value={room.partyComposition.infants || 0} />
              <input name={`roomRates[rooms][${i}][occupancyType]`} type='hidden' value={room.occupancyType} />
            </div>
          )
        })}

        {this.props.fieldsToValidate && this.props.fieldsToValidate.tickets &&
          <input name='filter[eventCode]' type='hidden' value={`${ticketBucket}${ticketCode}`} />
        }

        {ticketBucket &&
          <input name='ticketRates[bucket]' type='hidden' value={ticketBucket} />
        }

        {ticketCode &&
          <input name='ticketRates[code]' type='hidden' value={ticketCode} />
        }

        <input name='ticketRates[startDate]' type='hidden' value={moment(this.props.ticketDate).format('YYYY-MM-DD')} />

        {/* This is needed for the hotelProducts include on packageRates call */}
        <input name='context[hotelProducts][checkinDate]' type='hidden' value={checkInDate} />

        {/* Needed to pass on an applied promotion code */}
        {this.props.promotionCode &&
          <input name='promotionCode' type='hidden' value={this.props.promotionCode} />
        }

        {this.props.referrer &&
          <input name='referrer' type='hidden' value={this.props.referrer} />
        }

        {/* Needed for event products endpoint */}
        {SeatType &&
          <input name='SeatType' type='hidden' value={SeatType} />
        }

        {/* Needed for venue products endpoint */}
        <input name='venueCode' type='hidden' value={this.props.venueCode} />

        {/* Needed for MINC (CARE) */}
        <input name='operator' type='hidden' value={this.props.operator} />

        {this.props.children}

        {!this.props.submitOnChange && (
          <div className='searchform-submit'>
            <div className='form-group'>
              <span>&nbsp;</span>
              <button className='btn btn-primary'>{this.props.labelSubmit}</button>
            </div>
          </div>
        )}
      </form>
    )
  }
}

Form.defaultProps = {
  action: 'availability',
  inline: false,
  labelSubmit: 'Search',
  operator: '',
  submitOnChange: false
}

function mapStateToProps (state) {
  const {
    checkInDate,
    checkOutDate,
    childAges,
    hotelDate,
    partyComp,
    rooms,
    selectedNights,
    ticketCode,
    ticketDate
  } = state.engineOld.engineStore

  return {
    action: state.engineOld.action,
    agent: state.engineOld.agent,
    checkInDate,
    checkOutDate,
    childAges,
    customerCode: state.engineOld.customerCode,
    hasEngineStore: state.engineOld.hasEngineStore,
    hotelDate,
    isValidForSubmission: state.engineOld.isValidForSubmission,
    labelSubmit: _.get(state.engineOld.brandConfig, 'UITextDefaults.labelSubmit'),
    messages: state.engineOld.messages,
    selectedNights,
    operator: state.engineOld.operator,
    partyComp,
    promotionCode: state.engineOld.promotionCode,
    referrer: state.engineOld.referrer,
    roomBucket: state.engineOld.roomBucket,
    rooms,
    seatType: state.engineOld.seatType,
    ticketCode,
    ticketDate,
    venueCode: state.engineOld.venueCode
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    onSubmit: () => {
      return dispatch(engineOld.submit())
    },
    initializeEngine: () => {
      return dispatch(engineOld.initialize(ownProps.engineLogicOverrides))
    },

    toggle: (toggleType, forceVisible = false) => {
      return dispatch(engineOld.toggle(toggleType, forceVisible))
    },

    validate: (e) => {
      if (e && e.preventDefault) e.preventDefault()
      if (e && e.stopPropagation) e.stopPropagation()
      const fieldsToValidate = Object.assign({}, {
        checkInDate: true,
        checkOutDate: true,
        childAges: withChildAges,
        hotelDate: true,
        partyComp: true,
        rooms: true,
        ticketDate: true,
        tickets: true
      }, ownProps.fieldsToValidate)
      return dispatch(engineOld.validate(fieldsToValidate))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
