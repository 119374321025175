import _ from 'lodash'
import routeHelpers from './routeHelpers'

const interstitialLogic = {}

interstitialLogic.checkBasketBuilderServiceReply = () => {
  if (!_.get(window, 'basketBuilderServiceReply.basket.id', false)) interstitialLogic.redirectToAvailability()
  else interstitialLogic.redirectToPaymentStage()
}

interstitialLogic.redirectToAvailability = () => {
  window.location = `${window.location.protocol}//${window.location.hostname}/availability${window.location.search}`
}

// sets up our payment page variables. This is locked down to only work with payment page for now/POC
interstitialLogic.redirectToPaymentStage = () => {
  const params = Object.assign({}, window.basketBuilderServiceReply.meta, {
    basketId: _.get(window, 'basketBuilderServiceReply.basket.id'),
    versionId: _.get(window, 'basketBuilderServiceReply.basket.version')
  })
  const {
    agent,
    basketId,
    roomRates,
    tag,
    ticketRates,
    hotelCode,
    venueCode,
    versionId,
    customerCode
  } = params
  const paymentStageParams = {
    SeatType: ticketRates.code,
    agent,
    basketId,
    roomRates: {
      hotelCode: hotelCode,
      checkinDate: roomRates.checkinDate,
      bucket: roomRates.bucket
    },
    tag,
    ticketRates: {
      bucket: ticketRates.bucket,
      ticketCode: ticketRates.bucket + ticketRates.code
    },
    venueCode,
    versionId,
    customerCode
  }

  routeHelpers.navigateTo('payment', paymentStageParams)
}

export default interstitialLogic
