import React, { Component } from 'react'
import classNames from 'classnames'

class SeatLoader extends Component {
  render () {
    const classes = classNames({
      'seat-loader': true,
      'seat-error': this.props.errors && this.props.errors.seatMap
    })
    return (
      <div className={classes}>
        <img alt='' className='loader-logo' src={this.props.logoUrl} role='presentation' />
        <div className='light-loader'>
          <div className='r-light'>
            <div className='spotlight' />
            <div className='shine' />
          </div>
          <div className='l-light'>
            <div className='spotlight' />
            <div className='shine' />
          </div>
        </div>
        <div className='message'>{this.props.message}</div>
      </div>
    )
  }
}

export default SeatLoader
