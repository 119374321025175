import _ from 'lodash'

import resourceHelpers from '../../helpers/resourceHelpers'

const packageRatesHelpers = {}

packageRatesHelpers._sortObjectsByArray = (sortByList, arrayOfObjsToSort, keyToSortBy = 'id') => {
  // Only sort the items in arrayOfObjsToSort based on the order specified in sortByList & any that
  // weren't sorted, append in their original order to the end of the newly sorted array.
  return sortByList.reduce((acc, key) => {
    const found = arrayOfObjsToSort.find(obj => obj[keyToSortBy] === key)
    if (found) acc.push(found)
    return acc
  }, []).concat(arrayOfObjsToSort.filter(obj => sortByList.indexOf(obj[keyToSortBy]) === -1))
}

packageRatesHelpers._buildHotelDataStructure = ({ packageRate, hotelProduct, imagesDomain, hotelImagePath, roomRates } = {}) => {
  if (!packageRate || !packageRate.id || !hotelProduct || !imagesDomain || !hotelImagePath || !roomRates) return {}
  let primaryImage = null
  let hasPath = null
  if (hotelProduct.primary_image) {
    // if using the primary image field need to allow for this having a path or no path in prismic
    hasPath = hotelProduct.primary_image.match(/16-9\//)
    primaryImage = resourceHelpers.generateImagePaths(
      imagesDomain,
      hotelImagePath,
      hotelProduct.primary_image,
      (!hasPath) ? hotelProduct.id + '/' : '', // if no path then build one
      (!hasPath) ? '16-9/' : ''
    )
  } else {
    primaryImage = resourceHelpers.generateImagePaths(
      imagesDomain,
      hotelImagePath,
      hotelProduct.images && hotelProduct.images[0],
      hotelProduct.id + '/',
      '16-9/'
    )
  }

  return {
    packageId: packageRate.id,
    ghostPackage: packageRate.ghostPackage || false,
    ...hotelProduct, // All the content about the hotel
    price: packageRate.grossPrice || 0,
    standardPrice: packageRate.standardPrice || 0,
    roomRates: roomRates,
    images: (hotelProduct.images || []).map(imageName => {
      return resourceHelpers.generateImagePaths(
        imagesDomain,
        hotelImagePath,
        imageName,
        hotelProduct.id,
        '/16-9/'
      )
    }),
    primaryImage,
    roomInformationList: hotelProduct.room_information_list
  }
}

packageRatesHelpers._buildThemeDataStructure = ({ tag, packageRate, eventProduct, roomProduct, imagesDomain, hotelImagePath, rooms } = {}) => {
  if (!tag || !packageRate || !eventProduct || !roomProduct || !imagesDomain || !hotelImagePath) return {}
  return {
    tag,
    name: roomProduct.name || null,
    shortname: roomProduct.shortname || null,
    description: roomProduct.description || null,
    roomBenefits: roomProduct.room_benefits || null,
    layoutDescription: roomProduct.layout_description || null,
    bathroomDescription: roomProduct.bathroom_description || null,
    images: (roomProduct.images || []).map(imageName => {
      return resourceHelpers.generateImagePaths(
        imagesDomain,
        hotelImagePath,
        imageName,
        '',
        tag
      )
    }),
    rooms: rooms || [],
    price: packageRate.grossPrice || 0,
    standardPrice: packageRate.standardPrice || 0,
    primaryImage: resourceHelpers.generateImagePaths(
      imagesDomain,
      hotelImagePath,
      roomProduct.primary_image || (roomProduct.images && roomProduct.images[0]),
      `${tag}/`
    )
  }
}

packageRatesHelpers._buildRoomDataStructure = ({ packageRate, roomProduct, imagesDomain, hotelImagePath, roomRate } = {}) => {
  if (!packageRate || !packageRate.id || !roomProduct || !imagesDomain || !hotelImagePath || !roomRate) return {}
  return {
    packageId: packageRate.id,
    id: roomProduct.id || null,
    name: roomProduct.nameVariant || null,
    shortname: roomProduct.shortname || null,
    description: roomProduct.descriptionVariant || null,
    layoutDescription: roomProduct.layout_descriptionVariant || null,
    bathroomDescription: roomProduct.bathroom_descriptionVariant || null,
    images: (roomProduct.imagesVariant || []).map(imageName => {
      return resourceHelpers.generateImagePaths(
        imagesDomain,
        hotelImagePath,
        imageName,
        roomProduct.id,
        '/4-3/'
      )
    }),
    price: packageRate.grossPrice || 0,
    standardPrice: packageRate.standardPrice || 0,
    roomRate
  }
}

packageRatesHelpers._mergeHotelEventProducts = (packageRatesList, linked) => {
  const hotelEventProducts = _.get(linked, 'hotelEventProducts', null)
  if (!hotelEventProducts) return packageRatesList
  const clonedPackageList = _.cloneDeep(packageRatesList)
  _.forEach(hotelEventProducts, (value, key) => {
    const foundHotel = _.find(clonedPackageList, (obj) => key.slice(0, 6) === obj.id)
    if (foundHotel) {
      foundHotel.hotelEventExclusiveBenefits = value.exclusive_benefits
    }
  })
  return clonedPackageList
}

packageRatesHelpers._buildOnsiteOffsiteList = packageRatesList => {
  const clonedPackageRatesList = _.cloneDeep(packageRatesList)
  return Object.keys(clonedPackageRatesList).reduce((acc, hotelCode) => {
    clonedPackageRatesList[hotelCode].isResort ? acc.resort.push(clonedPackageRatesList[hotelCode]) : acc.offsite.push(clonedPackageRatesList[hotelCode])
    return acc
  }, {
    resort: [],
    offsite: []
  })
}

packageRatesHelpers._mergeAdditionalNights = (packageRatesList = {}, additionalNightList = {}, mapping = {}) => {
  const clonedPackageList = _.cloneDeep(packageRatesList)
  // Attach additional nights replies
  return Object.keys(clonedPackageList).reduce((accumulator, hotelCode) => {
    // Getting the additionalNight code from the mapping & defaulting to
    // the hotelCode if we don't have a mapped equivilent
    const additionalNightCode = mapping[hotelCode] || hotelCode
    // Attach the extra night reply if we have it
    const additionalNight = additionalNightList[additionalNightCode]
    if (additionalNight) {
      clonedPackageList[hotelCode].additionalNight = additionalNight
    }
    // Add the modified package rate to our accumulator
    accumulator[hotelCode] = clonedPackageList[hotelCode]
    return accumulator
  }, {})
}

packageRatesHelpers._convertThemesToArray = packageRatesList => {
  if (!packageRatesList) return []
  const clonedPackageRatesList = _.cloneDeep(packageRatesList)
  return clonedPackageRatesList.map(packageRate => {
    if (packageRate.themes) {
      // Convert our themes into an array for easy sorting and organisation
      packageRate.themes = _.values(packageRate.themes)
    }
    return packageRate
  })
}

packageRatesHelpers._sortThemes = (packageRatesList, roomThemeOrdering = {}) => {
  if (!packageRatesList) return []
  const clonedPackageRatesList = _.cloneDeep(packageRatesList)
  return clonedPackageRatesList.map(packageRate => {
    // themes must be an array for this to work
    if (!packageRate.themes || !packageRate.themes.length) return packageRate
    const ordering = roomThemeOrdering[packageRate.id] || {}
    const themeOrder = ordering.roomThemeOrder
    if (!themeOrder) return packageRate
    // Sort the themes based on config
    packageRate.themes = packageRatesHelpers._sortObjectsByArray(themeOrder, packageRate.themes, 'tag')
    return packageRate
  })
}

packageRatesHelpers._sortThemeRooms = (packageRatesList, roomThemeOrdering = {}) => {
  if (!packageRatesList) return []
  const clonedPackageRatesList = _.cloneDeep(packageRatesList)
  return clonedPackageRatesList.map(packageRate => {
    // themes must be an array for this to work
    if (!packageRate.themes || !packageRate.themes.length) return packageRate
    const ordering = roomThemeOrdering[packageRate.id] || {}
    const roomOrder = ordering.rooms
    if (!roomOrder) return packageRate
    // Sort rooms based on config
    packageRate.themes = packageRate.themes.map(theme => {
      if (!theme.rooms) return theme
      theme.rooms = packageRatesHelpers._sortObjectsByArray(roomOrder, theme.rooms, 'id')
      return theme
    })
    return packageRate
  })
}

packageRatesHelpers._restructurePackageRates = (packageRates = [], linked = {}, brand = {}) => {
  const packageList = {}
  // Make a copy of the package rates to ensure we don't mutate any data
  const clonedPackageRates = _.cloneDeep(packageRates)
  // Set some variables here with defaults to prevent code borking if they are missing.
  const {
    eventProducts = {},
    hotelProducts = {},
    roomProducts = {},
    roomRates = {},
    rooms = {}
  } = linked
  const secure = brand.secure || {}

  // @todo this needs to go into Transformer once all images across all pages have been moved
  // As we are only using this reducer on the moreinformation page (which has the images moved)
  // its fine to hardcode it for now
  secure.hotelImagePath = 'hotel/'
  // Loop over each package rate
  clonedPackageRates.map(packageRate => {
    // Picking id's off the links ready for use shortly
    // In the newer JSONAPI spec, these are not arrays. For now, they are & we always take first entry
    const eventCode = _.get(packageRate, 'links.eventProducts.ids[0]', null)
    const hotelCode = _.get(packageRate, 'links.hotelProducts.ids[0]', null)
    const roomProductId = _.get(packageRate, 'links.roomProducts.ids[0]', null)
    const roomRateId = _.get(packageRate, 'links.roomRates.ids[0]', null)
    // const ticketRateId = _.get(packageRate, 'links.ticketRates.ids[0]', null)

    // ================
    // ==== Hotels ====
    // ================

    if (!hotelCode) return null

    // We want to group each package by hotel code at first... but we may have many packages
    // per hotel code (different rooms) so here, we can either add as a theme or as a hotel.
    if (!packageList[hotelCode]) {
      packageList[hotelCode] = packageRatesHelpers._buildHotelDataStructure({
        packageRate,
        hotelProduct: hotelProducts[hotelCode],
        imagesDomain: secure.imagesDomain,
        hotelImagePath: secure.hotelImagePath,
        roomRates: roomRates[roomRateId]
      })
    }

    // Ensure that we set the price on the parent to the lowest found price in the themes.
    packageList[hotelCode].price = Math.min(packageRate.grossPrice, packageList[hotelCode].price)

    // If we are a resort, group the rooms here.
    if (roomProductId) {
      const tag = ((roomProducts[roomProductId] || {}).tags || [])[0]
      // Only attempt to group by themes if we have a tag
      if (tag) {
        // ================
        // ==== Themes ====
        // ================

        if (!packageList[hotelCode].themes) packageList[hotelCode].themes = {}
        if (!packageList[hotelCode].themes[tag]) {
          packageList[hotelCode].themes[tag] = packageRatesHelpers._buildThemeDataStructure({
            tag,
            packageRate,
            eventProduct: eventProducts[eventCode],
            roomProduct: roomProducts[roomProductId],
            imagesDomain: secure.imagesDomain,
            hotelImagePath: secure.hotelImagePath,
            rooms: rooms[roomRateId]
          })
        }

        // Ensure that we set the prices on the theme to the lowest found prices in the rooms.
        packageList[hotelCode].themes[tag].price = Math.min(packageRate.grossPrice, packageList[hotelCode].themes[tag].price)

        // ===============
        // ==== Rooms ====
        // ===============

        // Add rooms to our theme
        packageList[hotelCode].themes[tag].rooms.push(packageRatesHelpers._buildRoomDataStructure({
          packageRate,
          roomProduct: roomProducts[roomProductId],
          imagesDomain: secure.imagesDomain,
          hotelImagePath: secure.hotelImagePath,
          roomRate: roomRates[roomRateId]
        }))
      }

      // Combine all the room standardPrice values and find the lowest and use it for the basket
      const themes = packageList[hotelCode].themes
      const roomStandardPrices = []
      const roomGrossPrices = []
      for (let themeTag in themes) {
        themes[themeTag].rooms.map(room => {
          roomStandardPrices.push(room.standardPrice)
          roomGrossPrices.push(room.price)
        })
      }
      const lowestGross = Math.min(...roomGrossPrices)
      packageList[hotelCode].standardPrice = roomStandardPrices[roomGrossPrices.indexOf(lowestGross)]
    } else {
      packageList[hotelCode].standardPrice = Math.min(packageRate.standardPrice || 0, packageList[hotelCode].standardPrice || 0)
    }
  })

  return packageList
}

export default packageRatesHelpers
