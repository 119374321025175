import React from 'react'
const generalHelpers = {}

/**
 * Returns a list of HTMLOptionElements
 * @param {Array} options array of options
 * @param {String} uniqueID a name to append to the <option> key to make them unique
 * @param {String} initialOption the initially selected option
 * @return {Array} of HTMLOptionElements
 */
generalHelpers.getOptions = function (options, uniqueID = '', initialOption = '') {
  if (!Array.isArray(options)) return null

  // Create a local copy so we don't accidentally modify the passed in array
  options = [].concat(options)

  // Add an initial option if nothing is selected
  if (initialOption !== '') {
    options.unshift({
      value: '',
      text: initialOption
    })
  }

  const optionElements = []
  options.forEach((option, index) => {
    // Convert if we have passed in an array of numbers or strings instead of objects
    if (typeof option !== 'object') {
      option = {
        text: option,
        value: option
      }
    }

    // Check we have something to display.
    // .value can be an empty string (for initialOption)
    if (!option.text || !option.hasOwnProperty('value')) {
      return
    }

    optionElements.push((
      <option key={`${uniqueID}Option${index}`} value={option.value}>
        {option.text}
      </option>
    ))
  })

  return optionElements
}

/**
 * Sends message to Gabble to pause call
 * @param {String} eventType eventType this pauseCallRecording is coming from
 * @return {void} undefined
 */
generalHelpers.pauseCallRecording = function (eventType) {
  if (window.parent && window.parent.postMessage && eventType) {
    window.parent.postMessage(`pauseCallRecording,${eventType}`, '*')
  }
}

/**
 * Scrolls the given list of containers to the given value
 * @param {String} classString string for class list
 * @param {Number} topValue value to scroll to
 * @param {Number} timeout timeout value for scroll action
 * @return {void} We don't return anything, just scroll
 */
generalHelpers.scrollElementsWithClass = function (classString, topValue, timeout = 0) {
  const elementsToScroll = document.getElementsByClassName(classString);
  [].forEach.call(elementsToScroll, function (item) {
    setTimeout(() => {
      item.scrollTop = topValue
    }, timeout)
  })
}

/**
  * Scrolls the given element into view
  * @param {String} selector element to select
  * @param {Number} duration how long does the scrolling need to take
  * @param {Number} offset how far from the element do we want to scroll
  * @return {void} nothing to return
  */
generalHelpers.scrollElementIntoView = (selector, duration = 1500, offset = 0) => {
  if (!selector) return

  let element = document.querySelector(selector)
  if (!element) return

  let startingY = window.pageYOffset
  // Get the elements offset relative to the viewport and add what the current scroll position is
  // Math.floor to prevent rescroll on IE
  let elementOffsetTop = Math.floor(element.getBoundingClientRect().top) + startingY
  let distance = (elementOffsetTop - startingY) - offset

  // No distance, nowhere to go, or no requestAnimationFrame
  if (distance === 0 || !window.requestAnimationFrame) return

  let start = null
  const step = timestamp => {
    if (!start) start = timestamp
    const time = timestamp - start
    const percent = Math.min(time / duration, 1)
    window.scrollTo(0, startingY + distance * percent)
    if (time < duration) {
      window.requestAnimationFrame(step)
    } else {
      // Recalculate the distance
      element = document.querySelector(selector)
      startingY = window.pageYOffset
      elementOffsetTop = Math.floor(element.getBoundingClientRect().top) + startingY
      const newDistance = (elementOffsetTop - startingY) - offset

      // Are we there yet? If not, continue scrolling
      if (newDistance !== 0 && distance !== newDistance) {
        distance = newDistance
        window.requestAnimationFrame(step)
      }
    }
  }
  window.requestAnimationFrame(step)
}

/**
 * Replace the prismic hash with a hash sign
 * * @param {String} html html from prismic
 * * @return {String} returns the html with the replaced hash
 */
generalHelpers.parsePrismicHash = (html) => {
  if (!html || typeof html !== 'string') return html
  return html.replace(/href="http:\/\/#([^"]*)"/g, (match, key) => `onclick="showContentModal('${key}')"`)
}

/**
 * Clone the string, and convert the em tags to small tags
 * this is due to prismic's WYSIWYG not having small text capabilities
 * * @param {String} text text from prismic
 * * @return {String} returns the html with small tags instead of em tags
 */
generalHelpers.italicToSmall = (text) => {
  if (!text || typeof text !== 'string') {
    return ''
  }
  return text.replace(/<em>/g, '<small>').replace(/<\/em>/g, '</small>')
}

/**
 * Return a youtube thumbnail image based off videoID
 * Quality options (default, hqdefault, mqdefault, sddefault, maxresdefault)
 * * @param {String} videoID for generating the youtube thumbnail
 * * @param {String} quality quality of the youtube thumbnail image
 */
generalHelpers.generateYoutubeThumbnail = (videoID, quality = 'maxresdefault') => {
  return `https://img.youtube.com/vi/${videoID}/${quality}.jpg`
}

/**
 * Utility helpers for setting and fetching sessionStorage
 * * @param {String} key key name for sessionStorage
 * * @param {String} value you can set any value here, objects are best JSON.stringified before storing.
 */
generalHelpers.setSessionStorage = (key, value) => {
  if (window && window.sessionStorage) window.sessionStorage.setItem(key, value)
}

// set key/value pairs into session storage
generalHelpers.setSessionStorageObject = (obj) => {
  Object.keys(obj).forEach((key) => {
    generalHelpers.setSessionStorage(key, obj[key])
  })
}

// get sessionStorage, if item is a object string, JSON.parse the function at source.
generalHelpers.getSessionStorage = (key) => {
  if (window && window.sessionStorage) return window.sessionStorage.getItem(key) || ''
}

export default generalHelpers
