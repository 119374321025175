const configuration = (state = {
  roomThemeOrdering: {}
}, action) => {
  switch (action.type) {
    case 'RECEIVED_ROOM_THEME_ORDERING':
      return Object.assign({}, state, {
        roomThemeOrdering: action.roomThemeOrdering
      })
    default:
      return state
  }
}

export default configuration
