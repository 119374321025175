import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGPlay extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <g>
          <path d='M0 0h24v24H0z' fill='none' />
          <path d='M12 2C6.48 2 2 6.48 2 12s4.48 10 10 10 10-4.48 10-10S17.52 2 12 2zm-2 14.5v-9l6 4.5-6 4.5z' />
        </g>
      </SVG>
    )
  }
}

SVGPlay.propTypes = SVG.propTypes

SVGPlay.defaultProps = {
  title: 'Play Icon'
}

export default SVGPlay
