import _ from 'lodash'
import config from '../configs/config'
import { isDiscoveryPrimed } from '../helpers/discoveryHelpers'

const {
  brandConfig,
  packageRatesReply
} = config

const discovery = {
  checkSearchIsDiscoveryPrimed: () => {
    return dispatch => {
      const dealFinderEnabled = _.get(brandConfig, 'secure.hasFeatures.availability.hasPriceFinder', false)
      const dealFinderConfig = _.get(brandConfig, 'secure.dealFinder', {})
      const { rooms = [], checkinDate, checkoutDate } = _.get(packageRatesReply, 'meta.context.roomRates', {})
      const partyComposition = rooms.reduce((result, room) => {
        result.adultsAges = [...result.adultsAges, ...room.adultsAges]
        result.childrenAges = [...result.childrenAges, ...room.childrenAges]
        result.infantsAges = [...result.infantsAges, ...room.infantsAges]
        result.adults += room.adults
        result.children += room.children
        result.infants += room.infants
        return result
      }, { adults: 0, children: 0, infants: 0, babies: 0, adultsAges: [], childrenAges: [], infantsAges: [] })

      if (dealFinderEnabled && isDiscoveryPrimed(dealFinderConfig, partyComposition, checkinDate, checkoutDate)) {
        return dispatch({
          partyComposition,
          primed: true,
          type: 'DISCOVERY_PRIMED'
        })
      }
      return dispatch({
        partyComposition,
        primed: false,
        type: 'DISCOVERY_PRIMED'
      })
    }
  }
}

export default discovery
