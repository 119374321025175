import classNames from 'classnames'
import React, { useCallback, useEffect, useMemo } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'

import SVGSpyglass from './SVGSpyglass'

import tracking from '../../actions/tracking'

const PriceFinderButton = ({
  ghostPackage = false,
  hasParkEntry = false,
  hotel = {}
}) => {
  const dispatch = useDispatch()
  const openDiscoveryModal = useCallback((hasParkEntry, hotelId) => {
    dispatch({
      modalName: 'discoveryCalendar',
      type: 'MODAL_TOGGLE'
    })

    dispatch({
      hasParkEntry,
      hotelId,
      type: 'DISCOVERY_CALENDAR'
    })
  }, [dispatch])
  const track = useCallback((action, category, label) => {
    dispatch(tracking.track(action, category, label))
  }, [])

  useEffect(() => {
    track('Deal Finder button shown', 'availability', hotel.id)
  }, [hotel.id])

  const priceFinderClassNames = useMemo(() => {
    return classNames('btn btn-block', {
      'btn-price-finder': !ghostPackage,
      'btn-primary': ghostPackage
    })
  }, [ghostPackage])

  return (
    <div>
      <button
        aria-label={`Pricing for alternative dates${hotel ? ` at ${hotel.name}` : ''}`}
        className={priceFinderClassNames}
        onClick={() => {
          track('open', 'discovery-modal', hotel ? hotel.id : 'All hotels')
          openDiscoveryModal(hasParkEntry, hotel.id)
        }}
        onKeyDown={(e) => {
          if (e.key !== 'Enter') return
          track('open', 'discovery-modal', hotel ? hotel.id : 'All hotels')
          openDiscoveryModal(hasParkEntry, hotel.id)
        }}
      >
        {!ghostPackage &&
          <React.Fragment>
            <span className='pr-2 hidden-xs'>
              <SVGSpyglass width='22' height='22' />
            </span>
            <FormattedMessage id='common.dealFinder'>
              {txt => <span className='d-inline d-sm-table-cell'>{txt}</span>}
            </FormattedMessage>
          </React.Fragment>
        }
        {ghostPackage && <FormattedMessage id='common.seeOtherDates' />}
      </button>
    </div>
  )
}

export default PriceFinderButton
