import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import {
  Alert,
  Button,
  Checkbox,
  Col,
  ControlLabel,
  Form,
  FormControl,
  FormGroup,
  HelpBlock,
  Modal,
  Row
} from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import BasketTotal from '../atoms/BasketTotal'
import BasketTotalWithSurcharge from '../atoms/BasketTotalWithSurcharge'
import DisplayPrice from '../atoms/DisplayPrice'
import MailCheck, { domains, topLevelDomains, secondLevelDomains } from '../atoms/MailCheck'

import NameInput from '../molecules/NameInput'
import PaymentChoices from '../../containers/PaymentChoices'
import PaymentFormRooms from '../molecules/PaymentFormRooms'

import AddressLookup from '../../containers/AddressLookup'

import config from '../../configs/config'

import trackingHelpers from '../../helpers/trackingHelpers'

/**
 * PaymentForm - The actual component
 *
 * @return {JSX} the rendered component
 */
class PaymentForm extends Component {
  constructor (props) {
    super(props)

    this.references = {
      email: null
    }

    this.setFocusToField = this.setFocusToField.bind(this)
  }

  setFocusToField (field) {
    if (!field || !this.references[field]) return

    const _field = this.references[field]
    _field && _field.focus()
  }

  shouldComponentUpdate (nextProps) {
    return !_.isEqual(nextProps, this.props)
  }

  render () {
    const hasEnhancedTotalsSubtext = _.get(config, 'brandConfig.secure.hasFeatures.payment.hasEnhancedTotalsSubtext', false)
    const hasSimplifiedPaymentPage = _.get(config, 'brandConfig.secure.hasFeatures.payment.hasSimplifiedPaymentPage', false)
    const hasEmailSuggestion = _.get(config, 'brandConfig.secure.hasFeatures.payment.hasEmailSuggestion', false)
    const hasBoldCancellationProtection = _.get(config, 'brandConfig.secure.hasFeatures.payment.hasBoldCancellationProtection', false)

    const { hasBreakfastIncluded, hasTicketsIncluded, whatsIncludedinSwappableTicket } = this.props
    let totalSectionSubText
    if (hasEnhancedTotalsSubtext && (whatsIncludedinSwappableTicket || hasTicketsIncluded || hasBreakfastIncluded)) {
      totalSectionSubText = 'including '
      if (whatsIncludedinSwappableTicket) {
        totalSectionSubText += whatsIncludedinSwappableTicket.toLowerCase() + ' and '
      }
      if (hasTicketsIncluded && !whatsIncludedinSwappableTicket) {
        totalSectionSubText += 'tickets and '
      }

      totalSectionSubText += 'hotel'
      if (hasBreakfastIncluded) {
        totalSectionSubText += ' with breakfast'
      }
    }

    // Define some column classes for the BasketTotal component
    const basketTotalWithSurchargeColClasses = {
      left: {
        md: 3,
        mdOffset: 3,
        xs: 7
      },
      right: {
        md: 3,
        xs: 5
      }
    }

    const handleEmailMessage = () => {
      if (this.props.hasPreferenceCentre) {
        return (
          <Col sm={8} smOffset={3}>
            <HelpBlock className='small'>
              <FormattedMessage id='payment.emailMessage' />
            </HelpBlock>
          </Col>
        )
      }
      return (
        <Col sm={8} smOffset={3}>
          <HelpBlock className='small'>
            <FormattedMessage id='payment.emailMessageCompact' />
          </HelpBlock>
        </Col>
      )
    }

    return (
      <Form
        {...trackingHelpers.getAttributes('payment details', 'forms', 'Submit')}
        horizontal
        id='paymentForm'
        method='POST'>

        <fieldset>
          {/* Email */}

          {/* EmailLabel */}
          <FormGroup controlId='billToEmail' validationState={this.props.errors.billToEmail ? 'error' : null}>
            <Col componentClass={ControlLabel} sm={4}>
              <FormattedMessage id='payment.emailConfirmationTo' data-automated-test='paymentEmail' />
            </Col>
            <Col sm={6} md={6}>
              {hasEmailSuggestion ? (
                <React.Fragment>
                  <FormControl
                    {...trackingHelpers.getAttributes('email', 'payment', '1')}
                    name='bill_to_email'
                    onBlur={() => this.props.onRemoveAllVouchers()}
                    onChange={(e) => { this.props.onInputChange(e.target.id, e.target.value) }}
                    type='email'
                    inputMode='email'
                    inputRef={ref => { this.references.email = ref }}
                    value={this.props.billingDetails.billToEmail}
                  />
                  <p>
                    <MailCheck
                      email={this.props.billingDetails.billToEmail}
                      domains={domains}
                      topLevelDomains={topLevelDomains}
                      secondLevelDomains={secondLevelDomains}
                    >
                      {suggestion => suggestion && suggestion !== this.props.billingDetails.billToEmail && (
                        <span>
                          Did you mean
                          <a
                            {...trackingHelpers.getAttributes('click', 'payment', 'email suggestion')}
                            className='ml-1'
                            name='email-suggestion'
                            value={suggestion.domain}
                            onClick={(e) => { this.props.onInputChange('billToEmail', suggestion.full) }}
                          >
                            {suggestion.full}
                          </a>?
                        </span>
                      )}
                    </MailCheck>
                  </p>
                </React.Fragment>
              ) : (
                <FormControl
                  {...trackingHelpers.getAttributes('email', 'payment', '1')}
                  name='bill_to_email'
                  onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                  type='email'
                  inputMode='email'
                  value={this.props.billingDetails.billToEmail}
                />
              )}
              {this.props.errors.billToEmail &&
                <HelpBlock className='pull-right'>
                  {this.props.errors.billToEmail}
                </HelpBlock>
              }
            </Col>
            {/* Email Message */}
            {handleEmailMessage()}
          </FormGroup>

          {!this.props.hasPaymentFormContactName &&
            <NameInput
              billToForename={this.props.billingDetails.billToForename}
              billToSurname={this.props.billingDetails.billToSurname}
              billToTitle={this.props.billingDetails.billToTitle}
              errors={this.props.errors}
              onInputChange={this.props.onInputChange}
            />
          }

          <AddressLookup />

          {this.props.hasAnnualPassField &&
            <FormGroup controlId='annualPassNo' validationState={this.props.errors.annualPassNo ? 'error' : null}>
              {/* pt-0 class can be removed if the hardcoded strings for annual pass text below are changed to be shorter */}
              <Col componentClass={ControlLabel} sm={hasSimplifiedPaymentPage ? 4 : 3} className='pt-0'>
                <span data-automated-test='paymentPassNumber'>{this.props.isJustAnnualPassLabel ? 'Annual Pass Number' : 'Please enter your Pass Number'}</span>
              </Col>

              <Col sm={8} md={6}>
                <FormControl
                  name='annualPassNo'
                  onChange={(e) => {
                    this.props.onInputChange(e.target.id, e.target.value)
                    this.props.onChangeMerlinAnnualPass(e.target.value)
                  }}
                  placeholder={this.props.isJustAnnualPassLabel ? 'Annual Pass Number' : 'Pass Number'}
                  size='20'
                  maxLength='18'
                  type='text'
                  value={this.props.billingDetails.annualPassNo} />

                {this.props.errors.annualPassNo &&
                  <HelpBlock className='pull-right'>
                    {this.props.errors.annualPassNo}
                  </HelpBlock>
                }

                <Alert bsStyle='info'>
                  <FormattedMessage id='payment.annualPassReminder' />
                </Alert>

              </Col>
            </FormGroup>
          }

          {this.props.isCallCentre &&
            <FormGroup controlId='remarks'>
              <Col componentClass={ControlLabel} sm={hasSimplifiedPaymentPage ? 4 : 3}>
                <FormattedMessage id='payment.remarks' data-automated-test='paymentRemarks' />
              </Col>

              <Col sm={8} md={6}>
                <FormControl
                  name='Remarks'
                  onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                  placeholder='Remarks'
                  size='40'
                  type='text'
                  value={this.props.billingDetails.remarks} />
              </Col>
            </FormGroup>
          }
        </fieldset>

        {this.props.hasPaymentFormContactName &&
          <fieldset>
            {!hasSimplifiedPaymentPage &&
              <FormattedMessage id='common.ticketInformation' tagName='legend' />
            }
            <p className='block-xs'>
              <FormattedMessage id='payment.noteTicketHolder' />
            </p>
            <NameInput
              billingDetails={this.props.billingDetails}
              errors={this.props.errors}
              onInputChange={this.props.onInputChange}
            />
          </fieldset>
        }

        {(!!this.props.rooms.find(room => (room.agesToValidate || []).length > 0) || this.props.hasLeadName) && (
          <PaymentFormRooms
            billingDetails={this.props.billingDetails}
            errors={this.props.errors}
            hasLeadName={this.props.hasLeadName}
            maxChildAge={this.props.maxChildAge}
            minChildAge={this.props.minChildAge}
            onInputChange={this.props.onInputChange}
            rooms={this.props.rooms}
            setLeadRoomDetails={this.props.setLeadRoomDetails}
          />
        )}

        {(this.props.cancellationWaiver.grossPrice || (this.props.postalConfirmation.grossPrice && !whatsIncludedinSwappableTicket)) && (
          <fieldset>
            {!hasBoldCancellationProtection && this.props.cancellationWaiver.grossPrice &&
              <FormGroup className='row'>
                <Col xs={12} sm={hasSimplifiedPaymentPage ? 9 : 10} smOffset={hasSimplifiedPaymentPage ? 2 : 1} className='bg-muted block-sm'>
                  <Row>
                    <Col sm={12} className='clearfix'>
                      <p className={'text-success large ' + (this.props.billingDetails.hasCancellationWaiver ? null : 'hidden')}>
                        &#x2714; <FormattedMessage id='payment.cancellationProtected' />
                      </p>
                      <p className={'text-warning large ' + (this.props.billingDetails.hasCancellationWaiver ? 'hidden' : null)}>
                        &#x2716; <FormattedMessage id='payment.cancellationProtect' />
                      </p>
                      <p className='small'>
                        {this.props.cancellationWaiver.bookingProtectedMessage}
                        <button type='button' className='btn-link p-0'
                          {...trackingHelpers.getAttributes('cancellationWaiver Info', 'open', '1')}
                          onClick={() => this.props.onToggleModal('cancellationWaiver')}
                          onKeyDown={(e) => e.key === 'Enter' && this.props.onToggleModal('cancellationWaiver')}>
                          <FormattedMessage id='payment.moreDetailsCancellationPolicy' />
                        </button>
                      </p>
                      <Checkbox
                        checked={this.props.billingDetails.hasCancellationWaiver}
                        {...trackingHelpers.getAttributes(!this.props.billingDetails.hasCancellationWaiver ? 'Added' : 'Removed', 'payment', 'cancellationWaiver')}
                        id='hasCancellationWaiver'
                        name='cancellationWaiver'
                        onChange={(e) => { this.props.onToggleUpgrade(e.target.id, e.target.checked, this.props.cancellationWaiver) }}>
                        <span htmlFor='hasCancellationWaiver'>
                          <strong>Add <FormattedMessage id='common.cancellationWaiver' /> for <DisplayPrice price={this.props.cancellationWaiver.grossPrice} /></strong>
                        </span>
                      </Checkbox>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            }

            {hasBoldCancellationProtection && this.props.cancellationWaiver.grossPrice &&
              <FormGroup className='row'>
                <Col xs={12} sm={hasSimplifiedPaymentPage ? 9 : 10} smOffset={hasSimplifiedPaymentPage ? 2 : 1} className='canx-waiver block-sm'>
                  <Row>
                    <Col sm={12} className='clearfix'>
                      <FormattedMessage id='payment.cancellationProtection'>{txt => <div className='h4 my-1'>{txt}</div>}</FormattedMessage>
                      {this.props.cancellationWaiver.bookingProtectedMessage}
                      <Checkbox
                        checked={this.props.billingDetails.hasCancellationWaiver}
                        {...trackingHelpers.getAttributes(!this.props.billingDetails.hasCancellationWaiver ? 'Added' : 'Removed', 'payment', 'cancellationWaiver')}
                        id='hasCancellationWaiver'
                        name='cancellationWaiver'
                        onChange={(e) => { this.props.onToggleUpgrade(e.target.id, e.target.checked, this.props.cancellationWaiver) }}>
                        <span htmlFor='hasCancellationWaiver'>
                          <strong><FormattedMessage id='payment.protectMyBookingFor' /> <DisplayPrice price={this.props.cancellationWaiver.grossPrice} /></strong>
                        </span>
                      </Checkbox>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            }

            {this.props.postalConfirmation.grossPrice && !whatsIncludedinSwappableTicket &&
              <FormGroup className='row'>
                <Col xs={12} sm={hasSimplifiedPaymentPage ? 9 : 10} smOffset={hasSimplifiedPaymentPage ? 2 : 1}>
                  <Row>
                    <Col xs={12}>
                      <Checkbox
                        checked={this.props.billingDetails.hasPostalConfirmation}
                        className='pull-left'
                        {...trackingHelpers.getAttributes('postalConfirmation Info', 'payment', '1')}
                        id='hasPostalConfirmation'
                        name='postalConfirmation'
                        onChange={(e) => this.props.onToggleUpgrade(e.target.id, e.target.checked, this.props.postalConfirmation)}
                        title='hasPostalConfirmation'>
                        <span>
                          <FormattedMessage id='payment.addTicketPostageFor' values={{ grossPrice: this.props.postalConfirmation.grossPrice }} tagName='strong' />
                        </span>
                      </Checkbox>
                      <small className='pull-left'>
                        <FormattedMessage id='payment.dontHaveAprinter' />&nbsp;
                        <a
                          onClick={() => this.props.onToggleModal('postalConfirmationInfo')}
                          onKeyDown={(e) => e.key === 'Enter' && this.props.onToggleModal('postalConfirmationInfo')}
                          role='button'>
                          <FormattedMessage id='payment.moreDetailsPostalConfirmation' />
                        </a>
                      </small>
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            }
          </fieldset>
        )}
        <fieldset>
          <PaymentChoices setFocusToField={this.setFocusToField} />

          {this.props.removeCreditCardSurcharge &&
            <div>
              <BasketTotal
                grossPrice={this.props.grossPrice}
                totalSectionSubText={totalSectionSubText} />
              <Row>
                <Col sm={6} smOffset={hasSimplifiedPaymentPage ? 4 : 3}>
                  <Button
                    block
                    bsStyle='primary'
                    bsSize='large'
                    className='btn-accent addToBasket raquo'
                    {...trackingHelpers.getAttributes('proceed to confirm', 'payment', '1')}
                    id='submitButton'
                    type='submit'>
                    {this.props.submitButtonLabel}
                  </Button>

                  <HelpBlock>
                    {this.props.hasPtr && this.props.ptrLegislationModal &&
                      <React.Fragment>
                        <FormattedMessage id='payment.ptrTextMessage' />&nbsp;
                        <a
                          className='hoverable'
                          {...trackingHelpers.getAttributes('open', 'payment', 'ptr legislation modal')}
                          onClick={() => this.props.onToggleModal('ptr_legislation')}
                          onKeyDown={(e) => e.key === 'Enter' && this.props.onToggleModal('ptr_legislation')}>
                          <FormattedMessage id='common.packageTravelRegulations' />
                        </a>.&nbsp;
                      </React.Fragment>
                    }

                    {this.props.termsAndConditions &&
                      <React.Fragment>
                        <FormattedMessage id='payment.termsAndConditionsMessage' />&nbsp;
                        <a
                          className='hoverable'
                          {...trackingHelpers.getAttributes('open', 'payment', 'terms and conditions modal')}
                          onClick={() => this.props.onToggleModal('termsAndConditions')}
                          onKeyDown={(e) => e.key === 'Enter' && this.props.onToggleModal('termsAndConditions')}>
                          <FormattedMessage id='common.termsAndConditions' />
                        </a>
                      </React.Fragment>
                    }
                    {this.props.privacyPolicyUrl ? (
                      <React.Fragment>
                        &nbsp;<FormattedMessage id='common.and' />&nbsp;
                        <a
                          className='hoverable'
                          href={this.props.privacyPolicyUrl}
                          target='_blank'
                          rel='noopener'
                          {...trackingHelpers.getAttributes('open', 'payment', 'privacy policy')}>
                          <FormattedMessage id='common.privacyPolicy' />
                        </a>
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <FormattedMessage id='common.and' />&nbsp;
                        <a
                          className='hoverable'
                          {...trackingHelpers.getAttributes('open', 'payment', 'privacy policy modal')}
                          onClick={() => this.props.onToggleModal('privacy_policy')}
                          onKeyDown={(e) => e.key === 'Enter' && this.props.onToggleModal('privacy_policy')}>
                          <FormattedMessage id='common.privacyPolicy' />
                        </a>
                      </React.Fragment>
                    )
                    }
                    {!this.props.cancellationWaiver.id &&
                      <span>, which includes details of our Cancellation and Amendment Policy</span>
                    }
                    .
                  </HelpBlock>
                </Col>
              </Row>
            </div>
          }
        </fieldset>

        {!this.props.removeCreditCardSurcharge &&
          <fieldset>
            {!hasSimplifiedPaymentPage &&
              <legend>
                <FormattedMessage id='common.total' />&nbsp;
                {totalSectionSubText &&
                  <small>
                    {totalSectionSubText}
                  </small>
                }
              </legend>
            }

            {this.props.grossPrice && this.props.creditCardSurcharge &&
              <BasketTotalWithSurcharge
                colClasses={basketTotalWithSurchargeColClasses}
                creditCardSurcharge={this.props.creditCardSurcharge}
                grossPrice={this.props.grossPrice}
              />
            }

            <Row>
              <Col md={6} mdOffset={4}>
                <Button
                  block
                  bsStyle='primary'
                  bsSize='large'
                  className='btn-accent addToBasket raquo'
                  {...trackingHelpers.getAttributes('proceed to confirm', 'payment', '1')}
                  id='submitButton'
                  type='submit'>
                  {this.props.submitButtonLabel}
                </Button>

                <p className='block-xs pull-left text-center'>
                  On making payment you agree to our booking&nbsp;
                  <a
                    className='hoverable'
                    {...trackingHelpers.getAttributes('open', 'payment', 'terms and conditions modal')}
                    onClick={() => this.props.onToggleModal('termsAndConditions')}
                    onKeyDown={(e) => e.key === 'Enter' && this.props.onToggleModal('termsAndConditions')}>
                    <FormattedMessage id='common.termsAndConditions' />
                  </a>
                </p>
              </Col>
            </Row>
          </fieldset>
        }

        {this.props.visibleModals.cancellationWaiver && this.props.cancellationWaiver &&
          <Modal
            onHide={this.props.onToggleModal.bind(this, 'cancellationWaiver')}
            show>
            <Modal.Header closeButton>
              <Modal.Title>
                {hasBoldCancellationProtection
                  ? <FormattedMessage id='payment.cancellationProtection' />
                  : <FormattedMessage id='common.cancellationWaiver' />}
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: this.props.cancellationWaiver.description }} />
            </Modal.Body>
          </Modal>
        }

        {this.props.visibleModals.postalConfirmationInfo && this.props.postalConfirmation &&
          <Modal
            onHide={this.props.onToggleModal.bind(this, 'postalConfirmationInfo')}
            show>
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id='common.postalConfirmation' />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: this.props.postalConfirmation.description }} />
            </Modal.Body>
          </Modal>
        }

        {this.props.visibleModals.termsAndConditions && this.props.termsAndConditions &&
          <Modal
            onHide={this.props.onToggleModal.bind(this, 'termsAndConditions')}
            show>
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id='common.termsAndConditions' />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: this.props.termsAndConditions }} />
            </Modal.Body>
          </Modal>
        }

        {this.props.visibleModals.privacy_policy && this.props.privacyPolicyModal &&
          <Modal
            onHide={this.props.onToggleModal.bind(this, 'privacy_policy')}
            show>
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id='common.privacyPolicy' />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: this.props.privacyPolicyModal }} />
            </Modal.Body>
          </Modal>
        }

        {this.props.visibleModals.ptr_legislation && this.props.ptrLegislationModal &&
          <Modal
            onHide={this.props.onToggleModal.bind(this, 'ptr_legislation')}
            show>
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id='common.packageTravelRegulations' />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: this.props.ptrLegislationModal }} />
            </Modal.Body>
          </Modal>
        }

        {this.props.visibleModals.modal3DSecure && this.props.frame3DSecure &&
          <Modal
            className='text-center'
            onHide={this.props.onToggleModal.bind(this, 'modal3DSecure')}
            show>
            <Modal.Header closeButton>
              <Modal.Title>
                <FormattedMessage id='common.3dSecure' />
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <div dangerouslySetInnerHTML={{ __html: this.props.frame3DSecure }} />
            </Modal.Body>
          </Modal>
        }
      </Form>
    )
  }
}

PaymentForm.defaultProps = {
  hasAnnualPassField: false,
  hasPreferenceCentre: false,
  isCallCentre: false,
  rooms: [],
  submitButtonLabel: 'Make my booking',
  isJustAnnualPassLabel: false
}

PaymentForm.propTypes = {
  billingDetails: PropTypes.shape({
    annualPassNo: PropTypes.string,
    applyCreditFees: PropTypes.bool,
    billToEmail: PropTypes.string,
    hasCancellationWaiver: PropTypes.bool,
    hasPostalConfirmation: PropTypes.bool,
    paymentChoice: PropTypes.string,
    remarks: PropTypes.string,
    providerEnvironment: PropTypes.object
  }),
  cancellationWaiver: PropTypes.shape({
    description: PropTypes.string,
    grossPrice: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  creditCardSurcharge: PropTypes.object.isRequired,
  errors: PropTypes.object,
  frame3DSecure: PropTypes.node,
  grossPrice: PropTypes.number.isRequired,
  hasAnnualPassField: PropTypes.bool.isRequired,
  hasBreakfastIncluded: PropTypes.bool,
  hasPaymentFormContactName: PropTypes.bool,
  hasPreferenceCentre: PropTypes.bool,
  hasTicketsIncluded: PropTypes.bool,
  isCallCentre: PropTypes.bool.isRequired,
  isJustAnnualPassLabel: PropTypes.bool.isRequired,
  maxChildAge: PropTypes.number.isRequired,
  minChildAge: PropTypes.number.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  onToggleUpgrade: PropTypes.func.isRequired,
  postalConfirmation: PropTypes.shape({
    description: PropTypes.string,
    grossPrice: PropTypes.string,
    id: PropTypes.string
  }).isRequired,
  privacyPolicyModal: PropTypes.string,
  privacyPolicyUrl: PropTypes.string,
  ptrLegislationModal: PropTypes.string,
  removeCreditCardSurcharge: PropTypes.bool,
  rooms: PropTypes.array.isRequired,
  submitButtonLabel: PropTypes.string.isRequired,
  termsAndConditions: PropTypes.string,
  visibleModals: PropTypes.shape({
    cancellationWaiver: PropTypes.bool,
    modal3DSecure: PropTypes.bool,
    postalConfirmationInfo: PropTypes.bool,
    termsAndConditions: PropTypes.bool
  }).isRequired
}

export default PaymentForm
