import React from 'react'
import SummaryWrapper from '../../hoc/SummaryWrapper'
import { Col, Collapse, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

const FixedSearchSummary = ({
  checkinDate,
  checkoutDate,
  collapsed,
  handleSummaryCollapseToggle,
  hasParkEntry,
  hasTicketDateCalendar,
  hasTicketDescriptions,
  hasYourStay,
  labelParkDate,
  nights,
  onChange,
  parkDate,
  roomDescriptions,
  summaryTitleCollapsible,
  summaryTitleNonCollapsible,
  ticketDescriptions,
  ticketDuration,
  trackValues
}) => (
  <React.Fragment>
    <div className='summary-panel container block-sm'>
      <Row>
        <Col md={2} sm={12}>
          <h3 className={summaryTitleNonCollapsible}>
            <FormattedMessage id='common.summary' />
          </h3>
          <a
            className={summaryTitleCollapsible}
            onClick={handleSummaryCollapseToggle}
            onKeyDown={(e) => e.key === 'Enter' && handleSummaryCollapseToggle}>
            <h3 className='h3 mt-0'>
              <FormattedMessage id='common.summary' /> <span className='i-plus pull-right collapse-icon'>+</span>
            </h3>
          </a>
        </Col>

        <Collapse in={!collapsed}>
          <div className='summary-content clearfix collapse-non-sm collapse-non-md collapse-non-lg'>
            {hasYourStay &&
              <Col
                md={2}
                xs={6}
                role='presentation'
                tabIndex='0'>
                <Row
                  onClick={() => trackValues('yourStay', 'click')}
                  onKeyDown={(e) => e.key === 'Enter' && trackValues('yourStay', 'keydown')}
                  tabIndex='0'>
                  <Col lg={12} md={12}>
                    <small className='text-muted'>
                      <FormattedMessage id='common.yourStay' />
                    </small>
                  </Col>
                  <Col lg={12} md={12}>
                    <p className='larger m-0'>
                      <FormattedMessage id='common.dateForNights' values={{ date: checkinDate, nights }} />
                    </p>
                  </Col>
                </Row>
              </Col>
            }
            {!hasYourStay &&
              <React.Fragment>
                <Col
                  md={2}
                  xs={6}
                  role='presentation'
                  tabIndex='0'>
                  <Row
                    className='row'
                    onClick={() => trackValues('checkIn', 'click')}
                    onKeyDown={(e) => e.key === 'Enter' && trackValues('checkIn', 'keydown')}
                    tabIndex='0'>
                    <Col md={12} lg={12}>
                      <small className='text-muted'>
                        <FormattedMessage id='common.checkinDate' />
                      </small>
                    </Col>
                    <Col md={12} lg={12}>
                      <p className='larger m-0'>
                        {checkinDate}
                      </p>
                    </Col>
                  </Row>
                </Col>
                <Col
                  md={2}
                  xs={6}
                  role='presentation'
                  tabIndex='0'>
                  <Row
                    className='row'
                    onClick={() => trackValues('checkOut', 'click')}
                    onKeyDown={(e) => e.key === 'Enter' && trackValues('checkOut', 'keydown')}
                    tabIndex='0'>
                    <Col md={12} lg={12}>
                      <small className='text-muted'>
                        <FormattedMessage id='common.checkoutDate' />
                      </small>
                    </Col>
                    <Col md={12} lg={12}>
                      <p className='larger m-0'>
                        {checkoutDate}
                      </p>
                    </Col>
                  </Row>
                </Col>
              </React.Fragment>
            }

            <Col
              md={hasYourStay ? 2 : 3}
              xs={6}
              onClick={() => trackValues('roomDescriptions', 'click')}
              onKeyDown={(e) => e.key === 'Enter' && trackValues('roomDescriptions', 'keydown')}
              role='presentation'
              tabIndex='0'>
              <Row>
                <Col lg={12} md={12}>
                  <small className='text-muted'>
                    <FormattedMessage id='common.yourRoom' values={{ count: roomDescriptions.length }} />
                  </small>
                </Col>
                <Col lg={12} md={12}>
                  {roomDescriptions.map((roomDescription, roomDescriptionKey) =>
                    <p key={`roomDescription_${roomDescriptionKey}`} className='larger m-0'>{roomDescription}</p>
                  )}
                </Col>
              </Row>
            </Col>

            {hasTicketDateCalendar && hasParkEntry &&
              <Col
                md={hasYourStay ? 3 : 2}
                xs={6}
                onClick={() => trackValues('parkEntry', 'click')}
                onKeyDown={(e) => e.key === 'Enter' && trackValues('parkEntry', 'keydown')}
                role='presentation'
                tabIndex='0'>
                <Row>
                  <Col lg={12} md={12}>
                    <small className='text-muted'>
                      {labelParkDate}
                    </small>
                  </Col>
                  <Col lg={12} md={12}>
                    <FormattedMessage id='common.parkEntryForConsecutiveDays' values={{ parkDate: parkDate && parkDate.format('Do MMMM YY'), ticketDuration }} />
                  </Col>
                </Row>
              </Col>
            }

            <Col
              md={1}
              xs={6}
              onClick={() => trackValues('change', 'click')}
              onKeyDown={(e) => e.key === 'Enter' && trackValues('change', 'keydown')}
              role='presentation'
              tabIndex='0'>
              <small className='d-block'>&nbsp;</small>
              <button
                className='btn-link small m-0 p-0'
                onClick={onChange}
                onKeyDown={(e) => e.key === 'Enter' && onChange()}
                role='presentation'
                tabIndex='0'>
                <FormattedMessage id='common.change' /> &raquo;
              </button>
            </Col>
          </div>
        </Collapse>
      </Row>
    </div>

    {hasTicketDescriptions &&
      <Collapse in={!collapsed}>
        <div className='fade summary-content bg-tertiary collapse-non-sm collapse-non-md collapse-non-lg'>
          <div className='block-xs container-fluid d-sm-flex small-xs text'>
            {Object.keys(ticketDescriptions).map(date => {
              return (
                <div className='py-1 pr-4 flex-column'>
                  <strong>{date}</strong>
                  {ticketDescriptions[date].map(details => {
                    return <p className='mb-0'>{details.name} - {details.time}: <span className='text-muted small'>{details.comp}</span></p>
                  })}
                </div>
              )
            })}
          </div>
        </div>
      </Collapse>
    }
  </React.Fragment>
)

export default SummaryWrapper(FixedSearchSummary)
