import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVG360 extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <g>
          <path d='M12 7C6.48 7 2 9.24 2 12c0 2.24 2.94 4.13 7 4.77V20l4-4-4-4v2.73c-3.15-.56-5-1.9-5-2.73 0-1.06 3.04-3 8-3s8 1.94 8 3c0 .73-1.46 1.89-4 2.53v2.05c3.53-.77 6-2.53 6-4.58 0-2.76-4.48-5-10-5z' />
        </g>
      </SVG>
    )
  }
}

SVG360.propTypes = SVG.propTypes

SVG360.defaultProps = {
  title: '360 Icon'
}

export default SVG360
