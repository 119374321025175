import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { Modal } from 'react-bootstrap'
import classNames from 'classnames'
import trackingHelpers from '../../../helpers/trackingHelpers'

import ResponsiveImage from '../../atoms/ResponsiveImage'
import SVGCoin from '../../atoms/SVGCoin'

const imagesDomain = _.get(window.brandConfig, 'secure.imagesDomain', '')

class Footer extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      showModal: false
    }
  }

  render () {
    const bestPrice = classNames({
      'panel-footer bg-tertiary': true,
      'hidden-xs': this.props.reducedInfo
    })

    const bestPricePromiseModal = (this.state.showModal) ? (
      <Modal
        onHide={() => this.setState(prevState => ({ showModal: !prevState.showModal }))}
        show>
        <Modal.Header closeButton>
          <Modal.Title>
            <FormattedMessage id='basket.bestPriceGuarantee' />
          </Modal.Title>
        </Modal.Header>
        <ResponsiveImage
          lazyload={false}
          defaultSize={300}
          src={`${imagesDomain}${this.props.bestPriceGuaranteeModal.image}`}
        />
        <Modal.Body>
          <div dangerouslySetInnerHTML={{ __html: this.props.bestPriceGuaranteeModal.text }} />
        </Modal.Body>
      </Modal>
    ) : null

    if (!this.props.hasFixedSummary) {
      return (
        <div className={bestPrice}>
          <div className='media d-inline-block'>
            <div className='media-left'>
              <SVGCoin
                height='30'
                width='30'
                viewBox='0 0 58 58'
              />
            </div>
            <div className='pl-0 pr-0 tiny media-right'>
              {this.props.bestPriceGuarantee
                ? <a
                  {...trackingHelpers.getAttributes('open modal', 'Link Click', 'Best Price Guarantee')}
                  onClick={() => this.setState(prevState => ({ showModal: !prevState.showModal }))}
                >
                  <FormattedMessage id='basket.bestPriceGuarantee' />
                </a>
                : <FormattedMessage id='basket.bestPricePromise' />
              }
              <p className='m-0 tiny text-muted'>
                <FormattedMessage id='basket.bestPricePromiseMessage' />
              </p>
            </div>
          </div>
          {bestPricePromiseModal}
        </div>
      )
    }

    return (
      <div className={bestPrice}>
        <div className='media'>
          <div className='media-left'>
            <SVGCoin
              height='30'
              width='30'
              viewBox='0 0 58 58'
            />
          </div>
          <div className='media-body'>
            <div className='media-heading m-0'>
              {this.props.bestPriceGuarantee
                ? <a
                  {...trackingHelpers.getAttributes('open modal', 'Link Click', 'Best Price Guarantee')}
                  onClick={() => this.setState(prevState => ({ showModal: !prevState.showModal }))}
                >
                  <FormattedMessage id='basket.bestPriceGuarantee' />
                </a>
                : <FormattedMessage id='basket.bestPricePromise' />
              }
            </div>
            <p className='m-0 tiny text-muted'>
              <FormattedMessage id='basket.bestPricePromiseMessage' />
            </p>
          </div>
        </div>
        {bestPricePromiseModal}
      </div>
    )
  }
}

export default Footer
