import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import Calendar from './Calendar'

import engineOld from '../../../actions/engineOld'

class HotelDate extends PureComponent {
  dateAsString (date, errorString = '') {
    const asMoment = moment(date)
    return asMoment.isValid() ? asMoment.format('Do MMM YY') : errorString
  }

  render () {
    // @TODO: remove this brand check and instead check for a transformer flag
    // (firstDayOfWeek or similar, soon to be implemented)
    const firstDayOfWeek = this.props.brand === 'ET' ? 1 : 0
    if (!this.props.hasEngineStore) return null
    return (
      <div className={classNames({
        'searchform-hoteldate': true,
        'has-error': this.props.error
      })}>
        <div className='form-group'>
          <label htmlFor='searchformhoteldatebutton'>{this.props.label}</label>
          <button
            id='searchformhoteldatebutton'
            type='button'
            onClick={this.props.toggleVisible}
            onKeyDown={(e) => e.key === 'Enter' && this.props.toggleVisible()}
            className='form-control ignore-react-onclickoutside'
          >
            {this.dateAsString(this.props.hotelDate, 'Please choose')}
          </button>
          {this.props.isVisible &&
            <Calendar
              onClose={this.props.toggleVisible}
              isDayDisabled={hotelDate => {
                return this.props.isHotelCalendarDayDisabled(hotelDate)
              }}
              endDate={this.props.lastAvailableHotelDate}
              selected={this.props.hotelDate}
              startDate={this.props.firstAvailableHotelDate}
              onChange={this.props.change}
              firstDayOfWeek={firstDayOfWeek}
            />
          }
          {this.props.error && <div className='help-block'>
            <FormattedMessage id='common.pleaseSelect' />
          </div>}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const {
    hotelDate,
    firstAvailableHotelDate,
    lastAvailableHotelDate
  } = state.engineOld.engineStore

  return {
    brand: state.brand.brand,
    error: state.engineOld.errorHotelDate,
    firstAvailableHotelDate,
    hasEngineStore: state.engineOld.hasEngineStore,
    hotelDate,
    isVisible: state.engineOld.visibleHotelCalendar,
    label: _.get(state.engineOld.brandConfig, 'UITextDefaults.labelHotelDate', 'Check-in Date'),
    lastAvailableHotelDate
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleVisible: e => {
      if (e && e.preventDefault) e.preventDefault()
      if (e && e.stopPropagation) e.stopPropagation()
      return dispatch(engineOld.toggle('hotelCalendar', false))
    },

    change: hotelDate => {
      return dispatch(engineOld.changeHotelDate(hotelDate))
    },

    // Not actually actions here but I was unsure the best way to handle this one.
    isHotelCalendarDayDisabled: date => {
      return engineOld.isHotelCalendarDayDisabled(date)
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(HotelDate)
