import React, { useContext } from 'react'
import _ from 'lodash'
import classNames from 'classnames'
import brandContext from '../../contexts/BrandContext'
import engine from '../../actions/engine'
import engineOld from '../../actions/engineOld'
import searchSummary from '../../actions/searchSummary'
import tracking from '../../actions/tracking'
import { query } from '../atoms/BreakPoint'
import { useDispatch, useSelector, shallowEqual } from 'react-redux'

const SummaryWrapper = WrappedComponent => () => {
  const context = useContext(brandContext)
  const metaContext = _.get(window, 'packageRatesReply.meta.context', {})
  const dispatch = useDispatch()
  const reduxState = useSelector(state => {
    const searchSummary = state.searchSummary
    const { UITextDefaults } = searchSummary.brandConfig
    return {
      ...searchSummary,
      ...UITextDefaults
    }
  }, shallowEqual)
  const collapsed = !reduxState.isSummaryElementExpanded && query.isXs()
  const handleSummaryCollapseToggle = () => dispatch(searchSummary.toggleIsSummaryElementExpanded(!reduxState.isSummaryElementExpanded))
  const hasTicketDescriptions = _.get(context, 'brandConfig.secure.hasFeatures.hasTicketDescriptions', false)
  const hasTicketDateCalendar = _.get(context, 'brandConfig.secure.hasFeatures.hasTicketDateCalendar', false)
  const selectedItems = metaContext.selectedItems ? metaContext.selectedItems[Object.keys(metaContext.selectedItems)[0]] : null
  const summaryTitleCollapsible = classNames('hidden-sm hidden-md hidden-lg', {
    'collapsed': collapsed,
    'hidden-xs': !reduxState.isSummaryTitleClickable
  })
  const summaryTitleNonCollapsible = classNames('m-0', {
    'hidden-xs': reduxState.isSummaryTitleClickable
  })
  const toggleEngineModal = () => {
    if (_.get(context, 'brandConfig.secure.hasFeatures.hasPackaging', false)) return dispatch(engine.toggleModal())
    return dispatch(engineOld.toggleModal())
  }
  const trackValues = (action, label) => dispatch(tracking.track(action, 'summary', label))

  return <WrappedComponent
    {...reduxState}
    collapsed={collapsed}
    handleSummaryCollapseToggle={handleSummaryCollapseToggle}
    hasTicketDescriptions={hasTicketDescriptions}
    hasTicketDateCalendar={hasTicketDateCalendar}
    onChange={toggleEngineModal}
    selectedItems={selectedItems}
    summaryTitleCollapsible={summaryTitleCollapsible}
    summaryTitleNonCollapsible={summaryTitleNonCollapsible}
    trackValues={trackValues}
  />
}

export default SummaryWrapper
