export default [
  'event-marker-star',
  'event-marker-circle',
  'event-marker-triangle',
  'event-marker-square',
  'event-marker-diamond',
  'event-marker-crescent',
  'event-marker-heart',
  'event-marker-drop',
  'event-marker-half-circle'
]
