import classNames from 'classnames'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { FormattedMessage } from 'react-intl'

import BrandContext from '../contexts/BrandContext'
import ContactInformation from '../components/molecules/ContactInformation'
import FAQs from '../components/molecules/FAQs'
import ModalContainer from './ModalContainer'
import CursedChildFooter from '../components/atoms/CursedChildFooter'

import modal from '../actions/modal'

import trackingHelpers from '../helpers/trackingHelpers'

import config from '../configs/config'
const {
  endpoint
} = config

class ModalLink extends PureComponent {
  render () {
    return (
      <a
        {...trackingHelpers.getAttributes('Footer link click', 'Secure Shell', this.props.trackingHelpersId)}
        data-prog='0'
        onClick={() => this.props.onToggleModal(this.props.modalId)}
        onKeyDown={(e) => e.key === 'Enter' && this.props.onToggleModal(this.props.modalId)}
        role='button'
        tabIndex='0'>
        {this.props.children}
      </a>
    )
  }
}

class Footer extends PureComponent {
  getModalDialogs () {
    const contactModal = this.props.visibleModal.contact &&
      <ModalContainer
        modalId='contact'
        formattedMessageId='common.contactUs'>
        <ContactInformation
          contactMessage={this.props.contactMessage}
          handleOpenLiveChat={this.props.handleOpenLiveChat}
          helpCentreUrl={this.context.brandConfig.secure}
          isLiveChatAvailable={this.props.liveChatState.isOnline}
        />
      </ModalContainer>

    const faqsModal = (this.props.visibleModal.faqs && this.context.brandConfig.faqs) &&
      <ModalContainer
        modalId='faqs'
        formattedMessageId='common.frequentlyAskedQuestions'>
        <FAQs faqs={this.context.brandConfig.faqs} />
      </ModalContainer>

    const termsModal = (this.props.visibleModal.termsAndConditions && this.context.brandConfig.termsAndConditions) &&
      <ModalContainer
        modalId='termsAndConditions'
        formattedMessageId='common.termsAndConditions'>
        <div dangerouslySetInnerHTML={{ __html: this.context.brandConfig.termsAndConditions }} />
      </ModalContainer>

    const privacyModal = (this.props.visibleModal.privacy && this.context.brandConfig.privacy_policy) &&
      <ModalContainer
        modalId='privacy'
        formattedMessageId='common.privacyPolicy'>
        <div dangerouslySetInnerHTML={{ __html: this.context.brandConfig.privacy_policy }} />
      </ModalContainer>

    const cookieModal = (this.props.visibleModal.cookie && this.context.brandConfig.cookie_policy) &&
      <ModalContainer
        modalId='cookie'
        formattedMessageId='common.cookiePolicy'>
        <div dangerouslySetInnerHTML={{ __html: this.context.brandConfig.cookie_policy }} />
      </ModalContainer>

    return {
      contactModal,
      faqsModal,
      termsModal,
      privacyModal,
      cookieModal
    }
  }
  render () {
    const footerClasses = classNames('secure-footer', {
      'footer-affix-spacing': endpoint === 'moreinformation'
    })

    const {
      cookie_policy: cookiePolicyContent,
      privacy_policy: privacyPolicyContent,
      secure = {}
    } = this.context.brandConfig

    // Destructure from 'secure'
    const {
      assetsUrl,
      cookiePolicyUrl,
      hasFeatures,
      helpCentreUrl,
      privacyPolicyUrl,
      ptrLegislationUrl,
      termsUrl,
      strapline: strapLine
    } = secure

    // Destructure from 'hasFeatures.footer'
    const {
      contact,
      cookiePolicy,
      enableNav = true,
      faqs,
      helpCentre,
      paypal,
      privacyPolicy,
      ptrLegislation,
      termsAndConditions
    } = hasFeatures.footer

    const {
      contactModal,
      faqsModal,
      termsModal,
      privacyModal,
      cookieModal
    } = this.getModalDialogs()

    let style = null
    if (!this.props.enableLinks) {
      style = {
        height: '100px'
      }
    }
    return (
      <footer className={footerClasses}>
        <div>
          {enableNav &&
            <nav className='tile-container navbar-footer_branded text-center'>
              {hasFeatures.confidenceBar && <CursedChildFooter assetsUrl={assetsUrl} />}

              <div className='text-center' style={style}>
                {this.props.enableLinks &&
                  <React.Fragment>
                    <ul className='list-inline navbar-footer_list'>
                      {contact && <li>
                        <ModalLink trackingHelpersId='Contact Us' modalId='contact' onToggleModal={this.props.onToggleModal}>
                          <FormattedMessage id='common.contactUs' />
                        </ModalLink>
                        {contactModal}
                      </li>
                      }
                      {faqs &&
                        <li>
                          <ModalLink trackingHelpersId='payment faqs' modalId='faqs' onToggleModal={this.props.onToggleModal}>
                            <FormattedMessage id='common.faqs' />
                          </ModalLink>
                          {faqsModal}
                        </li>
                      }
                      {helpCentre &&
                        <li>
                          <a
                            {...trackingHelpers.getAttributes('Footer link click', 'Secure Shell', 'help')}
                            data-prog='0'
                            href={helpCentreUrl}
                            target='_blank'>
                            <FormattedMessage id='common.help' />
                          </a>
                        </li>
                      }

                      {termsAndConditions && !termsUrl &&
                        <li>
                          <ModalLink trackingHelpersId='terms and conditions' modalId='termsAndConditions' onToggleModal={this.props.onToggleModal}>
                            <FormattedMessage id='common.termsAndConditions' />
                          </ModalLink>
                          {termsModal}
                        </li>
                      }

                      {termsAndConditions && termsUrl &&
                        <li>
                          <a
                            {...trackingHelpers.getAttributes('Footer link click', 'Secure Shell', 'terms')}
                            data-prog='0'
                            href={termsUrl}
                            role='button'
                            target='_blank'>
                            <FormattedMessage id='common.termsAndConditions' />
                          </a>
                        </li>
                      }

                      {privacyPolicy && privacyPolicyUrl &&
                        <li>
                          <a
                            {...trackingHelpers.getAttributes('Footer link click', 'Secure Shell', 'privacy')}
                            data-prog='0'
                            href={privacyPolicyUrl}
                            role='button'
                            target='_blank'>
                            <FormattedMessage id='common.privacyPolicy' />
                          </a>
                        </li>
                      }

                      {privacyPolicy && !privacyPolicyUrl && privacyPolicyContent &&
                        <li>
                          <ModalLink trackingHelpersId='privacy policy' modalId='privacy' onToggleModal={this.props.onToggleModal}>
                            <FormattedMessage id='common.privacyPolicy' />
                          </ModalLink>
                          {privacyModal}
                        </li>
                      }

                      {cookiePolicy && cookiePolicyUrl &&
                        <li>
                          <a
                            {...trackingHelpers.getAttributes('Footer link click', 'Secure Shell', 'cookie')}
                            data-prog='0'
                            href={cookiePolicyUrl}
                            target='_blank'>
                            <FormattedMessage id='common.cookiePolicy' />
                          </a>
                        </li>
                      }

                      {cookiePolicy && !cookiePolicyUrl && cookiePolicyContent &&
                        <li>
                          <ModalLink trackingHelpersId='cookie policy' modalId='cookie' onToggleModal={this.props.onToggleModal}>
                            <FormattedMessage id='common.cookiePolicy' />
                          </ModalLink>
                          {cookieModal}
                        </li>
                      }

                      {ptrLegislation && ptrLegislationUrl &&
                        <li>
                          <a
                            {...trackingHelpers.getAttributes('Footer link click', 'Secure Shell', 'ptr legislation')}
                            data-prog='0'
                            href={ptrLegislationUrl}
                            target='_blank'>
                            <FormattedMessage id='common.packageTravelRegulations' />
                          </a>
                        </li>
                      }
                    </ul>
                    <ul className='list-inline tile-icons'>
                      <FormattedMessage id='common.weAccept' tagName='li' />

                      {paypal &&
                        <li><img className='tile-icons_image' src={`${assetsUrl}shared/payment-types/paypal.svg`} alt='PayPal' /></li>
                      }

                      <li><img className='tile-icons_image' src={`${assetsUrl}shared/payment-types/visa.svg`} alt='Visa' /></li>
                      <li><img className='tile-icons_image' src={`${assetsUrl}shared/payment-types/mastercard.svg`} alt='Mastercard' /></li>
                      <li><img className='tile-icons_image' src={`${assetsUrl}shared/payment-types/maestro.svg`} alt='Maestro' /></li>
                    </ul>
                  </React.Fragment>
                }
              </div>
            </nav>
          }

          <div className='footer-strapline'>
            <div className='footer-strapline-text container text-center'>
              {strapLine}{strapLine && ' '}<FormattedMessage id='common.allRightsReserved' values={{ date: new Date().getFullYear() }} />
            </div>
          </div>
        </div>
      </footer>

    )
  }
}

Footer.defaultProps = {
  enableLinks: true
}

Footer.contextType = BrandContext

function mapStateToProps (state, ownProps) {
  return {
    visibleModal: (state.modal && state.modal.visible) || {}
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    onToggleModal: modalName => dispatch(modal.toggle(modalName))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Footer)
export {
  Footer
}
