import React from 'react'
import PropTypes from 'prop-types'
import Select from './Select'

const Room = props => (
  <div>
    <Select
      id={props.id}
      label={props.label}
      hideLabel={props.hideLabel}
      clearable={props.clearable}
      onChange={props.onChange}
      options={props.options}
      placeholder='Please select'
      selected={props.selected} />
  </div>
)

Room.defaultProps = {
  label: 'Room',
  hideLabel: false,
  clearable: true,
  onChange: () => {}
}

Room.propTypes = {
  id: PropTypes.string.isRequired,
  clearable: PropTypes.bool,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    value: PropTypes.string,
    label: PropTypes.string
  })).isRequired,
  selected: PropTypes.string,
  label: PropTypes.string,
  hideLabel: PropTypes.bool
}

export default Room
