import qs from 'qs'
import routeHelpers from '../helpers/routeHelpers'
import trackingHelpers from '../helpers/trackingHelpers'

const vouchers = {

  add: voucherCode => {
    return (dispatch, getState) => {
      const paymentState = getState().payment || {}
      const customerEmail = paymentState.billingDetails && paymentState.billingDetails.billToEmail
      if (!customerEmail || !voucherCode) return

      const vouchersState = getState().vouchers || {}
      const alreadyApplied = (vouchersState.vouchers || []).some(voucher => voucher.voucherCode === voucherCode)

      if (alreadyApplied) {
        trackingHelpers.track('sb.track', `Voucher "${voucherCode}" already applied`, 'Vouchers')
        return dispatch({
          error: 'This voucher code has already been applied.',
          type: 'ADD_VOUCHER_FAILED'
        })
      }

      trackingHelpers.track('sb.track', `Voucher "${voucherCode}" added (pre validation)`, 'Vouchers', voucherCode)

      dispatch({
        type: 'ADD_VOUCHER_START'
      })

      const queryStringParams = routeHelpers.getQueryStringParams()
      const queryParams = qs.stringify({
        customerEmail,
        referrer: queryStringParams.referrer
      })
      return fetch(`/vouchers/${voucherCode}?${queryParams}`, {
        headers: {
          'content-type': 'application/json',
          'Accept': 'application/json'
        },
        credentials: 'include'
      })
        .then(response => response.json())
        .then(response => {
          const {
            errors = [],
            vouchers = []
          } = response.vouchersReply || {}

          if (vouchers.length === 0 && errors.length === 0) {
            throw new Error('Invalid response')
          }

          if (errors.length !== 0) {
            throw new Error(errors[0].title)
          }

          const totalAmountToPay = [...vouchersState.vouchers, vouchers[0]].reduce((acc, curr) => {
            acc -= curr.remainingValue
            return acc
          }, paymentState.grossPrice)

          // Only if vouchers cover the total amount do we want this payment method.
          // It allows payment-client to do a skip payment
          if (totalAmountToPay <= 0) {
            dispatch({
              type: 'PAYMENT_CHOICE_CHANGE',
              value: 'vouchers'
            })
          }

          trackingHelpers.track('sb.track', `Voucher "${voucherCode}" is valid and has been applied`, 'Vouchers', voucherCode)
          dispatch({
            type: 'ADD_VOUCHER_SUCCESS',
            voucher: vouchers[0]
          })
        })
        .catch(error => {
          trackingHelpers.track('sb.track', `Voucher "${voucherCode}" is not valid`, 'Vouchers', error.message)
          dispatch({
            error: error.message,
            type: 'ADD_VOUCHER_FAILED'
          })
        })
    }
  },

  remove: voucherCode => {
    return dispatch => {
      trackingHelpers.track('sb.track', 'Removing Voucher', 'Vouchers', voucherCode)
      dispatch({
        type: 'PAYMENT_CHOICE_CHANGE',
        value: 'card'
      })
      dispatch({
        voucherCode,
        type: 'REMOVE_VOUCHER_SUCCESS'
      })
    }
  },

  removeAll: () => {
    return (dispatch, getState) => {
      const vouchersState = getState().vouchers || {}
      // Don't dispatch or track when there are no vouchers
      if ((vouchersState.vouchers || []).length === 0) return

      trackingHelpers.track('sb.track', 'Removing All Vouchers (email changed)', 'Vouchers', '1')
      dispatch({
        type: 'REMOVE_ALL_VOUCHERS'
      })
    }
  }
}

export default vouchers
