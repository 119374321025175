import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'
import config from '../../configs/config'

import CheckIn from './UI/CheckIn'
import CheckOut from './UI/CheckOut'
import Form from './UI/Form'
import PartyComp from './UI/PartyComp'
import Rooms from './UI/Rooms'
import TicketDate from './UI/TicketDate'
import Timeslots from './UI/Timeslots'

const {
  brandConfig,
  packageRatesReply
} = config

const PackagingEngine = ({
  inline
}) => {
  const hasTicketDateCalendar = useSelector(state => _.get(state, 'brand.secure.hasFeatures.hasTicketDateCalendar', false))
  const isFlexibleDate = useSelector(state => state.searchSummary.isFlexibleDate)
  const hasParkEntry = useSelector(state => state.engine.hasParkEntry)
  const _ticketCodeKey = Object.keys(packageRatesReply.linked.ticketRates)[0]
  const isAnnualPass = _.get(packageRatesReply, 'linked.ticketRates', false)[_ticketCodeKey].isAnnualPass

  const bookingType = () => {
    if (isAnnualPass) return 'map'
    if (hasParkEntry) return 'hasParkEntry'
    return 'bedAndBreakfast'
  }
  const alternativePlatformUrl = brandConfig.alternativePlatformUrl[bookingType()]

  return (
    <Form
      inline={inline}
    >
      <div>
        <p>Booking a short break to arrive from 4th February 2024? Click <a href={alternativePlatformUrl}>here</a> to search for availability.</p>
        <CheckIn />
        <CheckOut />
        {!isFlexibleDate && hasTicketDateCalendar && hasParkEntry && <TicketDate />}
        <Timeslots />
        <PartyComp />
        <Rooms />
      </div>
    </Form>
  )
}

PackagingEngine.defaultProps = {
  inline: false
}

export default PackagingEngine
