import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import DisplayPrice from '../../atoms/DisplayPrice'

import {
  getTotalAmountToPay,
  getTotalRedemptionValue
} from '../../../selectors/payment'

const Total = ({
  standardPrice,
  reducedInfo,
  showRoomsFromPrice,
  showPriceOnMobile,
  totalAmountToPay,
  totalOrderValue,
  totalRedemptionValue,
  vouchers,
  wasText
}) => {
  if (!totalOrderValue) return null

  // showRoomsFromPrice is only used on the  More Information page
  let summaryTotalFromId = reducedInfo && showRoomsFromPrice ? 'common.from' : 'common.totalPrice'
  if (totalRedemptionValue > 0) summaryTotalFromId = 'payment.leftToPay'
  const displayFromPrice = Number(standardPrice) > 0 && (standardPrice !== totalOrderValue)

  // Show either price or dates in the basket sticky book bar (on mobile) depending on a flag for offsite and resort hotels
  const priceSectionClasses = classNames('mb-2 mb-sm-3 text-right col-sm-12', {
    'hidden-xs': reducedInfo && !showPriceOnMobile
  })

  return (
    <div className='row'>
      {totalRedemptionValue > 0 &&
        <React.Fragment>
          <div className='col-xs-12 small'>
            <FormattedMessage id='common.total' />
            <span className='pull-right'>
              <DisplayPrice price={totalOrderValue} />
            </span>
          </div>

          <div className='col-xs-12'>
            {vouchers.map(voucher =>
              <div className='small label-secondary py-1 pl-1 pr-1 mb-2'>
                <FormattedHTMLMessage
                  id='payment.voucherHasBeenApplied'
                  values={{
                    voucherCode: voucher.voucherCode
                  }}
                />
                <span className='pull-right'>
                    -<DisplayPrice price={voucher.redemptionValue} />
                </span>
              </div>
            )}
          </div>
        </React.Fragment>
      }

      <div className={priceSectionClasses}>
        {displayFromPrice &&
          <div className='mt-0 text-capitalize'>
            {wasText} <s><DisplayPrice price={standardPrice} /></s>
          </div>
        }
        <FormattedMessage id={summaryTotalFromId} />&nbsp;
        <span className='h2 d-block d-sm-inline m-0'>
          <DisplayPrice price={totalAmountToPay >= 0 ? totalAmountToPay : 0} showZeroAsPrice />
        </span>
      </div>
    </div>
  )
}

Total.defaultProps = {
  hasFixedSummary: false
}

Total.propTypes = {
  hasFixedSummary: PropTypes.bool.isRequired
}

function mapStateToProps (state, ownProps) {
  const {
    grossPrice,
    standardPrice
  } = (ownProps.isPaymentStage && state.payment) || ownProps
  return {
    standardPrice,
    totalAmountToPay: ownProps.isPaymentStage ? getTotalAmountToPay(state) : grossPrice,
    totalOrderValue: grossPrice,
    totalRedemptionValue: getTotalRedemptionValue(state),
    vouchers: state.vouchers.vouchers || []
  }
}

export default connect(mapStateToProps)(Total)
export {
  Total
}
