import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGCalendar extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <g>
          <path d='M42.899,4.5c-0.465-2.279-2.484-4-4.899-4c-0.553,0-1,0.447-1,1s0.447,1,1,1c1.654,0,3,1.346,3,3s-1.346,3-3,3   c-0.553,0-1,0.447-1,1s0.447,1,1,1c2.414,0,4.434-1.721,4.899-4H56v9H2v-9h14h3c0.553,0,1-0.447,1-1s-0.447-1-1-1h-1.816   c0.414-1.162,1.514-2,2.816-2c1.654,0,3,1.346,3,3s-1.346,3-3,3c-0.553,0-1,0.447-1,1s0.447,1,1,1c2.757,0,5-2.243,5-5   s-2.243-5-5-5c-2.414,0-4.434,1.721-4.899,4H0v13v40h58v-40v-13H42.899z M56,55.5H2v-38h54V55.5z' />
          <path d='M26,2.5c1.654,0,3,1.346,3,3s-1.346,3-3,3c-0.553,0-1,0.447-1,1s0.447,1,1,1c2.757,0,5-2.243,5-5s-2.243-5-5-5   c-0.553,0-1,0.447-1,1S25.447,2.5,26,2.5z' />
          <path d='M32,2.5c1.654,0,3,1.346,3,3s-1.346,3-3,3c-0.553,0-1,0.447-1,1s0.447,1,1,1c2.757,0,5-2.243,5-5s-2.243-5-5-5   c-0.553,0-1,0.447-1,1S31.447,2.5,32,2.5z' />
          <circle cx='22' cy='24.5' r='1' />
          <circle cx='29' cy='24.5' r='1' />
          <circle cx='36' cy='24.5' r='1' />
          <circle cx='43' cy='24.5' r='1' />
          <circle cx='50' cy='24.5' r='1' />
          <circle cx='8' cy='32.5' r='1' />
          <circle cx='15' cy='32.5' r='1' />
          <circle cx='22' cy='32.5' r='1' />
          <circle cx='29' cy='32.5' r='1' />
          <circle cx='36' cy='32.5' r='1' />
          <circle cx='43' cy='32.5' r='1' />
          <circle cx='50' cy='32.5' r='1' />
          <circle cx='8' cy='39.5' r='1' />
          <circle cx='15' cy='39.5' r='1' />
          <circle cx='22' cy='39.5' r='1' />
          <circle cx='29' cy='39.5' r='1' />
          <circle cx='36' cy='39.5' r='1' />
          <circle cx='43' cy='39.5' r='1' />
          <circle cx='50' cy='39.5' r='1' />
          <circle cx='8' cy='47.5' r='1' />
          <circle cx='15' cy='47.5' r='1' />
          <circle cx='22' cy='47.5' r='1' />
          <circle cx='29' cy='47.5' r='1' />
          <circle cx='36' cy='47.5' r='1' />
        </g>
      </SVG>
    )
  }
}

SVGCalendar.propTypes = SVG.propTypes

SVGCalendar.defaultProps = {
  title: 'Calendar Icon'
}

export default SVGCalendar
