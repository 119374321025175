import React, { Component } from 'react'
import { connect } from 'react-redux'

import AddressLookup from '../components/molecules/AddressLookup'
import payment from '../actions/payment'

import BrandContext from '../contexts/BrandContext'

class AddressLookupContainer extends Component {
  render () {
    return (
      <AddressLookup
        isCallCentre={this.context.isCallCentre}
        errors={this.props.errors}
        hasManualInput={this.props.hasManualInput}
        listOfAddresses={this.props.listOfAddresses}
        onAddressConfirm={this.props.onAddressConfirm}
        onAddressReset={this.props.onAddressReset}
        onAddressSelect={this.props.onAddressSelect}
        onInputChange={this.props.onInputChange}
        onPostalCodeGet={this.props.onPostalCodeGet}
        onToggleManualInput={this.props.onToggleManualInput}
        setBillingTelephoneNumber={this.props.setBillingTelephoneNumber} // TODO: should this be part of this component - no, remove telepohone later?
        billToAddressCity={this.props.billingDetails.billToAddressCity}
        billToAddressCountry={this.props.billingDetails.billToAddressCountry}
        billToAddressLine0={this.props.billingDetails.billToAddressLine0}
        billToAddressLine1={this.props.billingDetails.billToAddressLine1}
        billToAddressLine2={this.props.billingDetails.billToAddressLine2}
        billToAddressPostalCode={this.props.billingDetails.billToAddressPostalCode}
        billToAddressState={this.props.billingDetails.billToAddressState}
        billToPhone={this.props.billingDetails.billToPhone}
      />
    )
  }
}

AddressLookupContainer.contextType = BrandContext

AddressLookupContainer.defaultProps = {
  billingDetails: {
    billToAddressCity: '',
    billToAddressCountry: '',
    billToAddressLine0: '',
    billToAddressLine1: '',
    billToAddressLine2: '',
    billToAddressPostalCode: '',
    billToAddressState: '',
    billToPhone: ''
  }
}

function mapStateToProps (state) {
  return {
    billingDetails: state.payment.billingDetails,
    errors: state.payment.errors,
    hasManualInput: state.payment.hasManualInput,
    listOfAddresses: state.payment.listOfAddresses
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onAddressConfirm: (addressFields) => {
      return dispatch(payment.addressConfirm(addressFields))
    },
    onAddressReset: () => {
      return dispatch(payment.addressReset())
    },
    onAddressSelect: (addressId) => {
      return dispatch(payment.addressSelect(addressId))
    },
    onInputChange: (id, value) => {
      return dispatch(payment.inputChange(id, value))
    },
    onPostalCodeGet: (postcode) => {
      return dispatch(payment.postalCodeGet(postcode))
    },
    onToggleManualInput: () => {
      return dispatch(payment.toggleManualInput())
    },
    setBillingTelephoneNumber: (telephoneNumber, countryCode, dialCode) => {
      return dispatch(payment.setBillingTelephoneNumber(telephoneNumber, countryCode, dialCode))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(AddressLookupContainer)
export {
  AddressLookupContainer
}
