import _ from 'lodash'
import moment from 'moment'

import basketHelpers from '../helpers/basketHelpers'
import roomTypes from '../configs/roomTypes'

import config from '../configs/config'

const { harvestBasketData } = config

const withChildAges = _.get(config, 'brandConfig.secure.withChildAges', false)

const searchSummary = (state = {
  brandConfig: {},
  title: '',
  checkinDate: '',
  checkoutDate: '',
  parkDate: '',
  party: '',
  roomDescriptions: [],
  onChange: () => {}
}, action) => {
  switch (action.type) {
    case 'GET_AVAILABILITY_PAGE_DATA': {
      const packageRatesReply = _.get(window, 'packageRatesReply', [])

      // As we technically haven't actually got a ticket selected at this point. Let's just get the
      // 1st package rate & use that as our basis for all the linked data we will need.
      const eventProductId = _.get(packageRatesReply, 'packageRates[0].links.eventProducts.ids[0]', null)
      const ticketName = _.get(packageRatesReply, ['linked', 'eventProducts', eventProductId, 'name'], '')
      const roomRates = _.get(packageRatesReply, 'meta.context.roomRates', {})
      const ticketRates = _.get(packageRatesReply, 'meta.context.ticketRates', {})
      const checkinDate = roomRates.checkinDate || null
      const checkoutDate = roomRates.checkoutDate || null
      const rooms = roomRates.rooms || null
      const nights = moment(checkoutDate).diff(moment(checkinDate), 'days')

      const { adults, children, infants } = basketHelpers.maybeConvertPartyComp(withChildAges ? roomRates : ticketRates)
      const startDate = _.get(packageRatesReply, 'meta.context.ticketRates.startDate')
      const timeslot = _.get(packageRatesReply, 'meta.context.ticketRates.timeslot')

      const roomDescriptions = basketHelpers.getRoomsDescriptions(roomTypes, rooms)
      const brandConfig = _.get(window, 'searchFormService.brandConfig', {})

      const linkedTicketRates = _.cloneDeep(Object.values(_.get(packageRatesReply, ['linked', 'ticketRates'], {})))
      const linkedEventProducts = _.get(packageRatesReply, ['linked', 'eventProducts'], {})

      linkedTicketRates.forEach(rate => {
        const eventProduct = _.get(rate, 'links.eventProducts.ids[0]')
        if (linkedEventProducts[eventProduct]) {
          rate.name = linkedEventProducts[eventProduct].name
        }
      })
      const ticketDescriptions = basketHelpers.getTicketDescriptions(linkedTicketRates)
      const ticketRate = linkedTicketRates[0] || {}
      const ticketDuration = Number(ticketRate.days)
      const hasParkEntry = ticketRate.hasParkEntry
      const isFlexibleDate = ticketRate.isFlexibleDate
      const hasYourStay = _.get(config, 'brandConfig.secure.hasFeatures.summary.hasYourStay', false)

      return Object.assign({}, state, {
        adults,
        brandConfig,
        checkinDate: checkinDate ? moment(checkinDate).format('Do MMMM YYYY') : null,
        checkoutDate: checkoutDate ? moment(checkoutDate).format('Do MMMM YYYY') : null,
        children,
        hasParkEntry,
        hasYourStay,
        infants,
        initialProps: true,
        isFlexibleDate,
        isSummaryElementExpanded: !_.get(window, 'location.hash'),
        isSummaryTitleClickable: false,
        nights,
        parkDate: startDate ? moment(startDate) : null,
        roomDescriptions,
        ticketDescriptions,
        ticketDuration,
        ticketTime: timeslot && timeslot.start && timeslot.start !== '0000' ? moment(timeslot.start, 'HHmm').format('h:mma') : null,
        title: ticketName
      })
    }
    case 'GET_MOREINFORMATION_PAGE_DATA':
    case 'GET_UPGRADES_PAGE_DATA':
    case 'GET_PAYMENT_PAGE_DATA': {
      const hasParkEntry = harvestBasketData.hasParkEntry
      const isFlexibleDate = _.get(harvestBasketData, 'ticket.isFlexibleDate', false) === 'true'
      const ticketDuration = Number(_.get(harvestBasketData, 'ticket.duration', null))
      const ticketName = _.get(harvestBasketData, 'ticket.name', null)
      const checkinDate = _.get(harvestBasketData, 'hotel.checkinDate', null)
      const checkoutDate = _.get(harvestBasketData, 'hotel.checkoutDate', null)
      const rooms = _.get(harvestBasketData, 'rooms', null)
      const partyComp = basketHelpers.maybeConvertPartyComp(_.get(harvestBasketData, 'ticket', {}))
      const { adults, children, infants, startDate } = partyComp
      const roomDescriptions = basketHelpers.getRoomsDescriptions(roomTypes, rooms)
      const brandConfig = _.get(window, 'searchFormService.brandConfig', {})
      const labelParkDate = _.get(brandConfig, 'UITextDefaults.labelParkDate', 'Ticket Date')
      const nights = moment(checkoutDate).diff(moment(checkinDate), 'days')
      const hasYourStay = _.get(config, 'brandConfig.secure.hasFeatures.summary.hasYourStay', false)

      return Object.assign({}, state, {
        adults,
        brandConfig,
        checkinDate: checkinDate ? moment(checkinDate).format('Do MMMM YYYY') : null,
        children,
        hasParkEntry,
        hasYourStay,
        infants,
        initialProps: true,
        isFlexibleDate,
        labelParkDate,
        nights,
        parkDate: startDate ? moment(startDate) : null,
        roomDescriptions,
        ticketDuration,
        title: ticketName
      })
    }

    case 'TOGGLE_IS_SUMMARY_ELEMENT_EXPANDED':
      return {
        ...state,
        isSummaryElementExpanded: action.expanded
      }
    case 'TOGGLE_IS_SUMMARY_TITLE_CLICKABLE':
      return {
        ...state,
        isSummaryTitleClickable: action.clickable
      }
    default:
      return state
  }
}

export default searchSummary
