const languageHelpers = {

  /**
   * Generates a string explaining a little about a facility depending on some input
   * @param {String} facilityName the facility
   * @param {Boolean} isFree is it free or not?
   * @param {Number} amount price you have to pay
   *
   * @return {String} rendered bit of text
   */
  generateFacilityTooltip: function (facilityName, isFree, amount) {
    if (!facilityName) return null
    if (isFree === undefined) return `${facilityName} is available`
    if (isFree) return `Free ${facilityName} is available`

    const amountString = amount ? ` at £${amount} per day` : ''
    return `Paid ${facilityName} is available${amountString}`
  },

  /**
   * Turns a singular version of a word into the plural
   * @param {String} word singular word
   * @param {Number} quantity number of the item
   * @return {String} The word pluralised to match the number
   */
  pluralise: function (word, quantity) {
    if (quantity === 1) return word
    switch (word.toLowerCase()) {
      case 'child':
        return word + 'ren'
      case 'baby':
        return word.substr(0, 3) + 'ies'
      default:
        return word + 's'
    }
  },

  /**
   * Replaces %s with arguments passed in
   * @param  {String} string string to replace arguments in
   * @return {String} The replaced string
   */
  sprintf: function (string) {
    if (!string || arguments.length <= 1) {
      return null
    }

    for (var i = 1; i < arguments.length; i++) {
      string = string.replace(/%s/, arguments[i])
    }

    return string
  }
}

export default languageHelpers
