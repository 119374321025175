import _ from 'lodash'

const seats = (state, action) => {
  state = state || {
    availability: {},
    booked: false,
    errors: {},
    eventProduct: {
      eventInformation: null,
      id: null,
      image: null,
      name: null
    },
    loading: false,
    queryObject: {},
    selected: null,
    venue: {
      id: null,
      name: null,
      seatMap: {},
      sections: []
    }
  }

  switch (action.type) {
    case 'SET_ERROR_SUCCESS':
      return {
        ...state,
        errors: action.payload
      }

    case 'SET_QUERY_OBJECT_SUCCESS':
      return { ...state,
        queryObject: action.payload
      }

    case 'CHANGE_SEATS_SUCCESS': {
      const ages = _.times(action.payload, () => 18)
      const roomRates = Object.assign({}, state.queryObject.roomRates)
      delete roomRates.rooms
      return {
        ...state,
        queryObject: {
          ...state.queryObject,
          roomRates,
          ages
        },
        selected: null
      }
    }

    case 'CHANGE_QUERY_OBJECT_SUCCESS':
      return {
        ...state,
        queryObject: {
          ...state.queryObject,
          [action.payload.key]: action.payload.value
        },
        selected: null
      }

    case 'SET_IS_LOADING':
      return Object.assign({}, state, {
        loading: action.payload
      })

    case 'SET_SELECTED_SEAT':
      return Object.assign({}, state, {
        selected: action.payload.seat,
        booked: action.payload.book
      })

    case 'SET_EVENT_SUCCESS':
      return Object.assign({}, state, {
        eventProduct: action.payload
      })

    case 'SET_VENUE_SUCCESS':
      return Object.assign({}, state, {
        venue: action.payload
      })

    case 'SET_SEAT_AVAILABILITY':
      const packageRates = action.payload.packageRates
      const { seatCombinations, ticketRates } = action.payload.linked
      let availability = {}
      packageRates.map((rate, index) => {
        const ticketRateId = rate.links.ticketRates.ids[0]
        const ticketRate = ticketRates[ticketRateId]
        const alternativeCode = ticketRate.alternativeCode || ''
        const timeslot = ticketRate.timeslot.start
        const ids = _.get(ticketRate, `links.seatCombinations.ids`, [])
        ids.map(id => {
          const seat = seatCombinations[id]
          _.range(seat.first, Number(seat.last + 1)).map(i => {
            const sectionId = seat.section.match(/\b(\w)/g).join('').toLowerCase()
            const sectionRow = (`${sectionId}-${seat.row}`).toLowerCase()
            const code = `${sectionRow}-${i}`
            availability[code] = Object.assign({}, seat, {
              alternativeCode,
              code,
              grossPrice: rate.grossPrice,
              packageRateId: rate.id,
              sectionId,
              sectionRow,
              ticketRateId,
              timeslot
            })
          })
        })
      })
      return Object.assign({}, state, {
        availability
      })

    default:
      return state
  }
}

export default seats
