import _ from 'lodash'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Collapse, Col } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { AutoAffix } from 'react-overlays'
import { connect } from 'react-redux'

import BrandContext from '../../../contexts/BrandContext'

import { query } from '../../atoms/BreakPoint'
import DisplayPrice from '../../atoms/DisplayPrice'

import Dates from './Dates'
import TicketOnlyDate from './TicketOnlyDate'
import EventItem from './EventItem'
import Footer from './Footer'
import HotelItem from './HotelItem'
import ParkEntry from './ParkEntry'
import PartyComposition from './PartyComposition'
import SummaryDiscountMessage from './SummaryDiscountMessage'
import Total from './Total'
import TotalWithSurcharge from './TotalWithSurcharge'
import UpgradeSummary from './UpgradeSummary'

import trackingHelpers from '../../../helpers/trackingHelpers'
import config from '../../../configs/config'

import {
  getTotalAmountToPay,
  getTotalRedemptionValue
} from '../../../selectors/payment'

const hasCancellationProtectionMessage = _.get(config, 'brandConfig.secure.hasFeatures.hasCancellationProtectionMessage', false)
const hasInitialBasketCollapsed = _.get(config, 'brandConfig.secure.hasFeatures.hasInitialBasketCollapsed', false)

class Basket extends PureComponent {
  // @hack: FP-11905 for split test on mobile - expanded basket summary
  constructor (props) {
    super(props)

    this.state = {
      isSummaryElementExpanded: hasInitialBasketCollapsed
    }

    this.handleSummaryCollapseToggle = this.handleSummaryCollapseToggle.bind(this)
    this.getBasketContent = this.getBasketContent.bind(this)
    this.scrollEventsHandler = this.scrollEventsHandler.bind(this)
    this.upgradesToDisplay = []
    if (this.props.shouldBasketExpand && !this.state.isSummaryElementExpanded) {
      this.handleSummaryCollapseToggle(false)
    }

    this.ref = React.createRef()
  }

  handleSummaryCollapseToggle (shouldTrack = true) {
    this.setState(prevState => ({
      isSummaryElementExpanded: !prevState.isSummaryElementExpanded
    }))

    if (!shouldTrack) return

    const action = this.props.isSummaryElementExpanded ? 'Collapse' : 'Expand'
    trackingHelpers.track('sb.track', action, 'Booking Summary', this.props.endpoint)
  }

  componentDidMount () {
    window.addEventListener('scroll', _.debounce(this.scrollEventsHandler, 100, {
      'leading': true,
      'trailing': false
    }))
  }

  componentWillUnmount () {
    window.removeEventListener('scroll', this.scrollEventsHandler)
  }

  scrollEventsHandler () {
    if (this.props.hasFixedSummary && this.props.endpoint !== 'moreinformation') {
      // close the summary on scroll when fixed)
      query.isXs() &&
      this.state.isSummaryElementExpanded &&
      window.innerHeight > this.ref.current.clientHeight &&
      this.handleSummaryCollapseToggle()
      // open the summary if screen is larger than Xs
      !query.isXs() && !this.state.isSummaryElementExpanded && this.handleSummaryCollapseToggle()
    }
  }

  getBasketContent () {
    const cancellationProtectionMessageClassNames = classNames('small text-center text-success d-inline-block', {
      'mt-2 mt-sm-3': this.props.endpoint !== 'payment'
    })
    return (
      <React.Fragment>
        <div className='panel-body'>
          <ul className='list-unstyled'>
            <HotelItem
              cancellationWaiver={this.props.cancellationWaiver}
              cateringDescription={this.props.cateringDescription}
              hasFixedSummary={this.props.hasFixedSummary}
              hideBasketSVGs={this.props.hideBasketSVGs}
              hotelEventDescription={this.props.hotelEventDescription}
              name={this.props.name}
              reducedInfo={this.props.reducedInfo}
              roomDescriptions={this.props.roomDescriptions}
              roomName={this.props.roomName}
              whatsIncluded={this.props.whatsIncluded}
            />
            <Dates
              checkInDate={this.props.checkInDate}
              checkInTime={this.props.checkInTime}
              checkOutDate={this.props.checkOutDate}
              checkOutTime={this.props.checkOutTime}
              hasFixedSummary={this.props.hasFixedSummary}
              hideBasketSVGs={this.props.hideBasketSVGs}
              reducedInfo={this.props.reducedInfo}
              hasYourStay={this.props.hasYourStay}
            />
            <ParkEntry
              hasFixedSummary={this.props.hasFixedSummary}
              hasParkEntry={this.props.hasParkEntry}
              hideBasketSVGs={this.props.hideBasketSVGs}
              labelParkDate={this.props.labelParkDate}
              parkDate={this.props.parkDate}
              reducedInfo={this.props.reducedInfo}
              ticketDuration={this.props.ticketDuration}
            />
            <EventItem
              basketAlternativeMessage={this.props.basketAlternativeMessage}
              category={this.props.category}
              cateringDescription={this.props.cateringDescription}
              hasFixedSummary={this.props.hasFixedSummary}
              hasParkEntry={this.props.hasParkEntry}
              hideBasketSVGs={this.props.hideBasketSVGs}
              hotelEventDescription={this.props.hotelEventDescription}
              packageIncludedVisibleUpgrades={this.props.packageIncludedVisibleUpgrades || []}
              reducedInfo={this.props.reducedInfo}
              swappableTicketUpgradeTicket={this.props.swappableTicketUpgradeTicket || {}}
              showData={this.props.showData}
              ticketName={this.props.ticketName}
              venueType={this.props.venueType}
              whatsIncluded={this.props.whatsIncluded}
            />
            {((this.upgradesToDisplay.length > 0 && this.props.showUpgradeSummary) ||
              (this.props.postalConfirmation || this.props.cancellationWaiver)) && (
              <UpgradeSummary
                cancellationWaiver={this.props.cancellationWaiver}
                extraNights={this.props.extraNights}
                handleExtraNightCTA={this.props.handleExtraNightCTA}
                handleUpgradeCTA={this.props.handleUpgradeCTA}
                hasFixedSummary={this.props.hasFixedSummary}
                hideBasketSVGs={this.props.hideBasketSVGs}
                postalConfirmation={this.props.postalConfirmation}
                summaryTitle={this.props.summaryTitle}
                upgrades={this.upgradesToDisplay}
              />
            )}
          </ul>

          {/* Show TotalWithSurcharge if payment and other check meets */}
          {this.props.endpoint === 'payment' ? (
            <React.Fragment>
              {this.context.brandConfig.secure.removeCreditCardSurcharge
                ? (
                  <Total isPaymentStage wasText={this.props.wasText} />
                )
                : (
                  <TotalWithSurcharge
                    wasText={this.props.wasText}
                    creditCardSurcharge={this.context.brandConfig.creditCardSurcharge}
                    grossPrice={this.props.grossPrice}
                    hasFixedSummary={this.props.hasFixedSummary}
                    standardPrice={this.props.standardPrice}
                  />
                )
              }
              {!this.props.cancellationWaiver && <hr />}
            </React.Fragment>
          ) : (
            <React.Fragment>
              <Total
                grossPrice={this.props.grossPrice}
                reducedInfo={this.props.reducedInfo}
                wasText={this.props.wasText}
              />
              <hr />
            </React.Fragment>
          )}
          {this.props.button}

          {!this.props.cancellationWaiver && hasCancellationProtectionMessage && this.props.cancellationWaiverPrice &&
            <Col xs={12} className={cancellationProtectionMessageClassNames}>
              <Col xs={9} sm={12} lg={10} className='d-inline-block float-none'>
                <FormattedMessage id='basket.protectYourBooking' tagName='strong' values={{ cancellationWaiverPrice: this.props.cancellationWaiverPrice }} />
              </Col>
            </Col>
          }
        </div>

        {(this.props.hasBestPricePromise || this.props.hasBestPriceGuarantee) &&
          <Footer
            bestPriceGuarantee={this.props.hasBestPriceGuarantee}
            bestPriceGuaranteeModal={this.context.brandConfig.bestPricePromise && this.context.brandConfig.bestPricePromise[0]}
            hasFixedSummary={this.props.hasFixedSummary}
            reducedInfo={this.props.reducedInfo}
          />
        }
      </React.Fragment>
    )
  }

  render () {
    // filter out any upgrade we do not want to display
    this.upgradesToDisplay = (this.props.upgrades || []).filter(upgrade => upgrade.isSummaryVisibleUpgrade === 'true' || upgrade.isSummaryVisibleUpgrade === true)

    const collapseIn = query.isSm() || (this.state.isSummaryElementExpanded && query.isXs())
    const hrClassName = this.props.reducedInfo ? 'hidden-xs' : ''
    const parkName = this.context.brandConfig.name.charAt(0).toUpperCase() + this.context.brandConfig.name.slice(1)
    const showBrandedHeader = _.get(this.context.brandConfig, 'secure.hasFeatures.basket.showBrandedHeader', false)

    if (this.props.hasFixedSummary && this.props.endpoint !== 'moreinformation') {
      return (
        <aside className='basket-summary basket-summary-fixed pos-rel mb-3'>
          <div className='affix-container'>
            <AutoAffix
              affixClassName='summary-affix'
              bottomClassName='summary-affix'
              container={document.getElementById('appRoot')}
            >
              <div className='panel panel-default' ref={this.ref}>
                <div
                  aria-controls='summaryCollapse'
                  aria-expanded={this.state.isSummaryElementExpanded}
                  className={`panel-heading ${this.state.isSummaryElementExpanded ? '' : 'collapsed'}`}
                  onClick={this.handleSummaryCollapseToggle}
                  onKeyPress={e => e.key === 'Enter' && this.handleSummaryCollapseToggle()}
                  tabIndex='-1'
                  role='button'>
                  <h2 className='h3 mb-0 mt-0'>
                    {showBrandedHeader
                      ? <FormattedMessage id='common.yourParkBreak' values={{ parkName }} />
                      : <FormattedMessage id='common.bookingSummary' />}
                    <span className='i-plus collapse-icon pull-right visible-xs'>&#43;</span>
                  </h2>
                  <span className='small m-0 visible-xs'>
                    {this.props.totalRedemptionValue
                      ? <React.Fragment>
                        <p className='mb-0'>
                          <FormattedMessage
                            id='payment.vouchersApplied'
                            values={{
                              amount: this.props.totalRedemptionValue
                            }}
                          />
                        </p>
                        <FormattedMessage id='payment.totalPrice' />:&nbsp;
                        <s>
                          <DisplayPrice price={this.props.grossPrice} />
                        </s>
                        &nbsp;<DisplayPrice price={this.props.totalAmountToPay} showZeroAsPrice />
                      </React.Fragment>
                      : <React.Fragment>
                        <FormattedMessage id='payment.totalPrice' />:&nbsp;
                        <DisplayPrice price={this.props.grossPrice} />
                      </React.Fragment>
                    }
                  </span>
                </div>
                <Collapse in={collapseIn}>
                  <div
                    className={`collapse collapse-non-md collapse-non-lg ${this.state.isSummaryElementExpanded ? 'in' : ''}`}
                    id='summaryCollapse'>
                    <div className='mb-3'>
                      {this.props.discountMessage}
                    </div>
                    {this.getBasketContent()}
                  </div>
                </Collapse>
              </div>
            </AutoAffix>
          </div>
        </aside>
      )
    }

    // New Summary Basket - More Information
    if (this.props.hasFixedSummary && this.props.endpoint === 'moreinformation') {
      return (
        <aside className='basket-summary basket-summary-fixed basket-summary-moreinfo pos-rel mb-0'>
          <div className='panel panel-default mb-0'>
            <div className={this.props.reducedInfo ? 'hidden-xs panel-heading' : 'panel-heading'}>
              <h2 className='h3 mb-0 mt-0 hidden-xs'>
                {showBrandedHeader
                  ? <FormattedMessage id='common.yourParkBreak' values={{ parkName }} />
                  : <FormattedMessage id='common.bookingSummary' />}
              </h2>
            </div>
            <div className='hidden-xs'>
              {this.props.discountMessage}
            </div>
            <div className='panel-body py-2 py-sm-3'>
              <ul className={`list-unstyled ${this.props.reducedInfo ? 'hidden-xs' : ''}`}>
                <HotelItem
                  cancellationWaiver={this.props.cancellationWaiver}
                  cateringDescription={this.props.cateringDescription}
                  hasFixedSummary={this.props.hasFixedSummary}
                  hideBasketSVGs={this.props.hideBasketSVGs}
                  hotelEventDescription={this.props.hotelEventDescription}
                  name={this.props.name}
                  roomDescriptions={this.props.roomDescriptions}
                  roomName={this.props.roomName}
                  whatsIncluded={this.props.whatsIncluded}
                />

                <Dates
                  checkInDate={this.props.checkInDate}
                  checkInTime={this.props.checkInTime}
                  checkOutDate={this.props.checkOutDate}
                  checkOutTime={this.props.checkOutTime}
                  hasFixedSummary={this.props.hasFixedSummary}
                  hideBasketSVGs={this.props.hideBasketSVGs}
                  showPriceOnMobile={this.props.showPriceOnMobile}
                  hasYourStay={this.props.hasYourStay}
                />

                <ParkEntry
                  hasFixedSummary={this.props.hasFixedSummary}
                  hasParkEntry={this.props.hasParkEntry}
                  hideBasketSVGs={this.props.hideBasketSVGs}
                  labelParkDate={this.props.labelParkDate}
                  parkDate={this.props.parkDate}
                  reducedInfo={this.props.reducedInfo}
                  ticketDuration={this.props.ticketDuration}
                />

                <EventItem
                  basketAlternativeMessage={this.props.basketAlternativeMessage}
                  category={this.props.category}
                  cateringDescription={this.props.cateringDescription}
                  hasFixedSummary={this.props.hasFixedSummary}
                  hasParkEntry={this.props.hasParkEntry}
                  hideBasketSVGs={this.props.hideBasketSVGs}
                  hotelEventDescription={this.props.hotelEventDescription}
                  packageIncludedVisibleUpgrades={this.props.packageIncludedVisibleUpgrades || []}
                  swappableTicketUpgradeTicket={this.props.swappableTicketUpgradeTicket || {}}
                  showData={this.props.showData}
                  ticketName={this.props.ticketName}
                  venueType={this.props.venueType}
                  whatsIncluded={this.props.whatsIncluded}
                />

                <li className='hidden-xs'>
                  <a
                    {...trackingHelpers.getAttributes('Basket CTA', 'moreinformation', 'Amend Search')}
                    onClick={this.props.toggleEngineModal}
                    onKeyDown={(e) => { e.key === 'Enter' && this.props.toggleEngineModal() }}
                    role='button'
                    tabIndex='0'>
                    <div className='text-right'>
                      <FormattedMessage id='moreInformation.amendSearch' tagName='small' />
                    </div>
                  </a>
                  <hr />
                </li>
              </ul>

              <Total
                grossPrice={this.props.grossPrice}
                reducedInfo={this.props.reducedInfo}
                showRoomsFromPrice={this.props.showRoomsFromPrice}
                showPriceOnMobile={this.props.showPriceOnMobile}
                wasText={this.props.wasText}
              />

              {this.props.button}

              {hasCancellationProtectionMessage && this.props.cancellationWaiverPrice &&
                <Col xs={12} className='small text-center text-success d-inline-block mt-2 mt-sm-3'>
                  <Col xs={9} sm={12} lg={10} className='d-inline-block float-none'>
                    <FormattedMessage id='basket.protectYourBooking' tagName='strong' values={{ cancellationWaiverPrice: this.props.cancellationWaiverPrice }} />
                  </Col>
                </Col>
              }
            </div>

            {(this.props.hasBestPricePromise || this.props.hasBestPriceGuarantee) &&
              <Footer
                bestPriceGuarantee={this.props.hasBestPriceGuarantee}
                bestPriceGuaranteeModal={this.context.brandConfig.bestPricePromise && this.context.brandConfig.bestPricePromise[0]}
                hasFixedSummary={this.props.hasFixedSummary}
                reducedInfo={this.props.reducedInfo}
              />
            }
          </div>
        </aside>
      )
    }

    // Old Summary Basket
    return (
      <aside className={classNames('basket-summary pos-rel', {
        'mb-3': !this.props.reducedInfo,
        'mb-0': this.props.reducedInfo
      })}>
        <div className={classNames('panel panel-default', {
          'mb-0': this.props.reducedInfo
        })}>
          <div className='panel-body'>
            <div className='basket-summary__details list-unstyled'>
              <div>
                <div className={classNames('clearfix', {
                  'col-xs-12 basket-title': this.props.discountMessage,
                  'hidden-xs': this.props.reducedInfo
                })}>
                  <h3 className='mt-0 float-left'>
                    <FormattedMessage id='common.summary' />
                  </h3>
                  {this.props.discountMessage}
                </div>
              </div>

              <div className='clearfix mb-0'>
                {!this.props.hideHotel &&
                  <HotelItem
                    cateringDescription={this.props.cateringDescription}
                    hasFixedSummary={this.props.hasFixedSummary}
                    hideBasketSVGs={this.props.hideBasketSVGs}
                    hotelEventDescription={this.props.hotelEventDescription}
                    name={this.props.name}
                    reducedInfo={this.props.reducedInfo}
                    roomDescriptions={this.props.roomDescriptions}
                    roomName={this.props.roomName}
                    whatsIncluded={this.props.whatsIncluded}
                  />
                }
              </div>

              <hr className={hrClassName} />

              <div className='clearfix mb-0'>
                <EventItem
                  basketAlternativeMessage={this.props.basketAlternativeMessage}
                  category={this.props.category}
                  eventTitle={this.props.eventTitle}
                  hasFixedSummary={this.props.hasFixedSummary}
                  hasParkEntry={this.props.hasParkEntry}
                  hideBasketSVGs={this.props.hideBasketSVGs}
                  hotelEventDescription={this.props.hotelEventDescription}
                  isFlexibleDate={this.props.isFlexibleDate}
                  packageIncludedVisibleUpgrades={this.props.packageIncludedVisibleUpgrades || []}
                  reducedInfo={this.props.reducedInfo}
                  swappableTicketUpgradeTicket={this.props.swappableTicketUpgradeTicket || {}}
                  showData={this.props.showData}
                  venueType={this.props.venueType}
                  whatsIncluded={this.props.whatsIncluded}
                />
              </div>

              {this.props.hasParkEntry &&
                <React.Fragment>
                  <hr className={hrClassName} />
                  <PartyComposition
                    hasFixedSummary={this.props.hasFixedSummary}
                    hideBasketSVGs={this.props.hideBasketSVGs}
                    partyComposition={this.props.partyComposition}
                    reducedInfo={this.props.reducedInfo}
                  />
                </React.Fragment>
              }
              <hr className={hrClassName} />

              <div className='clearfix mb-0'>
                {this.props.hideHotel
                  ? <TicketOnlyDate
                    checkInDate={this.props.checkInDate}
                    checkInTime={this.props.checkInTime}
                    checkOutDate={this.props.checkOutDate}
                    checkOutTime={this.props.checkOutTime}
                    hasFixedSummary={this.props.hasFixedSummary}
                    hideBasketSVGs={this.props.hideBasketSVGs}
                    reducedInfo={this.props.reducedInfo}
                  />
                  : <Dates
                    checkInDate={this.props.checkInDate}
                    checkInTime={this.props.checkInTime}
                    checkOutDate={this.props.checkOutDate}
                    checkOutTime={this.props.checkOutTime}
                    hasFixedSummary={this.props.hasFixedSummary}
                    hideBasketSVGs={this.props.hideBasketSVGs}
                    reducedInfo={this.props.reducedInfo}
                    showPriceOnMobile={this.props.showPriceOnMobile}
                    hasYourStay={this.props.hasYourStay}
                  />
                }
              </div>

              {this.props.endpoint === 'moreinformation' &&
                <React.Fragment>
                  <hr className={hrClassName} />
                  <div className='clearfix mb-0'>
                    <a
                      {...trackingHelpers.getAttributes('Basket CTA', 'moreinformation', 'Amend Search')}
                      onClick={this.props.toggleEngineModal}>
                      <div className='text-right hidden-xs'>
                        <FormattedMessage id='moreInformation.amendSearch' tagName='small' />
                      </div>
                    </a>
                  </div>
                </React.Fragment>
              }

              {this.upgradesToDisplay.length > 0 && this.props.showUpgradeSummary &&
                <div className='clearfix mb-0'>
                  <hr />
                  <UpgradeSummary
                    cancellationWaiver={this.props.cancellationWaiver}
                    extraNights={this.props.extraNights}
                    handleExtraNightCTA={this.props.handleExtraNightCTA}
                    handleUpgradeCTA={this.props.handleUpgradeCTA}
                    hasFixedSummary={this.props.hasFixedSummary}
                    hideBasketSVGs={this.props.hideBasketSVGs}
                    postalConfirmation={this.props.postalConfirmation}
                    summaryTitle={this.props.summaryTitle}
                    upgrades={this.upgradesToDisplay}
                  />
                </div>
              }

              <hr className={hrClassName} />

              {this.props.endpoint === 'payment' ? (
                <React.Fragment>
                  {this.context.brandConfig.secure.removeCreditCardSurcharge
                    ? (
                      <Total isPaymentStage />
                    )
                    : (
                      <TotalWithSurcharge
                        creditCardSurcharge={this.context.brandConfig.creditCardSurcharge}
                        grossPrice={this.props.grossPrice}
                        hasFixedSummary={this.props.hasFixedSummary}
                        standardPrice={this.props.standardPrice}
                      />
                    )
                  }
                </React.Fragment>
              ) : (
                <div className='mb-0'>
                  <Total
                    grossPrice={this.props.grossPrice}
                    reducedInfo={this.props.reducedInfo}
                    showPriceOnMobile={this.props.showPriceOnMobile}
                  />
                </div>
              )}

              {this.props.button}
            </div>
          </div>
          {(this.props.hasBestPricePromise || this.props.hasBestPriceGuarantee) &&
          <Footer
            bestPriceGuarantee={this.props.hasBestPriceGuarantee}
            bestPriceGuaranteeModal={this.context.brandConfig.bestPricePromise && this.context.brandConfig.bestPricePromise[0]}
            reducedInfo={this.props.reducedInfo}
          />}
        </div>
      </aside>
    )
  }
}

Basket.contextType = BrandContext

Basket.defaultProps = {
  hasFixedSummary: false,
  reducedInfo: false
}

Basket.propTypes = {
  button: PropTypes.element,
  discountMessage: PropTypes.oneOfType([
    PropTypes.element,
    PropTypes.string
  ]),
  grossPrice: PropTypes.number.isRequired,
  hasBestPricePromise: PropTypes.bool,
  hasFixedSummary: PropTypes.bool,
  isSummaryElementExpanded: PropTypes.bool
}

Basket.SummaryDiscountMessage = SummaryDiscountMessage

function mapStateToProps (state) {
  const {
    hasYourStay,
    isFlexibleDate,
    labelParkDate,
    parkDate,
    ticketDuration
  } = state.searchSummary
  return {
    hasYourStay,
    isFlexibleDate,
    labelParkDate,
    parkDate,
    ticketDuration,
    totalAmountToPay: getTotalAmountToPay(state),
    totalRedemptionValue: getTotalRedemptionValue(state)
  }
}

export default connect(mapStateToProps)(Basket)
