import React, { useContext } from 'react'
import { Checkbox, Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import payment from '../../actions/payment'
import BrandContext from '../../contexts/BrandContext'
import trackingHelpers from '../../helpers/trackingHelpers'

const DTMFFields = ({
  applyCreditFees,
  dtmfCardExpiryDate,
  dtmfSecurityCode,
  errors = {},
  onInputChange,
  paymentChoice
}) => {
  const context = useContext(BrandContext)
  return (
    <React.Fragment>
      <FormGroup className={paymentChoice === 'dtmf' ? null : 'hidden'}>
        <Col sm={3}>
          <ControlLabel htmlFor='dtmfCardExpiryDate'>
            <FormattedMessage id='payment.expiryDate' />
          </ControlLabel>
        </Col>

        <Col sm={3} className={errors.dtmfCardExpiryDate ? 'has-error' : ''}>
          <FormControl
            {...trackingHelpers.getAttributes('expiry date', 'payment', '1')}
            id='dtmfCardExpiryDate'
            maxLength='7'
            name='card_expiry_date'
            onChange={(e) => onInputChange(e.target.id, e.target.value)}
            placeholder='MM/YY'
            value={dtmfCardExpiryDate} />
          {errors.dtmfCardExpiryDate &&
            <HelpBlock className='pull-right'>
              {errors.dtmfCardExpiryDate}
            </HelpBlock>
          }
        </Col>

        <Col sm={2} className='gutter-top-xs'>
          <ControlLabel htmlFor='dtmfSecurityCode'>
            <FormattedMessage id='payment.securityCode' />
          </ControlLabel>
        </Col>

        <Col sm={3} className={errors.dtmfSecurityCode ? 'has-error' : ''}>
          <FormControl
            {...trackingHelpers.getAttributes('security code', 'payment', '1')}
            id='dtmfSecurityCode'
            maxLength='4'
            name='card_security_code'
            onChange={(e) => onInputChange(e.target.id, e.target.value)}
            value={dtmfSecurityCode} />

          {errors.dtmfSecurityCode &&
            <HelpBlock className='pull-right'>
              {errors.dtmfSecurityCode}
            </HelpBlock>
          }
        </Col>
      </FormGroup>

      <FormGroup className={(paymentChoice === 'skip' && !_.get(context, 'brandConfig.secure.removeCreditCardSurcharge', false)) ? null : 'hidden'}>
        <Col xs={6} sm={8} smOffset={3}>
          <Checkbox
            checked={applyCreditFees}
            id='applyCreditFees'
            name='apply_credit_fees'
            onChange={(e) => onInputChange(e.target.id, e.target.checked)}>
            <FormattedMessage id='payment.applyCreditCardFees' />
          </Checkbox>
        </Col>
      </FormGroup>

      <FormGroup className={paymentChoice === 'skip' ? null : 'hidden'}>
        <Col sm={3}>
          <ControlLabel className='gutter-top-xs' htmlFor='adminPassword'>
            <FormattedMessage id='common.password' />
          </ControlLabel>
        </Col>

        <Col xs={6} sm={8} className={errors.adminPassword ? 'has-error' : ''}>
          <FormControl
            id='adminPassword'
            name='admin_password'
            onChange={(e) => onInputChange(e.target.id, e.target.value)}
            type='password' />
          {errors.adminPassword &&
            <HelpBlock className='pull-right'>
              {errors.adminPassword}
            </HelpBlock>
          }
        </Col>
      </FormGroup>
    </React.Fragment>
  )
}

function mapStateToProps (state) {
  const {
    billingDetails = {},
    errors
  } = state.payment
  const {
    applyCreditFees,
    dtmfCardExpiryDate,
    dtmfSecurityCode,
    paymentChoice
  } = billingDetails
  return {
    applyCreditFees,
    dtmfCardExpiryDate,
    dtmfSecurityCode,
    errors,
    paymentChoice
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onInputChange: (id, value) => dispatch(payment.inputChange(id, value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(DTMFFields)
export {
  DTMFFields
}
