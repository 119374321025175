import _ from 'lodash'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import DisplayPrice from '../../atoms/DisplayPrice'
import ExtraNightSummary from '../../atoms/ExtraNightSummary'
import SVGTick from '../../atoms/SVGTick'

import trackingHelpers from '../../../helpers/trackingHelpers'

class UpgradeSummary extends PureComponent {
  render () {
    if (!this.props.hasFixedSummary) {
      const upgrades = (this.props.upgrades || []).map(upgrade =>
        <li key={`addedUpgrade${upgrade.id}`} className='row no-margin-padding mb-2'>
          <div className='col-sm-9 pull-left no-margin-padding'>
            <div>
              <strong>{upgrade.title}</strong>
            </div>
            {(upgrade.showUpgradeDate === 'true' || upgrade.showUpgradeDate === true) && upgrade.isAvailableForAllSelectedDates !== true && !upgrade.composition.quantity &&
              <div>
                <small className='text-muted'>{moment(upgrade.selectedDate).format('ddd DD MMM YY')}</small>
              </div>
            }
            {upgrade.composition.quantity && !upgrade.isRoomBased &&
              <div>
                <small className='text-muted'><FormattedMessage id='common.quantity' />:&nbsp;{upgrade.composition.quantity}</small>
              </div>
            }
          </div>
          <div className='col-sm-3 pull-right no-margin-padding'>
            <div className='basket-upgrade-price text-right'>
              <strong>
                <DisplayPrice price={upgrade.totalPrice || upgrade.price} />
              </strong>
            </div>
            {this.props.handleUpgradeCTA && !upgrade.isIncludedInPackage &&
              <div className='text-right'>
                <a className='small'
                  {...trackingHelpers.getAttributes('Remove Upgrade From Cart', 'Upgrades', upgrade.id)}
                  role='button'
                  onClick={this.props.handleUpgradeCTA.bind(this, upgrade.selectedProductId)}
                  onKeyDown={(e) => e.key === 'Enter' && this.props.handleUpgradeCTA.bind(this, upgrade.selectedProductId)}>
                  <FormattedMessage id='common.remove' />
                </a>
              </div>
            }
          </div>
        </li>
      )

      return (
        <div className='basket-upgrade-summary'>
          {!this.props.hideBasketSVGs &&
            <div className='col-xs-2 pl-0 pr-0'>
              <SVGTick
                height='30'
                width='30'
                viewBox='0 0 73 73'
              />
            </div>
          }
          <dl className={`col-xs-${this.props.hideBasketSVGs ? '12' : '10'} mb-0 pl-0 flex-align_center`}>
            <dt className='h5'>{this.props.summaryTitle}</dt>
            <dd className='basket-upgrade-summary__details'>
              <ul className='list-unstyled'>
                {upgrades}

                {this.props.cancellationWaiver &&
                  <li className='row no-margin-padding mb-2'>
                    <div className='col-sm-9 pull-left no-margin-padding'>
                      <strong>
                        <FormattedMessage id='common.cancellationWaiver' />
                      </strong>
                    </div>
                    <div className='col-sm-3 pull-right no-margin-padding'>
                      <div className='basket-upgrade-price text-right'>
                        <strong>
                          <DisplayPrice price={this.props.cancellationWaiver.grossPrice} />
                        </strong>
                      </div>
                    </div>
                  </li>
                }

                {this.props.postalConfirmation &&
                  <li className='row no-margin-padding'>
                    <div className='col-sm-9 pull-left no-margin-padding'>
                      <strong>
                        <FormattedMessage id='common.postalConfirmation' />
                      </strong>
                    </div>
                    <div className='col-sm-3 pull-right no-margin-padding'>
                      <div className='basket-upgrade-price text-right'>
                        <strong>
                          <DisplayPrice price={this.props.postalConfirmation.grossPrice} />
                        </strong>
                      </div>
                    </div>
                  </li>
                }

                {_.get(this.props, 'extraNights.before.isInBasket', false) &&
                  <li>
                    <ExtraNightSummary
                      data={this.props.extraNights.before}
                      handleExtraNightCTA={this.props.handleExtraNightCTA}
                      isNightBefore
                      removeBtnTrackingAttrs={trackingHelpers.getAttributes('Remove Upgrade From Cart', 'Upgrades', 'Extra Night At Start')}
                    />
                  </li>
                }
                {_.get(this.props, 'extraNights.after.isInBasket', false) &&
                  <li>
                    <ExtraNightSummary
                      data={this.props.extraNights.after}
                      handleExtraNightCTA={this.props.handleExtraNightCTA}
                      isNightBefore={false}
                      removeBtnTrackingAttrs={trackingHelpers.getAttributes('Remove Upgrade From Cart', 'Upgrades', 'Extra Night At End')}
                    />
                  </li>
                }
              </ul>
            </dd>
          </dl>
        </div>
      )
    } else {
      const upgrades = (this.props.upgrades || []).map(upgrade =>
        <li key={`addedUpgrade${upgrade.id}`} className='text-muted tiny row mb-2'>
          <div className='col-xs-8'>
            {upgrade.title}
            {!upgrade.composition.quantity && (upgrade.showUpgradeDate === 'true' || upgrade.showUpgradeDate === true) &&
              <React.Fragment>
                <br />
                {moment(upgrade.selectedDate).format('ddd DD MMM YY')}
              </React.Fragment>
            }
            {upgrade.composition.quantity && !upgrade.isRoomBased &&
              <React.Fragment>
                <br />
                <FormattedMessage id='common.quantity' />
                :&nbsp;{upgrade.composition.quantity}
              </React.Fragment>
            }
          </div>
          <div className='col-xs-4 text-right'>
            <DisplayPrice price={upgrade.totalPrice || upgrade.price} />
            <br />
            {this.props.handleUpgradeCTA && !upgrade.isIncludedInPackage &&
              <a
                {...trackingHelpers.getAttributes('Remove Upgrade From Cart', 'Upgrades', upgrade.id)}
                role='button'
                onClick={this.props.handleUpgradeCTA.bind(this, upgrade.selectedProductId)}
                onKeyDown={(e) => e.key === 'Enter' && this.props.handleUpgradeCTA.bind(this, upgrade.selectedProductId)}>
                <FormattedMessage id='common.remove' />
              </a>
            }
          </div>
        </li>
      )

      return (
        <li>
          <div className='media'>
            <div className='media-left'>
              {!this.props.hideBasketSVGs &&
                <div className='col-xs-2 pl-0 pr-0'>
                  <SVGTick
                    height='30'
                    width='30'
                    viewBox='0 0 73 73'
                  />
                </div>
              }
            </div>
            <div className='media-body'>
              <div className='media-heading'>
                <FormattedMessage id='common.extras' />
              </div>
              <ul className='list-unstyled'>
                {upgrades}
              </ul>

              {this.props.cancellationWaiver &&
                <div className='text-muted tiny row mb-2'>
                  <div className='col-xs-8'>
                    <FormattedMessage id='common.cancellationWaiver' />
                  </div>
                  <div className='col-xs-4 text-right'>
                    <DisplayPrice price={this.props.cancellationWaiver.grossPrice} />
                  </div>
                </div>
              }

              {this.props.postalConfirmation &&
                <div className='text-muted tiny row mb-2'>
                  <div className='col-xs-8'>
                    <FormattedMessage id='common.postalConfirmation' />
                  </div>
                  <div className='col-xs-4 text-right'>
                    <DisplayPrice price={this.props.postalConfirmation.grossPrice} />
                  </div>
                </div>
              }

              {_.get(this.props, 'extraNights.before.isInBasket', false) &&
                <ExtraNightSummary
                  data={this.props.extraNights.before}
                  handleExtraNightCTA={this.props.handleExtraNightCTA}
                  hasFixedSummary={this.props.hasFixedSummary}
                  isNightBefore
                  removeBtnTrackingAttrs={trackingHelpers.getAttributes('Remove Upgrade From Cart', 'Upgrades', 'Extra Night At Start')}
                />
              }
              {_.get(this.props, 'extraNights.after.isInBasket', false) &&
                <ExtraNightSummary
                  data={this.props.extraNights.after}
                  handleExtraNightCTA={this.props.handleExtraNightCTA}
                  hasFixedSummary={this.props.hasFixedSummary}
                  isNightBefore={false}
                  removeBtnTrackingAttrs={trackingHelpers.getAttributes('Remove Upgrade From Cart', 'Upgrades', 'Extra Night At End')}
                />
              }
            </div>
          </div>
          <hr />
        </li>
      )
    }
  }
}

UpgradeSummary.propTypes = {
  extraNights: PropTypes.shape({
    after: PropTypes.shape({
      isInBasket: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    before: PropTypes.shape({
      isInBasket: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  }),
  handleExtraNightCTA: PropTypes.func,
  handleUpgradeCTA: PropTypes.func,
  hasFixedSummary: PropTypes.bool.isRequired,
  hideBasketSVGs: PropTypes.bool,
  summaryTitle: PropTypes.string.isRequired,
  upgrades: PropTypes.array
}

export default UpgradeSummary
