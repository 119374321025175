const filtersHelpers = {}

filtersHelpers.getFilters = (master) => {
  let price = []
  let distance = []
  let propertyType = []
  let starRating = []
  let popularOptions = []
  let familyFeatures = []
  let hotelFeatures = []
  let uniquePopularOptions = []
  let uniqueFamilyFeatures = []
  let uniqueHotelFeatures = []
  let onsite = false
  const uspOptions = [
    'hasBreakfastIncluded',
    'hasFreeParking',
    'hasFreeWifi',
    'hasRestaurant',
    'hasSwimmingPool'
  ]
  const family = [
    'hasCookiesAndMilk',
    'hasGoodieBag',
    'hasKidsDining',
    'hasKidsPlayArea'
  ]
  const removeOptions = [
    'isInfantFriendly'
  ]
  const unique = (value, index, self) => {
    return self.indexOf(value) === index
  }
  master.forEach(hotel => {
    if (!isNaN(hotel.grossPrice)) price.push(Math.ceil(hotel.grossPrice))
    if (!isNaN(hotel.milesToPark)) distance.push(hotel.milesToPark)
    if (hotel.accommodationType) propertyType.push(hotel.accommodationType)
    if (parseInt(hotel.starRating) > 0) starRating.push(parseInt(hotel.starRating))
    const facilityKeys = Object.keys(hotel.facilities || {})
    facilityKeys.map(facility => {
      if (hotel.facilities[facility]) {
        if (facility && removeOptions.indexOf(facility) > -1) {
          return false
        } else if (facility && uspOptions.indexOf(facility) > -1) {
          popularOptions.push(facility)
        } else if (facility && family.indexOf(facility) > -1) {
          familyFeatures.push(facility)
        } else {
          hotelFeatures.push(facility)
        }
      }
    })
  })
  popularOptions = popularOptions.filter(unique).sort()
  popularOptions.map(facility => {
    uniquePopularOptions.push({
      text: filtersHelpers.sentenceCase(facility.replace('has', '')),
      value: facility
    })
  })
  familyFeatures = familyFeatures.filter(unique).sort()
  familyFeatures.map(facility => {
    uniqueFamilyFeatures.push({
      text: filtersHelpers.sentenceCase(facility.replace('has', '')),
      value: facility
    })
  })
  hotelFeatures = hotelFeatures.filter(unique).sort()
  hotelFeatures.map(feature => {
    uniqueHotelFeatures.push({
      text: filtersHelpers.sentenceCase(feature.replace('has', '')),
      value: feature
    })
  })
  price.sort((a, b) => a - b)
  distance.sort((a, b) => a - b)
  if (distance[0] <= 0) {
    onsite = true
  }
  return {
    price: {
      low: price[0],
      high: price[price.length - 1]
    },
    distance: {
      onsite,
      closest: distance[0],
      furthest: distance[distance.length - 1]
    },
    propertyType: propertyType.filter(unique).sort(),
    starRating: starRating.filter(unique).sort((a, b) => b - a),
    popularOptions: uniquePopularOptions,
    familyFeatures: uniqueFamilyFeatures,
    hotelFeatures: uniqueHotelFeatures.slice(0, 3),
    hotelFeaturesMore: uniqueHotelFeatures.slice(3)
  }
}

filtersHelpers.getUpdated = hotels => {
  let optionTotals = {}
  hotels.filter(hotel => {
    const facilityKeys = Object.keys(hotel.facilities)
    facilityKeys.map(facility => {
      if (hotel.facilities[facility]) {
        if (!optionTotals[facility]) {
          optionTotals[facility] = {
            counter: 1
          }
        } else {
          optionTotals[facility].counter++
        }
      }
    })
  })
  return {
    optionTotals
  }
}

filtersHelpers.sentenceCase = value => {
  const result = value.replace(/([A-Z])/g, ' $1')
  return result.charAt(0).toUpperCase() + result.slice(1)
}

export default filtersHelpers
