import _ from 'lodash'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import classNames from 'classnames'
import { Button, Modal } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import Rating from '../atoms/Rating'
import ResponsiveImage from '../atoms/ResponsiveImage'

import Hotel from '../molecules/Hotel'
import DefaultBasket from './DefaultBasket'
import HotelInformation from './HotelInformation'

import routeHelpers from '../../helpers/routeHelpers'
import basketHelpers from '../../helpers/basketHelpers'
import resourceHelpers from '../../helpers/resourceHelpers'

import roomTypes from '../../configs/roomTypes'
import accommodationTypes from '../../configs/accommodationTypes'

import SplitTest from '../../containers/SplitTest'

class HotelList extends Component {
  constructor (props) {
    super(props)

    this.hotelEventProducts = _.get(window.packageRatesReply, 'linked.hotelEventProducts', {})
    this.closeModal = this.closeModal.bind(this)
  }

  closeModal () {
    this.props.handleSetProductModal(null, false)

    // removes the hash string for when more info modal is closed
    // we need to keep something within the hash or it navigates to the top of the page on each modal close, hence the underscore
    routeHelpers.setHash('_')
  }

  hotelOutput (hotel) {
    // if we don't have an id, things break
    if (!hotel || !hotel.id) return null
    // find hotel specific inventory messages

    // These are carousel images, take the filename of large image and build
    const images = _.get(hotel, 'images.large', [])
    const carouselImages = images.map((carouselImage) => {
      if (!carouselImage) return null

      // Pick out the image filename without extension
      const match = carouselImage.match(/large\/([^.]+)\.jpg/)

      // No match, return
      if (!match || !match[1]) return null
      return { src: resourceHelpers.generateImagePaths(this.props.imageBasePath, 'hotel/', match[1], `${hotel.id}/`, '16-9/') }
    })
    const fromText = hotel.isResort && !hotel.isRoom && hotel.hasMoreThanOneRoom ? 'from ' : ''

    const {
      hasBreakfastIncluded = false,
      hasFreeParking = false,
      hasFreeWifi = false,
      hasRestaurant = false,
      hasSpecialOffer = false,
      hasSwimmingPool = false,
      hasVideo = false,
      isCotConfirmationRequired = false
    } = hotel.facilities || {}

    const messages = (this.props.inventoryLevelMessages || []).find(msg => msg[hotel.id])
    const inventoryLevelMessages = messages ? messages[hotel.id] : this.props.inventoryLevelMessages
    const accommodationType = accommodationTypes[(hotel.accommodationType || '').toLowerCase()] || accommodationTypes['hotel']
    const name = hotel.isRoom ? hotel.roomName : hotel.name

    let packageRatesByHotel = null
    let { packageTotal, standardPrice } = hotel

    if (this.props.packageRatesByHotel) {
      // get the packageRates for this hotel
      packageRatesByHotel = this.props.packageRatesByHotel[hotel.id]

      let chosenPackageRate = null
      if (packageRatesByHotel) {
        if (hotel.isRoom) {
          // if this is a hotel room, we need to find the packageRate for the hotel in which this room is linked
          chosenPackageRate = packageRatesByHotel.find(packageRate => packageRate.links.roomProducts.ids[0] === hotel.roomId)
        } else if (hotel.isResort) {
          const filteredPackageRates = _.filter(packageRatesByHotel, { isExtraNight: (hotel.isExtraNight || false) })
          // get the cheapest packageRate in the hotel (in case of multiple packages per hotel e,g. Resort hotels)
          chosenPackageRate = _.minBy(filteredPackageRates, 'grossPrice')
        }
        // otherwise if we're a resort (and not a room) we should set our "From" price to the cheapest packageRate
        // we should use the standardPrice of the same packageRate, so we show an accurate discount
        if (typeof chosenPackageRate.grossPrice === 'number') {
          packageTotal = chosenPackageRate.grossPrice
        }
        if (typeof chosenPackageRate.standardPrice === 'number') {
          standardPrice = chosenPackageRate.standardPrice
        }
      }
    }

    const mediumImage = _.get(hotel, 'images.medium[0]', null)
    const match = mediumImage ? mediumImage.match(/medium\/([^.]+)\.jpg/) : null
    let primaryImage = null
    if (match && match[1]) {
      primaryImage = resourceHelpers.generateImagePaths(this.props.imageBasePath, 'hotel/', match[1], `${hotel.id}/`, '16-9/') + this.props.sbImageBucketSize
    }

    const { checkinTime, checkoutTime } = this.hotelEventProducts[`${hotel.id}_${hotel.ticket.id}`] || {}
    const hotelTimes = {
      checkinTime: checkinTime || hotel.checkinTime,
      checkoutTime: checkoutTime || hotel.checkoutTime
    }

    let hotelModal = null
    if ((!hotel.isRoom && this.props.modalState.visible === hotel.id) ||
        (hotel.isRoom && this.props.modalState.visible === hotel.roomId)) {
      // Here is where we create the modal

      const eventName = (<span className='event-name'>{hotel.ticket.name}</span>)
      const eventItemName = this.props.hasParkEntry ? <div><strong>Ticket: </strong>{eventName}</div> : eventName

      const modalBasket = (
        <DefaultBasket headerImageUrl={primaryImage}>
          <DefaultBasket.HotelItem
            accommodationType={accommodationType}
            checkInDate={hotel.checkInDate}
            checkInTime={hotelTimes.checkinTime}
            checkOutDate={hotel.checkOutDate}
            checkOutTime={hotelTimes.checkoutTime}
            name={hotel.name}
            roomDescriptions={basketHelpers.getRoomsDescriptions(roomTypes, this.props.rooms)}
            roomName={hotel.roomName}
            starRating={Number(hotel.starRating)}
            stayDurationInDays={Number(hotel.nights)}
          />
          <DefaultBasket.EventItem
            dates={this.props.hasParkEntry ? this.props.eventDates : null}
            name={eventItemName}
            partyComposition={this.props.ticketComposition}
            title={`${this.props.venueType} Information`}
          />
          <DefaultBasket.Summary
            cateringDescription={hasBreakfastIncluded ? hotel.breakfastType : null}
            eventName={!this.props.hasParkEntry ? hotel.ticket.name : ''}
            hotelName={hotel.name}
          />
        </DefaultBasket>
      )

      hotelModal = (
        <Modal
          bsSize='large'
          className='modal--xl modal--product-information'
          dialogClassName='modal-xl'
          key={`hotel-modal-${hotel.id}`}
          onEnter={this.props.getReviews.bind(this, hotel.reviewsUrl)}
          onHide={this.closeModal}
          show>
          <Modal.Header closeButton>
            <Modal.Title>
              <span>{name}</span>
              <Rating rating={hotel.starRating} className='stars--inline' />
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <HotelInformation
              address0={hotel.addressLine0}
              address1={hotel.addressLine1}
              address2={hotel.addressLine2}
              addressLocality={hotel.addressLocality}
              addressRegion={hotel.addressRegion}
              basket={modalBasket}
              childFacilities={hotel.childFacilities}
              defaultRoomDescriptions={hotel.defaultRoomDescriptions}
              distance={hotel.milesToPark}
              fromText={fromText}
              grossPrice={hotel.grossPrice}
              handleSetProductModal={this.props.handleSetProductModal}
              handleAddProduct={this.props.handleAddProduct}
              hasBreakfastIncluded={hasBreakfastIncluded}
              hasFeatures={this.props.hasFeatures}
              hasFreeParking={hasFreeParking}
              hasParkEntry={this.props.hasParkEntry}
              hotel={hotel}
              hotelFacilities={hotel.hotelFacilities}
              id={hotel.id}
              images={carouselImages}
              importantInformation={hotel.importantInformation}
              isCotConfirmationRequired={isCotConfirmationRequired && hotel.rooms.filter(room => room.infants > 0).length > 0}
              isResort={hotel.isResort}
              isRoom={hotel.isRoom}
              isVideoAutoplay={this.props.isVideoAutoplay}
              leisureFacilities={hotel.leisureFacilities}
              latitude={hotel.latitude}
              longitude={hotel.longitude}
              modalState={this.props.modalState}
              name={name}
              packageInfo={this.props.packageInfo}
              packageTotal={packageTotal}
              perPersonPrice={hotel.perPersonPrice}
              postalCode={hotel.postalCode}
              restaurantFacilities={hotel.restaurantFacilities}
              reviews={this.props.reviews}
              roomFacilities={hotel.roomFacilities}
              roomId={hotel.roomId}
              roomInformationList={hotel.room_information_list}
              rooms={hotel.rooms}
              telephone={hotel.telephone}
              videoId={hotel.videoId}
              venueType={this.props.venueType}
              venueProducts={this.props.venueProducts}
            />
          </Modal.Body>
        </Modal>
      )
    }

    const hotelProps = {
      ...hotel,
      brand: this.props.brand,
      carouselImages,
      distance: hotel.milesToPark,
      eventMessaging: this.props.eventMessaging,
      featuredHotel: this.props.featuredHotel === hotel.id,
      fromText,
      getSessionBasketData: this.props.getSessionBasketData,
      handleAddProduct: this.props.handleAddProduct,
      handleSetProductModal: this.props.handleSetProductModal,
      hasBreakfastIncluded,
      hasFeatures: this.props.hasFeatures,
      hasFreeParking,
      hasFreeWifi,
      hasMoreInformationStage: this.props.hasMoreInformationStage,
      hasRestaurant,
      hasSpecialOffer,
      hasSwimmingPool,
      hasVideo,
      hotel,
      hasParkEntry: this.props.hasParkEntry,
      image: primaryImage,
      imageBasePath: this.props.imageBasePath,
      isAnnualPass: this.props.isAnnualPass,
      inventoryLevelMessages,
      isTrainStationPreferred: this.props.isTrainStationPreferred,
      modalState: this.props.modalState,
      name,
      packageInfo: this.props.packageInfo,
      packageTotal,
      reviews: this.props.reviews,
      standardPrice,
      ticketName: hotel.ticket.name,
      whatsIncludedOnEventDate: _.get(hotel, 'ticket.whatsIncludedOnEventDate.all', ''),
      venueType: this.props.venueType,
      videoId: hotel.videoId
    }

    const { hasHotelMoreInfoModal } = this.props.hasFeatures.availability

    if (hasHotelMoreInfoModal) {
      return (
        <SplitTest.Experiment name='EXP-020:AvailabilityMoreInfoModalButton'>
          <SplitTest.Variant name='show_original'>
            <div className='product product-item'>
              <Hotel {...hotelProps} />
              {hotelModal}
            </div>
          </SplitTest.Variant>
          <SplitTest.Variant name='show_alternative'>
            <div className='product product-item'>
              <Hotel {...hotelProps} linkToModal={hasHotelMoreInfoModal} />
              {hotelModal}
            </div>
          </SplitTest.Variant>
        </SplitTest.Experiment>
      )
    }

    return (
      <div className='product product-item'>
        <Hotel {...hotelProps} />
      </div>
    )
  }

  render () {
    if (!this.props.hotels) return null

    // If we have a hotelCode in url params feature the hotel at top of list
    let featuredHotel = null
    if (this.props.featuredHotel) {
      const featuredIndex = _.findIndex(this.props.hotels, (hotel) => {
        return hotel.id === this.props.featuredHotel
      })
      if (featuredIndex >= 0) {
        featuredHotel = this.props.hotels[featuredIndex]
        this.props.hotels.splice(featuredIndex, 1)
        this.props.hotels.unshift(featuredHotel)
      }
    }

    const hotelsOutput = this.props.hotels.map((hotel, index) => {
      if (!hotel || !hotel.id) return false

      const hotelOutput = this.hotelOutput(hotel)

      // Is this the hotel clicked on from the SEO page?
      const featuredHotel = this.props.featuredHotel === hotel.id

      const productItemClasses = classNames({
        'product-item product-multi-option': true,
        'hotel-of-the-month': hotel.isHotelOfTheMonth,
        'featured-hotel': featuredHotel
      })

      return (
        <li className={productItemClasses} key={`hotel-${hotel.id}-${index}`}>
          {hotelOutput}
        </li>
      )
    })
    let uspModal = null

    if (this.props.visibleModals.uspModal) {
      uspModal = (
        <Modal
          bsSize='sm'
          keyboard
          onHide={() => this.props.onHideModal('USPModal')}
          show>
          <Modal.Header closeButton>
            <Modal.Title>
              {this.props.modalContent.modalTitle}
            </Modal.Title>
          </Modal.Header>
          <ResponsiveImage
            defaultSize={300}
            lazyload={false}
            src={`${this.props.imageBasePath}${this.props.modalContent.modalImage}`}
          />
          <Modal.Body dangerouslySetInnerHTML={{ __html: this.props.modalContent.modalDescription }} />
          <Modal.Footer>
            <Button onClick={() => this.props.onHideModal('USPModal')}>
              <FormattedMessage id='common.close' />
            </Button>
          </Modal.Footer>
        </Modal>
      )
    }

    return (
      <ul id={this.props.listType} data-automated-test={this.props.listType} className='list-unstyled products block-sm'>
        {hotelsOutput}
        {uspModal}
      </ul>
    )
  }
}

HotelList.propTypes = {
  brand: PropTypes.string.isRequired,
  eventDates: PropTypes.element,
  featuredHotel: PropTypes.string,
  getReviews: PropTypes.func.isRequired,
  handleAddProduct: PropTypes.func.isRequired,
  handleSetProductModal: PropTypes.func.isRequired,
  hasFeatures: PropTypes.object.isRequired,
  hasMoreInformationStage: PropTypes.bool.isRequired,
  hotels: PropTypes.arrayOf(
    PropTypes.shape({
      adults: PropTypes.number.isRequired,
      children: PropTypes.number.isRequired,
      discountMessage: PropTypes.string,
      distance: PropTypes.number,
      facilities: PropTypes.shape({
        hasBreakfastIncluded: PropTypes.bool,
        hasFreeParking: PropTypes.bool,
        hasFreeWifi: PropTypes.bool,
        hasRestaurant: PropTypes.bool,
        hasSpecialOffer: PropTypes.string,
        hasSwimmingPool: PropTypes.bool
      }),
      grossPrice: PropTypes.number.isRequired,
      hasParkEntry: PropTypes.bool,
      id: PropTypes.string.isRequired,
      images: PropTypes.shape({
        medium: PropTypes.array,
        large: PropTypes.array
      }),
      infants: PropTypes.number.isRequired,
      isCotConfirmationRequired: PropTypes.bool.isRequired,
      isResort: PropTypes.bool,
      latitude: PropTypes.number,
      longitude: PropTypes.number,
      name: PropTypes.string,
      nights: PropTypes.number.isRequired,
      packageTotal: PropTypes.number,
      perPersonPrice: PropTypes.number,
      standardPrice: PropTypes.number,
      starRating: PropTypes.number.isRequired,
      telephone: PropTypes.string.isRequired,
      ticketRates: PropTypes.shape({
        adults: PropTypes.number.isRequired,
        children: PropTypes.number.isRequired,
        endDate: PropTypes.string.isRequired,
        infants: PropTypes.number.isRequired,
        startDate: PropTypes.string.isRequired
      }),
      uniqueSellingPoints: PropTypes.array
    })
  ).isRequired,
  inventoryLevelMessages: PropTypes.array,
  isAnnualPass: PropTypes.bool,
  isTrainStationPreferred: PropTypes.bool,
  isVideoAutoplay: PropTypes.bool.isRequired,
  listType: PropTypes.string.isRequired,
  reviews: PropTypes.array.isRequired,
  ticketComposition: PropTypes.string.isRequired,
  venueType: PropTypes.string
}

HotelList.defaultProps = {
  listType: 'hotel-list'
}

export default HotelList
