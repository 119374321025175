import React, { PureComponent } from 'react'

class PageContent extends PureComponent {
  render () {
    return (
      <div className='panel panel-default'>
        <div className='panel-body text-center'>
          <div className='row'>
            <div className='col-sm-10 col-sm-offset-1 col-lg-10 col-lg-offset-1'>
              <div className='page-header text-center'>
                <p className='lead'><i><strong>Official Short Break Partner of Harry Potter and the Cursed Child</strong></i></p>
              </div>

              <hr className='hr-brand' />

              <p><strong>Based on an original new story by J.K. Rowling, Jack Thorne and John Tiffany, <i>Harry Potter and the Cursed Child</i> is a new play by Jack Thorne. It is the eighth story in the Harry Potter series and the first official Harry Potter story to be presented on stage.</strong></p>
              <p>It was always difficult being Harry Potter and it isn’t much easier now that he is an overworked employee of the Ministry of Magic, a husband and father of three school-age children.</p>
              <p>While Harry grapples with a past that refuses to stay where it belongs, his youngest son Albus must struggle with the weight of a family legacy he never wanted. As past and present fuse ominously, both father and son learn the uncomfortable truth: sometimes, darkness comes from unexpected places.</p>
              <h3>Package Information</h3>
              <p><i>Harry Potter and the Cursed Child</i> is one play presented in two parts, which are intended to be seen in order on the same day (matinee and evening) or on two consecutive evenings.</p>
              <p><strong>All Hotel Packages will include the best available seats for Part One and Part Two.</strong></p>
              <p>On Wednesdays and Saturdays there will be a matinee performance of Part One at 2pm and an evening performance of Part Two at 7.30pm. On Sundays there will be a matinee performance of Part One at 1pm and an evening performance of Part Two at 6.30pm. One ticket will automatically secure you the same seat for both Part One and Part Two on the same day.</p>
              <p>On Thursdays there will be an evening performance of Part One at 7.30pm and on Fridays an evening performance of Part Two at 7.30pm. If you choose to see Part One on a Thursday evening, we will automatically book you into Part Two the following Friday evening.</p>
              <div className='table-responsive'>
                <table className='table table-shadowed'>
                  <thead>
                    <tr>
                      <th>WED</th>
                      <th>THU</th>
                      <th>FRI</th>
                      <th>SAT</th>
                      <th>SUN</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td className='cell--ampersand'><strong>Part One, 2pm</strong></td>
                      <td colSpan={2} className='cell--muted'>&nbsp;</td>
                      <td className='cell--ampersand'><strong>Part One, 2pm</strong></td>
                      <td className='cell--ampersand'><strong>Part One, 1pm</strong></td>
                    </tr>
                    <tr>
                      <td><strong>Part Two, 7.30pm</strong></td>
                      <td colSpan={2}><strong>Part One, 7.30pm &amp; Part Two, 7.30pm</strong></td>
                      <td><strong>Part Two, 7.30pm</strong></td>
                      <td><strong>Part Two, 6.30pm</strong></td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>

        <div className='panel-body'>
          <div className='row'>
            <div className='col-sm-10 col-sm-offset-1 col-lg-10 col-lg-offset-1'>
              <hr className='hr-brand' />
              <div className='text-center'>
                <h3>Your Questions Answered</h3>
              </div>
              <ul className='list-group'>
                <li className='list-group-item' data-toggle='collapse' data-target='.answer1'>
                  <div className='question' >Can I buy tickets without a hotel? <span className='pull-right'><i className='fa fa-angle-down' /></span></div>
                  <div className='answer1 collapse text-muted'>Please note we provide ticket and hotel packages, ticket only packages are not available to purchase through Show and Stay. For ticket only purchases please click here: <a href='https://www.harrypottertheplay.com/ticket-information/' target='_blank'>https://www.harrypottertheplay.com/ticket-information/</a></div>
                </li>
                <li className='list-group-item' data-toggle='collapse' data-target='.answer2'>
                  <div className='question' >Can I amend, cancel or resell my tickets? <span className='pull-right'><i className='fa fa-angle-down' /></span></div>
                  <div className='answer2 collapse text-muted'>
                    <p>No amends, changes or cancellations can be made to your ticket and hotel package booking.  Tickets cannot be resold.</p>
                    <p>The lead name given at the time of booking in the <strong>Ticket Details</strong> section must be the same person attending the show, you’ll be required to show photographic ID.</p>
                  </div>
                </li>
                <li className='list-group-item' data-toggle='collapse' data-target='.answer3'>
                  <div className='question'>How do I get my tickets? <span className='pull-right'><i className='fa fa-angle-down' /></span></div>
                  <div className='answer3 collapse text-muted'>Your tickets will <strong>not</strong> be mailed to you, therefore the lead ticket holder must collect the tickets from the Box Office.</div>
                </li>
                <li className='list-group-item' data-toggle='collapse' data-target='.answer4'>
                  <div className='question'>Is there storage for my overnight bag? <span className='pull-right'><i className='fa fa-angle-down' /></span></div>
                  <div className='answer4 collapse text-muted'>No suitcases or large bags (above 41 x 31 x 16cm) will be allowed into the Palace Theatre and there will be no storage space immediately outside. The majority of the hotels we offer do have a concierge and luggage holding service and will be happy to accommodate.</div>
                </li>
                <li className='list-group-item' data-toggle='collapse' data-target='.answer5'>
                  <div className='question'>What type of seats will I be allocated? <span className='pull-right'><i className='fa fa-angle-down' /></span></div>
                  <div className='answer5 collapse text-muted'>We will provide you with the best seats we have, theses are typically in the Stalls or Dress Circle. If you would like to know the exact seat you are sitting in then please feel free to give us a call on 0845 330 1010. (Calls cost 3p plus your phone company's access charge).</div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

export default PageContent
