import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import config from '../../configs/config'
const { brandConfig } = config

class CategoryIcon extends PureComponent {
  constructor (props) {
    super(props)
    this.svg = null
    this.categoryIconUrl = `https:${brandConfig.secure.assetsUrl}shared/categoryicons/${this.props.category}.svg`
  }

  componentDidMount () {
    fetch(this.categoryIconUrl).then(response => {
      if (!response.ok) {
        throw new Error(`There was an issue loading category icon: ${this.categoryIconUrl}`)
      }
      return response.text()
    }).then(svg => {
      this.svg.innerHTML = svg
      this.svg.children[0].setAttribute('width', this.props.width)
      this.svg.children[0].setAttribute('height', this.props.height)
    }).catch(() => {})
  }

  render () {
    if (!this.props.category || this.props.category === 'no_icon') {
      return <div className='no_icon' />
    }

    return (
      <span
        ref={element => {
          this.svg = element
        }} dangerouslySetInnerHTML={{ __html: `<!-- SVG has failed to load ${this.categoryIconUrl} -->` }} />
    )
  }
}

CategoryIcon.propTypes = {
  category: PropTypes.string,
  height: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  width: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

CategoryIcon.defaultProps = {
  category: 'tickets',
  height: '30',
  width: '30'
}

export default CategoryIcon
