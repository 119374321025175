import _ from 'lodash'
import emitter from '@marvelapp/react-ab-test/lib/emitter'
import experimentDebugger from '@marvelapp/react-ab-test/lib/debugger'
import { query } from '../components/atoms/BreakPoint'

import routeHelpers from '../helpers/routeHelpers'

// This is here as we transistion over to tracker as this requires variants to
// be show_original / show_alternative / show_alternative_1 / show_alternative_2 etc
// see: https://github.com/holidayextras/tracker
const normaliseVariantName = variantName => {
  if (variantName && variantName.includes('show_')) return variantName
  return variantName === 'default' ? 'show_original' : 'show_alternative'
}

// Using https://github.com/pushtell/@marvelapp/react-ab-test for split testing
const experiment = {
  complete: experimentName => {
    return (dispatch, getState) => {
      dispatch({
        experimentName,
        type: 'EXPERIMENT_COMPLETED'
      })
      emitter.emitWin(experimentName)
    }
  },
  forceActive: () => {
    return (dispatch, getState) => {
      // Deal with experiment switching on etc
      const queryStringParams = routeHelpers.getQueryStringParams()

      let experiments = queryStringParams.experiment

      // Store in session / read from session to persist choices between page loads
      if (window.sessionStorage) {
        try {
          const storedExperiments = JSON.parse(sessionStorage.getItem('thewallexperimentoverrides'))
          // Override any values passed from the url using the stored values as defaults.
          experiments = Object.assign({}, storedExperiments, experiments)

          // Store the new overrides.
          sessionStorage.setItem('thewallexperimentoverrides', JSON.stringify(experiments))
        } catch (e) {
          // Just in case JSON parse borks out, protect that here.
        }
      }

      Object.keys(experiments || {}).map(experimentName => {
        emitter.setActiveVariant(experimentName, experiments[experimentName])
        dispatch({
          name: experimentName,
          type: 'EXPERIMENT_FORCE_ACTIVE'
        })
      })
    }
  },
  setupEventListeners: () => {
    return (dispatch, getState) => {
      const experiments = window.theWallExperiments || {}
      const hasEventListeners = getState().experiment.eventListenersInit

      dispatch({
        eventListenersInit: true,
        type: 'INITIALIZE_EXPERIMENT_EVENT_LISTENERS',
        experiments
      })

      if (!hasEventListeners) {
        // Debugger is stripped out in production builds using process.env.NODE_ENV
        experimentDebugger.enable()

        // set weighting based on the device that the user is using
        const userDevice = query.deviceBasedOnScreenSize()

        Object.keys(experiments).map(name => {
          const weighting = _.get(experiments[name], ['devices', userDevice, 'weighting'], experiments[name].weighting)
          if (weighting.length > 0 && experiments[name].variants.length === weighting.length) emitter.defineVariants(name, experiments[name].variants, weighting)
        })

        // Called when the experiment is displayed to the user.
        const playListener = emitter.addPlayListener(function (experimentName, variantName) {
          const variant = normaliseVariantName(variantName)
          window.tracker && window.tracker.track('test', {
            step: 'start',
            test_name: experimentName,
            variant
          })

          dispatch({
            experimentName,
            type: 'EXPERIMENT_DATA',
            variantName
          })
        })

        dispatch({
          listener: playListener,
          type: 'EXPERIMENT_LISTENER_ADD'
        })

        // Called when a 'win' is emitted, you can have a customer interact with a section & record that interaction
        const winListener = emitter.addWinListener(function (experimentName, variantName) {
          if (window && window.localStorage) {
            if (!variantName) {
              variantName = window.localStorage.getItem(`PUSHTELL-${experimentName}`)
            }
          }
          if (!variantName) return
          const variant = normaliseVariantName(variantName)
          window.tracker && window.tracker.track('test', {
            step: 'complete',
            test_name: experimentName,
            variant
          })

          dispatch({
            experimentName,
            type: 'EXPERIMENT_DATA_INTERACTION',
            variantName
          })
        })

        dispatch({
          listener: winListener,
          type: 'EXPERIMENT_LISTENER_ADD'
        })
      }
    }
  },
  tearDownListeners: () => {
    return (dispatch, getState) => {
      getState().experiment.listeners.map(listener => {
        listener.remove && listener.remove()
      })
      dispatch({
        type: 'EXPERIMENT_LISTENER_TEARDOWN'
      })
    }
  }
}

export default experiment
