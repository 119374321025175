import _ from 'lodash'

import config from '../configs/config'
const { brandConfig } = config

const LiveChat = {
  _isEnabled: _.get(brandConfig, 'secure.hasFeatures.contact.liveChat', false),

  _load: (callback) => {
    window.loadZopim(callback)
  },

  _retrieveUser: (liveChatInstance) => {
    // If the customer has interacted on chat before get their details from the cookie
    const result = /Email=([^;]+)/.exec(document.cookie)
    const chatCookie = result && result[1]
    if (chatCookie) {
      liveChatInstance.setOnConnected(() => {
        liveChatInstance.setEmail(chatCookie)
      })
    }
    return liveChatInstance
  },

  _bootstrap: (liveChatInstance) => {
    liveChatInstance = LiveChat._retrieveUser(liveChatInstance)
    liveChatInstance = LiveChat._applyBranding(liveChatInstance)
    return liveChatInstance
  },

  _applyBranding: (liveChatInstance) => {
    // Remove the department field from the form
    liveChatInstance.departments.filter('')

    // set the deparment to short breaks by default
    liveChatInstance.departments.setVisitorDepartment('Short Breaks')

    // Hide the chat so we can customise the settings
    liveChatInstance.bubble.hide()
    liveChatInstance.window.hide()

    liveChatInstance.theme.setColor(brandConfig.chat.color, 'primary')
    liveChatInstance.window.setTitle('Live help')
    liveChatInstance.sendVisitorPath({
      url: document.referrer,
      title: brandConfig.parkName
    })

    // Reload/show the chat window
    liveChatInstance.theme.reload()
    liveChatInstance.hideAll()
    return liveChatInstance
  },

  _handleLoadedLiveChat: (liveChatInstance) => {
    LiveChat._handleSetChatState({
      isLoading: false
    })
    if (LiveChat._isOnline(liveChatInstance)) {
      liveChatInstance = LiveChat._bootstrap(liveChatInstance)
      LiveChat._liveChatInstance = liveChatInstance
      LiveChat._handleSetChatState({
        isOnline: true
      })
    } else {
      LiveChat._liveChatInstance = liveChatInstance
      LiveChat._handleSetChatState({
        isOnline: false
      })
      LiveChat.close()
    }
  },

  _isOnline: (liveChatInstance) => {
    if (!liveChatInstance) return false
    const departmentStatus = liveChatInstance.departments.getDepartment('Short Breaks')

    // Check the department status and hide the chat if the agents are all away or offline.
    // The offline chat for will appear only if both hx and SB agents are all away/offline.
    // so we will just hide the chat if SB agents are not available
    return (departmentStatus && (!/offline|away/.test(departmentStatus.status)))
  },

  init: (handleSetChatState) => {
    const isInstantiable = (typeof handleSetChatState === 'function' && typeof window.loadZopim === 'function')
    if (isInstantiable && LiveChat._isEnabled && !LiveChat._liveChatInstance) {
      LiveChat._handleSetChatState = handleSetChatState

      // set isOnline to false, to start with
      LiveChat._handleSetChatState({
        isLoading: true,
        isOnline: false
      })
      LiveChat._load(LiveChat._handleLoadedLiveChat)
    }
  },

  open: () => {
    if (LiveChat._liveChatInstance) {
      // show the chat widget
      LiveChat._liveChatInstance.window.show()
    }
  },

  close: () => {
    if (LiveChat._liveChatInstance) {
      // hide the chat widget
      LiveChat._liveChatInstance.hideAll()
    }
  }
}

export default LiveChat
