import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { FormattedMessage } from 'react-intl'

import SVGBed from '../atoms/SVGBed'
import SVGCatering from '../atoms/SVGCatering'
import SVGCheck from '../atoms/SVGCheck'
import SVGTicket from '../atoms/SVGTicket'

class BasketSummary extends PureComponent {
  render () {
    return (
      <div>
        <h3><FormattedMessage id='common.summary' /></h3>
        <ul className='basket-summary__details list-unstyled icon-list'>
          {this.props.eventName && (
            <li className='event-name'>
              <SVGTicket
                title='Included Ticket'
                uniqueID='basketEvent' />
              {this.props.eventName}
            </li>
          )}
          {this.props.hotelName && (
            <li className='hotel-name'>
              <SVGBed
                title='Included Hotel'
                uniqueID='basketHotel' />
              {this.props.hotelName}
            </li>
          )}
          {this.props.cateringDescription && (
            <li className='catering-description'>
              <SVGCatering
                title='Included Catering'
                uniqueID='basketCatering' />
              {this.props.cateringDescription}
            </li>
          )}
          {this.props.upgradeNames && this.props.upgradeNames.map(upgradeName => (
            <li
              key={`upgrade${upgradeName}`}>
              <SVGCheck
                title='Included Extra'
                uniqueID={`basketExtra${upgradeName}`} />
              {upgradeName}
            </li>
          ))}
          {this.props.cancellationWaiver && (
            <li className='cancellationWaiver-description'>
              <SVGCheck
                title='Added Cancellation Waiver'
                uniqueID='cancellationWaiver' />
              {this.props.cancellationWaiver}
            </li>
          )}
        </ul>
      </div>
    )
  }
}

BasketSummary.propTypes = {
  cancellationWaiver: PropTypes.string,
  cateringDescription: PropTypes.string,
  eventName: PropTypes.string,
  hotelName: PropTypes.string,
  title: PropTypes.string,
  upgradeNames: PropTypes.arrayOf(PropTypes.string)
}

export default BasketSummary
