import React, { PureComponent } from 'react'

class Loading extends PureComponent {
  render () {
    return (
      <div>
        <div className='overlay holdingPageWrapper' id='holdingPage'>
          <div className='holdingPage container'>
            <div className='spinner' />
          </div>
        </div>
      </div>
    )
  }
}

export default Loading
