import React from 'react'
import PropTypes from 'prop-types'
import Tab from 'react-bootstrap/lib/Tab'

const ModalTab = (props) => {
  return (
    <Tab
      eventKey={props.eventKey}
      title={props.tabTitle}>
      <section>
        <div className='panel panel-default'>
          <div className='panel-heading'>
            <h2 className='panel-title'>{props.title}</h2>
          </div>
          <div className='panel-body'>
            {props.children}
          </div>
        </div>
      </section>
    </Tab>
  )
}

ModalTab.propTypes = {
  children: PropTypes.node.isRequired,
  eventKey: PropTypes.string.isRequired,
  tabTitle: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired
}

export default ModalTab
