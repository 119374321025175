import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { injectIntl, intlShape } from 'react-intl'

import SVGCar from '../atoms/SVGCar'
import SVGCharter from '../atoms/SVGCharter'
import SVGPool from '../atoms/SVGPool'
import SVGRestaurant from '../atoms/SVGRestaurant'
import SVGWifi from '../atoms/SVGWifi'

import TrackingLink from '../../containers/TrackingLink'

import languageHelpers from '../../helpers/languageHelpers'

class CommonFacilities extends PureComponent {
  render () {
    const {
      charter,
      ghostPackage,
      handleMoreInfo,
      hasFreeParking,
      hasFreeWifi,
      hasRestaurant,
      hasSwimmingPool,
      hotelKey,
      isResort,
      paidParkingAmount
    } = this.props
    const className = classNames('block-xs common-facilities list-inline', {
      'hidden-xs': isResort
    })

    return (
      <ul className={className}>
        {hasFreeWifi &&
          <li className='text-center'>
            <TrackingLink
              action='Facility Icons'
              category='Hotel Element'
              label={`Wifi - ${hotelKey}`}
              ghostPackage={ghostPackage}
              handler={handleMoreInfo}
            >
              <OverlayTrigger
                trigger={['hover', 'focus', 'click']}
                placement='top'
                overlay={
                  <Tooltip id='WifiTooltip'>
                    {languageHelpers.generateFacilityTooltip(this.props.intl.formatMessage({ id: 'common.wifi' }), hasFreeWifi)}
                  </Tooltip>
                }>
                <div className='img-thumbnail'>
                  <SVGWifi
                    title={this.props.intl.formatMessage({ id: 'common.wifi' })}
                    uniqueID={`freeWifiIncluded-${hotelKey}`}
                  />
                  <br />
                  <div className='tiny sr-only'>
                    {languageHelpers.generateFacilityTooltip(this.props.intl.formatMessage({ id: 'common.wifi' }), hasFreeWifi)}
                  </div>
                </div>
              </OverlayTrigger>
            </TrackingLink>
          </li>
        }

        {hasSwimmingPool &&
          <li className='text-center'>
            <TrackingLink
              action='Facility Icons'
              category='Hotel Element'
              label={`Swimming Pool - ${hotelKey}`}
              ghostPackage={ghostPackage}
              handler={handleMoreInfo}
            >
              <OverlayTrigger
                trigger={['hover', 'focus', 'click']}
                placement='top'
                overlay={
                  <Tooltip id='PoolTooltip'>
                    {languageHelpers.generateFacilityTooltip(this.props.intl.formatMessage({ id: 'common.swimmingPool' }))}
                  </Tooltip>
                }>
                <div className='img-thumbnail'>
                  <SVGPool
                    title={this.props.intl.formatMessage({ id: 'common.swimmingPool' })}
                    uniqueID={`swimmingPoolIncluded-${hotelKey}`}
                  />
                  <br />
                  <div className='tiny sr-only'>
                    {languageHelpers.generateFacilityTooltip(this.props.intl.formatMessage({ id: 'common.swimmingPool' }))}
                  </div>
                </div>
              </OverlayTrigger>
            </TrackingLink>
          </li>
        }

        {(hasFreeParking || paidParkingAmount) &&
          <li className='text-center'>
            <TrackingLink
              action='Facility Icons'
              category='Hotel Element'
              label={`Parking - ${hotelKey}`}
              ghostPackage={ghostPackage}
              handler={handleMoreInfo}
            >
              <OverlayTrigger
                trigger={['hover', 'focus', 'click']}
                placement='top'
                overlay={
                  <Tooltip id='ParkingTooltip'>
                    {languageHelpers.generateFacilityTooltip(this.props.intl.formatMessage({ id: 'common.carParking' }), hasFreeParking, paidParkingAmount)}
                  </Tooltip>
                }>
                <div className='img-thumbnail'>
                  <SVGCar
                    title={this.props.intl.formatMessage({ id: 'common.carParking' })}
                    uniqueID={`carParkingIncluded-${hotelKey}`}
                  />
                  <br />
                  <div className='tiny sr-only'>
                    {languageHelpers.generateFacilityTooltip(this.props.intl.formatMessage({ id: 'common.carParking' }), hasFreeParking, paidParkingAmount)}
                  </div>
                </div>
              </OverlayTrigger>
            </TrackingLink>
          </li>
        }

        {hasRestaurant &&
          <li className='text-center'>
            <TrackingLink
              action='Facility Icons'
              category='Hotel Element'
              label={`Restaurant - ${hotelKey}`}
              ghostPackage={ghostPackage}
              handler={handleMoreInfo}
            >
              <OverlayTrigger
                trigger={['hover', 'focus', 'click']}
                placement='top'
                overlay={
                  <Tooltip id='RestaurantTooltip'>
                    {languageHelpers.generateFacilityTooltip(this.props.intl.formatMessage({ id: 'common.restaurant' }))}
                  </Tooltip>
                }>
                <div className='img-thumbnail'>
                  <SVGRestaurant
                    title={this.props.intl.formatMessage({ id: 'common.restaurant' })}
                    uniqueID={`restaurantIncluded-${hotelKey}`}
                  />
                  <br />
                  <div className='tiny sr-only'>
                    {languageHelpers.generateFacilityTooltip(this.props.intl.formatMessage({ id: 'common.restaurant' }))}
                  </div>
                </div>
              </OverlayTrigger>
            </TrackingLink>
          </li>
        }

        {charter &&
          <li className='text-center'>
            <TrackingLink
              action='Facility Icons'
              category='Hotel Element'
              label={`Charter - ${hotelKey}`}
              ghostPackage={ghostPackage}
              handler={handleMoreInfo}
            >
              <SVGCharter type={charter} />
            </TrackingLink>
          </li>
        }
      </ul>
    )
  }
}

CommonFacilities.propTypes = {
  handleMoreInfo: PropTypes.func.isRequired,
  hasFreeParking: PropTypes.bool.isRequired,
  hasFreeWifi: PropTypes.bool.isRequired,
  hasSwimmingPool: PropTypes.bool.isRequired,
  hasRestaurant: PropTypes.bool.isRequired,
  hotelKey: PropTypes.string.isRequired,
  intl: intlShape
}

export default injectIntl(CommonFacilities)
