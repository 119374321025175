// This file is shared between SSR and none SSR pages. So we need to check window exists before using it.

import _ from 'lodash'
import { createContext } from 'react'

import routeHelpers from '../helpers/routeHelpers'

// Default, for when the server provides the values
let BrandContext = createContext(null)

// For client side rendered pages
if (typeof window !== 'undefined' && window.brandConfig) {
  // Grab from the URL if we need to
  const queryStringParams = routeHelpers.getQueryStringParams()

  // Grab all the config from the window and merge together
  const brandContent = _.get(window, 'brandReply.brands[0]', {})
  const brandConfig = Object.assign({}, brandContent, window.brandConfig)

  BrandContext = createContext({
    agent: window.agent || queryStringParams.agent,
    brandConfig,
    harpDomain: window.harpDomain,
    isCallCentre: brandConfig.consumerType === 'callCentre',
    rpcGatewayBase: window.rpcGatewayBase
  })
}

export default BrandContext
