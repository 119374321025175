import 'isomorphic-fetch'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import trackingHelpers from '../helpers/trackingHelpers'

class MarketingPreferences extends Component {
  render () {
    const orderMarketingText = 'Addons and upgrades for this break'
    const generalMarketingText = 'Future offers and news'

    return (
      <div className='well block-sm'>
        <div className='block-sm clearfix row'>
          <div className='col-sm-7 text-sm-right'>
            <p className='mb-0'>
              <FormattedMessage id='preferenceCentre.orderMarketingText' />
            </p>
            <p className='text-muted'>
              <FormattedMessage id='preferenceCentre.orderMarketingSub' />
            </p>
          </div>
          <div className='col-sm-3'>
            <div className='btn-group btn-group-justified' role='group' aria-label='Justified button group'>
              <a
                tabIndex='0'
                className={`btn no-arrow ${this.props.allowOrderMarketing ? 'btn-secondary active' : 'btn-default'}`}
                {...trackingHelpers.getAttributes(orderMarketingText, 'Preference Centre', 'Yes')}
                onClick={() => this.props.changePreferences('allowOrderMarketing', true)}
                onKeyDown={(e) => e.key === 'Enter' && this.props.changePreferences('allowOrderMarketing', true)}
                role='button'>
                <FormattedMessage id='common.yes' />
              </a>
              <a
                tabIndex='0'
                className={`btn no-arrow ${!this.props.allowOrderMarketing ? 'btn-secondary active' : 'btn-default'}`}
                {...trackingHelpers.getAttributes(orderMarketingText, 'Preference Centre', 'No')}
                onClick={() => this.props.changePreferences('allowOrderMarketing', false)}
                onKeyDown={(e) => e.key === 'Enter' && this.props.changePreferences('allowOrderMarketing', false)}
                role='button'>
                <FormattedMessage id='common.no' />
              </a>
            </div>
          </div>
        </div>
        <div className='block-sm clearfix row'>
          <div className='col-sm-7 text-sm-right'>
            <p className='mb-0'>
              <FormattedMessage id='preferenceCentre.generalMarketingText' />
            </p>
            <p className='text-muted'>
              <FormattedMessage id='preferenceCentre.generalMarketingSub' />
            </p>
          </div>
          <div className='col-sm-3'>
            <div className='btn-group btn-group-justified' role='group' aria-label='Justified button group'>
              <a
                tabIndex='0'
                className={`btn no-arrow ${this.props.allowGeneralMarketing ? 'btn-secondary active' : 'btn-default'}`}
                {...trackingHelpers.getAttributes(generalMarketingText, 'Preference Centre', 'Yes')}
                onClick={() => this.props.changePreferences('allowGeneralMarketing', true)}
                onKeyDown={(e) => e.key === 'Enter' && this.props.changePreferences('allowGeneralMarketing', true)}
                role='button'>
                <FormattedMessage id='common.yes' />
              </a>
              <a
                tabIndex='0'
                className={`btn no-arrow ${!this.props.allowGeneralMarketing ? 'btn-secondary active' : 'btn-default'}`}
                {...trackingHelpers.getAttributes(generalMarketingText, 'Preference Centre', 'No')}
                onClick={() => this.props.changePreferences('allowGeneralMarketing', false)}
                onKeyDown={(e) => e.key === 'Enter' && this.props.changePreferences('allowGeneralMarketing', false)}
                role='button'>
                <FormattedMessage id='common.no' />
              </a>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    allowGeneralMarketing: state.preferenceCentre.allowGeneralMarketing,
    allowOrderMarketing: state.preferenceCentre.allowOrderMarketing
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    changePreferences: (marketingType, value) => dispatch({
      type: 'PREFERENCECENTRE_UPDATE_PREFERENCES',
      marketingType,
      value
    })
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(MarketingPreferences)
export {
  MarketingPreferences
}
