import React from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

const FAQs = props => {
  const faqKeys = Object.keys(props.faqs)
  const faqs = faqKeys.map((key, faqIndex) => {
    const faq = props.faqs[key].map((faq, index) => {
      return (
        <div key={`faqs${index}`}>
          <div className='h4' dangerouslySetInnerHTML={{ __html: faq.question }} />
          <div dangerouslySetInnerHTML={{ __html: faq.answer }} />
          {faqIndex < faqKeys.length - 1 && <hr />}
        </div>
      )
    })
    return (
      <div key={key}>
        <FormattedMessage id={`faqs.${key}`} tagName='h3' />
        {faq}
      </div>
    )
  })
  return (
    <div>{faqs}</div>
  )
}

FAQs.propTypes = {
  faqs: PropTypes.object.isRequired
}

export default FAQs
