import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Col, ControlLabel, FormGroup, HelpBlock } from 'react-bootstrap'

import DisplayPrice from './DisplayPrice'
import config from '../../configs/config'

class BasketTotal extends PureComponent {
  render () {
    const hasSimplifiedPaymentPage = _.get(config, 'brandConfig.secure.hasFeatures.payment.hasSimplifiedPaymentPage', false)
    return (
      <section className='summary-total'>
        <FormGroup>
          <Col componentClass={ControlLabel} sm={hasSimplifiedPaymentPage ? 4 : 3}>
            <span data-automated-test='basketTitle'>{this.props.title}</span>
          </Col>
          <Col sm={6}>
            {this.props.standardPrice && (
              <s data-automated-test='wasPrice' className='d-block text-muted text-capitalize'>{this.props.wasText} <DisplayPrice price={this.props.standardPrice} /></s>
            )}
            <div className='display-2'>
              <span data-automated-test='grossPrice' className='amount'>
                <DisplayPrice price={this.props.grossPrice} showZeroAsPrice />
              </span>
            </div>
            {this.props.totalSectionSubText && (<HelpBlock>{this.props.totalSectionSubText}</HelpBlock>)}
          </Col>
        </FormGroup>
      </section>
    )
  }
}

BasketTotal.propTypes = {
  grossPrice: PropTypes.number,
  standardPrice: PropTypes.number,
  title: PropTypes.string,
  totalSectionSubText: PropTypes.string
}

export default BasketTotal
