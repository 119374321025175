import React from 'react'
import Responsive from 'react-responsive'

/*
* Adding a new rule here will generate a new component + function
*/
const rules = [
  {
    name: 'isXs',
    maxWidth: 767
  },
  {
    name: 'isSm',
    minWidth: 768,
    maxWidth: 991
  },
  {
    name: 'isMd',
    minWidth: 992,
    maxWidth: 1199
  },
  {
    name: 'isLg',
    minWidth: 1200
  },
  {
    name: 'isAtLeastSm',
    minWidth: 768
  },
  {
    name: 'isAtLeastMd',
    minWidth: 992
  },
  {
    name: 'isAtMostSm',
    maxWidth: 991
  },
  {
    name: 'isAtMostMd',
    maxWidth: 1199
  }
]

/*
* Generates an object of components that can be used like:
* <BreakPoint.isXs>xtra small device here</BreakPoint.isXs>
* Where the BreakPoint.* relates to the name in each rule
*/
const BreakPoint = rules.reduce((acc, breakpoint) => {
  acc[breakpoint.name] = ({ children }) => <Responsive maxWidth={breakpoint.maxWidth} minWidth={breakpoint.minWidth} children={children} />
  return acc
}, {
  isPortrait: ({ children }) => <Responsive orientation='portait' children={children} />,
  isLandscape: ({ children }) => <Responsive orientation='landscape' children={children} />
})

/*
* Generates an object of functions that can be used like:
* if(BreakPoint.query.isXs()) { some code to run only on small device }
* Where the BreakPoint.query.* relates to the name in each rule
*/
const query = rules.reduce((acc, breakpoint) => {
  const query = []
  if (breakpoint.maxWidth) {
    query.push(`(max-width: ${breakpoint.maxWidth}px)`)
  }
  if (breakpoint.minWidth) {
    query.push(`(min-width: ${breakpoint.minWidth}px)`)
  }
  acc[breakpoint.name] = () => window.matchMedia && window.matchMedia(query.join(' and '), { min: breakpoint.minWidth, max: breakpoint.maxWidth }).matches
  return acc
}, {
  isPortrait: () => window.matchMedia && window.matchMedia('all and (orientation:portrait)', {}).matches,
  isLandscape: () => window.matchMedia && window.matchMedia('all and (orientation:landscape)', {}).matches
})

query.deviceBasedOnScreenSize = () => {
  if (query.isXs()) return 'mobile'
  if (query.isSm()) return 'tablet'
  if (query.isMd() || query.isLg()) return 'desktop'
}

export {
  BreakPoint,
  query
}
