import 'isomorphic-fetch'
import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Alert, Button, Col, Panel } from 'react-bootstrap'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'
import classNames from 'classnames'

import { BreakPoint, query } from './components/atoms/BreakPoint'
import DisplayPrice from './components/atoms/DisplayPrice'
import ResponsiveWaypoint from './components/atoms/ResponsiveWaypoint'

import Loading from './components/molecules/Loading'

import Basket from './components/organisms/Basket/Basket'
import BookingSummary from './components/organisms/BookingSummary'
import UpgradeList from './components/organisms/UpgradeList'

import basketHelpers from './helpers/basketHelpers'
import dateHelpers from './helpers/dateHelpers'
import generalHelpers from './helpers/generalHelpers'
import resourceHelpers from './helpers/resourceHelpers'
import routeHelpers from './helpers/routeHelpers'
import sortingHelpers from './helpers/sortingHelpers'
import trackingHelpers from './helpers/trackingHelpers'
import upgradesHelpers from './helpers/upgradesHelpers'

import config from './configs/config'
import roomTypes from './configs/roomTypes'

const {
  additionalPackageRatesReply,
  agesConfiguration,
  brandConfig,
  endpoint,
  eventProduct,
  harvestBasketData,
  hotelEventProductsReply,
  hotelProduct,
  savedUrls,
  upgradeRatesReply,
  venueProduct
} = config

class UpgradesContainer extends Component {
  constructor (props) {
    super(props)
    const { sundryRates = [] } = this.props
    this.hasFeatures = _.get(brandConfig, 'secure.hasFeatures', {})
    const upgradeFeatures = this.hasFeatures.upgrades || {}
    this.autoAddChessGrottoUpgrades = upgradeFeatures.autoAddChessGrottoUpgrades || false
    this.basicFixedSummaryStepper = _.get(brandConfig, 'secure.hasFeatures.upgrades.basicFixedSummaryStepper', false)
    this.cancellationWaiver = sundryRates.find(rate => rate.type === 'cancellationWaiver') || {}
    this.cancellationWaiverAmount = brandConfig.cancellationWaiverAmount || null
    this.childrenMaxAge = _.get(agesConfiguration, `[${brandConfig.name}].childrenMaxAge`, null)
    this.childrenMinAge = _.get(agesConfiguration, `[${brandConfig.name}].childrenMinAge`, null)
    this.ctaButtonBook = upgradeFeatures.ctaButtonBook || false
    this.extraNightButtonSmallSize = upgradeFeatures.extraNightButtonSmallSize || false
    this.hasCancellationProtectionMessage = _.get(brandConfig, 'secure.hasFeatures.hasCancellationProtectionMessage', false)
    this.hasUpgradesExtraNights = upgradeFeatures.extraNights || false
    this.hasUpgradesSwappableTickets = upgradeFeatures.swappableTickets || false
    this.hotelEventProducts = _.get(hotelEventProductsReply, 'hotelEventProducts[0]', {})
    this.hotelImage = resourceHelpers.generateImagePaths(brandConfig.secure.imagesDomain, 'hotel/', hotelProduct.images[0], hotelProduct.id, '/16-9/') + '?w=150'
    this.listLayout = upgradeFeatures.listLayout || false
    this.withChildAges = _.get(brandConfig, 'secure.withChildAges', false)
    this.queryStringParams = routeHelpers.getQueryStringParams()
    this.forceUpgradeModal = _.get(brandConfig, 'secure.hasFeatures.upgrades.forceUpgradeModal', false)
    this.forceUpgradeModalType = _.get(brandConfig, 'secure.hasFeatures.upgrades.upgradeModalFeatures.upgradeModalType', false)
    this.forceUpgradeModalIsClosable = _.get(brandConfig, 'secure.hasFeatures.upgrades.upgradeModalFeatures.isModalClosable', false)
    this.upgradeTitleChange = _.get(brandConfig, 'secure.hasFeatures.upgrades.upgradeTitleChange', false)
    this.forceTiledUpgradeModalFeatures = _.get(brandConfig, 'secure.hasFeatures.upgrades.forceTiledUpgradeModalFeatures', null)

    // Tech Debt - This Jira will hopefully take care of Harvest not keeping the data type when using strings
    // https://hxshortbreaks.atlassian.net/browse/WEB-10518
    // Once this is complete, this code will need to go
    this.hasParkEntry = harvestBasketData.hasParkEntry
    if (typeof this.hasParkEntry === 'string') {
      this.hasParkEntry = (this.hasParkEntry === 'true')
    }

    let availableUpgradeRates = upgradesHelpers.upgradeMapper()
    availableUpgradeRates = upgradesHelpers.addNightsBasedAutoBundledUpgrades(availableUpgradeRates, upgradeRatesReply)

    availableUpgradeRates.map(upgrade => {
      if (upgrade.isIncludedInPackage) {
        upgrade.isInBasket = true
      }
    })

    // Remove the upgrade from the page if this feature isn't turned on
    if (!this.thorpeBreakfastAddonSelectionModal) {
      availableUpgradeRates = availableUpgradeRates.filter(upgrade => !(upgrade.group || []).includes('GROUPTHORPEUPGRADEBREAKFAST'))
    }

    this.convertSwappableTicketsToUpgrades = this.convertSwappableTicketsToUpgrades.bind(this)
    this.checkUpgradesInBasket = this.checkUpgradesInBasket.bind(this)
    this.handleCompositionChange = this.handleCompositionChange.bind(this)
    this.handleDateSelection = this.handleDateSelection.bind(this)
    this.handleDaysSelection = this.handleDaysSelection.bind(this)
    this.handleExtraNightCTA = this.handleExtraNightCTA.bind(this)
    this.handleGetSessionBasketData = basketHelpers.handleGetSessionBasketData.bind(this)
    this.handleGroupUpgradeSelection = this.handleGroupUpgradeSelection.bind(this)
    this.handleSetSessionBasketData = basketHelpers.handleSetSessionBasketData.bind(this)
    this.handleUpgradeCTA = this.handleUpgradeCTA.bind(this)
    this.proceedToPayment = this.proceedToPayment.bind(this)
    this.removeUpgrades = this.removeUpgrades.bind(this)
    this.sortUpgrades = this.sortUpgrades.bind(this)
    this.getUpgrades = this.getUpgrades.bind(this)

    // additionalPackageRatesReply contains swappable tickets (season passes, extra day passes) and extra nights package rates
    const additionalUpgrades = this.getAdditionalUpgradeRates(additionalPackageRatesReply)

    this.state = {
      ages: harvestBasketData.ages,
      basicFixedSummaryStepperHide: false,
      checkinDate: window.checkinDate,
      checkoutDate: window.checkoutDate,
      composition: {
        adults: Number(window.adults),
        children: Number(window.children),
        infants: Number(window.infants)
      },
      customerCode: harvestBasketData.customerCode || 'Q',
      extraNights: (additionalUpgrades['extraNights'] && additionalUpgrades['extraNights'][0]) || {
        before: {
          date: null,
          grossPrice: null,
          isAvailable: false,
          isInBasket: false,
          name: null,
          packageTypeId: null
        },
        after: {
          date: null,
          grossPrice: null,
          isAvailable: false,
          isInBasket: false,
          name: null,
          packageTypeId: null
        }
      },
      eventDates: basketHelpers.getEventDates(
        moment.utc(_.get(harvestBasketData, 'ticket.startDate', 0)),
        venueProduct.venueType,
        this.hasParkEntry
      ),
      harvestData: null,
      isLoading: false,
      isSummaryTitleClickable: query.isXs(),
      notificationMessages: [],
      previousExtraNights: {},
      previousTicket: {},
      // swappableTicketUpgrades are tickets sold as upgrades - season passes and extra day passes.
      // when such an upgrade is added to the basket we should swap the original ticket with it
      swappableTicketUpgrades: additionalUpgrades['swappableTicketUpgrades'] || [],
      ticket: harvestBasketData.ticket || {},
      ticketCompCounts: {
        adults: Number(_.get(harvestBasketData, 'ticket.adults', 0)),
        children: Number(_.get(harvestBasketData, 'ticket.children', 0)),
        infants: Number(_.get(harvestBasketData, 'ticket.infants', 0))
      },
      upgradeModal: {
        visible: '',
        forceUpgradeModal: this.forceUpgradeModal,
        forceUpgradeModalType: this.forceUpgradeModalType,
        forceUpgradeModalIsClosable: this.forceUpgradeModalIsClosable,
        forceTiledUpgradeModalFeatures: this.forceTiledUpgradeModalFeatures
      },
      upgrades: availableUpgradeRates
    }
  }

  getAdditionalUpgradeRates (additionalPackageRatesReply) {
    if (this.hasUpgradesSwappableTickets || this.hasUpgradesExtraNights) {
      return upgradesHelpers.getAdditionalUpgrades(
        additionalPackageRatesReply,
        harvestBasketData
      )
    }
    return []
  }

  componentDidMount () {
    if (window && window.tracker) {
      tracker.page(
        'availability', {
          page_type: this.props.pageType
        }
      )
    }
    window.addEventListener('load', this.scrollToElementByHashUrl)

    if (!harvestBasketData || Object.keys(harvestBasketData).length < 1) {
      return this.props.showCallbackForm('Error: harvestBasketData undefined')
    }
    const includes = 'hotelProducts,ticketProducts,eventProducts,roomRates'
    this.handleGetSessionBasketData(includes, true)
    const swappableTicketGroupUpgrades = this.convertSwappableTicketsToUpgrades()
    if (swappableTicketGroupUpgrades.length) {
      const clonedUpgrades = _.cloneDeep(this.state.upgrades) || []

      const combinedUpgrades = swappableTicketGroupUpgrades.concat(clonedUpgrades)
      // make sure that the seasons passes are the correct order
      this.sortUpgrades(combinedUpgrades)
      this.setState(() => ({
        isLoading: false,
        upgrades: combinedUpgrades
      }))
    }
  }

  scrollToElementByHashUrl () {
    // Scroll to the element with id from the url hash
    generalHelpers.scrollElementIntoView(window.location.hash)
  }

  _generateSelectedItems () {
    const selectedItems = {}

    Object.keys(this.state.extraNights).forEach(key => {
      const extraNight = this.state.extraNights[key]

      if (extraNight.isInBasket) {
        selectedItems[extraNight.originalPackageId] = {
          id: extraNight.originalPackageId,
          resource: 'packageRates',
          type: 'package',
          grossPrice: extraNight.originalPrice,
          standardPrice: extraNight.standardOriginalPrice,
          packageTypeId: extraNight.packageTypeId
        }
      }
    })

    // If not extra night selected and chosen ticket is a season pass, add it to selectedItems
    if (this.state.ticket.isSwappableTicket && Object.keys(selectedItems).length === 0) {
      selectedItems[this.state.ticket.packageId] = {
        id: this.state.ticket.packageId,
        resource: 'packageRates',
        type: 'package',
        grossPrice: this.state.ticket.originalPrice,
        standardPrice: this.state.ticket.standardOriginalPrice,
        packageTypeId: this.state.ticket.packageTypeId
      }
    }

    // If it turns out that the user didn't add any extra nights, then this is fine, go back to our old selected items.
    // Otherwise, lets set our new selectedItems.
    return Object.keys(selectedItems).length > 0 ? selectedItems : this.state.harvestData.baskets.data.selectedItems
  }

  // @todo: currently contains a lot of logic for Chessington Grotto Hack but may be a function we need later on?
  // This is to automatically add on the first timeslot found if a customer does not add one themselves on the upgrades stage
  checkUpgradesInBasket () {
    if (this.isChessGrottoGroup && this.autoAddChessGrottoUpgrades) {
      const isChessGrottoGroupInBasket = this.state.upgrades
        .filter(upgrade => upgrade.isInBasket)
        .some(upgrade => upgrade.group.startsWith('GROUPCHESSINGTONUPGRADEGROTTO'))

      // this block of code is essentially a copy of handleUpgradeCTA: to automatically add chess grotto upgrade but proceed to payment in the setState callback
      if (!isChessGrottoGroupInBasket) {
        const clonedUpgrades = _.cloneDeep(this.state.upgrades)

        const upgrades = clonedUpgrades.map((upgrade, index, clonedUpgrades) => {
          const firstChessGrottoGroup = clonedUpgrades.find(upgrade => upgrade.group && upgrade.group.startsWith('GROUPCHESSINGTONUPGRADEGROTTO'))
          const firstChessGrottoGroupSelectedProductId = firstChessGrottoGroup && firstChessGrottoGroup.selectedProductId

          if (upgrade.id === firstChessGrottoGroupSelectedProductId) {
            const action = upgrade.isInBasket ? 'removed from' : 'added to'
            this.addNotification(`As you have not selected a time slot, ${upgrade.title} has automatically been ${action} your basket`)
            upgrade.isInBasket = !upgrade.isInBasket

            // @todo: HACK FOR CHESS GROTTO WORK IN THIS METHOD (FP-10552). THIS IS KNOWN AND WILL NEED TO BE CLEANED UP AT SOME POINT.
            // @todo: Is the selected upgrade part of a Chess Grotto Timeslot group? If so, disable the opposite timeslot group
            let timeSlot
            if (upgrade.group === 'GROUPCHESSINGTONUPGRADEGROTTOMORNING') {
              timeSlot = 'EVENING'
            }
            if (upgrade.group === 'GROUPCHESSINGTONUPGRADEGROTTOEVENING') {
              timeSlot = 'MORNING'
            }
            const requiredGroup = timeSlot && clonedUpgrades.find(upgrade => upgrade.group === `GROUPCHESSINGTONUPGRADEGROTTO${timeSlot}`)
            if (requiredGroup) requiredGroup.isDisabled = upgrade.isInBasket
          }

          return upgrade
        })

        return this.setState(() => ({
          upgrades
        }), this.proceedToPayment)
      }
    }
    this.proceedToPayment()
  }

  proceedToPayment () {
    // Track form submit
    trackingHelpers.track('sb.track', 'Proceed to Payment', 'forms', window.basket.data.hotel.id)
    this.setState(() => ({
      isLoading: true
    }))

    const {
      agent,
      ages,
      guests,
      brand,
      customerCode,
      hotel,
      operator,
      roomName,
      roomRates,
      rooms,
      roomThemeName
    } = this.state.harvestData.baskets.data
    let ticket = _.cloneDeep(this.state.ticket)
    delete ticket.extraNights

    const upgradesInBasket = this.state.upgrades
      .filter(upgrade => upgrade.isInBasket)
      .map(upgrade => {
        upgrade.id = upgrade.selectedProductId
        delete upgrade.extraNights
        if (upgrade.groupUpgradeProducts) {
          upgrade.groupUpgradeProducts.forEach(groupUpgradeProduct => {
            delete groupUpgradeProduct.extraNights
          })
        }
        return upgrade
      })

    const basketTotals = this.calculateBasketTotals(this.state.harvestData.baskets.data.grossPrice, upgradesInBasket, this.state.harvestData.baskets.data.standardPrice)
    const selectedItems = this._generateSelectedItems()
    const packageTypeId = Object.values(selectedItems)[0].packageTypeId

    let extraNightObject = null

    if (this.state.extraNights.before.isInBasket || this.state.extraNights.after.isInBasket) {
      const extraNightTimeslot = this.state.extraNights.before.isInBasket ? 'before' : 'after'
      const extraNight = this.state.extraNights[extraNightTimeslot]

      extraNightObject = {
        extraNightTimeslot,
        grossPrice: extraNight.grossPrice,
        name: extraNight.name
      }
    }

    return new Promise((resolve, reject) => {
      const harvestObject = {
        method: 'put',
        basketId: this.state.harvestData.baskets.id,
        version: window.basket.version,
        data: {
          agent,
          ages,
          guests,
          availabilityUrl: savedUrls.availability,
          brand,
          customerCode,
          extraNightObject,
          grossPrice: basketTotals.grossPrice,
          hotel: Object.assign({}, hotel, {
            checkinDate: this.state.checkinDate,
            checkoutDate: this.state.checkoutDate,
            nights: this.state.nights
          }),
          hasParkEntry: this.hasParkEntry,
          moreinformationUrl: savedUrls.moreinformation,
          operator,
          packageTypeId: packageTypeId || harvestBasketData.packageTypeId,
          packageGroupId: harvestBasketData.packageGroupId,
          roomRates,
          rooms,
          roomName,
          searchFormId: harvestBasketData.searchFormId,
          selectedItems,
          standardPrice: basketTotals.standardPrice,
          roomThemeName,
          ticket,
          upgrades: upgradesInBasket,
          upgradesUrl: window.location.href
        }
      }

      const { promotionCode } = harvestBasketData
      if (promotionCode) harvestObject.data.promotionCode = promotionCode
      if (this.queryStringParams.referrer) harvestObject.data.referrer = this.queryStringParams.referrer

      // Add our upgrades we have selected to our selected items object
      // This will then be used by the payment page in the basket.
      upgradesInBasket.filter(upgrade => !upgrade.isSwappableTicket).forEach(upgrade => {
        harvestObject.data.selectedItems[upgrade.packageId] = {
          id: upgrade.packageId,
          resource: 'upgradeRate',
          type: 'upgrade',
          grossPrice: upgrade.totalPrice || upgrade.price,
          standardPrice: upgrade.standardPrice || 0,
          quantity: (upgrade.composition && upgrade.composition.quantity) || 1
        }
      })

      // Remove some unneeded nodes from the upgrade, to drastically reduce payload size
      upgradesInBasket.forEach(upgrade => {
        delete upgrade.groupInfo
      })

      if (!window || !window.basket || !harvestBasketData || !window.updateBasket) {
        throw new Error('Basket not available for update.')
      }

      window.updateBasket(harvestObject).then(data => {
        this.handleSetSessionBasketData('sessionBasket', data.harvest.baskets)
        const requestObject = {
          agent: agent,
          basketId: window.basket.id,
          customerCode: customerCode,
          hotelCode: hotel.id,
          searchFormId: harvestBasketData.searchFormId,
          ticketCode: _.get(this.state, 'harvestData.baskets.data.ticket.id'),
          roomRates: {
            hotelCode: hotel.id,
            checkinDate: this.state.checkinDate,
            bucket: hotel.bucket
          },
          tag: endpoint,
          contentId: _.get(this.state, 'ticket.contentId', _.get(this.state, 'ticket.id', '')),
          ticketRates: {
            bucket: ticket.bucket,
            startDate: ticket.startDate,
            ticketCode: ticket.id
          },
          venueCode: venueProduct.id || brandConfig.venueCode || brandConfig.parkCode,
          versionId: window.basket.version,
          utmSource: this.queryStringParams.utmSource
        }

        if (this.queryStringParams.referrer) {
          requestObject.referrer = this.queryStringParams.referrer
        }

        const flow = brandConfig.secure && brandConfig.secure.flow
        const nextEndpoint = routeHelpers.getNextStage(flow, endpoint)
        if (nextEndpoint) {
          this.props.navigateTo(nextEndpoint, requestObject)
        }
      }, err => {
        console.error('Updating Harvest has resulted in an error:', err)
        // When there's an error with Harvest, show the callback form
        this.props.showCallbackForm()
        reject(err)
      })
    })
  }

  removeUpgrades () {
    const { grossPrice, roomRates, selectedItems, standardPrice } = harvestBasketData
    this.setState(prevState => {
      return Object.assign({}, prevState, {
        nights: roomRates.nights,
        harvestData: Object.assign({}, prevState.harvestData, {
          baskets: Object.assign({}, prevState.harvestData.baskets, {
            data: Object.assign({}, prevState.harvestData.baskets.data, {
              grossPrice,
              hotel: Object.assign({}, prevState.harvestData.baskets.data.hotel, {
                nights: roomRates.nights,
                checkinDate: roomRates.checkinDate,
                checkoutDate: roomRates.checkoutDate
              }),
              standardPrice,
              selectedItems
            })
          })
        })
      })
    })
  }

  sortUpgrades (upgrades) {
    const { upgradeOrder } = brandConfig.secure
    return upgrades.sort(sortingHelpers.sortByOrderArray(upgradeOrder, 'id'))
  }

  convertSwappableTicketsToUpgrades () {
    if (this.state.swappableTicketUpgrades.length === 0) return []
    const clonedSwappableTicketUpgrades = _.cloneDeep(this.state.swappableTicketUpgrades)

    return upgradesHelpers.convertToGroupUpgrades(clonedSwappableTicketUpgrades, this.state.upgrades, this.state.ticket, this.state.composition)
  }

  getUpgrades (options) {
    // getUpdatedUpgrade goes and gets the new prices and updates the state so we can see the new price inside the modal
    const { data } = this.state.harvestData.baskets
    const { ticket, hotel } = data
    const { extraNights } = this.state
    const previousTicket = this.state.ticket

    const checkinDate = options.checkinDate || (_.get(extraNights, 'before.isInBasket') ? extraNights.before.date.format('YYYY-MM-DD') : this.state.checkinDate)
    const checkoutDate = options.checkoutDate || (_.get(extraNights, 'after.isInBasket') ? extraNights.after.date.format('YYYY-MM-DD') : this.state.checkoutDate)

    const queryObject = {
      adults: options.composition.adults,
      agent: data.agent,
      children: options.composition.children,
      customerCode: data.customerCode,
      hotelCode: hotel.id,
      include: 'upgradeProducts,upgradeRequirements',
      infants: options.composition.infants,
      roomRates: {
        bucket: hotel.bucket,
        checkinDate,
        checkoutDate,
        hotelCode: hotel.id,
        rooms: data.rooms
      },
      ticketRates: {
        bucket: ticket.bucket || previousTicket.bucket,
        code: ticket.id || previousTicket.id,
        endDate: ticket.endDate || previousTicket.endDate,
        startDate: options.startDate || ticket.startDate || previousTicket.startDate
      }
    }

    // For resort hotel, we also send the room code
    if (hotel.roomId) {
      queryObject.roomRates.code = hotel.roomId
    }

    // Get the quantity based upgrades
    if (options.composition.cars || _.get(this.hasFeatures, 'upgrades.quantityBased')) {
      queryObject.quantity = options.composition.cars || 1
    }

    // If we have a quantity in our composition, add this to our queryObject
    if (options.composition.quantity || options.composition.quantityBased) {
      queryObject.quantity = options.composition.quantity || options.composition.quantityBased
    }

    if (this.queryStringParams.referrer) {
      queryObject.referrer = this.queryStringParams.referrer
    }

    // Transform the composition to ages, because the ages from the data are the hotel's composition ages, not the right
    // one for the events. Added 18 years old age for adults, 10 years old for children and 2 years old for infants
    queryObject['ages[]'] = [
      ...Array(Number(options.composition.adults)).fill(18),
      ...Array(Number(options.composition.children) || 0).fill(10),
      ...Array(Number(options.composition.infants) || 0).fill(2)
    ]

    return new Promise((resolve, reject) => {
      const params = routeHelpers.serialiseObject(queryObject)
      fetch(`/upgradeJson?${params}`)
        .then((response) => response.json())
        .then((response) => {
          // maintain a reference to request composition (needed in adding an extra night function)
          response.composition = options.composition

          resolve(response)
        })
        .catch((error) => reject(error))
    })
  }

  getUpdatedUpgrade (options) {
    this.setState(() => ({
      isLoading: true
    }))
    const { ticket } = this.state.harvestData.baskets
    this.getUpgrades(options)
      .then(response => {
        if (!_.get(response, 'upgradeRatesReply.upgradeRates', []).length > 0) {
          return this.setState(() => ({
            isLoading: false
          }))
        }

        // Get the upgrade rates only for the attractions with codes in groupIds array
        const upgradeRatesByCode = response.upgradeRatesReply.upgradeRates
          .filter(upgradeRate => options.groupIds.indexOf(upgradeRate.code) !== -1)

        // Filter the outdated upgradeRates and save the new
        // upgradeRates for the current attraction/attraction group
        upgradeRatesReply.upgradeRates = upgradeRatesReply.upgradeRates
          .filter(upgradeRate => options.groupIds.indexOf(upgradeRate.code) === -1)
          .concat(upgradeRatesByCode)

        const clonedUpgrades = _.cloneDeep(this.state.upgrades) || []
        const upgrades = clonedUpgrades.map(upgrade => {
          if (upgrade.id === options.id) {
            let updatedUpgrade

            // Find the upgradeRate
            updatedUpgrade = upgradesHelpers.getUpgradeRate({
              upgradeRates: upgradeRatesReply.upgradeRates,
              id: upgrade.selectedProductId,
              date: upgrade.selectedDate || options.startDate || ticket.startDate
            })

            // Update the upgrade with upgradeRate
            upgradesHelpers.applyUpgradeRate(upgrade, updatedUpgrade)
          }
          return upgrade
        })
        return this.setState(() => ({
          upgrades,
          isLoading: false
        }))
      })
      .catch(err => {
        console.error(err)
      })
  }

  getStayDates (extraNights) {
    const { roomRates } = this.state.harvestData.baskets.data
    let stayDates = {}
    Object.keys(extraNights).forEach(key => {
      const extraNight = extraNights[key]
      const position = (key === 'before') ? 'checkinDate' : 'checkoutDate'
      // If we have removed our extra night, then reset everything to default check-in / check-out date as per the roomRates
      if (!extraNight.isInBasket) {
        stayDates[position] = dateHelpers.sliceISO(roomRates[position])
      } else {
        // Set our new check-in / check-out date
        stayDates[position] = dateHelpers.sliceISO(extraNight.date)
      }
    })
    // Sets the number of nights to the difference between the checkinDate & checkoutDate
    stayDates.numberOfNights = moment(stayDates.checkoutDate).diff(moment(stayDates.checkinDate), 'days')
    return stayDates
  }

  addNotification (message) {
    const notificationMessages = this.state.notificationMessages

    // We do this so we can have a unique identifier for all of our notifications.
    const timestamp = new Date().getTime()

    // Push in our message and the unique timestamp.
    notificationMessages.push({ message, timestamp })
    this.setState(() => ({
      notificationMessages
    }))

    // Set the timeout for the notification to be removed from state again, and thus not displayed.
    setTimeout(() => this.removeNotification(timestamp), brandConfig.notificationTimeout)
  }

  removeNotification (timestamp) {
    // Filter through our messages based on timestamp and pop the message off.
    this.setState((prevState) => ({
      notificationMessages: prevState.notificationMessages.filter(notification => notification.timestamp !== timestamp)
    }))
  }

  handleCompositionChange (options) {
    const clonedUpgrades = _.cloneDeep(this.state.upgrades)
    const upgrades = clonedUpgrades.map(upgrade => {
      // If this isn't our upgrade, then we don't care.
      if (options.id !== upgrade.id) {
        return upgrade
      }

      const upgradeBoundaryMin = _.get(upgrade, `compositionBoundaries.min[${options.field}]`, null)
      const upgradeBoundaryMax = _.get(upgrade, `compositionBoundaries.max[${options.field}]`, null)

      let upgradeComposition = _.get(upgrade, `composition[${options.field}]`, 0)
      const stateComposition = _.get(this.state, `composition[${options.field}]`, 0)

      if (!upgrade.isAdultRequired) {
        const adultsComposition = _.get(upgrade, `composition['adults']`, 0)
        const childrenComposition = _.get(upgrade, `composition['children']`, 0)
        if (Number(adultsComposition) + Number(childrenComposition) <= 1) {
          upgradeComposition = 0
        }
      }

      // If we're incrementing, and we're not at our maximum allowed composition, lets let them update.
      if (options.increment && upgradeComposition !== stateComposition && (!upgradeBoundaryMax || upgradeComposition !== upgradeBoundaryMax)) {
        upgrade.composition[options.field]++
        if (options.field === 'quantity') {
          upgrade.totalPrice = parseFloat(upgradesHelpers.multiplyPriceByQuantity(upgrade.price, upgrade.composition.quantity)).toFixed(2)
        } else {
          // Update upgrade prices
          this.getUpdatedUpgrade({
            id: options.id,
            groupIds: upgrade.groupIds,
            composition: upgrade.composition
          })
        }
      } else if (!options.increment && upgradeComposition !== 0 && (!upgradeBoundaryMin || upgradeComposition > upgradeBoundaryMin)) {
        upgrade.composition[options.field]--
        // Update prices again
        if (options.field === 'quantity') {
          upgrade.totalPrice = parseFloat(upgradesHelpers.multiplyPriceByQuantity(upgrade.price, upgrade.composition.quantity)).toFixed(2)
        } else {
          // Update upgrade prices
          this.getUpdatedUpgrade({
            id: options.id,
            groupIds: upgrade.groupIds,
            composition: upgrade.composition
          })
        }
      }

      // The number of adults represents the number of cars in the composition
      if (options.field === 'cars' || options.field === 'quantity') {
        upgrade.composition.adults = upgrade.composition[options.field]
      }

      return upgrade
    })

    // Set the new compositon state which will update the stepper values.
    this.setState(() => ({
      upgrades
    }))
  }

  /**
   * Resets the composition to default for the provided upgrade id, and reassigns the default upgrade rate/price
   * @param {string} upgradeId
   */
  handleCompositionReset (upgradeId) {
    const clonedUpgrades = _.cloneDeep(this.state.upgrades)
    const upgrades = clonedUpgrades.map(upgrade => {
      if (upgrade.id !== upgradeId) return upgrade
      if (this.compositionNotChangedFromDefault(upgrade)) {
        upgrade.composition = Object.assign({}, this.state.composition) // reset to default
        return upgrade
      }

      const isQuantityBasedUpgrade = !!upgrade.composition.quantity
      upgrade.composition = Object.assign({}, this.state.composition)
      if (isQuantityBasedUpgrade) {
        upgrade.composition.adults = 1
        upgrade.composition.quantity = 1
      }

      if (isQuantityBasedUpgrade) {
        upgrade.price = parseFloat(upgradesHelpers.multiplyPriceByQuantity(upgrade.price, upgrade.composition.quantity)).toFixed(2)
        delete upgrade.totalPrice
      } else {
        this.getUpdatedUpgrade({
          id: upgradeId,
          groupIds: upgrade.groupIds,
          composition: upgrade.composition
        })
      }

      return upgrade
    })

    // Set the new compositon state which will update the stepper values.
    this.setState(() => ({
      upgrades
    }))
  }

  /**
   * Indicates if the composition is different from the default set on the container state
   * @param {object} upgrade
   * @returns {bool} is the composition different from the default
   */
  compositionNotChangedFromDefault (upgrade) {
    const defaultComposition = this.state.composition
    const isQuantityBasedUpgrade = !!upgrade.composition.quantity
    const isCompositionBasedUpgrade = !!upgrade.composition.adults

    return (isQuantityBasedUpgrade && upgrade.composition.quantity === 1) ||
      !isCompositionBasedUpgrade ||
      (defaultComposition.adults === upgrade.composition.adults &&
      defaultComposition.children === upgrade.composition.children)
  }

  /**
   * Triggered when the group upgrade selection is changed
   * For the previously selected group upgrade: reset the composition to default and reassign default rate/price
   * For the newly selected group upgrade: assign new product info, days, dates, composition, requirements and reset the composition to default
   * @param {string} id
   * @param {object} event
   */
  handleGroupUpgradeSelection (id, event, chosenGroupProductId = null) {
    const upgradeId = (event && event.target && event.target.value) || chosenGroupProductId
    if (!upgradeId) return

    // add applyRequirements as a callback to be called after the setState in handleDaysSelection in order to access updated state synchronously
    this.handleDaysSelection(id, event, () => {
      const upgrade = this.state.upgrades.find(upgrade => upgradeId === upgrade.id)

      // as the composition gets reset to default on changing the selection remove the totalPrice if it was set previously
      delete upgrade.totalPrice
      const upgradeRate = upgradesHelpers.getUpgradeRate({
        date: upgrade.selectedDate,
        id: upgrade.id,
        upgradeRates: upgradeRatesReply.upgradeRates
      })

      if (upgradeRate) {
        upgradesHelpers.applyUpgradeRate(upgrade, upgradeRate)
      }

      upgradesHelpers.applyRequirements(upgradeRatesReply, upgrade)
    }, chosenGroupProductId)
  }

  handleDaysSelection (id, event, callback, chosenGroupProductId = null) {
    const productId = (event && event.target && event.target.value) || chosenGroupProductId
    if (!productId) callback()

    const clonedUpgrades = _.cloneDeep(this.state.upgrades)
    const upgrades = clonedUpgrades.map(upgrade => {
      if (id === upgrade.id) {
        if (upgrade.isSwappableTicket) {
          upgradesHelpers.upgradeSelectedSwappableTicketChange(upgrade, productId, this.state.swappableTicketUpgrades)
        } else {
          upgradesHelpers.upgradeSelectedProductChange(upgrade, productId, upgradeRatesReply, harvestBasketData.ticket)
          const { composition, groupIds, id } = upgrade
          this.getUpdatedUpgrade({ composition, groupIds, id, startDate: upgrade.selectedDate })
        }
      }
      return upgrade
    })

    this.setState(() => ({
      upgrades
    }), callback)
  }

  handleDateSelection (id, event) {
    const selectedDate = event.target.value
    const clonedUpgrades = _.cloneDeep(this.state.upgrades)
    const upgrades = clonedUpgrades.map(upgrade => {
      if (id === upgrade.id) {
        upgrade.selectedDate = selectedDate

        // Find the upgradeRate
        const upgradeRate = upgradesHelpers.getUpgradeRate({
          date: upgrade.selectedDate,
          id: upgrade.id,
          upgradeRates: upgradeRatesReply.upgradeRates
        })

        if (upgradeRate) {
          // No XHR req
          upgradesHelpers.applyUpgradeRate(upgrade, upgradeRate)
        } else {
          // XHR req
          this.getUpdatedUpgrade({
            composition: upgrade.composition,
            groupIds: upgrade.groupIds,
            id: upgrade.id,
            startDate: upgrade.selectedDate
          })
        }
      }
      return upgrade
    })
    this.setState(() => ({
      upgrades
    }))
  }

  handleUpgradeCTA (id, event) {
    event && event.preventDefault()
    const clonedUpgrades = _.cloneDeep(this.state.upgrades)

    const upgrades = clonedUpgrades.map((upgrade, index, clonedUpgrades) => {
      if (id === upgrade.selectedProductId) {
        let action = upgrade.isInBasket ? 'removed from' : 'added to'

        // Season pass isn't technically an upgrade, so will need a different message
        if (upgrade.isSwappableTicket) {
          action = upgrade.isInBasket ? 'removed from' : 'applied to'
        }

        this.addNotification(`${upgrade.title} has been ${action} your basket`)
        upgrade.isInBasket = !upgrade.isInBasket

        // @todo: HACK FOR CHESS GROTTO WORK IN THIS METHOD (FP-10552). THIS IS KNOWN AND WILL NEED TO BE CLEANED UP AT SOME POINT.
        // @todo: Is the selected upgrade part of a Chess Grotto Timeslot group? If so, disable the opposite timeslot group
        let timeSlot
        if (upgrade.group === 'GROUPCHESSINGTONUPGRADEGROTTOMORNING') {
          timeSlot = 'EVENING'
        }
        if (upgrade.group === 'GROUPCHESSINGTONUPGRADEGROTTOEVENING') {
          timeSlot = 'MORNING'
        }
        const requiredGroup = timeSlot && clonedUpgrades.find(upgrade => upgrade.group === `GROUPCHESSINGTONUPGRADEGROTTO${timeSlot}`)
        if (requiredGroup) requiredGroup.isDisabled = upgrade.isInBasket
      }
      return upgrade
    })
    this.setState(() => ({
      upgrades
    }))

    const swappableTicketGroupedUpgrade = clonedUpgrades.find(upgrade => id === upgrade.selectedProductId && upgrade.isSwappableTicket)
    const upgradeIsSwappableTicket = swappableTicketGroupedUpgrade && swappableTicketGroupedUpgrade.groupUpgradeProducts.find(groupUpgradeProduct => id === groupUpgradeProduct.id)

    // Handle updating our package to a swappable ticket
    if (upgradeIsSwappableTicket && Object.keys(upgradeIsSwappableTicket).length > 0) {
      let extraNights = swappableTicketGroupedUpgrade.extraNights
      let ticket = upgradeIsSwappableTicket
      const newClonedUpgrades = _.cloneDeep(upgrades)
      let otherSwappableTicketsIsAvailable = false
      // Revert to original when user removes the swappable ticket from the basket
      if (!swappableTicketGroupedUpgrade.isInBasket) {
        extraNights = this.state.previousExtraNights
        ticket = this.state.previousTicket
        otherSwappableTicketsIsAvailable = true
        trackingHelpers.track('sb.track', `Downgraded from - ${this.state.ticket.id}`, 'Swappable ticket', this.state.ticket.originalPrice)
      } else {
        trackingHelpers.track('sb.track', `Upgraded from - ${this.state.ticket.id}`, 'Swappable ticket', harvestBasketData.grossPrice)
      }
      // Make swappable tickets mutually exclusive
      const updatedUpgrades = newClonedUpgrades.map(upgrade => {
        // for all swappable tickets except for the one we're adding to the basket
        if (upgrade.isSwappableTicket && upgrade.id !== swappableTicketGroupedUpgrade.id) {
          // disable or enable the 'Add' button of the swappable ticket depending on whether we're
          // adding or removing other swappable ticket to/from the basket
          upgrade.isAvailable = otherSwappableTicketsIsAvailable
          // just to be sure that only 1 swappable ticket is in the basket
          upgrade.isInBasket = false
        }
        return upgrade
      })
      extraNights.before.isInBasket = this.state.extraNights.before.isInBasket
      extraNights.after.isInBasket = this.state.extraNights.after.isInBasket

      this.setState(() => ({
        upgrades: updatedUpgrades,
        extraNights,
        previousExtraNights: this.state.extraNights,
        previousTicket: this.state.ticket,
        ticket
      }))
    }
  }

  handleExtraNightCTA (isNightBefore, event) {
    event.preventDefault()

    const extraNights = this.state.extraNights
    const extraNight = isNightBefore ? 'before' : 'after'
    const oppositeNight = (extraNight === 'before') ? 'after' : 'before'
    const action = extraNights[extraNight].isInBasket ? 'removed from' : 'added to'
    let removedUpgrade = false

    // Alert the user that we will be removing the opposite selected night if they already have one in their basket
    // This is required for feature parity, but should be looked at so we can offer an extra night at the start and end
    /*eslint-disable */
    // Disable eslint as it complains about alert being here.
    if (extraNights[oppositeNight].isInBasket) {
      const mutuallyExclusiveMessage = this.props.intl.formatMessage({ id: 'upgrades.mutuallyExclusiveExtraNights' })
      window.alert(mutuallyExclusiveMessage)
    }
    /* eslint-enable */

    // Always assign the opposite night to false and ensure the current one they are interacting with is switched out
    const extraNightsToSet = Object.assign({}, extraNights, {
      [extraNight]: Object.assign({}, extraNights[extraNight], {
        isInBasket: !extraNights[extraNight].isInBasket
      }),
      [oppositeNight]: Object.assign({}, extraNights[oppositeNight], {
        isInBasket: false
      })
    })

    // Get the check-in, check-out dates including the extra night
    const stayDates = this.getStayDates(extraNightsToSet)

    this.setState((prevState) => {
      const returnState = _.cloneDeep(prevState)
      // Update important information such as check in date, the amount of nights the user will be staying and set extra night's state to change the isInBasket value
      _.set(returnState, 'harvestData.baskets.data.hotel.nights', stayDates.numberOfNights)
      return Object.assign({}, returnState, {
        extraNights: extraNightsToSet,
        checkinDate: stayDates.checkinDate,
        checkoutDate: stayDates.checkoutDate,
        nights: stayDates.numberOfNights
      })
    })

    // This is about the upgrades, which don't rely on composition nad haven't amend options
    const emptyCompositionHash = '0-0-0'
    // That's the default package's composition
    const defaultCompositionHash = `${parseInt(this.state.composition.adults)}-${parseInt(this.state.composition.children)}-${parseInt(this.state.composition.infants)}`

    // Get all the unique compositions based on which we will get the upgrades rates with the
    // updated checkin and checkout dates
    const upgradesQueryOptions = this.state.upgrades.reduce((queriesOptions, upgrade) => {
      const compositionHash = upgradesHelpers.getCompositionHash(upgrade.composition)
      // If the upgrade's composition is different, than the default composition we have to
      // make another request to get the upgrades rates for the specific upgrade's composition
      if (compositionHash !== emptyCompositionHash && !queriesOptions[compositionHash] && (!parseInt(upgrade.composition.cars) || parseInt(upgrade.composition.cars) > 1)) {
        queriesOptions[compositionHash] = {
          composition: upgrade.composition,
          checkinDate: stayDates.checkinDate,
          checkoutDate: stayDates.checkoutDate
        }
      }

      return queriesOptions
    }, {
      [defaultCompositionHash]: {
        composition: this.state.composition,
        checkinDate: stayDates.checkinDate,
        checkoutDate: stayDates.checkoutDate
      }
    })

    this.setState(() => ({
      isLoading: true
    }))

    // build a list of promises to query the /upgradeJson endpoint
    const upgradeRequests = Object.keys(upgradesQueryOptions).map((compositionHash) => {
      const { composition, checkinDate, checkoutDate } = upgradesQueryOptions[compositionHash]
      return this.getUpgrades({ composition, checkinDate, checkoutDate })
    })

    // wait for all upgrade requests to be resolved
    Promise.all(upgradeRequests)
      .then((upgradeResponses) => {
        const upgradesRatesByComposition = upgradeResponses.reduce((result, response) => {
          const compositionHash = upgradesHelpers.getCompositionHash(response.composition)
          result[compositionHash] = _.get(response, 'upgradeRatesReply.upgradeRates', [])
          return result
        }, {})

        // for each of the upgrades find the associated upgrade response, by composition and code
        upgradeRatesReply.upgradeRates = this.state.upgrades.reduce((upgradeRates, upgrade) => {
          const upgradeRatesByComposition = upgradesRatesByComposition[upgradesHelpers.getCompositionHash(upgrade)] || upgradesRatesByComposition[defaultCompositionHash]

          if (upgradeRatesByComposition) {
            const upgradeRatesForUpgrade = upgradeRatesByComposition.filter((upgradeRate) => upgrade.groupIds.indexOf(upgradeRate.code) !== -1)
            upgradeRates = upgradeRates.concat(upgradeRatesForUpgrade)
          }
          return upgradeRates
        }, [])

        // Get the ids of the upgrades, which are available
        const availableUpgradesIds = upgradeRatesReply.upgradeRates.map((upgradeRate) => upgradeRate.code)

        // assign new values to the upgrades state property
        const upgrades = this.state.upgrades.reduce((upgrades, upgrade) => {
          // Remove the upgrade's groupIds, which are no longer available
          upgrade.groupIds = upgrade.groupIds.filter((id) => availableUpgradesIds.indexOf(id) !== -1)

          // If the upgrade is no longer available
          if (!upgrade.groupIds || !upgrade.groupIds.length) {
            removedUpgrade = true
            return upgrades
          }

          // Generate dates array and add it like a property to the attraction
          upgradesHelpers.applyUpgradeRateDates(upgrade, upgradeRatesReply.upgradeRates, this.state.ticket, !isNightBefore)

          // Find the upgradeRate
          const upgradeRate = upgradesHelpers.getUpgradeRate({
            upgradeRates: upgradeRatesReply.upgradeRates,
            id: upgrade.id,
            date: upgrade.selectedDate
          })

          if (upgradeRate) {
            // The upgrade is still available for the event dates
            upgradesHelpers.applyUpgradeRate(upgrade, upgradeRate)
            upgrades.push(upgrade)
          } else {
            // Check if there is another upgrade from the same group
            if (upgrade.groupIds.length > 1) {
              const upgradeDays = upgradesHelpers.getSortedUpgradesDays(upgradeRatesReply.upgradeRates, upgrade)

              if (upgradeDays && upgradeDays.length) {
                // We still have an available upgrade from the upgrade's group
                const productId = isNightBefore ? upgradeDays[0].id : upgradeDays[upgradeDays.length - 1].id

                // Set the upgrade's days
                upgrade.days = upgradeDays

                // Change the selected days
                upgradesHelpers.upgradeSelectedProductChange(upgrade, productId, upgradeRatesReply, this.state.ticket)

                // Find the upgradeRate
                const upgradeRate = upgradesHelpers.getUpgradeRate({
                  upgradeRates: upgradeRatesReply.upgradeRates,
                  id: upgrade.id,
                  date: upgrade.selectedDate
                })

                upgradesHelpers.applyUpgradeRate(upgrade, upgradeRate)
                upgrades.push(upgrade)
              } else {
                removedUpgrade = true
              }
            } else {
              // If the upgrade doesn't exist this should mean, that it's
              // not available anymore for the specific dates and/or composition
              removedUpgrade = true
            }
          }
          return upgrades
        }, [])

        const swappableTicketGroupUpgrades = this.convertSwappableTicketsToUpgrades()
        let clonedUpgrades = _.cloneDeep(upgrades)
        if (swappableTicketGroupUpgrades.length) {
          const swappableTicketInBasket = swappableTicketGroupUpgrades.find(upgrade => upgrade.isInBasket)
          const swappableUpgrades = swappableTicketGroupUpgrades.map(swappableUpgrade => {
            if (swappableTicketInBasket && swappableTicketInBasket.id !== swappableUpgrade.id) {
              // disable 'Add' button
              swappableUpgrade.isAvailable = false
            }
            return swappableUpgrade
          })
          clonedUpgrades = swappableUpgrades.concat(clonedUpgrades)

          // make sure that the seasons passes are the correct order
          this.sortUpgrades(clonedUpgrades)
        }

        // Finally, should this all succeed lets notify the user that their extraNight has been added or removed.
        this.addNotification(`An extra night at ${this.state.harvestData.baskets.data.hotel.name} has been ${action} your basket`)
        this.setState(() => ({
          isLoading: false,
          upgrades: clonedUpgrades
        }))

        // If we have update, which is no longer available
        if (removedUpgrade) {
          this.addNotification('An upgrade has been removed from your basket, because it was no longer available')
        }
      })
      .catch(err => {
        console.error(err)
      })
  }

  calculateBasketTotals (packagePrice, upgradesInBasket, standardPrice = null) {
    // Parse float to eliminate any potential bugs
    let basePrice = parseFloat(packagePrice)
    let baseStandardPrice
    if (standardPrice) {
      baseStandardPrice = parseFloat(standardPrice)
    }

    // Loop over our upgrades currently in the basket
    upgradesInBasket.forEach(upgrade => {
      // if the upgrade has a standardPrice, we should add that to the existing standardPrice
      if (upgrade.price) {
        // if we don't have a standardPrice already, we need to set it to the basePrice and then use the upgrade standardPrice (in case package isn't discounted but upgrade is)
        if (!baseStandardPrice) {
          baseStandardPrice = basePrice
        }
        baseStandardPrice += parseFloat(upgrade.totalPrice || upgrade.price)
      }
      // increment the basePrice by the upgrade price
      basePrice += parseFloat(upgrade.totalPrice || upgrade.price)
    })

    // Check out our extra nights and add the price of one if we have it in basket
    Object.keys(this.state.extraNights).forEach(key => {
      const extraNight = this.state.extraNights[key]
      if (extraNight.isInBasket) {
        if (extraNight.standardPrice) {
          // if we don't have a standardPrice already, we need to set it to the basePrice and then use the extraNight standardPrice (in case package isn't discounted but extra night is)
          if (!baseStandardPrice) {
            baseStandardPrice = basePrice
          }
          baseStandardPrice += parseFloat(extraNight.standardPrice)
        } else if (baseStandardPrice) {
          // if we have a discount, but the extraNight is not discounted, just add the grossPrice to the discounted packageTotal
          baseStandardPrice += parseFloat(extraNight.grossPrice)
        }
        basePrice += parseFloat(extraNight.grossPrice)
      }
    })

    // return updated price
    const reply = {
      grossPrice: basePrice
    }
    if (baseStandardPrice) {
      reply.standardPrice = parseFloat(baseStandardPrice)
    }
    return reply
  }

  renderUpgradesStepper () {
    return <div id='upgrades-stepper' className='panel panel-default'>
      {this.props.progressTracker}
      <div className='panel-heading'>
        <BreakPoint.isAtLeastSm>
          <FormattedMessage id='upgrades.stepperHeading' tagName='h1' />
        </BreakPoint.isAtLeastSm>
        <BreakPoint.isXs>
          <FormattedMessage id='upgrades.stepperHeadingShort' tagName='h1' />
        </BreakPoint.isXs>
      </div>
      <div className='panel-body pt-xs-0'>

        <div className='row'>
          <div className='col-sm-4 col-md-6'>
            <span className='hidden-xs lead'>
              <FormattedMessage id='upgrades.recommendedAdditions' />
            </span>
          </div>
          <div className='col-sm-8 col-md-6'>
            <Button
              block
              bsStyle='primary'
              {...trackingHelpers.getAttributes('Proceed To Payment Direct', 'Upgrades', 'top-button')}
              onClick={this.checkUpgradesInBasket}
              onKeyDown={(e) => e.key === 'Enter' && this.checkUpgradesInBasket()}
            >
              <FormattedMessage id='common.proceedToPayment' />
            </Button>
          </div>
        </div>
      </div>
    </div>
  }

  shouldComponentUpdate (nextProps, nextState) {
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)
  }

  render () {
    if (!this.state.harvestData) return null
    const upgradesInBasket = (this.state.upgrades || []).filter(upgrade => upgrade.isInBasket && !upgrade.isIncludedInPackage)
    const packageIncludedUpgrades = (this.state.upgrades || []).filter(upgrade => upgrade.isInBasket && upgrade.isIncludedInPackage)
    const packageIncludedVisibleUpgrades = packageIncludedUpgrades.filter(upgrade => upgrade.isSummaryVisibleUpgrade)
    const basketTotals = this.calculateBasketTotals(this.state.harvestData.baskets.data.grossPrice, upgradesInBasket, this.state.harvestData.baskets.data.standardPrice)

    // Filter out upgrades we don't want to display in the basket (e.g. Season pass or night based ones)
    const upgradesToDisplayInBasket = upgradesInBasket.filter(upgrade => {
      return !upgrade.isSwappableTicket && (upgrade.isNightsBased !== 'true' && upgrade.isNightsBased !== true)
    })
    const showUpgradeSummary = upgradesToDisplayInBasket.length > 0 || this.state.extraNights.before.isInBasket || this.state.extraNights.after.isInBasket

    const clonedUpgrades = _.cloneDeep(upgradesToDisplayInBasket)
    const clonedPackageIncludedUpgrades = _.cloneDeep(packageIncludedUpgrades)
    const orderedUpgradesToDisplayInBasket = clonedUpgrades.sort(sortingHelpers.sortByDate('selectedDate'))
    const orderedUpgradesToDisplayInBookingSummary = clonedUpgrades.concat(clonedPackageIncludedUpgrades).sort(sortingHelpers.sortByDate('selectedDate'))

    const proceedToPaymentButton = (
      <Button
        block
        bsStyle='primary'
        className='hidden-xs'
        {...trackingHelpers.getAttributes('Proceed To Payment Direct', 'Upgrades', 'top-button')}
        onClick={this.checkUpgradesInBasket}
        onKeyDown={(e) => e.key === 'Enter' && this.checkUpgradesInBasket()}
      >
        {this.ctaButtonBook
          ? <FormattedMessage id='common.book' />
          : <FormattedMessage id='common.proceedToPayment' />}
      </Button>
    )

    const hideBasketSVGs = this.hasFeatures.hideBasketSVGs
    const checkinDate = moment.utc(this.state.checkinDate || _.get(this.state, 'harvestData.baskets.data.roomRates.checkinDate', ''))
    const checkoutDate = moment.utc(this.state.checkoutDate || _.get(this.state, 'harvestData.baskets.data.roomRates.checkoutDate', ''))

    const wasText = this.props.isAnnualPass ? <FormattedMessage id='common.standardPrice' /> : <FormattedMessage id='common.was' />

    const eventDisplay = (this.hasParkEntry)
      ? (<span className='event-name'>{this.state.ticket.name}</span>)
      : (<div><strong><FormattedMessage id='common.ticket' />: </strong><span className='event-name'>{this.state.ticket.name}</span></div>)

    // check for presence of alternative basket message and make sure it isn't an empty string
    const basketAlternativeMessage = /([^\s])/.test(this.state.ticket.basketAlternativeMessage) ? this.state.ticket.basketAlternativeMessage : false
    const hasFixedSummary = this.hasFeatures.hasFixedSummary
    const roomDescriptions = hasFixedSummary ? basketHelpers.getPartyPerRoom(roomTypes, harvestBasketData.rooms) : basketHelpers.getRoomsDescriptions(roomTypes, harvestBasketData.rooms)
    const partyComposition = _.get(brandConfig, 'secure.withChildAges') ? this.props.hotelCompositionString : this.props.ticketCompositionString
    const dateSpecificInformation = _.get(eventProduct, 'dateSpecificInformation', {})
    const extraNightMessage = _.get(dateSpecificInformation, 'extraNights', null)
    const showUpgradesStepper = _.get(brandConfig, 'secure.hasFeatures.upgrades.showUpgradesStepper', true)
    const fixedCtaButton = _.get(brandConfig, 'secure.hasFeatures.upgrades.fixedCtaButton', false)

    // @todo: Hack for Chess Grotto
    const { startDate, endDate } = this.state.ticket || {}
    let swappableTicketUpgradeTicket = {}
    const isSwappableTicket = _.get(this.state, 'ticket.isSwappableTicket', false)
    if (isSwappableTicket) {
      swappableTicketUpgradeTicket = Object.assign({
        handleUpgradeCTA: this.handleUpgradeCTA,
        swappableTicketUpgradeId: this.state.ticket.id
      })
    }

    const basketComponent = /* @todo: Move inline styles */
      <div style={{ zIndex: 1, position: 'relative' }}>
        {this.hasFeatures.hasUpdatedBookingSummary
          ? <BookingSummary
            bestPricePromise={this.hasFeatures.bestPricePromise}
            cancellationWaiverPrice={isSwappableTicket ? null : this.cancellationWaiver.grossPrice}
            cateringDescription={hotelProduct.facilities.hasBreakfastIncluded && hotelProduct.breakfastType}
            cateringDetails={hotelProduct.facilities.hasBreakfastIncluded && hotelProduct.breakfast_details_booking_flow}
            checkInDate={checkinDate}
            checkInTime={this.hotelEventProducts.checkinTime || hotelProduct.checkinTime}
            checkOutDate={checkoutDate}
            checkOutTime={this.hotelEventProducts.checkoutTime || hotelProduct.checkoutTime}
            ctaButton={proceedToPaymentButton}
            discountMessage={this.state.ticket.summaryDiscountMessage}
            endpoint={endpoint}
            grossPrice={basketTotals.grossPrice}
            handleRemoveUpgrade={this.handleUpgradeCTA}
            hasCancellationProtectionMessage={this.hasFeatures.hasCancellationProtectionMessage}
            hasFixedSummary={this.hasFeatures.hasFixedSummary}
            hasInitialBasketCollapsed={this.hasFeatures.hasInitialBasketCollapsed}
            hasParkEntry={this.hasParkEntry}
            hasYourStay={this.hasFeatures.summary && this.hasFeatures.summary.hasYourStay}
            hotelName={hotelProduct.name}
            roomDescriptions={roomDescriptions}
            roomName={_.get(harvestBasketData, 'hotel.roomThemeName')}
            showSectionPrices={this.hasFeatures.basket && this.hasFeatures.basket.showSectionPrices}
            standardPrice={basketTotals.standardPrice}
            ticketAdditionalDetails={eventProduct.additionalDescription || ''}
            ticketName={this.state.ticket.name}
            upgrades={orderedUpgradesToDisplayInBookingSummary}
            wasText={wasText}
          />
          : <Basket
            basketAlternativeMessage={basketAlternativeMessage}
            button={proceedToPaymentButton}
            cancellationWaiverPrice={isSwappableTicket ? null : this.cancellationWaiver.grossPrice}
            category={eventProduct.category}
            cateringDescription={hotelProduct.facilities.hasBreakfastIncluded && hotelProduct.breakfastType}
            checkInDate={checkinDate}
            checkInTime={this.hotelEventProducts.checkinTime || hotelProduct.checkinTime}
            checkOutDate={checkoutDate}
            checkOutTime={this.hotelEventProducts.checkoutTime || hotelProduct.checkoutTime}
            discountMessage={this.state.ticket && this.state.ticket.summaryDiscountMessage &&
              <Basket.SummaryDiscountMessage
                message={this.state.ticket.summaryDiscountMessage}
              />
            }
            endpoint={endpoint}
            eventTitle={eventDisplay}
            extraNights={this.state.extraNights}
            grossPrice={basketTotals.grossPrice}
            handleExtraNightCTA={this.handleExtraNightCTA}
            handleUpgradeCTA={this.handleUpgradeCTA}
            hasBestPricePromise={this.hasFeatures.bestPricePromise}
            hasFixedSummary={hasFixedSummary}
            hasParkEntry={this.hasParkEntry}
            hideBasketSVGs={hideBasketSVGs}
            hotelEventDescription={this.hotelEventProducts.description || null}
            isSummaryTitleClickable={this.state.isSummaryTitleClickable}
            name={hotelProduct.name}
            packageIncludedVisibleUpgrades={packageIncludedVisibleUpgrades}
            partyComposition={partyComposition}
            roomDescriptions={roomDescriptions}
            roomName={_.get(harvestBasketData, 'hotel.roomThemeName', null)}
            swappableTicketUpgradeTicket={swappableTicketUpgradeTicket}
            shouldBasketExpand={false}
            showUpgradeSummary={showUpgradeSummary}
            standardPrice={basketTotals.standardPrice}
            summaryTitle='Upgrade Information'
            ticketName={this.state.ticket.name}
            upgrades={orderedUpgradesToDisplayInBasket}
            venueType={venueProduct.venueType}
            wasText={wasText}
            whatsIncluded={_.get(this.state, 'ticket.whatsIncluded', eventProduct.whatsIncluded)}
          />
        }
      </div>

    const basicFixedSummaryStepperClasses = classNames('basket-summary-fixed affix hidden-sm hidden-md hidden-lg', { 'hide-animate': this.state.basicFixedSummaryStepperHide })

    return (
      <div>
        {this.state.isLoading && <Loading />}
        <div className='notifications'>
          {this.state.notificationMessages.map((notification, index) => (
            <Alert className='upgrade-notifications__message' bsStyle='info' key={`alert${index}`}>
              <p>{notification.message}</p>
            </Alert>
          ))}
        </div>

        {this.basicFixedSummaryStepper && query.isXs() &&
          <aside className={basicFixedSummaryStepperClasses} data-spy='affix'>
            <Panel className='mb-0'>
              <Panel.Body className='py-2'>
                <div className='text-right mb-2'>
                  <span className='small'>
                    <FormattedMessage id='common.totalPrice' />:
                  </span>
                  <span className='h2 d-block m-0'>
                    <DisplayPrice price={basketTotals.grossPrice} />
                  </span>
                </div>

                <Button
                  block
                  bsStyle='primary'
                  className=''
                  {...trackingHelpers.getAttributes('Proceed To Payment Direct', 'Upgrades', 'basicFixedSummaryStepper')}
                  onClick={this.checkUpgradesInBasket}
                  onKeyDown={e => e.key === 'Enter' && this.checkUpgradesInBasket()}>
                  <FormattedMessage id='common.proceedToPayment' />
                </Button>

                {this.hasCancellationProtectionMessage && this.cancellationWaiverAmount &&
                  <Col xs={12} className='small text-center text-success d-inline-block mt-2 mt-sm-3'>
                    <Col xs={9} sm={12} lg={10} className='d-inline-block float-none'>
                      <FormattedMessage id='basket.protectYourBooking' tagName='strong' values={{ cancellationWaiverPrice: this.cancellationWaiverAmount }} />
                    </Col>
                  </Col>
                }
              </Panel.Body>
            </Panel>
          </aside>
        }

        <main role='main' id='main'>
          <div className='row'>
            <div className='col-xs-12 col-md-4 col-md-push-8'>
              {hasFixedSummary && basketComponent}
              <BreakPoint.isAtMostSm>
                {!showUpgradesStepper && this.props.progressTracker}
                {showUpgradesStepper && this.renderUpgradesStepper()}
              </BreakPoint.isAtMostSm>
              {!hasFixedSummary && basketComponent}
            </div>
            <div className='col-xs-12 col-md-8 col-md-pull-4'>
              <BreakPoint.isAtLeastMd>
                {!showUpgradesStepper && this.props.progressTracker}
                {showUpgradesStepper && this.renderUpgradesStepper()}
              </BreakPoint.isAtLeastMd>
              {harvestBasketData &&
                <div className='mt-3'>
                  <UpgradeList
                    childrenMaxAge={this.childrenMaxAge}
                    childrenMinAge={this.childrenMinAge}
                    defaultComposition={this.state.composition}
                    endDate={endDate} // @todo: Needed for Chess Grotto Hack
                    extraNightButtonSmallSize={this.extraNightButtonSmallSize}
                    extraNightMessage={extraNightMessage}
                    extraNights={this.state.extraNights}
                    featuredEventUpgradesTitle={eventProduct.featuredEventUpgradesTitle}
                    handleCompositionChange={this.handleCompositionChange}
                    handleDateSelection={this.handleDateSelection}
                    handleDaysSelection={this.handleDaysSelection}
                    handleExtraNightCTA={this.handleExtraNightCTA}
                    handleGroupUpgradeSelection={this.handleGroupUpgradeSelection}
                    handleUpgradeCTA={this.handleUpgradeCTA}
                    hasHotelTile={this.hasHotelTile}
                    hasUpgradesExtraNights={this.hasUpgradesExtraNights}
                    hotelCode={harvestBasketData.hotel.id}
                    hotelImage={this.hotelImage}
                    hotelName={harvestBasketData.hotel.name}
                    isExtraNightsVisible={harvestBasketData.hotel.nights < 5}
                    listLayout={this.listLayout}
                    modalState={this.state.upgradeModal}
                    startDate={startDate} // @todo: Needed for Chess Grotto Hack
                    thorpeBreakfastAddons={this.thorpeBreakfastAddons}
                    warwickBreakfastAddons={this.warwickBreakfastAddons}
                    upgrades={this.state.upgrades}
                    upgradeTitleChange={this.upgradeTitleChange}
                    wasText={wasText}
                  />
                  <ResponsiveWaypoint
                    breakPoint='isXs'
                    bottomOffset='9999px'
                    onEnter={() => this.setState(() => ({ basicFixedSummaryStepperHide: true }))}
                    onLeave={() => this.setState(() => ({ basicFixedSummaryStepperHide: false }))}>
                    {fixedCtaButton
                      ? <div className='pos-fix fixed-cta hidden-md hidden-lg'>
                        <div className='panel panel-default mb-0'>
                          <div className='panel-body'>
                            <div className='pl-0 col-xs-5'>
                              <FormattedMessage id='common.totalPrice' />
                              <span className='h2 d-block m-0'>
                                <DisplayPrice price={basketTotals.grossPrice} />
                              </span>
                            </div>
                            <div className='p-0 col-xs-7'>
                              <Button
                                block
                                bsStyle='primary'
                                bsSize='medium'
                                className='hidden-md hidden-lg'
                                {...trackingHelpers.getAttributes('Proceed To Payment Direct', 'Upgrades', 'top-button')}
                                onClick={this.checkUpgradesInBasket}
                                onKeyDown={e => e.key === 'Enter' && this.checkUpgradesInBasket()}>
                                <FormattedMessage id='common.book' />
                              </Button>
                            </div>
                          </div>
                        </div>
                      </div>
                      : <Button
                        block
                        bsStyle='primary'
                        bsSize='large'
                        className='hidden-md hidden-lg'
                        {...trackingHelpers.getAttributes('Proceed To Payment Direct', 'Upgrades', 'top-button')}
                        onClick={this.checkUpgradesInBasket}
                        onKeyDown={e => e.key === 'Enter' && this.checkUpgradesInBasket()}>
                        <FormattedMessage id='common.proceedToPayment' />
                      </Button>
                    }
                  </ResponsiveWaypoint>
                  <br className='hidden-md hidden-lg' />
                </div>
              }
            </div>
          </div>
        </main>
      </div>
    )
  }
}

UpgradesContainer.propTypes = {
  intl: intlShape,
  isAnnualPass: PropTypes.bool.isRequired,
  navigateTo: PropTypes.func.isRequired,
  progressTracker: PropTypes.element.isRequired
}

function mapStateToProps (state) {
  const { hotelPartyString, ticketPartyString } = state.basket
  return {
    hotelCompositionString: hotelPartyString,
    isAnnualPass: state.annualPass.isAnnualPass,
    ticketCompositionString: ticketPartyString
  }
}

export default connect(mapStateToProps)(injectIntl(UpgradesContainer))
export {
  UpgradesContainer
}
