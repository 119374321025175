import { PureComponent } from 'react'
import ReactDOM from 'react-dom'

// Search Form 3: needs to be adjusted afterwards.
class Portal extends PureComponent {
  render () {
    if (!this.props.domNode || !this.props.children) return null
    return ReactDOM.createPortal(
      this.props.children,
      this.props.domNode
    )
  }
}

export default Portal
