import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGMoon extends PureComponent {
  render () {
    return (
      <SVG {...this.props}>
        <path d='M402.336 396c0-170.8 110.855-315.909 265.914-371.473C624.765 8.935 577.986 0 528.957 0 305.167 0 123.75 177.309 123.75 396c0 218.716 181.417 396 405.207 396 49.029 0 95.808-8.935 139.293-24.527C513.191 711.909 402.336 566.8 402.336 396zm73.606 346.5C353.603 733.59 174.413 587.367 174.413 396S349.767 60.217 475.967 49.5c37.942-3.218 17.895-3.094 53.015 0-82.591 52.049-177.136 197.06-177.136 346.5s68.755 287.57 177.135 346.5c-33.387 3.094-16.706 2.648-53.039 0z' />
      </SVG>
    )
  }
}

SVGMoon.defaultProps = {
  title: 'Moon Icon',
  height: '44',
  width: '44',
  viewBox: '0 0 792 792'
}

SVGMoon.propTypes = SVG.propTypes

export default SVGMoon
