import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGCar extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <path d='M13 3H6v18h4v-6h3c3.31 0 6-2.69 6-6s-2.69-6-6-6zm.2 8H10V7h3.2c1.1 0 2 .9 2 2s-.9 2-2 2z' />
      </SVG>
    )
  }
}

SVGCar.propTypes = SVG.propTypes

SVGCar.defaultProps = {
  title: 'Car Icon'
}

export default SVGCar
