import React from 'react'
import PropTypes from 'prop-types'
import Room from './Room'

const Rooms = props => {
  const list = []
  for (let roomNumber = 0; roomNumber < props.maxRooms; roomNumber++) {
    if (props.suggestions[roomNumber] && props.suggestions[roomNumber].length) {
      list.push(
        <Room
          clearable={roomNumber !== 0}
          hideLabel={props.hideLabel || roomNumber !== 0}
          id={`${props.id}_${roomNumber}`}
          key={roomNumber}
          label={props.label}
          onChange={props.onChange.bind(this, roomNumber)}
          options={(props.suggestions[roomNumber] || []).map(suggestion => {
            return {
              label: suggestion.roomDescription,
              value: suggestion.code
            }
          })}
          selected={props.rooms[roomNumber] ? props.rooms[roomNumber].code : ''} />
      )
    }
  }

  return (
    <div>{list}</div>
  )
}

Rooms.defaultProps = {
  hideLabel: false,
  label: 'Rooms',
  maxRooms: 3,
  onChange: () => {},
  suggestions: []
}

Rooms.propTypes = {
  maxRooms: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  suggestions: PropTypes.array.isRequired,
  rooms: PropTypes.array.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  hideLabel: PropTypes.bool.isRequired
}

export default Rooms
