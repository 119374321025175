const chat = (state, action) => {
  state = state || {
    visible: false,
    minimized: true
  }

  switch (action.type) {
    case 'CHAT_TOGGLE':
      const isVisible = state.visible
      return {
        ...state,
        visible: !isVisible
      }
    case 'CHAT_MINIMIZE':
      const isMinimized = state.minimized
      return {
        ...state,
        minimized: !isMinimized
      }
    default:
      return state
  }
}

export default chat
