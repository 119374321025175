import _ from 'lodash'
import moment from 'moment'
import roomify from 'roomify'
import engineHelpers from '../helpers/engineHelpers'
import tracking from './tracking'
import config from '../configs/config'

const withChildAges = _.get(config, 'brandConfig.secure.withChildAges', false)

const engine = {
  initialize: overrides => {
    return (dispatch, getState) => {
      const state = getState()
      const { brandConfig, closeOutDates = {}, dates, packages } = _.get(window, 'searchFormServicePackaging', {})
      const firstAvailableDate = engineHelpers.firstDate(dates.all)
      let lastAvailableDate = engineHelpers.lastDate(dates.all)
      if (!lastAvailableDate) lastAvailableDate = state.engine.defaults.checkOutDate
      const { partyCompWithDefaultCounts: partyComp, children } = engineHelpers.mergeInDefaultAgesCounts(brandConfig.partyComps, state.engine.defaults)
      const maxCheckOutDate = engineHelpers._getMaximumCheckoutDate(state.engine.defaults.checkInDate, brandConfig.nights.maxNights)
      const roomPartyComp = state.engine.defaults.rooms.reduce((acc, room) => {
        acc.adults += parseInt(room.adults, 10)
        acc.children += parseInt(room.children, 10)
        acc.infants += parseInt(room.infants, 10)
        return acc
      }, {
        adults: 0,
        children: 0,
        infants: 0
      })
      let { roomTypes, remainingRoomPartyComp } = engineHelpers._generateRoomChoices([], [], roomPartyComp)

      const options = Object.assign({
        checkInDate: state.engine.defaults.checkInDate,
        checkOutDate: state.engine.defaults.checkOutDate,
        children,
        childrenRevert: _.cloneDeep(children),
        closeOutDates,
        dates,
        defaultAges: state.engine.defaults.ages,
        defaultHotelDate: moment(state.engine.defaults.checkInDate).toISOString(),
        defaultNights: state.engine.defaults.nights,
        defaultRooms: state.engine.defaults.rooms,
        firstAvailableDate,
        hotelDate: null,
        lastAvailableDate,
        searchFormId: state.engine.defaults.searchFormId,
        maxCheckOutDate,
        maxParty: brandConfig.maxParty,
        nightConfig: brandConfig.nights,
        packages,
        packageGroupId: state.engine.defaults.packageGroupId,
        partyComp,
        partyCompRevert: _.cloneDeep(partyComp),
        partyComps: brandConfig.partyComps, // A complex object used for the party composition picker & for the rules around partyComposition for tickets / default.
        phoneNumber: brandConfig.number,
        remainingRoomPartyComp,
        restrictDatesByTicket: brandConfig.restrictDatesByTicket || false,
        rooms: [],
        roomTypes,
        ticketDate: state.engine.defaults.ticketDate,
        timeslot: (state.engine.defaults.timeslot && state.engine.defaults.timeslot.start) || null,
        withChildAges
      }, overrides)

      let roomCount = 0

      state.engine.defaults.rooms.map(defaultRoom => {
        // Make sure the default values being passed in match allowed rooms in config
        const foundRoom = roomify.data.theme.find(roomifyRoomConfig => {
          return roomifyRoomConfig.occupancyType === defaultRoom.occupancyType &&
          roomifyRoomConfig.adults === parseInt(defaultRoom.adults, 10) &&
          roomifyRoomConfig.children === parseInt(defaultRoom.children, 10)
        })
        if (foundRoom) {
          const { rooms } = engineHelpers.changeRoom(roomCount, foundRoom.code, options)
          roomCount++
          options.rooms = [].concat(rooms)
        }
      })

      Object.assign(options, {
        ...engineHelpers._generateRoomChoices(options.rooms, [], roomPartyComp)
      })
      return dispatch({
        type: 'ENGINE_INITIALIZE',
        options,
        brandConfig
      })
    }
  },

  changeRoom: (roomNumber, roomCode) => {
    return (dispatch, getState) => {
      const {
        engineStore
      } = getState().engine
      const newEngineStore = engineHelpers.changeRoom(roomNumber, roomCode, engineStore)
      dispatch(tracking.track('roomCode', 'engine', roomCode))
      return dispatch({
        type: 'ENGINE_CHANGE_ROOM',
        newEngineStore
      })
    }
  },

  error: (errorKey, hasError = false) => {
    return dispatch => {
      return dispatch({
        type: `ENGINE_ERROR_${errorKey.toUpperCase()}`,
        hasError
      })
    }
  },

  toggle: (toggleType, forceVisible = false) => {
    return dispatch => {
      return dispatch({
        type: `ENGINE_TOGGLE_${toggleType.toUpperCase()}`,
        forceVisible
      })
    }
  },

  toggleModal: () => {
    return dispatch => {
      return dispatch({
        type: 'ENGINE_MODAL_TOGGLE'
      })
    }
  },

  submit: () => {
    return dispatch => {
      return dispatch({
        type: 'ENGINE_SUBMIT'
      })
    }
  },

  updateEngineStore: engineStore => {
    return dispatch => {
      return dispatch({
        type: 'ENGINE_UPDATE_STORE',
        engineStore
      })
    }
  },

  changeCheckInDate: checkInDate => {
    return (dispatch, getState) => {
      const {
        engine: {
          engineStore: {
            closeOutDates,
            dates,
            nightConfig
          }
        }
      } = getState()
      const engineStore = engineHelpers.changeDates(checkInDate, null, nightConfig, dates, closeOutDates)
      dispatch(tracking.track('checkInDate', 'engine', checkInDate))
      return dispatch({
        type: 'ENGINE_CHANGE_CHECKINDATE',
        engineStore
      })
    }
  },

  changeCheckOutDate: checkOutDate => {
    return (dispatch, getState) => {
      const {
        engine: {
          engineStore: {
            closeOutDates,
            dates,
            nightConfig
          }
        }
      } = getState()
      const checkInDate = getState().engine.engineStore.checkInDate
      const engineStore = engineHelpers.changeDates(checkInDate, checkOutDate, nightConfig, dates, closeOutDates)
      dispatch(tracking.track('checkOutDate', 'engine', checkOutDate))
      return dispatch({
        type: 'ENGINE_CHANGE_CHECKOUTDATE',
        engineStore
      })
    }
  },

  changePartyComp: (key, count) => {
    return (dispatch, getState) => {
      const engineState = getState().engine
      const engineStore = engineHelpers.changePartyComp(key, count, engineState)
      const name = `partyComp_${engineStore.partyComp[key].key}`
      dispatch(tracking.track(name, 'engine', count))
      return dispatch({
        type: 'ENGINE_CHANGE_PARTYCOMP',
        engineStore
      })
    }
  },

  changeTicketDate: ticketDate => dispatch => {
    dispatch(tracking.track('ticketDate', 'engine', ticketDate))
    return dispatch({
      type: 'ENGINE_CHANGE_TICKETDATE',
      ticketDate
    })
  },

  confirmPartyAndAges: () => {
    return dispatch => dispatch(tracking.track('confirmPartyComp', 'engine', true))
  },

  updateChild: (index, age, overOneMeter) => {
    return (dispatch, getState) => {
      const engineState = getState().engine
      const engineStore = engineHelpers.updateChild(index, age, overOneMeter, engineState)
      dispatch(tracking.track('childageupdate', 'engine', age))
      dispatch(tracking.track('childheightupdate', 'engine', overOneMeter ? 110 : 90))
      return dispatch({
        type: 'ENGINE_UPDATE_CHILD',
        engineStore
      })
    }
  },

  revertPartyAndAges: () => {
    return (dispatch, getState) => {
      const {
        engineStore
      } = getState().engine
      const newEngineStore = engineHelpers.revertPartyAndAges(engineStore.childrenRevert, engineStore.partyCompRevert, engineStore)
      return dispatch({
        type: 'ENGINE_REVERT_PARTYANDAGES',
        newEngineStore
      })
    }
  },

  validate: fieldsToValidate => {
    return (dispatch) => {
      return dispatch({
        type: 'ENGINE_VALIDATION',
        fieldsToValidate
      })
    }
  }
}

export default engine
