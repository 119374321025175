import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import Button from 'react-bootstrap/lib/Button'
import { FormattedMessage } from 'react-intl'

import trackingHelpers from '../../helpers/trackingHelpers'

class Stepper extends PureComponent {
  render () {
    const inputId = this.props.label ? `${this.props.label}_input` : null
    const { disabled, formattedMessage, formattedMessageSubLabel, handleDecrement, handleIncrement, label = null, tracking, value } = this.props
    const { decrement: decrementTracking, increment: incrementTracking } = tracking
    const hasLabel = label || formattedMessage || formattedMessageSubLabel
    return (
      <div className='form-horizontal'>
        <div className='form-group'>
          {hasLabel &&
            <label className='col-sm-5 col-lg-6 control-label' style={{ paddingTop: 0 }}>
              {label}
              {formattedMessage && <FormattedMessage id={formattedMessage.id} values={formattedMessage.values} />}
              {formattedMessageSubLabel && <small><br /><FormattedMessage id={formattedMessageSubLabel.id} values={formattedMessageSubLabel.values} /></small>}
            </label>
          }
          <div className='col-sm-7 col-lg-6'>
            <div className='input-group input-group'>
              <span className='input-group-btn'>
                <Button
                  disabled={disabled}
                  {...trackingHelpers.getAttributes(decrementTracking.action, decrementTracking.category, decrementTracking.label)}
                  onClick={handleDecrement}
                  onKeyDown={(e) => e.key === 'enter' && handleDecrement()}>
                  - <span className='sr-only'>
                    <FormattedMessage id='common.decrease' />
                  </span>
                </Button>
              </span>
              <input
                readOnly
                type='text'
                value={value}
                {...(inputId ? { id: inputId } : undefined)}
                className='form-control text-center input-stepper'
              />
              <span className='input-group-btn'>
                <Button
                  disabled={this.props.disabled || this.props.max}
                  {...trackingHelpers.getAttributes(incrementTracking.action, incrementTracking.category, incrementTracking.label)}
                  onClick={handleIncrement}
                  onKeyDown={(e) => e.key === 'enter' && handleIncrement()}>
                  + <span className='sr-only'>
                    <FormattedMessage id='common.increase' />
                  </span>
                </Button>
              </span>
            </div>
          </div>
        </div>
      </div>
    )
  }
}

Stepper.propTypes = {
  disabled: PropTypes.bool,
  handleDecrement: PropTypes.func.isRequired,
  handleIncrement: PropTypes.func.isRequired,
  label: PropTypes.string,
  max: PropTypes.bool,
  tracking: PropTypes.shape({
    increment: PropTypes.object,
    decrement: PropTypes.object
  }).isRequired,
  value: PropTypes.number.isRequired
}

export default Stepper
