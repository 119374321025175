const searchSummary = {
  toggleIsSummaryElementExpanded: expanded => ({
    expanded,
    type: 'TOGGLE_IS_SUMMARY_ELEMENT_EXPANDED'
  }),

  toggleIsSummaryTitleClickable: clickable => ({
    clickable,
    type: 'TOGGLE_IS_SUMMARY_TITLE_CLICKABLE'
  })
}

export default searchSummary
