import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import DisplayPrice from '../atoms/DisplayPrice'
import ResponsiveImage from '../atoms/ResponsiveImage'

import dateHelpers from '../../helpers/dateHelpers'
import trackingHelpers from '../../helpers/trackingHelpers'

class ExtraNightV2 extends PureComponent {
  render () {
    const { date, grossPrice, isAvailable, isInBasket } = this.props.data
    if (!grossPrice) return null

    let dateString = ''
    // night after needs to be the current check-out date (not check-out date + 1 which is passed in state)
    if (date && !this.props.isNightBefore) {
      dateString = dateHelpers.formatStandard(moment(date, 'DD-MM-YYYY').subtract(1, 'days'))
    } else {
      dateString = dateHelpers.formatStandard(date)
    }

    const disabled = (!isAvailable || !grossPrice)
    let buttonText = <FormattedMessage id='common.unavailable' />
    if (isAvailable && grossPrice) {
      buttonText = isInBasket ? <FormattedMessage id='common.remove' /> : <FormattedMessage id='common.add' />
    }

    const classes = classNames('btn btn-block btn-secondary', {
      'disabled': disabled
    })

    return (
      <div className='product-item'>
        <div className='bg-default pl-sm-0 product-option'>
          <div className='media d-sm-table media-sm my-0 pos-rel product-inner'>
            <div className='media-left float-left float-sm-none ml-sm-0 product-col product-thumb'>
              <div className='pos-rel'>
                <a className='thumb-container pb-0' href='#'>
                  <ResponsiveImage
                    lazyload={false}
                    src={this.props.hotelImage}
                  />
                </a>
              </div>
            </div>
            <div className='media-body product-col product-body pl-3'>
              <a role='button'>
                <h3 className='mb-0 product-title'>
                  <FormattedMessage id='upgrades.extraNightPeriod' values={{ isNightBefore: this.props.isNightBefore }} />
                </h3>
              </a>
              <div className='small text-muted'>
                <span className='mb-0 product-description'>
                  <FormattedMessage id='upgrades.extraNightUpgradeDescription' values={{ hotelName: <strong>{this.props.hotelName}</strong>, isNightBefore: this.props.isNightBefore }} />
                  &nbsp;- <strong>{dateString}</strong>
                </span>
              </div>
            </div>
            <div className='media-right media-middle p-0 product-col product-footer hidden-xs'>
              <div className='media media-sm'>
                <div className='media-body media-middle text-right text-sm-center product-col product-prices'>
                  <div className='media media-xs'>
                    <div className='media-body media-middle'>
                      <div className='h1 product-price-total'>
                        {Number(grossPrice) > 0 &&
                          <div className='h1 product-price-total'><DisplayPrice price={grossPrice} /></div>
                        }
                      </div>
                    </div>
                  </div>
                </div>
                <div className='media-body media-middle product-col product-actions'>
                  <div className='product-action'>
                    <button
                      className={classes}
                      {...trackingHelpers.getAttributes('Select Extra Night Date', 'Extra Night', `${this.props.hotelCode} ${(this.props.isNightBefore) ? 'Start' : 'End'}`)}
                      onClick={!disabled && this.props.handleExtraNightCTA.bind(this, this.props.isNightBefore)}
                      onKeyDown={(e) => e.key === 'Enter' && !disabled && this.props.handleExtraNightCTA.bind(this, this.props.isNightBefore)}>
                      {buttonText}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='clearfix product-actions visible-xs pt-0'>
          <div className='col-xs-5'>
            <div className='media-body media-middle'>
              <div className='h1 product-price-total'>
                {Number(grossPrice) > 0 &&
                  <div className='h1 product-price-total'><DisplayPrice price={grossPrice} /></div>
                }
              </div>
            </div>
          </div>
          <div className='col-xs-7 py-3'>
            <button
              className={classes}
              {...trackingHelpers.getAttributes('Select Extra Night Date', 'Extra Night', `${this.props.hotelCode} ${(this.props.isNightBefore) ? 'Start' : 'End'}`)}
              onClick={!disabled && this.props.handleExtraNightCTA.bind(this, this.props.isNightBefore)}
              onKeyDown={(e) => e.key === 'Enter' && !disabled && this.props.handleExtraNightCTA.bind(this, this.props.isNightBefore)}>
              {buttonText}
            </button>
          </div>
        </div>
      </div>
    )
  }
}

ExtraNightV2.defaultProps = {
  isNightBefore: false
}

ExtraNightV2.propTypes = {
  data: PropTypes.shape({
    date: PropTypes.instanceOf(moment),
    grossPrice: PropTypes.string,
    isAvailable: PropTypes.bool.isRequired,
    isInBasket: PropTypes.bool.isRequired
  }).isRequired,
  hotelCode: PropTypes.string.isRequired,
  hotelImage: PropTypes.string,
  isNightBefore: PropTypes.bool
}

export default ExtraNightV2
