import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGCatering extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <path d='M15.984 6c0-1.781 2.25-3.984 5.016-3.984v19.969h-2.484v-7.969h-2.531v-8.016zM11.016 9v-6.984h1.969v6.984c0 2.109-1.641 3.844-3.75 3.984v9h-2.484v-9c-2.109-0.141-3.75-1.875-3.75-3.984v-6.984h2.016v6.984h1.969v-6.984h2.016v6.984h2.016z' />
      </SVG>
    )
  }
}

SVGCatering.propTypes = SVG.propTypes

SVGCatering.defaultProps = {
  title: 'Food Icon'
}

export default SVGCatering
