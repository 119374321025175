import _ from 'lodash'
import config from '../configs/config'
const { brandConfig } = config

const resourceHelpers = {}

/**
 * Generate image URLs for consumption
 * @param  {Array.<string>} imagesArray The array of image filenames
 * @param  {String} hotelId The hotel identifier
 * @param  {Array.<string>} [variations] The different image variations to be generated
 * @return {Object} An object with keys of variations and values of arrays of the image paths
 */
resourceHelpers.generateImageVariations = (imagesArray, hotelId, variations = ['small', 'medium', 'large']) => {
  if (imagesArray && hotelId) {
    if (_.has(brandConfig, 'secure.hotelImagePath') && _.has(brandConfig, 'secure.assetsUrl')) {
      // for each variation, we want to return an object with the key of the variation, and an array of image URLs
      return variations.reduce((mappedImages, variation) => {
        mappedImages[variation] = imagesArray.map(image => `${brandConfig.secure.assetsUrl}${brandConfig.secure.hotelImagePath}${hotelId}/${variation}/${image}.jpg`)
        return mappedImages
      }, {})
    }
  }
  return {}
}

/**
 * Generate image paths for consumption
 * @param  {String} basePath The site URL for the brand
 * @param  {String} productPath The product path e.g. hotels/, upgrades/
 * @param  {String} fileName The image filename
 * @param  {String} [code=''] The hotel code (when present)
 * @param  {String} [ratio=''] The size folder path (when present)
 * @param  {String} [extension='.jpg'] The image extension
 * @return {String} A string of the image path pointing to the sb-responsive-images S3 bucket
 */
resourceHelpers.generateImagePaths = (basePath, productPath, fileName, code = '', ratio = '', extension = '.jpg') => {
  if (!basePath || !productPath || !fileName) return null
  return `${basePath}${productPath}${code}${ratio}${fileName}${extension}`
}

export default resourceHelpers
