import moment from 'moment'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import CategoryIcon from '../../atoms/CategoryIcon'
import trackingHelpers from '../../../helpers/trackingHelpers'
import config from '../../../configs/config'

class EventItem extends PureComponent {
  render () {
    const {
      basketAlternativeMessage,
      eventTitle,
      hasParkEntry,
      hideBasketSVGs,
      isFlexibleDate,
      parkDate,
      swappableTicketUpgradeTicket,
      showBasketParkDate,
      showData,
      venueType
    } = this.props

    // to protect ourselves against missed details in Prismic loading. - V2 Only
    const hideBreakfast = this.props.whatsIncluded && this.props.whatsIncluded.indexOf('Breakfast') > -1

    // don't show breakfast if it's included in the event title
    const hideBreakfastIfInTitle = this.props.ticketName && this.props.ticketName.toLowerCase().includes('breakfast')

    const showTicketDateInBasket = _.get(config, 'brandConfig.secure.hasFeatures.showTicketDateInBasket', false)

    if (!this.props.hasFixedSummary) {
      return (
        <div>
          {hasParkEntry ? (
            <div className='d-flex'>
              {!hideBasketSVGs &&
                <div className={classNames('col-xs-2 pl-0 pr-0', {
                  'hidden-xs': this.props.reducedInfo
                })}>
                  <CategoryIcon
                    category={this.props.category}
                    height='30'
                    width='30'
                  />
                </div>
              }
              <dl className={classNames('mb-0 pl-0 flex-align_center', {
                'col-xs-12': this.props.hideBasketSVGs,
                'col-xs-10': !this.props.hideBasketSVGs,
                'hidden-xs': this.props.reducedInfo
              })}>
                {eventTitle &&
                  <dd>
                    <h5 className='mt-0 mb-0'>{eventTitle}</h5>
                    {showData && !isFlexibleDate &&
                      <span>
                        {showData.date && <span>{moment(showData.date).format('ddd DD MMM YY')}</span>}
                        {showData.timeslot && showData.timeslot.start && showData.timeslot.start !== '0000' && <span> - {showData.timeslot.start}</span>}
                        {showData.seats && <span><br />Seats: {showData.seats}</span>}
                      </span>
                    }
                    {showTicketDateInBasket && !isFlexibleDate &&
                      <span>{showData.date}</span>
                    }
                  </dd>
                }
                {showBasketParkDate && !isFlexibleDate && (
                  <dd><FormattedMessage id='basket.parkDate' values={{ parkDate: parkDate && parkDate.format('ddd DD MMM YY') }} /></dd>
                )}
              </dl>
            </div>
          ) : (
            <div className={classNames('alert alert-info mb-0', {
              'hidden-xs': this.props.reducedInfo
            })}>
              {basketAlternativeMessage || <FormattedMessage id='basket.ticketsNotIncluded' values={{ venueType }} /> }
            </div>
          )}
        </div>
      )
    }

    return (
      <li className={this.props.reducedInfo ? 'hidden-xs' : ''}>
        <div className='media'>
          <div className='media-left'>
            {!this.props.hideBasketSVGs &&
              <CategoryIcon
                category={this.props.category}
                height='30'
                width='30'
              />
            }
          </div>
          <div className='media-body'>
            <div className='row d-flex'>
              <div className={classNames('media-heading', {
                'col-xs-8': Object.keys(swappableTicketUpgradeTicket).length > 0,
                'col-xs-12': Object.keys(swappableTicketUpgradeTicket).length === 0
              })}>{this.props.ticketName}</div>
              {Object.keys(swappableTicketUpgradeTicket).length > 0 &&
                <div className='col-xs-4 tiny text-right'><a
                  {...trackingHelpers.getAttributes('Remove Upgrade From Cart', 'Upgrades', swappableTicketUpgradeTicket.swappableTicketUpgradeId)}
                  role='button'
                  onClick={swappableTicketUpgradeTicket.handleUpgradeCTA.bind(this, swappableTicketUpgradeTicket.swappableTicketUpgradeId)}
                  onKeyDown={(e) => e.key === 'Enter' && swappableTicketUpgradeTicket.handleUpgradeCTA.bind(this, swappableTicketUpgradeTicket.swappableTicketUpgradeId)}>
                  <FormattedMessage id='common.remove' />
                </a>
                </div>
              }
            </div>
            {(this.props.whatsIncluded || this.props.hotelEventDescription) && (
              <React.Fragment>
                {this.props.hotelEventDescription &&
                  <div className='text-muted tiny' dangerouslySetInnerHTML={{ __html: this.props.hotelEventDescription }} />
                }
                {this.props.whatsIncluded && !this.props.hotelEventDescription && (
                  <div className='text-muted tiny' dangerouslySetInnerHTML={{ __html: this.props.whatsIncluded }} />
                )}
              </React.Fragment>
            )}

            {this.props.cateringDescription && !this.props.hotelEventDescription && !hideBreakfast && !hideBreakfastIfInTitle && (
              <div className='text-muted tiny'>
                <FormattedMessage id='common.breakfast' tagName='p' />
              </div>
            )}
            {this.props.packageIncludedVisibleUpgrades.map(upgrade =>
              <div key={`includedUpgrade${upgrade.id}`} className='row text-muted tiny'>
                <div className='col-xs-8'>
                  <p>{upgrade.title}<br />
                    {upgrade.showUpgradeDate && moment(upgrade.selectedDate).format('ddd DD MMM YY')}
                  </p>
                </div>
                <div className='col-xs-4 text-right'><FormattedMessage id='common.included' /><br /></div>
              </div>
            )}
          </div>
        </div>
        <hr />
      </li>
    )
  }
}

EventItem.propTypes = {
  category: PropTypes.string,
  eventTitle: PropTypes.node,
  hasFixedSummary: PropTypes.bool.isRequired,
  hasParkEntry: PropTypes.bool,
  hideBasketSVGs: PropTypes.bool,
  parkDate: PropTypes.string,
  swappableTicketUpgradeTicket: PropTypes.object.isRequired,
  showBasketParkDate: PropTypes.bool,
  packageIncludedVisibleUpgrades: PropTypes.array.isRequired,
  venueType: PropTypes.string
}

export default EventItem
