import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import Calendar from './Calendar'

import engineOld from '../../../actions/engineOld'

class CheckIn extends PureComponent {
  dateAsString (date, errorString = '') {
    const asMoment = moment(date)
    return asMoment.isValid() ? asMoment.format('Do MMM YY') : errorString
  }

  render () {
    if (!this.props.hasEngineStore) return null
    return (
      <div className={classNames({
        'searchform-checkin': true,
        'has-error': this.props.error
      })}>
        <div className='form-group'>
          <label htmlFor='searchformcheckinbutton'>
            {this.props.label}
          </label>
          <button
            id='searchformcheckinbutton'
            type='button'
            onClick={this.props.toggleVisible}
            onKeyDown={(e) => e.key === 'Enter' && this.props.toggleVisible()}
            className='form-control ignore-react-onclickoutside'
          >
            {this.dateAsString(this.props.checkInDate, 'Please choose')}
          </button>
          {this.props.isVisible &&
            <Calendar
              onClose={this.props.toggleVisible}
              isDayDisabled={checkInDate => {
                return this.props.isTicketCalendarDayDisabled(checkInDate)
              }}
              endDate={this.props.lastAvailableTicketDate}
              selected={this.props.checkInDate}
              startDate={this.props.firstAvailableTicketDate}
              onChange={this.props.change}
            />
          }
          {this.props.error &&
            <div className='help-block'>
              <FormattedMessage id='common.pleaseSelect' />
            </div>
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const {
    checkInDate,
    firstAvailableTicketDate,
    lastAvailableTicketDate
  } = state.engineOld.engineStore

  return {
    checkInDate,
    firstAvailableTicketDate,
    lastAvailableTicketDate,
    label: _.get(state.engineOld.brandConfig, 'UITextDefaults.labelCheckIn', 'Check in'),
    hasEngineStore: state.engineOld.hasEngineStore,
    error: state.engineOld.errorCheckInDate,
    isVisible: state.engineOld.visibleCheckInCalendar
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleVisible: e => {
      if (e && e.preventDefault) e.preventDefault()
      if (e && e.stopPropagation) e.stopPropagation()
      return dispatch(engineOld.toggle('checkInCalendar', false))
    },

    change: checkInDate => {
      return dispatch(engineOld.changeCheckInDate(checkInDate))
    },

    // Not actually actions here but I was unsure the best way to handle this one.
    isTicketCalendarDayDisabled: date => {
      return dispatch(engineOld.isTicketCalendarDayDisabled(date))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckIn)
