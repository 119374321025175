import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import countryOptionValues from '../../configs/countryOptionValues.json'

/**
 * FormattedAddress - The actual component
 *
 * @param {Object} props the props
 * @return {JSX} the rendered component
 */
class FormattedAddress extends PureComponent {
  render () {
    // Keep correct order of fields for the formatting
    const fields = [
      this.props.billToAddressLine0,
      this.props.billToAddressLine1,
      this.props.billToAddressLine2,
      this.props.billToAddressCity,
      this.props.billToAddressState,
      this.props.billToAddressPostalCode
    ]

    // Lookup the country that has been given
    const foundCountry = countryOptionValues.find(country => country.value === this.props.billToAddressCountry)
    if (foundCountry && foundCountry.text) {
      fields.push(foundCountry.text)
    }

    // Loop over keys in object, return them as array and join that with line breaks
    const formattedAddress = []
    fields.forEach((field, index) => {
      // No information, don't add
      if (!field) {
        return
      }
      formattedAddress.push(
        <span className='clearfix' key={`formattedAddress-${index}`}>
          {field}
        </span>
      )
    })

    return (
      <div className='formatted-address' data-automated-test='formatted-address' data-hj-suppress >
        {formattedAddress}
      </div>
    )
  }
}

FormattedAddress.propTypes = {
  billToAddressCity: PropTypes.string,
  billToAddressCountry: PropTypes.string,
  billToAddressLine0: PropTypes.string,
  billToAddressLine1: PropTypes.string,
  billToAddressLine2: PropTypes.string,
  billToAddressPostalCode: PropTypes.string,
  billToAddressState: PropTypes.string
}

export default FormattedAddress
