import _ from 'lodash'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { injectIntl, intlShape } from 'react-intl'
import { connect } from 'react-redux'

import packageRates from '../../actions/packageRates'

import Button from 'react-bootstrap/lib/Button'

import trackingHelpers from '../../helpers/trackingHelpers'

import config from '../../configs/config'
const { brandConfig } = config

class HotelSorter extends PureComponent {
  constructor (props) {
    super(props)

    this.sortOnMiles = this.sortOnMiles.bind(this)
    this.sortOnPrice = this.sortOnPrice.bind(this)
    this.sortOnTopSelling = this.sortOnTopSelling.bind(this)
  }

  sortOnMiles () {
    this.props.sortAction('milesToPark')
  }

  sortOnPrice () {
    this.props.sortAction('grossPriceInPence')
  }

  sortOnTopSelling () {
    this.props.sortAction('defaultOrder')
  }

  componentWillReceiveProps () {
    this.props.getFilteredPackages()
  }

  render () {
    const hasHotelSorterPriceButton = _.get(brandConfig, 'secure.hasFeatures.hasHotelSorterPriceButton', false)
    const hasFilters = _.get(brandConfig, 'secure.hasFeatures.filters', false)
    const availabilitySorters = [
      {
        active: this.props.active === 'grossPriceInPence',
        onClick: this.sortOnPrice,
        onKeyDown: this.sortOnPrice,
        textId: hasHotelSorterPriceButton ? 'common.price' : 'availability.cheapest'
      },
      {
        active: this.props.active === 'milesToPark',
        onClick: this.sortOnMiles,
        onKeyDown: this.sortOnMiles,
        textId: 'availability.closest'
      },
      {
        active: this.props.active === 'defaultOrder',
        onClick: this.sortOnTopSelling,
        onKeyDown: this.sortOnTopSelling,
        textId: 'availability.topSelling'
      }
    ]
    return (
      <div className='btn-group btn-group-sm engine__sorter mt-0' role='group'>
        {availabilitySorters.map(button => {
          const buttonClasses = classNames({
            'active': button.active,
            // hide top selling sort button as it creates unnecessary/ugly spacing on mobile - for brands with filters
            'hidden-xs': hasFilters && button.textId === 'availability.topSelling'
          })
          const buttonText = this.props.intl.formatMessage({ id: button.textId }) || ''
          return (
            <Button
              data-automated-test={`sort_${buttonText.toLowerCase().replace(' ', '')}`}
              className={buttonClasses}
              {...trackingHelpers.getAttributes('Sort', 'Secure Shell', buttonText)}
              key={button.textId}
              onClick={button.onClick}
              onKeyDown={(e) => e.key === 'Enter' && button.onKeyDown}>
              {buttonText}
            </Button>
          )
        })}
      </div>
    )
  }
}

HotelSorter.propTypes = {
  active: PropTypes.string.isRequired,
  intl: intlShape,
  sortAction: PropTypes.func.isRequired
}

function mapStateToProps (state, ownProps) {
  return {
    sortBy: state.filters.sortBy
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    getFilteredPackages: () => {
      return dispatch(packageRates.filter())
    }
  }
}

export {
  HotelSorter
}
export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(HotelSorter))
