const calculationHelpers = {}

/**
 * calculateLatLongDistance - calculate the distance between two places, with latitude and longitude
 * http://stackoverflow.com/a/21623206
 *
 * @param {object} [locationOne={}] Location one
 * @param {object} [locationTwo={}] Location two
 * @param {Number} [precision=1] used to return a number with x amount of decimal places
 *
 * @return {type} Description
 */
calculationHelpers.calculateLatLongDistance = (locationOne = {}, locationTwo = {}, precision = 1) => {
  if (locationOne.lat && locationTwo.lat && locationOne.long && locationTwo.long) {
    // Math.PI / 180
    const p = 0.017453292519943295
    const c = Math.cos
    const a = 0.5 - c((locationTwo.lat - locationOne.lat) * p) / 2 +
      c(locationOne.lat * p) * c(locationTwo.lat * p) *
      (1 - c((locationTwo.long - locationOne.long) * p)) / 2

    // 2 * R; R = 6371 km
    const returnValue = 12742 * Math.asin(Math.sqrt(a))
    return returnValue.toFixed(precision)
  }
  return null
}

/**
 * Wraps around calculateLatLongDistance to extract latitude and longitude values from a passed hotel object
 * and an object of venueProduct objects -(the first object of venueProducts returned via Object.keys is used)
 * and returns the calculated distance between them.
 * @param  {Object} hotel
 * @param  {Object} venueProducts
 * @return {string | null}
 */
calculationHelpers.getLatLongDistanceFromVenue = (hotel = {}, venueProducts = {}) => {
  // Bypass computation of distance from venue if hotel is an onsite hotel
  if (hotel.isResort) return null
  let venueLatLong = {}
  if (venueProducts) {
    const { latitude, longitude } = venueProducts[Object.keys(venueProducts)[0]]
    if (latitude && longitude) {
      venueLatLong = {
        lat: parseFloat(latitude),
        long: parseFloat(longitude)
      }
    }
  }
  const hotelLatLong = {
    lat: hotel.latitude,
    long: hotel.longitude
  }
  return calculationHelpers.calculateLatLongDistance(hotelLatLong, venueLatLong)
}

export default calculationHelpers
