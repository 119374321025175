import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import LazyLoad from 'react-lazyload'
import { connect } from 'react-redux'
import ScrollSpy from 'react-scrollspy'
import { emitter } from '@marvelapp/react-ab-test'

import { Alert, Button, Col, Collapse, ListGroup, ListGroupItem, Modal, Row } from 'react-bootstrap'

import { query } from '../components/atoms/BreakPoint'

import DisplayPrice from '../components/atoms/DisplayPrice'
import GoogleMapsWrapper from '../components/atoms/GoogleMapsWrapper'
import ResponsiveImage from '../components/atoms/ResponsiveImage'
import ResponsiveWaypoint from '../components/atoms/ResponsiveWaypoint'
import SVG360 from '../components/atoms/SVG360'
import SVGBed from '../components/atoms/SVGBed'
import SVGBath from '../components/atoms/SVGBath'
import SVGCamera from '../components/atoms/SVGCamera'
import SVGExpand from '../components/atoms/SVGExpand'
import SVGMap from '../components/atoms/SVGMap'
import SVGPlay from '../components/atoms/SVGPlay'
import SVGSpyglass from '../components/atoms/SVGSpyglass'

import Carousel from '../components/molecules/Carousel'
import Loading from '../components/molecules/Loading'

import Basket from '../components/organisms/Basket/Basket'
import BookingSummary from '../components/organisms/BookingSummary'
import SplitTest from './SplitTest'

import basketHelpers from '../helpers/basketHelpers'
import generalHelpers from '../helpers/generalHelpers'
import mapHelpers from '../helpers/mapHelpers'
import ratesHelpers from '../helpers/ratesHelpers'
import routeHelpers from '../helpers/routeHelpers'
import trackingHelpers from '../helpers/trackingHelpers'

import engine from '../actions/engine'
import engineOld from '../actions/engineOld'
import modal from '../actions/modal'
import packageRates from '../actions/packageRates'

import config from '../configs/config'
import roomTypes from '../configs/roomTypes'
import accommodationTypes from '../configs/accommodationTypes'
import discovery from '../actions/discovery'
import DiscoveryModal from '../containers/DiscoveryModal'

const {
  brandConfig,
  endpoint,
  harvestBasketData,
  hotelReviews,
  packageRatesReply
} = config

const SectionNavigation = ({ section }) => (
  <nav role='navigation'>
    <ul className='list-unstyled pull-right small'>
      <li className='sr-only'>
        <a
          {...trackingHelpers.getAttributes('Back to main navigation', 'Links', section)}
          href='#main-navigation'
          onClick={(e) => {
            e.preventDefault()
            generalHelpers.scrollElementIntoView('#main-navigation')
          }}
          onKeyDown={(e) => {
            if (e.key !== 'Enter') {
              return
            }
            e.preventDefault()
            generalHelpers.scrollElementIntoView('#main-navigation')
          }}
          role='button'>
          <FormattedMessage id='moreInformation.backToMainNavigation' />
        </a>
      </li>
      <li>
        <a
          {...trackingHelpers.getAttributes('Back to top', 'Links', section)}
          onClick={(e) => {
            e.preventDefault()
            generalHelpers.scrollElementIntoView('#wrap')
          }}
          onKeyDown={(e) => {
            if (e.key !== 'Enter') {
              return
            }
            e.preventDefault()
            generalHelpers.scrollElementIntoView('#wrap')
          }}
          role='button'
          tabIndex='0'>
          <FormattedMessage id='moreInformation.backToTop' />&nbsp;<SVGExpand />
        </a>
      </li>
    </ul>
  </nav>
)

const Review = ({ review }) => {
  return (
    <blockquote itemProp='review' itemScope='' itemType='http://schema.org/Review' >
      <p itemProp='description' dangerouslySetInnerHTML={{ __html: review.review }} />
    </blockquote>
  )
}

class MoreInformation extends Component {
  constructor (props) {
    super(props)

    this.queryStringParams = routeHelpers.getQueryStringParams()
    const { hotelCode, roomId } = this.queryStringParams
    let hotelPackageRates = []
    if (packageRatesReply.packageRates) {
      hotelPackageRates = (packageRatesReply.packageRates).filter((packageRate) => {
        return _.get(packageRate, 'links.hotelProducts.ids[0]') === hotelCode
      })
    }
    let currentPackageRate = hotelPackageRates[0]
    this.packageContext = _.get(packageRatesReply, 'meta.context')
    if (roomId) {
      currentPackageRate = hotelPackageRates.find(hotelPackageRate => hotelPackageRate.links.roomProducts.ids[0] === roomId)
    }
    this.eventProductId = ratesHelpers.getEventProductIdForPackageRate(currentPackageRate, this.packageContext)
    const ticketRateId = ratesHelpers.getTicketRateIdForPackageRate(currentPackageRate, this.packageContext, _.get(packageRatesReply, 'linked.ticketRates'))
    this.eventProduct = _.get(packageRatesReply, `linked.eventProducts[${this.eventProductId}]`, {})
    this.hasParkEntry = _.get(packageRatesReply, `linked.ticketRates[${ticketRateId}].hasParkEntry`, false)
    this.hotelEventId = Object.keys(_.get(packageRatesReply, 'linked.hotelEventProducts', {}))[0]
    this.hotelEventProducts = _.get(packageRatesReply, `linked.hotelEventProducts[${this.hotelEventId}]`, {})
    this.hotelProduct = _.get(packageRatesReply, `linked.hotelProducts[${hotelCode}]`, {})
    const { sundryRates = [] } = this.props
    this.cancellationWaiver = sundryRates.find(rate => rate.type === 'cancellationWaiver') || {}
    const venueProductId = _.get(currentPackageRate, `links.venueProducts.ids[0]`)
    this.venueProduct = _.get(packageRatesReply, `linked.venueProducts[${venueProductId}]`, {})
    this.alertInfoMobileClassName = (_.get(brandConfig, 'secure.hasFeatures.useAlertInfoMobileView', false)) ? 'alert-mobile-view' : ''
    this.hasRoomFinder = _.get(brandConfig, `secure.hasFeatures.moreInformation.hasRoomFinder`, false)

    // Initial state
    this.state = {
      group: null,
      modalShown: false,
      showMoreReviews: false,
      modalContent: {
        activeIndex: 0
      },
      youTubeInstances: {},
      hotelCode
    }
    this.changePage = this.changePage.bind(this)
    this.getHotelData = this.getHotelData.bind(this)
    this.onGoogleMapsClick = trackingHelpers.track.bind(null, 'click', 'Interaction with Map', 'moreinformation', null)
    this.onToggleModal = this.props.onToggleModal.bind(this)
    this.setBasketVisibility = this.setBasketVisibility.bind(this)
    props.getPackageRates(hotelCode)
    this.alternativeRoomGroupSortOrder = _.get(config, 'brandConfig.secure.hasFeatures.moreInformation.alternativeRoomGroupSortOrder', [])
    this.hasAlternativeRoomAndGroupSortOrders = _.get(config, 'brandConfig.secure.hasFeatures.moreInformation.hasAlternativeRoomAndGroupSortOrders', false)
    this.hasAlternativeRoomAndGroupSortOrdersSplitTest = _.get(config, 'brandConfig.secure.hasFeatures.moreInformation.hasAlternativeRoomAndGroupSortOrdersSplitTest', false)
    this.isAlternativeRoomAndGroupSortOrdersSplitAlternative = emitter.calculateActiveVariant('SIOP-294:alternativeRoomAndGroupSortOrders') === 'show_alternative'
  }

  /**
   * changePage - Call navigateTo to go to the next stage in the booking flow
   * @param {Object} room - When we can select a room, these details need to be part of the next URL
   * @return {void}
   */
  changePage (room = {}, hotelCode, roomBucket, ticketCode, packageTypeId = null) {
    // Get parameters from the current URL
    // Swap some variables for new ones
    Object.assign(this.queryStringParams, {
      versionId: _.get(window, 'basket.version'),
      tag: endpoint
    })

    // Making sure we have the objects
    this.queryStringParams.filter = this.queryStringParams.filter || {}
    this.queryStringParams.roomRates = this.queryStringParams.roomRates || {}
    this.queryStringParams.ticketRates = this.queryStringParams.ticketRates || {}

    this.queryStringParams.roomRates.bucket = roomBucket
    this.queryStringParams.ticketRates.bucket = ticketCode.substring(0, 3)
    this.queryStringParams.ticketRates.code = ticketCode.substring(3, 6)
    this.queryStringParams.ticketRates.ticketCode = ticketCode
    this.queryStringParams.ticketCode = ticketCode
    this.queryStringParams.utmSource = this.queryStringParams.utmSource

    if (room.id) {
      this.queryStringParams.filter.roomCode = room.id
      this.queryStringParams.roomRates.code = room.id
    } else {
      this.queryStringParams.filter.hotelCode = hotelCode
    }

    delete this.queryStringParams.packageGroupId
    delete this.queryStringParams.packageTypeId
    if (this.queryStringParams.searchFormId) {
      this.queryStringParams.packageTypeId = packageTypeId
    }

    const flow = brandConfig.secure && brandConfig.secure.flow
    const nextEndpoint = routeHelpers.getNextStage(flow, endpoint)
    if (nextEndpoint) {
      this.props.navigateTo(nextEndpoint, this.queryStringParams)
    }
  }

  getRoomFinderButton (room) {
    return (
      <button
        aria-label={`Pricing for alternative dates${room ? ` at ${room.name}` : ''}`}
        className='btn btn-block btn-price-finder pl-2'
        {...trackingHelpers.getAttributes('open', 'discovery-modal', room ? room.id : 'All rooms')}
        onClick={() => this.props.openDiscoveryModal(room.id, true)}
        onKeyDown={(e) => e.key === 'Enter' && this.props.openDiscoveryModal(room.id, true)}
      >
        <span className='pr-2'><SVGSpyglass width='22' height='22' /></span>
        <FormattedMessage id='common.roomFinder' />
      </button>
    )
  }

  componentDidMount () {
    if (window && window.tracker) {
      tracker.page(
        'availability', {
          page_type: this.props.pageType
        }
      )
    }
    window.addEventListener('load', this.scrollToElementByHashUrl.bind(this))
    window.showContentModal = this.showContentModal.bind(this)
    this.props.checkDiscoveryPrimed()
  }

  componentWillReceiveProps (nextProps) {
    const { hotelPackageRate = {} } = nextProps
    // Only run if hotelPackageRate has groups and a group hasn't been set on state
    if (hotelPackageRate.groups && hotelPackageRate.groups.length > 0 && !this.state.group) {
      const clonedGroups = _.cloneDeep(hotelPackageRate.groups)
      const hasAlternativeSortOrder = (this.hasAlternativeRoomAndGroupSortOrders && this.alternativeRoomGroupSortOrder.length)
      const alternativeSortOrderSplitTestDisabled = !this.hasAlternativeRoomAndGroupSortOrdersSplitTest
      const isAlternativeSideOfSortOrderSplit = (this.hasAlternativeRoomAndGroupSortOrdersSplitTest && this.isAlternativeRoomAndGroupSortOrdersSplitAlternative)
      const sortedGroups = (hasAlternativeSortOrder && (alternativeSortOrderSplitTestDisabled || isAlternativeSideOfSortOrderSplit))
        ? this.sortGroupsByRoomGroupTags(clonedGroups, this.alternativeRoomGroupSortOrder)
        : clonedGroups
      const group = sortedGroups.find((group) => group.available)

      group && this.setState(() => ({
        group
      }))
    }
  }

  sortGroupsByRoomGroupTags (groups, sortedRoomGroupTags = []) {
    return (groups || []).sort((a, b) => {
      const aIndex = sortedRoomGroupTags.indexOf(a.tag)
      const bIndex = sortedRoomGroupTags.indexOf(b.tag)
      return aIndex < bIndex ? -1 : 1
    })
  }

  componentDidUpdate (prevProps, prevState) {
    const openModal = this.queryStringParams.openVideoModal
    // Check hotelPackageRate is loaded otherwise it'll throw undefined
    if (this.props.hotelPackageRate.videoId) {
      if (prevState.modalShown === false && openModal === 'true') {
        this.setState(() => ({
          modalShown: true
        }))

        const images = this.props.hotelPackageRate.images.map(image => ({
          src: image
        }))
        // Add hotel video thumbnail to images
        images.unshift({ src: generalHelpers.generateYoutubeThumbnail(this.props.hotelPackageRate.videoId, 'maxresdefault') })
        this.showImageModal(images, 'Hotel Gallery', this.props.hotelPackageRate.name)
      }
    }
  }

  scrollToElementByHashUrl () {
    const { hotelPackageRate = {} } = this.props
    const { hash } = window.location
    const idFromHash = hash.split('#')[1]
    const isHashModalContentElement = (hotelPackageRate.modalContent || []).find(n => n.modal_id === idFromHash)

    if (!isHashModalContentElement) {
      // Scroll to the element with id from the url hash
      generalHelpers.scrollElementIntoView(hash)
    }
  }

  /**
  * getScrollSpyItems - Define an array for the internal page navigation
  * @param {Object} visible - sections that are visible or not
  * @return {void}
  */
  getScrollSpyItems ({ accommodationType, hasRoomOptions = false, hasReviews = false, hasPlacesToEat = false }) {
    const items = [{
      className: 'hidden-xs',
      hash: 'overview-block',
      messageKey: 'moreInformation.overview'
    }]

    if (hasRoomOptions) {
      items.push({
        hash: 'room-block',
        messageKey: (accommodationType.hotel || '').toLowerCase() === 'common.accommodation'
          ? 'common.options'
          : 'common.rooms'
      })
    }

    items.push({
      hash: 'hotel-block',
      messageKey: 'moreInformation.accommodationInfo',
      messageValues: {
        accommodationType: (accommodationType.hotel || '').toLowerCase() === 'common.accommodation'
          ? null
          : this.props.intl.formatMessage({ id: accommodationType.hotel })
      }
    })

    // Offsite 'rooms' section sits below 'hotel info'
    if (!hasRoomOptions) {
      items.push({
        hash: 'room-block',
        messageKey: (accommodationType.hotel || '').toLowerCase() === 'common.accommodation'
          ? 'common.options'
          : 'common.rooms'
      })
    }

    if (hasReviews) {
      // Add review link to 'sections' array
      items.push({
        hash: 'review-block',
        messageKey: 'common.reviews'
      })
    }

    if (hasPlacesToEat) {
      // Add link for 'Dining' section
      items.push({
        hash: 'placestoeat-block',
        messageKey: 'common.dining'
      })
    }

    // Add 'map' to array
    items.push({
      hash: 'map-block',
      messageKey: 'common.map'
    })

    return items
  }

  rebuildRoomObject (room) {
    let rooms = _.get(this.packageContext, 'roomRates.rooms', [])
    // If somethign goes wrong, bail out & no need to rebuild.
    if (!room) return rooms
    const roomIds = _.get(room, 'roomRate.links.rooms')
    if (roomIds) {
      // Rebuild the rooms info
      rooms = roomIds.map(roomId => {
        const originalRoom = _.get(packageRatesReply, `linked.rooms[${roomId}]`, {})
        const roomType = basketHelpers.getRoomType(roomTypes, originalRoom)
        return Object.assign({}, originalRoom, {
          name: roomType.roomDescription || room.name,
          originalName: room.name,
          shortName: roomType.roomShortDesc || room.name
        })
      })
    } else {
      // Update the rooms info with data from the package
      rooms = rooms.map(roomInfo => Object.assign({}, roomInfo, {
        originalName: room.name
      }))
    }

    return rooms
  }

  /**
   * selectProduct - PUT details to harvest and call changePage to proceeed to the next page
   * @param {Object} room - the selected room
   * @return {void}
   */
  selectProduct (room = {}, themeName = '') {
    const { hotelPackageRate } = this.props
    if (_.isEmpty(room) && this.packageContext) {
      room.roomRate = this.packageContext.roomRates
      room.roomRate.links = hotelPackageRate.roomRates.links
      room.roomRate.nights = hotelPackageRate.roomRates.nights
    }
    if (!harvestBasketData) return

    const {
      data = {},
      id = null,
      version = null
    } = window.basket || {}

    // Create object with partly cloned properties from harvestBasketData (as these are the same)
    // This will need to be changed when / if we move the adding the hotel to basket from the availability page
    // to this page
    const harvestObject = {
      basketId: id,
      data: _.cloneDeep(harvestBasketData),
      method: 'put',
      version
    }
    const searchFormId = _.get(packageRatesReply, 'meta.context.searchFormId', null)

    const grossPrice = room.price || hotelPackageRate.price
    const standardPrice = room.standardPrice || null
    const packageId = room.packageId || hotelPackageRate.packageId
    const selectedItemsHash = Object.keys(harvestObject.data.selectedItems)[0]
    const seats = harvestObject.data.selectedItems[selectedItemsHash].seats || null
    const selectedSeats = harvestObject.data.selectedItems[selectedItemsHash].selectedSeats || null
    data.rooms = this.rebuildRoomObject(room)

    const { adults, children, infants } = _.get(window, 'packageRatesReply.meta.context.roomRates', {})
    trackingHelpers.fbPixelTrack(grossPrice, [room.id, harvestObject.data.ticket.id], 'moreInformation', 'AddToCart')
    const selectedPackage = _.find(packageRatesReply.packageRates, { id: packageId }) || {}

    if (hotelPackageRate.isResort) {
      const { packageTypeId = null } = selectedPackage
      trackingHelpers.track('sb.track', 'Package selected', 'packageTypeId', packageTypeId)
      harvestObject.data.packageTypeId = packageTypeId
    }
    // get the ticket info for the current selected package can be different as we can restrict tickets on room level
    const context = packageRatesReply.meta.context
    const linkedTicketRates = _.get(packageRatesReply, ['linked', 'ticketRates'], {})
    const selectedPackageEventProductId = ratesHelpers.getEventProductIdForPackageRate(selectedPackage, context)
    const selectedPackageTicketId = ratesHelpers.getTicketRateIdForPackageRate(selectedPackage, context, linkedTicketRates)
    const selectedPackageTicketData = _.get(packageRatesReply, ['linked', 'ticketRates', selectedPackageTicketId], {})
    const selectedPackageEventData = _.get(packageRatesReply, ['linked', 'eventProducts', selectedPackageEventProductId], {})
    // Merge with some new data
    Object.assign(harvestObject.data, {
      grossPrice,
      searchFormId,
      hotel: {
        adults,
        children,
        bucket: _.get(packageRatesReply, 'meta.context.roomRates.bucket', ''),
        checkinDate: _.get(hotelPackageRate, 'roomRates.checkinDate'),
        checkoutDate: _.get(hotelPackageRate, 'roomRates.checkoutDate'),
        hasBreakfastIncluded: _.get(hotelPackageRate, 'facilities.hasBreakfastIncluded'),
        id: hotelPackageRate.id,
        infants,
        isResort: _.get(hotelPackageRate, 'roomRates.isResort'),
        name: hotelPackageRate.name,
        productId: hotelPackageRate.id,
        nights: _.get(room, 'roomRate.nights', data.roomRates && data.roomRates.nights),
        roomId: room.id,
        roomName: room.name,
        roomThemeName: themeName,
        stars: hotelPackageRate.starRating
      },
      ticket: {
        ...selectedPackageTicketData,
        duration: selectedPackageTicketData.days,
        eventProducts: selectedPackageEventData.id,
        id: `${selectedPackageTicketData.bucket}${selectedPackageTicketData.code}`,
        name: selectedPackageEventData.name,
        summaryDiscountMessage: selectedPackageEventData.summary_discount_message || '',
        whatsIncluded: selectedPackageEventData.whatsIncluded
      },
      hotelOnly: data.hotelOnly,
      moreinformationUrl: window.location.href,
      rooms: hotelPackageRate.rooms || data.rooms,
      roomRates: room.roomRate || data.roomRates,
      selectedItems: {
        [packageId]: {
          grossPrice,
          id: packageId,
          resource: 'packageRates',
          standardPrice,
          type: 'package',
          seats,
          selectedSeats
        }
      },
      standardPrice
    })

    Promise.resolve(updateBasket(harvestObject)).then(data => {
      // store basket in sessionStorage for retrieval later
      basketHelpers.handleSetSessionBasketData.call(this, 'sessionBasket', data.harvest.baskets)
      const roomBucket = harvestObject.data.hotel.bucket

      this.changePage(room, hotelPackageRate.id, roomBucket, harvestObject.data.ticket.id, harvestObject.data.packageTypeId)
    }).catch(err => {
      console.error('Updating Harvest has resulted in an error:', err)
      // When there's an error with Harvest, show the callback form
      this.props.showCallbackForm(`Updating Harvest has resulted in an error: ${err.message || err}`)
    })
  }

  /**
   * Set the visibility of the (floating) basket
   * @param {Boolean} [basketVisible=true] boolean to determine if we should remove or add a class
   */
  setBasketVisibility (basketVisible = true) {
    // reference to the basket
    if (this.basket) {
      // Toggle class so we can animate in an out
      this.basket.classList[basketVisible ? 'remove' : 'add']('hide-animate')
    }
  }

  shouldComponentUpdate (nextProps, nextState) {
    if (!nextProps.hotelPackageRate) return false
    return !_.isEqual(nextProps, this.props) || !_.isEqual(nextState, this.state)
  }

  /**
   * Will try and find the correct content and show the modal
   * @param {String} id id of modalContent node to link to
  */
  showContentModal (id) {
    if (!id) return
    // Find modal content that matches the one we want to show
    const modalContent = (this.props.hotelPackageRate.modalContent || []).find(n => n.modal_id === id)
    // Check if we have modal content to show
    // If not, the normal behaviour (jumping to the hash) will follow
    if (modalContent) {
      const { modal_description: modalDescription, modal_title: modalTitle } = modalContent
      let modalImage = modalContent.modal_image
      // Make modal image aganostic of path , SBP wil build automatically
      if (modalImage && !modalImage.match(/\//)) {
        modalImage = `hotel/${this.props.hotelPackageRate.id}/16-9/${modalImage}`
      }
      return this.setState(() => ({
        modalContent: {
          description: modalDescription,
          image: modalImage,
          title: modalTitle
        }
      }), () => {
        this.props.onShowModal('contentModal')
        trackingHelpers.track('click', 'Content Modal', 'moreinformation', id)
      })
    }
  }

  /**
   * showImageModal - Sets state with images of the room where the current clicked image is part of
   * It also adds the current index of the image (so we show the correct image in the modal) and a caption
   * @param {Array} images - The array of images we want to show
   * @param {String} title - Modal title
   * @param {String} trackingAction - The value for the GA tracking event action
   * @param {String} trackingCategory - The value for the GA tracking event category
   * @param {Number} activeIndex - The index of the clicked image
   * @return {void}
   */
  showImageModal (images, title = '', trackingAction, trackingCategory = 'Carousel', activeIndex = 1) {
    if ((images || []).length === 0) {
      return
    }

    trackingHelpers.track('sb.track', trackingAction, trackingCategory, `${activeIndex}/${images.length}`)

    const hasVideo = (images.filter(image => image.type === 'youtube').length > 0)

    // Set content and open up modal
    this.setState(() => ({
      modalContent: {
        activeIndex,
        images,
        title,
        trackingCategory,
        hasVideo
      }
    }), () => this.props.onShowModal('imageModal'))
  }

  /**
   * switchGroup - Switches group after selecting on from the nav-container in 'Room options'
   * @param {String} groupTag - The tag of the clicked tab
   * @param {Array} groups - Supplying groups to select from needed when calling this from componentWillReceiveProps
   * @return {void}
   */
  switchGroup (groupTag, groups = _.get(this.props, 'hotelPackageRate.groups', [])) {
    const group = groups.find(group => group.tag === groupTag)
    if (group && group.available) {
      this.setState(() => ({
        group
      }))
    }
  }

  getRoomsDescriptions () {
    const hasFixedSummary = _.get(brandConfig, 'secure.hasFeatures.hasFixedSummary', false)
    const rooms = this.rebuildRoomObject(_.get(this.state, ['group', 'themes', 0, 'rooms', 0])) // use the first room for our descriptions.
    const roomDescriptions = hasFixedSummary ? basketHelpers.getPartyPerRoom(roomTypes, rooms) : basketHelpers.getRoomsDescriptions(roomTypes, rooms)
    return roomDescriptions
  }

  getCTAButton (hasRoomOptions, accommodationType) {
    let ctaButton = null
    const showBookButton = config.showBookButtonInMoreInfoSummary[this.state.hotelCode] || !hasRoomOptions
    const buttonClasses = classNames('btn col-xs-12 col-sm-12', {
      'btn-secondary': !showBookButton,
      'btn-primary': showBookButton
    })

    if (showBookButton) {
      ctaButton = (
        <a
          role='button'
          className={buttonClasses}
          aria-controls='room-block'
          {...trackingHelpers.getAttributes('Basket CTA', 'moreinformation', 'Book')}
          tabIndex='0'
          onClick={() => this.selectProduct()}
          onKeyDown={(e) => { e.key === 'Enter' && this.selectProduct() }}>
          <FormattedMessage id='common.book' />
        </a>
      )
    } else {
      ctaButton = (
        <a
          role='button'
          aria-controls='room-block'
          className={buttonClasses}
          {...trackingHelpers.getAttributes('Basket CTA', 'moreinformation', 'View Rooms')}
          href='#room-block'
          onClick={(e) => {
            e.preventDefault()
            generalHelpers.scrollElementIntoView('#room-block')
          }}
          onKeyDown={(e) => {
            if (e.key !== 'Enter') {
              return
            }
            e.preventDefault()
            generalHelpers.scrollElementIntoView('#room-block')
          }}>
          <div className='text-center'>
            <FormattedMessage id='moreInformation.viewAccommodation' values={{ accommodationType: <FormattedMessage id={accommodationType.room.plural} /> }} />
          </div>
        </a>
      )
    }

    return ctaButton
  }

  getHotelData () {
    if (!this.props.hotelPackageRate) return {}
    const roomRatesWithoutId = _.omit(this.props.hotelPackageRate.roomRates, ['id'])
    const mergedData = Object.assign(
      {},
      this.props.hotelPackageRate,
      roomRatesWithoutId,
      {
        adults: +_.get(this.packageContext, 'ticketRates.adults', 0),
        children: +_.get(this.packageContext, 'ticketRates.children', 0),
        infants: +_.get(this.packageContext, 'ticketRates.infants', 0),
        ticketName: _.get(this, 'eventProduct.name', '')
      }
    )
    return mergedData
  }

  getReorderedHotelGroups () {
    const hotel = this.getHotelData()
    return this.alternativeRoomGroupSortOrder.reduce((acc, current) => {
      const group = (hotel.groups || []).find(group => group.tag === current)
      if (group) acc.push(group)
      return acc
    }, [])
  }

  getHotelGroups () {
    const hotel = this.getHotelData()
    if (!this.hasAlternativeRoomAndGroupSortOrders || !this.alternativeRoomGroupSortOrder.length) {
      return hotel.groups
    }

    if (!this.hasAlternativeRoomAndGroupSortOrdersSplitTest || (this.hasAlternativeRoomAndGroupSortOrdersSplitTest && this.isAlternativeRoomAndGroupSortOrdersSplitAlternative)) {
      return this.getReorderedHotelGroups()
    }

    return hotel.groups
  }

  render () {
    if (!this.props.hotelPackageRate) {
      return null
    }

    const hotel = this.getHotelData()

    // Join address parts together after filtering the ones that have a value
    const addressPart1 = [
      hotel.addressLine0,
      hotel.addressLine1,
      hotel.addressLine2
    ].filter(v => v).join(' ')
    const addressPart2 = [
      hotel.addressLocality,
      hotel.addressRegion,
      hotel.postalCode
    ].filter(v => v).join(', ')

    const mapMarkers = mapHelpers.getMapMarkers(
      brandConfig,
      packageRatesReply,
      this.venueProduct,
      { name: hotel.name, latitude: hotel.latitude, longitude: hotel.longitude }
    )

    const hasFixedSummary = _.get(brandConfig, 'secure.hasFeatures.hasFixedSummary', false)
    const imagesDomain = _.get(brandConfig, 'secure.imagesDomain', '')
    const hasHotelMoreInfoModalPaultonPeppa = _.get(brandConfig, 'secure.hasFeatures.availability.hasHotelMoreInfoModalPaultonPeppa', false)
    const hasReviews = Object.keys(hotelReviews).length !== 0
    const hasRoomOptions = hotel.groups && hotel.groups.length !== 0
    const hasPlacesToEat = hotel.placesToEat && _.get(hotel.placesToEat, '[0].title')
    const hotelAccommodationType = hotel.accommodationType ? hotel.accommodationType.toLowerCase() : null
    const accommodationType = accommodationTypes[(hotelAccommodationType || '').toLowerCase()] || accommodationTypes['hotel']
    const scrollSpyItems = this.getScrollSpyItems({ accommodationType, hasRoomOptions, hasReviews, hasPlacesToEat })
    const hotelExclusiveBenefitsHTML = hasFixedSummary && hotel.hotelEventExclusiveBenefits ? hotel.hotelEventExclusiveBenefits : hotel.exclusive_benefits
    const showLeisureFacilities = hotel.leisureFacilities && _.get(brandConfig, 'secure.hasFeatures.moreInformation.showLeisureFacilities', false)
    let offsiteRoomDescription = hotel.roomFacilities
    const moreInfoModalBookNow = this.queryStringParams.moreInfoModalBookNow === 'true'

    if (!offsiteRoomDescription) {
      const occupancyTypes = (_.get(this.packageContext, 'roomRates.rooms', [])).map(room => room.occupancyType)

      offsiteRoomDescription = (hotel.defaultRoomDescriptions || [])
        // Filter descriptions based on what occupancyType(s) have been selected
        .filter(room => occupancyTypes.includes(room.occupancy_type))
        // Set the primary image to be the occupancy image and return the description
        .map(room => {
          if (room.image) hotel.primaryImage = `${imagesDomain}${room.image}`
          return room.description
        })
        // Make it one string
        .join('')
    }

    if (this.props.isLoading) {
      return (
        <div>
          <Loading />
        </div>
      )
    }

    const showPriceOnMobile = hotel.isResort
      ? _.get(brandConfig, 'secure.hasFeatures.moreInformation.mobileBasketSummaryShowResortPrice', false)
      : _.get(brandConfig, 'secure.hasFeatures.moreInformation.mobileBasketSummaryShowOffsitePrice', false)
    const amendSearchButton = <a
      {...trackingHelpers.getAttributes('Basket CTA', 'moreinformation', 'Amend Search')}
      onClick={this.props.toggleEngineModal}
      onKeyDown={(e) => { e.key === 'Enter' && this.props.toggleEngineModal() }}
      role='button'
      tabIndex='0'>
      <div className='text-right'>
        <FormattedMessage id='moreInformation.amendSearch' tagName='small' />
      </div>
    </a>
    const ctaButton = this.getCTAButton(hasRoomOptions, accommodationType)

    const hideBasketSVGs = _.get(brandConfig, 'secure.hasFeatures.hideBasketSVGs', false)
    const hasBestPricePromise = _.get(brandConfig, 'secure.hasFeatures.bestPricePromise', false)

    const checkinDate = moment.utc(hotel.checkinDate)
    const checkoutDate = moment.utc(hotel.checkoutDate)

    const checkinTime = this.hotelEventProducts.checkinTime || hotel.checkinTime
    const checkoutTime = this.hotelEventProducts.checkoutTime || hotel.checkoutTime

    const hasChildrenOrInfants = hotel.children > 0 || hotel.infants > 0

    let eventDisplay = null
    let ticketName = null
    ticketName = _.get(this, 'eventProduct.name', false)
    eventDisplay = (this.hasParkEntry)
      ? (<span className='event-name'>{ticketName}</span>)
      : (<div><strong><FormattedMessage id='common.ticket' />: </strong><span className='event-name'>{ticketName}</span></div>)
    // check for presence of alternative basket message and make sure it isn't an empty string
    const basketAlternativeMessage = (this.eventProduct && this.eventProduct.basketAlternativeMessage) && /([^\s])/.test(this.eventProduct.basketAlternativeMessage) ? this.eventProduct.basketAlternativeMessage : false
    const roomDescriptions = this.getRoomsDescriptions()
    const partyComposition = _.get(brandConfig, 'secure.withChildAges') ? this.props.hotelCompositionString : this.props.ticketCompositionString

    // Basket Summary bottom offset
    const bottomOffset = query.isXs() ? 0 : 400

    const wasText = this.props.isAnnualPass ? <FormattedMessage id='common.standardPrice' /> : <FormattedMessage id='common.was' />
    const hasMoreThanOneRoomVariant = hotel.groups && hotel.groups.length > 1

    const showData = {
      date: moment(harvestBasketData.ticket.startDate).format('DD MMM YYYY')
    }
    if (harvestBasketData.ticket.timeslot && harvestBasketData.ticket.timeslot.start &&
      harvestBasketData.ticket.timeslot.start !== '0000') {
      showData.timeslot = { start: moment(harvestBasketData.ticket.timeslot.start, 'HHmm').format('h:mma') }
    }

    const basketComponent = (
      <div>
        {query.isAtLeastSm() && _.get(brandConfig, 'secure.hasFeatures.hasUpdatedBookingSummary')
          ? <BookingSummary
            bestPricePromise={hasBestPricePromise}
            amendSearchButton={amendSearchButton}
            cancellationWaiverPrice={this.cancellationWaiver.grossPrice}
            cateringDescription={this.hotelProduct.facilities && this.hotelProduct.facilities.hasBreakfastIncluded && this.hotelProduct.breakfastType}
            cateringDetails={this.hotelProduct.facilities && this.hotelProduct.facilities.hasBreakfastIncluded && this.hotelProduct.breakfast_details_booking_flow}
            checkInDate={checkinDate}
            checkInTime={checkinTime}
            checkOutDate={checkoutDate}
            checkOutTime={checkoutTime}
            ctaButton={ctaButton}
            discountMessage={this.eventProduct.summary_discount_message}
            endpoint={endpoint}
            forceCollapse
            grossPrice={this.props.hotelPackageRate.price}
            hasCancellationProtectionMessage={_.get(brandConfig, 'secure.hasFeatures.hasCancellationProtectionMessage', false)}
            hasFixedSummary={_.get(brandConfig, 'secure.hasFeatures.hasFixedSummary', false)}
            hasParkEntry={this.hasParkEntry}
            hasYourStay={_.get(brandConfig, 'secure.hasFeatures.summary.hasYourStay', false)}
            hotelName={this.hotelProduct.name}
            reducedInfo
            roomDescriptions={roomDescriptions}
            roomName={_.get(harvestBasketData, 'hotel.roomThemeName')}
            showSectionPrices={_.get(brandConfig, 'secure.hasFeatures.basket.showSectionPrices', false)}
            showRoomsFromPrice={hasMoreThanOneRoomVariant}
            standardPrice={this.props.hotelPackageRate.standardPrice}
            ticketAdditionalDetails={this.eventProduct.additionalDescription || ''}
            ticketName={ticketName}
            wasText={wasText}
          />
          : <Basket
            basketAlternativeMessage={basketAlternativeMessage}
            button={ctaButton}
            cancellationWaiverPrice={this.cancellationWaiver.grossPrice}
            category={this.eventProduct.category}
            cateringDescription={this.hotelProduct.facilities && this.hotelProduct.facilities.hasBreakfastIncluded && this.hotelProduct.breakfastType}
            checkInDate={checkinDate}
            checkInTime={checkinTime}
            checkOutDate={checkoutDate}
            checkOutTime={checkoutTime}
            discountMessage={this.eventProduct && this.eventProduct.summary_discount_message &&
              <Basket.SummaryDiscountMessage
                message={this.eventProduct.summary_discount_message}
              />
            }
            endpoint={endpoint}
            eventTitle={eventDisplay}
            grossPrice={this.props.hotelPackageRate.price}
            hasBestPricePromise={hasBestPricePromise}
            hasFixedSummary={hasFixedSummary}
            hasParkEntry={this.hasParkEntry}
            hideBasketSVGs={hideBasketSVGs}
            hotelEventDescription={this.hotelEventProducts.description || null}
            name=''
            partyComposition={partyComposition}
            reducedInfo
            roomDescriptions={roomDescriptions}
            showRoomsFromPrice={hasMoreThanOneRoomVariant}
            showData={showData}
            showPriceOnMobile={showPriceOnMobile}
            standardPrice={this.props.hotelPackageRate.standardPrice}
            ticketName={ticketName}
            toggleEngineModal={this.props.toggleEngineModal}
            venueType={this.venueProduct.venueType}
            wasText={wasText}
            whatsIncluded={this.eventProduct.whatsIncluded}
          />
        }
      </div>
    )

    const hotelImportInformation = hotel.importantInformation
    const allHotelDateSpecificInformation = hotel.dateSpecificInformation
    const allDateSpecificInformation = _.values(hotel.dateSpecificInformation)
    const dateSpecificInformation = _.includes(hotel.dateSpecificInformation, allDateSpecificInformation[0]) ? allDateSpecificInformation[0] : null

    const hotelStandardPrice = Number(hotel.standardPrice) || 0
    const getMenuTabItem = (item, index) => {
      return (
        <li className={item.className} key={`scrollSpyItem${index}`}>
          <a
            aria-controls={item.hash}
            href={`#${item.hash}`}
            {...trackingHelpers.getAttributes('Navigation', 'moreinformation', item.label)}
            onClick={(e) => {
              e.preventDefault()
              generalHelpers.scrollElementIntoView(`#${item.hash}`)
            }}
            role='button'>
            <FormattedMessage id={item.messageKey} values={item.messageValues} />
          </a>
        </li>
      )
    }

    const getOverviewBlock = () => {
      return (
        <React.Fragment>
          <section id='overview-block' className='block-sm affix-top'>
            <h2>
              <FormattedMessage id='moreInformation.overview' />
            </h2>
            <div className='lead' dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(hotel.overview) }} />
            <a
              role='button'
              aria-controls='hotel-block'
              {...trackingHelpers.getAttributes('Read More', 'Links', 'hotel-block')}
              href='#hotel-block'
              onClick={(e) => {
                e.preventDefault()
                generalHelpers.scrollElementIntoView('#hotel-block')
              }}>
              <FormattedMessage id='common.readMoreAboutHotel' values={{ hotelName: hotel.name }} />
            </a>
            <SectionNavigation section='overview-block' />
          </section>
          <hr aria-hidden='true' role='presentation' />
        </React.Fragment>
      )
    }

    const handleExclusiveBenefitsHTML = () => {
      if (hotelExclusiveBenefitsHTML && moreInfoModalBookNow && hasHotelMoreInfoModalPaultonPeppa) return ''
      return (
        <div className='bg-secondary well featured-list'>
          {hotel.exclusiveBenefitsHeading ? <span dangerouslySetInnerHTML={{ __html: hotel.exclusiveBenefitsHeading }} /> : <h4><FormattedMessage id='moreInformation.exclusiveBenefits' /></h4>}
          <div dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(hotelExclusiveBenefitsHTML) }} />
        </div>
      )
    }

    const getHotelInfo = () => {
      return (
        <React.Fragment>
          <section id='hotel-block' className='block-sm'>
            <Row>
              <Col xs={12} sm={8}>
                <h2>
                  <FormattedMessage id='common.accommodationInformation' values={{
                    accommodationType: this.props.intl.formatMessage({ id: accommodationType.hotel })
                  }} />
                </h2>
                <div dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(hotel.hotelFacilities) }} />
              </Col>
              <Col xs={12} sm={4}>
                {handleExclusiveBenefitsHTML()}
                {(checkinTime || checkoutTime) &&
                  <div className='bg-primary well'>
                    <ul className='list-featured'>
                      {checkinTime &&
                        <li>
                          <strong>
                            <FormattedMessage id='common.checkin' />:
                          </strong> {checkinTime}
                        </li>
                      }
                      {checkoutTime &&
                        <li>
                          <strong>
                            <FormattedMessage id='common.checkout' />:
                          </strong> {checkoutTime}
                        </li>
                      }
                    </ul>
                  </div>
                }
              </Col>
            </Row>

            {(hotel.hotel_at_a_glance || hotel.guestFacilities || (hotel.childFacilities && hasChildrenOrInfants)) &&
              <div>
                <hr aria-hidden='true' role='presentation' />
                <Row>
                  {hotel.hotel_at_a_glance &&
                    <Col className='block-xs' sm={6}>
                      <h3>
                        <FormattedMessage id='moreInformation.accommodationAtAGlance' values={{
                          accommodationType: this.props.intl.formatMessage({ id: accommodationType.hotel })
                        }} />:
                      </h3>
                      <div className='featured-list' dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(hotel.hotel_at_a_glance) }} />
                    </Col>
                  }

                  {hotel.guestFacilities &&
                    <Col className='block-xs' sm={6}>
                      <h3>
                        <FormattedMessage id='moreInformation.guestFacilities' />:
                      </h3>
                      <div className='featured-list' dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(hotel.guestFacilities) }} />
                    </Col>
                  }
                </Row>

                <Row>
                  {/* Hidden for offsite hotels as per FP-14066 until we find a way to display it properly (FP-14067) */}
                  {hotel.childFacilities && hasChildrenOrInfants && hotel.isResort &&
                    <Col className='block-xs' sm={6}>
                      <h3>
                        <FormattedMessage id='moreInformation.childrensFacilities' />:
                      </h3>
                      <div className='featured-list' dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(hotel.childFacilities) }} />
                    </Col>
                  }
                  {showLeisureFacilities &&
                    <Col className='block-xs' sm={12}>
                      <h3>
                        <FormattedMessage id='moreInformation.leisureFacilities' />:
                      </h3>
                      <div className='featured-list' dangerouslySetInnerHTML={{ __html: generalHelpers.italicToSmall(hotel.leisureFacilities) }} />
                    </Col>
                  }
                </Row>
              </div>
            }
            <SectionNavigation section='hotel-block' />

          </section>
          <hr aria-hidden='true' role='presentation' />
        </React.Fragment>
      )
    }

    const handleOverviewBlock = () => {
      if (hotel.overview && moreInfoModalBookNow) {
        return (
          <SplitTest.Experiment name='EXP-020:AvailabilityMoreInfoModalButton'>
            <SplitTest.Variant name='show_original'>
              {getOverviewBlock()}
            </SplitTest.Variant>
            <SplitTest.Variant name='show_alternative' />
          </SplitTest.Experiment>
        )
      }
      return getOverviewBlock()
    }

    if (hotel.importantInformation || dateSpecificInformation) {
      // Track when we show this. The '.replace' is to remove HTML tags
      trackingHelpers.track('sb.track', 'Event Information shown', 'Event Information', `${(hotel.importantInformation || dateSpecificInformation).replace(/<[^>]*>?/gm, '')}`)
    }

    let orderRoomsByPrice = (this.hasAlternativeRoomAndGroupSortOrders && this.alternativeRoomGroupSortOrder.length && this.hasAlternativeRoomAndGroupSortOrdersSplitTest && this.isAlternativeRoomAndGroupSortOrdersSplitAlternative) ||
    (this.hasAlternativeRoomAndGroupSortOrders && this.alternativeRoomGroupSortOrder.length && !this.hasAlternativeRoomAndGroupSortOrdersSplitTest)

    return (
      <div data-automated-test='more-information-page' className={hotel.isResort ? 'hotel-type-resort' : 'hotel-type-offsite'}>
        <SplitTest.Experiment name='SIOP-294:alternativeRoomAndGroupSortOrders'>
          <SplitTest.Variant name='show_original' />
          <SplitTest.Variant name='show_alternative' />
        </SplitTest.Experiment>

        {this.props.progressTracker}

        <div className='top-block'>
          <section>
            <div className='container'>
              <Row>
                <Col className='block-sm' xs={12} sm={8}>

                  <h1>
                    {hotel.name}&nbsp;
                    <span>
                      <span className='sr-only'>
                        <FormattedMessage id='common.hotelNameStarRating' values={{ count: hotel.starRating }} />
                      </span>
                      <span aria-hidden='true' className='text-nowrap stars' role='presentation'>
                        {[...Array(+hotel.starRating || 0)].map((_, index) => <span key={`starRating${index}`}>&#9733;</span>)}
                      </span>
                    </span>
                  </h1>

                  <p>
                    {`${addressPart1}, ${addressPart2} `}
                    <a
                      aria-controls='map-block'
                      {...trackingHelpers.getAttributes('View on map', 'Links', 'map-block')}
                      href='#map-block'
                      onClick={(e) => {
                        e.preventDefault()
                        generalHelpers.scrollElementIntoView('#map-block')
                      }}
                      onKeyDown={(e) => {
                        if (e.key !== 'Enter') {
                          return
                        }
                        e.preventDefault()
                        generalHelpers.scrollElementIntoView('#map-block')
                      }}
                      role='button'
                      tabIndex='0'>
                      <SVGMap
                        height='1em'
                        width='1em'
                        viewBox='0 0 24 24' />&nbsp;
                      <FormattedMessage id='moreInformation.viewOnMap' />
                    </a>
                  </p>

                </Col>

                <Col xs={12} sm={4}>
                  <aside className='affix-top' ref={(el) => { this.basket = el }} data-spy='affix' data-offset-top='80' data-offset-bottom={bottomOffset}>
                    {basketComponent}
                  </aside>
                </Col>
              </Row>
            </div>
          </section>
          {(this.props.hotelPackageRate.gallery || []).length > 0 &&
            <div>
              <Carousel
                hotelName={this.props.hotelPackageRate.name}
                indicators
                imgs={this.props.hotelPackageRate.gallery}
                imgOnly
                name={this.props.hotelPackageRate.name}
                onClick={i => this.showImageModal(this.props.hotelPackageRate.gallery, 'Hotel Gallery', this.props.hotelPackageRate.name, 'Carousel', i)}
                trackingCategory='Carousel'
              />
              <div className='carousel-btn-container'>
                <Button
                  {...trackingHelpers.getAttributes(this.props.hotelPackageRate.name, 'Carousel', this.props.hotelPackageRate.gallery.length)}
                  onClick={e => {
                    e.stopPropagation()
                    e.preventDefault()
                    this.showImageModal(this.props.hotelPackageRate.gallery, 'Hotel Gallery', this.props.hotelPackageRate.name, 'Carousel', this.state.modalContent.activeIndex)
                  }}
                  onKeyDown={e => {
                    if (e.key !== 'Enter') {
                      return
                    }
                    e.stopPropagation()
                    e.preventDefault()
                    this.showImageModal(this.props.hotelPackageRate.gallery, 'Hotel Gallery', this.props.hotelPackageRate.name, 'Carousel', this.state.modalContent.activeIndex)
                  }}
                  bsSize='small'
                  bsStyle='info'
                  className='hidden-xs'>
                  <SVGCamera
                    height='14'
                    width='14'
                    viewBox='0 0 24 24' />&nbsp;
                  <FormattedMessage id='moreInformation.viewGallery' />
                </Button>
                {this.props.hotelPackageRate.videoId &&
                  <Button
                    {...trackingHelpers.getAttributes('Play', 'Youtube', this.props.hotelPackageRate.videoId)}
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      this.showImageModal(this.props.hotelPackageRate.gallery.filter(e => e.type === 'video'), 'Hotel Video', this.props.hotelPackageRate.name)
                    }}
                    onKeyDown={e => {
                      if (e.key !== 'Enter') {
                        return
                      }
                      e.stopPropagation()
                      e.preventDefault()
                      this.showImageModal(this.props.hotelPackageRate.gallery.filter(e => e.type === 'video'), 'Hotel Video', this.props.hotelPackageRate.name)
                    }}
                    bsSize='small'
                    bsStyle='primary'
                    className='hidden-xs'>
                    <SVGPlay
                      height='14'
                      width='14'
                      viewBox='0 0 24 24' />&nbsp;
                    <FormattedMessage id='moreInformation.watchVideo' />
                  </Button>
                }

                {this.props.hotelPackageRate.interactive_tour &&
                  <Button
                    onClick={e => {
                      e.stopPropagation()
                      e.preventDefault()
                      this.showImageModal(this.props.hotelPackageRate.gallery.filter(e => e.type === 'interactiveTour'), 'Interactive Tour', this.props.hotelPackageRate.name)
                    }}
                    onKeyDown={e => {
                      if (e.key !== 'Enter') {
                        return
                      }
                      e.stopPropagation()
                      e.preventDefault()
                      this.showImageModal(this.props.hotelPackageRate.gallery.filter(e => e.type === 'interactiveTour'), 'Interactive Tour', this.props.hotelPackageRate.name)
                    }}
                    bsSize='small'
                    bsStyle='success'
                    className='hidden-xs'>
                    <SVG360
                      height='14'
                      width='14'
                      viewBox='0 0 24 24' />&nbsp;
                    <FormattedMessage id='moreInformation.interactiveTour' />
                  </Button>
                }

              </div>
            </div>
          }
        </div>

        {/* Page specific navigation */}
        <div className='affix-container' id='main-navigation'>
          <nav className='navbar navbar-default affix-top nav-container' data-spy='affix' data-offset-top='720' role='navigation'>
            <div className='container p-xs-0'>
              {moreInfoModalBookNow &&
                <SplitTest.Experiment name='EXP-020:AvailabilityMoreInfoModalButton'>
                  <SplitTest.Variant name='show_original'>
                    <ScrollSpy
                      className='nav navbar-nav m-0'
                      currentClassName='active'
                      offset={-20}
                      items={[...scrollSpyItems.map(item => item.hash)]}
                      role='tablist'>
                      {scrollSpyItems
                        .map((item, index) => getMenuTabItem(item, index))
                      }
                    </ScrollSpy>
                  </SplitTest.Variant>

                  <SplitTest.Variant name='show_alternative'>
                    <ScrollSpy
                      className='nav navbar-nav m-0'
                      currentClassName='active'
                      offset={-20}
                      items={[...scrollSpyItems
                        .filter(item => item.hash !== 'overview-block')
                        .map((item, index, array) => {
                          if (index === 1) return array[0].hash
                          if (index === 0) return array[1].hash
                          return item.hash
                        })
                      ]}
                      role='tablist'>
                      {scrollSpyItems
                        .filter(item => item.hash !== 'overview-block')
                        .map((item, index) => getMenuTabItem(item, index))
                        .map((item, index, array) => {
                          if (index === 1) return array[0]
                          if (index === 0) return array[1]
                          return item
                        })}
                    </ScrollSpy>
                  </SplitTest.Variant>
                </SplitTest.Experiment>
              }

              {!moreInfoModalBookNow &&
                <ScrollSpy
                  className='nav navbar-nav m-0'
                  currentClassName='active'
                  offset={-20}
                  items={[...scrollSpyItems.map(item => item.hash)]}
                  role='tablist'>
                  {scrollSpyItems
                    .map((item, index) => getMenuTabItem(item, index))
                  }
                </ScrollSpy>}
            </div>
          </nav>
        </div>

        <div className='container hotel-more-information'>
          <Row>
            {/* Information alerts */}
            {/* Non date 'Fixed' important information if needed */}
            {hotelImportInformation && (
              <Col xs={12} sm={8} className={this.alertInfoMobileClassName}>
                <Alert bsStyle='info' className={`mb-2 ${this.alertInfoMobileClassName}`}>
                  <div dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(hotelImportInformation) }} />
                </Alert>
              </Col>
            )}

            {/* Date specific info as required */}
            {allHotelDateSpecificInformation && (
              Object.entries(allHotelDateSpecificInformation).map(([key, hotelDateSpecificInformation], index) => {
                if (key === 'all' || key === 'moreInformation') {
                  return (
                    <Col xs={12} sm={8} key={index} className={this.alertInfoMobileClassName}>
                      <Alert bsStyle='info' className={`mb-2 ${this.alertInfoMobileClassName}`}>
                        <div dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(hotelDateSpecificInformation) }} />
                      </Alert>
                    </Col>
                  )
                }
              })
            )}
            <Col xs={12} sm={8}>
              <main id='main' role='main' className='block-sm'>

                {/* 'Overview' section */}
                {/* {hotel.overview && (
                  // No need to show the hotel information again if they came through via the more info modal on availability
                  moreInfoModalBookNow ? (
                    <SplitTest.Experiment name='EXP-020:AvailabilityMoreInfoModalButton'>
                      <SplitTest.Variant name='show_original'>
                        {getOverviewBlock()}
                      </SplitTest.Variant>
                      <SplitTest.Variant name='show_alternative' />
                    </SplitTest.Experiment>
                  ) : (
                    handleOverviewBlock()
                  )
                )} */}

                {handleOverviewBlock()}

                {/* 'Rooms' section */}
                {hasRoomOptions &&
                  <ResponsiveWaypoint
                    breakPoint='isXs'
                    onEnter={() => this.setBasketVisibility(false)}
                    onLeave={() => this.setBasketVisibility(true)}>
                    <section id='room-block' className='block-sm pull-out-xs tab-block affix-top'>
                      <h2>
                        <FormattedMessage id='moreInformation.accommodationOptions' values={{ accommodationType: <FormattedMessage id={accommodationType.room.singular} /> }} />
                      </h2>

                      <div dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(hotel.room_options_description) }} />

                      <ul className='nav nav-tabs'>
                        {this.getHotelGroups().map((group, index) => {
                          const currentActive = _.get(this.state, 'group.tag')
                          const activeClass = group.tag === currentActive || (!currentActive && index === 0) ? 'active' : ''
                          const className = group.available ? activeClass : 'disabled'
                          const dataLabel = index + 1 // position in the list
                          // quick fix to make things fit on smaller screens
                          const groupLabel = group.label && group.label.replace(/ /, '<br/>')
                          return (
                            <li role='presentation' className={className} key={`group${index}`}>
                              <a
                                aria-expanded='false'
                                className='pull-left'
                                {...trackingHelpers.getAttributes(`Select group: ${group.label}`, 'Group navigation CTA', dataLabel)}
                                data-toggle='tab'
                                onClick={() => group.available && this.switchGroup(group.tag)}
                                onKeyDown={(e) => { e.key === 'Enter' && group.available && this.switchGroup(group.tag) }}
                                role='tab'>

                                <span className='small large-sm text-primary pull-left' dangerouslySetInnerHTML={{ __html: groupLabel }} />
                                <span className='tiny small-sm clearfix pull-left'>
                                  {group.available ? (
                                    <span>
                                      <FormattedMessage id='common.from' />&nbsp;
                                      <br className='visible-xs' />
                                      <DisplayPrice price={group.fromPrice} />
                                    </span>
                                  ) : (
                                    <span>
                                      <br className='visible-xs' />
                                      <FormattedMessage id='common.soldOut' />
                                    </span>
                                  )
                                  }
                                </span>
                              </a>
                            </li>
                          )
                        })}
                      </ul>

                      {/* This is where the rooms will be rendered */}
                      <div className='tab-content container-fluid'>
                        <div className='tab-pane active'>
                          {this.state.group &&
                            (this.state.group.themes || []).map(theme => {
                              // Merge the first image of the theme with all the room images part of this theme together

                              const themeRooms = orderRoomsByPrice
                                ? (theme.rooms || []).sort((a, b) => a.price - b.price)
                                : theme.rooms

                              const currentThemeImages = (themeRooms || []).map(room => {
                                return room.images.map(image => {
                                  return {
                                    caption: room.name,
                                    src: image
                                  }
                                }
                                )
                              }).reduce((acc, curr) => {
                                return acc.concat(curr)
                              }, [
                                { src: theme.primaryImage }
                              ])
                              return (
                                <Row className='block-sm' key={`theme-${theme.name}`}>
                                  <Col xs={12} md={4}>
                                    {query.isXs()
                                      ? <Carousel
                                        hotelName={this.props.hotelPackageRate.name}
                                        indicators
                                        imgs={currentThemeImages}
                                        imgOnly
                                        name={this.props.hotelPackageRate.name}
                                        trackingCategory='Carousel'
                                      /> : (
                                        <React.Fragment>
                                          {theme.primaryImage &&
                                            <a
                                              role='button'
                                              tabIndex='0'
                                              onClick={() => this.showImageModal(currentThemeImages, 'Theme Gallery', theme.name, 'Room Carousel')}
                                              onKeyDown={(e) => { e.key === 'Enter' && this.showImageModal(currentThemeImages, 'Theme Gallery', theme.name, 'Room Carousel') }}>
                                              <ResponsiveImage
                                                lazyload
                                                height={124}
                                                alt={theme.name}
                                                src={theme.primaryImage}
                                                offset={{ top: 100, bottom: 0 }}
                                                containerSizes={[737, 440, 176, 230]}
                                              />
                                            </a>
                                          }
                                        </React.Fragment>
                                      )}
                                    {theme.roomBenefits &&
                                      <Col xs={12} className='block-sm featured-list bg-secondary'>
                                        <div dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(theme.roomBenefits) }} />
                                      </Col>
                                    }
                                  </Col>
                                  <Col xs={12} md={8}>
                                    <h3>{theme.name}</h3>
                                    <p dangerouslySetInnerHTML={{ __html: theme.description }} />
                                    <Col>
                                      <ListGroup
                                        aria-multiselectable='true'
                                        role='tablist' >
                                        {(theme.rooms || []).map((room, index) => {
                                          const dataLabel = index + 1 // position in list
                                          return (
                                            <ListGroupItem className='bg-muted' data-product-id={room.id} key={`${room.id}`}>
                                              <Row>
                                                <Col sm={8}>
                                                  <h5>
                                                    {room.name}
                                                  </h5>
                                                  {(room.layoutDescription || room.bathroomDescription) &&
                                                    <ul className='list-unstyled'>
                                                      {room.layoutDescription &&
                                                        <li className='small'>
                                                          <SVGBed
                                                            height='14'
                                                            width='14'
                                                            viewBox='0 0 24 24' /> -&nbsp;
                                                          <div className='d-inline force-inner-first-child-inline' dangerouslySetInnerHTML={{ __html: room.layoutDescription }} />
                                                        </li>
                                                      }
                                                      {room.bathroomDescription &&
                                                        <li className='small'>
                                                          <SVGBath
                                                            height='14'
                                                            width='14'
                                                            viewBox='0 0 24 24' /> -&nbsp;
                                                          <div className='d-inline force-inner-first-child-inline' dangerouslySetInnerHTML={{ __html: room.bathroomDescription }} />
                                                        </li>
                                                      }
                                                    </ul>
                                                  }
                                                </Col>
                                                <Col sm={4} className='text-right'>
                                                  {!!room.standardPrice &&
                                                    <p>
                                                      <small className='d-block text-muted text-right text-capitalize'>
                                                        {wasText} <s><DisplayPrice price={room.standardPrice} /></s>
                                                      </small>
                                                    </p>
                                                  }
                                                  <div className='h3 mt-0'>
                                                    <DisplayPrice price={room.price} />
                                                  </div>
                                                  <a
                                                    className='btn btn-primary btn-block'
                                                    {...trackingHelpers.getAttributes(`Select room: ${room.name}`, 'Room CTA', dataLabel)}
                                                    onClick={() => this.selectProduct(room, theme.name)}
                                                    onKeyDown={(e) => { e.key === 'Enter' && this.selectProduct(room, theme.name) }}
                                                    role='button'
                                                    tabIndex='0'>
                                                    <FormattedMessage id='common.book' />
                                                  </a>
                                                  {this.hasRoomFinder && this.props.isDiscoveryPrimed && this.getRoomFinderButton(room)}
                                                </Col>
                                              </Row>
                                              <Row>
                                                <Col xs={12} className='block-sm'>
                                                  <small dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(room.description) }} />
                                                </Col>
                                                {!query.isXs() && <Col xs={12}>
                                                  {/* This should only display the first 4 images as per the design */}
                                                  {(room.images || []).slice(0, 4).map((image, index) => {
                                                    const openRoomImagesGallery = (e) => {
                                                      // Array of objects to have the caption tied in with the image
                                                      const images = room.images.map(image => ({
                                                        caption: room.name,
                                                        src: image
                                                      }))
                                                      this.showImageModal(images, 'Room Gallery', theme.name, 'Room Carousel', index + 1)
                                                    }
                                                    return (
                                                      <a
                                                        role='button'
                                                        {...trackingHelpers.getAttributes('Image Modal', 'moreinformation', room.name)}
                                                        className='col-md-3 col-xs-6 p-0'
                                                        key={`roomImage${index}`}
                                                        tabIndex='0'
                                                        onClick={openRoomImagesGallery}
                                                        onKeyDown={(e) => e.key === 'Enter' && openRoomImagesGallery()}>
                                                        <ResponsiveImage
                                                          lazyload
                                                          src={image}
                                                          height={60}
                                                          alt={hotel.name}
                                                          offset={{ top: 100, bottom: 0 }}
                                                          containerSizes={[352, 204, 88, 110]}
                                                        />
                                                      </a>
                                                    )
                                                  })}
                                                </Col>
                                                }
                                              </Row>
                                            </ListGroupItem>
                                          )
                                        })}
                                      </ListGroup>
                                    </Col>

                                  </Col>
                                </Row>
                              )
                            })
                          }
                        </div>
                      </div>
                      <SectionNavigation section='room-block' />
                    </section>
                  </ResponsiveWaypoint>
                }

                {hasRoomOptions && <hr aria-hidden='true' role='presentation' />}

                {/* Offsite will show roomFacilities key, this sits below the hotel info on purpose */}
                {!hasRoomOptions && offsiteRoomDescription &&
                  <section id='room-block' className='block-sm pull-out-xs tab-block'>
                    <h2>
                      <FormattedMessage id='common.rooms' />
                    </h2>

                    <div dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(hotel.room_options_description) }} />

                    <div className='tab-content container-fluid'>
                      <div className='tab-pane active'>
                        <Row className='block-sm'>
                          <Col xs={12} md={4}>
                            {hotel.primaryImage &&
                              <ResponsiveImage
                                alt={hotel.name}
                                src={hotel.primaryImage}
                                containerSizes={[480, 435, 230, 230]}
                              />
                            }
                            {hotel.roomInformationList &&
                              <Col xs={12} className='block-sm featured-list bg-secondary'>
                                <div dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(hotel.roomInformationList) }} />
                              </Col>
                            }
                          </Col>
                          <Col xs={12} md={8}>
                            <div dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(offsiteRoomDescription) }} />
                            {brandConfig.accessibleRoomInformation &&
                              <small dangerouslySetInnerHTML={{ __html: brandConfig.accessibleRoomInformation }} />
                            }
                            <div className='bg-muted panel'>
                              <div className='panel-body'>
                                <div className='row'>
                                  <div className='col-sm-8'>
                                    <div className='h3 mt-0'>
                                      {hotelStandardPrice > 0 &&
                                        <small className='d-block text-muted text-capitalize'>
                                          {wasText} <s><DisplayPrice price={hotelStandardPrice} /></s>
                                        </small>
                                      }
                                      {hotel.price && <DisplayPrice price={hotel.price} />}
                                    </div>
                                  </div>
                                  <div className='col-sm-4'>
                                    {!hasRoomOptions &&
                                      <a
                                        role='button'
                                        aria-controls='room-block'
                                        className='btn btn-sm btn-primary btn-block'
                                        {...trackingHelpers.getAttributes('Basket CTA', 'moreinformation', 'Book')}
                                        tabIndex='0'
                                        onClick={() => this.selectProduct()}
                                        onKeyDown={(e) => { e.key === 'Enter' && this.selectProduct() }}>
                                        <FormattedMessage id='common.book' />
                                      </a>
                                    }
                                  </div>
                                </div>
                              </div>
                            </div>
                          </Col>
                        </Row>
                      </div>
                    </div>
                    <SectionNavigation section='room-block' />
                  </section>
                }
                {!hasRoomOptions && offsiteRoomDescription && <hr aria-hidden='true' role='presentation' />}

                {/* 'Hotel Info' section */}
                {getHotelInfo()}

                {/* 'Reviews' section */}
                {hasReviews &&
                  <section id='review-block' className='block-sm'>
                    <h2>
                      <FormattedMessage id='common.reviews' />
                    </h2>

                    {Object.keys(hotelReviews).slice(0, 3).map(key => <Review key={key} review={hotelReviews[key]} />)}
                    {Object.keys(hotelReviews).length > 3 &&
                      <div>
                        {!this.state.showMoreReviews &&
                          <a
                            tabIndex='0'
                            onClick={() => this.setState((state) => ({ showMoreReviews: !state.showMoreReviews }))}
                            onKeyDown={(e) => e.key === 'Enter' && this.setState((state) => ({ showMoreReviews: !state.showMoreReviews }))}
                            role='button'>
                            <FormattedMessage id='common.showMoreReviews' values={{ count: Object.keys(hotelReviews).length - 3 }} />
                          </a>
                        }
                        <Collapse in={this.state.showMoreReviews}>
                          <div>
                            {Object.keys(hotelReviews).slice(3).map(key => <Review key={key} review={hotelReviews[key]} />)}
                          </div>
                        </Collapse>
                      </div>
                    }

                    <SectionNavigation section='review-block' />
                  </section>
                }
                {hasReviews && <hr aria-hidden='true' role='presentation' />}

                {/* 'Places To Eat' section */}
                {hasPlacesToEat &&
                  <section id='placestoeat-block' className='block-sm'>
                    <h2>
                      <FormattedMessage id='common.dining' />
                    </h2>

                    {hotel.placesToEat && Object.keys(hotel.placesToEat).map((key, index, array) => {
                      const placeToEat = hotel.placesToEat[key]
                      // Allow content editors to add the Places To Eat image with or without the path
                      if (placeToEat.image && !placeToEat.image.match(/\//)) {
                        placeToEat.image = `hotel/${hotel.id}/16-9/${placeToEat.image}`
                      }
                      // Check if we want to show the second column (sometimes these things don't have an image)
                      const hasSecondColumn = placeToEat.image || placeToEat.menu
                      return (
                        <div key={`placeToEat${index}`}>
                          <Row>
                            <Col
                              xs={12}
                              sm={hasSecondColumn ? 6 : 9}
                              smPush={hasSecondColumn ? 3 : null}>
                              <h3 className='media-heading' dangerouslySetInnerHTML={{ __html: placeToEat.title }} />
                              <p dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(placeToEat.description) }} />
                            </Col>
                            {hasSecondColumn &&
                              <Col
                                xs={6}
                                sm={3}
                                smPull={6}>
                                {placeToEat.image &&
                                  <ResponsiveImage
                                    lazyload
                                    height={94}
                                    alt={placeToEat.title}
                                    offset={{ top: 100, bottom: 0 }}
                                    containerSizes={[352, 95, 132, 165]}
                                    src={`${imagesDomain}${placeToEat.image}`}
                                  />
                                }
                                {placeToEat.menu &&
                                  <div className='block-xs'>
                                    <a
                                      className='btn btn-default btn-sm btn-block'
                                      {...trackingHelpers.getAttributes('Dining CTA', 'moreinformation', 'View Menu')}
                                      href={placeToEat.menu}
                                      target='_blank'>
                                      <FormattedMessage id='moreInformation.viewMenu' />
                                    </a>
                                  </div>
                                }
                              </Col>
                            }
                            {placeToEat.opening_times &&
                              <Col
                                xs={hasSecondColumn ? 6 : 12}
                                sm={3}>
                                <div className='well well-sm'>
                                  <h5>
                                    <FormattedMessage id='common.openingTimes' />
                                  </h5>
                                  <div dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(placeToEat.opening_times) }} />
                                </div>
                              </Col>
                            }
                          </Row>
                          {index < array.length - 1 && <hr aria-hidden='true' role='presentation' />}
                        </div>
                      )
                    })}

                    <SectionNavigation section='placestoeat-block' />
                  </section>
                }
                {hasPlacesToEat && <hr aria-hidden='true' role='presentation' />}

                {/* 'Map' section */}
                <section id='map-block' className='block-sm'>
                  <h2>
                    <FormattedMessage id='common.map' />
                  </h2>
                  {mapMarkers.length > 0 && mapMarkers[0].lat !== 0 &&
                    <LazyLoad height={480} offset={[470, 0]} debounce={100}>
                      {/* height is the same height it gets from styling.
                        * 400 in offset is an arbitrary number, used to start loading when the map is 400px below the fold
                        */}
                      <GoogleMapsWrapper markers={mapMarkers} onClick={this.onGoogleMapsClick} fitBounds />
                    </LazyLoad>
                  }
                  <SectionNavigation section='map-block' />
                </section>

              </main>

            </Col>
          </Row>

          {(this.props.visibleModals.imageModal && _.get(this.state, 'modalContent.images', []).length > 0) &&
            <Modal
              bsSize='large'
              keyboard
              onHide={() => this.props.onHideModal('imageModal')}
              show>
              <Modal.Header closeButton>
                <Modal.Title>
                  {this.state.modalContent.title}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Carousel
                  buttons
                  counter
                  hotelName={this.props.hotelPackageRate.name}
                  indicators
                  imgs={this.state.modalContent.images}
                  name={this.props.hotelPackageRate.name}
                  preview
                  startPosition={this.state.modalContent.activeIndex}
                  trackingCategory={this.state.modalContent.trackingCategory}
                />
              </Modal.Body>
            </Modal>
          }

          {(this.props.visibleModals.contentModal && this.state.modalContent) &&
            <Modal
              bsSize='sm'
              keyboard
              onHide={() => this.props.onHideModal('contentModal')}
              show>
              <Modal.Header closeButton>
                <Modal.Title>
                  {this.state.modalContent.title}
                </Modal.Title>
              </Modal.Header>
              {this.state.modalContent.image &&
                <ResponsiveImage
                  lazyload={false}
                  defaultSize={300}
                  src={`${imagesDomain}${this.state.modalContent.image}`}
                />
              }
              <Modal.Body dangerouslySetInnerHTML={{ __html: generalHelpers.parsePrismicHash(this.state.modalContent.description) }} />
              <Modal.Footer>
                <Button onClick={() => this.props.onHideModal('contentModal')}>
                  <FormattedMessage id='common.close' />
                </Button>
              </Modal.Footer>
            </Modal>
          }
          {this.props.isDiscoveryPrimed && (
            <DiscoveryModal />
          )}
        </div>
      </div>
    )
  }
}

MoreInformation.propTypes = {
  hotelPackageRate: PropTypes.object,
  intl: intlShape,
  isAnnualPass: PropTypes.bool.isRequired,
  navigateTo: PropTypes.func.isRequired,
  onToggleModal: PropTypes.func.isRequired,
  progressTracker: PropTypes.node.isRequired,
  totalPackages: PropTypes.number,
  visibleModals: PropTypes.shape({
    contentModal: PropTypes.bool,
    imageModal: PropTypes.bool
  }).isRequired
}

function mapStateToProps (state) {
  return {
    hotelCompositionString: state.basket.hotelPartyString,
    hotelPackageRate: state.packageRates.hotelPackageRate,
    isAnnualPass: state.annualPass.isAnnualPass,
    isDiscoveryPrimed: state.discovery.isPrimed,
    ticketCompositionString: state.basket.ticketPartyString,
    totalPackages: state.packageRates.total,
    visibleModals: state.modal.visible
  }
}

function mapDispatchToProps (dispatch) {
  return {
    getPackageRates: (hotelCode) => {
      return dispatch(packageRates.get()).then(() => {
        return dispatch(packageRates.getGrouped(hotelCode))
      })
    },
    onHideModal: (modalName) => {
      return dispatch(modal.hide(modalName))
    },
    onShowModal: (modalName) => {
      return dispatch(modal.show(modalName))
    },
    onToggleModal: (modalName) => {
      return dispatch(modal.toggle(modalName))
    },
    toggleEngineModal: () => {
      if (brandConfig.secure.hasFeatures.hasPackaging) return dispatch(engine.toggleModal())
      return dispatch(engineOld.toggleModal())
    },
    checkDiscoveryPrimed: () => {
      return dispatch(discovery.checkSearchIsDiscoveryPrimed())
    },
    openDiscoveryModal: (roomId) => {
      dispatch({
        type: 'MODAL_TOGGLE',
        modalName: 'discoveryCalendar'
      })

      dispatch({
        type: 'DISCOVERY_CALENDAR',
        roomId
      })
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(MoreInformation))
export {
  MoreInformation
}
