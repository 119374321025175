import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class Rating extends PureComponent {
  render () {
    if (!this.props.rating || isNaN(this.props.rating)) return null

    const classes = `stars ${this.props.className}`
    const ratingCompiled = [...Array(+this.props.rating)].map((star, index) => <span key={`star-${index}`}>&#9733;</span>)

    return (
      <span className={classes}>
        {ratingCompiled}
      </span>
    )
  }
}

Rating.defaultProps = {
  className: ''
}

Rating.propTypes = {
  className: PropTypes.string,
  rating: PropTypes.number.isRequired
}

export default Rating
