import brandMessages from './en/brandMessages'
import domainMessages from './en/domainMessages'
import messages from './en/messages'

const getMessages = brandConfig => {
  if (!brandConfig) return messages
  return {
    ...messages,
    ...brandMessages[brandConfig.brand],
    ...domainMessages[brandConfig.secure && brandConfig.secure.url]
  }
}

export default getMessages
