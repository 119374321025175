import _ from 'lodash'
import moment from 'moment'
import languageHelpers from '../helpers/languageHelpers'
import config from '../configs/config'
const { harvestBasketData } = config

const datesDiffInDays = (date1, date2) => {
  // Ensuring the number is a positive integer (1, 2 etc not -1, -2 etc).
  // This is because if date2 comes after date1 the value is negative &
  // we only care about the diff value.
  return Math.abs(moment(date1).diff(moment(date2), 'days'))
}

const filterSeenMessages = (newMessages = [], existingMessages = []) => {
  return newMessages.filter(message => {
    return existingMessages.indexOf(message) === -1
  })
}

const withChildAges = _.get(config.brandConfig, 'secure.withChildAges', false)

const engine = (state = {
  action: 'availability',
  agent: undefined,
  brandConfig: {
    partyComps: {
      default: []
    }
  },
  customerCode: undefined,
  isSubmitting: false,
  modalVisible: false,
  operator: null,
  roomBucket: undefined,
  venueCode: undefined,
  referrer: undefined,
  hasGuests: false,
  engineStore: {
    checkInDate: null,
    checkOutDate: null,
    children: [],
    packageGroupId: null,
    partyComp: [],
    rooms: []
  },
  visibleCheckOutCalendar: false,
  visibleCheckInCalendar: false,
  visiblePartyComp: false,
  visibleTicketCalendar: false,
  visibleHotelCalendar: false,

  errorCheckInDate: false,
  errorCheckOutDate: false,
  errorChildAges: false,
  errorRooms: false,
  errorPartyComp: false,

  messages: [],
  seenMessages: [],
  isValidForSubmission: false,
  defaults: {
    checkInDate: undefined,
    checkOutDate: undefined,
    ticketDate: undefined,
    ticketCode: undefined,
    ages: [],
    rooms: [],
    nights: undefined
  },
  withChildAges
}, action) => {
  switch (action.type) {
    case 'ENGINE_CHANGE_CHECKINDATE':
      return Object.assign({}, state, {
        errorCheckInDate: false,
        visibleCheckInCalendar: false,
        engineStore: Object.assign({}, state.engineStore, {
          ...action.engineStore,
          timeslot: false
        }),
        messages: filterSeenMessages(action.engineStore.messages, state.seenMessages)
      })

    case 'ENGINE_CHANGE_CHECKOUTDATE':
      return Object.assign({}, state, {
        errorCheckOutDate: false,
        visibleCheckOutCalendar: false,
        engineStore: Object.assign({}, state.engineStore, {
          ...action.engineStore
        }),
        messages: filterSeenMessages(action.engineStore.messages, state.seenMessages)
      })

    case 'ENGINE_CHANGE_ROOM':
      return Object.assign({}, state, {
        errorRooms: false,
        engineStore: Object.assign({}, state.engineStore, {
          ...action.newEngineStore
        }),
        messages: filterSeenMessages(action.newEngineStore.messages, state.seenMessages)
      })

    case 'ENGINE_CHANGE_PARTYCOMP':
      return Object.assign({}, state, {
        errorPartyComp: !!action.engineStore.invalidPartyMax,
        engineStore: Object.assign({}, {
          ...state.engineStore
        }, {
          ...action.engineStore
        }),
        messages: filterSeenMessages(action.engineStore.messages, state.seenMessages)
      })

    case 'ENGINE_CHANGE_TICKETDATE':
      return Object.assign({}, state, {
        engineStore: Object.assign({}, state.engineStore, {
          ticketDate: action.ticketDate,
          timeslot: false
        })
      })

    case 'ENGINE_UPDATE_CHILD':
      return Object.assign({}, state, {
        errorPartyComp: false,
        engineStore: Object.assign({}, state.engineStore, {
          ...action.engineStore
        }),
        messages: filterSeenMessages(action.engineStore.messages, state.seenMessages)
      })

    case 'ENGINE_REVERT_PARTYANDAGES':
      return Object.assign({}, state, {
        engineStore: Object.assign({}, state.engineStore, {
          ...action.engineStore
        })
      })

    case 'ENGINE_SUBMIT':
      return Object.assign({}, state, {
        isSubmitting: true,
        modalVisible: false
      })

    case 'ENGINE_MODAL_TOGGLE':
      return Object.assign({}, state, {
        modalVisible: action.forceVisible || !state.modalVisible
      })

    case 'ENGINE_TOGGLE_CHECKOUTCALENDAR':
      return Object.assign({}, state, {
        visibleCheckOutCalendar: action.forceVisible || !state.visibleCheckOutCalendar
      })

    case 'ENGINE_TOGGLE_CHECKINCALENDAR':
      return Object.assign({}, state, {
        visibleCheckInCalendar: action.forceVisible || !state.visibleCheckInCalendar
      })

    case 'ENGINE_TOGGLE_PARTYCOMP':
      return Object.assign({}, state, {
        visiblePartyComp: action.forceVisible || !state.visiblePartyComp
      })

    case 'ENGINE_TOGGLE_TICKETCALENDAR':
      return Object.assign({}, state, {
        visibleTicketCalendar: action.forceVisible || !state.visibleTicketCalendar
      })

    case 'ENGINE_TOGGLE_HOTELCALENDAR':
      return Object.assign({}, state, {
        visibleHotelCalendar: action.forceVisible || !state.visibleHotelCalendar
      })

    case 'ENGINE_ERROR_CHECKINDATE':
      return Object.assign({}, state, {
        errorCheckInDate: action.hasError
      })

    case 'ENGINE_ERROR_CHILDAGES':
      return Object.assign({}, state, {
        errorChildAges: action.hasError
      })

    case 'ENGINE_INITIALIZE': {
      return Object.assign({}, state, {
        engineStore: action.options,
        brandConfig: action.brandConfig,
        messages: [].concat(action.options.messages)
      })
    }

    case 'ENGINE_VALIDATION': {
      const {
        checkInDate,
        checkOutDate,
        children: engineStoreChildren,
        timeslot,
        remainingRoomPartyComp,
        invalidPartyMax,
        invalidPartyMultiple,
        invalidPartyInfants
      } = state.engineStore
      const {
        hasTimeslotSelectUI = false
      } = state.brandConfig
      const {
        adults,
        children
      } = remainingRoomPartyComp

      // Rooms
      let errorRooms = false
      if (action.fieldsToValidate.rooms && (adults > 0 || children > 0)) {
        errorRooms = 'Please allocate the remaining '
        if (adults > 0) {
          errorRooms += `${adults} ${languageHelpers.pluralise('Adult', adults)}`
          if (children > 0) {
            errorRooms += ' and '
          }
        }
        if (children > 0) {
          errorRooms += `${children} ${languageHelpers.pluralise('Child', children)}`
        }
        errorRooms += ' to a room'
      }

      const errorChildAges = (engineStoreChildren || []).some(child => !child.age)

      // Check in
      const errorCheckInDate = !action.fieldsToValidate.checkInDate ? false : !checkInDate

      // Check Out
      const errorCheckOutDate = !action.fieldsToValidate.checkOutDate ? false : !checkOutDate

      // Party Composition
      const errorPartyComp = !action.fieldsToValidate.partyComp ? false : !!(invalidPartyMax || invalidPartyMultiple || invalidPartyInfants || errorChildAges)

      // check timeslot only if we need it
      const errorTimeslot = hasTimeslotSelectUI ? !timeslot : false
      // Finally set a boolean to indicate we are good to submit.
      const isValidForSubmission = (
        !errorCheckInDate &&
        !errorCheckOutDate &&
        !errorPartyComp &&
        !errorRooms &&
        !errorTimeslot,
        !errorChildAges
      )

      return Object.assign({}, state, {
        errorCheckInDate,
        errorCheckOutDate,
        errorChildAges,
        errorPartyComp,
        errorTimeslot,
        errorRooms,
        isValidForSubmission
      })
    }

    case 'GET_LANDING_PAGE_DATA': {
      const brandConfig = _.get(window, 'brandConfig', {})
      const checkInDate = moment().add(1, 'day')
      const checkOutDate = moment(checkInDate).add(1, 'day')
      const packageGroupId = _.get(window, `searchFormServicePackaging.dates.byNumberNights[1].${checkInDate.format('YYYY-MM-DD')}`, '')
      const searchFormId = _.get(window, 'searchFormServicePackaging.searchFormId', '')
      const defaults = {
        checkInDate,
        checkOutDate,
        ticketDate: checkInDate,
        ticketCode: null,
        ages: [],
        guests: [],
        rooms: [],
        nights: 1,
        packageGroupId,
        searchFormId,
        timeslot: null
      }
      return Object.assign({}, state, {
        agent: brandConfig.agent,
        customerCode: _.get(window, 'customerCode', undefined),
        defaults,
        roomBucket: brandConfig.roomBucket,
        venueCode: brandConfig.parkCode
      })
    }

    case 'GET_AVAILABILITY_PAGE_DATA':
    case 'GET_SEATS_PAGE_DATA': {
      const {
        SeatType = '',
        agent,
        ages,
        guests = [],
        customerCode,
        roomRates = {},
        ticketRates = {},
        venueCode,
        operator,
        promotionCode,
        packageGroupId = null,
        referrer,
        searchFormId = null
      } = _.get(window, 'packageRatesReply.meta.context', {})
      const linkedTicketRates = Object.values(_.get(window, 'packageRatesReply.linked.ticketRates', {})) || []
      const firstTicketRate = _.head(linkedTicketRates) || {}
      const hasParkEntry = firstTicketRate.hasParkEntry

      const checkInDate = roomRates.checkinDate || undefined
      const checkOutDate = roomRates.checkoutDate || undefined

      const defaults = {
        checkInDate,
        checkOutDate,
        ticketDate: ticketRates.startDate || undefined,
        ticketCode: (ticketRates.bucket || '') + SeatType,
        ages,
        guests,
        rooms: roomRates.rooms || [],
        nights: datesDiffInDays(checkInDate, checkOutDate),
        packageGroupId,
        searchFormId,
        timeslot: ticketRates.timeslot || null
      }
      const hasGuests = (guests && guests.length > 0)
      return Object.assign({}, state, {
        action: _.get(window, 'brandConfig.secure.flow[0].endpoint', {}),
        agent,
        customerCode,
        hasParkEntry,
        roomBucket: roomRates.bucket || undefined,
        venueCode,
        operator,
        promotionCode,
        referrer,
        defaults,
        hasGuests
      })
    }

    case 'GET_MOREINFORMATION_PAGE_DATA':
    case 'GET_PAYMENT_PAGE_DATA':
    case 'GET_UPGRADES_PAGE_DATA': {
      const {
        agent,
        ages,
        guests = [],
        customerCode,
        hasParkEntry,
        hotel = {},
        roomRates = {},
        rooms = [],
        ticket = {},
        operator,
        promotionCode,
        packageGroupId,
        referrer,
        searchFormId = null
      } = harvestBasketData

      const checkInDate = roomRates.checkinDate || undefined
      const checkOutDate = roomRates.checkoutDate || undefined

      const defaults = Object.assign({}, {
        checkInDate,
        checkOutDate,
        ticketDate: ticket.startDate || undefined,
        ticketCode: ticket.bucket,
        ages,
        guests,
        rooms,
        nights: datesDiffInDays(checkInDate, checkOutDate),
        packageGroupId,
        searchFormId,
        timeslot: ticket.timeslot || undefined
      })
      const hasGuests = (guests && guests.length > 0)
      return Object.assign({}, state, {
        action: _.get(window, 'brandConfig.secure.flow[0].endpoint', {}),
        agent,
        hasGuests,
        customerCode,
        hasParkEntry,
        roomBucket: hotel.bucket || undefined,
        venueCode: _.get(window, 'packageRatesReply.meta.context.venueCode', _.get(window, 'venueProduct.id')),
        operator,
        promotionCode,
        referrer,
        defaults
      })
    }
    case 'ENGINE_CHANGE_TIMESLOT': {
      return Object.assign({}, state, {
        errorTimeslot: false,
        engineStore: Object.assign({}, state.engineStore, {
          ...state.engineStore,
          timeslot: action.timeslot
        })
      })
    }
    default:
      return state
  }
}

export {
  datesDiffInDays,
  filterSeenMessages
}

export default engine
