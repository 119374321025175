import React, { PureComponent } from 'react'

class CursedChildFooter extends PureComponent {
  render () {
    const assetsUrl = this.props.assetsUrl

    return (
      <div className='container py-4'>
        {assetsUrl &&
          <div className='row'>
            <div className='col-xs-8 col-xs-offset-2 col-sm-4 col-sm-offset-4 block-xs'>
              <p className='confidence-text-primary'>
                Palace Theatre
              </p>
              <hr className='hr' />
              <p className='confidence-text-secondary'>
                Shaftesbury Avenue, London W1D 5AY
              </p>
            </div>
            <div className='col-xs-12 m-3'>
              <img
                alt
                width='124px'
                height='90px'
                aria-hidden='true'
                role='presentation'
                src={`${assetsUrl}atgCursedChild/assets/Wizarding-World-Logo.png`} />
            </div>
            <div className='col-xs-12 m-3'>
              <img
                alt
                className='confidence-sas-logo'
                aria-hidden='true'
                role='presentation'
                src={`${assetsUrl}atgCursedChild/assets/sas-logo.png`} />
            </div>
          </div>
        }
      </div>
    )
  }
}

export default CursedChildFooter
