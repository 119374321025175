import { createSelector } from 'reselect'
import _ from 'lodash'
import config from '../configs/config'
import calculationHelpers from '../../src/helpers/calculationHelpers'
const {
  packageRatesReply
} = config
const getFilters = state => state.filters
const getHotelList = state => _.get(state, 'packageRates.packages', [])
const venueProducts = _.get(packageRatesReply, 'linked.venueProducts', null)

export default createSelector(
  [getFilters, getHotelList],
  (filters, hotelList) => {
    hotelList = hotelList.map(hotel => {
      return Object.assign({}, hotel, {
        grossPrice: hotel.price,
        grossPriceInPence: hotel.price * 100,
        milesToPark: hotel.milesToPark || calculationHelpers.getLatLongDistanceFromVenue(hotel, venueProducts) || 0
      })
    })

    // If the filters have been activated run through the following filters
    // Price and miles to park are range so can be run every time
    hotelList = hotelList.filter(hotel => hotel.price <= filters.price)
    hotelList = hotelList.filter(hotel => {
      return hotel.milesToPark <= filters.milesToPark
    })
    // These filters remain off unless activated
    if (!Object.keys(filters.propertyType).every(key => !filters.propertyType[key])) {
      const propertyFilters = Object.keys(filters.propertyType).filter((property, index) => {
        return filters.propertyType[property] && property
      })

      hotelList = hotelList.filter(hotel => {
        return propertyFilters.some(filter => {
          return hotel.accommodationType === filter
        })
      })
    }

    if (filters.starRating.length) {
      hotelList = hotelList.filter(hotel => {
        return filters.starRating.indexOf(hotel.starRating) !== -1
      })
    }

    if (!Object.keys(filters.popularOptions).every(key => !filters.popularOptions[key])) {
      const facilitiesFilters = Object.keys(filters.popularOptions).filter((facility, index) => {
        return filters.popularOptions[facility] && facility
      })

      hotelList = hotelList.filter(hotel => {
        return facilitiesFilters.every(filter => {
          return hotel.facilities[filter]
        })
      })
    }

    if (!Object.keys(filters.hotelFeatures).every(key => !filters.hotelFeatures[key])) {
      const featuresFilter = Object.keys(filters.hotelFeatures).filter((feature, index) => {
        return filters.hotelFeatures[feature] && feature
      })

      hotelList = hotelList.filter(hotel => {
        return featuresFilter.every(filter => {
          return hotel.facilities[filter]
        })
      })
    }

    return hotelList
  }
)
