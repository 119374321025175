import React, { Component } from 'react'
import { query } from '../atoms/BreakPoint'
import DisplayPrice from '../atoms/DisplayPrice'

class SeatListItem extends Component {
  constructor (props) {
    super(props)
    this.selectSeat = this.selectSeat.bind(this)
  }

  selectSeat (book = true) {
    this.props.selectSeat(this.props.seat, book)
  }

  render () {
    const isReadyToBook = (query.isAtMostSm() || this.props.isSelected || this.props.hasSeatMapImage)
    return (
      <div className='show-when-selection row pos-rel'>
        <div
          className='col-xs-8 col-md-7 col-lg-8'>
          <span>Seats: {this.props.seat.row}{this.props.seat.first}-{this.props.seat.last}</span>
          <div className='h2 m-0'>
            <DisplayPrice price={this.props.seat.grossPrice} operator='ceil' />
          </div>
          <p className='mb-0 text-muted'>Tickets, Hotel and Breakfast</p>
        </div>
        <div className='col-xs-4 col-md-5 col-lg-4 text-right'>
          <div className='chosen-seat'>
            <button
              aria-label={`Book row ${this.props.seat.row} seats ${this.props.seat.first}-${this.props.seat.last}`}
              onClick={() => this.selectSeat(isReadyToBook)}
              onKeyDown={(e) => e.key === 'Enter' && this.selectSeat(isReadyToBook)}
              className='btn btn-primary btn-block mb-2'
            >
              Book
            </button>
            <button
              aria-label='Highlight seats'
              onClick={() => this.selectSeat(false)}
              onKeyDown={(e) => e.key === 'Enter' && this.selectSeat(false)}
              className='hidden-xs hidden-sm btn btn-secondary btn-block btn-sm'>
              View
            </button>
          </div>
        </div>
      </div>
    )
  }
}

export default SeatListItem
