import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import ProgressTrackerStep from '../atoms/ProgressTrackerStep'

class ProgressTracker extends PureComponent {
  render () {
    return (
      <nav className='hidden-xs' role='navigation' aria-label={this.props.label}>
        <ol className='progress-tracker'>
          {this.props.children}
        </ol>
      </nav>
    )
  }
}

ProgressTracker.Step = ProgressTrackerStep

ProgressTracker.propTypes = {
  label: PropTypes.string.isRequired
}

ProgressTracker.defaultProps = {
  label: 'Your Progress'
}

export default ProgressTracker
