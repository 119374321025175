import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Col, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import DisplayPrice from './DisplayPrice'

import basketHelpers from '../../helpers/basketHelpers'

class BasketTotalWithSurcharge extends PureComponent {
  render () {
    if (!this.props.grossPrice) {
      return null
    }

    const grossPrice = this.props.grossPrice.toFixed(2)
    const standardPrice = this.props.standardPrice ? this.props.standardPrice.toFixed(2) : null
    const creditCardSurcharge = basketHelpers.getCreditCardSurcharge(this.props.creditCardSurcharge, this.props.grossPrice)
    const creditCardGrossPrice = (Number(this.props.grossPrice) + creditCardSurcharge).toFixed(2)

    return (
      <section className='summary-total'>
        <div className='clearfix'>
          {this.props.title &&
            <h3 className='pull-left'>{this.props.title}</h3>
          }
          {standardPrice &&
            <s className='d-block tiny-xs text-right small-sm text-muted text-capitalize'>{this.props.wasText} {this.props.fromText} <DisplayPrice price={standardPrice} /></s>
          }
        </div>
        <Row className='block-xs'>
          <Col {...this.props.colClasses.left}>
            <div className='form-total__title'>
              <FormattedMessage id='payment.debitCard' tagName='strong' />
            </div>
            <FormattedMessage id='payment.noAdditionalCharges' tagName='small' />
          </Col>
          <Col {...this.props.colClasses.right} className='form-total__price text-right'>
            <h3>
              <span className='amount'>
                <DisplayPrice price={grossPrice} />
              </span>
            </h3>
          </Col>
        </Row>

        <Row className='block-xs'>
          <Col {...this.props.colClasses.left}>
            <div className='form-total__title'>
              <FormattedMessage id='payment.creditCard' tagName='strong' />
            </div>
            {this.props.creditCardSurcharge.percentage &&
            <small>Includes {this.props.creditCardSurcharge.percentage}% surcharge</small>
            }
          </Col>
          <Col {...this.props.colClasses.right} className='form-total__price text-right'>
            <h3>
              <span className='amount'>
                <DisplayPrice price={creditCardGrossPrice} />
              </span>
            </h3>
          </Col>
        </Row>
      </section>
    )
  }
}

BasketTotalWithSurcharge.defaultProps = {
  colClasses: {
    left: {
      md: null,
      mdOffset: null,
      xs: 7
    },
    right: {
      md: null,
      xs: 5
    }
  },
  creditCardSurcharge: {
    max: '0',
    min: '0',
    percentage: '0'
  }
}

BasketTotalWithSurcharge.propTypes = {
  colClasses: PropTypes.shape({
    left: PropTypes.shape({
      md: PropTypes.number,
      mdOffset: PropTypes.number,
      xs: PropTypes.number
    }),
    right: PropTypes.shape({
      md: PropTypes.number,
      xs: PropTypes.number
    })
  }),
  creditCardSurcharge: PropTypes.shape({
    max: PropTypes.string.isRequired,
    min: PropTypes.string.isRequired,
    percentage: PropTypes.string.isRequired
  }).isRequired,
  grossPrice: PropTypes.number,
  standardPrice: PropTypes.number,
  title: PropTypes.string
}

export default BasketTotalWithSurcharge
