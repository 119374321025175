import _ from 'lodash'
import React, { useContext, useEffect, useState } from 'react'
import { Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock } from 'react-bootstrap'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import InputMask from 'react-input-mask'

import vouchers from '../../actions/vouchers'
import trackingHelpers from '../../helpers/trackingHelpers'

import BrandContext from '../../contexts/BrandContext'

import {
  getRemainingVoucherValue,
  getTotalAmountToPay,
  getTotalRedemptionValue
} from '../../selectors/payment'

const VOUCHER_PATTERN = 'XXX-XXXX-XXXX'

const VoucherFields = ({
  enableVoucherPayment,
  error = '',
  setFocusToField,
  inputEnabled,
  onAddVoucher,
  onRemoveVoucher,
  remainingVoucherValue,
  totalAmountToPay,
  totalOrderValue,
  totalRedemptionValue,
  vouchers = []
}) => {
  if (!enableVoucherPayment) return null
  const [ voucherInputFieldVisible, setVoucherInputFieldVisibility ] = useState(true)
  const [ voucherCode, setVoucherCode ] = useState('')
  const context = useContext(BrandContext)

  useEffect(() => {
    if (error) return
    setVoucherInputFieldVisibility(false)
    setVoucherCode('')
  }, [error, vouchers])

  return (
    <div
      className='col-sm-12'
      onClick={() => {
        if (!setFocusToField || inputEnabled) return null
        trackingHelpers.track('sb.track', 'Clicked on disabled voucher field', 'Vouchers', '')
        setFocusToField('email')
      }}>
      <FormGroup className='row mt-2 mb-0'>
        <Col xs={12} sm={10} smOffset={1} className='bg-muted block-sm'>
          {vouchers.length !== 0 &&
            <React.Fragment>
              <small className='d-block mb-3'>
                <FormattedMessage
                  id='payment.theseVouchersHaveBeenApproved'
                  values={{
                    count: vouchers.length
                  }}
                />
              </small>
              {vouchers.map(voucher =>
                <div key={`voucherCode-${voucher.voucherCode}`} className='alert alert-success alert-dismissible mb-3 voucher-code' role='alert'>
                  <button
                    aria-label='Close'
                    className='close'
                    onClick={() => onRemoveVoucher(voucher.voucherCode)}
                    onKeyDown={e => {
                      if (e.key !== 'Enter') return
                      onRemoveVoucher(voucherCode)
                    }}
                    type='button'>
                    <span aria-hidden='true'>×</span>
                  </button>
                  <FormattedHTMLMessage
                    id='payment.voucherHasBeenAppliedAmount'
                    tagName='p'
                    values={{
                      redemptionValue: (voucher.redemptionValue || 0).toFixed(2),
                      voucherCode: voucher.voucherCode
                    }}
                  />
                </div>
              )}

              <hr />
              {totalRedemptionValue < totalOrderValue
                ? <FormattedHTMLMessage
                  id='payment.voucherValueExplanationRemainingOrderValue'
                  tagName='small'
                  values={{
                    remainingValueToPay: (totalAmountToPay || 0).toFixed(2),
                    totalOrderValue: (totalOrderValue || 0).toFixed(2),
                    totalRedemptionValue: (totalRedemptionValue || 0).toFixed(2)
                  }}
                />
                : <FormattedHTMLMessage
                  id='payment.voucherValueExplanationRemainingVoucherValue'
                  tagName='small'
                  values={{
                    remainingVoucherValue: (remainingVoucherValue || 0).toFixed(2),
                    voucherCode: vouchers[vouchers.length - 1].voucherCode,
                    totalOrderValue: (totalOrderValue || 0).toFixed(2),
                    totalRedemptionValue: (totalRedemptionValue || 0).toFixed(2)
                  }}
                />
              }

              {totalAmountToPay > 0 &&
                <React.Fragment>
                  <hr />
                  <a
                    className='small mb-3 d-inline-block'
                    onClick={() => {
                      trackingHelpers.track('sb.track', 'Have Another Voucher?', 'Vouchers', '1')
                      setVoucherInputFieldVisibility(true)
                    }}
                    onKeyDown={(e) => {
                      if (e.key !== 'Enter') return
                      trackingHelpers.track('sb.track', 'Have Another Voucher?', 'Vouchers', '1')
                      setVoucherInputFieldVisibility(true)
                    }}
                    role='button'>
                    <FormattedMessage id='payment.haveAnotherVoucherCode' />
                  </a>
                </React.Fragment>
              }
            </React.Fragment>
          }

          {(vouchers.length === 0 || voucherInputFieldVisible) && totalAmountToPay > 0 &&
            <React.Fragment>
              <small className='mb-3 d-block'>
                {!inputEnabled &&
                  <FormattedMessage id='payment.pleaseFillEmailField' />
                }
                {inputEnabled && vouchers.length === 0 &&
                  <FormattedMessage id='payment.enterVoucherCode' />
                }
                {_.get(context.brandConfig, 'secure.hasFeatures.payment.hasVoucherLinkToMMB', false) && _.get(context.brandConfig, 'manageMyBooking.url') &&
                  <FormattedHTMLMessage
                    id='payment.likeForLikeVoucher'
                    tagName='p'
                    values={{
                      mmbLink: context.brandConfig.manageMyBooking.url
                    }}
                  />
                }
              </small>
              <FormGroup controlId='voucherCode' validationState={error ? 'error' : null}>
                <Col componentClass={ControlLabel} sm={3}>
                  <FormattedMessage id='common.voucherCode' />
                </Col>

                <Col sm={6} className={error ? 'has-error' : ''}>
                  <InputMask
                    beforeMaskedValueChange={newState => ({
                      selection: newState.selection,
                      value: newState.value && newState.value.toUpperCase()
                    })}
                    disabled={!inputEnabled}
                    maskChar={null}
                    mask={VOUCHER_PATTERN.replace(/X/g, '*')}
                    onChange={(e) => setVoucherCode(e.target.value)}
                    value={voucherCode}
                  >
                    {(inputProps) =>
                      <FormControl
                        autoComplete='off'
                        disabled={!inputEnabled}
                        placeholder={VOUCHER_PATTERN}
                        value={inputProps.value} />
                    }
                  </InputMask>
                  {error &&
                    <HelpBlock className='pull-right'>
                      {error}
                    </HelpBlock>
                  }
                </Col>
              </FormGroup>

              <FormGroup>
                <Col sm={6} md={6} smOffset={3}>
                  <Button
                    bsStyle='primary'
                    className='btn-accent'
                    disabled={!inputEnabled || !voucherCode || voucherCode.length !== VOUCHER_PATTERN.length}
                    onClick={() => {
                      onAddVoucher(voucherCode)
                    }}
                    onKeyDown={(e) => {
                      if (e.key !== 'Enter') return
                      onAddVoucher(voucherCode)
                    }}>
                    <FormattedMessage id='payment.applyVoucher' />
                  </Button>
                </Col>
              </FormGroup>
            </React.Fragment>
          }
        </Col>
      </FormGroup>
      <hr />
    </div>
  )
}

function mapStateToProps (state) {
  const {
    enableVoucherPayment,
    grossPrice,
    billingDetails
  } = state.payment
  const {
    error,
    vouchers
  } = state.vouchers
  return {
    enableVoucherPayment,
    error,
    inputEnabled: billingDetails.billToEmail,
    remainingVoucherValue: getRemainingVoucherValue(state),
    totalAmountToPay: getTotalAmountToPay(state),
    totalOrderValue: grossPrice,
    totalRedemptionValue: getTotalRedemptionValue(state),
    vouchers
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onAddVoucher: voucherCode => dispatch(vouchers.add(voucherCode)),
    onRemoveVoucher: voucherCode => dispatch(vouchers.remove(voucherCode))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(VoucherFields)
export {
  VoucherFields
}
