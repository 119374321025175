import _ from 'lodash'
import classNames from 'classnames'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import Stepper from '../../../components/atoms/EngineUI/Stepper'

import engineOld from '../../../actions/engineOld'

class Nights extends PureComponent {
  render () {
    if (!this.props.hasEngineStore) return null
    return (
      <div className={classNames({
        'searchform-nights': true,
        'has-error': this.props.error
      })}>
        <div className='form-group'>
          <label htmlFor='nightsInput'>{this.props.label}</label>
          <Stepper
            count={this.props.selected}
            id='nightsInput'
            max={this.props.max}
            min={this.props.min}
            onUpdate={this.props.change}
          />
          {this.props.error && <div className='help-block'>
            <FormattedMessage id='common.pleaseSelect' />
          </div>}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const {
    maxNights,
    minNights,
    selectedNights
  } = state.engineOld.engineStore

  const {
    errorNights
  } = state.engineOld

  return {
    max: maxNights,
    min: minNights,
    selected: selectedNights,
    error: errorNights,
    hasEngineStore: state.engineOld.hasEngineStore,
    label: _.get(state.engineOld.brandConfig, 'UITextDefaults.labelNights', 'Nights')
  }
}

function mapDispatchToProps (dispatch) {
  return {
    change: nights => {
      return dispatch(engineOld.changeNights(nights))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Nights)
