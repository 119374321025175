import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGStar extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}
      >
        <g>
          <path d='M12 17.27L18.18 21l-1.64-7.03L22 9.24l-7.19-.61L12 2 9.19 8.63 2 9.24l5.46 4.73L5.82 21z' fill='#FFD700' stroke='#000000' />
        </g>
      </SVG>
    )
  }
}

SVGStar.defaultProps = {
  fill: '#FFD700',
  height: '1em',
  width: '1em',
  viewBox: '2 2 20 20'
}

SVGStar.propTypes = SVG.propTypes

export default SVGStar
