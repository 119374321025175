import _ from 'lodash'
import React from 'react'
import { connect } from 'react-redux'
import PropTypes from 'prop-types'

import tracking from '../actions/tracking'
import config from '../configs/config'

// https://app.asana.com/0/970833023049500/1118933060927947/f - this component was added to get around nested elements not tracking correctly (highlighted in this video https://www.loom.com/share/886a1736b5d14b63a2293c366136d907) by programatically triggering tracking. Going forward, this component could be made more robust?
const TrackingLink = props => {
  const hasTrackingLinks = _.get(config, 'brandConfig.secure.hasFeatures.hasTrackingLinks', false)
  const eventAndTrackingHandler = e => {
    e.stopPropagation()
    props.trackValues(props.action, props.category, props.label)
    if (props.handler) props.handler()
  }

  if (hasTrackingLinks && !props.ghostPackage) {
    return (
      <div
        onClick={e => eventAndTrackingHandler(e)}
        onKeyDown={e => (e.key === 'Enter') && eventAndTrackingHandler(e)}
        role='button'
        tabIndex='0'
      >
        {props.children}
      </div>
    )
  } else {
    return props.children
  }
}

TrackingLink.propTypes = {
  action: PropTypes.string.isRequired,
  category: PropTypes.string.isRequired,
  handler: PropTypes.func,
  ghostPackage: PropTypes.bool,
  label: PropTypes.string.isRequired
}

TrackingLink.defaultProps = {
  action: '',
  category: '',
  ghostPackage: false,
  label: ''
}

function mapDispatchToProps (dispatch) {
  return {
    trackValues: (action, category, label) => {
      return dispatch(tracking.track(
        action,
        category,
        label
      ))
    }
  }
}

export default connect(null, mapDispatchToProps)(TrackingLink)
export {
  TrackingLink
}
