import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import LazyLoad from 'react-lazyload'

class ResponsiveImage extends PureComponent {
  constructor (props) {
    super(props)
    this.buildSrcSet = this.buildSrcSet.bind(this)
    this.buildSizeString = this.buildSizeString.bind(this)
  }

  buildSrcSet () {
    const containerSizes = this.props.containerSizes && this.props.containerSizes.length === 4 ? this.props.containerSizes : [900, 750, 600, 450]

    const retinas = containerSizes.map(size => {
      const doubleSize = size * 2
      return `${this.props.src}?w=${size}&dpr=2 ${doubleSize}w`
    })

    const standards = containerSizes.map(size => {
      return `${this.props.src}?w=${size} ${size}w`
    })

    return [retinas].concat(standards).join(',')
  }

  buildSizeString () {
    // These sizes relate to the bootstrap 3 responsive media query breakpoints. See https://scotch.io/quick-tips/default-sizes-for-twitter-bootstraps-media-queries
    if (this.props.containerSizes && this.props.containerSizes.length === 4) {
      return [
        `(max-width: 767px) ${this.props.containerSizes[0]}px`,
        `(max-width: 991px) ${this.props.containerSizes[1]}px`,
        `(max-width: 1199px) ${this.props.containerSizes[2]}px`,
        `(min-width: 1200px) ${this.props.containerSizes[3]}px`
      ].join(',')
    }
    return `100vw`
  }

  render () {
    if (!this.props.src) {
      return null
    }
    const { offset, height = 100 } = this.props
    const srcSet = this.buildSrcSet()
    const sizeString = this.buildSizeString()
    const imageTag =
      (<img
        alt={this.props.alt}
        className={`img-responsive ${this.props.className}`}
        src={`${this.props.src}?w=${this.props.defaultSize}`}
        srcSet={srcSet}
        sizes={sizeString}
        role='presentation'
      />)
    return (
      this.props.lazyload ? <LazyLoad once height={height} offset={[offset.top, offset.bottom]}>
        {imageTag}
      </LazyLoad> : imageTag
    )
  }
}

ResponsiveImage.defaultProps = {
  alt: '',
  className: '',
  defaultSize: 900,
  lazyload: true,
  offset: {
    bottom: 0,
    top: -200
  }
}

ResponsiveImage.propTypes = {
  alt: PropTypes.string,
  className: PropTypes.string,
  defaultSize: PropTypes.number,
  containerSizes: PropTypes.array,
  src: PropTypes.string.isRequired,
  offset: PropTypes.shape({
    bottom: PropTypes.number,
    top: PropTypes.number
  })
}

export default ResponsiveImage
