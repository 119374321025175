import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGSpyglass extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}
        viewBox='0 0 31.6 31.6'
      >
        <g>
          <g>
            <path d='M15.2,13c0.9,0,1.4,0.6,1.4,1.2s-0.4,1.2-1.4,1.2h-1.1c0,0.8-0.1,1.3-0.6,2h4.2c0.8,0,1.4,0.5,1.4,1.2
              c0,0.7-0.6,1.2-1.4,1.2h-5.9c-0.8,0-1.5-0.4-1.5-1.2c0-1.2,1.1-1,1.3-3.3h-1c-0.9,0-1.4-0.6-1.4-1.2S9.7,13,10.6,13h0.5
              c-0.2-0.4-0.4-0.9-0.4-1.4c0-2,1.7-3.3,4.2-3.3c3.1,0,4.1,1.9,4.1,2.9c0,0.6-0.6,1.2-1.2,1.2c-1.3,0-0.8-1.8-2.9-1.8
              c-0.8,0-1.5,0.4-1.5,1.3c0,0.4,0.2,0.8,0.3,1.1v0H15.2z' />
          </g>
          <path d='M14.6,28.8c-3.6,0-7.3-1.4-10-4.2C-1,19.1-1,10.1,4.6,4.6C10.1-1,19.1-1,24.7,4.6l0,0c5.5,5.5,5.5,14.6,0,20.1
            C21.9,27.4,18.3,28.8,14.6,28.8z M14.6,4.9c-2.5,0-5,0.9-6.9,2.8c-3.8,3.8-3.8,10,0,13.8s10,3.8,13.8,0s3.8-10,0-13.8l0,0
            C19.6,5.8,17.1,4.9,14.6,4.9z' />
          <path d='M29,31.2c-0.6,0-1.1-0.2-1.6-0.7l-4.9-4.9c-0.9-0.9-0.9-2.3,0-3.2c0.9-0.9,2.3-0.9,3.2,0l4.9,4.9c0.9,0.9,0.9,2.3,0,3.2
            C30.1,31,29.5,31.2,29,31.2z' />
        </g>
      </SVG>
    )
  }
}

SVGSpyglass.propTypes = SVG.propTypes

SVGSpyglass.defaultProps = {
  height: 22,
  title: 'Spyglass Icon',
  width: 22
}

export default SVGSpyglass
