import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

class ErrorHandler extends PureComponent {
  render () {
    return (
      <div className={`alert alert-${this.props.type}`} role='alert'>
        {this.props.titleFormattedMessage && this.props.titleFormattedMessage.id &&
          <FormattedMessage id={this.props.titleFormattedMessage.id} values={this.props.titleFormattedMessage.values} tagName='strong' />
        }
        {this.props.formattedMessage && this.props.formattedMessage.id &&
          <FormattedMessage id={this.props.formattedMessage.id} values={this.props.formattedMessage.values} />
        }
        {this.props.children}
      </div>
    )
  }
}

ErrorHandler.propTypes = {
  formattedMessage: PropTypes.shape({
    id: PropTypes.string.isRequired,
    values: PropTypes.array
  }).isRequired,
  title: PropTypes.string,
  type: PropTypes.string
}

ErrorHandler.defaultProps = {
  type: 'danger'
}

export default ErrorHandler
