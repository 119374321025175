import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGShortBreaks extends PureComponent {
  render () {
    return (
      <SVG {...this.props}>
        <g>
          <g>
            <path fill='#E71B8C' d='M79.5,20.5c0.1,3.3-2.5,6.1-5.8,6.2L33,28.5c-3.3,0.1-6.1-2.5-6.2-5.8L26.3,9.1c-0.2-3.3,2.4-6.1,5.7-6.2
            l40.7-1.7C76,1,78.8,3.7,79,6.9L79.5,20.5z' />
          </g>
          <g>
            <g>
              <path fill='#FFFFFF' d='M33.4,5.8c2.4-0.1,3.8,2,3.9,4.2s-1.1,4.4-3.6,4.5s-3.8-2-3.9-4.2S30.9,5.9,33.4,5.8z M33.7,13.6
              c1.9-0.1,2.5-1.9,2.5-3.5c-0.1-1.6-0.8-3.4-2.8-3.3c-1.9,0.1-2.5,1.9-2.5,3.5C31,11.9,31.8,13.6,33.7,13.6z' />
              <path fill='#FFFFFF' d='M40.2,13.6c0,0.3-0.2,0.6-0.5,0.6s-0.5-0.2-0.6-0.5l-0.3-7.5c0-0.3,0.1-0.6,0.5-0.6l3-0.1
              c0.3,0,0.5,0.1,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5l-2.5,0.1l0.1,2.8l2.2-0.1c0.3,0,0.5,0.1,0.5,0.5s-0.2,0.5-0.5,0.5l-2.1,0
              L40.2,13.6z' />
              <path fill='#FFFFFF' d='M45.7,13.4c0,0.3-0.2,0.6-0.5,0.6s-0.5-0.2-0.5-0.5l-0.3-7.5c0-0.3,0.1-0.6,0.5-0.6l3-0.1
              c0.3,0,0.5,0.1,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5l-2.5,0.1l0.1,2.8l2.2-0.1c0.3,0,0.5,0.1,0.5,0.5s-0.2,0.5-0.5,0.5l-2.2,0.1
              L45.7,13.4z' />
              <path fill='#FFFFFF' d='M51.1,13.2c0,0.3-0.2,0.6-0.5,0.6s-0.5-0.2-0.5-0.5l-0.3-7.6c0-0.3,0.2-0.6,0.5-0.6s0.5,0.2,0.6,0.5
              L51.1,13.2z' />
              <path fill='#FFFFFF' d='M56.5,12.6c1.3-0.1,1.8-0.8,2.3-0.9c0.3,0,0.4,0.2,0.4,0.5c0,0.5-1.2,1.2-2.7,1.3c-2.5,0.1-3.8-2-3.9-4.2
              s1.1-4.4,3.6-4.5c1.5-0.1,2.5,0.7,2.5,1.1c0,0.3-0.1,0.5-0.4,0.5c-0.5,0-0.8-0.8-2.1-0.8c-1.9,0.1-2.6,1.9-2.5,3.5
              C53.8,10.9,54.7,12.8,56.5,12.6z' />
              <path fill='#FFFFFF' d='M61.9,12.8c0,0.3-0.2,0.6-0.5,0.6s-0.5-0.2-0.5-0.5l-0.3-7.5c-0.1-0.4,0.1-0.6,0.4-0.6s0.5,0.2,0.6,0.5
              L61.9,12.8z' />
              <path fill='#FFFFFF' d='M64.3,12.9c-0.1,0.2-0.2,0.4-0.5,0.4c-0.3,0-0.5-0.1-0.5-0.4c0-0.1,0.1-0.4,0.1-0.5l2.4-7.2
              c0.1-0.4,0.3-0.7,0.7-0.7c0.4,0,0.6,0.3,0.8,0.6l2.9,7c0,0.1,0.2,0.4,0.2,0.5c0,0.3-0.2,0.5-0.5,0.5s-0.4-0.1-0.5-0.4l-0.6-1.5
              l-4,0.2L64.3,12.9z M66.6,5.7L66.6,5.7l-1.5,4.7l3.3-0.1L66.6,5.7z' />
              <path fill='#FFFFFF' d='M72.8,11.9l2.3-0.1c0.3,0,0.5,0.1,0.5,0.5c0,0.3-0.2,0.5-0.5,0.5l-2.7,0.1c-0.4,0-0.6-0.2-0.6-0.6l-0.3-7.5
              c0-0.3,0.2-0.6,0.5-0.6s0.5,0.2,0.6,0.5L72.8,11.9z' />
            </g>
          </g>
          <g>
            <g>
              <path d='M9.1,45.5c-0.5,0-0.9,0-1.4-0.1c-0.9-0.1-1.8-0.2-2.5-0.4c-0.8-0.2-1.5-0.3-2.1-0.5c-0.7-0.2-1.3-0.4-1.9-0.6l-0.2,0
              l0.7-7.7L2,36.2c0.5,0.2,1,0.4,1.6,0.5c0.5,0.2,1,0.3,1.6,0.4c0.6,0.1,1.2,0.2,1.8,0.3c0.1,0,0.2,0,0.3,0c1,0,1.1-0.5,1.2-0.9
              c0-0.2-0.1-0.5-0.4-0.8c-0.3-0.3-0.7-0.7-1.2-1.2c-0.5-0.4-1-0.9-1.5-1.5c-0.4-0.4-1-1-1.4-1.7c-0.5-0.7-0.8-1.5-1.1-2.3
              s-0.4-1.8-0.3-2.8C2.7,25,3,24,3.5,23.2c0.5-0.9,1.2-1.6,2-2.1s1.7-0.9,2.7-1.1c0.7-0.1,1.4-0.2,2.1-0.2c0.4,0,0.7,0,1.1,0.1
              c0.6,0.1,1.2,0.1,1.7,0.3l1.5,0.3c0.5,0.1,1.7,0.4,1.7,0.4l-0.7,7.2L15.3,28c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1
              c-0.2,0-0.5-0.1-0.7-0.1c-0.1,0-0.3,0-0.5,0h-0.1c-0.2,0-0.3,0-0.4,0c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0-0.1,0.1-0.2,0.3
              c0,0,0,0.2,0.4,0.6c0.3,0.3,0.7,0.7,1.1,1.1c0.4,0.4,0.9,0.9,1.4,1.5s1,1.2,1.4,1.9s0.8,1.5,1,2.4c0.3,0.9,0.3,1.8,0.2,2.9
              c-0.1,1.1-0.4,2.1-0.8,3s-1.1,1.7-1.9,2.4c-0.8,0.6-1.8,1.1-3,1.4C11,45.3,10.1,45.5,9.1,45.5z' />
              <path d='M10.4,20c0.4,0,0.7,0,1.1,0.1c0.6,0.1,1.1,0.1,1.7,0.3c0.6,0.1,1.1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.5,0.4l-0.6,6.6
              c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5-0.1-0.8-0.1c-0.1,0-0.3,0-0.5,0H13c-0.2,0-0.3,0-0.5,0
              s-0.4,0.1-0.5,0.2c-0.2,0.1-0.2,0.3-0.3,0.5c0,0.2,0.1,0.4,0.4,0.8c0.3,0.3,0.7,0.7,1.1,1.1c0.4,0.4,0.9,0.9,1.4,1.5
              s1,1.2,1.4,1.9s0.7,1.5,1,2.3c0.2,0.8,0.3,1.8,0.2,2.8c-0.1,1.1-0.4,2-0.8,2.9s-1,1.6-1.8,2.3c-0.8,0.6-1.7,1.1-2.9,1.4
              c-0.8,0.2-1.6,0.3-2.6,0.3c-0.5,0-0.9,0-1.4-0.1C6.8,45,6,45,5.2,44.8s-1.5-0.3-2-0.5c-0.7-0.2-1.3-0.4-1.9-0.6L2,36.5
              c0.5,0.3,1,0.4,1.6,0.6c0.5,0.2,1,0.3,1.6,0.4c0.6,0.2,1.2,0.3,1.8,0.3c0.1,0,0.2,0,0.4,0c0.9,0,1.3-0.4,1.4-1.1
              c0-0.3-0.1-0.6-0.4-1c-0.3-0.4-0.7-0.8-1.2-1.2s-1-0.9-1.5-1.5c-0.5-0.5-1-1.1-1.5-1.8S3.4,29.8,3.1,29c-0.3-0.8-0.3-1.7-0.3-2.7
              c0.1-1.1,0.4-2.1,0.9-2.9s1.1-1.5,1.9-2s1.7-0.9,2.7-1.1C9,20.1,9.6,20,10.4,20 M10.4,19.5c-0.7,0-1.5,0.1-2.2,0.2
              c-1.1,0.2-2,0.6-2.8,1.1s-1.5,1.3-2,2.2c-0.5,0.9-0.8,1.9-1,3.1c-0.1,1.1,0,2.1,0.3,2.9C3,30,3.4,30.7,3.8,31.4
              c0.5,0.7,1,1.3,1.6,1.9c0.5,0.5,1.1,1,1.5,1.5C7.4,35.2,7.7,35.6,8,36c0.1,0.2,0.3,0.4,0.3,0.6c0,0.3-0.1,0.6-0.9,0.6
              c-0.1,0-0.2,0-0.3,0c-0.6-0.1-1.2-0.1-1.8-0.3c-0.6-0.1-1.1-0.3-1.5-0.4c-0.6-0.2-1.1-0.3-1.6-0.5l-0.7-0.2l-0.1,0.7l-0.7,7.2
              v0.4L1,44.2c0.7,0.2,1.3,0.4,2,0.6C3.6,45,4.3,45,5.1,45.2s1.7,0.3,2.6,0.4c0.5,0,1,0.1,1.5,0.1c1,0,1.9-0.1,2.7-0.3
              c1.2-0.3,2.2-0.8,3.1-1.5c0.8-0.7,1.5-1.5,1.9-2.4s0.7-2,0.8-3.1c0.1-1.1,0-2.1-0.2-3c-0.3-0.9-0.6-1.7-1-2.4s-0.9-1.4-1.4-2
              c-0.5-0.6-1-1.1-1.4-1.5s-0.8-0.8-1.1-1.1c-0.3-0.3-0.3-0.4-0.3-0.4c0-0.1,0-0.1,0-0.1c0.1-0.1,0.2-0.1,0.3-0.1s0.3,0,0.4,0h0.1
              c0.2,0,0.4,0,0.5,0c0.2,0,0.5,0.1,0.7,0.1s0.4,0.1,0.6,0.1c0.2,0,0.5,0.1,0.6,0.1l0.6,0.1l0.1-0.6l0.6-6.7v-0.4l-0.4-0.1
              c-0.5-0.1-1-0.2-1.5-0.4l-1.5-0.3c-0.6-0.1-1.2-0.2-1.8-0.3C11.1,19.5,10.7,19.5,10.4,19.5L10.4,19.5z' />
            </g>
            <g>
              <polygon points='18.2,21 26.4,20.5 26.9,28.6 30.7,28.5 30.2,20.2 38.4,19.8 40,44.2 31.8,44.8 31.2,35.8 27.4,36.2 28,45
              19.8,45.5' />
              <path d='M38.2,20l1.5,24L32,44.5l-0.6-8.9L27.1,36l0.6,8.9L20,45.3l-1.5-24l7.7-0.5l0.5,8.2l4.3-0.3l-0.5-8.2L38.2,20 M38.6,19.5
              h-0.5L30.4,20h-0.5v0.5l0.5,7.7l-3.3,0.2l-0.4-7.6v-0.5h-0.5l-7.7,0.5H18v0.5l1.5,24v0.5H20l7.7-0.5h0.5v-0.5l-0.5-8.4l3.3-0.2
              l0.5,8.4V45H32l7.7-0.5h0.5V44l-1.5-24L38.6,19.5L38.6,19.5z' />
            </g>
            <g>
              <path d='M53.6,45.5c-1.8,0-3.4-0.3-5-1s-2.9-1.6-4.1-2.8c-1.2-1.2-2.1-2.5-2.8-4.1s-1-3.2-1-5s0.3-3.4,1-5s1.6-2.9,2.8-4.1
              s2.5-2.1,4.1-2.8c1.6-0.7,3.2-1,5-1s3.4,0.3,5,1s2.9,1.6,4.1,2.8c1.2,1.2,2.1,2.5,2.8,4.1c0.7,1.6,1,3.3,1,5s-0.3,3.4-1,5
              s-1.6,2.9-2.8,4.1c-1.2,1.2-2.5,2.1-4.1,2.8C57,45.2,55.3,45.5,53.6,45.5z M53.6,27.9c-0.7,0-1.3,0.1-1.9,0.4
              c-0.6,0.3-1.1,0.6-1.5,1s-0.8,0.9-1,1.5c-0.2,0.6-0.4,1.2-0.4,1.9s0.1,1.3,0.4,1.9c0.3,0.6,0.6,1.1,1,1.5s0.9,0.8,1.5,1
              s1.2,0.4,1.9,0.4s1.3-0.1,1.9-0.4c0.6-0.2,1.1-0.6,1.5-1s0.8-0.9,1-1.5s0.4-1.2,0.4-1.9s-0.1-1.3-0.4-1.9c-0.3-0.6-0.6-1.1-1-1.5
              s-0.9-0.8-1.5-1C54.9,28,54.2,27.9,53.6,27.9z' />
              <path d='M53.6,20c1.7,0,3.4,0.3,4.9,1s2.9,1.6,4,2.7s2,2.5,2.7,4s1,3.2,1,4.9s-0.3,3.4-1,4.9s-1.6,2.9-2.7,4
              c-1.1,1.1-2.5,2-4,2.7s-3.2,1-4.9,1s-3.4-0.3-4.9-1s-2.9-1.6-4-2.7c-1.1-1.1-2-2.5-2.7-4s-1-3.2-1-4.9s0.3-3.4,1-4.9
              s1.6-2.9,2.7-4c1.1-1.1,2.5-2,4-2.7C50.2,20.4,51.8,20,53.6,20 M53.6,37.8c0.7,0,1.4-0.1,2-0.4c0.6-0.3,1.1-0.6,1.6-1.1
              s0.8-1,1.1-1.6s0.4-1.3,0.4-2s-0.1-1.4-0.4-2c-0.3-0.6-0.6-1.1-1.1-1.6s-1-0.8-1.6-1.1c-0.6-0.3-1.3-0.4-2-0.4s-1.4,0.1-2,0.4
              s-1.1,0.6-1.6,1.1s-0.8,1-1.1,1.6c-0.3,0.6-0.4,1.3-0.4,2s0.1,1.4,0.4,2c0.3,0.6,0.6,1.1,1.1,1.6s1,0.8,1.6,1.1
              C52.2,37.5,52.9,37.8,53.6,37.8 M53.6,19.5c-1.8,0-3.5,0.3-5.1,1s-3,1.6-4.2,2.8c-1.2,1.2-2.1,2.6-2.8,4.2s-1,3.3-1,5.1
              s0.3,3.5,1,5.1s1.6,3,2.8,4.2s2.6,2.1,4.2,2.8c1.6,0.7,3.3,1,5.1,1s3.5-0.3,5.1-1s3-1.6,4.2-2.8s2.1-2.6,2.8-4.2
              c0.7-1.6,1-3.3,1-5.1s-0.3-3.5-1-5.1s-1.6-3-2.8-4.2c-1.2-1.2-2.6-2.1-4.2-2.8C57.1,19.9,55.4,19.5,53.6,19.5L53.6,19.5z
               M53.6,37.2c-0.6,0-1.2-0.1-1.8-0.4c-0.6-0.2-1-0.6-1.5-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.5-0.4-1.1-0.4-1.8
              c0-0.6,0.1-1.2,0.4-1.8c0.2-0.6,0.6-1,1-1.5c0.4-0.4,0.9-0.7,1.5-1c0.5-0.2,1.1-0.4,1.8-0.4c0.6,0,1.2,0.1,1.8,0.4
              c0.6,0.2,1,0.6,1.5,1c0.4,0.4,0.7,0.9,1,1.5c0.2,0.5,0.4,1.1,0.4,1.8c0,0.6-0.1,1.2-0.4,1.8c-0.2,0.6-0.6,1-1,1.5
              c-0.4,0.4-0.9,0.7-1.5,1C54.8,37.2,54.2,37.2,53.6,37.2L53.6,37.2z' />
            </g>
            <g>
              <path d='M78.2,45l-2.7-5.7L75,44.7L66.8,44L69,19.5l8.5,0.8c1.6,0.1,3,0.5,4.3,1s2.4,1.3,3.3,2.2c0.9,0.9,1.6,2,2,3.2
              s0.6,2.7,0.5,4.2c-0.1,1.2-0.4,2.3-0.8,3.1s-0.9,1.5-1.3,2c-0.5,0.5-1,1-1.6,1.4l3.9,8.5L78.2,45z M76.3,32c0.2,0,0.3,0,0.5,0
              l0,0c0.7,0,1.2-0.1,1.8-0.4c0.6-0.3,0.9-0.9,1-1.8s-0.1-1.5-0.7-1.9s-1.3-0.7-2.1-0.8h-0.1L76.3,32L76.3,32z' />
              <path d='M69.3,19.8l8.3,0.7c1.5,0.1,2.9,0.5,4.2,1c1.3,0.5,2.3,1.2,3.2,2.1c0.9,0.9,1.5,1.9,2,3.1c0.4,1.2,0.6,2.6,0.4,4.1
              c-0.1,1.2-0.4,2.2-0.8,3s-0.8,1.5-1.3,2c-0.5,0.6-1.1,1.1-1.8,1.4l3.8,8.3l-9-0.8l-3-6.4l-0.5,6.1l-7.6-0.6L69.3,19.8 M76.8,32.2
              c0.7,0,1.3-0.1,1.9-0.4c0.7-0.3,1.1-1,1.1-2c0.1-1-0.2-1.7-0.8-2.1c-0.6-0.5-1.4-0.7-2.3-0.8h-0.4l-0.5,5.3h0.4
              C76.5,32.2,76.7,32.2,76.8,32.2 M68.9,19.2v0.5l-2.2,24v0.5h0.5l7.7,0.7h0.5v-0.5l0.4-4.3l2.3,4.8l0.1,0.3h0.3l9,0.8l0.9,0.1
              L88,45.3l-3.6-7.9c0.5-0.4,1-0.8,1.5-1.3s1-1.3,1.4-2.1c0.4-0.9,0.7-1.9,0.8-3.2c0.1-1.6,0-3-0.5-4.3s-1.2-2.4-2.1-3.3
              s-2.1-1.7-3.4-2.2s-2.8-0.9-4.4-1l-8.3-0.7L68.9,19.2L68.9,19.2z M76.5,31.8l0.4-4.3c0.8,0.1,1.4,0.3,1.9,0.7
              c0.5,0.4,0.6,0.9,0.6,1.7c-0.1,0.8-0.4,1.3-0.9,1.6c-0.5,0.2-1,0.4-1.6,0.4C76.7,31.8,76.6,31.8,76.5,31.8L76.5,31.8z' />
            </g>
            <g>
              <polygon points='92.5,27.5 88.1,27.6 87.8,20.9 105.1,20 105.4,26.8 101,27 101.9,44.8 93.4,45.2' />
              <path d='M104.8,20.2l0.3,6.3l-4.4,0.2l0.9,17.7l-7.9,0.4l-0.9-17.7l-4.4,0.2L88,21L104.8,20.2 M105.3,19.6h-0.5L88,20.5h-0.5V21
              l0.3,6.3v0.5h0.5l3.9-0.2l0.9,17.2v0.5h0.5l7.9-0.4h0.5v-0.5l-0.9-17.2L105,27h0.5v-0.5l-0.3-6.3L105.3,19.6L105.3,19.6z' />
            </g>
            <g>
              <path d='M123.9,45c-0.2,0-0.4,0-0.6,0l-10.1-0.4l1-24.6l9.7,0.4c1.2,0,2.3,0.2,3.3,0.6c1,0.3,1.9,0.8,2.6,1.4s1.3,1.3,1.6,2.2
              c0.4,0.9,0.5,1.9,0.5,3c0,0.8-0.2,1.5-0.4,2s-0.5,1-0.7,1.4c-0.2,0.3-0.5,0.6-0.8,0.9c0.4,0.3,0.8,0.8,1.2,1.2
              c0.4,0.5,0.7,1.1,1,1.8s0.4,1.6,0.4,2.7c0,1.2-0.3,2.3-0.8,3.2c-0.5,1-1.1,1.8-1.9,2.4c-0.8,0.6-1.8,1.1-2.9,1.4
              C126,44.8,125,45,123.9,45z M122.2,38.8c1.5,0,2.3-0.6,2.3-1.7c0-0.6-0.1-1.1-0.5-1.4c-0.4-0.3-1-0.5-1.9-0.5h-0.6l-0.1,3.7
              C121.4,38.8,122.2,38.8,122.2,38.8z M122.5,29.4c1,0,1.4-0.4,1.5-1.3c0-1-0.4-1.4-1.5-1.5h-0.6l-0.1,2.8H122.5z' />
              <path d='M114.4,20.2l9.4,0.4c1.2,0,2.3,0.2,3.3,0.5s1.8,0.8,2.5,1.3c0.7,0.6,1.2,1.3,1.6,2.1s0.5,1.8,0.5,2.9
              c0,0.8-0.2,1.4-0.4,2c-0.2,0.5-0.4,1-0.7,1.3c-0.3,0.4-0.6,0.8-0.9,1c0.5,0.4,1,0.8,1.4,1.4c0.3,0.5,0.6,1.1,0.9,1.8
              c0.3,0.7,0.4,1.6,0.3,2.6c0,1.2-0.3,2.2-0.7,3.1c-0.4,0.9-1.1,1.7-1.8,2.3c-0.8,0.6-1.7,1.1-2.8,1.4c-0.9,0.3-2,0.4-3.1,0.4
              c-0.2,0-0.4,0-0.6,0l-9.8-0.4L114.4,20.2 M122.5,29.5c1.1,0,1.7-0.5,1.7-1.6s-0.5-1.7-1.7-1.7h-0.9l-0.1,3.3L122.5,29.5
              C122.4,29.5,122.4,29.5,122.5,29.5 M122.2,39c1.7,0,2.5-0.7,2.6-2c0.1-1.4-0.8-2.1-2.7-2.2h-0.9L121,39h0.9
              C122.1,39,122.2,39,122.2,39 M113.9,19.8v0.5l-1,24.1v0.5h0.5l9.8,0.4c0.2,0,0.4,0,0.6,0c1.2,0,2.2-0.1,3.2-0.4
              c1.2-0.3,2.2-0.8,3-1.5s1.5-1.5,2-2.5s0.7-2.1,0.8-3.3c0-1.1-0.1-2-0.4-2.8c-0.3-0.7-0.6-1.4-1-1.9c-0.3-0.4-0.7-0.8-1-1.1
              c0.2-0.2,0.4-0.5,0.6-0.7c0.3-0.4,0.5-0.9,0.7-1.4c0.2-0.6,0.4-1.3,0.4-2.1c0-1.2-0.1-2.2-0.5-3.1s-1-1.7-1.7-2.3
              c-0.7-0.6-1.6-1.1-2.7-1.4c-1-0.3-2.2-0.5-3.4-0.6l-9.4-0.4H113.9L113.9,19.8z M122,29l0.1-2.3h0.4c1.1,0,1.3,0.6,1.3,1.2
              c0,0.8-0.4,1.1-1.2,1.1h-0.1L122,29L122,29z M121.6,38.5l0.1-3.2h0.4c2,0.1,2.2,0.9,2.2,1.7c0,0.7-0.3,1.5-2.1,1.5
              c-0.1,0-0.2,0-0.2,0H121.6L121.6,38.5z' />
            </g>
            <g>
              <path d='M133.2,21l8.5-0.5c0.4,0,0.7,0,1.1,0c1.2,0,2.3,0.1,3.3,0.4c1.4,0.3,2.6,0.9,3.6,1.6s1.9,1.7,2.5,2.9
              c0.6,1.2,1,2.5,1.1,4.1c0.1,1.2,0,2.3-0.3,3.2s-0.6,1.6-1,2.2c-0.4,0.6-0.9,1.1-1.4,1.6l5.1,7.8l-9.6,0.6l-3.6-5.2l0.3,5.4
              l-8.2,0.5L133.2,21z M142.1,32.3h0.1c0.9-0.1,1.6-0.3,2.2-0.7c0.5-0.4,0.8-1,0.7-1.9s-0.4-1.5-1-1.8c-0.5-0.3-1.2-0.4-1.9-0.4
              c-0.1,0-0.2,0-0.4,0h-0.1L142.1,32.3z' />
              <path d='M142.8,20.8c1.1,0,2.2,0.1,3.2,0.4c1.3,0.3,2.5,0.9,3.5,1.6s1.8,1.7,2.4,2.8c0.6,1.1,1,2.5,1.1,4c0.1,1.2,0,2.2-0.3,3.1
              c-0.3,0.9-0.6,1.6-1,2.2c-0.4,0.7-0.9,1.2-1.5,1.7l5,7.6l-9,0.6l-4-5.9l0.4,6.1l-7.7,0.5l-1.5-24l8.3-0.5
              C142.1,20.8,142.4,20.8,142.8,20.8 M141.9,32.7h0.4c0.9-0.1,1.7-0.3,2.3-0.8c0.6-0.4,0.9-1.2,0.8-2.1c-0.1-1-0.4-1.6-1.1-2
              c-0.6-0.3-1.2-0.5-2-0.5c-0.1,0-0.2,0-0.4,0h-0.4L141.9,32.7 M142.8,20.2c-0.4,0-0.7,0-1.1,0l-8.3,0.5h-0.5v0.5l1.5,24v0.5h0.5
              l7.7-0.5h0.5v-0.5l-0.3-4.3l3,4.3L146,45h0.3l9-0.6l0.9-0.1l-0.5-0.7l-4.8-7.3c0.5-0.4,0.9-0.9,1.3-1.5c0.4-0.6,0.7-1.4,1-2.3
              c0.3-0.9,0.4-2,0.3-3.3c-0.1-1.6-0.5-3-1.1-4.2s-1.5-2.2-2.6-3s-2.3-1.3-3.7-1.7C145.1,20.4,143.9,20.2,142.8,20.2L142.8,20.2z
               M142,27.8c0.1,0,0.2,0,0.2,0c0.7,0,1.3,0.1,1.8,0.4s0.8,0.8,0.8,1.6c0.1,0.8-0.2,1.3-0.6,1.7c-0.5,0.4-1.2,0.6-1.9,0.7L142,27.8
              L142,27.8z' />
            </g>
            <g>
              <polygon points='155.2,44.5 156.7,20 171.4,20.9 171,27.6 164.5,27.2 164.4,29.5 170.7,29.8 170.3,36.2 164,35.8 163.8,38.2
              170.6,38.5 170.1,45.3' />
              <path d='M157,20.1l14.2,0.9l-0.4,6.3l-6.5-0.4l-0.2,2.7l6.4,0.4l-0.4,6l-6.4-0.4l-0.2,2.8l6.7,0.4l-0.4,6.3l-14.4-0.9L157,20.1
               M156.5,19.6v0.5l-1.5,24v0.5h0.5l14.4,0.9h0.5v-0.5l0.4-6.3v-0.5h-0.5l-6.2-0.4l0.1-1.8l5.9,0.4h0.5V36l0.4-6v-0.5h-0.5
              l-5.9-0.4l0.1-1.7l6,0.4h0.5v-0.5l0.4-6.3v-0.5h-0.5L157,19.6H156.5L156.5,19.6z' />
            </g>
            <g>
              <path d='M177.7,21.6l6.1-0.5l11.5,23.7l-8.1,0.7l-1-2.1l-6.9,0.6l-0.6,2.2l-8.1,0.7L177.7,21.6z M181,38l2.5-0.2l-1.5-3.2L181,38
              z' />
              <path d='M183.7,21.5l11.2,23.2l-7.6,0.7l-1-2.1l-7.3,0.6l-0.6,2.2l-7.6,0.7l7-24.8L183.7,21.5 M180.6,38.3l3.2-0.3l-2-4.1
              L180.6,38.3 M184,20.9h-0.3l-5.8,0.5h-0.3l-0.1,0.3l-7,24.8l-0.2,0.7L171,47l7.6-0.7h0.3L179,46l0.5-1.9l6.6-0.6l0.8,1.8l0.1,0.4
              h0.3l7.6-0.7l0.7-0.1l-0.3-0.7L184.1,21L184,20.9L184,20.9z M181.3,37.8l0.7-2.4l1.1,2.2L181.3,37.8L181.3,37.8z' />
            </g>
            <g>
              <polygon points='208,46 203.9,38.8 203.2,45.5 195,44.8 197.5,20.2 205.7,21.1 205.2,26.2 209.9,21.5 219.9,22.5 210.1,32.8
              217.8,47' />
              <path d='M197.7,20.5l7.8,0.8l-0.6,5.5l5.1-5.1l9.4,1l-9.6,10.1l7.6,13.9l-9.2-0.9l-4.3-7.9l-0.8,7.4l-7.8-0.8L197.7,20.5
               M197.2,20l-0.1,0.5l-2.4,24l-0.1,0.5l0.5,0.1l7.9,0.6l0.5,0.1l0.1-0.5l0.6-5.8l3.6,6.5l0.1,0.2h0.3l9.2,0.9l0.9,0.1l-0.5-0.8
              l-7.4-13.6l9.3-9.8l0.7-0.7l-1-0.1l-9.4-1h-0.2l-0.2,0.2l-4.1,4.1l0.4-4.1l0.1-0.5l-0.5-0.1l-7.8-0.8H197.2L197.2,20z' />
            </g>
            <g>
              <path d='M223.4,46.8c-0.4,0-0.7,0-1,0c-0.7,0-1.4-0.1-2-0.2h-0.2l-1.1-7.7l0.3,0.1c0.5,0.1,1.1,0.1,1.7,0.1
              c0.4,0,0.8,0.1,1.2,0.1c0.2,0,0.3,0,0.5,0c0.6,0,1.2-0.1,1.8-0.2c0.5-0.1,0.8-0.2,1-0.4s0.3-0.4,0.2-0.8c0-0.2-0.2-0.5-0.5-0.7
              c-0.4-0.3-0.8-0.6-1.4-0.9c-0.6-0.3-1.2-0.7-1.8-1.1c-0.7-0.4-1.3-0.9-1.9-1.4c-0.6-0.6-1.2-1.2-1.6-2c-0.5-0.7-0.8-1.7-0.9-2.7
              c-0.2-1.2-0.1-2.2,0.2-3.2c0.3-0.9,0.8-1.8,1.4-2.5c0.6-0.7,1.5-1.3,2.4-1.7c0.9-0.4,2-0.8,3.1-0.9c0.6-0.1,1.2-0.1,1.8-0.2
              l1.6-0.1c0.5,0,1.8,0,1.8,0l1.1,7.2c0,0-1.3,0-1.6,0.1c-0.2,0-0.5,0-0.7,0.1c-0.1,0-0.3,0.1-0.5,0.1s-0.4,0.1-0.6,0.2
              c-0.1,0.1-0.3,0.1-0.4,0.3c0,0.1-0.1,0.1-0.1,0.3c0,0,0.1,0.2,0.5,0.5c0.4,0.2,0.8,0.5,1.3,0.8s1.1,0.7,1.7,1.1
              c0.6,0.4,1.3,0.9,1.8,1.5c0.6,0.6,1.1,1.3,1.5,2.1c0.5,0.8,0.8,1.7,0.9,2.7c0.2,1.1,0.1,2.1-0.1,3.1c-0.2,1-0.6,1.9-1.2,2.7
              c-0.6,0.8-1.5,1.5-2.5,2.1c-1.1,0.6-2.4,1-3.9,1.2c-0.9,0.1-1.8,0.2-2.6,0.2C224.2,46.8,223.8,46.8,223.4,46.8z' />
              <path d='M229.6,20.9l1,6.7c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4,0-0.7,0c-0.2,0-0.5,0-0.8,0.1c-0.1,0-0.3,0.1-0.5,0.1s-0.4,0.1-0.6,0.2
              c-0.2,0.1-0.3,0.2-0.5,0.3s-0.2,0.3-0.1,0.5c0,0.2,0.2,0.4,0.6,0.6c0.4,0.2,0.8,0.5,1.3,0.8s1.1,0.7,1.7,1.1
              c0.6,0.4,1.2,0.9,1.8,1.5s1.1,1.2,1.5,2s0.7,1.6,0.9,2.6s0.1,2.1-0.1,3c-0.2,1-0.6,1.8-1.2,2.6c-0.6,0.8-1.4,1.5-2.5,2
              c-1,0.6-2.3,0.9-3.8,1.2c-0.9,0.1-1.8,0.2-2.5,0.2c-0.4,0-0.8,0-1.1,0s-0.7,0-1,0c-0.7,0-1.4-0.1-2-0.1l-1.1-7.1
              c0.5,0.1,1.1,0.1,1.7,0.2c0.4,0,0.7,0.1,1.2,0.1c0.2,0,0.3,0,0.5,0c0.6,0,1.2-0.1,1.9-0.2c1.1-0.2,1.6-0.6,1.5-1.5
              c0-0.3-0.3-0.6-0.6-0.9c-0.4-0.3-0.9-0.6-1.4-0.9c-0.6-0.3-1.2-0.7-1.8-1.1c-0.7-0.4-1.3-0.9-1.9-1.4c-0.6-0.5-1.1-1.2-1.6-1.9
              c-0.4-0.7-0.7-1.6-0.9-2.6c-0.2-1.1-0.1-2.1,0.2-3.1c0.3-0.9,0.7-1.7,1.4-2.4s1.4-1.2,2.3-1.7c0.9-0.4,1.9-0.7,3-0.9
              c0.6-0.1,1.1-0.1,1.7-0.2c0.6,0,1.1,0,1.6-0.1C228.6,20.9,229.1,20.9,229.6,20.9 M230,20.4h-0.4c-0.5,0-1,0-1.6,0l-1.6,0.1
              c-0.6,0-1.2,0.1-1.8,0.2c-1.1,0.2-2.2,0.5-3.2,0.9c-1,0.5-1.8,1.1-2.5,1.8c-0.7,0.7-1.2,1.6-1.5,2.6s-0.4,2.1-0.2,3.3
              c0.2,1.1,0.5,2,1,2.8s1,1.4,1.7,2c0.6,0.6,1.3,1.1,2,1.5s1.3,0.7,1.8,1.1c0.5,0.3,1,0.6,1.4,0.9c0.2,0.1,0.4,0.3,0.4,0.5
              c0,0.3,0,0.5-0.1,0.5c-0.2,0.2-0.5,0.3-0.9,0.3c-0.6,0.1-1.2,0.1-1.8,0.2c-0.2,0-0.3,0-0.5,0c-0.4,0-0.8,0-1.1,0
              c-0.6,0-1.2-0.1-1.7-0.1l-0.7-0.1l0.1,0.7l1.1,7.1l0.1,0.4l0.4,0.1c0.6,0.1,1.3,0.1,2,0.2c0.3,0,0.6,0,1,0s0.7,0,1.1,0
              c0.8,0,1.7-0.1,2.6-0.3c1.5-0.2,2.9-0.6,4-1.2c1.1-0.6,2-1.3,2.6-2.1s1.1-1.8,1.3-2.8s0.2-2.1,0.1-3.2c-0.2-1.1-0.5-2-0.9-2.8
              c-0.5-0.8-1-1.5-1.6-2.1s-1.2-1.1-1.9-1.6c-0.7-0.4-1.2-0.8-1.7-1.1c-0.5-0.3-0.9-0.6-1.3-0.8c-0.3-0.2-0.4-0.3-0.4-0.3
              c0-0.1,0-0.1,0-0.1c0.1-0.1,0.2-0.1,0.3-0.2c0.2-0.1,0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.5-0.1,0.7-0.1s0.4,0,0.6,0
              c0.2,0,0.5,0,0.7,0h0.6l-0.1-0.6l-1-6.7L230,20.4L230,20.4z' />
            </g>
          </g>
          <g>
            <g>
              <path fill='#FFFFFF' d='M10.7,44c-0.5,0-0.9,0-1.4-0.1c-0.9-0.1-1.8-0.2-2.5-0.4C6,43.2,5.3,43.2,4.7,43c-0.7-0.2-1.4-0.4-1.9-0.6
              l-0.2-0.1l0.7-7.7l0.3,0.2c0.5,0.2,1,0.4,1.6,0.5c0.5,0.2,1,0.3,1.6,0.4C7.4,35.8,8,35.9,8.6,36c0.1,0,0.2,0,0.3,0
              c1,0,1.1-0.5,1.2-0.9c0-0.2-0.1-0.5-0.4-0.8C9.4,34,9,33.5,8.5,33c-0.5-0.4-1-0.9-1.5-1.5c-0.6-0.5-1.1-1.2-1.5-1.8
              C5,29,4.7,28.2,4.4,27.5c-0.3-0.8-0.3-1.7-0.2-2.7c0.1-1.2,0.4-2.2,0.9-3c0.5-0.9,1.2-1.6,2-2.1s1.7-0.9,2.7-1.1
              c0.7-0.1,1.4-0.2,2.1-0.2c0.4,0,0.7,0,1.1,0.1c0.6,0.1,1.2,0.1,1.7,0.3l1.5,0.3c0.5,0.1,1.7,0.4,1.7,0.4l-0.7,7.2
              c0,0-0.7-0.1-0.9-0.2c-0.2,0-0.4-0.1-0.6-0.1c-0.2,0-0.5-0.1-0.7-0.1c-0.1,0-0.3,0-0.5,0h-0.1c-0.2,0-0.3,0-0.4,0
              c-0.2,0-0.3,0.1-0.4,0.2c-0.1,0-0.1,0.1-0.2,0.3c0,0,0,0.2,0.4,0.6c0.3,0.3,0.7,0.7,1.1,1.1c0.4,0.4,0.9,0.9,1.4,1.5
              s1,1.2,1.4,1.9s0.8,1.5,1,2.4c0.3,0.9,0.3,1.8,0.2,2.9c-0.1,1.1-0.4,2.1-0.8,3s-1.1,1.7-1.9,2.4c-0.8,0.6-1.8,1.1-3,1.4
              C12.5,43.8,11.6,44,10.7,44z' />
              <path d='M11.9,18.5c0.4,0,0.7,0,1.1,0.1c0.6,0.1,1.1,0.1,1.7,0.3c0.6,0.1,1.1,0.2,1.5,0.3c0.5,0.1,1,0.2,1.5,0.4l-0.6,6.7
              c-0.2,0-0.4-0.1-0.6-0.1s-0.4-0.1-0.6-0.1c-0.2,0-0.5-0.1-0.8-0.1c-0.1,0-0.3,0-0.5,0h-0.1c-0.2,0-0.3,0-0.5,0s-0.4,0.1-0.5,0.2
              c-0.2,0.1-0.2,0.3-0.3,0.5c0,0.2,0.1,0.4,0.4,0.8c0.3,0.3,0.7,0.7,1.1,1.1c0.4,0.4,0.9,0.9,1.4,1.5s1,1.2,1.4,1.9s0.7,1.5,1,2.3
              c0.2,0.8,0.3,1.8,0.2,2.8c-0.1,1.1-0.4,2-0.8,2.9s-1,1.6-1.8,2.3c-0.8,0.6-1.7,1.1-2.9,1.4C12.4,44,11.6,44,10.6,44
              c-0.5,0-0.9,0-1.4-0.1c-0.9-0.1-1.8-0.2-2.5-0.4c-0.8-0.2-1.5-0.3-2-0.5c-0.7-0.2-1.3-0.4-1.9-0.6l0.7-7.2
              c0.5,0.2,1.1,0.4,1.6,0.6c0.5,0.2,1,0.3,1.6,0.4c0.6,0.1,1.2,0.2,1.8,0.3c0.1,0,0.2,0,0.4,0c0.9,0,1.3-0.4,1.4-1.1
              c0-0.3-0.1-0.6-0.4-1c-0.3-0.4-0.7-0.8-1.2-1.2s-1-0.9-1.5-1.5c-0.5-0.5-1-1.1-1.5-1.8c-0.5-0.7-0.8-1.4-1.1-2.2
              c-0.2-1.1-0.3-2-0.2-3c0.1-1.1,0.4-2.1,0.9-2.9s1.1-1.5,1.9-2C8,19.2,8.8,19,9.8,18.8C10.5,18.5,11.2,18.5,11.9,18.5 M11.9,18
              c-0.7,0-1.5,0.1-2.2,0.2c-1.1,0.2-2,0.6-2.8,1.1s-1.5,1.3-2,2.2c-0.5,0.9-0.8,1.9-1,3.1c-0.1,1.1,0,2.1,0.3,2.9
              c0.3,0.9,0.7,1.6,1.1,2.3c0.5,0.7,1,1.3,1.6,1.9c0.5,0.5,1.1,1,1.5,1.5c0.5,0.4,0.8,0.8,1.1,1.2c0.1,0.2,0.3,0.4,0.3,0.6
              c0,0.3-0.1,0.6-0.9,0.6c-0.1,0-0.2,0-0.3,0c-0.6-0.1-1.2-0.1-1.8-0.3c-0.6-0.1-1.1-0.3-1.5-0.4c-0.6-0.2-1.1-0.3-1.6-0.5
              l-0.6-0.3L3,34.8L2.3,42v0.4l0.3,0.1c0.6,0.2,1.2,0.4,1.9,0.6c0.6,0.2,1.3,0.3,2.1,0.5s1.7,0.3,2.6,0.4c0.5,0,1,0.1,1.5,0.1
              c1,0,1.9-0.1,2.7-0.3c1.2-0.3,2.2-0.8,3.1-1.5c0.8-0.7,1.5-1.5,1.9-2.4s0.7-2,0.8-3.1c0.1-1.1,0-2.1-0.2-3
              c-0.3-0.9-0.6-1.7-1-2.4s-0.9-1.4-1.4-2s-1-1.1-1.4-1.5s-0.8-0.8-1.1-1.1c-0.3-0.3-0.3-0.4-0.3-0.4c0-0.1,0-0.1,0-0.1
              c0.1-0.1,0.2-0.1,0.3-0.1s0.3,0,0.4,0h0.1c0.2,0,0.4,0,0.5,0c0.2,0,0.5,0.1,0.7,0.1s0.4,0.1,0.6,0.1c0.2,0,0.5,0.1,0.6,0.1
              l0.6,0.1l0.1-0.6l0.6-6.7v-0.4l-0.4-0.1c-0.5-0.1-1-0.2-1.5-0.4l-1.5-0.3c-0.6-0.1-1.2-0.2-1.8-0.3C12.7,18,12.3,18,11.9,18
              L11.9,18z' />
            </g>
            <g>
              <polygon fill='#FFFFFF' points='19.8,19.5 28,19 28.5,27.1 32.3,26.9 31.7,18.8 40,18.1 41.5,42.8 33.3,43.2 32.8,34.3 29,34.5
              29.5,43.5 21.4,44' />
              <path d='M39.7,18.5l1.5,24L33.5,43L33,34l-4.3,0.3l0.6,8.9l-7.7,0.5l-1.5-24l7.7-0.5l0.5,8.2l4.3-0.3L32,19L39.7,18.5 M40.2,18
              h-0.5L32,18.5h-0.5V19l0.5,7.7l-3.3,0.2l-0.5-7.7v-0.5h-0.5L20,19.2h-0.5v0.5l1.5,24v0.5h0.5l7.7-0.5h0.5v-0.5l-0.5-8.4l3.3-0.2
              L33,43v0.5h0.5l7.7-0.5h0.5v-0.5l-1.5-24V18L40.2,18z' />
            </g>
            <g>
              <path fill='#FFFFFF' d='M55.1,44c-1.8,0-3.4-0.3-5-1s-2.9-1.6-4.1-2.8c-1.2-1.2-2.1-2.5-2.8-4.1s-1-3.2-1-5s0.3-3.4,1-5
              s1.6-2.9,2.8-4.1s2.5-2.1,4.1-2.8c1.6-0.7,3.2-1,5-1s3.4,0.3,5,1s2.9,1.6,4.1,2.8c1.2,1.2,2.1,2.5,2.8,4.1c0.7,1.6,1,3.2,1,5
              s-0.3,3.4-1,5s-1.6,2.9-2.8,4.1c-1.2,1.2-2.5,2.1-4.1,2.8C58.6,43.7,56.9,44,55.1,44z M55.1,26.4c-0.7,0-1.3,0.1-1.9,0.4
              c-0.6,0.3-1.1,0.6-1.5,1s-0.8,0.9-1,1.5s-0.4,1.2-0.4,1.9s0.1,1.3,0.4,1.9c0.3,0.6,0.6,1.1,1,1.5s0.9,0.8,1.5,1s1.2,0.4,1.9,0.4
              s1.3-0.1,1.9-0.4c0.6-0.2,1.1-0.6,1.5-1s0.8-0.9,1-1.5s0.4-1.2,0.4-1.9s-0.1-1.3-0.4-1.9c-0.3-0.6-0.6-1.1-1-1.5s-0.9-0.8-1.5-1
              C56.4,26.5,55.8,26.4,55.1,26.4z' />
              <path d='M55.1,18.5c1.7,0,3.4,0.3,4.9,1s2.9,1.6,4,2.7s2,2.5,2.7,4s1,3.2,1,4.9s-0.3,3.4-1,4.9s-1.6,2.9-2.7,4
              c-1.1,1.1-2.5,2-4,2.7s-3.2,1-4.9,1s-3.4-0.3-4.9-1s-2.9-1.6-4-2.7c-1.1-1.1-2-2.5-2.7-4s-1-3.2-1-4.9s0.3-3.4,1-4.9
              s1.6-2.9,2.7-4c1.1-1.1,2.5-2,4-2.7C51.8,18.9,53.4,18.5,55.1,18.5 M55.1,36.2c0.7,0,1.4-0.1,2-0.4c0.6-0.3,1.1-0.6,1.6-1.1
              s0.8-1,1.1-1.6s0.4-1.3,0.4-2s-0.1-1.4-0.4-2c-0.3-0.6-0.6-1.1-1.1-1.6s-1-0.8-1.6-1.1c-0.6-0.3-1.3-0.4-2-0.4s-1.4,0.1-2,0.4
              S52,27,51.5,27.5s-0.8,1-1.1,1.6s-0.4,1.3-0.4,2s0.1,1.4,0.4,2c0.3,0.6,0.6,1.1,1.1,1.6s1,0.8,1.6,1.1
              C53.8,36,54.4,36.2,55.1,36.2 M55.1,18c-1.8,0-3.5,0.3-5.1,1s-3,1.6-4.2,2.8C44.6,23,43.7,24.4,43,26s-1,3.3-1,5.1s0.3,3.5,1,5.1
              s1.6,3,2.8,4.2s2.6,2.1,4.2,2.8c1.6,0.7,3.3,1,5.1,1s3.5-0.3,5.1-1s3-1.6,4.2-2.8s2.1-2.6,2.8-4.2c0.7-1.6,1-3.3,1-5.1
              s-0.3-3.5-1-5.1s-1.6-3-2.8-4.2c-1.2-1.2-2.6-2.1-4.2-2.8C58.6,18.4,56.9,18,55.1,18L55.1,18z M55.1,35.7c-0.6,0-1.2-0.1-1.8-0.4
              c-0.6-0.2-1-0.6-1.5-1c-0.4-0.4-0.7-0.9-1-1.5c-0.2-0.5-0.4-1.1-0.4-1.8c0-0.6,0.1-1.2,0.4-1.8c0.2-0.6,0.6-1,1-1.5
              c0.4-0.4,0.9-0.7,1.5-1c0.5-0.2,1.1-0.4,1.8-0.4c0.6,0,1.2,0.1,1.8,0.4c0.6,0.2,1,0.6,1.5,1c0.4,0.4,0.7,0.9,1,1.5
              c0.2,0.5,0.4,1.1,0.4,1.8c0,0.6-0.1,1.2-0.4,1.8c-0.2,0.6-0.6,1-1,1.5c-0.4,0.4-0.9,0.7-1.5,1C56.4,35.5,55.8,35.7,55.1,35.7
              L55.1,35.7z' />
            </g>
            <g>
              <path fill='#FFFFFF' d='M79.8,43.5l-2.7-5.7l-0.5,5.4l-8.2-0.7L70.6,18l8.5,0.8c1.6,0.1,3,0.5,4.3,1s2.4,1.3,3.3,2.2
              c0.9,0.9,1.6,2,2,3.2s0.6,2.7,0.5,4.2c-0.1,1.2-0.4,2.3-0.8,3.1s-0.9,1.5-1.3,2c-0.5,0.5-1,1-1.6,1.4l3.9,8.5L79.8,43.5z
               M77.9,30.5c0.2,0,0.3,0,0.5,0l0,0c0.7,0,1.2-0.1,1.8-0.4c0.6-0.3,0.9-0.9,1-1.8s-0.1-1.5-0.7-1.9s-1.3-0.7-2.1-0.8h-0.1
              L77.9,30.5L77.9,30.5z' />
              <path d='M70.9,18.1l8.3,0.7c1.5,0.1,2.9,0.5,4.2,1c1.3,0.5,2.3,1.2,3.2,2.1c0.9,0.9,1.5,1.9,2,3.1c0.4,1.2,0.6,2.6,0.4,4.1
              c-0.1,1.2-0.4,2.2-0.8,3s-0.8,1.5-1.3,2c-0.5,0.6-1.1,1.1-1.8,1.4l3.8,8.3l-9-0.8l-3-6.4l-0.5,6.1L68.7,42L70.9,18.1 M78.4,30.8
              c0.7,0,1.3-0.1,1.9-0.4c0.7-0.3,1.1-1,1.1-2c0.1-1-0.2-1.7-0.8-2.1c-0.6-0.5-1.4-0.7-2.3-0.8h-0.4l-0.5,5.3h0.4
              C78,30.8,78.2,30.8,78.4,30.8 M70.4,17.6v0.5l-2.2,24v0.5h0.5l7.7,0.7h0.5v-0.5l0.4-4.3l2.3,4.8l0.1,0.3H80l9,0.8l0.9,0.1
              l-0.5-0.8l-3.6-7.9c0.5-0.4,1-0.8,1.5-1.3s1-1.3,1.4-2.1c0.4-0.9,0.7-1.9,0.8-3.2c0.1-1.6,0-3-0.5-4.3s-1.2-2.4-2.1-3.3
              s-2.1-1.7-3.4-2.2s-2.8-0.9-4.4-1l-8.3-0.7L70.4,17.6L70.4,17.6z M78,30.2l0.4-4.3c0.8,0.1,1.4,0.3,1.9,0.7
              c0.5,0.4,0.6,0.9,0.6,1.7c-0.1,0.8-0.4,1.3-0.9,1.6c-0.5,0.2-1,0.4-1.6,0.4C78.3,30.2,78.1,30.2,78,30.2L78,30.2z' />
            </g>
            <g>
              <polygon fill='#FFFFFF' points='94.1,25.9 89.7,26 89.3,19.2 106.6,18.4 107,25.1 102.5,25.5 103.4,43.2 95,43.5' />
              <path d='M106.4,18.6l0.3,6.3l-4.4,0.2l0.9,17.7l-7.9,0.4l-0.9-17.7L90,25.8l-0.3-6.3L106.4,18.6 M106.9,18.1h-0.5l-16.8,0.8h-0.5
              v0.5l0.3,6.3v0.5h0.5l3.9-0.2l0.9,17.2v0.5h0.5l7.9-0.4h0.5v-0.5l-0.9-17.2l3.9-0.2h0.5v-0.5l-0.3-6.3L106.9,18.1L106.9,18.1z' />
            </g>
            <g>
              <path fill='#FFFFFF' d='M125.4,43.5c-0.2,0-0.4,0-0.6,0L114.7,43l1-24.6l9.7,0.4c1.2,0,2.3,0.2,3.3,0.6c1,0.3,1.9,0.8,2.6,1.4
              c0.7,0.6,1.3,1.3,1.6,2.2c0.4,0.9,0.5,1.9,0.5,3c0,0.8-0.2,1.5-0.4,2s-0.5,1-0.7,1.4c-0.2,0.3-0.5,0.6-0.8,0.9
              c0.4,0.3,0.8,0.7,1.2,1.2s0.7,1.1,1,1.8s0.4,1.6,0.4,2.7c0,1.2-0.3,2.3-0.8,3.2c-0.5,1-1.1,1.8-1.9,2.4c-0.8,0.6-1.8,1.1-2.9,1.4
              C127.6,43.2,126.5,43.5,125.4,43.5z M123.8,37.2c1.5,0,2.3-0.6,2.3-1.7c0-0.6-0.1-1.1-0.5-1.4c-0.4-0.3-1-0.5-1.9-0.5h-0.6
              l-0.1,3.7C122.9,37.2,123.7,37.2,123.8,37.2z M124.1,27.8c1,0,1.4-0.4,1.5-1.3c0-1-0.4-1.4-1.5-1.5h-0.6l-0.1,2.8H124.1z' />
              <path d='M116,18.6l9.4,0.4c1.2,0,2.3,0.2,3.3,0.5c1,0.3,1.8,0.8,2.5,1.3c0.7,0.6,1.2,1.3,1.6,2.1c0.4,0.8,0.5,1.8,0.5,2.9
              c0,0.8-0.2,1.4-0.4,2c-0.2,0.5-0.4,1-0.7,1.3c-0.3,0.4-0.6,0.8-0.9,1c0.5,0.4,1,0.8,1.4,1.4c0.3,0.5,0.6,1.1,0.9,1.8
              c0.3,0.7,0.4,1.6,0.3,2.6c0,1.2-0.3,2.2-0.7,3.1c-0.4,0.9-1.1,1.7-1.8,2.3c-0.8,0.6-1.7,1.1-2.8,1.4c-0.9,0.3-2,0.4-3.1,0.4
              c-0.2,0-0.4,0-0.6,0l-9.9-0.4L116,18.6 M124.1,28c1.1,0,1.7-0.5,1.7-1.6s-0.5-1.7-1.7-1.7h-0.9l-0.1,3.3L124.1,28
              C124,28,124,28,124.1,28 M123.8,37.5c1.7,0,2.5-0.7,2.6-2c0.1-1.4-0.8-2.1-2.7-2.2h-0.9l-0.2,4.2h0.9
              C123.6,37.5,123.7,37.5,123.8,37.5 M115.5,18.1v0.5l-1,24.1v0.5h0.5l9.8,0.4c0.2,0,0.4,0,0.6,0c1.2,0,2.2-0.1,3.2-0.4
              c1.2-0.3,2.2-0.8,3-1.5s1.5-1.5,2-2.5s0.7-2.1,0.8-3.3c0-1.1-0.1-2-0.4-2.8c-0.3-0.7-0.6-1.4-1-1.9c-0.3-0.4-0.7-0.8-1-1.1
              c0.2-0.2,0.4-0.5,0.6-0.7c0.3-0.4,0.5-0.9,0.7-1.4c0.2-0.6,0.4-1.3,0.4-2.1c0-1.2-0.1-2.2-0.5-3.1s-1-1.7-1.7-2.3
              c-0.7-0.6-1.6-1.1-2.7-1.4c-1-0.3-2.2-0.5-3.4-0.6l-9.4-0.4H115.5L115.5,18.1z M123.6,27.5l0.1-2.3h0.4c1.1,0,1.3,0.6,1.3,1.2
              c0,0.8-0.4,1.1-1.2,1.1h-0.1L123.6,27.5L123.6,27.5z M123.2,37l0.1-3.2h0.4c2,0.1,2.2,0.9,2.2,1.7c0,0.7-0.3,1.5-2.1,1.5
              c-0.1,0-0.2,0-0.2,0H123.2L123.2,37z' />
            </g>
            <g>
              <path fill='#FFFFFF' d='M134.7,19.5l8.5-0.5c0.4,0,0.7,0,1.1,0c1.2,0,2.3,0.1,3.3,0.4c1.4,0.3,2.6,0.9,3.6,1.6s1.9,1.7,2.5,2.9
              c0.6,1.2,1,2.5,1.1,4.1c0.1,1.2,0,2.3-0.3,3.2s-0.6,1.6-1,2.2c-0.4,0.6-0.9,1.1-1.4,1.6l5.1,7.8l-9.6,0.6l-3.6-5.2l0.3,5.4
              l-8.2,0.5L134.7,19.5z M143.6,30.8h0.1c0.9-0.1,1.6-0.3,2.2-0.7c0.5-0.4,0.8-1,0.7-1.9s-0.4-1.5-1-1.8c-0.5-0.3-1.2-0.4-1.9-0.4
              c-0.1,0-0.2,0-0.4,0h-0.1L143.6,30.8z' />
              <path d='M144.3,19.1c1.1,0,2.2,0.1,3.2,0.4c1.3,0.3,2.5,0.9,3.5,1.6s1.8,1.7,2.4,2.8c0.6,1.1,1,2.5,1.1,4c0.1,1.2,0,2.2-0.3,3.1
              c-0.3,0.9-0.6,1.6-1,2.2c-0.4,0.7-0.9,1.2-1.5,1.7l5,7.6l-9,0.6l-4-5.9l0.4,6.1l-7.7,0.5L135,19.8l8.3-0.5
              C143.6,19.1,144,19.1,144.3,19.1 M143.4,31h0.4c0.9-0.1,1.7-0.3,2.3-0.8c0.6-0.4,0.9-1.2,0.8-2.1c-0.1-1-0.4-1.6-1.1-2
              c-0.6-0.3-1.2-0.5-2-0.5c-0.1,0-0.2,0-0.4,0H143L143.4,31 M144.3,18.6c-0.4,0-0.7,0-1.1,0l-8.3,0.5h-0.5v0.5l1.5,24v0.5h0.5
              l7.7-0.5h0.5v-0.5l-0.3-4.3l3,4.3l0.2,0.2h0.3l9-0.6l0.9-0.1l-0.5-0.7l-4.8-7.3c0.5-0.4,0.9-0.9,1.3-1.5c0.4-0.6,0.7-1.4,1-2.3
              c0.3-0.9,0.4-2,0.3-3.3c-0.1-1.6-0.5-3-1.1-4.2s-1.5-2.2-2.6-3s-2.3-1.3-3.7-1.7C146.6,18.8,145.5,18.6,144.3,18.6L144.3,18.6z
               M143.6,26.1c0.1,0,0.2,0,0.2,0c0.7,0,1.3,0.1,1.8,0.4s0.8,0.8,0.8,1.6c0.1,0.8-0.2,1.3-0.6,1.7c-0.5,0.4-1.2,0.6-1.9,0.7
              L143.6,26.1L143.6,26.1z' />
            </g>
            <g>
              <polygon fill='#FFFFFF' points='156.8,43 158.3,18.4 172.9,19.2 172.5,26 166,25.6 165.9,27.9 172.3,28.2 171.9,34.8 165.5,34.3
              165.4,36.7 172.1,37 171.7,43.8' />
              <path d='M158.5,18.6l14.2,0.9l-0.4,6.3l-6.5-0.4l-0.2,2.7l6.4,0.4l-0.4,6l-6.4-0.4l-0.2,2.8l6.7,0.4l-0.4,6.3l-14.4-0.9
              L158.5,18.6 M158,18.1v0.5l-1.5,24v0.5h0.5l14.4,0.9h0.5v-0.5l0.4-6.3v-0.5h-0.5l-6.2-0.4l0.1-1.8l5.9,0.4h0.5v-0.5l0.4-6V28H172
              l-5.9-0.4l0.1-1.7l6,0.4h0.5v-0.5l0.4-6.3V19h-0.5l-14.2-0.9L158,18.1L158,18.1z' />
            </g>
            <g>
              <path fill='#FFFFFF' d='M179.3,20.1l6.1-0.5l11.5,23.7l-8.1,0.7l-1-2.1l-6.9,0.6l-0.6,2.2l-8.1,0.7L179.3,20.1z M182.5,36.5l2.5-0.2
              l-1.5-3.2L182.5,36.5z' />
              <path d='M185.2,19.9l11.2,23.2l-7.6,0.7l-1-2.1l-7.3,0.6l-0.6,2.2l-7.6,0.7l7-24.8L185.2,19.9 M182.2,36.8l3.2-0.3l-2-4.1
              L182.2,36.8 M185.5,19.4h-0.3l-5.8,0.5h-0.3l-0.1,0.3l-7,24.8l-0.2,0.7l0.7-0.1l7.6-0.7h0.3l0.1-0.3l0.5-1.8l6.6-0.6l0.8,1.8
              l0.1,0.3h0.3l7.6-0.7l0.7-0.1l-0.3-0.7l-11.2-23.2L185.5,19.4L185.5,19.4z M182.9,36.2l0.7-2.4l1.1,2.2L182.9,36.2L182.9,36.2z'
              />
            </g>
            <g>
              <polygon fill='#FFFFFF' points='209.5,44.5 205.5,37.2 204.8,44 196.5,43.2 199,18.8 207.3,19.5 206.8,24.6 211.4,20 221.4,21
              211.7,31.3 219.3,45.5' />
              <path d='M199.2,19l7.8,0.7l-0.6,5.5l5.1-5.1l9.4,1l-9.6,10.1l7.6,13.9l-9.2-0.9l-4.3-7.9l-0.8,7.4l-7.8-0.8L199.2,19 M198.8,18.5
              l-0.1,0.5l-2.4,24l-0.1,0.5l0.5,0.1l7.8,0.8l0.5,0.1l0.1-0.5l0.6-5.8l3.6,6.5l0.1,0.2h0.3l9.2,0.9l0.9,0.1l-0.5-0.8L212,31.3
              l9.3-9.8l0.7-0.7l-1-0.1l-9.4-1h-0.2l-0.2,0.2l-4.1,4.1l0.4-4.1l0.1-0.5l-0.5-0.1l-7.8-0.8L198.8,18.5L198.8,18.5z' />
            </g>
            <g>
              <path fill='#FFFFFF' d='M225,45.2c-0.4,0-0.7,0-1,0c-0.7,0-1.4-0.1-2-0.2h-0.2l-1.1-7.7l0.3,0.1c0.5,0.1,1.1,0.1,1.7,0.1
              c0.4,0,0.8,0.1,1.2,0.1c0.2,0,0.3,0,0.5,0c0.6,0,1.2-0.1,1.8-0.2c0.5-0.1,0.8-0.2,1-0.4s0.3-0.4,0.2-0.8c0-0.2-0.2-0.5-0.5-0.7
              c-0.4-0.3-0.8-0.6-1.4-0.9c-0.6-0.3-1.2-0.7-1.8-1.1c-0.7-0.4-1.3-0.9-1.9-1.4c-0.6-0.6-1.2-1.2-1.6-2c-0.5-0.7-0.8-1.7-0.9-2.7
              c-0.2-1.2-0.1-2.2,0.2-3.2c0.3-0.9,0.8-1.8,1.4-2.5c0.6-0.7,1.5-1.3,2.4-1.7c0.9-0.4,2-0.8,3.1-0.9c0.6-0.1,1.2-0.1,1.8-0.2
              l1.6-0.1c0.5,0,1.8,0,1.8,0l1.1,7.2c0,0-0.7,0-0.9,0s-0.4,0-0.7,0c-0.2,0-0.5,0-0.7,0.1c-0.1,0-0.3,0.1-0.5,0.1s-0.4,0.1-0.6,0.2
              c-0.1,0.1-0.3,0.1-0.4,0.3c0,0.1-0.1,0.1-0.1,0.3c0,0,0.1,0.2,0.5,0.5c0.4,0.2,0.8,0.5,1.3,0.8s1.1,0.7,1.7,1.1
              c0.6,0.4,1.3,0.9,1.8,1.5c0.6,0.6,1.1,1.3,1.5,2.1c0.5,0.8,0.8,1.7,0.9,2.7c0.2,1.1,0.1,2.1-0.1,3.1c-0.2,1-0.6,1.9-1.2,2.7
              c-0.6,0.8-1.5,1.5-2.5,2.1c-1.1,0.6-2.4,1-3.9,1.2c-0.9,0.1-1.8,0.2-2.6,0.2C225.7,45.2,225.3,45.2,225,45.2z' />
              <path d='M231.2,19.4l1,6.7c-0.2,0-0.4,0-0.7,0c-0.2,0-0.4,0-0.7,0c-0.2,0-0.5,0-0.8,0.1c-0.1,0-0.3,0.1-0.5,0.1s-0.4,0.1-0.6,0.2
              c-0.2,0.1-0.3,0.2-0.5,0.3c-0.1,0.1-0.2,0.3-0.1,0.5c0,0.2,0.2,0.4,0.6,0.6c0.4,0.2,0.8,0.5,1.3,0.8s1.1,0.7,1.7,1.1
              c0.6,0.4,1.2,0.9,1.8,1.5c0.6,0.6,1.1,1.2,1.5,2s0.7,1.6,0.9,2.6s0.1,2.1-0.1,3c-0.2,1-0.6,1.8-1.2,2.6c-0.6,0.8-1.4,1.5-2.5,2
              c-1,0.6-2.3,0.9-3.8,1.2c-0.9,0.1-1.8,0.2-2.5,0.2c-0.4,0-0.8,0-1.1,0s-0.7,0-1,0c-0.7,0-1.4-0.1-2-0.1l-0.9-7
              c0.5,0.1,1.1,0.1,1.7,0.2c0.4,0,0.7,0.1,1.2,0.1c0.2,0,0.3,0,0.5,0c0.6,0,1.2-0.1,1.9-0.2c1.1-0.2,1.6-0.6,1.5-1.5
              c0-0.3-0.3-0.6-0.6-0.9c-0.4-0.3-0.9-0.6-1.4-0.9c-0.6-0.3-1.2-0.7-1.8-1.1c-0.7-0.4-1.3-0.9-1.9-1.4c-0.6-0.5-1.1-1.2-1.6-1.9
              c-0.4-0.7-0.7-1.6-0.9-2.6c-0.2-1.1-0.1-2.1,0.2-3.1c0.3-0.9,0.7-1.7,1.4-2.4c0.6-0.7,1.4-1.2,2.3-1.7c0.9-0.4,1.9-0.7,3-0.9
              c0.6-0.1,1.1-0.1,1.7-0.2c0.6,0,1.1,0,1.6-0.1C230.1,19.4,230.6,19.4,231.2,19.4 M231.6,18.9h-0.4c-0.5,0-1,0-1.6,0L228,19
              c-0.6,0-1.2,0.1-1.8,0.2c-1.1,0.2-2.2,0.5-3.2,0.9c-1,0.5-1.8,1.1-2.5,1.8c-0.7,0.7-1.2,1.6-1.5,2.6s-0.4,2.1-0.2,3.3
              c0.2,1.1,0.5,2,1,2.8s1,1.4,1.7,2c0.6,0.6,1.3,1.1,2,1.5s1.3,0.7,1.8,1.1c0.5,0.3,1,0.6,1.4,0.9c0.2,0.1,0.4,0.3,0.4,0.5
              c0,0.3,0.1,0.7-1,0.9c-0.6,0.1-1.2,0.1-1.8,0.2c-0.2,0-0.3,0-0.5,0c-0.4,0-0.8,0-1.1,0c-0.6,0-1.2-0.1-1.7-0.1l-0.7-0.1l0.1,0.7
              l1.1,7.1l0.1,0.4l0.4,0.1c0.6,0.1,1.3,0.1,2,0.2c0.3,0,0.6,0,1,0s0.7,0,1.1,0c0.8,0,1.7-0.1,2.6-0.3c1.5-0.2,2.9-0.6,4-1.2
              c1.1-0.6,2-1.3,2.6-2.1s1.1-1.8,1.3-2.8s0.2-2.1,0.1-3.2c-0.2-1.1-0.5-2-0.9-2.8c-0.5-0.8-1-1.5-1.6-2.1s-1.2-1.1-1.9-1.6
              c-0.7-0.4-1.2-0.8-1.7-1.1c-0.5-0.3-0.9-0.6-1.3-0.8c-0.3-0.2-0.4-0.3-0.4-0.3c0-0.1,0-0.1,0-0.1c0.1-0.1,0.2-0.1,0.3-0.2
              c0.2-0.1,0.3-0.1,0.5-0.2c0.2,0,0.4-0.1,0.5-0.1c0.2,0,0.5-0.1,0.7-0.1s0.4,0,0.6,0c0.2,0,0.5,0,0.7,0h0.6l-0.1-0.6l-1-6.7
              L231.6,18.9L231.6,18.9z' />
            </g>
          </g>
        </g>
      </SVG>
    )
  }
}

SVGShortBreaks.propTypes = SVG.propTypes

SVGShortBreaks.defaultProps = {
  title: 'Shortbreaks Icon'
}

export default SVGShortBreaks
