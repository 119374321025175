import PropTypes from 'prop-types'
import classNames from 'classnames'
import React, { PureComponent } from 'react'
import { Modal, Row } from 'react-bootstrap'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'

import DisplayPrice from '../atoms/DisplayPrice'
import ResponsiveImage from '../atoms/ResponsiveImage'
import Select from '../atoms/Select'

import dateHelpers from '../../helpers/dateHelpers'
import trackingHelpers from '../../helpers/trackingHelpers'
import Stepper from './Stepper'

import config from '../../configs/config'
const {
  harvestBasketData
} = config

class AmendButton extends PureComponent {
  render () {
    return (
      <a
        className='hoverable'
        {...trackingHelpers.getAttributes('Amend Extras', 'Extras', this.props.dataLabel)}
        onClick={this.props.onClick}
        onKeyDown={this.props.onKeyDown}>
        amend
      </a>
    )
  }
}

class UpgradeV2 extends PureComponent {
  constructor (props) {
    super(props)
    this.handleUpdatedUpgradeDates = this.handleUpdatedUpgradeDates.bind(this)
    this.handleCompositionIncrement = this.handleCompositionIncrement.bind(this)
    this.handleCompositionDecrement = this.handleCompositionDecrement.bind(this)
    this.compositionOptions = this.compositionOptions.bind(this)
    this.daysOptions = this.daysOptions.bind(this)
    this.datesOptions = this.datesOptions.bind(this)

    this.handleUpdatedUpgradeDates(this.props.upgrade.dates)
    this.handleUpdatedUpgradeDays(this.props.upgrade.days)
    this.modalOpen = this.modalOpen.bind(this)
    this.hasChildrenUnder3 = (harvestBasketData.ages || []).filter(age => age < 3).length > 0
    this.handleUpgradeCTAClose = this.handleUpgradeCTAClose.bind(this)
    this.modalClose = this.props.modalClose.bind(this)
  }

  handleUpgradeCTAClose (selectedProductId, event) {
    event && event.preventDefault()
    this.props.handleUpgradeCTA(selectedProductId)
    this.modalClose()
  }

  componentWillReceiveProps (nextProps) {
    if (!nextProps.upgrade) return
    this.handleUpdatedUpgradeDates(nextProps.upgrade.dates)
    this.handleUpdatedUpgradeDays(nextProps.upgrade.days)
  }

  handleUpdatedUpgradeDates (upgradeDates) {
    this.availableDates = upgradeDates
      ? upgradeDates.map(date => {
        return {
          text: dateHelpers.formatStandard(date),
          value: date
        }
      })
      : []
  }

  handleUpdatedUpgradeDays (upgradeDays) {
    this.availableDays = upgradeDays
      ? upgradeDays.map(daysObj => {
        return {
          text: daysObj.days,
          value: daysObj.id
        }
      })
      : []
  }

  handleCompositionIncrement (type) {
    return this.props.handleCompositionChange({
      field: type,
      id: this.props.upgrade.id,
      increment: true
    })
  }

  handleCompositionDecrement (type) {
    return this.props.handleCompositionChange({
      field: type,
      id: this.props.upgrade.id,
      increment: false
    })
  }

  compositionOptions () {
    if (this.props.upgrade.composition.quantityBased) {
      const trackingQuantityBased = {
        decrement: {
          action: 'Remove Qty',
          category: 'Upgrades',
          label: this.props.upgrade.id
        },
        increment: {
          action: 'Add Qty',
          category: 'Upgrades',
          label: this.props.upgrade.id
        }
      }
      return (
        <Stepper
          label='Quantity'
          disabled={this.props.upgrade.isInBasket}
          max={this.props.upgrade.composition.quantity >= (this.props.upgrade.compositionBoundaries.max.quantityBased || 15)}
          handleDecrement={() => this.handleCompositionDecrement('quantityBased')}
          handleIncrement={() => this.handleCompositionIncrement('quantityBased')}
          tracking={trackingQuantityBased}
          value={this.props.upgrade.composition.quantityBased} />
      )
    }
    if (this.props.upgrade.composition.quantity) {
      const trackingQuantity = {
        decrement: {
          action: 'Remove Quantity',
          category: 'Upgrades',
          label: this.props.upgrade.id
        },
        increment: {
          action: 'Add Quantity',
          category: 'Upgrades',
          label: this.props.upgrade.id
        }
      }
      return (
        <Stepper
          disabled={this.props.upgrade.isInBasket}
          handleDecrement={() => this.handleCompositionDecrement('quantity')}
          handleIncrement={() => this.handleCompositionIncrement('quantity')}
          label='Quantity'
          max={this.props.upgrade.composition.quantity >= (this.props.upgrade.compositionBoundaries.max.quantity || 15)}
          tracking={trackingQuantity}
          value={this.props.upgrade.composition.quantity} />
      )
    }
    if (this.props.upgrade.isAdultRequired && !this.props.upgrade.composition.adults) return null
    const trackingParty = {
      adults: {
        decrement: {
          action: 'Remove Adults',
          label: this.props.upgrade.id,
          category: 'Upgrades'
        },
        increment: {
          action: 'Add Adults',
          label: this.props.upgrade.id,
          category: 'Upgrades'
        }
      },
      children: {
        decrement: {
          action: 'Remove Children',
          label: this.props.upgrade.id,
          category: 'Upgrades'
        },
        increment: {
          action: 'Add Children',
          label: this.props.upgrade.id,
          category: 'Upgrades'
        }
      }
    }

    return (
      <React.Fragment>
        <Stepper
          formattedMessage={{
            id: 'upgrades.adultsUpgradeStepperLabel',
            values: { years: this.props.childrenMaxAge + 1 }
          }}
          disabled={this.props.upgrade.isInBasket}
          handleDecrement={() => this.handleCompositionDecrement('adults')}
          handleIncrement={() => this.handleCompositionIncrement('adults')}
          tracking={trackingParty.adults}
          value={this.props.upgrade.composition.adults} />
        {this.props.defaultComposition.children > 0 &&
          <Stepper
            formattedMessage={{
              id: 'upgrades.childrenUpgradeStepperLabel',
              values: { years: `${this.hasChildrenUnder3 ? '3' : this.props.childrenMinAge}-${this.props.childrenMaxAge}` }
            }}
            formattedMessageSubLabel={this.hasChildrenUnder3 ? {
              id: 'common.under3GoFree'
            } : null}
            disabled={this.props.upgrade.isInBasket}
            handleDecrement={() => this.handleCompositionDecrement('children')}
            handleIncrement={() => this.handleCompositionIncrement('children')}
            tracking={trackingParty.children}
            value={this.props.upgrade.composition.children} />
        }
      </React.Fragment>
    )
  }

  daysOptions () {
    return (
      <Select
        options={this.availableDays}
        disabled={this.props.upgrade.isInBasket}
        onChange={(e) => this.props.handleDaysSelection(this.props.upgrade.id, e)}
        name='Days'
        label='Days'
        value={this.props.upgrade.selectedProductId}
        tracking={{
          label: this.props.upgrade.id,
          action: 'Attraction Change',
          category: 'Upgrades'
        }} />
    )
  }

  datesOptions () {
    return (
      <Select
        label='Date'
        options={this.availableDates}
        disabled={this.props.upgrade.isInBasket}
        onChange={(e) => this.props.handleDateSelection(this.props.upgrade.id, e)}
        name='UpgradeDate'
        value={this.props.upgrade.selectedDate}
        tracking={{
          label: this.props.upgrade.id,
          action: 'Attraction Change',
          category: 'Upgrades'
        }}
      />
    )
  }

  modalOpen () {
    if (this.props.upgrade.isAvailable !== false) {
      this.props.openModal(this.props.upgrade.group, true)
    }
  }

  render () {
    const totalPrice = this.props.upgrade.price
    let grossPrice = totalPrice

    const addBasketCTATracking = {
      action: this.props.upgrade.isInBasket ? 'Remove Attraction From Cart' : 'Add Attraction To Cart',
      category: 'Upgrades',
      label: this.props.upgrade.id
    }

    const closeModalCTATracking = {
      action: 'Close Modal',
      category: 'Upgrades',
      label: this.props.upgrade.id
    }

    const openModalCTATracking = {
      action: this.props.upgrade.isInBasket ? 'Open Modal to Edit Attraction' : 'Open Modal to Add Attraction',
      category: 'Upgrades',
      label: this.props.upgrade.id
    }
    const mainTileTitle = this.props.upgrade.title
    let upgradeDescription = this.props.upgrade.strapline || ''
    if (upgradeDescription.length > 130) {
      upgradeDescription = this.props.upgrade.strapline.substr(0, 130) + `&hellip;`
    }

    const quantityDescription = this.props.intl.formatMessage({ id: 'upgrades.quantity' }, { quantity: this.props.upgrade.composition.quantity })
    const quantityBasedDescription = this.props.intl.formatMessage({ id: 'upgrades.quantity' }, { quantity: this.props.upgrade.composition.quantityBased })

    const classes = classNames('btn btn-block btn-secondary', {
      'disabled': this.props.upgrade.isAvailable === false
    })
    return (
      <React.Fragment>
        <div className='bg-default pl-sm-0 product-option'>
          <div className='media d-sm-table media-sm my-0 pos-rel product-inner'>
            <div className='media-left float-left float-sm-none ml-sm-0 product-col product-thumb'>
              <div className='pos-rel'>
                <a onClick={this.modalOpen} role='button' className='thumb-container pb-0' onKeyDown={(e) => e.key === 'Enter' && this.modalOpen()}>
                  <ResponsiveImage
                    lazyload={false}
                    src={this.props.upgrade.image}
                  />
                </a>
              </div>
            </div>
            <div className='media-body product-col product-body pl-3'>
              {mainTileTitle &&
                <a onClick={this.modalOpen} role='button' onKeyDown={(e) => e.key === 'Enter' && this.modalOpen()} data-category='Extras' data-action='View Extras' data-label={this.props.upgrade.group}>
                  <h3 className='mb-0 product-title'>{mainTileTitle}</h3>
                </a>
              }
              {this.props.upgrade.strapline &&
                <div className='small text-muted'>
                  <span className='mb-0 product-description' dangerouslySetInnerHTML={{ __html: upgradeDescription }} />&nbsp;
                  <a
                    className='m-0 p-0'
                    onClick={this.modalOpen}
                    onKeyDown={(e) => e.key === 'Enter' && this.modalOpen()}
                    role='button'>
                    <FormattedMessage id='common.readMore' />
                  </a>
                </div>
              }
              {/* Only display for quantity requirement upgrades */}
              {this.props.upgrade.composition.quantity && !this.props.upgrade.composition.quantityBased &&
                <div className='small'>
                  {quantityDescription} <AmendButton onClick={this.modalOpen} onKeyDown={(e) => e.key === 'Enter' && this.modalOpen()} dataLabel={this.props.upgrade.group} />
                </div>
              }
              {this.props.upgrade.composition.quantityBased &&
                <div className='small'>
                  {quantityBasedDescription} <AmendButton onClick={this.modalOpen} onKeyDown={(e) => e.key === 'Enter' && this.modalOpen()} dataLabel={this.props.upgrade.group} />
                </div>
              }
              {this.props.upgrade.showUpgradeDate && (!this.props.upgrade.isAvailableForAllSelectedDates || this.props.upgrade.isAvailableForAllSelectedDates === 'false') && !this.props.upgrade.composition.quantity &&
                <div className='small text-muted'>
                  {dateHelpers.formatStandard(this.props.upgrade.selectedDate)}
                </div>
              }
            </div>
            <Modal show={this.props.modalIsOpen} onHide={() => this.modalClose()}>
              <Modal.Header closeButton>
                <Modal.Title>
                  {this.props.upgrade.title}
                </Modal.Title>
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <div className='col-sm-12'>
                    {<div dangerouslySetInnerHTML={{ __html: this.props.upgrade.description }} />}
                    {this.props.upgrade.additionalDescription &&
                      <a
                        href='#additionalDescriptionCollapse'
                        data-toggle='collapse'
                        className='collapsed'
                      >
                        <span className='i-plus collapse-icon'>&#43;</span>
                        Read <span className='collapsed-text'>more&hellip;</span><span className='expanded-text'>less</span>
                      </a>
                    }
                    <div id='additionalDescriptionCollapse' className='collapse'>
                      <div className='block-xs'>
                        <div className='small'
                          dangerouslySetInnerHTML={{ __html: this.props.upgrade.additionalDescription }} />
                      </div>
                    </div>
                  </div>
                </Row>
                <hr />
                <Row>
                  <div className='col-lg-10'>
                    {this.compositionOptions()}
                  </div>
                  {this.availableDays.length > 0 &&
                    <div className='col-lg-10'>
                      {this.daysOptions()}
                    </div>
                  }
                  {this.availableDates.length > 0 &&
                    <div className='col-lg-10'>
                      {this.datesOptions()}
                    </div>
                  }
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Row>
                  {Number(totalPrice) > 0 &&
                    <div className='col-xs-12'>
                      <FormattedMessage id='common.totalPrice' />:&nbsp;
                      <span className='h3'><DisplayPrice price={totalPrice} /></span>
                    </div>
                  }
                  <div className='col-xs-6 col-sm-4'>
                    <button
                      className='btn btn-block btn-default'
                      {...trackingHelpers.getAttributes(closeModalCTATracking.action, closeModalCTATracking.category, closeModalCTATracking.label)}
                      onClick={() => this.modalClose()}
                      onKeyDown={(e) => e.key === 'Enter' && this.modalClose()}
                      type='button'
                    >
                      <FormattedMessage id='common.close' />
                    </button>
                  </div>
                  <div className='col-xs-6 col-sm-4 col-sm-offset-4'>
                    <button
                      className='btn btn-block btn-primary'
                      {...trackingHelpers.getAttributes(addBasketCTATracking.action, addBasketCTATracking.category, addBasketCTATracking.label)}
                      onClick={(e) => this.handleUpgradeCTAClose(this.props.upgrade.selectedProductId, e)}
                      onKeyDown={(e) => e.key === 'Enter' && this.handleUpgradeCTAClose(this.props.upgrade.selectedProductId, e)}
                      type='button'
                    >
                      <FormattedMessage id='upgrades.ctaAmendText' values={{ isInBasket: this.props.upgrade.isInBasket }} />
                    </button>
                  </div>
                </Row>
              </Modal.Footer>
            </Modal>
            <div className='media-right media-middle p-0 product-col product-footer hidden-xs'>
              <div className='media media-sm'>
                <div className='media-body media-middle text-right text-sm-center product-col product-prices'>
                  <div className='media media-xs'>
                    <div className='media-body media-middle'>
                      {Number(grossPrice) > 0 &&
                        <div className='h1 product-price-total' data-automated-test='grossPrice'><DisplayPrice price={grossPrice} /></div>
                      }
                    </div>
                  </div>
                </div>
                <div className='media-body media-middle product-col product-actions hidden-xs'>
                  <div className='product-action'>
                    {/* Upgrade call to action. Should read Add or Remove based on isInBasket prop. */}
                    <button
                      className={classes}
                      {...trackingHelpers.getAttributes(openModalCTATracking.action, openModalCTATracking.category, openModalCTATracking.label)}
                      onClick={this.modalOpen}
                      onKeyDown={(e) => e.key === 'Enter' && this.modalOpen()}
                      type='button'
                    >
                      <FormattedMessage id='upgrades.ctaText' values={{ isInBasket: this.props.upgrade.isInBasket }} />
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='clearfix product-actions visible-xs pt-0'>
          <div className='col-xs-5'>
            <div className='media-body media-middle'>
              {Number(grossPrice) > 0 &&
                <div className='h1 product-price-total' data-automated-test='grossPrice'><DisplayPrice price={grossPrice} /></div>
              }
            </div>
          </div>
          <div className='col-xs-7 py-3'>
            <button
              className={classes}
              {...trackingHelpers.getAttributes(openModalCTATracking.action, openModalCTATracking.category, openModalCTATracking.label)}
              onClick={this.modalOpen}
              onKeyDown={(e) => e.key === 'Enter' && this.modalOpen()}
              type='button'
            >
              <FormattedMessage id='upgrades.ctaText' values={{ isInBasket: this.props.upgrade.isInBasket }} />
            </button>
          </div>
        </div>
      </React.Fragment>
    )
  }
}

UpgradeV2.propTypes = {
  defaultComposition: PropTypes.object.isRequired,
  handleUpgradeCTA: PropTypes.func.isRequired,
  modalClose: PropTypes.func.isRequired,
  modalOpen: PropTypes.func,
  row: PropTypes.bool,
  upgrade: PropTypes.shape({
    composition: PropTypes.shape({
      adults: PropTypes.number,
      cars: PropTypes.number,
      children: PropTypes.number
    }).isRequired,
    description: PropTypes.string.isRequired,
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  intl: intlShape,
  wasText: PropTypes.element.isRequired
}

export default injectIntl(UpgradeV2)
export {
  UpgradeV2
}
