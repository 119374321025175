import _ from 'lodash'
import moment from 'moment'
import qs from 'qs'

const discovery = (state = {
  bounds: {},
  hotelId: null,
  isLoading: true,
  isPrimed: false,
  nights: 0,
  packagesCount: 0,
  packageGroupId: null,
  partyComp: { adults: 2, childAges: [] },
  startDate: null,
  venueCode: null,
  venueName: null
}, action) => {
  switch (action.type) {
    case 'DISCOVERY_PRIMED': {
      return Object.assign({}, state, {
        isPrimed: action.primed,
        partyComposition: action.partyComposition
      })
    }
    case 'DISCOVERY_CALENDAR_UPDATE': {
      return Object.assign({}, state, {
        nights: action.nights,
        packagesCount: action.packagesCount,
        bounds: action.bounds,
        isLoading: action.isLoading
      })
    }
    case 'DISCOVERY_CALENDAR_SELECT_DAY': {
      const { pathname, searchParams } = action.day
      if (pathname && searchParams && state.hotelId) {
        const locationSearch = _.get(window, 'location.search', '')
        const parsedLocationSearch = qs.parse(locationSearch)
        const parsedSearchParams = qs.parse(`${searchParams}`)

        if (parsedLocationSearch.CallCentreFlag) {
          parsedSearchParams.CallCentreFlag = parsedLocationSearch.CallCentreFlag
        }
        if (parsedLocationSearch.customerCode) {
          parsedSearchParams.customerCode = parsedLocationSearch.customerCode
        }
        if (parsedLocationSearch.operator) {
          parsedSearchParams.operator = parsedLocationSearch.operator
        }

        const updatedSearchParams = qs.stringify(parsedSearchParams)

        window.location = `https://${window.location.host}/${pathname}?${updatedSearchParams}${`#${state.hotelId}`}`
        break
      }
      if (pathname === 'moreinformation') {
        const queryStringProperties = qs.parse(window.location.search.substring(1))
        const parsedParams = qs.parse(searchParams)
        const mergedUrl = qs.stringify(_.merge(queryStringProperties, parsedParams))

        window.location = `https://${window.location.host}/${pathname}?${mergedUrl}`
      }
      break
    }
    case 'DISCOVERY_CALENDAR': {
      const { roomRates = {}, venueCode, searchFormId } = _.get(window, 'packageRatesReply.meta.context', {})
      const { rooms = [], checkinDate, checkoutDate } = roomRates
      const { venueProducts = {}, eventProducts = {} } = _.get(window, 'packageRatesReply.linked', {})
      const packageGroupId = _.get(window, 'packageGroupId', null)
      const name = (Object.values(venueProducts)[0] && Object.values(venueProducts)[0].name) || ''
      const hotelEventName = (Object.values(eventProducts)[0] && Object.values(eventProducts)[0].name) || ''
      const nights = moment(checkoutDate).diff(checkinDate, 'days')
      const partyComp = rooms.reduce((partyComp, room) => {
        partyComp.adults += room.adults
        partyComp.childAges = partyComp.childAges.concat(room.childrenAges, room.infantsAges)
        return partyComp
      }, { adults: 0, childAges: [] })
      return Object.assign({}, state, {
        hasEventEntry: action.hasParkEntry,
        hotelEventName,
        hotelId: action.hotelId,
        isLoading: true,
        nights,
        partyComp,
        packageGroupId,
        searchFormId,
        startDate: checkinDate,
        venueCode,
        venueName: name,
        roomId: action.roomId
      })
    }
  }
  return state
}

export default discovery
