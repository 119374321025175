import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGExpand extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <g>
          <path d='M12 8l-6 6 1.41 1.41L12 10.83l4.59 4.58L18 14z' />
          <path d='M0 0h24v24H0z' fill='none' />
        </g>
      </SVG>
    )
  }
}

SVGExpand.propTypes = SVG.propTypes

SVGExpand.defaultProps = {
  title: 'Expand Icon'
}

export default SVGExpand
