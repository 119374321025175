import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component, PureComponent } from 'react'
import Autocomplete from 'react-autocomplete'
import { Alert, Button, Col, ControlLabel, FormControl, FormGroup, HelpBlock, InputGroup } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import onClickOutside from 'react-onclickoutside'
// Using lightweight libphonenumber plugin to get various information about customer phone number
// and then format it for improved UI and CX
// More info: https://github.com/catamphetamine/libphonenumber-js
import { getCountryCallingCode } from 'libphonenumber-js'

import FormattedAddress from '../atoms/FormattedAddress'
import SVGInformation from '../atoms/SVGInformation'

import generalHelpers from '../../helpers/generalHelpers'
import trackingHelpers from '../../helpers/trackingHelpers'

import config from '../../configs/config'

import countryOptionValues from '../../configs/countryOptionValues.json'
import countryStateOptionValues from '../../configs/countryStateOptionValues.json'

class AddressList extends PureComponent {
  handleClickOutside () {
    this.props.onClose()
  }
  render () {
    return (
      <div data-automated-test='addr-autocomplete' className='dropdown-menu open list-group' children={this.props.items} data-hj-suppress />
    )
  }
}

const EnhancedAddressList = onClickOutside(AddressList)

/**
 * AddressLookup - The actual component
 *
 * @param {Object} props the props
 * @return {JSX} the rendered component
 */
class AddressLookup extends Component {
  constructor (props) {
    super(props)
    this.handlePostalCodeLookup = this.handlePostalCodeLookup.bind(this)
    this.hasSimplifiedPaymentPage = _.get(config, 'brandConfig.secure.hasFeatures.payment.hasSimplifiedPaymentPage', false)

    // Default dialling and country code to GB
    // This is for UI use
    this.state = {
      addressCountry: 'GB',
      addressDialCode: '+44'
    }
  }

  // Postcode lookup should not submit form but should do a onPostalCodeGet when
  // the user hits 'Enter'
  handlePostalCodeLookup (e) {
    // Any key apart from enter
    if (e.key !== 'Enter') {
      return false
    }

    e.preventDefault()

    // Enter without value or if we are in manual mode
    if ((e.key === 'Enter' && !e.target.value) || this.props.hasManualInput) {
      return false
    }

    return this.props.onPostalCodeGet(e.target.value)
  }

  // Display the dialling code for the country selected
  internationalCodeUI (internationalCode) {
    // Just to ensure onload we have a dialling code use code in initial state
    if (_.isEmpty(internationalCode)) {
      internationalCode = this.state.addressCountry
    }
    // Identify country dialling code
    let country = getCountryCallingCode(internationalCode)
    // Update the codes in state
    // This is for UI display use
    this.setState(() => ({
      addressCountry: internationalCode,
      addressDialCode: `+${country}`
    }))
  }

  shouldComponentUpdate (nextProps) {
    return !_.isEqual(nextProps, this.props)
  }

  render () {
    // Components
    let addressNotFoundComponent = null
    let cancelButton = null
    let changeAddressButton = null
    let confirmButton = null
    let formattedAddress = null

    // Create object of address fields that require address confirm button validation
    let amendAddressFormFieldsToValidate = Object.assign({}, {
      billToAddressPostalCode: this.props.billToAddressPostalCode,
      billToAddressLine0: this.props.billToAddressLine0,
      billToAddressCity: this.props.billToAddressCity,
      billToAddressCountry: this.props.billToAddressCountry
    })

    const postCodeNotFoundText = () => {
      if (!this.hasSimplifiedPaymentPage) {
        return (
          <React.Fragment>
            <FormattedMessage id='payment.postcodeNotFound' />&nbsp;
          </React.Fragment>
        )
      }
      return (
        <React.Fragment>
          <FormattedMessage id='payment.postcodeNotFoundUpdate' />&nbsp;
        </React.Fragment>
      )
    }

    const postCodeLabel = () => {
      return (
        <Col componentClass={ControlLabel} sm={4}>
          <FormattedMessage id='common.postcode' data-automated-test='postcodeLabel' />
        </Col>
      )
    }

    const handleTelephoneLabel = () => {
      return (
        <Col componentClass={ControlLabel} sm={4}>
          <FormattedMessage id='common.telephone' data-automated-test='paymentTelephone' />
        </Col>
      )
    }

    // If we don't have a list of address to render in a <select>
    if (!this.props.listOfAddresses.length) {
      // Define the 'Cancel' and 'Confirm' button when hasManualInput is true
      if (this.props.hasManualInput) {
        cancelButton = (
          <button className='btn btn-link' onClick={this.props.onAddressReset} data-automated-test='cancelButton'>
            <FormattedMessage id='common.cancel' />
          </button>
        )
        confirmButton = (
          <Button
            bsStyle='primary'
            data-automated-test='paymentConfirmButton'
            onClick={(e) => this.props.onAddressConfirm(amendAddressFormFieldsToValidate)}
            onKeyDown={(e) => e.key === 'Enter' && this.props.onAddressConfirm(amendAddressFormFieldsToValidate())}>
            <FormattedMessage id='common.confirm' />
          </Button>
        )
      } else {
        // Grab all the 'billTo' keys (which are the address details) from props
        const address = _.pickBy(this.props, (value, key) => {
          return _.startsWith(key, 'billTo')
        })

        // If we have address details show 'Change Address' button and formattedAddress component
        if (address.billToAddressLine0 !== '') {
          changeAddressButton = (
            <button data-automated-test='change-address' className='btn btn-default' onClick={this.props.onToggleManualInput}>
              <FormattedMessage id='payment.changeAddress' />
            </button>
          )

          // If we have address details show them in a formatted list
          formattedAddress = (
            <FormattedAddress {...address} />
          )
        } else {
          addressNotFoundComponent = (
            <p>
              {postCodeNotFoundText()}
              <a
                onClick={this.props.onToggleManualInput}
                onKeyDown={(e) => e.key === 'Enter' && this.props.onToggleManualInput()}
                role='button'>
                <FormattedMessage id='payment.enterAddressManually' />
              </a>
            </p>
          )
        }
      }
    }

    // Notify the user that something went wrong
    const notificationComponent = this.props.errors.addressLookup && (
      <Alert bsStyle='warning' className='mt-4'>
        <strong>{this.props.errors.addressLookup}</strong>
      </Alert>
    )

    // Get country <option>s
    const countryOptions = generalHelpers.getOptions(countryOptionValues, 'country', 'Country')

    // Check if the country selected has states
    const countryStates = this.props.billToAddressCountry && countryStateOptionValues[this.props.billToAddressCountry]

    // Get some state <option>s if the country selected have states
    const countryStateOptions = countryStates && generalHelpers.getOptions(countryStates, 'countryStates', 'Please select a state')
    const countryStateComponentClass = countryStates ? 'select' : 'input'

    // Hide / show some of the manual input fields
    const manualEntryClass = (!this.props.hasManualInput) ? 'hidden' : ''
    const nonManualEntryClass = (this.props.hasManualInput) ? 'hidden' : ''

    // Grab postalcode, replace last 3 chars and any optional spaces with empty string and uppercase the whole thing
    const postcodeLabel = (this.props.billToAddressPostalCode || '').replace(/\s*/g, '').replace(/.{3}$/, '').toUpperCase()

    // Input props for the Autocomplete component
    const autoCompleteInputProps = Object.assign({
      autoCapitalize: 'characters',
      className: 'form-control',
      id: 'billToAddressPostalCode',
      onKeyPress: this.handlePostalCodeLookup,
      type: 'text'
    }, trackingHelpers.getAttributes('postcode', 'payment', postcodeLabel))
    const hasTwoStagePayment = _.get(config, 'brandConfig.secure.hasFeatures.payment.hasTwoStagePayment', false)

    const handleMiscFields = () => {
      return (
        <Col sm={8} md={6} smOffset={4}>
          {addressNotFoundComponent}
          {formattedAddress}
          {changeAddressButton}
        </Col>
      )
    }

    return (
      <React.Fragment>
        {(!hasTwoStagePayment || this.props.isCallCentre) &&
          <React.Fragment>
            {/* only show postcode fields when this.props.hasPostcodeField is set to true */}
            {this.props.hasPostcodeField &&
              <FormGroup
                className={this.props.errors.billToAddressPostalCode ? 'has-error' : null}
                controlId='billToAddressPostalCode'>

                {/* PostCode Field Label */}
                {postCodeLabel()}
                <Col sm={5}>
                  <InputGroup>
                    <Autocomplete
                      getItemValue={item => item.address[0] || ''}
                      inputProps={autoCompleteInputProps}
                      items={this.props.listOfAddresses}
                      onChange={e => this.props.onInputChange(e.target.id, e.target.value)}
                      onSelect={this.props.onAddressSelect}
                      open={this.props.listOfAddresses.length !== 0}
                      renderItem={(item, isHighlighted) => (
                        <button
                          className={`list-group-item text-nowrap ${isHighlighted ? 'highlightedItem' : ''}`}
                          key={item.address[0] || ''}
                          tabIndex='-1'>
                          {`${item.address.join(', ')}, ${item.locality || ''}, ${item.region || ''}, ${item.postalCode || ''}`}
                        </button>
                      )}
                      renderMenu={items => <EnhancedAddressList items={items} onClose={this.props.onToggleManualInput} />}
                      shouldItemRender={item => Array.isArray(item.address)}
                      value={this.props.billToAddressPostalCode}
                      wrapperStyle={null} />
                    <InputGroup.Button>
                      <Button
                        block
                        bsStyle='primary'
                        className='no-arrow'
                        data-automated-test='find-address'
                        disabled={!this.props.billToAddressPostalCode}
                        onClick={() => this.props.onPostalCodeGet(this.props.billToAddressPostalCode)}
                        onKeyDown={(e) => e.key === 'Enter' && this.props.onPostalCodeGet(this.props.billToAddressPostalCode)}>
                        <FormattedMessage id='payment.findAddress' />
                      </Button>
                    </InputGroup.Button>
                  </InputGroup>
                  {this.props.errors.billToAddressPostalCode &&
                    <HelpBlock className='pull-right'>
                      {this.props.errors.billToAddressPostalCode}
                    </HelpBlock>
                  }
                </Col>

                <Col sm={8} md={6} smOffset={this.hasSimplifiedPaymentPage ? 4 : 3}>
                  {notificationComponent}
                </Col>
              </FormGroup>
            }

            {/* Manual input fields / formatted address / 'postcode not found' */}
            <FormGroup className={nonManualEntryClass}>

              {/* show the label here when we have no postcode field */}
              {!this.props.hasPostcodeField &&
                <Col componentClass={ControlLabel} htmlFor='billToAddressLine0' sm={this.hasSimplifiedPaymentPage ? 4 : 3}>
                  <FormattedMessage id='common.address' />
                </Col>
              }
              {handleMiscFields()}
            </FormGroup>

            <FormGroup
              className={manualEntryClass}
              controlId='billToAddressLine0'
              validationState={this.props.errors.billToAddressLine0 ? 'error' : null}>
              <Col componentClass={ControlLabel} sm={4}>
                <FormattedMessage
                  data-automated-test='paymentAddressLabel'
                  id='payment.addressLineNo'
                  values={{ no: '1' }}
                />
              </Col>

              <Col sm={5}>
                <FormControl
                  {...trackingHelpers.getAttributes('address 1', 'payment', '1')}
                  name='bill_to_address_line0'
                  onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                  type='text'
                  value={this.props.billToAddressLine0} />

                {this.props.errors.billToAddressLine0 &&
                  <HelpBlock className='pull-right'>
                    {this.props.errors.billToAddressLine0}
                  </HelpBlock>
                }
              </Col>
            </FormGroup>

            <FormGroup className={manualEntryClass} controlId='billToAddressLine1'>
              <Col componentClass={ControlLabel} sm={4}>
                <FormattedMessage
                  data-automated-test='paymentAddressLabel'
                  id='payment.addressLineNo'
                  values={{ no: '2' }}
                />
              </Col>

              <Col sm={5}>
                <FormControl
                  {...trackingHelpers.getAttributes('address 2', 'payment', '1')}
                  name='bill_to_address_line1'
                  onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                  type='text'
                  value={this.props.billToAddressLine1} />
              </Col>
            </FormGroup>

            <FormGroup className={manualEntryClass} controlId='billToAddressLine2'>
              <Col componentClass={ControlLabel} sm={4}>
                <FormattedMessage
                  data-automated-test='paymentAddressLabel'
                  id='payment.addressLineNo'
                  values={{ no: '3' }}
                />
              </Col>

              <Col sm={5}>
                <FormControl
                  {...trackingHelpers.getAttributes('address 3', 'payment', '1')}
                  name='bill_to_address_line2'
                  onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                  type='text'
                  value={this.props.billToAddressLine2} />
              </Col>
            </FormGroup>

            <FormGroup
              className={manualEntryClass}
              controlId='billToAddressCity'
              validationState={this.props.errors.billToAddressCity ? 'error' : null}>
              <Col componentClass={ControlLabel} sm={4}>
                <FormattedMessage id='common.townOrCity' data-automated-test='paymentAddressLabel' />
              </Col>

              <Col sm={4}>
                <FormControl
                  {...trackingHelpers.getAttributes('town / city', 'payment', '1')}
                  name='bill_to_address_city'
                  onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                  type='text'
                  value={this.props.billToAddressCity} />
                {this.props.errors.billToAddressCity &&
                  <HelpBlock className='pull-right'>
                    {this.props.errors.billToAddressCity}
                  </HelpBlock>
                }
              </Col>
            </FormGroup>

            <FormGroup className={manualEntryClass} controlId='billToAddressState'>
              <Col componentClass={ControlLabel} sm={4}>
                <FormattedMessage id='common.countyOrState' data-automated-test='paymentAddressLabel' />
              </Col>

              <Col sm={4}>
                <FormControl
                  children={countryStateOptions || null}
                  componentClass={countryStateComponentClass}
                  {...trackingHelpers.getAttributes('county / state', 'payment', '1')}
                  name='bill_to_address_state'
                  onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                  value={this.props.billToAddressState} />
              </Col>
            </FormGroup>

            <FormGroup
              className={manualEntryClass}
              controlId='billToAddressCountry'
              validationState={this.props.errors.billToAddressCountry ? 'error' : null}>
              <Col componentClass={ControlLabel} sm={4}>
                <FormattedMessage id='common.country' data-automated-test='paymentAddressLabel' />
              </Col>

              <Col sm={4}>
                <FormControl
                  children={countryOptions}
                  componentClass='select'
                  {...trackingHelpers.getAttributes('country', 'payment')}
                  name='bill_to_address_country'
                  onChange={(e) => {
                    this.props.onInputChange(e.target.id, e.target.value)
                    this.internationalCodeUI(e.target.value)
                  }}
                  value={this.props.billToAddressCountry} />
                {this.props.errors.billToAddressCountry &&
                  <HelpBlock className='pull-right'>
                    {this.props.errors.billToAddressCountry}
                  </HelpBlock>
                }
              </Col>
            </FormGroup>

            {this.props.hasManualInput &&
              <FormGroup>
                <Col sm={8} md={6} smOffset={4}>
                  {cancelButton}
                  &nbsp;
                  {confirmButton}
                </Col>
              </FormGroup>
            }

            {/* Telephone */}
            <FormGroup controlId='billToPhone' validationState={this.props.errors.billToPhone ? 'error' : null}>
              {handleTelephoneLabel()}
              <Col sm={5}>
                <InputGroup className='billToPhone'>
                  {this.state.addressDialCode &&
                    <InputGroup.Addon placeholder={this.state.addressDialCode}>{this.state.addressDialCode}</InputGroup.Addon>
                  }
                  <FormControl
                    {...trackingHelpers.getAttributes('contact number', 'payment', '1')}
                    name='bill_to_phone'
                    onChange={(e) => {
                      this.props.onInputChange(e.target.id, e.target.value)
                      this.props.setBillingTelephoneNumber(e.target.value, this.state.addressCountry, this.state.addressDialCode)
                    }}
                    type='tel'
                    defaultValue={this.props.billToPhone} />
                </InputGroup>
                {this.props.errors.billToPhone &&
                <HelpBlock className='pull-right'>
                  {this.props.errors.billToPhone}
                </HelpBlock>
                }
              </Col>
              {this.props.eventMessaging &&
                <Col sm={8} md={6} smOffset={this.hasSimplifiedPaymentPage ? 4 : 3}>
                  <Alert bsStyle='info'>
                    <p>
                      <SVGInformation viewBox='0 0 512 512' height='1em' width='1em' />&nbsp;
                      {this.props.eventMessaging}
                    </p>
                  </Alert>
                </Col>
              }
            </FormGroup>
          </React.Fragment>
        }
      </React.Fragment>
    )
  }
}

AddressLookup.defaultProps = {
  errors: {},
  hasManualInput: false,
  hasPostcodeField: true,
  listOfAddresses: []
}

AddressLookup.propTypes = {
  billToAddressCity: PropTypes.string,
  billToAddressCountry: PropTypes.string,
  billToAddressLine0: PropTypes.string,
  billToAddressLine1: PropTypes.string,
  billToAddressLine2: PropTypes.string,
  billToAddressPostalCode: PropTypes.string,
  billToAddressState: PropTypes.string,
  billToPhone: PropTypes.string,
  errors: PropTypes.object,
  eventMessaging: PropTypes.string,
  hasManualInput: PropTypes.bool.isRequired,
  hasPostcodeField: PropTypes.bool.isRequired,
  listOfAddresses: PropTypes.arrayOf(
    PropTypes.shape({
      address: PropTypes.arrayOf(
        PropTypes.string
      ).isRequired,
      locality: PropTypes.string.isRequired,
      postalCode: PropTypes.string.isRequired,
      region: PropTypes.string.isRequired
    }).isRequired
  ),
  onAddressConfirm: PropTypes.func.isRequired,
  onAddressReset: PropTypes.func.isRequired,
  onAddressSelect: PropTypes.func.isRequired,
  onInputChange: PropTypes.func.isRequired,
  onPostalCodeGet: PropTypes.func.isRequired,
  onToggleManualInput: PropTypes.func.isRequired,
  setBillingTelephoneNumber: PropTypes.func.isRequired
}

export default AddressLookup
