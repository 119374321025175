import React from 'react'
import PropTypes from 'prop-types'
import Youtube from 'react-youtube'

import trackingHelpers from '../../helpers/trackingHelpers'

const endpoint = window.endpoint

const HotelImageCarousel = (props) => {
  let playerInstance = null

  const playerReady = (event) => {
    playerInstance = event.target
  }
  const stopVideo = () => playerInstance && playerInstance.stopVideo()

  const slides = props.images.map((image, index) => (
    <div
      className={`item ${((index === 0 && !props.videoId) && 'active')}`}
      key={`carousel-slides-${index}`}
      role='option'>
      {/* this alt tag is horrible, but we are getting rid of this component soon anyway */}
      <img
        alt={`${props.hotelName} ${index}`}
        className='img-responsive'
        key={image}
        src={image} />
    </div>
  ))

  if (props.videoId) {
    const opts = {
      width: '100%',
      playerVars: {
        autoplay: props.autoplay,
        enablejsapi: 1,
        modestbranding: 1,
        rel: 0,
        showInfo: 0
      }
    }

    // add the video to the beginning of the slides
    slides.unshift(
      (<div className='item active' key='YoutubeSlide' role='option'>
        <Youtube
          onReady={playerReady}
          opts={opts}
          videoId={props.videoId} />
      </div>)
    )
  }

  return (
    <div className='block-sm'>
      <div id='mainCarousel' className='carousel slide'>
        <div className='carousel-inner' role='listbox'>
          {slides}
        </div>
        {slides.length > 1 &&
          <div>
            <a className='left carousel-control'
              data-slide='prev'
              href='#mainCarousel'
              onClick={stopVideo}
              onKeyDown={(e) => e.key === 'Enter' && stopVideo()}
              role='button'
              {...trackingHelpers.getAttributes('previous slide', endpoint, 'carousel')}
            >
              <span className='icon-prev' />
              <span className='sr-only'>Previous</span>
            </a>
            <a className='right carousel-control'
              data-slide='next'
              href='#mainCarousel'
              onClick={stopVideo}
              onKeyDown={(e) => e.key === 'Enter' && stopVideo()}
              role='button'
              {...trackingHelpers.getAttributes('next slide', endpoint, 'carousel')}>
              <span className='icon-next' />
              <span className='sr-only'>Next</span>
            </a>
          </div>
        }
      </div>
    </div>
  )
}

HotelImageCarousel.defaultProps = {
  autoplay: false,
  videoId: null
}

HotelImageCarousel.propTypes = {
  autoplay: PropTypes.bool,
  images: PropTypes.array.isRequired,
  videoId: PropTypes.string
}

export default HotelImageCarousel
