import { createSelector } from 'reselect'
import moment from 'moment'

const getCheckInDate = state => state.engine.engineStore.checkInDate
const getCheckOutDate = state => state.engine.engineStore.checkOutDate
const getDefaultTicketDate = state => state.engine.defaults.ticketDate
const getTicketDate = state => state.engine.engineStore.ticketDate

export const getFirstAvailableTicketDate = createSelector(
  getCheckInDate,
  checkInDate => moment(checkInDate).toDate()
)

export const getLastAvailableTicketDate = createSelector(
  getCheckOutDate,
  checkOutDate => moment(checkOutDate).endOf('day').toDate()
)

export const getSelectedTicketDate = createSelector(
  [getDefaultTicketDate, getTicketDate],
  (defaultTicketDate, ticketDate) => moment(ticketDate || defaultTicketDate).toDate()
)
