const filters = {
  filterByPrice: (value) => {
    return (dispatch) => {
      dispatch({
        type: 'FILTER_BY_PRICE',
        value
      })
    }
  },
  filterByMilesToPark: (value) => {
    return (dispatch) => {
      dispatch({
        type: 'FILTER_BY_MILES_TO_PARK',
        value
      })
    }
  },
  filterByPropertyType: (value) => {
    return (dispatch) => {
      dispatch({
        type: 'FILTER_BY_PROPERTY_TYPE',
        value
      })
    }
  },
  filterByStarRating: (value) => {
    return (dispatch) => {
      dispatch({
        type: 'FILTER_BY_STAR_RATING',
        value
      })
    }
  },
  filterByPopularOptions: (value) => {
    return (dispatch) => {
      dispatch({
        type: 'FILTER_BY_POPULAR_OPTIONS',
        value
      })
    }
  },
  filterByHotelFeatures: (value) => {
    return (dispatch) => {
      dispatch({
        type: 'FILTER_BY_HOTEL_FEATURES',
        value
      })
    }
  },
  sortByCheapest: () => {
    return (dispatch) => {
      dispatch({
        type: 'SORT_BY_CHEAPEST'
      })
    }
  },
  sortByClosest: () => {
    return (dispatch) => {
      dispatch({
        type: 'SORT_BY_CLOSEST'
      })
    }
  },
  sortByTopSelling: () => {
    return (dispatch) => {
      dispatch({
        type: 'SORT_BY_TOP_SELLING'
      })
    }
  }
}

export default filters
