import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Checkbox, Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import generalHelpers from '../../helpers/generalHelpers'
import languageHelpers from '../../helpers/languageHelpers'
import trackingHelpers from '../../helpers/trackingHelpers'

import config from '../../configs/config'

import titleOptionValues from '../../configs/titleOptionValues.json'
const { brandConfig, childAgeControlId, hotelProduct } = config

class PaymentFormRooms extends PureComponent {
  render () {
    if (!this.props.rooms || !this.props.rooms.length) {
      return null
    }

    const titleOptions = generalHelpers.getOptions(titleOptionValues, 'title', 'Title')

    // Define an array with a range of child ages
    const childAgeRange = _.range(this.props.minChildAge, this.props.maxChildAge + 1)
    const withChildAges = _.get(brandConfig, 'secure.withChildAges', false) || hotelProduct.hide_hotel === '1'
    const hasSimplifiedPaymentPage = _.get(brandConfig, 'secure.hasFeatures.payment.hasSimplifiedPaymentPage', false)

    // Define the output for this method by looping over the rooms
    const rooms = this.props.rooms.map((room, roomIndex) => {
      const roomGuestCount = Number(room.adults) + Number(room.children) + Number(room.infants)
      const roomNumber = roomIndex + 1

      // Create form fields for each guest that needs to be validated
      const childAgeFields = (room.agesToValidate || []).map((age, childIndex) => {
        const childNumber = childIndex + 1
        const ageOptions = generalHelpers.getOptions(childAgeRange, `child${childNumber}age`, `Child ${childNumber}`)
        const controlName = languageHelpers.sprintf(childAgeControlId, childNumber, roomNumber)
        const controlId = _.lowerFirst(controlName)
        const label = `Child ${childNumber} Room ${roomNumber} Age`
        const error = this.props.errors[controlId]
        const storedValue = generalHelpers.getSessionStorage(controlName)

        if (withChildAges) {
          return (
            <FormControl
              componentClass='input'
              defaultValue={age}
              id={controlId}
              key={`${controlName}-${controlId}`}
              name={controlName}
              readOnly
              type='hidden' />
          )
        }

        return (
          <Col sm={4} lg={3} key={controlId} className={error ? 'has-error' : null}>
            <ControlLabel htmlFor={controlId} srOnly>
              {label}
            </ControlLabel>
            <FormControl
              className='gutter-top-xs'
              componentClass='select'
              {...trackingHelpers.getAttributes(controlId, 'payment')}
              id={controlId}
              key={`${controlName}-${controlId}`}
              name={controlName}
              onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
              defaultValue={storedValue}>
              {ageOptions}
            </FormControl>
            {error &&
              <HelpBlock>{error}</HelpBlock>
            }
          </Col>
        )
      })

      return (
        <div key={`room${roomNumber}`}>
          {this.props.hasLeadName &&
            <React.Fragment>
              {childAgeFields.length > 0 &&
                <div>
                  <Row>
                    <Col xs={12} sm={8} smOffset={hasSimplifiedPaymentPage ? 4 : 3}>
                      <p className='page-header mt-0' data-automated-test='roomName'>
                        {room.shortName} <small className='text-muted'>- (<FormattedMessage id='payment.guests' values={{ count: roomGuestCount }} />)</small>
                      </p>
                    </Col>
                  </Row>
                  {roomNumber === 1 && this.props.hasLeadName &&
                    <FormGroup>
                      <Col sm={hasSimplifiedPaymentPage ? 8 : 9} smOffset={hasSimplifiedPaymentPage ? 4 : 3} className='checkbox'>
                        <Checkbox
                          checked={this.props.billingDetails.isBookerFirstRoomLead}
                          id='isBookerFirstRoomLead'
                          name='isBookerFirstRoomLead'
                          onChange={(e) => this.props.setLeadRoomDetails(e.target.id, e.target.checked)}>
                          <FormattedMessage id='payment.leadNameCheckboxLabel' values={{ roomName: room.shortName }} />
                        </Checkbox>
                      </Col>
                    </FormGroup>
                  }
                </div>
              }

              <FormGroup>
                <Col componentClass={ControlLabel} htmlFor={`titleRoom${roomNumber}`} sm={hasSimplifiedPaymentPage ? 4 : 3}>
                  Name
                </Col>
                <Col sm={hasSimplifiedPaymentPage ? 8 : 9}>
                  <Row>
                    <Col sm={3} key={`titleRoom${roomNumber}`} className={this.props.errors[`titleRoom${roomNumber}`] ? 'has-error' : null}>
                      <FormControl
                        componentClass='select'
                        id={`titleRoom${roomNumber}`}
                        name={`titleRoom${roomNumber}`}
                        value={this.props.billingDetails[`titleRoom${roomNumber}`]}
                        onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}>
                        {titleOptions}
                      </FormControl>
                      {this.props.errors[`titleRoom${roomNumber}`] &&
                        <HelpBlock className='pull-right'>
                          {this.props.errors[`titleRoom${roomNumber}`]}
                        </HelpBlock>
                      }
                    </Col>
                    <Col sm={3} key={`initialRoom${roomNumber}`} className={this.props.errors[`initialRoom${roomNumber}`] ? 'has-error' : null}>
                      <FormControl
                        id={`initialRoom${roomNumber}`}
                        name={`initialRoom${roomNumber}`}
                        onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                        placeholder='Initial'
                        size='20'
                        type='text'
                        value={this.props.billingDetails[`initialRoom${roomNumber}`]}
                        checked={this.props.billingDetails.isBookerFirstRoomLead}
                        className='form-control' />
                      {this.props.errors[`initialRoom${roomNumber}`] &&
                        <HelpBlock className='pull-right'>
                          {this.props.errors[`initialRoom${roomNumber}`]}
                        </HelpBlock>
                      }
                    </Col>
                    <Col sm={hasSimplifiedPaymentPage ? 5 : 6} key={`surnameRoom${roomNumber}`} className={this.props.errors[`surnameRoom${roomNumber}`] ? 'has-error' : null}>
                      <FormControl
                        id={`surnameRoom${roomNumber}`}
                        name={`surnameRoom${roomNumber}`}
                        value={this.props.billingDetails[`surnameRoom${roomNumber}`]}
                        onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                        placeholder='Last Name'
                        size='20'
                        type='text'
                        className='form-control' />
                      {this.props.errors[`surnameRoom${roomNumber}`] &&
                        <HelpBlock className='pull-right'>
                          {this.props.errors[`surnameRoom${roomNumber}`]}
                        </HelpBlock>
                      }
                    </Col>
                  </Row>
                </Col>
              </FormGroup>
            </React.Fragment>
          }

          {!withChildAges && childAgeFields.length > 0 &&
            <FormGroup>
              {room.originalName && <p className='col-xs-12 col-sm-8 col-sm-offset-3 text-muted'>{room.originalName}</p>}
              <Col componentClass={ControlLabel} sm={hasSimplifiedPaymentPage ? 4 : 3}>
                <span data-automated-test='childAge'><FormattedMessage id='payment.childAges' values={{ count: childAgeFields.length }} /></span>
              </Col>
              <Col sm={hasSimplifiedPaymentPage ? 8 : 9}>
                <Row>
                  {childAgeFields}
                </Row>
              </Col>
            </FormGroup>
          }
          {withChildAges && childAgeFields}
        </div>
      )
    })

    if (!rooms.length) {
      return null
    }

    // Not asking for a leadname and we are asking for childages
    // Only render the rooms, which in this case only have the hidden child age fields
    if (withChildAges && !this.props.hasLeadName) {
      return rooms
    }

    // Normal situation or we ask for lead name
    return (
      <fieldset>
        {!hasSimplifiedPaymentPage &&
          <FormattedMessage id='payment.roomDetails' tagName='legend' />
        }
        {this.props.hasLeadName &&
          <p>
            <FormattedMessage id='payment.provideNameAndChildAges' />
          </p>
        }
        {rooms}
      </fieldset>
    )
  }
}

PaymentFormRooms.defaultProps = {
  minChildAge: 2,
  maxChildAge: 15
}

PaymentFormRooms.propTypes = {
  errors: PropTypes.object,
  maxChildAge: PropTypes.number.isRequired,
  minChildAge: PropTypes.number.isRequired,
  onInputChange: PropTypes.func.isRequired,
  rooms: PropTypes.arrayOf(
    PropTypes.shape({
      adults: PropTypes.string.isRequired,
      agesToValidate: PropTypes.array.isRequired,
      children: PropTypes.string.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  ).isRequired
}

export default PaymentFormRooms
