import isLength from 'validator/lib/isLength'
import isEmail from 'validator/lib/isEmail'
import trim from 'validator/lib/trim'
import moment from 'moment'
import config from '../configs/config'

const { validationMessages, validationRules } = config

const validationHelpers = {}

/**
 * validateField - validates a field based on rules from config
 * @param {String} id id of field to validate
 * @param {String} value value to validate
 *
 * @return {String} error
 */
validationHelpers.validateField = (id, value) => {
  // Get rule for specific input field
  const rules = validationRules[id]

  if (rules) {
    // Check input is required and return an error message
    if (!value) {
      if (rules.required) {
        return rules.requiredMessage || validationMessages.requiredMessage
      }
      return null
    }

    // For cx cases where we need to validate a button click (boolean value) instead of input field (string)
    if (rules.required && typeof value === 'boolean') {
      return null
    }

    // Check value for email address and return message
    if (rules.email && !isEmail(value)) {
      return rules.invalidMessage
    }

    // Check value for number and length and return message
    const isLengthOptions = {
      max: rules.maxLength || undefined,
      min: rules.minLength || 0
    }

    if (rules.number) {
      // if spaces are allowed, strip them before performing the checks
      const sanitisedValue = rules.allowSpace ? value.replace(/\s/g, '') : value
      if (isNaN(sanitisedValue) || !isLength(sanitisedValue, isLengthOptions)) {
        return rules.invalidMessage || validationMessages.defaultMessage
      }
    }

    // Check date has the right format
    if (rules.date && rules.format && !moment(value, rules.format, true).isValid()) {
      return rules.invalidMessage || validationMessages.defaultMessages
    }

    // Check expiry date is this or future months
    if (rules.afterToday && rules.format && !moment(value, rules.format).isSameOrAfter(moment.now(), 'month')) {
      return rules.invalidMessage || validationMessages.defaultMessages
    }
  }

  return null
}

/**
 * validateFields - loops over fields to validate and returns an object with errors
 * @param {Object} fields Object of keys and values to validate
 *
 * @return {Object} key, value pairs of errors
 */
validationHelpers.validateFields = (fields = {}) => {
  const errors = {}

  for (var key in fields) {
    const value = fields[key]
    const error = validationHelpers.validateField(key, value)

    if (error) {
      // add to object of errors
      errors[key] = error
    }
  }
  return errors
}

/**
 * trimRequiredFields - loops over required fields to trim and validate for space and returns an object with errors
 * @param {Object} fields Object of keys and values to validate
 *
 * @return {Object} key, value pairs of errors
 */
validationHelpers.trimRequiredFields = (fields = {}) => {
  const errors = {}

  for (var key in fields) {
    const value = fields[key]

    if (trim(value).length === 0) {
      errors[key] = 'Required'
    }
  }
  return errors
}

export default validationHelpers
