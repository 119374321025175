import _ from 'lodash'

// Temporary as this file is included server side, which doesn't have `window`
if (typeof window === 'undefined') {
  global.window = {
    brandConfig: {
      secure: {}
    }
  }
}

const brandContent = _.get(window, 'brandReply.brands[0]', {})
const brandConfig = Object.assign({}, brandContent, window.brandConfig)
const discoveryPrimerConfig = _.get(window, 'discoveryPrimerConfig', {})
const discoveryApiUrl = window.discoveryApiUrl
const {
  additionalNightPackageRatesReply = {},
  additionalPackageRatesReply = {},
  agesConfiguration = {},
  endpoint = '',
  eventProduct = {},
  featuredUpgrades = {},
  harvestBasket = {},
  hotelEventProductsReply = {},
  hotelOfferCodesReply = {},
  hotelProductReply = {},
  packageRateCallbacks = [],
  packageRatesReply = {},
  roomThemeOrderingReply: roomThemeOrderReply = [],
  showBookButtonInMoreInfoSummary = {},
  upgradeCompositionRequirements = {},
  upgradeRatesReply = {},
  upgradeIsNightsBasedConfig = {},
  venueProduct = {}
} = window

const harvestBasketData = harvestBasket.data || {}

let creditCardNumberField = {}
if (brandConfig.consumerType === 'callCentre') {
  creditCardNumberField = {
    selector: '#creditCardNumber',
    type: 'password'
  }
} else {
  creditCardNumberField = {
    selector: '#creditCardNumber'
  }
}

const {
  seatsUrl = null,
  availabilityUrl = null,
  moreinformationUrl = null,
  upgradesUrl = null
} = harvestBasketData

export default {
  PayplastConfig: {
    adminCardPasswordId: 'adminCardPassword',
    adminPasswordButtonId: 'adminPasswordButton',
    apimap: {
      billingAddresses: '/billingAddresses',
      customers: '/customers',
      orders: '/orders',
      paymentClientTokens: '/paymentClientTokens',
      paymentMethods: '/paymentMethods',
      replyDataPath: 'braintree'
    },
    emailId: 'billToEmail',
    fields: {
      number: creditCardNumberField,
      cvv: {
        selector: '#securityCode'
      },
      expirationDate: {
        selector: '#cardExpiryDate',
        placeholder: 'MM/YY'
      }
    },
    styles: {
      'input': {
        'font-size': '16px',
        'color': '#555555'
      }
    },
    formDataRequestEvent: 'formDataRequestEvent',
    formDataResponseEvent: 'formDataResponseEvent',
    formId: 'paymentForm',
    paymentCompleteEvent: 'paymentComplete',
    paymentReadyEvent: 'paymentReady',
    paypalButtonId: 'paypalButton',
    submitButtonId: 'submitButton',
    tearDownEvent: 'tearDown'
  },
  additionalNightPackageRatesReply,
  additionalPackageRatesReply,
  agesConfiguration,
  brandConfig,
  childAgeControlId: 'childAge%sRoom%s',
  discoveryApiUrl,
  discoveryPrimerConfig,
  endpoint,
  eventProduct,
  featuredUpgrades,
  harvestBasket,
  harvestBasketData,
  hotelEventProductsReply,
  hotelOfferCodesReply,
  hotelProduct: _.get(hotelProductReply, 'hotelProducts[0]', {}),
  hotelReviews: _.get(hotelProductReply, 'linked.reviews', {}),
  legacyUrl: _.get(brandConfig, 'secure.legacyUrl'),
  packageRateCallbacks,
  packageRatesReply,
  roomControlIds: ['titleRoom%s', 'initialRoom%s', 'surnameRoom%s'],
  roomThemeOrderReply,
  savedUrls: {
    seats: seatsUrl,
    availability: availabilityUrl,
    moreinformation: moreinformationUrl,
    upgrades: upgradesUrl
  },
  showBookButtonInMoreInfoSummary,
  upgradeCompositionRequirements,
  upgradeRatesReply,
  upgradeIsNightsBasedConfig,
  validationMessages: {
    defaultMessage: 'Invalid',
    requiredMessage: 'Required'
  },
  validationRules: {
    adminPassword: {
      required: true
    },
    annualPassNo: {
      maxLength: 18,
      minLength: 5,
      number: true,
      required: true
    },
    billToTitle: {
      requiredMessage: 'Required',
      required: true
    },
    billToForename: {
      requiredMessage: 'Required',
      required: true
    },
    billToSurname: {
      required: true
    },
    billToAddressPostalCode: {
      required: true
    },
    billToAddressLine0: {
      required: true
    },
    billToAddressCity: {
      required: true
    },
    billToAddressCountry: {
      required: true
    },
    billToEmail: {
      email: true,
      invalidMessage: 'Please enter a valid email address',
      required: true
    },
    billToPhone: {
      maxLength: 30,
      minLength: 6,
      number: true,
      invalidMessage: 'Please enter a valid telephone number',
      required: true,
      allowSpace: true
    },
    billToRoomTitle: {
      requiredMessage: 'Required',
      required: true
    },
    billToRoomInitial: {
      requiredMessage: 'Required',
      required: true
    },
    billToRoomSurname: {
      requiredMessage: 'Required',
      required: true
    },
    cardExpiryDate: {
      requiredMessage: 'Required',
      required: true
    },
    titleRoom1: {
      required: true
    },
    titleRoom2: {
      required: true
    },
    titleRoom3: {
      required: true
    },
    initialRoom1: {
      required: true
    },
    initialRoom2: {
      required: true
    },
    initialRoom3: {
      required: true
    },
    surnameRoom1: {
      required: true
    },
    surnameRoom2: {
      required: true
    },
    surnameRoom3: {
      required: true
    },
    childAge1Room1: {
      required: true
    },
    childAge2Room1: {
      required: true
    },
    childAge3Room1: {
      required: true
    },
    childAge4Room1: {
      required: true
    },
    childAge5Room1: {
      required: true
    },
    childAge6Room1: {
      required: true
    },
    childAge7Room1: {
      required: true
    },
    childAge8Room1: {
      required: true
    },
    childAge1Room2: {
      required: true
    },
    childAge2Room2: {
      required: true
    },
    childAge3Room2: {
      required: true
    },
    childAge4Room2: {
      required: true
    },
    childAge5Room2: {
      required: true
    },
    childAge6Room2: {
      required: true
    },
    childAge7Room2: {
      required: true
    },
    childAge8Room2: {
      required: true
    },
    childAge1Room3: {
      required: true
    },
    childAge2Room3: {
      required: true
    },
    childAge3Room3: {
      required: true
    },
    childAge4Room3: {
      required: true
    },
    childAge5Room3: {
      required: true
    },
    childAge6Room3: {
      required: true
    },
    childAge7Room3: {
      required: true
    },
    childAge8Room3: {
      required: true
    },
    creditCardNumber: {
      required: true,
      invalidMessage: 'Please enter a valid creditcard number'
    },
    dtmfCardExpiryDate: {
      afterToday: true,
      date: true,
      format: 'MM/YY',
      invalidMessage: 'Invalid',
      required: true,
      requiredMessage: 'Required'
    },
    dtmfSecurityCode: {
      invalidMessage: 'Invalid',
      minLength: 3,
      number: true,
      required: true,
      requiredMessage: 'Required'
    },
    securityCode: {
      required: true,
      requiredMessage: 'Required'
    }
  },
  venueProduct
}
