import React, { useState } from 'react'
import { Modal, Row, Col } from 'react-bootstrap'
import ResponsiveImage from '../atoms/ResponsiveImage'
import dateHelpers from '../../helpers/dateHelpers'
import Select from '../atoms/Select'
import trackingHelpers from '../../helpers/trackingHelpers'

const UpgradeModalSelection = ({ title, description, upgrades, handleUpgradeCTA, handleDateSelection }) => {
  const [hideModal, setHideModal] = useState(false)

  const selectUpgrade = (selectedProductId, event) => {
    event && event.preventDefault()
    handleUpgradeCTA(selectedProductId)
    setHideModal(true)
  }

  const datesOptions = (upgrade) => {
    const availableDates = (upgrade.dates || []).map(date => ({
      text: dateHelpers.formatStandard(date),
      value: date
    }))

    if (availableDates.length) {
      return (
        <div style={{ marginTop: '10px' }}>
          <Select
            name='UpgradeDate'
            onChange={(e) => handleDateSelection(upgrade.id, e)}
            options={availableDates}
            tracking={{
              action: 'Park Entry Upgrade Date Change',
              category: 'Upgrades',
              label: upgrade.id
            }}
            value={upgrade.selectedDate}
            disabled={availableDates.length < 2}
          />
        </div>
      )
    }
  }

  const addBasketCTATracking = (upgrade) => {
    const action = 'Add Park Entry Upgrade To Cart'
    const category = 'Upgrades'
    const label = upgrade.id
    console.log('action', action, category, label)
    return trackingHelpers.getAttributes(action, category, label)
  }

  return (
    <div>
      <Modal show={!hideModal} onHide={() => setHideModal(true)}>
        <Modal.Header closeButton >
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <Row>
            <Col md={12}>
              <p dangerouslySetInnerHTML={{ __html: description }} />
            </Col>
          </Row>
          <Row>
            {upgrades.map((upgrade, index) => (
              <Col key={`upgrade_${index}`} md={(12 / upgrades.length)}>
                <ResponsiveImage
                  src={upgrade.image}
                  lazyload={false}
                />
                {datesOptions(upgrade)}
                <button
                  className='btn btn-block btn-primary'
                  style={{ marginTop: '10px' }}
                  {...addBasketCTATracking(upgrade)}
                  onClick={(e) => selectUpgrade(upgrade.selectedProductId, e)}
                  type='button'
                >
                  {`Choose ${upgrade.title} for £${upgrade.price}`}
                </button>
                {(index < (upgrades.length - 1)) &&
                  <hr className='d-md-none' />
                }
              </Col>
            ))}
          </Row>
        </Modal.Body>
      </Modal>
    </div>
  )
}

export default UpgradeModalSelection
