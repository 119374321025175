import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import ScriptLoader from 'react-script-loader-hoc'
import _ from 'lodash'

import config from '../../configs/config'
const API_KEY = _.get(config, 'brandConfig.secure.googleMapsApiKey', 'AIzaSyBspS_GeN-pnFtd-41vB77VIKTLebZkJgU')

let markerIcon = null

class GoogleMapsWrapper extends PureComponent {
  constructor (props) {
    super(props)
    this.map = null
    this.mapDom = null
  }

  componentDidUpdate () {
    // When done and no map initialised yet
    if (this.props.scriptsLoadedSuccessfully && !this.map) this.initMap()
  }

  initMap () {
    this.map = new google.maps.Map(this.mapDom, { zoom: 4 })
    markerIcon = {
      origin: new google.maps.Point(0, 0),
      anchor: new google.maps.Point(11, 40),
      scaledSize: new google.maps.Size(22, 40),
      labelOrigin: new google.maps.Point(11, -10),
      url: '//maps.gstatic.com/mapfiles/api-3/images/spotlight-poi-dotless_hdpi.png'
    }
    this.map.setOptions({
      styles: [{
        featureType: 'poi',
        stylers: [{ visibility: 'off' }]
      }]
    })
    if (this.props.fitBounds) this.fitBounds()
  }

  fitBounds () {
    const bounds = new google.maps.LatLngBounds()
    this.props.markers.forEach(item => {
      const LatLng = new google.maps.LatLng(item.lat, item.lng)
      /* eslint no-unused-vars: ["error", { "varsIgnorePattern": "marker" }] */
      const marker = new google.maps.Marker({
        map: this.map,
        position: LatLng,
        icon: markerIcon,
        label: {
          text: item.label,
          color: '#333',
          fontSize: '15px',
          fontWeight: 'bold'
        }
      })
      // Extends this bounds to contain the given point.
      bounds.extend(LatLng)
    })
    // Listener for a zoom change and perform a max zoom
    const zoomListener = google.maps.event.addListener(this.map, 'zoom_changed', () => {
      const zoom = this.map.getZoom()
      // If fitBounds() zooms in a lot, zoom out
      if (zoom > 16) this.map.setZoom(zoom - 1)
      // Remove listener so the customer can zoom in further if they want
      google.maps.event.removeListener(zoomListener)
    })
    // Set the viewport to contain the given bounds
    this.map.fitBounds(bounds)
  }

  render () {
    if (!this.props.scriptsLoadedSuccessfully) return <p>Loading Map</p>
    return (
      <div
        onClick={this.props.onClick}
        onKeyDown={(e) => e.key === 'Enter' && this.props.onClick()}
        ref={mapDom => { this.mapDom = mapDom }}
        style={{ height: 480, width: '100%' }}
        tabIndex='0'
        role='application'
      />
    )
  }
}

GoogleMapsWrapper.defaultProps = {
  onClick: () => {}
}

GoogleMapsWrapper.propTypes = {
  markers: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string.isRequired,
      lat: PropTypes.number.isRequired,
      lng: PropTypes.number.isRequired
    }).isRequired
  ).isRequired,
  onClick: PropTypes.func,
  fitBounds: PropTypes.bool
}

export default ScriptLoader(`//maps.googleapis.com/maps/api/js?key=${API_KEY}`)(GoogleMapsWrapper)
