import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import Calendar from './Calendar'

import engine from '../../../actions/engine'

class CheckOut extends PureComponent {
  constructor (props) {
    super(props)
    this.toggleVisible = this.toggleVisible.bind(this)
    this.isDayDisabled = this.isDayDisabled.bind(this)
  }

  dateAsString (date, errorString = '') {
    const asMoment = moment(date)
    return asMoment.isValid() ? asMoment.format('Do MMM YY') : errorString
  }

  toggleVisible (e) {
    if (e && e.preventDefault) e.preventDefault()
    if (e && e.stopPropagation) e.stopPropagation()
    if (!this.props.checkInDate) {
      return this.props.errorCheckIn()
    }
    this.props.toggleVisible()
  }
  isDayDisabled (day) {
    day = moment(day)
    const check = !(day.isBefore(this.props.maxCheckOutDate) && day.isAfter(this.props.checkInDate, 'day'))
    if (this.props.hasTimeslotSelectUI) {
      if (check && !(this.props.dates.all[day.format('YYYY-MM-DD')] && this.props.dates.all[day.format('YYYY-MM-DD')].timeslots.length)) {
        return true
      }
    }
    return check
  }

  render () {
    return (
      <div className={classNames({
        'searchform-checkout': true,
        'has-error': this.props.error
      })}>
        <div className='form-group'>
          <label htmlFor='searchformcheckoutbutton'>
            {this.props.label}
          </label>
          <button
            className='form-control ignore-react-onclickoutside'
            id='searchformcheckoutbutton'
            onClick={this.toggleVisible}
            onKeyDown={(e) => e.key === 'Enter' && this.toggleVisible}
            type='button'
          >
            {this.dateAsString(this.props.checkOutDate, 'Please choose')}
          </button>
          {this.props.isVisible &&
            <Calendar
              endDate={this.props.lastAvailableDate}
              isDayDisabled={this.isDayDisabled}
              onChange={this.props.change}
              onClose={this.toggleVisible}
              selected={this.props.checkOutDate}
              startDate={this.props.ticketDate}
            />
          }
          {this.props.error && <div className='help-block'>
            <FormattedMessage id='common.pleaseSelect' />
          </div>}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const {
    checkInDate: defaultCheckInDate,
    checkOutDate: defaultCheckOutDate
  } = state.engine.defaults

  const {
    checkInDate,
    checkOutDate,
    dates,
    firstAvailableDate,
    lastAvailableDate,
    maxCheckOutDate
  } = state.engine.engineStore

  return {
    dates,
    maxCheckOutDate: new Date(maxCheckOutDate),
    checkInDate: (checkInDate) ? new Date(checkInDate) : new Date(defaultCheckInDate),
    checkOutDate: (checkOutDate) ? new Date(checkOutDate) : new Date(defaultCheckOutDate),
    firstAvailableDate: new Date(firstAvailableDate),
    lastAvailableDate: new Date(lastAvailableDate),
    label: _.get(state.engine.brandConfig, 'UITextDefaults.labelCheckOut', 'Check out'),
    hasTimeslotSelectUI: _.get(state, 'engine.brandConfig.hasTimeslotSelectUI', false),
    error: state.engine.errorCheckOutDate,
    isVisible: state.engine.visibleCheckOutCalendar
  }
}

function mapDispatchToProps (dispatch) {
  return {
    errorCheckIn: () => {
      return dispatch(engine.error('checkInDate', true))
    },

    toggleVisible: () => {
      return dispatch(engine.toggle('checkOutCalendar', false))
    },

    change: checkOutDate => {
      return dispatch(engine.changeCheckOutDate(checkOutDate))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckOut)
