import _ from 'lodash'
import classNames from 'classnames'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import Select from '../../../components/atoms/EngineUI/Select'

import engineOld from '../../../actions/engineOld'

class Tickets extends PureComponent {
  render () {
    if (!this.props.hasEngineStore) return null
    return (
      <div className={classNames({
        'searchform-tickets': true,
        'has-error': this.props.error
      })}>
        <div className='form-group'>
          <Select
            id='ticket_select'
            label={this.props.label}
            onChange={this.props.change}
            options={this.props.tickets.map(ticket => {
              return {
                label: ticket.name,
                value: ticket.code
              }
            })}
            searchable={false}
            selected={this.props.selected}
          />
          {this.props.error &&
            <div className='help-block'>
              <FormattedMessage id='common.pleaseSelect' />
            </div>
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const {
    tickets,
    ticketCode
  } = state.engineOld.engineStore

  return {
    tickets,
    selected: ticketCode,
    label: _.get(state.engineOld.brandConfig, 'UITextDefaults.labelTickets', 'Choose Package'),
    hasEngineStore: state.engineOld.hasEngineStore,
    error: state.engineOld.errorTickets
  }
}

function mapDispatchToProps (dispatch) {
  return {
    change: ticketCode => {
      return dispatch(engineOld.changeTicket(ticketCode))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Tickets)
