import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import Calendar from './Calendar'

import engineOld from '../../../actions/engineOld'

class TicketDate extends PureComponent {
  dateAsString (date, errorString = '') {
    const asMoment = moment(date)
    return asMoment.isValid() ? asMoment.format('Do MMM YY') : errorString
  }

  render () {
    // @TODO: remove this brand check and instead check for a transformer flag
    // (firstDayOfWeek or similar, soon to be implemented)
    const firstDayOfWeek = this.props.brand === 'ET' ? 1 : 0
    if (!this.props.hasEngineStore) return null
    return (
      <div className={classNames({
        'searchform-ticketdate': true,
        'has-error': this.props.error
      })}>
        <div className='form-group'>
          <label htmlFor='pleaseChooseButton'>{this.props.label}</label>
          <button
            className='form-control ignore-react-onclickoutside'
            id='pleaseChooseButton'
            onClick={this.props.toggleVisible}
            onKeyDown={(e) => e.key === 'Enter' && this.props.toggleVisible}
            type='button'
          >
            {this.dateAsString(this.props.ticketDate, 'Please choose')}
          </button>
          {this.props.isVisible &&
            <Calendar
              onClose={this.props.toggleVisible}
              isDayDisabled={ticketDate => {
                return this.props.isTicketCalendarDayDisabled(ticketDate)
              }}
              endDate={this.props.lastAvailableTicketDate}
              selected={this.props.ticketDate}
              startDate={this.props.firstAvailableTicketDate}
              onChange={this.props.change}
              firstDayOfWeek={firstDayOfWeek}
            />
          }
          {this.props.error && <div className='help-block'>
            <FormattedMessage id='common.pleaseSelect' />
          </div>}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const {
    ticketDate,
    firstAvailableTicketDate,
    lastAvailableTicketDate
  } = state.engineOld.engineStore

  return {
    brand: state.brand.brand,
    error: state.engineOld.errorTicketDate,
    firstAvailableTicketDate,
    hasEngineStore: state.engineOld.hasEngineStore,
    isVisible: state.engineOld.visibleTicketCalendar,
    label: _.get(state.engineOld.brandConfig, 'UITextDefaults.labelParkDate', 'Arrival Date'),
    lastAvailableTicketDate,
    ticketDate
  }
}

function mapDispatchToProps (dispatch) {
  return {
    toggleVisible: e => {
      if (e && e.preventDefault) e.preventDefault()
      if (e && e.stopPropagation) e.stopPropagation()
      return dispatch(engineOld.toggle('ticketCalendar', false))
    },

    change: ticketDate => {
      return dispatch(engineOld.changeTicketDate(ticketDate))
    },

    // Not actually actions here but I was unsure the best way to handle this one.
    isTicketCalendarDayDisabled: date => {
      return dispatch(engineOld.isTicketCalendarDayDisabled(date, true))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(TicketDate)
