import React from 'react'
import { Col, Modal, Row } from 'react-bootstrap'
import ResponsiveImage from '../atoms/ResponsiveImage'
import dateHelpers from '../../helpers/dateHelpers'
import trackingHelpers from '../../helpers/trackingHelpers'
import { FormattedMessage } from 'react-intl'
import DisplayPrice from '../atoms/DisplayPrice'
import Select from '../atoms/Select'
import Stepper from './Stepper'
import config from '../../configs/config'
const {
  harvestBasketData
} = config

const UpgradeModal = ({ childrenMaxAge, childrenMinAge, defaultComposition, isClosable, modalIsOpen, modalClose, upgrade, row, handleUpgradeCTA, handleCompositionChange, handleDaysSelection, handleDateSelection, handleGroupUpgradeSelection }) => {
  const hasChildrenUnder3 = (harvestBasketData.ages || []).filter(age => age < 3).length > 0
  const totalPrice = upgrade.totalPrice || upgrade.price
  const CTAAmendText = upgrade.isInBasket ? 'Remove' : 'Add'
  const addBasketCTATracking = {
    action: upgrade.isInBasket ? 'Remove Attraction From Cart' : 'Add Attraction To Cart',
    category: 'Upgrades',
    label: upgrade.id
  }

  const closeModalCTATracking = {
    action: 'Close Modal',
    category: 'Upgrades',
    label: upgrade.id
  }

  const groupUpgrades = upgrade.groupUpgradeProducts || []
  const availableDays = upgrade.days ? upgrade.days.map(daysObj => {
    return {
      text: daysObj.days,
      value: daysObj.id
    }
  }) : []

  const availableDates = upgrade.dates ? upgrade.dates.map(date => {
    return {
      text: dateHelpers.formatStandard(date),
      value: date
    }
  })
    : []

  const handleCompositionIncrement = (type) => {
    return handleCompositionChange({
      field: type,
      id: upgrade.id,
      increment: true
    })
  }

  const handleCompositionDecrement = (type) => {
    return handleCompositionChange({
      field: type,
      id: upgrade.id,
      increment: false
    })
  }

  const compositionOptions = () => {
    if (upgrade.isRoomBased) return null
    if (!upgrade.composition) return null
    if (upgrade.composition.cars) {
      const trackingCars = {
        decrement: {
          action: 'Remove Cars',
          category: 'Upgrades',
          label: upgrade.id
        },
        increment: {
          action: 'Add Cars',
          category: 'Upgrades',
          label: upgrade.id
        }
      }
      return (
        <div className='col-lg-10'>
          <Stepper
            disabled={upgrade.isInBasket}
            handleDecrement={() => handleCompositionDecrement('cars')}
            handleIncrement={() => handleCompositionIncrement('cars')}
            label='Cars'
            tracking={trackingCars}
            value={upgrade.composition.cars} />
        </div>
      )
    }
    if (upgrade.composition.quantityBased) {
      const trackingQuantityBased = {
        decrement: {
          action: 'Remove Qty',
          category: 'Upgrades',
          label: upgrade.id
        },
        increment: {
          action: 'Add Qty',
          category: 'Upgrades',
          label: upgrade.id
        }
      }
      return (
        <div className='col-lg-10'>
          <Stepper
            disabled={upgrade.isInBasket}
            max={upgrade.composition.quantity >= (upgrade.compositionBoundaries.max.quantityBased || 15)}
            handleDecrement={() => handleCompositionDecrement('quantityBased')}
            handleIncrement={() => handleCompositionIncrement('quantityBased')}
            label='Quantity'
            tracking={trackingQuantityBased}
            value={upgrade.composition.quantityBased} />
        </div>
      )
    }
    if (upgrade.composition.quantity) {
      const trackingQuantity = {
        decrement: {
          action: 'Remove Quantity',
          category: 'Upgrades',
          label: upgrade.id
        },
        increment: {
          action: 'Add Quantity',
          category: 'Upgrades',
          label: upgrade.id
        }
      }
      return (
        <div className='col-lg-10'>
          <Stepper
            disabled={upgrade.isInBasket}
            handleDecrement={() => this.handleCompositionDecrement('quantity')}
            handleIncrement={() => this.handleCompositionIncrement('quantity')}
            label='Quantity'
            max={upgrade.composition.quantity >= (upgrade.compositionBoundaries.max.quantity || 15)}
            tracking={trackingQuantity}
            value={upgrade.composition.quantity} />
        </div>
      )
    }

    if (upgrade.isAdultRequired && !upgrade.composition.adults) return null
    const trackingParty = {
      adults: {
        decrement: {
          action: 'Remove Adults',
          label: upgrade.id,
          category: 'Upgrades'
        },
        increment: {
          action: 'Add Adults',
          label: upgrade.id,
          category: 'Upgrades'
        }
      },
      children: {
        decrement: {
          action: 'Remove Children',
          label: upgrade.id,
          category: 'Upgrades'
        },
        increment: {
          action: 'Add Children',
          label: upgrade.id,
          category: 'Upgrades'
        }
      }
    }

    return (
      <div className='col-lg-10'>
        <Stepper
          formattedMessage={{
            id: 'upgrades.adultsUpgradeStepperLabel',
            values: { years: childrenMaxAge + 1 }
          }}
          disabled={upgrade.isInBasket}
          handleDecrement={() => handleCompositionDecrement('adults')}
          handleIncrement={() => handleCompositionIncrement('adults')}
          tracking={trackingParty.adults}
          value={upgrade.composition.adults} />
        {/* @todo: whats this? */}
        {defaultComposition.children > 0 &&
          <Stepper
            formattedMessage={{
              id: 'upgrades.childrenUpgradeStepperLabel',
              values: { years: `${hasChildrenUnder3 ? '3' : childrenMinAge}-${childrenMaxAge}` }
            }}
            formattedMessageSubLabel={hasChildrenUnder3 && !upgrade.hideInfantsGoFreeMessage ? {
              id: 'common.under3GoFree'
            } : null}
            disabled={upgrade.isInBasket}
            handleDecrement={() => handleCompositionDecrement('children')}
            handleIncrement={() => handleCompositionIncrement('children')}
            tracking={trackingParty.children}
            value={upgrade.composition.children} />
        }
      </div>
    )
  }

  const groupOptions = () => {
    if (upgrade.isUpgradeGroup) {
      return (
        <div className='col-lg-10'>
          <Select
            disabled={upgrade.isInBasket}
            label='Upgrade Type'
            name='Upgrade Type'
            onChange={(e) => handleGroupUpgradeSelection(upgrade.id, e)}
            options={groupUpgrades}
            tracking={{
              action: 'Attraction Change',
              category: 'Upgrades',
              label: upgrade.id
            }}
            value={upgrade.id} />
        </div>
      )
    }
  }

  const daysOptions = () => {
    if (availableDays.length > 0) {
      return (
        <div className='col-lg-10'>
          <Select
            disabled={upgrade.isInBasket}
            label='Days'
            name='Days'
            onChange={(e) => handleDaysSelection(upgrade.id, e)}
            options={availableDays}
            tracking={{
              action: 'Attraction Change',
              category: 'Upgrades',
              label: upgrade.id
            }}
            value={upgrade.selectedProductId} />
        </div>
      )
    }
  }

  const datesOptions = () => {
    if (availableDates.length > 0) {
      return (
        <div className='col-lg-10'>
          <Select
            // disabled={upgrade.isInBasket}
            label='Date'
            name='UpgradeDate'
            onChange={(e) => handleDateSelection(upgrade.id, e)}
            options={availableDates}
            tracking={{
              action: 'Attraction Change',
              category: 'Upgrades',
              label: upgrade.id
            }}
            value={upgrade.selectedDate}
          />
        </div>
      )
    }
  }

  const handleUpgradeCTAClose = (selectedProductId, event) => {
    event && event.preventDefault()
    handleUpgradeCTA(selectedProductId)
    modalClose()
  }

  return (
    <div className='upgrade-modal'>
      <Modal show={modalIsOpen} onHide={() => modalClose()} >
        <Modal.Header closeButton={isClosable} >
          <Modal.Title dangerouslySetInnerHTML={{ __html: upgrade.isUpgradeGroup ? upgrade.groupTitle : upgrade.title }} />
          {/* <img src={upgrade.image} width='100%' height='50%'/> */}
        </Modal.Header>
        <Modal.Body>
          <Row>
            <div className={upgrade.isUpgradeGroup ? 'col-sm-8' : 'col-sm-12'}>
              {upgrade.isUpgradeGroup && <h3 className='h5'>{upgrade.title}</h3>}
              {/* Prismic description for the upgrade */}
              {
                <React.Fragment>
                  <Col xs={12} sm={6}>
                    <ResponsiveImage
                      defaultSize={450}
                      src={upgrade.image}
                      lazyload={false}
                    />
                  </Col>
                  <Col xs={12} sm={6}>
                    <div className='block-xs'>
                      { upgrade.additionalDescriptionCompact
                        ? <div className='small' dangerouslySetInnerHTML={{ __html: upgrade.additionalDescriptionCompact }} />
                        : <div className='small' dangerouslySetInnerHTML={{ __html: upgrade.additionalDescription }} />
                      }
                    </div>
                  </Col>
                </React.Fragment>
              }
              {upgrade.isUpgradeGroup &&
              <div className='col-sm-4'>
                <ResponsiveImage
                  className={!row ? 'center-block' : ''}
                  src={upgrade.image}
                  lazyload={false}
                />
              </div>
              }
            </div>
          </Row>
          <hr />
          <Row>
            {/* Decides whether to show party composition or car selection */}
            {/* Hide the group option for the swappable tickets except for the season passes */}
            {(!upgrade.isSwappableTicket || upgrade.isSeasonPass) && groupOptions()}
            {!upgrade.isSwappableTicket &&
            <React.Fragment>
              {compositionOptions()}
              {daysOptions()}
              {datesOptions()}
            </React.Fragment>
            }
          </Row>
        </Modal.Body>
        <Modal.Footer>
          <Row>
            {totalPrice > 0 &&
            <div className='col-xs-12'>
              <FormattedMessage id='common.totalPrice' />:&nbsp;
              <span className='h3'><DisplayPrice price={totalPrice} /></span>
            </div>
            }
            <div className='col-xs-6 col-sm-4'>
              {isClosable &&
              <button
                className='btn btn-block btn-default'
                {...trackingHelpers.getAttributes(closeModalCTATracking.action, closeModalCTATracking.category, closeModalCTATracking.label)}
                onClick={() => modalClose()}
                onKeyDown={(e) => e.key === 'Enter' && modalClose()}
                type='button'
              >
                <FormattedMessage id='common.close' />
              </button>
              }
            </div>
            <div className='col-xs-6 col-sm-4 col-sm-offset-4'>
              <button
                className='btn btn-block btn-primary'
                {...trackingHelpers.getAttributes(addBasketCTATracking.action, addBasketCTATracking.category, addBasketCTATracking.label)}
                onClick={(e) => handleUpgradeCTAClose(upgrade.selectedProductId, e)}
                onKeyDown={(e) => e.key === 'Enter' && handleUpgradeCTAClose(upgrade.selectedProductId, e)}
                type='button'
              >
                {CTAAmendText}
              </button>
            </div>
          </Row>
        </Modal.Footer>
      </Modal>
    </div>
  )
}

export default UpgradeModal
