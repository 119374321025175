import React from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { FormattedMessage } from 'react-intl'
import classNames from 'classnames'
import Select from '../../../components/atoms/EngineUI/Select'
import moment from 'moment'

function Timeslots () {
  const dispatch = useDispatch()
  const {
    dates,
    ticketDate,
    selectedTimeslot,
    errorTimeslot
  } = useSelector(state => {
    return {
      errorTimeslot: state.engine.errorTimeslot,
      dates: state.engine.engineStore.dates,
      ticketDate: state.engine.engineStore.ticketDate,
      selectedTimeslot: state.engine.engineStore.timeslot
    }
  })
  if (!dates) return null

  const timeslotsValues = (dates.all[moment(ticketDate).format('YYYY-MM-DD')].timeslots || []).map(timeslot => ({
    value: timeslot.time,
    label: moment(timeslot.time, 'HHmm').format('HH:mm')
  }))

  if (!timeslotsValues.length) return null

  function handleChange (timeslot) {
    dispatch({
      type: 'ENGINE_CHANGE_TIMESLOT',
      timeslot
    })
  }
  return (
    <div className={classNames({
      'searchform-tickets': true,
      'has-error': errorTimeslot
    })}>
      <div className='form-group'>
        <Select
          id='ticket_select'
          label={'Time slot'}
          onChange={handleChange}
          options={timeslotsValues}
          searchable={false}
          selected={selectedTimeslot}
        />
        {errorTimeslot &&
          <div className='help-block'>
            <FormattedMessage id='common.pleaseSelect' />
          </div>
        }
      </div>
    </div>
  )
}

export default Timeslots
