import React from 'react'
import { useSelector } from 'react-redux'
import _ from 'lodash'

import CheckIn from './UI/CheckIn'
import CheckOut from './UI/CheckOut'
import Form from './UI/Form'
import PartyComp from './UI/PartyComp'
import Rooms from './UI/Rooms'
import TicketDate from './UI/TicketDate'

const CheckInCheckOut = ({
  inline
}) => {
  const hasTicketDateCalendar = useSelector(state => _.get(state, 'brand.secure.hasFeatures.hasTicketDateCalendar', false))
  const hasParkEntry = useSelector(state => state.engineOld.hasParkEntry)
  return (
    <Form
      inline={inline}
      hasHotelDate={false}
    >
      <div>
        <CheckIn />
        <CheckOut />
        {hasTicketDateCalendar && hasParkEntry && <TicketDate />}
        <PartyComp />
        <Rooms />
      </div>
    </Form>
  )
}

CheckInCheckOut.defaultProps = {
  inline: false
}

export default CheckInCheckOut
