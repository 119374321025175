import moment from 'moment'
import PropTypes from 'prop-types'
import React, { useCallback, useEffect, useRef, useState } from 'react'
import { Col, Collapse, Modal } from 'react-bootstrap'
import { FormattedHTMLMessage, FormattedMessage } from 'react-intl'
import { AutoAffix } from 'react-overlays'
import { connect } from 'react-redux'

import DisplayPrice from '../atoms/DisplayPrice'
import SVGCalendar from '../atoms/SVGCalendar'
import SVGHotel from '../atoms/SVGHotel'
import SVGTick from '../atoms/SVGTick'
import { query } from '../atoms/BreakPoint'

import Footer from './Basket/Footer'

import trackingHelpers from '../../helpers/trackingHelpers'

import {
  getTotalAmountToPay,
  getTotalRedemptionValue
} from '../../selectors/payment'

const BookingSummaryModal = ({
  content,
  onHide,
  show,
  title
}) => {
  return (
    <Modal
      onHide={onHide}
      show={show}
    >
      <Modal.Header closeButton>
        <Modal.Title>
          {title}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div dangerouslySetInnerHTML={{ __html: content }} />
      </Modal.Body>
    </Modal>
  )
}

const BookingSummarySection = ({
  checkInOutDetails,
  customBody,
  formatted,
  hasMoreInfoLink,
  handleRemoveUpgrade,
  heading,
  moreInfo,
  moreInfoModalContent,
  moreInfoModalTitle,
  price,
  roomDescriptions,
  showPrice,
  roomName,
  svg,
  upgrade
}) => {
  const [showModal, setShowModal] = useState(false)

  return (
    <div className='clearfix'>
      <div className='media'>
        <div className='media-left'>
          {svg}
        </div>

        <div className='media-body'>
          {showPrice &&
            <div className='m-0 media-heading pull-right'>
              <span className='text-uppercase'>
                <DisplayPrice price={price || 0} />
              </span>
            </div>
          }

          {customBody}

          {heading &&
            <React.Fragment>
              {hasMoreInfoLink && moreInfoModalContent ? (
                <React.Fragment>
                  <a
                    className='media-heading text-decoration-underline'
                    {...trackingHelpers.getAttributes('open', 'booking-summary-modal', `${moreInfoModalTitle}`)}
                    onClick={() => setShowModal(!showModal)}
                    role='button'>
                    {heading}
                  </a>
                  <BookingSummaryModal
                    content={moreInfoModalContent}
                    onHide={() => setShowModal(!showModal)}
                    show={showModal}
                    title={moreInfoModalTitle}
                  />
                </React.Fragment>
              ) : (
                <div className='m-0 mb-2 media-heading'>
                  {heading}
                </div>
              )}
            </React.Fragment>
          }

          {(roomDescriptions || []).map(({ description, party }, index) => {
            return (
              <p className='tiny mb-0 text-muted' key={index}>
                {roomName || description} {' - '} {party}
              </p>
            )
          })}

          {checkInOutDetails &&
            <React.Fragment>
              <p className='tiny mt-2 mb-2'>
                <FormattedMessage id='basket.checkIn' values={checkInOutDetails} />
              </p>
              <p className='tiny mb-0'>
                <FormattedMessage id='basket.checkOut' values={checkInOutDetails} />
              </p>
            </React.Fragment>
          }

          {moreInfo &&
            <div className='tiny mb-0 mt-2'>
              {formatted
                ? moreInfo
                : <div dangerouslySetInnerHTML={{ __html: moreInfo }} />
              }
            </div>
          }
        </div>

        {upgrade && handleRemoveUpgrade &&
          <div className='media-right'>
            <a
              className='tiny'
              onClick={(e) => handleRemoveUpgrade(upgrade.id, e)}
              onKeyDown={(e) => e.key === 'Enter' && handleRemoveUpgrade(upgrade.id, e)}>
              <FormattedMessage id='common.remove' />
            </a>
          </div>
        }
      </div>
    </div>
  )
}

const BookingSummary = ({
  amendSearchButton,
  bestPricePromise,
  cancellationWaiver = {},
  cancellationWaiverPrice,
  cateringDescription,
  cateringDetails,
  checkInDate,
  checkInTime,
  checkOutDate,
  checkOutTime,
  ctaButton,
  discountMessage,
  endpoint,
  forceCollapse = false,
  grossPrice,
  handleRemoveUpgrade,
  hasCancellationProtectionMessage = false,
  hasFixedSummary = false,
  hasInitialBasketCollapsed = false,
  hasParkEntry = true,
  hasYourStay = false,
  hotelName,
  parkDate,
  postalConfirmation = {},
  reducedInfo,
  roomDescriptions,
  roomName,
  showRoomsFromPrice,
  showSectionPrices,
  standardPrice,
  ticketAdditionalDetails,
  ticketDuration,
  ticketName,
  totalOrderValue,
  totalAmountToPay = 0,
  totalRedemptionValue,
  upgrades,
  vouchers,
  wasText
}) => {
  const [showModal, setShowModal] = useState(false)
  const [isSummaryElementExpanded, setSummaryElementExpanded] = useState(hasInitialBasketCollapsed)
  const panelRef = useRef(null)

  // Collapse summary in and out
  const handleSummaryCollapseToggle = useCallback(() => {
    setSummaryElementExpanded(!isSummaryElementExpanded)
    const action = isSummaryElementExpanded ? 'Collapse' : 'Expand'
    trackingHelpers.track('sb.track', action, 'Booking Summary', endpoint)
  }, [isSummaryElementExpanded])

  // Scroll handler
  useEffect(() => {
    const scrollHandler = () => {
      if (!hasFixedSummary || endpoint === 'moreinformation') return

      // close the summary on scroll when fixed)
      if (query.isXs() && isSummaryElementExpanded && window.innerHeight > panelRef.current.clientHeight) {
        handleSummaryCollapseToggle()
      }

      // open the summary if screen is larger than Xs
      if (!query.isXs() && !isSummaryElementExpanded) {
        handleSummaryCollapseToggle()
      }
    }
    const options = {
      'leading': true,
      'trailing': false
    }
    window.addEventListener('scroll', scrollHandler, options)
    return () => window.removeEventListener('scroll', scrollHandler, options)
  })

  const checkInOutDetails = {
    checkInDate: checkInDate && checkInDate.format('Do MMMM YYYY'),
    checkInTime: checkInTime && moment(checkInTime, 'HH:mm').format(checkInTime.substring(3, 5) === '00' ? 'ha' : 'h:mma'),
    checkOutDate: checkOutDate && checkOutDate.format('Do MMMM YYYY'),
    checkOutTime: checkOutTime && moment(checkOutTime, 'HH:mm').format(checkOutTime.substring(3, 5) === '00' ? 'ha' : 'h:mma')
  }
  const collapseIn = forceCollapse || query.isSm() || (isSummaryElementExpanded && query.isXs())
  const postalPrice = (postalConfirmation && postalConfirmation.grossPrice && parseFloat(postalConfirmation.grossPrice)) || 0
  const upgradesPrice = (Array.isArray(upgrades) && upgrades.reduce((total, upgrade) => total + parseFloat(upgrade.price), 0)) || 0
  const waiverPrice = (cancellationWaiver && cancellationWaiver.grossPrice && parseFloat(cancellationWaiver.grossPrice)) || 0

  // This is just used on Paultons, other brands don't show this price
  const hotelTicketPrice = Number(grossPrice) - Number(upgradesPrice) - Number(waiverPrice) - Number(postalPrice)

  // Show the 'from' price, but not on More Information, as that shows the 'from' room prices.
  const showStandardPrice = !showRoomsFromPrice && Number(standardPrice) > 0 && (standardPrice !== grossPrice)

  // showRoomsFromPrice is only used on the  More Information page
  let summaryTotalFromId = reducedInfo && showRoomsFromPrice ? 'common.from' : 'common.totalPrice'
  if (totalRedemptionValue > 0) summaryTotalFromId = 'payment.leftToPay'

  const ticketNameIncludesBreakfast = (ticketName || '').toLowerCase().includes('breakfast')
  let cateringMoreInfo = null

  // Without a link to open modal
  if (cateringDescription && !(ticketNameIncludesBreakfast && hasParkEntry)) {
    cateringMoreInfo = <span className='text-muted'><FormattedMessage id='common.breakfast' /></span>
  }

  // With link to open modal
  if (cateringDescription && cateringDetails) {
    cateringMoreInfo = <p className='mt-2 mb-0'>
      <a
        className='text-decoration-underline'
        {...trackingHelpers.getAttributes('open', 'booking-summary-modal', 'Catering Information')}
        onClick={() => setShowModal(!showModal)}
        role='button'>
        <span className='tiny pr-4'>
          <FormattedMessage id='common.breakfast' />
        </span>
      </a>
      <BookingSummaryModal
        content={cateringDetails}
        onHide={() => setShowModal(!showModal)}
        show={showModal}
        title={cateringDescription}
      />
    </p>
  }

  return (
    <div className='affix-container basket-summary basket-summary-fixed mb-sm-4'>
      <AutoAffix
        affixClassName='summary-affix'
        bottomClassName='summary-affix'
        container={document.getElementById('appRoot')}
      >
        <div className='panel panel-default my-0' ref={panelRef}>
          <div
            aria-controls='summaryCollapse'
            aria-expanded
            className={`panel-heading`}
            onClick={() => !forceCollapse && handleSummaryCollapseToggle()}
            onKeyPress={e => !forceCollapse && e.key === 'Enter' && handleSummaryCollapseToggle()}
            tabIndex='-1'
            role='button'>
            <h2 className='h3 mb-0 mt-0'>
              <FormattedMessage id='common.bookingSummary' />
              {query.isXs() ? isSummaryElementExpanded ? <span className='i-plus pull-right'>-</span> : <span className='i-plus pull-right'>+</span> : '' }
            </h2>
            <span className='small m-0 visible-xs'>
              {totalRedemptionValue
                ? <React.Fragment>
                  <p className='mb-0'>
                    <FormattedMessage
                      id='payment.vouchersApplied'
                      values={{
                        amount: totalRedemptionValue
                      }}
                    />
                  </p>
                  <FormattedMessage id='payment.totalPrice' />:&nbsp;
                  <s>
                    <DisplayPrice price={totalOrderValue} />
                  </s>
                  <DisplayPrice price={totalAmountToPay} showZeroAsPrice />
                </React.Fragment>
                : <React.Fragment>
                  <FormattedMessage id='payment.totalPrice' />:&nbsp;
                  <DisplayPrice price={grossPrice} />
                </React.Fragment>
              }
            </span>
          </div>
          <Collapse in={collapseIn}>
            <div
              className={`collapse collapse-non-md collapse-non-lg ${isSummaryElementExpanded ? 'in' : ''}`}
              id='summaryCollapse'>
              <div className='panel-body pb-0 pt-0'>
                <div className='clearfix mb-3'>
                  {discountMessage && <span className='label label-info'>{discountMessage}</span>}
                </div>

                <BookingSummarySection
                  checkInOutDetails={hasYourStay && checkInOutDetails}
                  formatted
                  heading={hotelName}
                  moreInfo={cateringMoreInfo}
                  price={hotelTicketPrice}
                  roomDescriptions={roomDescriptions}
                  roomName={roomName}
                  showPrice={showSectionPrices}
                  svg={<SVGHotel height={30} width={30} viewBox='0 0 512 512' />}
                />

                <hr />

                {!hasYourStay &&
                <React.Fragment>
                  <BookingSummarySection
                    customBody={
                      <div className='row'>
                        <div className='col-xs-6'>
                          <div className='media-heading'><FormattedMessage id='common.checkin' /></div>
                          <div className='text-muted tiny'>{checkInDate && checkInDate.format('ddd D MMM YY')}</div>
                          <div className='text-muted tiny'>from {checkInTime}</div>
                        </div>
                        <div className='col-xs-6'>
                          <div className='media-heading'><FormattedMessage id='common.checkout' /></div>
                          <div className='text-muted tiny'>{checkOutDate && checkOutDate.format('ddd D MMM YY')}</div>
                          <div className='text-muted tiny'>by {checkOutTime}</div>
                        </div>
                      </div>
                    }
                    showPrice={showSectionPrices}
                    svg={<SVGCalendar height={30} width={30} viewBox='0 0 58 58' />}
                  />
                  <hr />
                </React.Fragment>
                }

                {hasParkEntry &&
                <React.Fragment>
                  <BookingSummarySection
                    formatted
                    heading={ticketName}
                    hasMoreInfoLink
                    moreInfo={<p className='text-muted'>
                      <FormattedMessage
                        id='common.parkEntryForConsecutiveDays'
                        values={{
                          parkDate: parkDate && moment(parkDate).format('ddd D MMM YY'),
                          ticketDuration
                        }} />
                    </p>}
                    moreInfoModalContent={ticketAdditionalDetails || ''}
                    moreInfoModalTitle={ticketName}
                    showPrice={showSectionPrices}
                    svg={<SVGCalendar height={30} width={30} viewBox='0 0 58 58' />}
                  />
                  <hr />
                </React.Fragment>
                }

                {(upgrades || []).map(upgrade => {
                  return (
                    <React.Fragment>
                      <BookingSummarySection
                        formatted
                        heading={upgrade.title}
                        handleRemoveUpgrade={!upgrade.isIncludedInPackage && handleRemoveUpgrade}
                        hasMoreInfoLink
                        moreInfo={
                          // This value can either be boolean or string (because harvest basket)
                          <span>{/true/.test(upgrade.showUpgradeDate) && upgrade.selectedDate && moment(upgrade.selectedDate).format('ddd D MMM YY')}</span>
                        }
                        moreInfoModalContent={`${upgrade.description || ''}${upgrade.additionalDescription || ''}`}
                        moreInfoModalTitle={upgrade.title}
                        price={parseFloat(upgrade.price)}
                        showPrice={showSectionPrices}
                        svg={<SVGTick height={30} width={30} viewBox='0 0 85 85' />}
                        upgrade={upgrade}
                      />
                      <hr />
                    </React.Fragment>
                  )
                })}

                {cancellationWaiver && cancellationWaiver.grossPrice &&
                <React.Fragment>
                  <BookingSummarySection
                    heading={<FormattedMessage id='common.cancellationWaiver' />}
                    price={waiverPrice}
                    showPrice={showSectionPrices}
                    svg={<SVGTick height={30} width={30} viewBox='0 0 85 85' />} />
                  <hr />
                </React.Fragment>
                }

                {postalConfirmation && postalConfirmation.grossPrice &&
                <React.Fragment>
                  <BookingSummarySection
                    heading={<FormattedMessage id='common.postalConfirmation' />}
                    price={postalPrice}
                    showPrice={showSectionPrices}
                    svg={<SVGTick height={30} width={30} viewBox='0 0 85 85' />}
                  />
                  <hr />
                </React.Fragment>
                }

                <div className='media m-0 pb-3'>

                  {totalRedemptionValue > 0 &&
                  <React.Fragment>
                    <div className='small mb-2'>
                      <FormattedMessage id='common.total' />
                      <span className='pull-right'>
                        <DisplayPrice price={totalOrderValue} />
                      </span>
                    </div>

                    {vouchers.map(voucher =>
                      <div className='small label-primary py-1 px-2 mb-2'>
                        <FormattedHTMLMessage
                          id='payment.voucherHasBeenApplied'
                          values={{
                            voucherCode: voucher.voucherCode
                          }}
                        />
                        <span className='pull-right'>
                                -<DisplayPrice price={voucher.redemptionValue} />
                        </span>
                      </div>
                    )}
                  </React.Fragment>
                  }

                  {amendSearchButton &&
                  <React.Fragment>
                    {amendSearchButton}
                    <hr />
                  </React.Fragment>
                  }

                  <div className='text-right'>
                    {showStandardPrice && standardPrice && wasText &&
                    <div className='mt-0 mb-2 text-capitalize'>
                      {wasText} <s><DisplayPrice price={standardPrice} /></s>
                    </div>
                    }
                    <FormattedMessage id={summaryTotalFromId} />&nbsp;
                    <span className='h2 d-block d-sm-inline m-0'>
                      <DisplayPrice price={grossPrice >= 0 ? grossPrice : 0} showZeroAsPrice />
                    </span>
                  </div>

                  <hr />

                  {ctaButton &&
                  <div className='clearfix'>
                    {ctaButton}
                  </div>
                  }

                  {!cancellationWaiver.grossPrice && hasCancellationProtectionMessage && cancellationWaiverPrice &&
                  <React.Fragment>
                    <div className='small text-center text-success mt-3'>
                      <Col xs={12} className='d-inline-block float-none col-lg-10 col-sm-12 col-xs-9'>
                        <FormattedMessage
                          id='basket.protectYourBooking'
                          tagName='strong'
                          values={{ cancellationWaiverPrice }}
                        />
                      </Col>
                    </div>
                  </React.Fragment>
                  }
                </div>
              </div>

              {bestPricePromise &&
              <Footer
                bestPriceGuaranteeModal={bestPricePromise && bestPricePromise[0]}
                hasFixedSummary={hasFixedSummary}
                reducedInfo={reducedInfo}
              />
              }
            </div>
          </Collapse>
        </div>
      </AutoAffix>
    </div>
  )
}

BookingSummary.propTypes = {
  amendSearchButton: PropTypes.element,
  bestPricePromise: PropTypes.object,
  cancellationWaiver: PropTypes.object,
  cancellationWaiverPrice: PropTypes.string.isRequired,
  cateringDescription: PropTypes.string.isRequired,
  cateringDetails: PropTypes.string.isRequired,
  checkInDate: PropTypes.object.isRequired,
  checkInTime: PropTypes.string.isRequired,
  checkOutDate: PropTypes.object.isRequired,
  checkOutTime: PropTypes.string.isRequired,
  ctaButton: PropTypes.element,
  discountMessage: PropTypes.string,
  endpoint: PropTypes.string,
  forceCollapse: PropTypes.bool,
  grossPrice: PropTypes.number.isRequired,
  handleRemoveUpgrade: PropTypes.func,
  hasCancellationProtectionMessage: PropTypes.bool,
  hasFixedSummary: PropTypes.bool,
  hasInitialBasketCollapsed: PropTypes.bool,
  hasYourStay: PropTypes.bool,
  hotelName: PropTypes.string.isRequired,
  parkDate: PropTypes.object,
  postalConfirmation: PropTypes.object,
  reducedInfo: PropTypes.bool,
  roomDescriptions: PropTypes.array.isRequired,
  roomName: PropTypes.string,
  showRoomsFromPrice: PropTypes.bool,
  showSectionPrices: PropTypes.bool,
  standardPrice: PropTypes.number,
  ticketAdditionalDetails: PropTypes.string.isRequired,
  ticketDuration: PropTypes.number,
  ticketName: PropTypes.string.isRequired,
  totalAmountToPay: PropTypes.number.isRequired,
  totalOrderValue: PropTypes.number.isRequired,
  totalRedemptionValue: PropTypes.number,
  upgrades: PropTypes.array,
  vouchers: PropTypes.array,
  wasText: PropTypes.element
}

function mapStateToProps (state, ownProps) {
  const {
    grossPrice
  } = (ownProps.isPaymentStage && state.payment) || ownProps
  return {
    parkDate: state.searchSummary.parkDate,
    ticketDuration: state.searchSummary.ticketDuration,
    totalAmountToPay: getTotalAmountToPay(state),
    totalOrderValue: grossPrice,
    totalRedemptionValue: getTotalRedemptionValue(state),
    vouchers: state.vouchers.vouchers || []
  }
}

export default connect(mapStateToProps)(BookingSummary)
export {
  BookingSummary,
  BookingSummarySection
}
