import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'
import moment from 'moment'

import SVGCalendar from '../../atoms/SVGCalendar'

class TicketOnlyDate extends PureComponent {
  render () {
    return (
      <div>
        {!this.props.hideBasketSVGs &&
          <div className={'col-xs-2 pl-0 pr-0'}>
            <SVGCalendar
              height='30'
              width='30'
              viewBox='0 0 58 58'
            />
          </div>
        }
        <div>
          <ul className={'list-unstyled svg col-xs-10 pl-0 mb-0'}>
            <li><FormattedMessage id='payment.attractionDate' tagName='strong' /></li>
            <li>{this.props.checkInDate.format('ddd D MMM YY')}</li>
          </ul>
        </div>
      </div>
    )
  }
}

TicketOnlyDate.propTypes = {
  checkInDate: PropTypes.instanceOf(moment).isRequired,
  hideBasketSVGs: PropTypes.bool
}

export default TicketOnlyDate
