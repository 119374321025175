import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import trackingHelpers from '../../helpers/trackingHelpers'

class EventInformation extends PureComponent {
  componentDidMount () {
    if (this.props.content) {
      // Track when we show this. The '.replace' is to remove HTML tags
      trackingHelpers.track('sb.track', 'Event Information shown', 'Event Information', `${this.props.content.replace(/<[^>]*>?/gm, '')}`)
    }
  }

  render () {
    if (!this.props.content) return null

    return (
      <div className={`alert alert-info ${this.props.alertInfoMobileClassName}`}>
        {this.props.stickyAlert &&
          <button type='button' class='close' data-dismiss='alert' aria-label='Close' onClick={e => trackingHelpers.track('sb.track', 'Sticky alert close', 'availability', this.props.stickyAlert)}>
            <span aria-hidden='true'>&times;</span>
          </button>
        }
        <div dangerouslySetInnerHTML={{ __html: this.props.content }} />
      </div>
    )
  }
}

EventInformation.propTypes = {
  content: PropTypes.string.isRequired
}

export default EventInformation
