import React from 'react'
import PropTypes from 'prop-types'

const Select = (props) => (
  <div>
    <label className={props.hideLabel ? 'sr-only' : ''} htmlFor={props.id}>
      {props.label}
    </label>
    <select id={props.id}
      onChange={e => props.onChange(e.target.value)}
      className='form-control'
      value={props.selected}
    >
      {/* We only want to restrict the user from changing this if clearable is FALSE and they have already had an opportunity to select */}
      {(props.clearable || !props.selected) && <option value=''>{props.placeholder}</option>}
      {props.options.map(option => {
        return (
          <option
            key={`select_${option.value}`}
            value={option.value}>
            {option.label}
          </option>
        )
      })}
    </select>
  </div>
)

Select.propTypes = {
  clearable: PropTypes.bool,
  hideLabel: PropTypes.bool.isRequired,
  id: PropTypes.string.isRequired,
  label: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  options: PropTypes.arrayOf(PropTypes.shape({
    label: PropTypes.string,
    value: PropTypes.string
  })).isRequired,
  placeholder: PropTypes.string.isRequired,
  selected: PropTypes.string,
  value: PropTypes.string
}

Select.defaultProps = {
  clearable: true,
  hideLabel: false,
  placeholder: 'Select...',
  selected: ''
}

export default Select
