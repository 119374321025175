import React, { useEffect, useState } from 'react'
import Upgrade from '../molecules/Upgrade'
import UpgradeV2 from '../molecules/UpgradeV2'
import ExtraNightsBlock from './ExtraNightsBlock'
import { FormattedMessage } from 'react-intl'
import UpgradeModal from '../molecules/UpgradeModal'
import SplitTest from '../../containers/SplitTest'
import UpgradeModalSelection from '../molecules/UpgradeModalSelection'
import EventInformation from '../atoms/EventInformation'
import config from '../../configs/config'
import trackingHelpers from '../../helpers/trackingHelpers'
import UpgradeModalTiled from '../molecules/UpgradeModalTiled'

const UpgradeList = ({ childrenMaxAge, childrenMinAge, defaultComposition, extraNightButtonSmallSize, extraNightMessage, extraNights, featuredEventUpgradesTitle, handleCompositionChange, handleDateSelection, handleDaysSelection, handleExtraNightCTA,
  handleGroupUpgradeSelection, handleUpgradeCTA, hotelCode, hotelImage, hotelName, isExtraNightsVisible, listLayout, modalState, upgrades, upgradeTitleChange, wasText, warwickBreakfastAddons }) => {
  const [showStandardModal, setShowStandardModal] = useState(false)
  const [showForceUpgradeModal, setShowForceUpgradeModal] = useState(false)
  const [modalUpgradeToShow, setModalUpgradeToShow] = useState('')
  const [stopForceModal, setStopForceModal] = useState(false)
  const [isChessoGrotto, setIsChessoGrotto] = useState(false)

  const { brandConfig } = config

  useEffect(() => {
    const { forceUpgradeModal } = modalState
    if (modalState.forceUpgradeModal && !stopForceModal && modalState.forceUpgradeModalType.some(element => element.startsWith('GROUPCHESSINGTONUPGRADEGROTTO')) &&
      upgrades.some(upgrade => upgrade.group.startsWith('GROUPCHESSINGTONUPGRADEGROTTO'))) {
      setIsChessoGrotto(true)
    }

    if (forceUpgradeModal && !isChessoGrotto) {
      setShowForceUpgradeModal(forceUpgradeModal)
    }
  }, [isChessoGrotto, showForceUpgradeModal])

  const handleModalOpen = (selectedUpgrade = '', standardModal = false) => {
    setModalUpgradeToShow(selectedUpgrade)
    setShowStandardModal(standardModal)
  }

  const handleModalClose = () => {
    setModalUpgradeToShow('')
    setShowStandardModal(false)
    setShowForceUpgradeModal(false)
    setStopForceModal(true)
  }

  const maxFeaturedUpgrades = 4
  const enabledUpgrades = upgrades.filter(upgrade => !upgrade.isIncludedInPackage && !upgrade.isDisabled)

  useEffect(() => {
    trackingHelpers.track('sb.track', 'Search results shown addons', 'Search Results', enabledUpgrades.length)
    window.tracker && window.tracker.track('search_results', {
      products: enabledUpgrades.map((upgrade, position) => {
        const { id, price } = upgrade
        position++

        return {
          code: id,
          price: Math.trunc(price),
          original_position: position,
          display_position: position,
          merch_position: position,
          sales_currency: 'GBP'
        }
      })
    })
  }, [])

  const templatedUpgrades = enabledUpgrades.map(upgrade => {
    return (
      <div className='product-item' key={`upgrade${upgrade.group}`}>
        <UpgradeV2
          row
          upgrade={upgrade}
          childrenMaxAge={childrenMaxAge}
          childrenMinAge={childrenMinAge}
          handleUpgradeCTA={handleUpgradeCTA}
          modalOpen={handleModalOpen}
          modalClose={handleModalClose}
          defaultComposition={defaultComposition}
          handleDateSelection={handleDateSelection}
          handleDaysSelection={handleDaysSelection}
          handleCompositionChange={handleCompositionChange}
          modalIsOpen={showStandardModal && modalUpgradeToShow === upgrade.group}
          handleGroupUpgradeSelection={handleGroupUpgradeSelection}
          wasText={wasText}
        />
      </div>
    )
  })

  // if we have more featured upgrades than the maxFeaturedUpgrades then they need to put at the top of the standard list
  const droppedOffFeatured = enabledUpgrades.filter(upgrade => upgrade.featured).splice(maxFeaturedUpgrades)
  const standardUpgrades = droppedOffFeatured.concat(enabledUpgrades.filter(upgrade => !upgrade.featured)).map(upgrade => {
    return (
      <li className='list-group-item upgrades__upgrade' key={`upgrade${upgrade.group}`}>
        <Upgrade
          childrenMaxAge={childrenMaxAge}
          childrenMinAge={childrenMinAge}
          canCloseModal
          defaultComposition={defaultComposition}
          handleCompositionChange={handleCompositionChange}
          handleDateSelection={handleDateSelection}
          handleDaysSelection={handleDaysSelection}
          handleGroupUpgradeSelection={handleGroupUpgradeSelection}
          handleUpgradeCTA={handleUpgradeCTA}
          modalClose={handleModalClose}
          modalOpen={handleModalOpen}
          modalIsOpen={showStandardModal && modalUpgradeToShow === upgrade.group}
          row
          upgrade={upgrade}
          wasText={wasText}
        />
      </li>
    )
  })

  let featuredUpgradesTitle
  if (featuredEventUpgradesTitle) {
    featuredUpgradesTitle = <h2>{featuredEventUpgradesTitle}</h2>
  } else if (brandConfig.featuredUpgradesTitle) {
    featuredUpgradesTitle = <h2>{brandConfig.featuredUpgradesTitle}</h2>
  } else if ((warwickBreakfastAddons || []).length > 1) {
    featuredUpgradesTitle = <FormattedMessage id='common.includedInYourBreak' tagName='h2' />
  } else if (upgradeTitleChange) {
    featuredUpgradesTitle = <FormattedMessage id='common.upgradeTitle' tagName='h2' />
  } else {
    featuredUpgradesTitle = <FormattedMessage id='common.recommendedForYourBreak' tagName='h2' />
  }

  const featuredUpgrades = enabledUpgrades.filter(upgrade => upgrade.featured).slice(0, maxFeaturedUpgrades).map(upgrade => {
    return (
      <li key={`upgrade${upgrade.group}`}>
        <Upgrade
          childrenMaxAge={childrenMaxAge}
          childrenMinAge={childrenMinAge}
          canCloseModal
          defaultComposition={defaultComposition}
          handleCompositionChange={handleCompositionChange}
          handleDateSelection={handleDateSelection}
          handleDaysSelection={handleDaysSelection}
          handleGroupUpgradeSelection={handleGroupUpgradeSelection}
          handleUpgradeCTA={handleUpgradeCTA}
          modalClose={handleModalClose}
          modalOpen={handleModalOpen}
          modalIsOpen={showStandardModal && modalUpgradeToShow === upgrade.group}
          row={false}
          upgrade={upgrade}
          wasText={wasText}
        />
      </li>
    )
  })

  const renderForceUpgradeModal = () => {
    if (isChessoGrotto) {
      return (
        <UpgradeModalSelection
          description='Your package includes a visit to see Father Christmas. Choose your timeslot now to avoid disappointment'
          title='Pick Your Father Christmas Grotto Slot'
          upgradeOne='GROUPCHESSINGTONUPGRADEGROTTOEVENING'
          upgradeTwo='GROUPCHESSINGTONUPGRADEGROTTOMORNING'
          openModal={handleModalOpen}
        />
      )
    }

    if (modalState.forceTiledUpgradeModalFeatures) {
      const enabledUpgrades = upgrades.filter(upgrade =>
        modalState.forceTiledUpgradeModalFeatures.upgradeGroups.some(upgradeGroup =>
          upgrade.groupProducts &&
          upgrade.groupProducts.some(groupProduct => groupProduct.id === upgradeGroup)))
      if (!enabledUpgrades.length) return
      const groupProduct = enabledUpgrades[0].groupProducts.find(product =>
        modalState.forceTiledUpgradeModalFeatures.upgradeGroups.some(upgradeGroup => upgradeGroup === product.id))

      if (modalState.forceTiledUpgradeModalFeatures.splitTest) {
        return (
          <SplitTest.Experiment name='SIOP-161:ParkEntryUpsell'>
            <SplitTest.Variant name='show_original' />
            <SplitTest.Variant name='show_alternative'>
              <UpgradeModalTiled
                title={groupProduct.title}
                description={groupProduct.groupDescription}
                upgrades={enabledUpgrades}
                handleUpgradeCTA={handleUpgradeCTA}
                handleDateSelection={handleDateSelection}
              />
            </SplitTest.Variant>
          </SplitTest.Experiment>
        )
      } else {
        return (
          <UpgradeModalTiled
            title={groupProduct.title}
            description={groupProduct.groupDescription}
            upgrades={enabledUpgrades}
            handleUpgradeCTA={handleUpgradeCTA}
            handleDateSelection={handleDateSelection}
          />
        )
      }
    }

    const getUpgrade = upgrades.filter(upgrade => modalState.forceUpgradeModalType.find(element => element === upgrade.group))[0] || {}
    return (
      <UpgradeModal
        childrenMaxAge={childrenMaxAge}
        childrenMinAge={childrenMinAge}
        defaultComposition={defaultComposition}
        handleCompositionChange={handleCompositionChange}
        handleDateSelection={handleDateSelection}
        handleDaysSelection={handleDaysSelection}
        handleGroupUpgradeSelection={handleGroupUpgradeSelection}
        handleUpgradeCTA={handleUpgradeCTA}
        isClosable={modalState.forceUpgradeModalIsClosable}
        modalClose={handleModalClose}
        modalIsOpen={!showStandardModal && modalState.forceUpgradeModalType.some(element => element === getUpgrade.group)}
        row={false}
        upgrade={getUpgrade}
      />
    )
  }

  return (
    <div className='upgrades row'>
      <div className='col-xs-12'>
        {showForceUpgradeModal && !stopForceModal && renderForceUpgradeModal()}

        {listLayout && templatedUpgrades.length > 0 &&
          <div>
            <FormattedMessage id='common.addMoreFunToYourBreak' tagName='h2' />
            <div className='product-list product-list-divided product-list-secondary'>
              {templatedUpgrades}
            </div>
          </div>
        }
        {!listLayout &&
          <React.Fragment>
            {featuredUpgrades.length > 0 &&
              <div>
                {featuredUpgradesTitle}
                <ul className='featured list-unstyled'>
                  {featuredUpgrades}
                </ul>
              </div>
            }
            {standardUpgrades.length > 0 &&
              <div>
                <FormattedMessage id='common.allAvailableUpgrades' tagName='h2' />
                <ul className='list-group upgrades'>
                  {standardUpgrades}
                </ul>
              </div>
            }
          </React.Fragment>
        }
      </div>
      {isExtraNightsVisible && (extraNights.before.isAvailable || extraNights.after.isAvailable) &&
        <div className='col-xs-12'>
          {!listLayout && <FormattedMessage id='common.addExtraNight' tagName='h2' />}
          {extraNightMessage && <EventInformation content={extraNightMessage} />}
          <ExtraNightsBlock
            hotelCode={hotelCode}
            hotelImage={hotelImage}
            hotelName={hotelName}
            extraNights={extraNights}
            handleExtraNightCTA={handleExtraNightCTA}
            extraNightButtonSmallSize={extraNightButtonSmallSize}
            listLayout={listLayout}
          />
        </div>
      }
    </div>
  )
}

export default UpgradeList
