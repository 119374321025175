import _ from 'lodash'
import PropTypes from 'prop-types'
import React, { Component } from 'react'
import { Col, ControlLabel, FormControl, FormGroup, HelpBlock, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'

import config from '../../configs/config'

import generalHelpers from '../../helpers/generalHelpers'
import trackingHelpers from '../../helpers/trackingHelpers'

import titleOptionValues from '../../configs/titleOptionValues.json'

/**
 * NameInput - The actual component
 *
 * @param {Object} props the props
 * @return {JSX} the rendered component
 */
class NameInput extends Component {
  shouldComponentUpdate (nextProps) {
    return !_.isEqual(nextProps, this.props)
  }

  render () {
    // Get <option>s for the title dropdown
    const titleOptions = generalHelpers.getOptions(titleOptionValues, 'title', 'Title')
    const hasSimplifiedPaymentPage = _.get(config, 'brandConfig.secure.hasFeatures.payment.hasSimplifiedPaymentPage', false)

    return (
      <React.Fragment>

        <FormGroup>
          {/* Lead Name Label */}
          <Col
            className={`${(this.props.errors.billToTitle || this.props.errors.billToForename || this.props.errors.billToSurname) && 'has-error'}`}
            componentClass={ControlLabel}
            htmlFor='billToTitle'
            sm={4}>
            <FormattedMessage id='common.leadName' data-automated-test='paymentName' />
          </Col>

          {/* Field */}

          {/* Version 1 */}
          { hasSimplifiedPaymentPage
            ? <Col sm={8}>
              <Row>
                <Col sm={3} md={3} lg={3} className={this.props.errors.billToTitle ? 'has-error' : ''}>
                  <ControlLabel htmlFor='billToTitle' srOnly>
                    <FormattedMessage id='common.title' />
                  </ControlLabel>

                  <FormControl
                    componentClass='select'
                    {...trackingHelpers.getAttributes('title', 'payment', 'terms and conditions modal')}
                    id='billToTitle'
                    name='bill_to_title'
                    onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                    value={this.props.billToTitle}>
                    {titleOptions}
                  </FormControl>

                  {this.props.errors.billToTitle &&
                  <HelpBlock className='pull-right'>
                    {this.props.errors.billToTitle}
                  </HelpBlock>
                  }
                </Col>

                <Col sm={3} md={3} className={`gutter-top-xs pl-sm-0 ${this.props.errors.billToForename ? 'has-error' : ''}`}>
                  <ControlLabel htmlFor='billToForename' srOnly>
                    <FormattedMessage id='common.initial' />
                  </ControlLabel>

                  <FormControl
                    {...trackingHelpers.getAttributes('first name', 'payment', '1')}
                    id='billToForename'
                    name='bill_to_forename'
                    onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                    placeholder='Initial'
                    type='text'
                    value={this.props.billToForename}
                    autoCapitalize='characters'
                    maxLength={2}
                  />

                  {this.props.errors.billToForename &&
                  <HelpBlock className='pull-right'>
                    {this.props.errors.billToForename}
                  </HelpBlock>
                  }
                </Col>

                <Col sm={6} md={5} lg={5} className={`gutter-top-xs pl-sm-0 ${this.props.errors.billToSurname ? 'has-error' : ''}`}>
                  <ControlLabel htmlFor='billToSurname' srOnly>
                    <FormattedMessage id='common.lastName' />
                  </ControlLabel>

                  <FormControl
                    {...trackingHelpers.getAttributes('last name', 'payment', '1')}
                    id='billToSurname'
                    name='bill_to_surname'
                    onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                    placeholder='Last Name'
                    type='text'
                    value={this.props.billToSurname} />

                  {this.props.errors.billToSurname &&
                  <HelpBlock className='pull-right'>
                    {this.props.errors.billToSurname}
                  </HelpBlock>
                  }
                </Col>
              </Row>
            </Col>
            : <Col sm={6}>
              <Row style={{ marginBottom: '20px' }}>
                <Col xs={6} sm={6} md={6} lg={6} className={this.props.errors.billToTitle ? 'has-error' : ''}>
                  <ControlLabel htmlFor='billToTitle' srOnly>
                    <FormattedMessage id='common.title' />
                  </ControlLabel>
                  <FormControl
                    componentClass='select'
                    {...trackingHelpers.getAttributes('title', 'payment', 'terms and conditions modal')}
                    id='billToTitle'
                    name='bill_to_title'
                    onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                    value={this.props.billToTitle}>
                    {titleOptions}
                  </FormControl>
                  {this.props.errors.billToTitle &&
                  <HelpBlock className='pull-right'>
                    {this.props.errors.billToTitle}
                  </HelpBlock>
                  }
                </Col>
                <Col xs={6} sm={6} md={6} lg={6} className={this.props.errors.billToForename ? 'has-error' : ''}>
                  <ControlLabel htmlFor='billToForename' srOnly>
                    <FormattedMessage id='common.initial' />
                  </ControlLabel>
                  <FormControl
                    {...trackingHelpers.getAttributes('first name', 'payment', '1')}
                    id='billToForename'
                    name='bill_to_forename'
                    onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                    placeholder='Initial'
                    type='text'
                    value={this.props.billToForename}
                    autoCapitalize='characters'
                    maxLength={2}
                  />
                  {this.props.errors.billToForename &&
                  <HelpBlock className='pull-right'>
                    {this.props.errors.billToForename}
                  </HelpBlock>
                  }
                </Col>
              </Row>
              <Row>
                <Col sm={12} md={12} lg={12} className={this.props.errors.billToSurname ? 'has-error' : ''}>
                  <ControlLabel htmlFor='billToSurname' srOnly>
                    <FormattedMessage id='common.lastName' />
                  </ControlLabel>
                  <FormControl
                    {...trackingHelpers.getAttributes('last name', 'payment', '1')}
                    id='billToSurname'
                    name='bill_to_surname'
                    onChange={(e) => this.props.onInputChange(e.target.id, e.target.value)}
                    placeholder='Last Name'
                    type='text'
                    value={this.props.billToSurname} />

                  {this.props.errors.billToSurname &&
                  <HelpBlock className='pull-right'>
                    {this.props.errors.billToSurname}
                  </HelpBlock>
                  }
                </Col>
              </Row>
            </Col>
          }
        </FormGroup>
      </React.Fragment>
    )
  }
}

NameInput.propTypes = {
  billToForename: PropTypes.string,
  billToSurname: PropTypes.string,
  billToTitle: PropTypes.string,
  errors: PropTypes.object,
  onInputChange: PropTypes.func.isRequired
}

export default NameInput
