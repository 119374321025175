import _ from 'lodash'
import classNames from 'classnames'
import LazyLoad from 'react-lazyload'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import { connect } from 'react-redux'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'
import Modal from 'react-bootstrap/lib/Modal'
import { emitter } from '@marvelapp/react-ab-test'

import { query } from '../atoms/BreakPoint'
import DisplayPrice from '../atoms/DisplayPrice'
import GoogleMapsWrapper from '../atoms/GoogleMapsWrapper'
import HotelLocation from '../atoms/HotelLocation'
import PriceFinderButton from '../atoms/PriceFinderButton'
import SVGCallCentre from '../atoms/SVGCallCentre'
import SVGCamera from '../atoms/SVGCamera'
import SVGCheck from '../atoms/SVGCheck'
import SVGMap from '../atoms/SVGMap'
import SVGStar from '../atoms/SVGStar'
import SVGYouTube from '../atoms/SVGYouTube'
import SVGSpecialOffer from '../atoms/SVGSpecialOffer'

import EventInformation from '../atoms/EventInformation'

import SplitTest from '../../containers/SplitTest'
import TrackingLink from '../../containers/TrackingLink'
import CommonFacilities from './CommonFacilities'

import basketHelpers from '../../helpers/basketHelpers'
import mapHelpers from '../../helpers/mapHelpers'
import routeHelpers from '../../helpers/routeHelpers'
import trackingHelpers from '../../helpers/trackingHelpers'

import modal from '../../actions/modal'
import tracking from '../../actions/tracking'

import Carousel from './Carousel'

import config from '../../configs/config'
import roomTypes from '../../configs/roomTypes'

const {
  brandConfig,
  packageRatesReply
} = config
const hasStickyHotelAlert = _.get(config, 'brandConfig.secure.hasFeatures.availability.hasStickyHotelAlert', false)
const hasExtraNightsSplit = _.get(config, 'brandConfig.secure.hasFeatures.availability.hasExtraNightsSplit', false)
const venueProducts = _.get(packageRatesReply, 'linked.venueProducts', {})
const venueProduct = venueProducts[Object.keys(venueProducts)[0]]

class Hotel extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      showGoogleMapModal: false,
      showHotelImagesModal: false,
      showHotelMoreInfoModal: false,
      hideStickyDiscoveryAlert: false
    }

    // If we're a room, or not a resort hotel,
    // we don't need to open the slidey drawer
    let bookingParams = {}
    if (props.isResort && !props.hasMoreInformationStage) {
      bookingParams = {
        isExtraNight: props.isExtraNight,
        isResort: true,
        name: props.name,
        showRoomSelector: true
      }
      if (props.isRoom) {
        bookingParams.roomId = props.roomId
      } else {
        bookingParams.doChangePage = false
      }
    }

    // Brands with extranight needs to send an extra parameter
    if (props.isExtraNight) {
      bookingParams.isExtraNight = true
    }

    this.cancellationWaiverAmount = _.get(config, 'brandConfig.cancellationWaiverAmount', null)
    this.hasEnhancedHotelTile = _.get(config, 'brandConfig.secure.hasFeatures.availability.hasEnhancedHotelTile', false)
    this.showUSPs = _.get(config, 'brandConfig.secure.hasFeatures.availability.showUSPs', false)
    this.showSpecialOffer = _.get(config, 'brandConfig.secure.hasFeatures.availability.showSpecialOffer', false)
    this.hasCancellationProtectionMessage = _.get(config, 'brandConfig.secure.hasFeatures.hasCancellationProtectionMessage', false)
    this.hasPackageDescription = _.get(config, 'brandConfig.secure.hasFeatures.availability.hasPackageDescription', false)
    this.hasHotelImagesCarousel = _.get(config, 'brandConfig.secure.hasFeatures.availability.hasHotelImagesCarousel', false)
    this.hasTrackingLinks = _.get(config, 'brandConfig.secure.hasFeatures.hasTrackingLinks', false)
    this.hasGoogleMap = _.get(config, 'brandConfig.secure.hasFeatures.availability.hasGoogleMap', false)
    this.hasPriceFinder = _.get(config, 'brandConfig.secure.hasFeatures.availability.hasPriceFinder', false)
    this.useDealFinderSplitTest = _.get(config, 'brandConfig.secure.hasFeatures.availability.useDealFinderSplitTest', false)
    this.hasHotelMoreInfoModal = _.get(config, 'brandConfig.secure.hasFeatures.availability.hasHotelMoreInfoModal', false)
    this.hasHotelMoreInfoModalPaultonPeppa = _.get(config, 'brandConfig.secure.hasFeatures.availability.hasHotelMoreInfoModalPaultonPeppa', false)
    this.hasMatchingHotelAnchor = window.location.hash.includes(props.hotel.id)
    this.moreInfoBelowBookButton = _.get(config, 'brandConfig.secure.hasFeatures.availability.moreInfoBelowBookButton', false)
    this.showEventDateInclusionsOnHotelTile = _.get(config, 'brandConfig.secure.hasFeatures.availability.showEventDateInclusionsOnHotelTile', false)
    this.displayHotelText = this.displayHotelText.bind(this)
    this.getUrgencyMessage = this.getUrgencyMessage.bind(this)
    // Book now acts different when the brand has a More Information stage and the hotel is a resort hotel
    this.handleBookNow = this.handleAddProduct.bind(this, bookingParams)
    this.handleShowModal = this.handleShowModal.bind(this)
    this.toggleGoogleMapModal = this.toggleGoogleMapModal.bind(this)
    this.toggleHotelImagesModal = this.toggleHotelImagesModal.bind(this)
    this.toggleHotelMoreInfoModal = this.toggleHotelMoreInfoModal.bind(this)
    this.hotelImageOnClick = this.hotelImageOnClick.bind(this)
    this.displayMoreInfoButton = this.displayMoreInfoButton.bind(this)
    this.getHotelLocationComponent = this.getHotelLocationComponent.bind(this)

    // Brands that have the moreinformation stage have all their buttons go to this stage
    if (props.hasMoreInformationStage) {
      if ((this.props.hasFeatures.offsiteHotelMoreInfoButton && !this.props.isResort) || (this.props.hasFeatures.resortHotelMoreInfoButton && this.props.isResort)) {
        const bookNowParams = Object.assign({}, bookingParams, {
          skipStage: 'moreinformation'
        })
        this.handleBookNow = this.handleAddProduct.bind(this, bookNowParams)
      }
      this.handleMoreInfo = this.handleAddProduct.bind(this, bookingParams)
      this.handleMoreInfoModalBookNow = this.handleAddProduct.bind(this, { moreInfoModalBookNow: true })
      this.handleShowReviews = this.handleAddProduct.bind(this, bookingParams, {
        hashString: 'review-block'
      })
      this.handleShowVideo = this.handleAddProduct.bind(this, bookingParams, {
        openVideoModal: true
      })
      // Other brands first open the moreinformation modal
    } else {
      if ((!this.props.hasFeatures.offsiteHotelMoreInfoButton && !this.props.isResort) || (!this.props.hasFeatures.resortHotelMoreInfoButton && this.props.isResort)) {
        this.handleBookNow = this.handleShowModal.bind(this, false, 'hotel')
      }
      this.handleMoreInfo = this.handleShowModal.bind(this, false, 'hotel')
      this.handleShowReviews = this.handleShowModal.bind(this, false, 'reviews')
      this.handleShowVideo = this.handleShowModal.bind(this, true, 'hotel')
    }

    if (this.props.standardPrice) {
      trackingHelpers.track('sb.track', 'strikethrough', 'availability', this.props.id)
    }
  }

  componentDidMount () {
    if (hasStickyHotelAlert && this.hasMatchingHotelAnchor) {
      setTimeout(() => {
        this.setState(() => {
          return { hideStickyDiscoveryAlert: true }
        })
      }, 5000)
    }
  }

  getUrgencyMessage () {
    const { isResort, isRoom, inventoryLevel, inventoryLevelMessages = [] } = this.props
    if (!isResort || !inventoryLevel || !Array.isArray(inventoryLevelMessages) || (isResort && isRoom)) {
      return null
    }

    let returnValue = null
    inventoryLevelMessages
      // First sort based on threshold, lowest first
      .sort((a, b) => a.lowerThreshold - b.lowerThreshold)
      .forEach(obj => {
        if (inventoryLevel >= obj.lowerThreshold) {
          returnValue = obj.message
        }
      })
    return returnValue
  }

  handleAddProduct (params, routeOptions = null, isExtraNight) {
    // First make sure we confirm stuff
    if (this.props.isCotConfirmationRequired && this.props.hotelInfants > 0) {
      const cotRequestedText = this.props.intl.formatMessage({ id: 'availability.confirmCotCustomer' }, { telephone: this.props.telephone })
      if (!confirm(cotRequestedText)) return
    }
    if (typeof isExtraNight === 'boolean') return this.props.handleAddProduct(this.props.extraNightData, params, routeOptions)
    return this.props.handleAddProduct(this.props.hotel, params, routeOptions)
  }

  handleShowModal (autoplay = false, tabIndex, event) {
    event && event.stopPropagation()
    const modalId = this.props.isRoom ? this.props.roomId : this.props.id
    this.props.handleSetProductModal(modalId, {
      tab: tabIndex,
      autoplay
    })
    // Set hashstring for tracking
    routeHelpers.setHash(`modal-productInformation-${this.props.id}`)
  }

  displayHotelText (productTitle, newProductTitle) {
    const hotelTextClassNames = classNames('block-sm', {
      'price-finder-hotel-text': !this.props.isResort
    })
    if (this.hasEnhancedHotelTile) {
      return (
        <div
          {...trackingHelpers.getAttributes('Package Info', 'Hotel Element', this.props.id)}
          className={hotelTextClassNames}
        >
          <div className='tiny small-lg product-price-title'>
            {newProductTitle}
          </div>
        </div>
      )
    }
    return (
      <p
        {...trackingHelpers.getAttributes('Package Info', 'Hotel Element', this.props.id)}
        className='tiny-xs'
      >
        {productTitle}
      </p>
    )
  }

  displayMoreInfoButton () {
    const moreInfoClassNames = classNames('btn btn-block no-arrow', {
      'btn-default': this.props.brand === 'HP',
      'btn-secondary': this.props.brand !== 'HP' && !this.moreInfoBelowBookButton,
      'btn-sm btn-muted': this.moreInfoBelowBookButton
    })
    return (
      <button
        className={moreInfoClassNames}
        {...trackingHelpers.getAttributes(this.props.featuredHotel ? 'More Info - Link - Featured' : 'More Info - Link', 'Hotel Element', this.props.id)}
        data-product-id={this.props.id}
        onClick={this.handleMoreInfo}
        onKeyDown={(e) => e.key === 'Enter' && this.handleMoreInfo()}>
        <FormattedMessage id='common.moreInfo' />
      </button>
    )
  }

  toggleGoogleMapModal () {
    this.setState(prevState => ({
      showGoogleMapModal: !prevState.showGoogleMapModal
    }))
  }

  toggleHotelImagesModal () {
    if (!this.state.showHotelImagesModal) {
      trackingHelpers.track('sb.track', this.props.hotel.name, 'Carousel', `1/${this.props.carouselImages.length}`)
    }

    this.setState(prevState => ({
      showHotelImagesModal: !prevState.showHotelImagesModal
    }))
  }

  toggleHotelMoreInfoModal () {
    if (!this.state.showHotelMoreInfoModal) {
      trackingHelpers.track('sb.track', this.props.hotel.name, 'MoreInfoModal', `More Info Modal ${this.props.hotel.name}`)
    }

    this.setState(prevState => ({
      showHotelMoreInfoModal: !prevState.showHotelMoreInfoModal
    }))
  }

  hotelImageOnClick () {
    if (this.hasHotelImagesCarousel) return this.toggleHotelImagesModal()
    return this.props.hasVideo ? this.handleShowVideo() : this.handleMoreInfo()
  }

  getRoomType () {
    const roomTypeArray = []
    this.props.rooms.map(room => {
      const roomType = basketHelpers.getRoomType(roomTypes, room)
      roomTypeArray.push(roomType.roomShortDesc)
    })
    if (roomTypeArray.length > 1) {
      // get key/amount pair of rooms, e.g tripleRoom: 2
      const roomCount = roomTypeArray.reduce((acum, cur) => Object.assign(acum, { [cur]: (acum[cur] || 0) + 1 }), {})
      // map through object key/pair and if more then 1 items add plural
      return Object.keys(roomCount).map(room => `${roomCount[room]} ${room}${roomCount[room] > 1 ? 's' : ''}`).join(', ')
    }
    return roomTypeArray[0]
  }

  getBookNowButton () {
    if (this.props.hasExtraNight) {
      if (hasExtraNightsSplit) {
        return (
          <SplitTest.Experiment name='SIOP-146:extraNights'>
            <SplitTest.Variant name='show_original'>
              <Button
                block
                bsStyle='primary'
                className={classNames('no-arrow', { 'book-btn': this.props.hasExtraNight })}
                {...trackingHelpers.getAttributes((this.props.featuredHotel ? 'Add to Basket - Featured' : 'Add to Basket'), 'Hotel Element', this.props.id)}
                data-product-id={this.props.id}
                onClick={this.handleBookNow}
                onKeyDown={(e) => e.key === 'Enter' && this.handleBookNow()}>
                <FormattedMessage id='common.bookNow' />
              </Button>
            </SplitTest.Variant>
            <SplitTest.Variant name='show_alternative'>
              <Button
                block
                bsStyle='primary'
                className={classNames('no-arrow', { 'text-transform-none book-btn': this.props.hasExtraNight })}
                {...trackingHelpers.getAttributes((this.props.featuredHotel ? 'Add to Basket - Featured' : 'Add to Basket'), 'Hotel Element', this.props.id)}
                data-product-id={this.props.id}
                onClick={this.handleBookNow}
                onKeyDown={(e) => e.key === 'Enter' && this.handleBookNow()}>
                <FormattedMessage id={`${this.props.nights === 1 ? 'common.bookOneNight' : 'common.bookNights'}`} values={{ nights: this.props.nights }} />
              </Button>
            </SplitTest.Variant>
          </SplitTest.Experiment>
        )
      } else {
        return (
          <Button
            block
            bsStyle='primary'
            className={classNames('no-arrow', { 'text-transform-none book-btn': this.props.hasExtraNight })}
            {...trackingHelpers.getAttributes((this.props.featuredHotel ? 'Add to Basket - Featured' : 'Add to Basket'), 'Hotel Element', this.props.id)}
            data-product-id={this.props.id}
            onClick={this.handleBookNow}
            onKeyDown={(e) => e.key === 'Enter' && this.handleBookNow()}>
            <FormattedMessage id={`${this.props.nights === 1 ? 'common.bookOneNight' : 'common.bookNights'}`} values={{ nights: this.props.nights }} />
          </Button>
        )
      }
    }

    return (
      <Button
        block
        bsStyle='primary'
        className={classNames('no-arrow', { 'text-transform-none book-btn': this.props.hasExtraNight })}
        {...trackingHelpers.getAttributes((this.props.featuredHotel ? 'Add to Basket - Featured' : 'Add to Basket'), 'Hotel Element', this.props.id)}
        data-product-id={this.props.id}
        onClick={this.handleBookNow}
        onKeyDown={(e) => e.key === 'Enter' && this.handleBookNow()}>
        <FormattedMessage id='common.bookNow' />
      </Button>
    )
  }

  getPriceFinderButton (hasExtraNightBookButton) {
    // Price finder button is rendered on 2 places in the markup depending on extra night split test conditions
    const priceFinderButton = (
      <PriceFinderButton
        data-automated-test='priceFinderButton'
        hasParkEntry={this.props.hasParkEntry}
        hotel={this.props.hotel}
        ghostPackage={this.props.ghostPackage}
      />
    )

    const renderDealFinderButton = this.useDealFinderSplitTest
      ? (
        <SplitTest.Experiment name='SIOP-160:DealFinder'>
          <SplitTest.Variant name='show_original' />
          <SplitTest.Variant name='show_alternative'>
            {priceFinderButton}
          </SplitTest.Variant>
        </SplitTest.Experiment>
      )
      : priceFinderButton

    if (!hasExtraNightBookButton) {
      if (this.props.hasExtraNight) {
        if (hasExtraNightsSplit) {
          return (
            <SplitTest.Experiment name='SIOP-146:extraNights'>
              <SplitTest.Variant name='show_original'>
                {renderDealFinderButton}
              </SplitTest.Variant>
              <SplitTest.Variant name='show_alternative' />
            </SplitTest.Experiment>
          )
        }
        return null
      }
      return renderDealFinderButton
    }

    if (hasExtraNightBookButton) {
      if (this.props.hasExtraNight) {
        if (hasExtraNightsSplit) {
          return (
            <SplitTest.Experiment name='SIOP-146:extraNights'>
              <SplitTest.Variant name='show_original'>
                <React.Fragment />
              </SplitTest.Variant>
              <SplitTest.Variant name='show_alternative'>
                <div className='col-xs-12 text-center'>
                  {renderDealFinderButton}
                </div>
              </SplitTest.Variant>
            </SplitTest.Experiment>
          )
        } else {
          return (
            <div className='col-xs-12 text-center'>
              {renderDealFinderButton}
            </div>
          )
        }
      }
      return null
    }
  }

  getHotelLocationComponent () {
    const mapMarkers = mapHelpers.getMapMarkers(
      brandConfig,
      packageRatesReply,
      venueProduct,
      { name: this.props.name, latitude: this.props.latitude, longitude: this.props.longitude }
    )
    const hasGoogleMap = this.hasGoogleMap && !this.props.ghostPackage
    const hotelLocationComponent = HotelLocation({
      ...this.props,
      showDistanceFromVenueName: hasGoogleMap,
      hasEnhancedHotelTile: this.hasEnhancedHotelTile,
      venueName: venueProduct.name || 'Theme Park'
    })

    if (!hotelLocationComponent) return null
    return (
      hasGoogleMap
        ? <React.Fragment>
          <button
            className='btn btn-link p-0 m-0 mb-3 multiline-button distance-to-venue'
            {...trackingHelpers.getAttributes('Availability - Distance', 'Hotel Element', this.props.id)}
            onClick={this.toggleGoogleMapModal}>
            <SVGMap
              height='1em'
              viewBox='0 0 24 24'
              width='1em' />
            {hotelLocationComponent}
          </button>
          <Modal
            onHide={this.toggleGoogleMapModal}
            show={this.state.showGoogleMapModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>
                Map
              </Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <GoogleMapsWrapper markers={mapMarkers} fitBounds />
            </Modal.Body>
            <Modal.Footer>
              <button
                className='btn btn-default'
                onClick={this.toggleGoogleMapModal}
              >
                <FormattedMessage id='common.close' />
              </button>
            </Modal.Footer>
          </Modal>
        </React.Fragment>
        : <p className='tiny-xs text-muted'>
          <SVGMap
            height='1em'
            viewBox='0 0 24 24'
            width='1em' />
          {hotelLocationComponent}
        </p>
    )
  }

  getExtraNightTitle () {
    const hasParkEntry = this.props.hasParkEntry && this.props.extraNightData && this.props.extraNightData.ticket && this.props.ticketName
    const hasBreakfast = this.props.hasBreakfastIncluded

    if (hasParkEntry && !hasBreakfast) {
      return (<FormattedMessage
        id={'availability.extraNightAndTicketTitle'}
        values={{ ticketName: this.props.ticketName }}
      />)
    }

    if (!hasParkEntry && hasBreakfast) {
      return (<FormattedMessage
        id={'availability.extraNightAndBreakfastTitle'}
      />)
    }

    if (hasParkEntry && hasBreakfast) {
      return (<FormattedMessage
        id={'availability.extraNightTicketAndBreakfastTitle'}
        values={{ ticketName: this.props.ticketName }}
      />)
    }

    return (
      <FormattedMessage
        id={'availability.extraNightCapitalTitle'}
      />
    )
  }

  getTileBookExtraNightMarkup () {
    const hotelTextClassNames = 'block-sm pt-3 pb-2'
    const btnClassNames = 'no-arrow text-transform-none book-btn'

    if (this.props.hasExtraNight) {
      if (hasExtraNightsSplit) {
        return (
          <SplitTest.Experiment name='SIOP-146:extraNights'>
            <SplitTest.Variant name='show_original'>
              <React.Fragment />
            </SplitTest.Variant>
            <SplitTest.Variant name='show_alternative'>
              <React.Fragment>
                <div className='col-xs-12 text-center'>
                  <div
                    {...trackingHelpers.getAttributes('Package Info', 'Hotel Element', this.props.hasExtraNight ? this.props.extraNightData.id : this.props.id)}
                    className={hotelTextClassNames}
                  >
                    <div className='tiny small-lg'>
                      {this.getExtraNightTitle()}
                    </div>
                  </div>
                  <div className='h1 m-0'>
                    <small className='text-muted'>{this.props.fromText} </small>
                    <DisplayPrice price={this.props.extraNightData.grossPrice} operator='ceil' />
                  </div>
                </div>
                <div className='col-xs-12 text-center'>
                  <Button
                    block
                    bsStyle='primary'
                    className={btnClassNames}
                    {...trackingHelpers.getAttributes((this.props.featuredHotel ? 'Add to Basket - Featured - Tail' : 'Add to Basket - Tail'), 'Hotel Element', this.props.extraNightData.id)}
                    data-product-id={this.props.extraNightData.id}
                    onClick={() => this.handleBookNow(null, true)}
                    onKeyDown={(e) => e.key === 'Enter' && this.handleBookNow(null, true)}>
                    <FormattedMessage id='common.bookNights' values={{ nights: this.props.extraNightData.nights }} />
                  </Button>
                </div>
              </React.Fragment>
            </SplitTest.Variant>
          </SplitTest.Experiment>
        )
      } else {
        return (
          <React.Fragment>
            <div className='col-xs-12 text-center'>
              <div
                {...trackingHelpers.getAttributes('Package Info', 'Hotel Element', this.props.hasExtraNight ? this.props.extraNightData.id : this.props.id)}
                className={hotelTextClassNames}
              >
                <div className='tiny small-lg'>
                  {this.getExtraNightTitle()}
                </div>
              </div>
              <div className='h1 m-0'>
                <small className='text-muted'>{this.props.fromText} </small>
                <DisplayPrice price={this.props.extraNightData.grossPrice} operator='ceil' />
              </div>
            </div>
            <div className='col-xs-12 text-center'>
              <Button
                block
                bsStyle='primary'
                className={btnClassNames}
                {...trackingHelpers.getAttributes((this.props.featuredHotel ? 'Add to Basket - Featured - Tail' : 'Add to Basket - Tail'), 'Hotel Element', this.props.extraNightData.id)}
                data-product-id={this.props.extraNightData.id}
                onClick={() => this.handleBookNow(null, true)}
                onKeyDown={(e) => e.key === 'Enter' && this.handleBookNow(null, true)}>
                <FormattedMessage id={`${this.props.extraNightData.nights === 1 ? 'common.bookOneNight' : 'common.bookNights'}`} values={{ nights: this.props.extraNightData.nights }} />
              </Button>
            </div>
          </React.Fragment>
        )
      }
    } else {
      return null
    }
  }

  render () {
    // Define some strings
    let packageInfo = null
    // TODO - It would be good to review these checks and utilise react-int
    let productTitle = this.props.hasParkEntry ? `${this.props.venueType} tickets` : 'For Hotel'
    if (this.props.hasTicketNameOnCTA) {
      productTitle = this.props.ticketName
    }

    // Doing some swapsies
    if (this.props.packageInfo) {
      packageInfo = productTitle
      productTitle = this.props.packageInfo
    }
    if (this.props.brand === 'ET') productTitle = 'For Hotel, Breakfast & Tickets'

    // @todo: This needs fixing as its too much tech debt for a simple solution.
    // In cases where the package is a hotel only package (in this case the title will have the & breakfast already in it)
    let newProductTitle = _.get(this.props.hotel, 'hotelEventProduct', null)
    let productTitleWithEventInclusions = null
    // If the brand is set to display the date specific inclusions for the event on the hotel tile
    if (this.showEventDateInclusionsOnHotelTile && this.props.whatsIncludedOnEventDate.length) {
      productTitleWithEventInclusions = this.props.whatsIncludedOnEventDate
    }

    let enhancedProductTitle = newProductTitle || productTitleWithEventInclusions || this.props.ticketName || productTitle

    if (this.props.hasBreakfastIncluded) {
      if (productTitle && productTitle.indexOf('Breakfast') === -1) {
        productTitle += this.props.intl.formatMessage({ id: 'basket.breakfast' })
      }
      if (!newProductTitle) {
        if (enhancedProductTitle && enhancedProductTitle.indexOf('Breakfast') === -1) {
          // If the product title is an event like Santa's Sleepover it has breakfast included already
          enhancedProductTitle += this.props.intl.formatMessage({ id: 'basket.breakfast' })
        }
      }
    }

    if (this.props.ticket.alternative_product_title) {
      productTitle = enhancedProductTitle = this.props.ticket.alternative_product_title
    }

    const ctaPrice = this.props.perPersonPrice || this.props.packageTotal
    const telephone = <strong>{this.props.telephone}</strong>
    const urgencyMessage = this.getUrgencyMessage()
    const uspClassNames = classNames('list-unstyled small product-features', {
      'hidden-xs': !this.showUSPs && (!this.props.isResort || !this.hasEnhancedHotelTile),
      'hidden-sm': !this.hasEnhancedHotelTile
    })
    const thumbContainerClassNames = classNames('col-xs-12 col-sm-6 p-0', {
      'thumb-container': !this.hasHotelImagesCarousel && !this.props.ghostPackage
    })
    const imageLinkClassNames = classNames('d-block', {
      'thumb-container': !this.hasHotelImagesCarousel || (this.hasHotelImagesCarousel && !query.isLg()) // image zoom effect
    })

    const wasText = this.props.isAnnualPass ? <FormattedMessage id='common.standardPrice' /> : <FormattedMessage id='common.was' />
    const hotelOffer = _.get(this.props.dateSpecificInformation, 'hotelTileLozenge', null)

    let lozenge = null
    if (this.props.summaryTypeMessage) lozenge = { label: this.props.summaryTypeMessage, className: 'lozenge-success' }
    if (hotelOffer) lozenge = { label: hotelOffer, className: 'lozenge-info' }
    if (this.props.hotelType) lozenge = { label: this.props.hotelType, className: 'lozenge-info' }

    const panelClassNames = classNames('panel panel-default', {
      'ghost-hotel': this.props.ghostPackage,
      'has-extra-night': this.props.hasExtraNight && (emitter.calculateActiveVariant('SIOP-146:extraNights') === 'show_alternative' || !hasExtraNightsSplit)
    })

    const trackingLinkHandler = this.props.linkToModal ? this.toggleHotelMoreInfoModal : this.handleMoreInfo

    // Remove <a></a> tags from prismic response at a glance. (as we cannot show 2 modals)
    const regex = /<a.*?>|(<\/a>)/g
    const atAGlance = (this.props.hotel_at_a_glance || '').replace(regex, '')

    return (
      <div
        id={`hotel_id_${this.props.id}`}
        key={this.props.hotel.id}
        className={panelClassNames}
      >
        {this.hasMatchingHotelAnchor && hasStickyHotelAlert &&
          <div className={`anchored-alert position-absolute ${this.state.hideStickyDiscoveryAlert ? 'fade' : ''}`}>
            <EventInformation stickyAlert={this.props.hotel.id} content={this.props.eventMessaging} />
          </div>
        }
        <span id={`${this.props.id}`} className='anchor' />
        {lozenge && lozenge.label &&
          <div className='product_media-container'>
            <div className={`lozenge ${lozenge.className} small-xs`}>
              {lozenge.label.replace(/<[^>]*>?/gm, '')}
            </div>
          </div>
        }
        {this.hasHotelImagesCarousel &&
          <Modal
            bsSize='large'
            onHide={this.toggleHotelImagesModal}
            show={this.state.showHotelImagesModal}
          >
            <Modal.Header closeButton>
              <Modal.Title>{this.props.name}</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Carousel
                buttons
                counter
                hotelName={this.props.hotel.name}
                imgs={this.props.carouselImages}
                indicators
                name={this.props.name}
                preview
                trackingCategory='Carousel'
              />
            </Modal.Body>
          </Modal>
        }
        {!this.props.isExtraNight && (
          // This check needs to be removed once the PR is approved as some changes which are wrapped in the block won't be visible
          <div className='panel-body m-0 p-0'>
            <div className='d-flex'>
              <div className='col-xs-7 col-sm-9 col-lg-10 d-sm-flex'>
                <div className='row d-sm-flex' style={{ 'flex': '1 1 auto' }}>
                  <div className={thumbContainerClassNames} style={{ 'minHeight': '150px' }}>
                    {!this.props.ghostPackage &&
                      <a
                        className={imageLinkClassNames}
                        {...trackingHelpers.getAttributes('More Info - Image', 'Hotel Element', this.props.id)}
                        data-product-id={this.props.id}
                        onClick={this.hotelImageOnClick}
                        onKeyDown={(e) => e.key === 'Enter' && (this.hotelImageOnClick())}
                        role='button'
                      >
                        {this.hasHotelImagesCarousel &&
                          <TrackingLink
                            action='Camera Icon'
                            category='Hotel Element'
                            label={this.props.id}
                            handler={this.hotelImageOnClick}
                          >
                            <span className='btn btn-info camera-button'>
                              <SVGCamera
                                height={18}
                                width={18}
                                viewBox='0 0 24 24'
                              />
                            </span>
                          </TrackingLink>
                        }
                        {this.props.hasVideo &&
                          <div className='product_media-container__youtube'>
                            <SVGYouTube
                              className='youtube-icon'
                              height={48}
                              viewBox='0 0 68 48'
                              width={68} />
                          </div>
                        }
                        <LazyLoad height={265} offset={640}>
                          <div className='bg-cover thumb fill' style={{ 'backgroundImage': `url(${this.props.image})` }} />
                          {this.hasEnhancedHotelTile &&
                            <div className='bigStars'>
                              {[...Array(+this.props.starRating || 0)].map((_, index) => <SVGStar height='1.5em' width='1.5em' key={`starRating${index}`} />)}
                            </div>
                          }
                        </LazyLoad>
                      </a>
                    }
                    {this.props.ghostPackage &&
                      <LazyLoad height={265} offset={640}>
                        <div className='bg-cover thumb fill' style={{ 'backgroundImage': `url(${this.props.image})` }} />
                        {this.hasEnhancedHotelTile &&
                          <div className='bigStars'>
                            {[...Array(+this.props.starRating || 0)].map((_, index) => <SVGStar height='1.5em' width='1.5em' key={`starRating${index}`} />)}
                          </div>
                        }
                      </LazyLoad>
                    }
                    {this.props.featuredHotel &&
                      <div className='featured-hotel-tag'>
                        <FormattedMessage id='common.featuredHotel' />
                      </div>
                    }
                  </div>
                  <div className='col-xs-12 col-sm-6 block-xs'>
                    <h3 className={`m-0 ${this.props.ghostPackage ? 'mb-2' : ''}`}>
                      {/* Hotel title, with, or without link */}
                      {(this.hasTrackingLinks || !this.props.hasMoreInformationStage) && !this.props.ghostPackage ? (
                        <a
                          {...trackingHelpers.getAttributes('Hotel Title', 'Hotel Element', this.props.id)}
                          onClick={trackingLinkHandler}
                          onKeyDown={e => e.key === 'Enter' && trackingLinkHandler()}
                          role='button'
                          tabIndex='0'
                        >
                          {this.props.name}
                        </a>
                      ) : this.props.name}
                    </h3>

                    {!this.hasEnhancedHotelTile &&
                      <p className='stars'>
                        <TrackingLink
                          action='Star Rating'
                          category='Hotel Element'
                          ghostPackage={this.props.ghostPackage}
                          label={this.props.id}
                          handler={trackingLinkHandler}
                        >
                          {[...Array(+this.props.starRating || 0)].map((_, index) => <SVGStar height='1em' width='1em' key={`starRating${index}`} />)}
                        </TrackingLink>
                      </p>
                    }

                    {/* Reviews link */}
                    {!this.props.ghostPackage && !this.props.isRoom && this.props.hasFeatures.reviews && this.props.numberOfReviews > 0 && (
                      <p className='review-link hidden-xs'>
                        <a
                          {...trackingHelpers.getAttributes('Customer Reviews', 'Hotel Element', this.props.id)}
                          onClick={this.handleShowReviews}
                          onKeyDown={(e) => e.key === 'Enter' && this.handleShowReviews()}
                          role='button'
                          tabIndex='0'
                        >
                          <span className='hoverable'>
                            <FormattedMessage id='common.customerReviews' /> ({this.props.numberOfReviews})</span>
                        </a>
                      </p>
                    )}

                    {/* Distance to park */}
                    {this.getHotelLocationComponent()}

                    {!this.props.isRoom && (
                      <React.Fragment>
                        {this.showSpecialOffer && this.props.hasSpecialOffer && (
                          <ul className='list-unstyled small m-0 p-0'>
                            <li className='media mt-0'>
                              <TrackingLink
                                action='USPs'
                                category='Hotel Element'
                                label='USP Special Offer'
                                ghostPackage={this.props.ghostPackage}
                                handler={trackingLinkHandler}
                              >
                                <div className='media-left text-success'>
                                  <SVGSpecialOffer
                                    height='1em'
                                    viewBox='0 0 24 24'
                                    width='1em'
                                    aria-hidden='true'
                                    role='presentation'
                                  />
                                </div>
                                <div className='media-body'>
                                  <p>{this.props.hasSpecialOffer}</p>
                                </div>
                              </TrackingLink>
                            </li>
                          </ul>
                        )}
                        <ul className={uspClassNames}>
                          {(this.props.uniqueSellingPoints || []).map((usp, index) => {
                            return (
                              <li
                                className='media mt-0' key={`unique-selling-point-${index}`}
                              >
                                <TrackingLink
                                  action='USPs'
                                  category='Hotel Element'
                                  label={`USP ${index + 1} - ${this.props.id}`}
                                  ghostPackage={this.props.ghostPackage}
                                  handler={trackingLinkHandler}
                                >
                                  <div className='media-left text-success'>
                                    <SVGCheck
                                      height='1em'
                                      viewBox='0 0 24 24'
                                      width='1em' />
                                  </div>
                                  <div
                                    className='media-body'
                                    dangerouslySetInnerHTML={{ __html: usp }}
                                  />
                                </TrackingLink>
                              </li>
                            )
                          })}
                        </ul>
                      </React.Fragment>
                    )}

                    {/* Wifi, Parking and Pool icons */}
                    {this.props.hasFeatures.commonFacilities &&
                      <CommonFacilities
                        charter={this.props.charter}
                        ghostPackage={this.props.ghostPackage}
                        handleMoreInfo={trackingLinkHandler}
                        hasFreeParking={this.props.hasFreeParking}
                        hasFreeWifi={this.props.hasFreeWifi}
                        hasRestaurant={this.props.hasRestaurant}
                        hasSwimmingPool={this.props.hasSwimmingPool}
                        hotelKey={this.props.id}
                        paidParkingAmount={this.props.paidParkingAmount}
                        // Conditionally passing in `isResort` as a prop
                        {...(this.hasEnhancedHotelTile ? { isResort: this.props.isResort } : null)}
                      />
                    }
                    {/* Putting Paultons & Peppa under a new feature flag, due to slight variation, in moreInfo Modal split test */}
                    {this.hasHotelMoreInfoModal &&
                      <SplitTest.Experiment name='EXP-020:AvailabilityMoreInfoModalButton'>
                        <SplitTest.Variant name='show_original' />
                        <SplitTest.Variant name='show_alternative'>
                          <React.Fragment>
                            <button
                              className='btn-link p-0 m-0 h6 text-decoration-underline '
                              onClick={this.toggleHotelMoreInfoModal}
                            ><strong>More about this hotel</strong></button>
                            <Modal
                              bsSize='large'
                              onHide={this.toggleHotelMoreInfoModal}
                              show={this.state.showHotelMoreInfoModal}
                            >
                              <Modal.Body className='hotelMoreInfoModal'>
                                {this.props.hotel.overview &&
                                  <div className='well m-2 pb-0'>
                                    <h4 className={this.hasHotelMoreInfoModalPaultonPeppa ? '' : 'modal-title'}><FormattedMessage id='availability.aboutHotel' values={{ hotelName: this.props.name }} /></h4>
                                    <div dangerouslySetInnerHTML={{ __html: this.props.hotel.overview }} />
                                    {this.props.hasFeatures.commonFacilities &&
                                      <CommonFacilities
                                        charter={this.props.charter}
                                        ghostPackage={this.props.ghostPackage}
                                        handleMoreInfo={trackingLinkHandler}
                                        hasFreeParking={this.props.hasFreeParking}
                                        hasFreeWifi={this.props.hasFreeWifi}
                                        hasRestaurant={this.props.hasRestaurant}
                                        hasSwimmingPool={this.props.hasSwimmingPool}
                                        hotelKey={this.props.id}
                                        paidParkingAmount={this.props.paidParkingAmount}
                                        // Conditionally passing in `isResort` as a prop
                                        {...(this.hasEnhancedHotelTile ? { isResort: this.props.isResort } : null)}
                                      />
                                    }
                                  </div>
                                }
                                {this.props.hotel.exclusive_benefits &&
                                  <div className='well m-2'>
                                    <h4 className={this.hasHotelMoreInfoModalPaultonPeppa ? '' : 'modal-title'}><FormattedMessage id='availability.atAGlance' /></h4>
                                    {this.props.hotel.exclusive_benefits ? <div dangerouslySetInnerHTML={{ __html: this.props.hotel.exclusive_benefits }} />
                                      : <div dangerouslySetInnerHTML={{ __html: atAGlance }} />
                                    }
                                  </div>
                                }
                                {this.props.hotel.roomInformationList &&
                                  <div className='well m-2'>
                                    <h4 className={this.hasHotelMoreInfoModalPaultonPeppa ? '' : 'modal-title'}><FormattedMessage id='availability.roomAmenities' /></h4>
                                    <div dangerouslySetInnerHTML={{ __html: this.props.hotel.roomInformationList }} />
                                  </div>
                                }
                              </Modal.Body>
                              <Modal.Footer>
                                <Row>
                                  <div className='col-xs-3 text-left'>
                                    <FormattedMessage id='common.from' />
                                    <h3 className={`'my-0 ${this.hasHotelMoreInfoModalPaultonPeppa ? '' : 'modal-title'}`}><DisplayPrice price={this.props.grossPrice} operator='ceil' /></h3>
                                  </div>

                                  <div className='col-xs-9'>
                                    <Button
                                      bsStyle='default'
                                      {...trackingHelpers.getAttributes('Hotel more-info modal close', 'Hotel Element', this.props.id)}
                                      onClick={this.toggleHotelMoreInfoModal}>
                                      <FormattedMessage id='common.close' />
                                    </Button>

                                    <Button
                                      className='no-arrow'
                                      bsStyle='primary'
                                      {...trackingHelpers.getAttributes('Hotel more-info modal book', 'Hotel Element', this.props.id)}
                                      data-product-id={this.props.id}
                                      onClick={this.handleMoreInfoModalBookNow}
                                      onKeyDown={(e) => e.key === 'Enter' && this.handleMoreInfoModalBookNow()}>
                                      <FormattedMessage id='common.bookNow' />
                                    </Button>
                                  </div>
                                </Row>
                              </Modal.Footer>
                            </Modal>
                          </React.Fragment>
                        </SplitTest.Variant>
                      </SplitTest.Experiment>
                    }
                  </div>
                </div>
              </div>
              <div className='col-xs-5 col-sm-3 col-lg-2 block-xs bg-muted d-flex'>
                <div className='row d-flex'>
                  <div className='col-xs-12 text-center'>
                    {this.props.ghostPackage &&
                      <p className='ghost-notice'>
                        <FormattedMessage id='availability.notAvailabileOnThisDate' />
                      </p>
                    }

                    {!this.props.ghostPackage && this.hasPackageDescription &&
                      <p className='small'>
                        <FormattedMessage
                          id='availability.packageDescription'
                          values={{
                            roomType: this.getRoomType(),
                            breakfastType: this.props.hotel.breakfastType,
                            ticketName: this.props.ticketName
                          }}
                        />
                      </p>
                    }

                    {!this.props.ghostPackage && this.hasEnhancedHotelTile &&
                      <React.Fragment>
                        {packageInfo && this.props.brand === 'HP' && (
                          <div className='col-xs-12 text-center'>
                            <strong>{packageInfo}</strong>
                          </div>
                        )}

                        {this.displayHotelText(productTitle, enhancedProductTitle)}
                      </React.Fragment>
                    }

                    {!this.hasEnhancedHotelTile && !this.hasPackageDescription &&
                      <React.Fragment>
                        {/* Cursed Child title is pulled in as packageInfo so we want this at the top */}
                        {packageInfo && this.props.brand === 'HP' && (
                          <div className='col-xs-12 text-center'>
                            <strong>{packageInfo}</strong>
                          </div>
                        )}

                        {this.displayHotelText(productTitle, enhancedProductTitle)}

                        {urgencyMessage && (
                          <div className='clearfix'>
                            <div className='lozenge pull-right tiny'>
                              {urgencyMessage}
                            </div>
                          </div>
                        )}
                      </React.Fragment>
                    }

                    {/* If we have an offer going on, this will be the original price and the above the discounted */}
                    {!this.props.ghostPackage &&
                      <React.Fragment>
                        {this.props.standardPrice && (
                          <p className='text-capitalize mb-0'>
                            {wasText} <s><DisplayPrice price={this.props.standardPrice} operator='ceil' /></s>
                          </p>
                        )}
                        <div className='h1 m-0'>
                          {this.props.isResort && this.props.fromText && <small className='text-muted'>{this.props.fromText} </small>}
                          <DisplayPrice price={ctaPrice} operator='ceil' />
                        </div>
                        {this.props.perPersonPrice && (
                          <div className='h1 m-0'>
                            <strong>
                              <FormattedMessage id='common.total' /> {this.props.fromText}
                              <DisplayPrice price={this.props.grossPrice} operator='ceil' />
                            </strong>
                          </div>
                        )}
                      </React.Fragment>
                    }
                  </div>

                  {packageInfo && !this.props.brand === 'HP' && (
                    <div className='col-xs-12 text-center'>
                      <strong>{packageInfo}</strong>
                    </div>
                  )}

                  <div className='col-xs-12 text-center'>
                    <div>
                      {!this.moreInfoBelowBookButton && ((this.props.hasFeatures.offsiteHotelMoreInfoButton && !this.props.isResort) || (this.props.hasFeatures.resortHotelMoreInfoButton && this.props.isResort)) && (
                        this.displayMoreInfoButton()
                      )}

                      {!this.props.ghostPackage && this.getBookNowButton()}

                      {this.props.isCotConfirmationRequired && this.props.hotelInfants > 0 && (
                        <small>
                          <SVGCallCentre
                            title='Call Centre Notification'
                            key={`Hotel${this.props.id}CotsNotification`}
                          />
                          <FormattedMessage id='availability.confirmCotCallCentre' values={{ telephone }} />
                        </small>
                      )}
                    </div>
                    {this.hasPriceFinder && this.props.isDiscoveryPrimed &&
                      this.getPriceFinderButton()
                    }
                  </div>

                  {!this.props.ghostPackage &&
                      this.getTileBookExtraNightMarkup()
                  }

                  {this.hasPriceFinder && this.props.isDiscoveryPrimed &&
                    this.getPriceFinderButton(true)
                  }

                  {this.hasEnhancedHotelTile && urgencyMessage &&
                    <div className='clearfix text-center text-danger block-xs small tiny-xs'>
                      <strong>{urgencyMessage}</strong>
                    </div>
                  }

                  {this.hasCancellationProtectionMessage && this.cancellationWaiverAmount &&
                    <div className='small text-center text-success'>
                      <Col xs={12} className='d-inline-block float-none'>
                        <FormattedMessage id='availability.protectBooking' tagName='strong' values={{ cancellationWaiverAmount: this.cancellationWaiverAmount }} />
                      </Col>
                    </div>
                  }

                  {this.moreInfoBelowBookButton && ((this.props.hasFeatures.offsiteHotelMoreInfoButton && !this.props.isResort) || (this.props.hasFeatures.resortHotelMoreInfoButton && this.props.isResort)) && (
                    <div className='col-xs-12 text-center'>
                      {this.displayMoreInfoButton()}
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
    )
  }
}

Hotel.propTypes = {
  brand: PropTypes.string.isRequired,
  carouselImages: PropTypes.array,
  handleAddProduct: PropTypes.func.isRequired,
  hotel: PropTypes.object.isRequired,
  intl: intlShape,
  isAnnualPass: PropTypes.bool,
  linkToModal: PropTypes.bool,
  whatsIncludedOnEventDate: PropTypes.string.isRequired,
  videoId: PropTypes.string
}

function mapStateToProps (state) {
  return {
    isDiscoveryPrimed: state.discovery.isPrimed,
    checkInDate: moment(state.engine.defaults.checkInDate),
    checkOutDate: moment(state.engine.defaults.checkOutDate),
    hotelPartyString: state.basket.hotelPartyString,
    rooms: state.engine.defaults.rooms,
    title: state.searchSummary.title,
    ticketDuration: state.searchSummary.ticketDuration
  }
}

function mapDispatchToProps (dispatch) {
  return {
    openDiscoveryModal: (hotelId, hasParkEntry) => {
      dispatch({
        type: 'MODAL_TOGGLE',
        modalName: 'discoveryCalendar'
      })

      dispatch({
        type: 'DISCOVERY_CALENDAR',
        hasParkEntry,
        hotelId
      })
    },
    onHideModal: (modalName) => {
      return dispatch(modal.hide(modalName))
    },
    onShowModal: (modalName) => {
      return dispatch(modal.show(modalName))
    },
    track: (action, category, label) => {
      return dispatch(tracking.track(action, category, label))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(injectIntl(Hotel))
export {
  Hotel
}
