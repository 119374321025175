const chat = {
  toggleChat: () => {
    return (dispatch) => {
      return dispatch({
        type: 'CHAT_TOGGLE'
      })
    }
  },

  minimizeChat: () => {
    return (dispatch) => {
      dispatch({
        type: 'CHAT_MINIMIZE'
      })
    }
  }
}

export default chat
