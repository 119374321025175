const modal = (state, action) => {
  state = state || {
    visible: {},
    modalContent: {}
  }

  switch (action.type) {
  // Hide the modal that has been passed as the 'action.modalName'
    case 'MODAL_HIDE':
      return Object.assign({}, state, {
        visible: Object.assign({}, state.visible, {
          [action.modalName]: false
        })
      })

      // Show the modal that has been passed as the 'action.modalName'
    case 'MODAL_SHOW':
      return Object.assign({}, state, {
        visible: Object.assign({}, state.visible, {
          [action.modalName]: true
        })
      })

      // Toggle the modal that has been passed as the 'action.modalName'
    case 'MODAL_TOGGLE':
      return Object.assign({}, state, {
        visible: Object.assign({}, state.visible, {
          [action.modalName]: !state.visible[action.modalName]
        })
      })

    default:
      return state
  }
}

export default modal
