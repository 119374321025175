import classNames from 'classnames'
import React, { useContext } from 'react'
import { Col, ControlLabel, FormGroup, HelpBlock, Row } from 'react-bootstrap'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import payment from '../../actions/payment'
import BrandContext from '../../contexts/BrandContext'

import {
  getTotalAmountToPay
} from '../../selectors/payment'

const CardFields = ({
  adminPasswordValid,
  errors = {},
  paymentCardType,
  paymentChoice,
  paymentClientToken,
  totalAmountToPay
}) => {
  const cardFieldClasses = classNames('card-fields-container', {
    'hidden': (paymentClientToken === 'dtmf' && !adminPasswordValid) || paymentChoice !== 'card' || totalAmountToPay === 0
  })
  const context = useContext(BrandContext)
  const hasSimplifiedPaymentPage = _.get(context, 'brandConfig.secure.hasFeatures.payment.hasSimplifiedPaymentPage', false)
  return (
    <div className={cardFieldClasses}>
      <FormGroup className={paymentCardType} validationState={errors.creditCardNumber ? 'error' : null}>
        <Col componentClass={ControlLabel} htmlFor='creditCardNumber' sm={hasSimplifiedPaymentPage ? 4 : 3}>
          {hasSimplifiedPaymentPage ? (
            <FormattedMessage id='payment.creditDebitCardNumber' data-automated-test='cardNumberLabel' />
          ) : (
            <FormattedMessage id='payment.cardNumber' data-automated-test='cardNumberLabel' />
          )}
        </Col>

        {hasSimplifiedPaymentPage ? (
          <Col sm={5} md={6} lg={4} className='cardField'>
            <div id='creditCardNumber' className='form-control' />
            {errors.creditCardNumber &&
              <HelpBlock className='pull-right'>
                {errors.creditCardNumber}
              </HelpBlock>
            }
          </Col>
        ) : (
          <Col sm={8} className='cardField'>
            <div id='creditCardNumber' className='form-control' />
            {errors.creditCardNumber &&
            <HelpBlock className='pull-right'>
              {errors.creditCardNumber}
            </HelpBlock>

            }
          </Col>
        )
        }
      </FormGroup>

      {/* Card Expiry Date & Card Security Code */}
      {hasSimplifiedPaymentPage ? (
        <React.Fragment>
          <FormGroup>
            <Col componentClass={ControlLabel} htmlFor='cardExpiryDate' sm={4} className={errors.cardExpiryDate ? 'has-error' : ''}>
              <FormattedMessage id='payment.expiryDate' data-automated-test='cardExpiryDate' />
            </Col>

            <Col sm={3} className={errors.cardExpiryDate ? 'has-error' : ''}>
              <div id='cardExpiryDate' className='form-control' />
              {errors.cardExpiryDate &&
                <HelpBlock className='pull-right'>
                  {errors.cardExpiryDate}
                </HelpBlock>
              }
            </Col>
          </FormGroup>

          <FormGroup>
            <Col componentClass={ControlLabel} htmlFor='securityCode' sm={4} className={`${errors.securityCode && 'has-error'}`}>
              <FormattedMessage id='payment.securityCode' data-automated-test='cardSecurityCode' />
            </Col>

            <Col sm={3} className={errors.securityCode ? 'has-error' : ''}>
              <div id='securityCode' className='form-control' />
              {errors.securityCode &&
                <HelpBlock className='pull-right'>
                  {errors.securityCode}
                </HelpBlock>
              }
            </Col>
          </FormGroup>
        </React.Fragment>
      )
        : <Row>
          <Col xs={6}>
            <FormGroup>
              <Col componentClass={ControlLabel} htmlFor='cardExpiryDate' xs={4} sm={6} md={6} className={errors.cardExpiryDate ? 'has-error' : ''}>
                <FormattedMessage id='payment.expiryDate' data-automated-test='cardExpiryDate' />
              </Col>
              <Col xs={8} sm={6} md={6} className={errors.cardExpiryDate ? 'has-error' : ''}>
                <div id='cardExpiryDate' className='form-control' />
                {errors.cardExpiryDate &&
                <HelpBlock className='pull-right'>
                  {errors.cardExpiryDate}
                </HelpBlock>
                }
              </Col>
            </FormGroup>
          </Col>
          <Col xs={6} sm={5}>
            <FormGroup>
              <Col componentClass={ControlLabel} htmlFor='securityCode' xs={5} sm={6} md={7} className={`${errors.securityCode && 'has-error'}`}>
                <FormattedMessage id='payment.securityCode' data-automated-test='cardSecurityCode' />
              </Col>
              <Col xs={7} sm={6} md={5} className={errors.securityCode ? 'has-error' : ''}>
                <div id='securityCode' className='form-control' />
                {errors.securityCode &&
                <HelpBlock className='pull-right'>
                  {errors.securityCode}
                </HelpBlock>
                }
              </Col>
            </FormGroup>
          </Col>
        </Row>
      }
    </div>
  )
}

function mapStateToProps (state) {
  const {
    adminPasswordValid,
    billingDetails = {},
    errors,
    paymentCardType = '',
    paymentClientToken
  } = state.payment
  const {
    paymentChoice
  } = billingDetails
  return {
    adminPasswordValid,
    errors,
    paymentCardType,
    paymentChoice,
    paymentClientToken,
    totalAmountToPay: getTotalAmountToPay(state)
  }
}

function mapDispatchToProps (dispatch) {
  return {
    onInputChange: (id, value) => dispatch(payment.inputChange(id, value))
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CardFields)
export {
  CardFields
}
