const _ = require('lodash')
const mapHelpers = {}

/**
 * Gets markers for the venue and hotel to use with Google Maps
 * @return {Array} An array of two objects, one with the venue lat/lng and one with the hotel lat/lng
 */
mapHelpers.getMapMarkers = (brandConfig, packageRatesReply, venueProduct, hotel) => {
  // Checks for venue products to calculate distance and get coordinates for venue (e.g. Encore Tickets - distance to theatre)
  const venueProducts = _.get(packageRatesReply, 'linked.venueProducts', {})
  const { name: venueName, latitude: venueLatitude, longitude: venueLongitude } = venueProducts[Object.keys(venueProducts)[0]] || {}
  // Get venue and hotel coordinates for google maps markers
  return [{
    label: venueName || (venueProduct && venueProduct.name),
    lat: +(venueLatitude || brandConfig.latitude || 0),
    lng: +(venueLongitude || brandConfig.longitude || 0)
  },
  {
    label: hotel.name,
    lat: +hotel.latitude || 0,
    lng: +hotel.longitude || 0
  }]
}

export default mapHelpers
