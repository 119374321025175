import _ from 'lodash'
import config from '../configs/config'

const {
  harvestBasketData
} = config

const annualPass = (state, action) => {
  state = state || {
    isAnnualPass: false
  }

  switch (action.type) {
    case 'GET_MOREINFORMATION_PAGE_DATA':
    case 'GET_PAYMENT_PAGE_DATA':
    case 'GET_UPGRADES_PAGE_DATA': {
      const isAnnualPass = _.get(harvestBasketData, 'ticket.isAnnualPass') === 'true'
      return Object.assign({}, state, {
        isAnnualPass
      })
    }

    default:
      return state
  }
}

export default annualPass
