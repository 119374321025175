import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGChat extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <path d='M20.016 15.984v-12h-16.031v14.016l2.016-2.016h14.016zM20.016 2.016c1.078 0 1.969 0.891 1.969 1.969v12c0 1.078-0.891 2.016-1.969 2.016h-14.016l-3.984 3.984v-18c0-1.078 0.891-1.969 1.969-1.969h16.031z' />
      </SVG>
    )
  }
}

SVGChat.propTypes = SVG.propTypes

SVGChat.defaultProps = {
  title: 'Phone Icon'
}

export default SVGChat
