import React from 'react'
import SummaryWrapper from '../../hoc/SummaryWrapper'

import { FormattedMessage } from 'react-intl'

import PartyComposition from '../PartyComposition'

const StaticSearchSummary = ({
  adults,
  checkinDate,
  checkoutDate,
  children,
  infants,
  isFlexibleDate,
  labelParkDate,
  labelPartyComp,
  labelRooms,
  labelTickets,
  onChange,
  parkDate,
  roomDescriptions,
  selectedItems,
  ticketTime,
  title,
  trackValues
}) => (
  <div className='summary-panel'>
    <div className='row'>
      <div className='col-lg-2 col-md-2'>
        <div className='row'>
          <div
            className='col-lg-12 col-md-12 col-xs-5'
            onClick={() => trackValues('ticket', 'click')}
            onKeyDown={(e) => e.key === 'Enter' && trackValues('ticket', 'keydown')}
            role='presentation'
            tabIndex='0'>
            <small className='text-muted'>
              {labelTickets}
            </small>
          </div>
          <div className='col-lg-12 col-md-12 col-xs-7' >
            <p className='larger m-0'>
              {title}
              {selectedItems &&
                <span className='small d-block'>
                  {selectedItems.venueName}
                </span>
              }
            </p>
          </div>
          {selectedItems &&
            <React.Fragment>
              <div className='col-lg-12 col-md-12 col-xs-5'>
                <small className='text-muted'>
                  <FormattedMessage id='common.seats' />
                </small>
              </div>
              <div className='col-lg-12 col-md-12 col-xs-7' >
                {selectedItems.seats}
              </div>
            </React.Fragment>
          }
        </div>
      </div>
      {!isFlexibleDate &&
        <div
          className='col-lg-2 col-md-2'
          onClick={() => trackValues('parkDate', 'click')}
          onKeyDown={(e) => e.key === 'Enter' && trackValues('parkDate', 'keydown')}
          role='presentation'
          tabIndex='0'>
          <div className='row'>
            <div className='col-lg-12 col-md-12 col-xs-5'>
              <small className='text-muted'>
                {labelParkDate}
              </small>
            </div>
            <div className='col-lg-12 col-md-12 col-xs-7'>
              <p className='larger m-0'>
                {parkDate && parkDate.format('Do MMMM YYYY')}
              </p>
            </div>
            {(selectedItems || ticketTime) &&
              <React.Fragment>
                <div className='col-lg-12 col-md-12 col-xs-5'>
                  <small className='text-muted'>
                    <FormattedMessage id={selectedItems ? 'common.showTime' : 'common.ticketTime'} />
                  </small>
                </div>
                <div className='col-lg-12 col-md-12 col-xs-7'>
                  <p className='larger m-0'>
                    {selectedItems ? selectedItems.timeslot : ticketTime}
                  </p>
                </div>
              </React.Fragment>
            }
          </div>
        </div>
      }
      <div className='col-lg-2 col-md-2'>
        <div
          className='row'
          onClick={() => trackValues('checkIn', 'click')}
          onKeyDown={(e) => e.key === 'Enter' && trackValues('checkIn', 'keydown')}
          role='presentation'
          tabIndex='0'>
          <div className='col-lg-12 col-md-12 col-xs-5'>
            <small className='text-muted'>
              <FormattedMessage id='common.checkinDate' />
            </small>
          </div>
          <div className='col-lg-12 col-md-12 col-xs-7'>
            <p className='larger m-0'>
              {checkinDate}
            </p>
          </div>
        </div>
        <div
          className='row'
          onClick={() => trackValues('checkOut', 'click')}
          onKeyDown={(e) => e.key === 'Enter' && trackValues('checkOut', 'click')}
          role='presentation'
          tabIndex='0'>
          <div className='col-lg-12 col-md-12 col-xs-5'>
            <small className='text-muted'>
              <FormattedMessage id='common.checkoutDate' />
            </small>
          </div>
          <div className='col-lg-12 col-md-12 col-xs-7'>
            <p className='larger m-0'>
              {checkoutDate}
            </p>
          </div>
        </div>
      </div>
      <div
        className='col-lg-2 col-md-2'
        onClick={() => trackValues('roomDescriptions', 'click')}
        onKeyDown={(e) => e.key === 'Enter' && trackValues('roomDescriptions', 'click')}
        role='presentation'
        tabIndex='0'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-xs-5'>
            <small className='text-muted'>
              {labelRooms}
            </small>
          </div>
          <div className='col-lg-12 col-md-12 col-xs-7'>
            {roomDescriptions.map((roomDescription, roomDescriptionKey) =>
              <p key={`roomDescription_${roomDescriptionKey}`} className='larger m-0'>{roomDescription}</p>
            )}
          </div>
        </div>
      </div>
      <div
        className='col-lg-2 col-md-2'
        onClick={() => trackValues('party', 'click')}
        onKeyDown={(e) => e.key === 'Enter' && trackValues('party', 'keydown')}
        role='presentation'
        tabIndex='0'>
        <div className='row'>
          <div className='col-lg-12 col-md-12 col-xs-5'>
            <small className='text-muted'>
              {labelPartyComp}
            </small>
          </div>
          <div className='col-lg-12 col-md-12 col-xs-7'>
            <p className='larger m-0'>
              <PartyComposition
                adults={adults}
                children={children}
                infants={infants}
              />
            </p>
          </div>
        </div>
      </div>
      <div
        className='col-lg-2 col-md-2'
        onClick={() => trackValues('change', 'click')}
        onKeyDown={(e) => e.key === 'Enter' && trackValues('change', 'keydown')}
        role='presentation'
        tabIndex='0'>
        <button
          className='btn btn-primary btn-sm btn-block'
          onClick={onChange}
          onKeyDown={(e) => e.key === 'Enter' && onChange()}>
          <FormattedMessage id='common.change' />
        </button>
      </div>
    </div>
  </div>
)

export default SummaryWrapper(StaticSearchSummary)
