import _ from 'lodash'
import moment from 'moment'

const sortingHelpers = {

  _groupHotelRoomsByTheme: function (hotelRoomThemes) {
    return _.reduce(hotelRoomThemes, (result, value) => {
      if (!result[value.name]) {
        result[value.name] = []
      }
      result[value.name].push(value)
      return result
    }, {})
  },

  _reduceSortedHotelRooms: function (sortedHotelRoomsByProperty) {
    return (
      _.reduce(sortedHotelRoomsByProperty, (result, value) => {
        result = _.concat(result, value)
        return result
      }, [])
    )
  },

  groupAndSortThemesByProperty: function (hotelRoomThemes, property) {
    // For each roomtheme, we need to order the themes within it by 'property'
    // Group hotelRoomThemes by themes
    let hotelRoomsGroupedByTheme = this._groupHotelRoomsByTheme(hotelRoomThemes)
    // Sort each theme by 'property' within its group
    let sortedHotelRoomsByProperty = _.forEach(hotelRoomsGroupedByTheme, theme => {
      return theme.sort(this.sortByNumericProperty(property))
    })
    // Return a flattened array structure with uniquely named hotel rooms
    return Promise.resolve(
      _.sortedUniqBy(this._reduceSortedHotelRooms(sortedHotelRoomsByProperty), 'name')
    )
  },

  sortByNumericProperty (property, ascending = true) {
    return (a, b) => {
      if (ascending) return a[property] - b[property]
      return a[property] + b[property]
    }
  },

  sortByOrderArray: function (orderArray, identifier) {
    if (!orderArray || !Array.isArray(orderArray)) {
      return () => {}
    }
    return function compare (itemOne, itemTwo) {
      const a = identifier ? itemOne[identifier] : itemOne
      const b = identifier ? itemTwo[identifier] : itemTwo
      const indexOfA = orderArray.indexOf(a)
      const indexOfB = orderArray.indexOf(b)
      if (indexOfB < 0) {
        return -1
      }
      if (indexOfA < 0) {
        return 1
      }
      return indexOfA - indexOfB
    }
  },

  sortByProperty: function (property) {
    return function compare (itemOne, itemTwo) {
      if (itemOne[property] === itemTwo[property]) {
        return 0
      }
      return (itemOne[property] > itemTwo[property]) ? 1 : -1
    }
  },

  sortHotelsAndRooms: function (packageHotels, orderArray) {
    let returnArray = []

    // Sort hotels by inventoryLevel so we get the lowest first
    packageHotels = packageHotels.sort((a, b) => a.inventoryLevel - b.inventoryLevel)

    // For each code in order array, loop through hotels and put into returnArray
    // If it can't be found in hotels, look through rooms
    // @todo this is a bit unreadable
    orderArray.forEach(code => {
      // Get hotels with this code
      let items = _.filter(

        // Filter all items where ther hotel id matches the code in the orderArray
        packageHotels, hotel => hotel.id === code ||
        // Or roomProducts that match the code in the orderArray
        (hotel.roomProducts && hotel.roomProducts.id === code))

      // Put these items in the returnArray if it has values in
      return items.length && items.forEach(item => returnArray.push(item))
    })

    // merge this array with the rest of the packageHotels
    returnArray = _.union(returnArray, packageHotels)

    // Return array with unique hotels/rooms in the correct order
    return _.sortedUniqBy(returnArray, 'id')
  },

  sortRooms: function (hotelRoomOrder, theme, roomProducts) {
    const tags = theme.tags || []

    return Promise.resolve(
      _.reduce(hotelRoomOrder, (result, hotelRoomId) => {
        _.forEach(roomProducts, roomProduct => {
          if (roomProduct.id === hotelRoomId && _.intersection(roomProduct.tags, tags).length > 0) {
            result.push(roomProduct)
          }
        })
        return result
      }, [])
    )
  },

  // @todo: I think we can replace the _.forEach with normal forEach?
  sortRoomThemes: function (roomTagsOrder, roomProducts) {
    let returnArray = []

    // For each code in roomTagsOrder, loop through hotels and put into returnArray
    _.forEach(roomTagsOrder, tag => {
      // get each room with this tag
      let items = _.filter(roomProducts, room => {
        return room.tags.includes(tag)
      })

      return items.length && items.forEach(item => returnArray.push(item))
    })

    // Return array with roomThemes in the correct order
    return Promise.resolve(returnArray)
  },

  sortByDate: function (datePropName) {
    return function compare (itemOne, itemTwo) {
      return moment.utc(itemOne[datePropName]).diff(moment.utc(itemTwo[datePropName]))
    }
  }
}

export default sortingHelpers
