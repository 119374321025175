import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import Rating from '../atoms/Rating'

import dateHelpers from '../../helpers/dateHelpers'

class BasketHotelItem extends PureComponent {
  render () {
    return (
      <div className='basket-hotel-summary'>
        <FormattedMessage tagName='h3' id='common.accommodationInformation' values={{ accommodationType: <FormattedMessage id={this.props.accommodationType.hotel} /> }} />
        <h2>{this.props.name}</h2>
        {this.props.roomName &&
          <h4>{this.props.roomName}</h4>
        }
        <Rating rating={this.props.starRating} />
        <div className='basket-hotel-summary__details'>
          <div>
            <strong><FormattedMessage id='common.nights' />: </strong> {this.props.stayDurationInDays}
          </div>
          <div>
            <strong><FormattedMessage id='common.checkin' />: </strong> {dateHelpers.formatStandard(this.props.checkInDate)} - after {this.props.checkInTime}
          </div>
          <div>
            <strong><FormattedMessage id='common.checkout' />: </strong> {dateHelpers.formatStandard(this.props.checkOutDate)} - before {this.props.checkOutTime}
          </div>
          <div>
            <strong><FormattedMessage id={this.props.accommodationType.room.plural} />: </strong>
            <ul>
              {this.props.roomDescriptions.map((description, index) => (
                <li key={`roomDescription${index}`} dangerouslySetInnerHTML={{ __html: description }} />
              ))}
            </ul>
          </div>
        </div>
      </div>
    )
  }
}

BasketHotelItem.propTypes = {
  checkInDate: PropTypes.string.isRequired,
  checkInTime: PropTypes.string.isRequired,
  checkOutDate: PropTypes.string.isRequired,
  checkOutTime: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  roomDescriptions: PropTypes.arrayOf(PropTypes.string).isRequired,
  roomName: PropTypes.string,
  starRating: PropTypes.number.isRequired,
  stayDurationInDays: PropTypes.number.isRequired,
  title: PropTypes.string
}

export default BasketHotelItem
