
import React from 'react'
import PropTypes from 'prop-types'
import { Waypoint } from 'react-waypoint'
import { query } from './BreakPoint'

const ResponsiveWaypoint = ({
  breakPoint,
  bottomOffset,
  children,
  onEnter,
  onLeave,
  topOffset
}) => {
  // If we don't have a breakpoint or the query isn't relevant.
  if (!breakPoint || !query[breakPoint]()) {
    return (
      <div>
        {children}
      </div>
    )
  }

  // Otherwise send back our content wrapped in a Waypoint
  return (
    <Waypoint
      onEnter={(e) => e.previousPosition === 'below' && onEnter()}
      onLeave={(e) => e.currentPosition === 'below' && onLeave()}
      topOffset={topOffset || 0}>
      <div>
        {children}
        <Waypoint
          onEnter={(e) => e.previousPosition === 'below' && onLeave()}
          onLeave={(e) => e.currentPosition === 'below' && onEnter()}
          bottomOffset={bottomOffset || 0} />
      </div>
    </Waypoint>
  )
}

ResponsiveWaypoint.propTypes = {
  breakPoint: PropTypes.string.isRequired,
  bottomOffset: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ]),
  onEnter: PropTypes.func.isRequired,
  onLeave: PropTypes.func.isRequired,
  topOffset: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number
  ])
}

export default ResponsiveWaypoint
