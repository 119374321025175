import _ from 'lodash'
import classNames from 'classnames'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import moment from 'moment'

import engine from '../../../actions/engine'

class Form extends Component {
  constructor (props) {
    super(props)
    this.getGuests = this.getGuests.bind(this)
  }

  componentDidMount () {
    this.props.initializeEngine()
  }

  componentDidUpdate (prevProps) {
    // This allows the form to submit when all fields are valid
    this.props.isValidForSubmission && this.searchForm.submit()
  }

  getGuests () {
    const guests = []
    // Add childAges else use partyComp counts
    const adultBracket = (this.props.partyComp || []).find(party => party.key === 'adults') || {}
    const childrenBracket = (this.props.partyComp || []).find(party => party.key === 'children') || {}
    for (let i = 0; i < adultBracket.count; i++) {
      guests.push({ age: adultBracket.age })
    }
    const children = this.props.ourChildren.map(({ age, overOneMeter }) => {
      const child = {
        age
      }
      if (childrenBracket.withChildHeights) {
        child.heightInCentimetres = overOneMeter ? 110 : 90
      }

      return child
    })
    return guests.concat(children)
  }

  render () {
    return (
      <form
        onSubmit={this.props.validate}
        className={classNames({
          'searchform': true,
          'searchform-inline': this.props.inline
        }, this.props.className)}
        action={this.props.action}
        method='get'
        ref={form => { this.searchForm = form }}
      >
        {this.getGuests().map(({ age, heightInCentimetres = null }, i) => {
          return (
            <React.Fragment>
              <input key={i} type='hidden' name={`guests[${i}][age]`} value={age} />
              {heightInCentimetres && <input key={i} type='hidden' name={`guests[${i}][heightInCentimetres]`} value={heightInCentimetres} />}
            </React.Fragment>
          )
        })}
        <input name='customerCode' type='hidden' value={this.props.customerCode} />
        <input name='searchFormId' type='hidden' value={this.props.searchFormId} />

        <input name='roomRates[checkinDate]' type='hidden' value={moment(this.props.checkInDate).format('YYYY-MM-DD')} />
        <input name='roomRates[checkoutDate]' type='hidden' value={moment(this.props.checkOutDate).format('YYYY-MM-DD')} />

        {this.props.rooms && this.props.rooms.map((room, i) => {
          if (!room) return null
          return (
            <div key={`room_${i}`}>
              <input name={`roomRates[rooms][${i}][adults]`} type='hidden' value={room.partyComposition.adults} />
              <input name={`roomRates[rooms][${i}][children]`} type='hidden' value={room.partyComposition.children || 0} />
              <input name={`roomRates[rooms][${i}][infants]`} type='hidden' value={room.partyComposition.infants || 0} />
              <input name={`roomRates[rooms][${i}][occupancyType]`} type='hidden' value={room.occupancyType} />
            </div>
          )
        })}

        {this.props.packageGroupId &&
          <input name='packageGroupId' type='hidden' value={this.props.packageGroupId} />
        }
        {/* Needed to pass on an applied promotion code */}
        {this.props.promotionCode &&
          <input name='promotionCode' type='hidden' value={this.props.promotionCode} />
        }

        {this.props.referrer &&
          <input name='referrer' type='hidden' value={this.props.referrer} />
        }

        <input name='context[hotelProducts][checkinDate]' type='hidden' value={moment(this.props.checkInDate).format('YYYY-MM-DD')} />

        <input name='ticketRates[startDate]' type='hidden' value={moment(this.props.ticketDate).format('YYYY-MM-DD')} />
        {this.props.timeslot && <input name='ticketRates[timeslot][start]' type='hidden' value={this.props.timeslot} />}
        <input name='venueCode' type='hidden' value={this.props.venueCode} />
        {this.props.operator && <input name='operator' type='hidden' value={this.props.operator} />}
        {this.props.children}

        {!this.props.submitOnChange && (
          <div className='searchform-submit'>
            <div className='form-group'>
              <span>&nbsp;</span>
              <button className='btn btn-primary'>{this.props.labelSubmit}</button>
            </div>
          </div>
        )}
      </form>
    )
  }
}

Form.defaultProps = {
  action: 'availability',
  inline: false,
  labelSubmit: 'Search',
  operator: '',
  submitOnChange: false
}

function mapStateToProps (state) {
  const {
    checkInDate,
    checkOutDate,
    children,
    packageGroupId,
    partyComp,
    rooms,
    ticketDate,
    searchFormId,
    timeslot
  } = state.engine.engineStore
  return {
    action: state.engine.action,
    agent: state.engine.agent,
    hasGuests: state.engine.hasGuests,
    checkInDate,
    checkOutDate,
    ourChildren: children, //  yes i'm not calling this children, as this component already has a children component set from the Form component ....
    customerCode: state.engine.customerCode,
    isValidForSubmission: state.engine.isValidForSubmission,
    labelSubmit: _.get(state.engine.brandConfig, 'UITextDefaults.labelSubmit'),
    messages: state.engine.messages,
    operator: state.engine.operator,
    packageGroupId,
    partyComp,
    promotionCode: state.engine.promotionCode,
    referrer: state.engine.referrer,
    roomBucket: state.engine.roomBucket,
    rooms,
    searchFormId,
    ticketDate,
    timeslot,
    venueCode: state.engine.venueCode
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {
    onSubmit: () => {
      return dispatch(engine.submit())
    },
    initializeEngine: () => {
      return dispatch(engine.initialize(ownProps.engineLogicOverrides))
    },
    toggle: (toggleType, forceVisible = false) => {
      return dispatch(engine.toggle(toggleType, forceVisible))
    },
    validate: (e) => {
      if (e && e.preventDefault) e.preventDefault()
      if (e && e.stopPropagation) e.stopPropagation()
      const fieldsToValidate = Object.assign({}, {
        checkInDate: true,
        checkOutDate: true,
        partyComp: true,
        rooms: true
      }, ownProps.fieldsToValidate)
      return dispatch(engine.validate(fieldsToValidate))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Form)
