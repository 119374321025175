import _ from 'lodash'
import config from '../configs/config'

import generalHelpers from '../helpers/generalHelpers'
import languageHelpers from '../helpers/languageHelpers'
import routeHelpers from '../helpers/routeHelpers'

// Using lightweight libphonenumber plugin to get various information about customer phone number
// and then format it for improved UI and CX
// More info: https://github.com/catamphetamine/libphonenumber-js
import { formatNumber, parseNumber } from 'libphonenumber-js'

const {
  agesConfiguration,
  brandConfig,
  childAgeControlId,
  harvestBasketData,
  hotelProduct
} = config
const withChildAges = _.get(brandConfig, 'secure.withChildAges', false) || hotelProduct.hide_hotel === '1'
const hasSessionStorage = _.get(brandConfig, 'secure.hasFeatures.payment.hasSessionStorage', false) && brandConfig.consumerType !== 'callCentre'
const payment = (state, action) => {
  state = state || {
    activateApplePay: false,
    activatePaypal: {
      mobile: false,
      largeComputer: false,
      smallComputer: false,
      tablet: false
    },
    adminPasswordValid: false,
    agesConfiguration: agesConfiguration[brandConfig.name] || null,
    billingDetails: {
      annualPassNo: generalHelpers.getSessionStorage('annualPassNo'),
      billToAddressCity: generalHelpers.getSessionStorage('billToAddressCity'),
      billToAddressCountry: generalHelpers.getSessionStorage('billToAddressCountry'),
      billToAddressLine0: generalHelpers.getSessionStorage('billToAddressLine0'),
      billToAddressLine1: generalHelpers.getSessionStorage('billToAddressLine1'),
      billToAddressLine2: generalHelpers.getSessionStorage('billToAddressLine2'),
      billToAddressPostalCode: generalHelpers.getSessionStorage('billToAddressPostalCode'),
      billToAddressState: generalHelpers.getSessionStorage('billToAddressState'),
      billToEmail: generalHelpers.getSessionStorage('billToEmail'),
      billToForename: generalHelpers.getSessionStorage('billToForename'),
      billToPhone: generalHelpers.getSessionStorage('billToPhone'),
      billToSurname: generalHelpers.getSessionStorage('billToSurname'),
      billToTitle: generalHelpers.getSessionStorage('billToTitle'),
      hasCancellationWaiver: false,
      hasPostalConfirmation: false,
      isBookerFirstRoomLead: false,
      paymentChoice: '',
      providerEnvironment: {},
      remarks: '',
      roomsLength: ''
    },
    childValidationError: null,
    enableVoucherPayment: false,
    errors: {},
    frame3DSecure: '',
    grossPrice: Number(harvestBasketData.grossPrice),
    hasChildValidationError: false,
    hasManualInput: false,
    hasYoungAdultsValidationError: false,
    isFetchingPassword: false,
    isFormValid: false,
    isFormValidated: false,
    isLoading: false,
    listOfAddresses: [],
    maxChildAge: 0,
    minChildAge: 0,
    packageTravelRegulationsHistory: 'Package Travel Regulations web booking',
    paymentCardType: '',
    paymentClientToken: '',
    roomDetails: [],
    showForm: true,
    standardPrice: (harvestBasketData.standardPrice) ? Number(harvestBasketData.standardPrice) : null,
    ticketsPartyCompositions: _.get(window, `ticketsPartyCompositions`, null)
  }

  // dynamically set our initial states for child/room combiniations, defaults to null if no children
  if (harvestBasketData.rooms) {
    harvestBasketData.rooms.forEach((room, i) => {
      if (!room.agesToValidate) return false
      const roomNumber = i + 1
      room.agesToValidate.forEach((age, i) => {
        const childRoomIdentifier = languageHelpers.sprintf(childAgeControlId, i + 1, roomNumber)
        if (state.billingDetails[childRoomIdentifier]) return false
        state.billingDetails[childRoomIdentifier] = generalHelpers.getSessionStorage(childRoomIdentifier)
      })
    })
  }

  let isFormValid = false
  const queryStringParams = routeHelpers.getQueryStringParams()
  const selectedItems = _.get(harvestBasketData, 'selectedItems')
  const jsonPackage = selectedItems && _.get(selectedItems[_.keys(selectedItems)[0]], 'jsonPackage') ? JSON.parse(_.get(selectedItems[_.keys(selectedItems)[0]], 'jsonPackage')) : null
  const ticketProvider = _.get(jsonPackage, 'data.ticket.provider')

  // if the providerEnvironment is set for encore in the url we need to pass it to the payment client only if the selected ticket provider is encore.
  if (queryStringParams.providerEnvironment && queryStringParams.providerEnvironment.encore && ticketProvider === 'encore') {
    state.billingDetails.providerEnvironment.encore = queryStringParams.providerEnvironment.encore
  }

  switch (action.type) {
    case 'ADMIN_PASSWORD_SUCCESS':
      return Object.assign({}, state, {
        adminPasswordValid: true,
        errors: Object.assign({}, _.omit(state.errors, 'adminPasswordError')),
        isFetchingPassword: false
      })

    case 'ADMIN_PASSWORD_LOADING':
      return Object.assign({}, state, {
        isFetchingPassword: true
      })

    case 'ADMIN_PASSWORD_FAILURE':
      return Object.assign({}, state, {
        adminPasswordValid: false,
        errors: {
          ...state.errors,
          adminPasswordError: action.error
        },
        isFetchingPassword: false
      })

    // When Payment-client wants a 3D Secure frame1
    case 'ADD_3D_SECURE_FRAME':
      return Object.assign({}, state, {
        frame3DSecure: action.value
      })

    // When address has been found we need to populate some fields
    case 'ADDRESS_SELECT':
      const selectedAddress = state.listOfAddresses.find(addressObject => addressObject.address[0] === action.data)

      if (selectedAddress) {
        // Remove any errors from the guest details fields that are passed here
        const addressGetSuccessErrors = _.omitBy(state.errors, (value, key) => {
          return _.startsWith(key, 'billToAddress') || key === 'addressLookup'
        })

        // Add found address details to state
        const selectedAddressFormatted = {
          // Postcode lookup only works for UK so lets provide that
          billToAddressCountry: 'GB',
          billToAddressLine0: selectedAddress.address[0],
          billToAddressLine1: selectedAddress.address[1] || selectedAddress.address[2] || '',
          billToAddressLine2: selectedAddress.address[2] || '',
          billToAddressCity: selectedAddress.locality,
          billToAddressPostalCode: selectedAddress.postalCode,
          billToAddressState: selectedAddress.region
        }

        // set the above key/value pair object into session storage
        if (hasSessionStorage) generalHelpers.setSessionStorageObject(selectedAddressFormatted)
        return Object.assign({}, state, {
          billingDetails: Object.assign({}, state.billingDetails, selectedAddressFormatted),
          errors: addressGetSuccessErrors,
          isLoading: false,
          hasManualInput: false,
          listOfAddresses: []
        })
      } else {
        return Object.assign({}, state, {
          errors: {
            addressLookup: 'There was an issue processing the address. Please enter it manually'
          },
          hasManualInput: true,
          isLoading: false
        })
      }

    // Reset address input fields
    case 'ADDRESS_RESET':
      // Reset address details and remove errors
      return Object.assign({}, state, {
        billingDetails: Object.assign({}, state.billingDetails, {
          billToAddressCity: '',
          billToAddressCountry: '',
          billToAddressLine0: '',
          billToAddressLine1: '',
          billToAddressLine2: '',
          billToAddressPostalCode: '',
          billToAddressState: ''
        }),
        errors: Object.assign({}, _.omit(state.errors, 'addressLookup', 'paymentClient')),
        hasManualInput: false
      })

    // Set dummy address for two stage payment
    case 'SET_DUMMY_ADDRESS_DATA':
      return Object.assign({}, state, {
        billingDetails: Object.assign({}, state.billingDetails, {
          billToAddressCity: 'Hythe',
          billToAddressCountry: 'GB',
          billToAddressLine0: 'Holiday Extras',
          billToAddressLine1: 'Ashford Road',
          billToAddressLine2: 'Newingreen',
          billToAddressPostalCode: 'CT21 4JF',
          billToAddressState: 'Kent',
          billToPhone: '0208888888'
        })
      })

    // check the user input's ages against what we are expecting
    case 'CHECK_CHILD_AGES': {
      let expectedInfants = 0
      let expectedChildren = 0
      let expectedYoungAdults = 0
      const hasYoungAdultsConfig = (action.youngAdultsMaxAge !== null && action.youngAdultsMinAge !== null)
      // use ages here to decipher what validation is required of the child age options
      action.basket.ages.forEach((age) => {
        age = Number(age)
        if (age < action.childrenMinAge) {
          // baby
        } else if (age === action.childrenMinAge) {
          expectedInfants++
        } else if (age >= action.childrenMinAge && age <= action.childrenMaxAge) {
          expectedChildren++
        } else if (hasYoungAdultsConfig && age > action.youngAdultsMinAge && age <= action.youngAdultsMaxAge) {
          expectedYoungAdults++
        }
      })

      // whether or not the party comp has an 'infants' option - initialised to the option from the brand configuration
      let couldIncludeInfants = action.hasInfantsOnSearch

      // if the ticket specifies a custom party comp without 'parkInfants1', we should also not expect infants
      const partyCompForBasketTicket = _.get(action.ticketsPartyCompositions, _.get(action, 'basket.ticket.id'))
      if (partyCompForBasketTicket) {
        couldIncludeInfants = couldIncludeInfants && _.find(partyCompForBasketTicket, { key: 'parkInfants1' })
      }

      const selected = {
        youngAdults: 0,
        children: 0,
        infants: 0
      }
      // Get the ages selected by the user
      Object.keys(action.billingDetails).forEach(key => {
        if (/^childAge.*\d+$/.test(key)) {
          const age = Number(action.billingDetails[key])
          if (couldIncludeInfants && age <= action.childrenMinAge) {
            return selected.infants++
          } else if (age >= action.childrenMinAge && age <= action.childrenMaxAge) {
            selected.children++
          } else if (hasYoungAdultsConfig && age >= action.youngAdultsMinAge && age <= action.youngAdultsMaxAge) {
            selected.youngAdults++
          }
        }
      })

      let newState = {}
      // only check for young adults errors when we have the config and only when we are expecting young adults
      if (hasYoungAdultsConfig && expectedYoungAdults !== 0 && (selected.youngAdults === expectedYoungAdults)) {
        Object.assign(newState, {
          hasYoungAdultsValidationError: false
        })
      }
      // if we have no young adults set the flag for the check below
      if (expectedYoungAdults === 0) {
        Object.assign(newState, {
          hasYoungAdultsValidationError: false
        })
      }
      if (selected.children === expectedChildren && selected.infants === expectedInfants) {
        Object.assign(newState, {
          hasChildValidationError: false
        })
      }

      if (newState.hasChildValidationError === false && newState.hasYoungAdultsValidationError === false) {
        return Object.assign({}, state, newState)
      }
      // Alert the customer
      let expecting = 'Expecting: '
      if (hasYoungAdultsConfig && expectedYoungAdults > 0) {
        expecting += `${expectedYoungAdults} ${languageHelpers.pluralise('Young Adult', expectedYoungAdults)} (between ${action.youngAdultsMinAge} and ${action.youngAdultsMaxAge} years old)`
        if (expectedChildren > 0) {
          expecting += ` and `
        }
      }
      if (expectedChildren > 0) {
        const effectiveChildrenMinAge = couldIncludeInfants ? action.childrenMinAge + 1 : action.childrenMinAge
        expecting += `${expectedChildren} ${languageHelpers.pluralise('Child', expectedChildren)} (between ${effectiveChildrenMinAge} and ${action.childrenMaxAge} years old)`
        if (expectedInfants > 0) {
          expecting += ` and `
        }
      }
      if (expectedInfants > 0) {
        expecting += `${expectedInfants} ${languageHelpers.pluralise('Infant', expectedInfants)} (${action.childrenMinAge} years old)`
      }

      const alert = `Sorry, the ${(expectedYoungAdults > 0) ? 'Young Adult' : ''} ${(expectedYoungAdults > 0 && (expectedChildren > 0 || expectedInfants > 0)) ? ' / ' : ''} ${(expectedChildren > 0) ? 'Children' : ''}${(expectedInfants > 0 && expectedChildren > 0) ? ' / ' : ''}${(expectedInfants > 0) ? 'Infant' : ''} ages selected do not match the original search criteria. ${expecting}`
      return Object.assign({}, state, {
        isFormValid: false,
        hasChildValidationError: true,
        childValidationError: alert
      })
    }

    case 'POSTALCODE_LOOKUP_FIELD_FAILED':
      // Seperate small piece of validation for the address find button to check postcode field
      const addressLookupErrors = Object.assign({}, _.omit(state.errors, 'addressLookup', 'paymentClient'))
      // Just add message for postcode field at this time
      const postalCodeFieldError = 'Required'

      // In this case will just be applied to postcode but in this format this can be manipulated in future if required
      addressLookupErrors['billToAddressPostalCode'] = postalCodeFieldError

      return Object.assign({}, state, {
        errors: addressLookupErrors,
        isFormValid: false,
        isFormValidated: true
      })

    // When form isn't valid (all the fields that aren't handled by Payment-client)
    case 'FORM_VALIDATION_FAILED':
      const formErrors = Object.assign({}, _.omit(state.errors, 'addressLookup', 'paymentClient'), action.errors)
      const billToAddressKeys = Object.keys(action.errors).filter(key => {
        return key.indexOf('billToAddress') !== -1
      })

      const hasManualInput = (Object.keys(state.errors.addressLookup || {}).length > 0) || (billToAddressKeys.length > 0)

      return Object.assign({}, state, {
        errors: formErrors,
        hasManualInput,
        isFormValid: false,
        isFormValidated: true
      })

    // When form is valid (all the fields that aren't handled by Payment-client)
    case 'FORM_VALIDATION_PASSED':
      return Object.assign({}, state, {
        hasManualInput: false,
        isFormValid: !Object.keys(action.errors).length,
        isFormValidated: true
      })

    // initialize the data needed for the payment form
    case 'INITIALIZE_PAYMENT_FORM': {
      const defaultChildMaxAge = Number(_.get(action, 'agesConfiguration.childrenMaxAge', 15))
      const maxChildAge = Number(_.get(action, 'agesConfiguration.youngAdultsMaxAge', defaultChildMaxAge))
      let minChildAge = Number(_.get(action, 'agesConfiguration.childrenMinAge', 2))

      let billingDetails = Object.assign({}, state.billingDetails)

      // if we have a party composition setup on the event, we need to use it
      const roomDetails = (action.rooms || []).map((room, roomIndex) => {
        room.agesToValidate = [...room.adultsAges || [], ...room.childrenAges || [], ...room.infantsAges || []].filter(age => {
          return age <= maxChildAge && age >= minChildAge
        })
        room.roomCode = room.occupancyType + room.adults + room.children

        // Prepopulate state with ages for brands that have `withChildAges` (all Merlin)
        if (withChildAges) {
          room.agesToValidate.map((age, ageIndex) => {
            billingDetails[`childAge${ageIndex + 1}Room${roomIndex + 1}`] = age
          })
        }
        return room
      })
      return Object.assign({}, state, {
        billingDetails,
        maxChildAge,
        minChildAge,
        roomDetails
      })
    }

    // Save input and add or remove any errors

    case 'INPUT_CHANGED':
      const inputId = action.id
      const inputError = action.error
      const inputErrors = Object.assign({}, _.omit(state.errors, ['addressLookup', 'paymentClient', 'adminPasswordError', inputId]))

      // Only when form is validated we want to show errors
      if (state.isFormValidated) {
        // Add error if we have one
        if (inputError) {
          inputErrors[inputId] = inputError
        }

        isFormValid = !Object.keys(inputErrors).length
      }

      // Add a slash to DTMF expiry date when we have at least two digits
      if (inputId === 'dtmfCardExpiryDate' && action.value.length > 2) {
        action.value = action.value.replace(/^(\d{2})\/?(\d{2})?/, '$1/$2')
      }
      // if sessionStorage feature is turned on && not GX consumer then setSessionStorage
      if (hasSessionStorage) generalHelpers.setSessionStorage(inputId, action.value)

      // Add value of input field to state
      return Object.assign({}, state, {
        billingDetails: Object.assign({}, state.billingDetails, {
          [inputId]: action.value
        }),
        errors: inputErrors,
        isFormValid
      })

    case 'MAP_ANNUAL_PASS_CHECK':
      const nonVerified = 'Invalid Pass Number'
      let verified = false
      const accountNumberPrefix = action.code.substring(0, 7)
      const annualPassTypes = window.ticketBucketsWithMerlinAnnualPassTypes && window.ticketBucketsWithMerlinAnnualPassTypes[harvestBasketData.ticket.bucket]

      annualPassTypes.forEach(annualPassName => {
        if (verified) return
        if (annualPassName === 'localAnnualPassPrefix') {
          let currentBrandPrefixes = window.merlinAnnualPassPrefixes['localAnnualPassPrefix'][harvestBasketData.brand]
          if (!Array.isArray(currentBrandPrefixes)) {
            currentBrandPrefixes = [currentBrandPrefixes]
          }
          if (!currentBrandPrefixes.length) return
          _.forEach(currentBrandPrefixes, currentBrandPrefix => {
            if (currentBrandPrefix && currentBrandPrefix === action.code.slice(0, currentBrandPrefix.length)) {
              verified = true
            }
          })
        } else {
          const passPrefix = window.merlinAnnualPassPrefixes[annualPassName]
          _.forEach(passPrefix, prefixValue => {
            // Merlin Annual Passes have 18 digits
            if (_.includes(prefixValue, accountNumberPrefix) && action.code.length === 18) {
              verified = true
            }
          })
        }
      })
      const annualPassVerificationError = verified ? false : nonVerified
      return Object.assign({}, state, {
        errors: Object.assign({}, state.errors, {
          annualPassNo: annualPassVerificationError
        })
      })

    // Use the name from the guest details for first family room
    case 'SET_LEAD_ROOM_DETAILS':
      let returnedErrorsState = _.omit(state.errors, [
        'titleRoom1',
        'initialRoom1',
        'surnameRoom1'
      ])
      const roomErrors = {}
      if (!state.billingDetails.billToTitle) roomErrors.titleRoom1 = 'Required'
      if (!state.billingDetails.billToForename) roomErrors.initialRoom1 = 'Required'
      if (!state.billingDetails.billToSurname) roomErrors.surnameRoom1 = 'Required'
      if (Object.keys(roomErrors).length > 0) returnedErrorsState = Object.assign({}, state.errors, roomErrors)

      return Object.assign({}, state, {
        billingDetails: Object.assign({}, state.billingDetails, {
          [action.id]: action.value,
          titleRoom1: action.value ? state.billingDetails.billToTitle : '',
          initialRoom1: action.value ? state.billingDetails.billToForename : '',
          surnameRoom1: action.value ? state.billingDetails.billToSurname : ''
        }),
        errors: returnedErrorsState
      })

      // Set isBookerFirstRoomLead to true

    case 'SET_IS_BOOKER_FIRST_ROOM_LEAD':
      return Object.assign({}, state, {
        billingDetails: Object.assign({}, state.billingDetails, {
          isBookerFirstRoomLead: true
        })
      })

    // When Payment-Client updates with a different card type
    case 'PAYMENT_CARD_TYPE_CHANGE':
      return Object.assign({}, state, {
        paymentCardType: action.value
      })

    // When user changes payment choice
    case 'PAYMENT_CHOICE_CHANGE':
      return Object.assign({}, state, {
        billingDetails: Object.assign({}, state.billingDetails, {
          paymentChoice: action.value
        })
      })

    case 'PAYMENT_CLIENT_ERROR':
      const paymentClientErrors = Object.assign({}, state.errors, {
        paymentClient: action.message
      })
      return Object.assign({}, state, {
        errors: paymentClientErrors,
        isLoading: false,
        showForm: action.showForm
      })

    // When Payment-client returns a different token
    case 'PAYMENT_CLIENT_TOKEN':
      // FP-7651 activatePaypal is now expected to be an object, not a boolean
      // this condition will temporarily adapt the type - can be removed when the corresponding server-side change is in production
      if (_.isBoolean(action.activatePaypal)) {
        action.activatePaypal = {
          mobile: action.activatePaypal,
          largeComputer: action.activatePaypal,
          smallComputer: action.activatePaypal,
          tablet: action.activatePaypal
        }
      }
      return Object.assign({}, state, {
        activateApplePay: action.activateApplePay,
        activatePaypal: action.activatePaypal,
        paymentClientToken: action.token,
        billingDetails: Object.assign({}, state.billingDetails, {
          paymentChoice: action.token
        })
      })

    // When a payment field gets validated
    case 'PAYMENT_FIELD_VALIDATION':
      const paymentId = action.id
      const paymentError = action.error
      const paymentErrors = Object.assign({}, _.omit(state.errors, paymentId))

      // Only when form is validated we want to show errors
      if (state.isFormValidated) {
        // Add error if we have one
        if (paymentError) {
          paymentErrors[paymentId] = paymentError
        }
        // If cleared delete from errors to ensure UI acts as expected
        if (!paymentError) delete paymentErrors[paymentId]

        isFormValid = !Object.keys(paymentErrors).length
      }

      return Object.assign({}, state, {
        billingDetails: Object.assign({}, state.billingDetails, {
          [paymentId]: action.isValid && 'valid'
        }),
        errors: paymentErrors,
        isFormValid
      })

    // When we need to wait for the Payment-client, show spinny wheel
    case 'PAYMENT_UPDATE':
      return Object.assign({}, state, {
        isLoading: !action.value
      })

    // Search for postalcode triggered, showing spinny wheel
    case 'POSTALCODE_GET':
      return Object.assign({}, state, {
        hasManualInput: false,
        isLoading: true,
        listOfAddresses: []
      })

    // No results, reset search
    case 'POSTALCODE_GET_FAILURE':
      return Object.assign({}, state, {
        errors: {
          addressLookup: action.error
        },
        hasManualInput: true,
        isLoading: false
      })

      // Search for a list of addresses based on the given postcode
    case 'POSTALCODE_GET_SUCCESS':
      return Object.assign({}, state, {
        hasManualInput: false,
        isLoading: false,
        listOfAddresses: action.data
      })

      // When Payment-client wants a 3D Secure frame
    case 'REMOVE_3D_SECURE_FRAME':
      return Object.assign({}, state, {
        frame3DSecure: null
      })

      // Toggle manual input fields for address details
    case 'TOGGLE_MANUAL_INPUT':
      return Object.assign({}, state, {
        hasManualInput: !state.hasManualInput,
        listOfAddresses: [] // as we are manual, we don't need the list of addresses anymore.
      })

    // Toggle upgrades and calculate grossPrice
    case 'TOGGLE_UPGRADE':
      const {
        id,
        value
      } = action
      const upgrade = _.omit(action.upgrade, ['description', 'href', 'bookingProtectedMessage'])
      let grossPrice = state.grossPrice
      let standardPrice = state.standardPrice
      if (!value && harvestBasketData.selectedItems[upgrade.id]) {
        delete harvestBasketData.selectedItems[upgrade.id]
        grossPrice -= Number(upgrade.grossPrice)
        if (upgrade.standardPrice) {
          // we should have a standardPrice already if we're removing an upgrade with a standardPrice
          if (standardPrice) {
            standardPrice -= Number(upgrade.standardPrice)
          }
        } else if (standardPrice) {
          standardPrice -= Number(upgrade.grossPrice)
        }
        // if we end up with a standardPrice matching the grossPrice, we no longer need to show the standardPrice
        if (standardPrice === grossPrice) {
          standardPrice = null
        }
      } else {
        harvestBasketData.selectedItems[upgrade.id] = upgrade
        // if the upgrade has a standardPrice, we need to update that too
        if (upgrade.standardPrice) {
          // if we don't already have a standardPrice, we should set it to the grossPrice
          if (!standardPrice) {
            standardPrice = grossPrice
          }
          standardPrice += Number(upgrade.standardPrice)
        } else if (standardPrice) {
          // if the upgrade doesn't have a standardPrice, but we do already have one for the basket, we should just add the grossPrice to it
          standardPrice += Number(upgrade.grossPrice)
        }
        grossPrice += Number(upgrade.grossPrice)
      }
      return Object.assign({}, state, {
        billingDetails: Object.assign({}, state.billingDetails, {
          [id]: value
        }),
        grossPrice,
        standardPrice
      })

      // Send out correctly formatted telephone number
    case 'FORMAT_TELEPHONE_NUMBER':
      // Default to E.164 format
      let phoneNumberFormatting = 'E.164'
      // Parse the number to allow correct formatting
      let { phone, country } = parseNumber(action.telephoneNumber, action.countryCode, { extended: true })
      // Assign formatted number
      let formattedNumber = ''
      // If GB format as national
      // This will be easier for CC to read back GB numbers
      if (action.countryCode === 'GB') {
        phoneNumberFormatting = 'National'
      }
      // Ensure we have a value returned for parsed telephone number
      if (phone) {
        formattedNumber = formatNumber({
          country: country,
          phone: phone
        }, phoneNumberFormatting)
      }

      return Object.assign({}, state, {
        billingDetails: Object.assign({}, state.billingDetails, {
          billToPhone: formattedNumber
        })
      })

    case 'TOGGLE_VOUCHER_PAYMENT': {
      const clonedState = _.cloneDeep(state)
      return {
        ...clonedState,
        enableVoucherPayment: !state.enableVoucherPayment
      }
    }

    default:
      return state
  }
}

export default payment
