import _ from 'lodash'
import classNames from 'classnames'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { Modal, Row } from 'react-bootstrap'
import { FormattedMessage, injectIntl, intlShape } from 'react-intl'

import DisplayPrice from '../atoms/DisplayPrice'
import ResponsiveImage from '../atoms/ResponsiveImage'
import Select from '../atoms/Select'

import dateHelpers from '../../helpers/dateHelpers'
import trackingHelpers from '../../helpers/trackingHelpers'
import Stepper from './Stepper'

import config from '../../configs/config'
const {
  harvestBasketData
} = config

const hasChildrenUnder3 = (harvestBasketData.ages || []).filter(age => age < 3).length > 0
// harvest gives us booleans as strings
const hasTicketsIncluded = (harvestBasketData.hasParkEntry === 'true')

class AmendButton extends PureComponent {
  render () {
    return (
      <a
        className='hoverable'
        {...trackingHelpers.getAttributes('Amend Extras', 'Extras', this.props.dataLabel)}
        onClick={this.props.onClick}
        onKeyDown={this.props.onKeyDown}>
        amend
      </a>
    )
  }
}

class Upgrade extends PureComponent {
  // This is where the magic happens. We have two different looking tiles that do the same job.
  // Instead of having to maintain code in two places, we just use some ternary magic to dynamically change classes.
  // Bit of a trade off for not having to write code twice. Open and likely to change.
  constructor (props) {
    super(props)
    this.getAddUpgradeFormattedMessageText = this.getAddUpgradeFormattedMessageText.bind(this)
    this.handleUpdatedUpgradeDates = this.handleUpdatedUpgradeDates.bind(this)
    this.handleCompositionIncrement = this.handleCompositionIncrement.bind(this)
    this.handleCompositionDecrement = this.handleCompositionDecrement.bind(this)
    this.compositionOptions = this.compositionOptions.bind(this)
    this.daysOptions = this.daysOptions.bind(this)
    this.datesOptions = this.datesOptions.bind(this)
    this.groupOptions = this.groupOptions.bind(this)

    this.handleUpdatedUpgradeDates(this.props.upgrade.dates)
    this.handleUpdatedUpgradeDays(this.props.upgrade.days)
    this.handleUpdatedGroupUpgrades(this.props.upgrade.groupUpgradeProducts)
    this.modalOpen = this.modalOpen.bind(this)
    this.handleUpgradeCTAClose = this.handleUpgradeCTAClose.bind(this)
    this.modalClose = this.props.modalClose.bind(this)
  }

  handleUpgradeCTAClose (selectedProductId, event) {
    event && event.preventDefault()
    this.props.handleUpgradeCTA(selectedProductId)
    this.modalClose()
  }

  componentWillReceiveProps (nextProps) {
    if (!nextProps.upgrade) return
    this.handleUpdatedUpgradeDates(nextProps.upgrade.dates)
    this.handleUpdatedUpgradeDays(nextProps.upgrade.days)
  }

  handleUpdatedUpgradeDates (upgradeDates) {
    this.availableDates = upgradeDates
      ? upgradeDates.map(date => {
        return {
          text: dateHelpers.formatStandard(date),
          value: date
        }
      })
      : []
  }

  handleUpdatedUpgradeDays (upgradeDays) {
    this.availableDays = upgradeDays
      ? upgradeDays.map(daysObj => {
        return {
          text: daysObj.days,
          value: daysObj.id
        }
      })
      : []
  }

  handleUpdatedGroupUpgrades (groupUpgrades) {
    this.groupUpgrades = groupUpgrades || []
  }

  handleCompositionIncrement (type) {
    return this.props.handleCompositionChange({
      field: type,
      id: this.props.upgrade.id,
      increment: true
    })
  }

  handleCompositionDecrement (type) {
    return this.props.handleCompositionChange({
      field: type,
      id: this.props.upgrade.id,
      increment: false
    })
  }

  compositionOptions () {
    if (this.props.upgrade.isRoomBased) return null
    if (!this.props.upgrade.composition) return null
    if (this.props.upgrade.composition.cars) {
      const trackingCars = {
        decrement: {
          action: 'Remove Cars',
          category: 'Upgrades',
          label: this.props.upgrade.id
        },
        increment: {
          action: 'Add Cars',
          category: 'Upgrades',
          label: this.props.upgrade.id
        }
      }
      return (
        <div className='col-lg-10'>
          <Stepper
            disabled={this.props.upgrade.isInBasket}
            handleDecrement={() => this.handleCompositionDecrement('cars')}
            handleIncrement={() => this.handleCompositionIncrement('cars')}
            label='Cars'
            tracking={trackingCars}
            value={this.props.upgrade.composition.cars} />
        </div>
      )
    }
    if (this.props.upgrade.composition.quantityBased) {
      const trackingQuantityBased = {
        decrement: {
          action: 'Remove Qty',
          category: 'Upgrades',
          label: this.props.upgrade.id
        },
        increment: {
          action: 'Add Qty',
          category: 'Upgrades',
          label: this.props.upgrade.id
        }
      }
      return (
        <div className='col-lg-10'>
          <Stepper
            disabled={this.props.upgrade.isInBasket}
            max={this.props.upgrade.composition.quantity >= (this.props.upgrade.compositionBoundaries.max.quantityBased || 15)}
            handleDecrement={() => this.handleCompositionDecrement('quantityBased')}
            handleIncrement={() => this.handleCompositionIncrement('quantityBased')}
            label='Quantity'
            tracking={trackingQuantityBased}
            value={this.props.upgrade.composition.quantityBased} />
        </div>
      )
    }
    if (this.props.upgrade.composition.quantity) {
      const trackingQuantity = {
        decrement: {
          action: 'Remove Quantity',
          category: 'Upgrades',
          label: this.props.upgrade.id
        },
        increment: {
          action: 'Add Quantity',
          category: 'Upgrades',
          label: this.props.upgrade.id
        }
      }
      return (
        <div className='col-lg-10'>
          <Stepper
            disabled={this.props.upgrade.isInBasket}
            handleDecrement={() => this.handleCompositionDecrement('quantity')}
            handleIncrement={() => this.handleCompositionIncrement('quantity')}
            label='Quantity'
            max={this.props.upgrade.composition.quantity >= (this.props.upgrade.compositionBoundaries.max.quantity || 15)}
            tracking={trackingQuantity}
            value={this.props.upgrade.composition.quantity} />
        </div>
      )
    }

    if (this.props.upgrade.isAdultRequired && !this.props.upgrade.composition.adults) return null
    const trackingParty = {
      adults: {
        decrement: {
          action: 'Remove Adults',
          label: this.props.upgrade.id,
          category: 'Upgrades'
        },
        increment: {
          action: 'Add Adults',
          label: this.props.upgrade.id,
          category: 'Upgrades'
        }
      },
      children: {
        decrement: {
          action: 'Remove Children',
          label: this.props.upgrade.id,
          category: 'Upgrades'
        },
        increment: {
          action: 'Add Children',
          label: this.props.upgrade.id,
          category: 'Upgrades'
        }
      }
    }

    return (
      <div className='col-lg-10'>
        <Stepper
          formattedMessage={{
            id: 'upgrades.adultsUpgradeStepperLabel',
            values: { years: this.props.childrenMaxAge + 1 }
          }}
          disabled={this.props.upgrade.isInBasket}
          handleDecrement={() => this.handleCompositionDecrement('adults')}
          handleIncrement={() => this.handleCompositionIncrement('adults')}
          tracking={trackingParty.adults}
          value={this.props.upgrade.composition.adults} />
        {/* @todo: whats this? */}
        {this.props.defaultComposition.children > 0 &&
          <Stepper
            formattedMessage={{
              id: 'upgrades.childrenUpgradeStepperLabel',
              values: { years: `${hasChildrenUnder3 ? '3' : this.props.childrenMinAge}-${this.props.childrenMaxAge}` }
            }}
            formattedMessageSubLabel={hasChildrenUnder3 && !this.props.upgrade.hideInfantsGoFreeMessage ? {
              id: 'common.under3GoFree'
            } : null}
            disabled={this.props.upgrade.isInBasket}
            handleDecrement={() => this.handleCompositionDecrement('children')}
            handleIncrement={() => this.handleCompositionIncrement('children')}
            tracking={trackingParty.children}
            value={this.props.upgrade.composition.children} />
        }
      </div>
    )
  }

  daysOptions () {
    if (this.availableDays.length > 0) {
      return (
        <div className='col-lg-10'>
          <Select
            disabled={this.props.upgrade.isInBasket}
            label='Days'
            name='Days'
            onChange={(e) => this.props.handleDaysSelection(this.props.upgrade.id, e)}
            options={this.availableDays}
            tracking={{
              action: 'Attraction Change',
              category: 'Upgrades',
              label: this.props.upgrade.id
            }}
            value={this.props.upgrade.selectedProductId} />
        </div>
      )
    }
  }

  modalOpen () {
    if (this.props.upgrade.isAvailable !== false) {
      this.props.modalOpen(this.props.upgrade.group, true)
    }
  }

  datesOptions () {
    if (this.availableDates.length > 0) {
      return (
        <div className='col-lg-10'>
          <Select
            disabled={this.props.upgrade.isInBasket}
            label='Date'
            name='UpgradeDate'
            onChange={(e) => this.props.handleDateSelection(this.props.upgrade.id, e)}
            options={this.availableDates}
            tracking={{
              action: 'Attraction Change',
              category: 'Upgrades',
              label: this.props.upgrade.id
            }}
            value={this.props.upgrade.selectedDate} />
        </div>
      )
    }
  }

  groupOptions () {
    if (this.props.upgrade.isUpgradeGroup) {
      return (
        <div className='col-lg-10'>
          <Select
            disabled={this.props.upgrade.isInBasket}
            label='Upgrade Type'
            name='Upgrade Type'
            onChange={(e) => this.props.handleGroupUpgradeSelection(this.props.upgrade.id, e)}
            options={this.groupUpgrades}
            tracking={{
              action: 'Attraction Change',
              category: 'Upgrades',
              label: this.props.upgrade.id
            }}
            value={this.props.upgrade.id} />
        </div>
      )
    }
  }

  getAddUpgradeFormattedMessageText (hasTicketsIncluded) {
    // if the ticket is included in the package and the upgrade is a season pass display different label
    if (this.props.upgrade.isSeasonPass && hasTicketsIncluded) {
      return 'upgrades.addSeasonPass'
    } else if (this.props.upgrade.isUpgradeGroup && this.props.upgrade.groupPrice && !this.props.upgrade.isPriceSameForAllUpgradesInGroup) {
      return 'common.from'
    }
    return 'common.add'
  }

  render () {
    const isRow = this.props.row
    const CTAText = this.props.upgrade.isInBasket ? 'Change' : 'Add'
    const CTAAmendText = this.props.upgrade.isInBasket ? 'Remove' : 'Add'
    const totalPrice = Number(this.props.upgrade.totalPrice || this.props.upgrade.price)
    const grossPrice = Number(this.props.upgrade.isUpgradeGroup ? this.props.upgrade.groupPrice : totalPrice)

    const addBasketCTATracking = {
      action: this.props.upgrade.isInBasket ? 'Remove Attraction From Cart' : 'Add Attraction To Cart',
      category: 'Upgrades',
      label: this.props.upgrade.id
    }

    const closeModalCTATracking = {
      action: 'Close Modal',
      category: 'Upgrades',
      label: this.props.upgrade.id
    }

    const openModalCTATracking = {
      action: this.props.upgrade.isInBasket ? 'Open Modal to Edit Attraction' : 'Open Modal to Add Attraction',
      category: 'Upgrades',
      label: this.props.upgrade.id
    }

    const groupUpgradeName = this.props.upgrade.groupTitle
    const mainTileTitle = this.props.upgrade.isUpgradeGroup ? groupUpgradeName : this.props.upgrade.title
    const upgradeDescription = this.props.upgrade.isUpgradeGroup ? this.props.upgrade.groupDescription : this.props.upgrade.strapline
    const quantityDescription = this.props.intl.formatMessage({ id: 'upgrades.quantity' }, { quantity: _.get(this.props.upgrade, 'composition.quantity', 0) })
    const quantityBasedDescription = this.props.intl.formatMessage({ id: 'upgrades.quantity' }, { quantity: _.get(this.props.upgrade, 'composition.quantityBased', 0) })

    const classes = classNames('btn btn-secondary btn-block', {
      'disabled': this.props.upgrade.isAvailable === false
    })
    return (
      <div className={isRow ? 'row' : 'thumbnail'}> {/* Can't use ReactBS thumbnail as we use responsive images */}
        <div className={isRow ? 'col-xs-5 col-sm-2 pr-0' : 'thumbnail-image'}>
          <a onClick={this.modalOpen} onKeyDown={(e) => e.key === 'Enter' && this.modalOpen()}>
            <ResponsiveImage
              lazyload={false}
              src={this.props.upgrade.groupImage || this.props.upgrade.image}
            />
          </a>
        </div>
        <div className={isRow ? 'col-xs-7' : 'caption'}>
          {mainTileTitle &&
            <a
              onClick={this.modalOpen}
              onKeyDown={(e) => e.key === 'Enter' && this.modalOpen()}
              {...trackingHelpers.getAttributes('View Extras', 'Extras', this.props.upgrade.group)}
            >
              <h3 className='h5' dangerouslySetInnerHTML={{ __html: mainTileTitle }} />
            </a>
          }
          <span className='tiny' dangerouslySetInnerHTML={{ __html: upgradeDescription }} />
          {/* Only display for quantity requirement upgrades */}
          {_.get(this.props.upgrade, 'composition.quantity') && !_.get(this.props.upgrade, 'composition.quantityBased') && !this.props.upgrade.isUpgradeGroup &&
            <div className='small'>
              {quantityDescription} <AmendButton onClick={this.modalOpen} onKeyDown={(e) => e.key === 'Enter' && this.modalOpen()} dataLabel={this.props.upgrade.group} />
            </div>
          }
          {_.get(this.props.upgrade, 'composition.quantityBased') &&
            <div className='small'>
              {quantityBasedDescription} <AmendButton onClick={this.modalOpen} onKeyDown={(e) => e.key === 'Enter' && this.modalOpen()} dataLabel={this.props.upgrade.group} />
            </div>
          }
          {this.props.upgrade.showUpgradeDate && (!this.props.upgrade.isAvailableForAllSelectedDates || this.props.upgrade.isAvailableForAllSelectedDates === 'false') && !this.props.upgrade.isUpgradeGroup && !_.get(this.props.upgrade, 'composition.quantity') &&
            <div className='small text-muted'>
              {dateHelpers.formatStandard(this.props.upgrade.selectedDate)}
            </div>
          }
          <div className='upgrade-modal'>
            <Modal show={this.props.modalIsOpen} onHide={() => this.props.canCloseModal && this.modalClose()}>
              <Modal.Header closeButton={this.props.canCloseModal}>
                {/* Upgrade title and the party composition selected. */}
                <Modal.Title dangerouslySetInnerHTML={{ __html: this.props.upgrade.isUpgradeGroup ? this.props.upgrade.groupTitle : this.props.upgrade.title }} />
              </Modal.Header>
              <Modal.Body>
                <Row>
                  <div className={this.props.upgrade.isUpgradeGroup ? 'col-sm-8' : 'col-sm-12'}>
                    {this.props.upgrade.isUpgradeGroup && <h3 className='h5'>{this.props.upgrade.title}</h3>}
                    {/* Prismic description for the upgrade */}
                    {<div dangerouslySetInnerHTML={{ __html: this.props.upgrade.description }} />}
                    {this.props.upgrade.additionalDescription &&
                      <a
                        href='#additionalDescriptionCollapse'
                        data-toggle='collapse'
                        className='collapsed'
                      >
                        <span className='i-plus collapse-icon'>&#43;</span>
                        Read <span className='collapsed-text'>more&hellip;</span><span className='expanded-text'>less</span>
                      </a>
                    }
                    <div id='additionalDescriptionCollapse' className='collapse'>
                      <div className='block-xs'>
                        <div className='small'
                          dangerouslySetInnerHTML={{ __html: this.props.upgrade.additionalDescription }} />
                      </div>
                    </div>
                  </div>
                  {this.props.upgrade.isUpgradeGroup &&
                    <div className='col-sm-4'>
                      <ResponsiveImage
                        className={!isRow ? 'center-block' : ''}
                        src={this.props.upgrade.image}
                        lazyload={false}
                      />
                    </div>
                  }
                </Row>
                <hr />
                <Row>
                  {/* Decides whether to show party composition or car selection */}
                  {/* Hide the group option for the swappable tickets except for the season passes */}
                  {(!this.props.upgrade.isSwappableTicket || this.props.upgrade.isSeasonPass) && this.groupOptions()}
                  {!this.props.upgrade.isSwappableTicket &&
                    <React.Fragment>
                      {this.compositionOptions()}
                      {this.daysOptions()}
                      {this.datesOptions()}
                    </React.Fragment>
                  }
                </Row>
              </Modal.Body>
              <Modal.Footer>
                <Row>
                  {totalPrice > 0 &&
                    <div className='col-xs-12'>
                      <FormattedMessage id='common.totalPrice' />:&nbsp;
                      <span className='h3'><DisplayPrice price={totalPrice} /></span>
                    </div>
                  }
                  <div className='col-xs-6 col-sm-4'>
                    {this.props.canCloseModal &&
                      <button
                        className='btn btn-block btn-default'
                        {...trackingHelpers.getAttributes(closeModalCTATracking.action, closeModalCTATracking.category, closeModalCTATracking.label)}
                        onClick={() => this.modalClose()}
                        onKeyDown={(e) => e.key === 'Enter' && this.modalClose()}
                        type='button'
                      >
                        <FormattedMessage id='common.close' />
                      </button>
                    }
                  </div>
                  <div className='col-xs-6 col-sm-4 col-sm-offset-4'>
                    <button
                      className='btn btn-block btn-primary'
                      {...trackingHelpers.getAttributes(addBasketCTATracking.action, addBasketCTATracking.category, addBasketCTATracking.label)}
                      onClick={(e) => this.handleUpgradeCTAClose(this.props.upgrade.selectedProductId, e)}
                      onKeyDown={(e) => e.key === 'Enter' && this.handleUpgradeCTAClose(this.props.upgrade.selectedProductId, e)}
                      type='button'
                    >
                      {CTAAmendText}
                    </button>
                  </div>
                </Row>
              </Modal.Footer>
            </Modal>
          </div>
        </div>
        <div className={isRow ? 'col-xs-7 col-xs-offset-5 col-sm-3 col-sm-offset-0' : 'caption'}>
          {this.props.upgrade.standardPrice && this.props.upgrade.standardPrice !== this.props.upgrade.price &&
            (<div className='clearfix'>
              <div className='h5 text-right text-muted text-capitalize'>
                <p><s data-automated-test='strikeThroughPrice'>{this.props.wasText} <DisplayPrice price={this.props.upgrade.standardPrice} /></s></p>
              </div>
            </div>)}
          {/* Don't show upgrade price if its £0.00 */}
          {grossPrice > 0 &&
            <div className='h5 text-right' data-automated-test='grossPrice'>
              <FormattedMessage id={this.getAddUpgradeFormattedMessageText(hasTicketsIncluded)} />&nbsp;<DisplayPrice price={grossPrice} />
            </div>
          }
          {/* Upgrade call to action. Should read Add or Remove based on isInBasket prop. */}
          <button
            className={classes}
            {...trackingHelpers.getAttributes(openModalCTATracking.action, openModalCTATracking.category, openModalCTATracking.label)}
            onClick={this.modalOpen}
            onKeyDown={(e) => e.key === 'Enter' && this.modalOpen()}
            type='button'
          >
            <span>
              {CTAText}
            </span>
          </button>
        </div>
      </div>
    )
  }
}

Upgrade.propTypes = {
  defaultComposition: PropTypes.object.isRequired,
  handleUpgradeCTA: PropTypes.func.isRequired,
  intl: intlShape,
  modalClose: PropTypes.func.isRequired,
  modalOpen: PropTypes.func,
  row: PropTypes.bool,
  upgrade: PropTypes.shape({
    composition: PropTypes.shape({
      adults: PropTypes.number,
      cars: PropTypes.number,
      children: PropTypes.number
    }).isRequired,
    description: PropTypes.string,
    id: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired
  }).isRequired,
  wasText: PropTypes.element.isRequired
}

export default injectIntl(Upgrade)
export {
  Upgrade
}
