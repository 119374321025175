import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGBed extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <path d='M18.984 6.984c2.203 0 4.031 1.828 4.031 4.031v9h-2.016v-3h-18v3h-2.016v-15h2.016v9h8.016v-7.031h7.969zM6.984 12.984c-1.641 0-3-1.359-3-3s1.359-3 3-3 3 1.359 3 3-1.359 3-3 3z' />
      </SVG>
    )
  }
}

SVGBed.propTypes = SVG.propTypes

SVGBed.defaultProps = {
  title: 'Bed Icon'
}

export default SVGBed
