import _ from 'lodash'
import classNames from 'classnames'
import moment from 'moment'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'

import Calendar from './Calendar'

import engineOld from '../../../actions/engineOld'

class CheckOut extends PureComponent {
  constructor (props) {
    super(props)
    this.toggleVisible = this.toggleVisible.bind(this)
  }

  dateAsString (date, errorString = '') {
    const asMoment = moment(date)
    return asMoment.isValid() ? asMoment.format('Do MMM YY') : errorString
  }

  toggleVisible (e) {
    if (e && e.preventDefault) e.preventDefault()
    if (e && e.stopPropagation) e.stopPropagation()
    if (!this.props.checkInDate) {
      return this.props.errorCheckIn()
    }
    this.props.toggleVisible()
  }

  render () {
    if (!this.props.hasEngineStore) return null
    return (
      <div className={classNames({
        'searchform-checkout': true,
        'has-error': this.props.error
      })}>
        <div className='form-group'>
          <label htmlFor='searchformcheckoutbutton'>
            {this.props.label}
          </label>
          <button
            className='form-control ignore-react-onclickoutside'
            id='searchformcheckoutbutton'
            onClick={this.toggleVisible}
            onKeyDown={(e) => e.key === 'Enter' && this.toggleVisible()}
            type='button'
          >
            {this.dateAsString(this.props.checkOutDate, 'Please choose')}
          </button>
          {this.props.isVisible &&
            <Calendar
              endDate={this.props.maxCheckOutDate}
              isDayDisabled={checkOutDate => {
                return !(moment(checkOutDate).isSameOrBefore(this.props.maxCheckOutDate, 'day') && moment(checkOutDate).isSameOrAfter(this.props.minCheckOutDate, 'day'))
              }}
              onChange={this.props.change}
              onClose={this.toggleVisible}
              selected={this.props.checkOutDate}
              startDate={this.props.ticketDate}
            />
          }
          {this.props.error && <div className='help-block'>
            <FormattedMessage id='common.pleaseSelect' />
          </div>}
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const {
    ticketDate,
    checkOutDate,
    checkInDate,
    maxCheckOutDate,
    minCheckOutDate
  } = state.engineOld.engineStore

  return {
    ticketDate,
    maxCheckOutDate,
    minCheckOutDate,
    checkInDate,
    checkOutDate,
    label: _.get(state.engineOld.brandConfig, 'UITextDefaults.labelCheckOut', 'Check out'),
    hasEngineStore: state.engineOld.hasEngineStore,
    error: state.engineOld.errorCheckOutDate,
    isVisible: state.engineOld.visibleCheckOutCalendar
  }
}

function mapDispatchToProps (dispatch) {
  return {
    errorCheckIn: () => {
      return dispatch(engineOld.error('checkInDate', true))
    },

    toggleVisible: () => {
      return dispatch(engineOld.toggle('checkOutCalendar', false))
    },

    change: checkOutDate => {
      return dispatch(engineOld.changeCheckOutDate(checkOutDate))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(CheckOut)
