import qs from 'qs'

const routeHelpers = {}

/**
 * Move to the next stage of the flow
 * @param {Array} flow Array of Flow objects. Object must contain at least an "endpoint" property
 * @param {String} currentEndpoint the currently active endpoint
 * @param {String} skipStage a stage we want to skip
 * @return {String} the next endpoint
 */
routeHelpers.getNextStage = (flow, currentEndpoint, skipStage) => {
  if (!flow || !Array.isArray(flow) || !currentEndpoint) return null
  const endpoints = flow.map(item => item.endpoint).filter(item => item !== skipStage)
  const existingIndex = endpoints.indexOf(currentEndpoint)
  // Return the next endpoint in the flow.
  // When `currentEndpoint` can't be found, it will return `-1` which will still work here and just return `undefined`
  return endpoints[existingIndex + 1]
}

/**
 * Move to a new endpoint
 * @param  {String} url endpoint
 * @param  {Object} parameters an object representing the query parameters
 * @return {void}
 */
routeHelpers.navigateTo = (url, parameters, options) => {
  if (!url) return
  if (parameters) {
    const requestString = routeHelpers.serialiseObject(parameters)
    url += `?${requestString}`
  }
  if (options && options.hashString) {
    url += `#${options.hashString}`
  }
  window.location.href = url
}

/**
 * Will parse a URL and return you key:value pairs from the query string
 * @param  {String} url URL to parse
 * @return {Object} key:value pairs
 */
routeHelpers.getQueryStringParams = () => {
  if (typeof window === 'undefined' || !window.location || !window.location.search) return {}
  return qs.parse(window.location.search.substring(1))
}

/**
 * Convert an object representing query parameters to a string
 * @param  {Object|Array} obj object or array representing query parameters
 * @param  {String} prefix prefix for properties
 * @return {String} query param string
 */
routeHelpers.serialiseObject = (obj, prefix) => {
  if (!obj) return ''

  const urlParams = []
  for (let prop in obj) {
    if (!obj.hasOwnProperty(prop)) {
      continue
    }

    const key = prefix ? prefix + '[' + prop + ']' : prop
    const value = obj[prop]

    let param = null
    if (typeof value === 'object') {
      param = routeHelpers.serialiseObject(value, key)
    } else {
      param = encodeURIComponent(key) + '=' + encodeURIComponent(value)
    }

    if (param) {
      urlParams.push(param)
    }
  }
  return urlParams.join('&')
}

/**
 * Add a hash to URL
 * @param  {String} hash string to add as a hash to the URL
 * @return {void}
 */
routeHelpers.setHash = (hash = '') => {
  if (typeof hash === 'string') {
    window.location.hash = hash
  }
}

export default routeHelpers
