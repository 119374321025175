import _ from 'lodash'
import actionConfiguration from './configuration'
import filterHotelList from '../selectors/filterHotelList'
import generalHelpers from '../helpers/generalHelpers'

const packageRates = {
  filter: () => {
    return (dispatch, getState) => {
      const state = getState()
      dispatch({
        type: 'FILTERED_PACKAGES',
        packages: filterHotelList(state)
      })
    }
  },
  get: () => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        // Using dispatch here so the reducers can respond accordingly when fetching the data
        // for the first time.

        return dispatch(actionConfiguration.getRoomThemeOrdering()).then(() => {
          const brand = getState().brand
          const configuration = getState().configuration
          dispatch({
            additionalNight: (window && window.additionalNightPackageRatesReply) || {},
            brand,
            hotelOfferCodes: (window && window.hotelOfferCodesReply) || {},
            roomThemeOrdering: configuration.roomThemeOrdering,
            standard: (window && window.packageRatesReply) || {},
            type: 'RECEIVED_PACKAGE_RATES'
          })
          resolve()
        })
      })
    }
  },

  getGrouped: (hotelId) => {
    return (dispatch, getState) => {
      return new Promise((resolve, reject) => {
        const mergedPackageRates = getState().packageRates.packages
        const hotelPackageRate = mergedPackageRates.find(packageRate => packageRate.id === hotelId) || []
        if (!hotelPackageRate) {
          return reject(new Error('no hotelPackageRate found'))
        }

        const grouping = _.get(getState(), `configuration.roomThemeOrdering[${hotelId}].themeGrouping`, [])

        // Create new array that groups themes based on their themeTag
        hotelPackageRate.groups = []
        grouping.forEach(group => {
          const themes = []
          group.themeTags.forEach(themeTag => {
            const theme = (hotelPackageRate.themes || []).find(theme => theme.tag === themeTag)
            theme && themes.push(theme)
          })
          // Only add to array if we have themes
          hotelPackageRate.groups.push({
            label: group.label,
            tag: group.label.replace(/ /g, '').toLowerCase(),
            themes,
            available: themes.length > 0
          })
        })

        hotelPackageRate.gallery = []
        if (hotelPackageRate.videoId) {
          hotelPackageRate.gallery.push({
            id: hotelPackageRate.videoId,
            src: generalHelpers.generateYoutubeThumbnail(hotelPackageRate.videoId, 'maxresdefault'),
            type: 'video'
          })
        }

        if (hotelPackageRate.interactive_tour) {
          hotelPackageRate.gallery.push({
            id: hotelPackageRate.interactive_tour,
            src: generalHelpers.generateYoutubeThumbnail(hotelPackageRate.interactive_tour, 'maxresdefault'),
            type: 'interactiveTour'
          })
        }

        hotelPackageRate.gallery = hotelPackageRate.gallery.concat((hotelPackageRate.images || []).map(image => {
          // We shouldn't have a missing src... but checking here just in case
          if (!image) return
          return {
            id: null,
            src: image,
            type: 'image'
          }
        })).filter(Boolean)

        // Determine lowest price per group
        hotelPackageRate.groups.forEach(group => {
          group.fromPrice = group.themes.reduce((prev, curr) => prev && prev < curr.price ? prev : curr.price, null)
        })
        return dispatch({
          hotelPackageRate,
          type: 'RECEIVED_GROUPED_THEMES'
        })
      })
    }
  }
}

export default packageRates
