import moment from 'moment'

const isDiscoveryPrimed = function (dealFinderConfig, partyComposition, checkinDate, checkoutDate) {
  const { supportedChildAgeBoundaries, supportedPartyCompositions, supportedStayLengths } = dealFinderConfig
  if (!supportedChildAgeBoundaries || !supportedPartyCompositions || !supportedStayLengths || !partyComposition || !checkinDate || !checkoutDate) return false
  return assertChildAgesSupported(supportedChildAgeBoundaries, partyComposition) &&
  assertPartyCompositionSupported(supportedPartyCompositions, partyComposition) &&
  assertStayLengthSupported(supportedStayLengths, checkinDate, checkoutDate)
}

const assertChildAgesSupported = function (supportedChildAgeBoundaries, partyComposition) {
  return partyComposition.childrenAges.every(childAge =>
    supportedChildAgeBoundaries.min <= childAge &&
    supportedChildAgeBoundaries.max >= childAge)
}

const assertPartyCompositionSupported = function (supportedPartyCompositions, partyComposition) {
  return supportedPartyCompositions.some(supportedPartyComposition => (
    supportedPartyComposition.adults === partyComposition.adults &&
    supportedPartyComposition.children === partyComposition.children &&
    supportedPartyComposition.infants === partyComposition.infants
  ))
}

const assertStayLengthSupported = function (supportedStayLengths, checkinDate, checkoutDate) {
  const stayLength = moment.utc(checkoutDate).diff(checkinDate, 'days')
  return supportedStayLengths.includes(stayLength)
}

export {
  isDiscoveryPrimed,
  assertChildAgesSupported,
  assertPartyCompositionSupported,
  assertStayLengthSupported
}
