import _ from 'lodash'
import React, { PureComponent } from 'react'
import { connect } from 'react-redux'

import PageContent from './components/organisms/PageContent'
import CheckInCheckOut from './containers/EngineOld/CheckInCheckOut'
import PackagingEngine from './containers/Engine/PackagingEngine'
import WithTickets from './containers/EngineOld/WithTickets'

import config from './configs/config'

const {
  brandConfig
} = config

class LandingPageContainer extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {}
  }

  render () {
    if (!this.props.hasGuestData) return null
    const hasPageContent = _.get(brandConfig, 'secure.hasFeatures.landingPageContent', false)
    let EngineComponent = PackagingEngine
    if (!brandConfig.secure.hasFeatures.hasPackaging) {
      EngineComponent = this.props.version === 'withTickets' ? WithTickets : CheckInCheckOut
    }
    return (
      <div>
        {/* JD: This page is currently used by all brands and not just Cursed Child.
            The only way a user can access this page is by manipulating the URL.
            Merlin do use this page to do searches, so be mindful when editing.
            Future:
            - Redirect all pages to their subsequent homepage
            - Move Cursed child to SSG
        */}
        {brandConfig.brand === 'HP' && (
          <div className='block-md'><img alt='Harry Potter and the Cursed Child logo' className='center-block img-responsive' src='//assets-cursedchild.holidayextraspartners.co.uk/atgCursedChild/assets/Cursed-Child-Logo.png' /></div>
        )}
        <div className='panel panel-default engine-panel'>
          <div className='panel-body'>
            <EngineComponent
              inline={false}
              hasHotelDateUI={false}
              hasRoomsSelectUI={false}
              hasTicketSelectUI={false}
            />
          </div>
        </div>
        {hasPageContent && <PageContent />}
      </div>
    )
  }
}

function mapStateToProps (state, ownProps) {
  return {
    hasGuestData: state.engine.defaults.guests
  }
}

function mapDispatchToProps (dispatch, ownProps) {
  return {}
}

export default connect(mapStateToProps, mapDispatchToProps)(LandingPageContainer)
export {
  LandingPageContainer
}
