import 'isomorphic-fetch'
import _ from 'lodash'

import config from '../configs/config'
import routeHelpers from '../helpers/routeHelpers'
import validationHelpers from '../helpers/validationHelpers'

const { validationRules } = config

// @todo: needs some more tests (Promise bits)
const payment = {

  /**
   * add3DSecureFrame - This will dispatch an event to add the 3DSecure iframe to the modal
   * @param {String} value HTML element to be rendered
   *
   * @return {Function} Returns a function to dispatch
   */
  add3DSecureFrame: (value) => {
    return (dispatch) => {
      return new Promise(resolve => {
        resolve(dispatch({
          type: 'ADD_3D_SECURE_FRAME',
          value
        }))
      })
    }
  },
  /**
   * addressSelect - This will dispatch an event for when a user has selected an
   * address from the dropdown that was generated from the postalcode get
   *
   * @param {String} addressLine This is the first address line from the address array on the address object, used to identify the correct address object
   */
  addressSelect: (addressLine) => {
    return dispatch => {
      return dispatch({
        data: addressLine,
        type: 'ADDRESS_SELECT'
      })
    }
  },

  /**
   * addressConfirm - This will dispatch an event to ensure the required
   * address fields are populated before the address can be confirmed
   *
   * @param {Object} fieldsToValidate This is an object of the fields to validate
   *
   * @return {Boolean} Returns a Boolean
   */
  addressConfirm: (fieldsToValidate) => {
    // Pass all the required address fields to validate for space
    let addressFormFieldsToValidate = fieldsToValidate

    return dispatch => {
      return new Promise((resolve) => {
        // Trim and check for value on the address fields that cannot be submitted without value
        const errors = validationHelpers.trimRequiredFields(addressFormFieldsToValidate)

        if (Object.keys(errors).length) {
          return resolve(dispatch({
            errors,
            type: 'FORM_VALIDATION_FAILED'
          }))
        }

        return resolve(dispatch({
          type: 'TOGGLE_MANUAL_INPUT'
        }))
      })
    }
  },

  /**
   * addressReset - This will dispatch an event for when the user hits the cancel button
   *
   * @return {Function} Returns a function to dispatch
   */
  addressReset: () => {
    return dispatch => {
      return new Promise(resolve => {
        resolve(dispatch({
          type: 'ADDRESS_RESET'
        }))
      })
    }
  },

  /**
    * checkAnnualPass - This will dispatch an event for when the form gets submitted
    * @param {Number} code Customer's Merlin Annual Pass number
    * @return {Boolean} Returns a Boolean
  */
  checkAnnualPass: code => {
    return dispatch => {
      const errors = validationHelpers.validateFields({ 'annualPassNo': code })
      if (Object.keys(errors).length) {
        return dispatch({
          errors,
          type: 'FORM_VALIDATION_FAILED'
        })
      }
      dispatch({
        type: 'MAP_ANNUAL_PASS_CHECK',
        code
      })
    }
  },

  checkChildAges: (basket) => {
    return (dispatch, getState) => {
      const {
        billingDetails,
        agesConfiguration: {
          childrenMaxAge,
          childrenMinAge,
          hasInfantsOnSearch = true,
          youngAdultsMaxAge = null,
          youngAdultsMinAge = null
        },
        ticketsPartyCompositions
      } = getState().payment
      return dispatch({
        type: 'CHECK_CHILD_AGES',
        basket,
        billingDetails,
        childrenMaxAge,
        childrenMinAge,
        hasInfantsOnSearch,
        youngAdultsMaxAge,
        youngAdultsMinAge,
        ticketsPartyCompositions
      })
    }
  },

  setBillingTelephoneNumber: (telephoneNumber, countryCode, dialCode) => {
    return dispatch => {
      return new Promise((resolve) => {
        resolve(dispatch({
          type: 'FORMAT_TELEPHONE_NUMBER',
          telephoneNumber,
          dialCode,
          countryCode
        }))
      })
    }
  },

  /**
   * formSubmit - This will dispatch an event for when the form gets submitted
   * @param {Array} fields fields to validate
   * @return {Function} Returns a function to dispatch
   */
  formSubmit: (fields, fieldsToTrim) => {
    return dispatch => {
      return new Promise((resolve) => {
        const fieldErrors = validationHelpers.validateFields(fields)
        const trimmedFields = validationHelpers.trimRequiredFields(fieldsToTrim)
        // Create single errors object
        const errors = Object.assign(fieldErrors, trimmedFields)

        if (Object.keys(errors).length) {
          return resolve(dispatch({
            errors,
            type: 'FORM_VALIDATION_FAILED'
          }))
        }
        return resolve(dispatch({
          errors,
          type: 'FORM_VALIDATION_PASSED'
        }))
      })
    }
  },

  initializePaymentForm: (rooms) => {
    return (dispatch, getState) => {
      const { agesConfiguration } = getState().payment
      dispatch({
        type: 'INITIALIZE_PAYMENT_FORM',
        agesConfiguration,
        rooms
      })
    }
  },

  /**
   * inputChange - This will dispatch an event for when an input field has changed
   *
   * @param {String} id ID of input field changed
   * @param {String} value Value of input field
   * @return {Function} Returns a function to dispatch
   */
  inputChange: (id, value) => {
    return dispatch => {
      // See if we have an error on this field
      const error = validationHelpers.validateField(id, value)
      dispatch({
        error,
        id,
        type: 'INPUT_CHANGED',
        value
      })
    }
  },

  /**
   * paymentCardTypeChange - This will dispatch an event for when an Payment-client
   * returns a different card type after validation of card number field
   *
   * @param {Array} cards Array of returned card types
   * @return {Function} Returns a function to dispatch
    */
  paymentCardTypeChange: (cards) => {
    return dispatch => {
      return new Promise((resolve) => {
        resolve(dispatch({
          type: 'PAYMENT_CARD_TYPE_CHANGE',
          value: cards[0] && cards[0].type
        }))
      })
    }
  },

  /**
   * paymentChoiceChange - This will dispatch an event for when the user selects
   * a payment type
   *
   * @param {String} value chosen payment type
   * @return {Function} Returns a function to dispatch
   */
  paymentChoiceChange: (value) => {
    return dispatch => {
      return new Promise((resolve) => {
        resolve(dispatch({
          type: 'PAYMENT_CHOICE_CHANGE',
          value
        }))
      })
    }
  },

  /**
   * paymentClientError - This will dispatch an event for when there is an error from the paymentClient
   *
   * @param {String} message chosen payment type
   * @param {Boolean} showForm don't rerender the form if set to true
   * @return {Function} Returns a function to dispatch
   */
  paymentClientError: (message, showForm) => {
    return dispatch => {
      dispatch({
        message,
        showForm,
        type: 'PAYMENT_CLIENT_ERROR'
      })
    }
  },

  /**
   * paymentClientToken - This will dispatch an event for when the Payment-client returns a token
   *
   * @param {String} token returned payment client token
   * @return {Function} Returns a function to dispatch
   */
  paymentClientToken: (token, activatePaypal, activateApplePay) => {
    return dispatch => {
      return new Promise((resolve) => {
        resolve(dispatch({
          token,
          activateApplePay,
          activatePaypal,
          type: 'PAYMENT_CLIENT_TOKEN'
        }))
      })
    }
  },

  /**
   * paymentFieldValidation - This will dispatch an event when to add or remove an error from any payment field
   *
   * @param {String} id field to validate
   * @param {Boolean} isValid response from payment client if field is valid or not
   * @return {Function} Returns a function to dispatch
   */
  paymentFieldValidation: (id, isValid) => {
    return dispatch => {
      return new Promise((resolve) => {
        // See if there is an error for this field
        const message = _.get(validationRules, [id, 'message'], 'Field is invalid')
        const error = isValid ? null : message
        resolve(dispatch({
          error,
          id,
          isValid,
          type: 'PAYMENT_FIELD_VALIDATION'
        }))
      })
    }
  },

  /**
   * paymentUpdate - This will dispatch an event when we need to wait for Payment-client
   *
   * @param {Boolean} value will set isLoading to true or false
   * @return {Function} Returns a function to dispatch
   */
  paymentUpdate: (value) => {
    return dispatch => {
      return new Promise((resolve) => {
        resolve(dispatch({
          type: 'PAYMENT_UPDATE',
          value
        }))
      })
    }
  },

  /**
   * postalCodeGet - This will dispatch an event for when the user hits the postcode lookup button
   *
   * @param {String} postcode the given postcode
   * @return {Function} Returns a function to dispatch
   */
  postalCodeGet: (postcode) => {
    const trimPostcode = _.trim(postcode).length
    return dispatch => {
      // If postcode field is empty not even worth us querying
      if (trimPostcode === 0) {
        dispatch({
          type: 'POSTALCODE_LOOKUP_FIELD_FAILED'
        })
      } else {
        dispatch({
          type: 'POSTALCODE_GET'
        })
        // Do a search for the postcode details
        return fetch(`/addressLookup?postalCode=${postcode}`, {
          headers: {
            'content-type': 'application/json',
            'Accept': 'application/json'
          },
          credentials: 'include'
        })
          .then(response => {
            if (!response.ok) {
              throw new Error('Response not successful')
            }
            return response.json()
          })
          .then(response => {
            const addressLookup = response.addressLookupReply.addresses
            if (!Array.isArray(addressLookup) || response.addressLookupReply.error) {
              throw new Error('Error returned from postcode lookup')
            }
            // We found a list of addresses, return it
            dispatch({
              data: addressLookup,
              type: 'POSTALCODE_GET_SUCCESS'
            })
          })
          .catch(() => {
            dispatch({
              error: 'There was an issue processing the postcode. Please enter your address manually',
              type: 'POSTALCODE_GET_FAILURE'
            })
          })
      }
    }
  },

  /**
   * remove3DSecureFrame - This will dispatch an event to remove the 3DSecure iframe
   *
   * @return {Function} Returns a function to dispatch
   */
  remove3DSecureFrame: () => {
    return (dispatch) => {
      return new Promise(resolve => {
        resolve(dispatch({
          type: 'REMOVE_3D_SECURE_FRAME'
        }))
      })
    }
  },

  /**
   * setDummyAddressData - Sets dummy address data for two stage payment
   */
  setDummyAddressData: () => {
    return dispatch => {
      dispatch({
        type: 'SET_DUMMY_ADDRESS_DATA'
      })
    }
  },

  /**
   * setIsBookerFirstRoomLead - Event dispatch for setting isBookerFirstRoomLead to true
   */
  setIsBookerFirstRoomLead: () => {
    return dispatch => {
      return new Promise((resolve) => {
        resolve(dispatch({
          type: 'SET_IS_BOOKER_FIRST_ROOM_LEAD'
        }))
      })
    }
  },

  /**
   * setLeadRoomDetails - Event dispatch for when the lead room details are the same as the guest details
   *
   * @param {String} id ID of input field changed
   * @param {String} value Value of input field
   */
  setLeadRoomDetails: (id, value) => {
    return dispatch => {
      return new Promise((resolve) => {
        resolve(dispatch({
          type: 'SET_LEAD_ROOM_DETAILS',
          id,
          value
        }))
      })
    }
  },

  /**
   * submitAdminPassword - This will dispatch an event to check the password in order to display the card fields to CX
   *
   * @param {String} encryptedPassword Encrypted password returned from payment-client
   * @return {Function} Returns a function to dispatch
   */
  submitAdminPassword: (encryptedPassword) => {
    return dispatch => {
      // Set the loading state to true while the passwords are being checked
      dispatch({
        type: 'ADMIN_PASSWORD_LOADING'
      })

      const queryStringParams = routeHelpers.getQueryStringParams()
      const queryParams = queryStringParams.referrer ? '?referrer=' + queryStringParams.referrer : ''
      return fetch(`/adminPassword${queryParams}`, {
        method: 'POST',
        body: JSON.stringify({
          encryptedPassword
        }),
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include'
      })
        .then(response => {
          if (response.ok) {
            dispatch({
              data: response,
              type: 'ADMIN_PASSWORD_SUCCESS'
            })
          } else {
            throw new Error('There was an issue processing the password')
          }
        })
        .catch((error) => {
          dispatch({
            error: error.message,
            type: 'ADMIN_PASSWORD_FAILURE'
          })
        })
    }
  },

  /**
   * toggleManualInput - This will dispatch an event to toggle between manual and non manual input
   *
   * @return {Function} Returns a function to dispatch
   */
  toggleManualInput: () => {
    return dispatch => {
      return new Promise(resolve => {
        resolve(dispatch({
          type: 'TOGGLE_MANUAL_INPUT'
        }))
      })
    }
  },

  /**
   * toggleUpgrade - This will dispatch an event when the user checks or unchecks an upgrade on the payment page
   *
   * @param {String} id ID of input field changed
   * @param {String} value Value of input field
   * @param {Object} upgrade information about the upgrade
   * @return {Function} Returns a function to dispatch
   */
  toggleUpgrade: (id, value, upgrade) => {
    return dispatch => {
      return new Promise(resolve => {
        resolve(dispatch({
          id,
          type: 'TOGGLE_UPGRADE',
          upgrade,
          value
        }))
      })
    }
  },

  toggleVoucherPayment: () => {
    return dispatch => {
      dispatch({
        type: 'TOGGLE_VOUCHER_PAYMENT'
      })
    }
  }
}

export default payment
