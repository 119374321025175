import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

class BasketSummaryDiscountMessage extends PureComponent {
  render () {
    return (
      <div className='clearfix'>
        <div className='lozenge pull-right'>{this.props.message}</div>
      </div>
    )
  }
}

BasketSummaryDiscountMessage.propTypes = {
  message: PropTypes.string.isRequired
}

export default BasketSummaryDiscountMessage
