import React, { Component } from 'react'
import PropTypes from 'prop-types'

class Stepper extends Component {
  update (number) {
    if (number < this.props.min) return this.props.onUpdate(this.props.min)
    if (number > this.props.max) return this.props.onUpdate(this.props.max)
    return this.props.onUpdate(number)
  }

  plusIcon () {
    return { __html: '&plus;<span class="sr-only">increase</span>' }
  }

  minusIcon () {
    return { __html: '&minus;<span class="sr-only">decrease</span>' }
  }

  render () {
    return (
      <div className='input-group'>
        <span className='input-group-btn'>
          <button
            data-automated-test='btnDecrease'
            className='btn btn-default'
            dangerouslySetInnerHTML={this.minusIcon()}
            disabled={this.props.count <= this.props.min}
            onClick={() => this.update(this.props.count - this.props.step)}
            onKeyDown={(e) => e.key === 'Enter' && this.update(this.props.count - this.props.step)}
            type='button'
          />
        </span>
        <input
          className='form-control text-center'
          {...(this.props.id ? { id: this.props.id } : undefined)}
          max={this.props.max}
          min={this.props.min}
          onChange={e => this.update(parseInt(e.target.value, 10))}
          type='number'
          value={this.props.count}
        />
        <span className='input-group-btn'>
          <button
            data-automated-test='btnIncrease'
            className='btn btn-default'
            dangerouslySetInnerHTML={this.plusIcon()}
            disabled={this.props.count >= this.props.max}
            onClick={() => this.update(this.props.count + this.props.step)}
            onKeyDown={(e) => e.key === 'Enter' && this.update(this.props.count + this.props.step)}
            type='button'
          />
        </span>
      </div>
    )
  }
}

Stepper.defaultProps = {
  max: 10,
  min: 0,
  step: 1
}

Stepper.propTypes = {
  count: PropTypes.number.isRequired,
  max: PropTypes.number,
  min: PropTypes.number,
  onUpdate: PropTypes.func.isRequired,
  step: PropTypes.number
}

export default Stepper
