import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGLoadingSpinner extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <path
          d='M43.935,25.145c0-10.318-8.364-18.683-18.683-18.683c-10.318,0-18.683,8.365-18.683,18.683h4.068c0-8.071,6.543-14.615,14.615-14.615c8.072,0,14.615,6.543,14.615,14.615H43.935z'
          style={{ fill: 'currentColor' }}>
          <animateTransform
            attributeName='transform'
            attributeType='xml'
            dur='0.6s'
            from='0 25 25'
            repeatCount='indefinite'
            to='360 25 25'
            type='rotate'
          />
        </path>
      </SVG>
    )
  }
}

SVGLoadingSpinner.propTypes = SVG.propTypes

SVGLoadingSpinner.defaultProps = {
  title: 'Loading Spinner'
}

export default SVGLoadingSpinner
