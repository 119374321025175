import moment from 'moment'
import roomify from 'roomify'
import _ from 'lodash'
import childAgeToHeight from '../configs/childAgeToHeights'
const engineHelpers = {}

engineHelpers.changeDates = (checkIn, checkOut, nightConfig, dates, closeOutDates) => {
  const nights = checkOut ? moment(checkOut).diff(moment(checkIn), 'days') : nightConfig.defaultNights
  const maxCheckOutDate = moment(engineHelpers._getMaximumCheckoutDate(checkIn, nightConfig.maxNights)).toDate()
  let { checkInDate, checkOutDate } = engineHelpers._getCheckInCheckOut(checkIn, nights)

  let packageGroupId = null
  const numberOfNights = moment(checkOutDate).diff(checkInDate, 'days')
  const nightsAvailable = Object.keys(dates.byNumberNights).map(Number)
  let nightToUse = nightsAvailable[0]
  for (const night of nightsAvailable) {
    if (night <= numberOfNights && dates.byNumberNights[night][moment(checkInDate).format('YYYY-MM-DD')]) {
      nightToUse = night
    }
  }
  packageGroupId = dates.byNumberNights[nightToUse][moment(checkInDate).format('YYYY-MM-DD')]
  if (!packageGroupId) {
    const forceNights = Object.keys(dates.byNumberNights).find(night => {
      if (dates.byNumberNights[night][moment(checkInDate).format('YYYY-MM-DD')]) {
        return true
      }
    })
    checkOutDate = moment(checkInDate).add(Number(forceNights), 'days')
    packageGroupId = dates.byNumberNights[Number(forceNights)][moment(checkInDate).format('YYYY-MM-DD')]
  }
  const ticketDate = engineHelpers._getFirstAvailableTicketDate(checkInDate, nights, closeOutDates)

  return {
    checkInDate,
    checkOutDate,
    nights,
    maxCheckOutDate,
    packageGroupId,
    ticketDate
  }
}

engineHelpers.changePartyComp = (key, count, engineState) => {
  let invalidPartyMax = null
  let invalidPartyMultiple = null
  let newCount = count
  const clonedEngineState = _.cloneDeep(engineState)
  let children = _.cloneDeep(_.get(clonedEngineState, 'engineStore.children', []))
  const partyComp = _.cloneDeep(_.get(clonedEngineState, 'engineStore.partyComp', []))
  const brandConfig = engineState.brandConfig
  const defaultRooms = _.get(clonedEngineState, 'engineStore.rooms', [])
  const diff = Math.abs(partyComp[key].count - count)
  newCount = !isNaN(count) ? count : partyComp[key].limit[0]

  // Add default values to the children ages array
  if (partyComp[key].key === 'children') {
    if (partyComp[key].count < newCount) {
      for (let i = 0; i < diff; i++) {
        children.push({
          age: '',
          error: false
        })
      }
    } else if (partyComp[key].count > newCount) {
      children = children.splice(0, newCount)
    }
  }
  partyComp[key].count = newCount

  if (brandConfig.maxParty || brandConfig.partyMultiplesOf) {
    const totalPartyComp = partyComp.reduce((total, party) => {
      return total + party.count
    }, 0)

    if (brandConfig.maxParty && totalPartyComp > brandConfig.maxParty) {
      invalidPartyMax = {
        expected: brandConfig.maxParty,
        actual: totalPartyComp
      }
    }

    if (brandConfig.partyMultiplesOf && totalPartyComp % brandConfig.partyMultiplesOf === 1) {
      invalidPartyMultiple = {
        expected: brandConfig.partyMultiplesOf,
        actual: totalPartyComp
      }
    }
  }

  let defaultRoomTypes = []
  if (defaultRooms[0] !== null) {
    defaultRoomTypes = [
      defaultRooms[0].occupancyType
    ]
  }

  const roomPartyComp = engineHelpers._roomPartyCompositionObject(partyComp, children)
  const { roomTypes, rooms, remainingRoomPartyComp } = engineHelpers._generateRoomChoices(defaultRooms, defaultRoomTypes, roomPartyComp)
  const invalidPartyInfants = engineHelpers._checkValidInfantsForRooms(partyComp, rooms, children)

  return {
    children,
    partyComp,
    remainingRoomPartyComp,
    roomTypes,
    rooms,
    invalidPartyMax,
    invalidPartyMultiple,
    invalidPartyInfants
  }
}

engineHelpers.changeRoom = (roomNumber, roomCode, engineStore) => {
  const roomPartyComp = engineHelpers._roomPartyCompositionObject(engineStore.partyComp, engineStore.children)
  const availableRooms = engineHelpers._filterRoomsByPartyComposition(roomPartyComp)
  const chosenRoom = engineHelpers._findChosenRoom(roomCode, availableRooms)
  // Copy the existing room & modify data on it.
  const allocatedRooms = engineHelpers._mergeNewRoomIntoRooms(engineStore.rooms, roomNumber, {
    code: roomCode,
    adults: chosenRoom.adults,
    children: chosenRoom.children,
    occupancyType: chosenRoom.occupancyType,
    partyComposition: {
      adults: chosenRoom.adults,
      children: chosenRoom.children
    }
  })

  const { roomTypes, rooms, remainingRoomPartyComp } = engineHelpers._generateRoomChoices(allocatedRooms, engineStore.roomTypes, roomPartyComp)
  const invalidPartyInfants = engineHelpers._checkValidInfantsForRooms(engineStore.partyComp, rooms, engineStore.children)

  return {
    rooms,
    remainingRoomPartyComp,
    roomTypes,
    invalidPartyInfants
  }
}

engineHelpers._checkValidInfantsForRooms = (partyComp, rooms, children) => {
  const { infants } = engineHelpers._roomPartyCompositionObject(partyComp, children)

  const numberOfRooms = rooms.filter(room => room !== null).length
  if (numberOfRooms === 0) return null
  if (infants > numberOfRooms) {
    return {
      expected: numberOfRooms,
      actual: infants
    }
  }
  return null
}

engineHelpers._filterRoomsByPartyComposition = (partyComposition) => {
  return roomify.filterRooms(roomify.data.theme, partyComposition)
}

engineHelpers._findChosenRoom = (roomCode, rooms) => {
  return rooms.find(room => room.code === roomCode) || {}
}

engineHelpers.firstDate = (dates) => {
  const first = Object.keys(dates).sort()[0]
  return new Date(first).toISOString()
}

engineHelpers._generateRoomChoices = (rooms = [], roomTypes = [], partyComposition = { adults: 0, children: 0, infants: 0 }) => {
  let roomCode
  let chosenRoom
  let remainingRoomPartyComp = _.cloneDeep(partyComposition)
  // Filter down the list of roomtypes (roomify.data.theme) to give us suitable rooms for the party composition passed in.
  let availableRooms = engineHelpers._filterRoomsByPartyComposition(partyComposition)

  const roomsDeepCopy = _.cloneDeep(rooms)
  const roomTypesDeepCopy = _.cloneDeep(roomTypes)
  let assignedInfants = 0
  let requiredInfants = partyComposition.infants
  for (let roomNumber = 0; roomNumber < 3; roomNumber++) {
    // What is the code for this room?
    roomCode = (roomsDeepCopy && roomsDeepCopy[roomNumber] && roomsDeepCopy[roomNumber].code)

    // Add the available rooms to this rooms suggestions.
    roomTypesDeepCopy[roomNumber] = availableRooms

    // Get the details for this particular room
    chosenRoom = roomCode ? engineHelpers._findChosenRoom(roomCode, availableRooms) : {}

    const hasChosenRoom = !!Object.keys(chosenRoom).length

    // Do we actually have a room selected here?
    if (hasChosenRoom) {
      if (roomsDeepCopy[roomNumber].partyComposition) {
        if (assignedInfants < requiredInfants) {
          assignedInfants++
          // Add the infant to the room
          roomsDeepCopy[roomNumber].partyComposition.infants = 1
        } else if (requiredInfants === 0) {
          roomsDeepCopy[roomNumber].partyComposition.infants = 0
        }
      }

      remainingRoomPartyComp = chosenRoom.remaining
      availableRooms = engineHelpers._filterRoomsByPartyComposition(remainingRoomPartyComp)
    } else {
      availableRooms = []
    }

    // Reset the code if we don't have any room details found (maybe we reduced party comp & this room
    // is no longer available?).
    roomsDeepCopy[roomNumber] = hasChosenRoom ? roomsDeepCopy[roomNumber] : null
  }

  return {
    roomTypes: roomTypesDeepCopy,
    rooms: roomsDeepCopy,
    remainingRoomPartyComp: remainingRoomPartyComp || partyComposition
  }
}

engineHelpers._getFirstAvailableTicketDate = (checkInDate, nights, closeOutDates) => {
  for (let i = 0; i <= nights; i++) {
    const date = moment(checkInDate).add(i, 'days').format('YYYY-MM-DD')
    if (!closeOutDates[date]) {
      return moment.utc(date).toDate()
    }
  }
  return moment.utc(checkInDate).toDate()
}

engineHelpers._getCheckInCheckOut = (checkInDate, selectedNights) => {
  const checkOutDate = checkInDate ? moment(checkInDate).add(selectedNights, 'days').toISOString() : null
  return {
    checkInDate,
    checkOutDate
  }
}

engineHelpers._getMaximumCheckoutDate = (checkIn, maximumNights) => {
  return checkIn ? moment(checkIn).add(maximumNights + 1, 'days').toISOString() : null
}

engineHelpers.lastDate = (dates) => {
  try {
    const last = Object.keys(dates).sort()[Object.keys(dates).length - 1]
    return new Date(last).toISOString()
  } catch (error) {
    return null
  }
}

engineHelpers.mergeInDefaultAgesCounts = (partyComp, { guests = [] }) => {
  partyComp = _.cloneDeep(partyComp)
  let children = []
  partyComp.forEach(ageBracket => {
    ageBracket.count = (ageBracket || ageBracket.count) ? ageBracket.count : 0
  })
  if (!guests || !guests.length) {
    partyComp.filter(ageBracket => {
      if (ageBracket.key === 'children') {
        for (let i = 0; i < ageBracket.count; i++) {
          children.push({ age: '' })
        }
      }
    })
    return {
      partyCompWithDefaultCounts: partyComp,
      children
    }
  }
  guests.forEach(guest => {
    const age = Number(guest.age)
    partyComp.forEach((party, i) => {
      const range = partyComp[i].range
      const isInfant = partyComp[i].key.toLowerCase().includes('infants')
      if (age >= range[0] &&
        // Infants are counted up to the top range (but do not include the top)
        ((isInfant && age < range[1]) ||
          // Other age groups are counted including the top range
          (!isInfant && age <= range[1]) ||
          // No top range
          (range[1] === null) ||
          // Some brand ages are outside of the range i.e. Paultons age '14' range '3-11'
          (age === partyComp[i].age))
      ) {
        if (partyComp[i].key === 'children' && age <= partyComp[i].range[1]) {
          children.push({
            age,
            overOneMeter: guest.heightInCentimetres > 100
          })
        }
      }
    })
  })
  partyComp.map(ageBracket => {
    ageBracket.count = 0
    guests.forEach(guest => {
      const age = Number(guest.age)
      if (['adults', 'children'].includes(ageBracket.key)) {
        if (age >= ageBracket.range[0] && (ageBracket.range[1] === null || age <= ageBracket.range[1])) {
          ageBracket.count++
        }
      }
    })
    return ageBracket
  })

  return {
    partyCompWithDefaultCounts: partyComp,
    children
  }
}

engineHelpers._mergeNewRoomIntoRooms = (rooms, roomNumber, newRoom) => {
  // Copy the existing room & modify data on it.
  const roomsDeepCopy = _.cloneDeep(rooms)
  roomsDeepCopy[roomNumber] = Object.assign((roomsDeepCopy[roomNumber] || {}), newRoom)
  return roomsDeepCopy
}

engineHelpers.revertPartyAndAges = (children, partyComp, engineState) => {
  const roomPartyComp = engineHelpers._roomPartyCompositionObject(partyComp, children)
  const { roomTypes, rooms, remainingRoomPartyComp } = engineHelpers._generateRoomChoices(engineState.rooms, engineState.roomTypes, roomPartyComp)
  return {
    children,
    partyComp,
    remainingRoomPartyComp,
    roomTypes,
    rooms
  }
}

engineHelpers._roomPartyCompositionObject = (partyComposition, children) => {
  const partyComp = _.cloneDeep(partyComposition)
  let infantsCountFromChildAges = 0

  // For withChildAges we need to generate infants in the party comp when we assign rooms
  const checkInfantsFromChildAges = !partyComp.find(curr => curr.key === 'parkInfants2')
  if (checkInfantsFromChildAges) {
    children.forEach(child => {
      // Subtract count for 'children' add count for 'infants' (While checking room composition)
      if (child.age === 1) {
        infantsCountFromChildAges++
        partyComp.forEach(curr => {
          if (curr.key === 'children') {
            curr.count--
          }
        })
      }
    })
  }

  return partyComp.reduce((accumulator, party) => {
    // Merging youngAdults, children & infants into one as treated the same in the room compositions.
    if (party.key === 'children' || party.key === 'parkInfants1' || party.key === 'disChild') {
      accumulator.children = (accumulator.children || 0) + party.count
    } else if (party.key === 'adults') {
      accumulator.adults = party.count
    } else if (party.key === 'parkInfants2') {
      accumulator.infants = party.count
    }

    // If there is no infant count (withChildAges: true), check if there are infants in the childAges
    if (infantsCountFromChildAges !== 0 && party.key === 'children') {
      accumulator.infants = infantsCountFromChildAges
    }
    return accumulator
  }, { adults: 0, children: 0, infants: 0 })
}

engineHelpers.updateChild = (index, age, overOneMeter, engineState) => {
  if (overOneMeter === undefined) {
    overOneMeter = childAgeToHeight[age]
  }
  const children = _.cloneDeep(engineState.engineStore.children)
  const partyComp = _.get(engineState, 'engineStore.partyComp', [])
  // The drop down selected will have an value therefore not error
  if (age) {
    children[index].age = parseInt(age)
  }
  children[index].overOneMeter = overOneMeter

  const defaultRooms = engineState.defaults.rooms
  let defaultRoomTypes = []
  // On the 'landing' page, this is an empty array.
  if (defaultRooms[0]) {
    defaultRoomTypes = [defaultRooms[0].occupancyType]
  }
  const roomPartyComp = engineHelpers._roomPartyCompositionObject(partyComp, children.map(child => child.age))
  const { roomTypes, rooms, remainingRoomPartyComp } = engineHelpers._generateRoomChoices(defaultRooms, defaultRoomTypes, roomPartyComp)
  return {
    children,
    remainingRoomPartyComp,
    roomTypes,
    rooms
  }
}

export default engineHelpers
