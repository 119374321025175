import _ from 'lodash'
import moment from 'moment'
import PropTypes from 'prop-types'
import React, { PureComponent } from 'react'
import { FormattedMessage } from 'react-intl'

import DisplayPrice from '../atoms/DisplayPrice'
import ExtraNightSummary from '../atoms/ExtraNightSummary'

import trackingHelpers from '../../helpers/trackingHelpers'

class BasketUpgradeSummary extends PureComponent {
  render () {
    return (
      <div className='basket-upgrade-summary'>
        <h3>
          {this.props.summaryTitle}
        </h3>
        <div className='basket-upgrade-summary__details'>
          {this.props.upgrades && this.props.upgrades.map(upgrade =>
            <div key={`addedUpgrade${upgrade.id}`} className='row no-margin-padding'>
              <div className='col-sm-9 pull-left no-margin-padding'>
                <div>
                  <strong>{upgrade.title}</strong>
                </div>
                {upgrade.showUpgradeDate && upgrade.isAvailableForAllSelectedDates !== true && !upgrade.composition.quantity &&
                  <div>
                    <small className='text-muted'>{moment(upgrade.selectedDate).format('ddd DD MMM YY')}</small>
                  </div>
                }
                {upgrade.composition.quantity &&
                  <div>
                    <small className='text-muted'>
                      <FormattedMessage id='common.quantity' />:&nbsp;{upgrade.composition.quantity}
                    </small>
                  </div>
                }
              </div>
              <div className='col-sm-3 pull-right no-margin-padding'>
                <div className='basket-upgrade-price text-right'>
                  <strong>
                    <DisplayPrice price={upgrade.totalPrice || upgrade.price} />
                  </strong>
                </div>
                {this.props.handleUpgradeCTA && !upgrade.isIncludedInPackage &&
                  <div className='text-right'>
                    <a className='small'
                      {...trackingHelpers.getAttributes('Remove Upgrade From Cart', 'Upgrades', upgrade.id)}
                      href='#'
                      onClick={this.props.handleUpgradeCTA.bind(this, upgrade.selectedProductId)}>
                      <FormattedMessage id='common.remove' />
                    </a>
                  </div>
                }
              </div>
            </div>
          )}
          {_.get(this.props, 'extraNights.before.isInBasket', false) &&
            <ExtraNightSummary
              data={this.props.extraNights.before}
              handleExtraNightCTA={this.props.handleExtraNightCTA}
              isNightBefore
              removeBtnTrackingAttrs={trackingHelpers.getAttributes('Remove Upgrade From Cart', 'Upgrades', 'Extra Night At Start')}
            />
          }
          {_.get(this.props, 'extraNights.after.isInBasket', false) &&
            <ExtraNightSummary
              data={this.props.extraNights.after}
              handleExtraNightCTA={this.props.handleExtraNightCTA}
              isNightBefore={false}
              removeBtnTrackingAttrs={trackingHelpers.getAttributes('Remove Upgrade From Cart', 'Upgrades', 'Extra Night At End')}
            />
          }
        </div>
      </div>
    )
  }
}

BasketUpgradeSummary.propTypes = {
  extraNights: PropTypes.shape({
    after: PropTypes.shape({
      isInBasket: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired,
    before: PropTypes.shape({
      isInBasket: PropTypes.bool.isRequired,
      name: PropTypes.string.isRequired
    }).isRequired
  }),
  handleExtraNightCTA: PropTypes.func,
  handleUpgradeCTA: PropTypes.func,
  summaryTitle: PropTypes.string.isRequired,
  upgrades: PropTypes.array
}

export default BasketUpgradeSummary
