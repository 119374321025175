import React, { PureComponent } from 'react'
import SVG from './SVG'

class SVGBath extends PureComponent {
  render () {
    return (
      <SVG
        {...this.props}>
        <g>
          <circle cx='7' cy='6' r='2' />
          <path d='M11.15 12c-.31-.22-.59-.46-.82-.72l-1.4-1.55c-.19-.21-.43-.38-.69-.5-.29-.14-.62-.23-.96-.23h-.03C6.01 9 5 10.01 5 11.25V12H2v8c0 1.1.9 2 2 2h16c1.1 0 2-.9 2-2v-8H11.15zM7 20H5v-6h2v6zm4 0H9v-6h2v6zm4 0h-2v-6h2v6zm4 0h-2v-6h2v6zm-.35-14.14l-.07-.07c-.57-.62-.82-1.41-.67-2.2L18 3h-1.89l-.06.43c-.2 1.36.27 2.71 1.3 3.72l.07.06c.57.62.82 1.41.67 2.2l-.11.59h1.91l.06-.43c.21-1.36-.27-2.71-1.3-3.71zm-4 0l-.07-.07c-.57-.62-.82-1.41-.67-2.2L14 3h-1.89l-.06.43c-.2 1.36.27 2.71 1.3 3.72l.07.06c.57.62.82 1.41.67 2.2l-.11.59h1.91l.06-.43c.21-1.36-.27-2.71-1.3-3.71z' />
          <path d='M0 0h24v24H0z' fill='none' />
        </g>
      </SVG>
    )
  }
}

SVGBath.propTypes = SVG.propTypes

SVGBath.defaultProps = {
  title: 'Bath Icon'
}

export default SVGBath
