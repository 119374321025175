import _ from 'lodash'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Experiment, Variant } from '@marvelapp/react-ab-test'
import BrandContext from '../contexts/BrandContext'
import { query } from '../components/atoms/BreakPoint'

class SplitTest extends Component {
  render () {
    if (!this.props.initialized) return

    const { brandConfig = {} } = this.context
    const { activeExperiment, children = [] } = this.props

    const isCallCentre = brandConfig.consumerType === 'callCentre'
    const userDevice = query.deviceBasedOnScreenSize()
    const experimentWeighting = _.get(activeExperiment, ['devices', userDevice, 'weighting'], activeExperiment.weighting)
    const hasValidWeighting = experimentWeighting && experimentWeighting.length > 0 && experimentWeighting[0] !== 100

    // If the experiment is not forced AND is invalid OR is callcentre OR is not active, render the default variant
    if (!activeExperiment.forced && (!hasValidWeighting || isCallCentre || !Object.keys(activeExperiment).length)) {
      // Find the experiment from our list of experiments
      const experiment = _.get(this.props, ['all', this.props.name], {})
      // Now grab the default variant (first in the list by design - "show_original" as a fallback)
      const defaultVariantName = _.get(experiment, ['variants', 0], 'show_original')
      // Now we know the default variant name, we can grab that variant from the children
      const defaultVariant = children.find && children.find(child => {
        // We ONLY allow children of Experiment to be type of Variant
        const isVariantType = child.type === Variant
        if (!isVariantType) throw new Error('Child is not of type Variant')
        return isVariantType && child.props.name === defaultVariantName
      })

      // Because this is not actually an Experiment (as it's not active)
      // we only need to render the children (not the Variant component).
      return _.get(defaultVariant, 'props.children', null)
    }

    // This is the default case where we just drop into the Experiment / Variant code as per
    // react-ab-test docs.
    return (
      <Experiment name={this.props.name}>
        {children}
      </Experiment>
    )
  }
}

SplitTest.defaultProps = {
  name: null,
  activeExperiment: {},
  initialized: false,
  all: {}
}

// Gives the Component the ability to access the BrandContext
SplitTest.contextType = BrandContext

function mapStateToProps (state, ownProps) {
  return {
    activeExperiment: state.experiment.active[ownProps.name],
    all: state.experiment.all,
    initialized: state.experiment.initialized
  }
}

export default {
  Experiment: connect(mapStateToProps)(SplitTest),
  Variant
}
