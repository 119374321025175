import _ from 'lodash'
import moment from 'moment'
import EngineLogic from 'sb-engine-logic'

import tracking from './tracking'
import config from '../configs/config'
const withChildAges = _.get(config, 'brandConfig.secure.withChildAges', false)

const toDateObject = dateString => {
  if (!dateString) return null
  const asMoment = moment(dateString)
  return asMoment.isValid() ? asMoment.toDate() : null
}

const track = (name, value) => {
  return tracking.track(name, 'engine', value)
}

let engineLogic

const engineOld = {

  initialize: overrides => {
    return (dispatch, getState) => {
      const state = getState()
      const { availableTickets, availableDates, brandConfig, closeOutDates } = _.get(window, 'searchFormService', {})

      // If withChildAges only return adults and children from the partyComp
      if (withChildAges) {
        brandConfig.partyComps.default = brandConfig.partyComps.default.filter(ageBracket => {
          if (ageBracket.key === 'children') {
            ageBracket.subtext = '0 to 15 years'
            ageBracket.range = [0, 15]
          }
          return ['adults', 'children'].includes(ageBracket.key)
        })
      }

      const options = Object.assign({
        availableDates, // from getAvailability call.
        availableTickets, // from getTickets / getTicketDetails call.
        closeOutDates,
        defaultAges: state.engineOld.defaults.ages,
        defaultCheckInDate: toDateObject(state.engineOld.defaults.checkInDate),
        defaultHotelDate: toDateObject(state.engineOld.defaults.checkInDate),
        defaultNights: state.engineOld.defaults.nights,
        defaultRooms: state.engineOld.defaults.rooms,
        defaultTicketCode: state.engineOld.defaults.ticketCode,
        defaultTicketDate: toDateObject(state.engineOld.defaults.ticketDate),
        forceTicketByDate: true,
        maxParty: brandConfig.maxParty,
        phoneNumber: brandConfig.number,
        partyComps: brandConfig.partyComps, // A complex object used for the party composition picker & for the rules around partyComposition for tickets / default.
        partyMultiplesOf: brandConfig.partyMultiplesOf,
        restrictDatesByTicket: brandConfig.restrictDatesByTicket || false,
        withChildAges
      }, overrides)

      engineLogic = new EngineLogic(options)
      const engineStore = engineLogic.store

      // If default ages are not passed e.g. when accessing the engine on the root pages,
      // we need to fill out the array with empty ages so the child age fields are still displayed & validated.
      // First we calculate how many child ages we need, according to the partyComp
      let childrenCount = 0
      if (engineStore.partyComp.length) {
        childrenCount = engineStore.partyComp.reduce((count, party) => {
          return party.key === 'children' ? count + party.count : count
        }, 0)
      }
      const childAgesDiff = Math.abs(engineStore.childAges.length - childrenCount)
      // Then add the empty ages to the childAges array
      let childAges = engineStore.childAges
      if (childAgesDiff > 0) {
        for (let i = 0; i < childAgesDiff; i++) {
          childAges.push({
            value: '',
            error: true
          })
        }
      }
      engineStore.childAges = childAges
      engineStore.childAgesRevert = childAges

      return dispatch({
        type: 'ENGINEOLD_INITIALIZE',
        engineStore,
        brandConfig
      })
    }
  },

  isTicketCalendarDayDisabled: (date, isTicketDate = false) => (dispatch, getState) => {
    const state = getState()
    const { checkInDate, checkOutDate, closeOutDates } = state.engineOld.engineStore
    const restrictTicketDatesByHotelDates = state.engineOld.brandConfig.restrictTicketDatesByHotelDates
    const venueClosureDate = closeOutDates[moment(date).format('YYYY-MM-DD')]
    if (restrictTicketDatesByHotelDates && isTicketDate) return !moment(date).isBetween(checkInDate, checkOutDate, 'day', '[]') || venueClosureDate

    return engineLogic.isTicketCalendarDayDisabled(date) || venueClosureDate
  },

  isHotelCalendarDayDisabled: date => {
    return engineLogic.isHotelCalendarDayDisabled(date)
  },

  error: (errorKey, hasError = false) => {
    return dispatch => {
      return dispatch({
        type: `ENGINEOLD_ERROR_${errorKey.toUpperCase()}`,
        hasError
      })
    }
  },

  toggle: (toggleType, forceVisible = false) => {
    return dispatch => {
      return dispatch({
        type: `ENGINEOLD_TOGGLE_${toggleType.toUpperCase()}`,
        forceVisible
      })
    }
  },

  toggleModal: () => {
    return dispatch => {
      return dispatch({
        type: 'ENGINEOLD_MODAL_TOGGLE'
      })
    }
  },

  submit: () => {
    return dispatch => {
      return dispatch({
        type: 'ENGINEOLD_SUBMIT'
      })
    }
  },

  updateEngineStore: engineStore => {
    return dispatch => {
      return dispatch({
        type: 'ENGINEOLD_UPDATE_STORE',
        engineStore
      })
    }
  },

  track: (name, value) => {
    return dispatch => {
      return dispatch(track(name, value))
    }
  },

  changeCheckInDate: checkInDate => {
    const engineStore = engineLogic.changeDates(checkInDate, null)
    return dispatch => {
      dispatch(track('checkInDate', checkInDate))
      return dispatch({
        type: 'ENGINEOLD_CHANGE_CHECKINDATE',
        engineStore
      })
    }
  },

  changeCheckOutDate: checkOutDate => {
    return (dispatch, getState) => {
      const state = getState()
      const checkInDate = state.engineOld.engineStore.checkInDate
      const engineStore = engineLogic.changeDates(checkInDate, checkOutDate)
      dispatch(track('checkOutDate', checkOutDate))
      return dispatch({
        type: 'ENGINEOLD_CHANGE_CHECKOUTDATE',
        engineStore
      })
    }
  },

  changeRoom: (roomNumber, roomCode) => {
    const engineStore = engineLogic.changeRoom(roomNumber, roomCode)
    return dispatch => {
      dispatch(track('roomCode', roomCode))
      return dispatch({
        type: 'ENGINEOLD_CHANGE_ROOM',
        engineStore
      })
    }
  },

  changePartyComp: (key, count) => {
    const engineStore = engineLogic.changePartyComp(key, count)
    const name = `partyComp_${engineStore.partyComp[key].key}`
    return dispatch => {
      dispatch(track(name, count))
      return dispatch({
        type: 'ENGINEOLD_CHANGE_PARTYCOMP',
        engineStore
      })
    }
  },

  confirmPartyAndAges: () => {
    engineLogic.confirmPartyAndAges()
    return dispatch => dispatch(track('confirmPartyComp', true))
  },

  updateChildAge: (key, age) => {
    const engineStore = engineLogic.updateChildAge(key, age)
    return dispatch => {
      dispatch(track('childageupdate', age))
      return dispatch({
        type: 'ENGINEOLD_UPDATE_CHILDAGE',
        engineStore
      })
    }
  },

  revertPartyAndAges: (childAgesCopy, partyCompCopy) => {
    return (dispatch, getState) => {
      let { engineStore } = getState().engine
      engineStore = engineLogic.revertPartyAndAges(engineStore.childAgesRevert, partyCompCopy)
      return dispatch({
        type: 'ENGINEOLD_REVERT_PARTYANDAGES',
        engineStore
      })
    }
  },

  changeTicket: ticketCode => {
    const engineStore = engineLogic.changeTicket(ticketCode)
    return dispatch => {
      dispatch(track('ticketCode', ticketCode))
      return dispatch({
        type: 'ENGINEOLD_CHANGE_TICKET',
        engineStore
      })
    }
  },

  changeTicketDate: ticketDate => {
    return (dispatch, getState) => {
      dispatch(track('ticketDate', ticketDate))

      const restrictTicketDatesByHotelDates = getState().engineOld.brandConfig.restrictTicketDatesByHotelDates
      if (restrictTicketDatesByHotelDates) {
        return dispatch({
          type: 'ENGINEOLD_CHANGE_ONLY_TICKET_DATE',
          ticketDate
        })
      }

      const engineStore = engineLogic.changeTicketDate(ticketDate)
      return dispatch({
        type: 'ENGINEOLD_CHANGE_TICKET_DATE',
        engineStore
      })
    }
  },

  changeHotelDate: hotelDate => {
    const engineStore = engineLogic.changeHotelDate(hotelDate)
    return dispatch => {
      dispatch(track('hotelDate', hotelDate))
      return dispatch({
        type: 'ENGINEOLD_CHANGE_HOTEL_DATE',
        engineStore
      })
    }
  },

  changeNights: nights => {
    const engineStore = engineLogic.changeNights(nights)
    return dispatch => {
      dispatch(track('nights', nights))
      return dispatch({
        type: 'ENGINEOLD_CHANGE_NIGHTS',
        engineStore
      })
    }
  },

  validate: fieldsToValidate => {
    return (dispatch, getState) => {
      return dispatch({
        type: 'ENGINEOLD_VALIDATION',
        fieldsToValidate
      })
    }
  }

}

export default engineOld
