import _ from 'lodash'
import React, { Component } from 'react'
import { FormattedMessage } from 'react-intl'
import { connect } from 'react-redux'
import onClickOutside from 'react-onclickoutside'

import EngineUIPartyComp from '../../../components/atoms/EngineUI/PartyComp'

import config from '../../../configs/config'
import engine from '../../../actions/engine'
const withChildAges = _.get(config, 'brandConfig.secure.withChildAges', false)

class PartyComp extends Component {
  constructor (props) {
    super(props)
    this.state = {}
    this.confirm = this.confirm.bind(this)
    this.close = this.close.bind(this)
  }

  close () {
    // When exiting the party comp modal we need to revert the partyComposition and childAges to their previous values
    this.props.revertPartyAndAges()
    this.props.toggleVisible()
  }

  confirm () {
    if (withChildAges) {
      let error = false

      // Check child ages dropdowns are selected
      this.props.children.forEach((child) => {
        if (child.age === '') {
          child.error = true
          error = true
        }
      })
      if (error) {
        return this.props.validateChildAges(true)
      } else {
        this.props.validateChildAges(false)
        return this.props.toggleVisible()
      }
    }
    this.props.confirmPartyAndAges()
    this.props.toggleVisible()
  }

  handleClickOutside () {
    this.props.isVisible && this.close()
  }

  render () {
    return (
      <div className='searchform-partycomposition'>
        <div className='form-group'>
          <strong>{this.props.label}</strong>
          <div
            className='party_people'
            onClick={this.props.toggleVisible}
            onKeyDown={(e) => e.key === 'Enter' && this.props.toggleVisible()}
            role='button'
            tabIndex='0'>
            <div className='party_people_overlay'>
              <FormattedMessage id='common.amend' />
            </div>
            {this.props.partyComp.map((party, i) => {
              if (!party.count) return null
              return (
                <div key={i} className='party_people_type tiny-xs'>
                  <span className='party_people_key'>{party.text}</span>
                  <span className='party_people_count'> x {party.count}</span>
                </div>
              )
            }) }
          </div>

          {this.props.isVisible && (
            <div className='party_picker form-group'>
              <h3 className='text-center block-xs'>
                <FormattedMessage id='common.whoIsGoing' />
              </h3>
              <button
                aria-label='Close'
                className='close'
                onClick={this.close}
                onKeyDown={(e) => e.key === 'Enter' && this.close()}
                type='button'>
                <span aria-hidden='true' dangerouslySetInnerHTML={{ __html: '&times;' }} />
              </button>

              {this.props.errorPartyComp && (
                <div className='alert alert-warning' role='alert'>
                  {this.props.invalidPartyMax && (
                    <FormattedMessage
                      id='availability.exceededMaxPartyMessage'
                      tagName='p'
                      values={{ maxParty: this.props.invalidPartyMax.expected, telephone: this.props.phoneNumber }} />
                  )}

                  {this.props.invalidPartyMultiple && (
                    <FormattedMessage
                      id='availability.invalidPartyMultiple'
                      tagName='p'
                      values={{ invalidPartyMultipleExpected: this.props.invalidPartyMultiple.expected }} />
                  )}

                  {this.props.invalidPartyInfants && (
                    <FormattedMessage id='availability.invalidPartyInfants' tagName='p' />
                  )}

                  {this.props.errorChildAges && (
                    <FormattedMessage id='availability.invalidPartyChildAges' tagName='p' />
                  )}
                </div>
              )}

              <EngineUIPartyComp
                children={this.props.children}
                errorChildAges={this.props.errorChildAges}
                errorPartyComp={this.props.errorPartyComp}
                onChange={this.props.change}
                partyComp={this.props.partyComp}
                updateChild={this.props.updateChild}
                withChildAges={withChildAges}
              />
              <button
                className='btn btn-primary btn-block'
                onClick={this.confirm}
                onKeyDown={(e) => e.key === 'Enter' && this.confirm()}
                type='button'>
                <FormattedMessage id='common.confirm' />
              </button>
            </div>
          )}
          {this.props.errorPartyComp &&
            <div className='help-block'>
              <FormattedMessage id='common.pleaseSelect' />
            </div>
          }
        </div>
      </div>
    )
  }
}

function mapStateToProps (state) {
  const {
    children,
    partyComp,
    partyComps,
    invalidPartyMax,
    invalidPartyMultiple,
    invalidPartyInfants
  } = state.engine.engineStore

  const {
    errorPartyComp,
    errorChildAges
  } = state.engine

  const { ages, guests } = state.engine.defaults
  const numberOfChildren = partyComp[1] && partyComp[1].count
  return {
    ages,
    guests,
    children,
    errorChildAges,
    errorPartyComp,
    invalidPartyInfants,
    invalidPartyMax,
    invalidPartyMultiple,
    isVisible: state.engine.visiblePartyComp,
    label: _.get(state.engine.brandConfig, 'UITextDefaults.labelPartyComp', 'Guests'),
    numberOfChildren,
    partyComp,
    partyComps,
    phoneNumber: state.engine.brandConfig.phoneNumber
  }
}

function mapDispatchToProps (dispatch) {
  return {
    change: (key, count) => {
      return dispatch(engine.changePartyComp(key, count))
    },
    confirmPartyAndAges: () => {
      return dispatch(engine.confirmPartyAndAges())
    },
    updateChild: (index, age, overOneMeter) => {
      return dispatch(engine.updateChild(index, age, overOneMeter))
    },
    toggleVisible: e => {
      if (e && e.preventDefault) e.preventDefault()
      if (e && e.stopPropagation) e.stopPropagation()
      return dispatch(engine.toggle('partyComp'))
    },
    revertPartyAndAges: () => {
      return dispatch(engine.revertPartyAndAges())
    },
    validateChildAges: (error) => {
      return dispatch(engine.error('childAges', error))
    }
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(onClickOutside(PartyComp))
