import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { FormattedMessage } from 'react-intl'

class PartyComposition extends PureComponent {
  render () {
    return (
      <span>
        {this.props.adults !== 0 &&
          <React.Fragment>
            <FormattedMessage id='common.adultsCount' values={{ count: this.props.adults }} />
          </React.Fragment>
        }
        {this.props.children !== 0 &&
          <React.Fragment>{this.props.infants === 0 ? ' and' : ','}&nbsp;
            <FormattedMessage id='common.childrenCount' values={{ count: this.props.children }} />
          </React.Fragment>
        }
        {this.props.infants !== 0 &&
          <React.Fragment>&nbsp;and&nbsp;
            <FormattedMessage id='common.infantsCount' values={{ count: this.props.infants }} />
          </React.Fragment>
        }
      </span>
    )
  }
}

PartyComposition.defaultProps = {
  adults: 0,
  children: 0,
  infants: 0
}

PartyComposition.propTypes = {
  adults: PropTypes.number.isRequired,
  children: PropTypes.number,
  infants: PropTypes.number
}

export default PartyComposition
