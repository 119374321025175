import React, { PureComponent } from 'react'
import onClickOutside from 'react-onclickoutside'

import EngineUICalendar from '../../../components/atoms/EngineUI/Calendar'

class Calendar extends PureComponent {
  handleClickOutside () {
    this.props.onClose()
  }

  render () {
    return (
      <div className='searchform-calendar-container'>
        <div className='searchform-calendar'>
          <div className='searchform-close'>
            <button
              onClick={this.props.onClose}
              onKeyDown={(e) => e.key === 'Enter' && this.props.onClose()}
              type='button'
              className='close'
              aria-label='Close'>
              <span aria-hidden='true'>×</span>
            </button>
          </div>
          <EngineUICalendar {...this.props} />
        </div>
      </div>
    )
  }
}

export default onClickOutside(Calendar)
